import {observer} from 'mobx-react-lite';
import {useModule} from '../../../root/services/MobXServices/BaseModule';
import {KSeFEventsService} from './KSeFEvents.service';
import {Content} from '../../../root/components/Content';
import {ExtendedTable} from '../../../root/components/ExtendedTable';
import React, {useMemo, useState} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Header} from '../../../root/components/Header';
import {useIntl} from 'react-intl';
import {
  Breadcrumbs,
  ButtonTertiary,
  ButtonTertiarySize,
  Dropdown,
  DropdownListOptions,
  DropdownSize,
} from '@symfonia/brandbook';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from '../../../root/Routes';
import {usePageTitle} from '../../../common';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

enum NotificationsVariants {
  All = 'All',
  Unread = 'Unread'
}

export const KSeFEventsView = observer(() => {
  const navigate = useNavigate();
  const {setPageTitle} = usePageTitle();
  const service = useModule(() => new KSeFEventsService());
  const intl = useIntl();
  const {company: {tenantId, envId}} = earchiveState;
  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.companiesList}),
      onClick: () => {
        earchiveState.company.removeStoredCompanyId();
        navigate(AppRoutes.hub.address);
      } 
    },
    {
      label: 'KSeF',
      onClick: () => navigate(AppRoutes.eArchive.address + '/' + tenantId + '/company/' + envId + '/documents/purchase')
    },
    {
      label: intl.formatMessage({id: Tr.wsEventsHistory}),
    },
  ];

  const variants = useMemo<DropdownListOptions>(() => [
    {value: NotificationsVariants.All, label: intl.formatMessage({id: Tr.allNotifications})},
    {value: NotificationsVariants.Unread, label: intl.formatMessage({id: Tr.unreadNotifications})},
  ], [intl]);

  const [currentVariant, setCurrentVariant] = useState(variants[0].value);

  setPageTitle(intl.formatMessage({id: Tr.wsEventsHistory}));

  return <Content>
    <Breadcrumbs breadcrumbs={breadcrumbs} className="mb-[8px]" testId="notificationsPage-breadcrumbs"/>
    <Header>{intl.formatMessage({id: Tr.wsEventsHistory})}</Header>
    <div className="mb-[24px] mt-[12px] flex items-center">
      <Dropdown
        options={variants}
        size={DropdownSize.SM}
        value={[currentVariant]}
        onChange={([value]) => {
          setCurrentVariant(value);
          service.tableService.setFilter({isActive: value === NotificationsVariants.Unread});
        }}
      />
      <ButtonTertiary
        className="mx-[24px]"
        size={ButtonTertiarySize.SM}
        text={intl.formatMessage({id: Tr.wsMarkAsRead})}
        onClick={() => service.repository.markAllAsRead()}
        testId="kSeFEventsMarkAllAsReadButton"
      />
    </div>
    <ExtendedTable service={service.tableService}/>
  </Content>;
});
