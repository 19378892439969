import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import {usePageTitle} from '../../../../common';
import {PERMISSIONS_PAGE_CONSTS} from './consts';
import AddEditPermission from './AddEditPermissions';
import {
  CredentialsFragment,
  KSeFAuthResult,
  KSeFTokenCredential,
  SubscriptionStateEnum,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Loader} from '../../../../common/components/Loader/Loader';
import NotAuthorizedInKSeF from './NotAuthorizedInKSeFAlert';
import {AddEditSelfInvoicing} from './AddEditSelfInvoicing';
import {DelayType, TriggerConfig, useSubscription} from '../../../hooks/useSubscription';
import {PermissionsView} from '../../../modules/Permissions/Permissions.view';
import {PermissionsRegisterView} from '../../../modules/Permissions/PermissionsRegister.view';
import {Breadcrumbs, ButtonPrimary, ButtonSecondary, Icon, IconColor, IconSize, IconSvg, IconVariant, Tab, Tooltip} from '@symfonia/brandbook';
import {Header} from '../../../../root/components/Header';
import {earchiveState, grantPermissionsService, ksefEventsService} from '@symfonia-ksef/state/rootRepository';
import {PageContent, usePrevious} from '@symfonia/symfonia-ksef-components';

export const PermissionsPage = observer(() => {
  const [isFromKsef, setIsFromKsef] = useState<boolean>(false);
  const intl = useIntl();
  const {company, packageStatistics} = earchiveState;
  const {setPageTitle} = usePageTitle();
  setPageTitle(intl.formatMessage({id: Tr.permissions}));

  const [addEditMode, setAddEditMode] = useState(false);
  const [addSelfInvoicingEditMode, setAddSelfInvoicingEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {authorizedInKSeF, ksefCredentials} = company;
  const credentialRead = ksefCredentials.includes(KSeFTokenCredential.CredentialsRead);
  const credentialManage = ksefCredentials.includes(
    KSeFTokenCredential.CredentialsManage,
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState<CredentialsFragment>(PERMISSIONS_PAGE_CONSTS.DEFAULT_PERMISSIONS_MODEL);
  const [dataExpired, setDataExpired] = useState<Date | undefined>(undefined);
  const [isRegisterPreview, setIsRegisterPreview] = useState(false);
  const [isSingleActionMenuVisible, setIsSingleActionMenuVisible] = useState(false);
  const [singleActionAnchorEl, setSingleActionAnchorEl] = useState<HTMLElement | undefined>(undefined);

  const prevAddEditMode = usePrevious(addEditMode);
  const prevAddSelfInvoicingEditMode = usePrevious(addSelfInvoicingEditMode);
  const {subscribeAsAction: subscribeToRefresh} = useSubscription(null, {
    subscriptionService: ksefEventsService,
    trigger: TriggerConfig.SUCCESS_ONLY,
    delay: {type: DelayType.DEBOUNCE, time: 1000},
    statuses: [SubscriptionStateEnum.Finished],
    types: [WebsocketNotificationType.GrantPermission, WebsocketNotificationType.SyncPermission],
  });

  useEffect(() => {
    if (prevAddEditMode || prevAddSelfInvoicingEditMode) {
      grantPermissionsService.reset();
    }
  }, [addEditMode, addSelfInvoicingEditMode]);

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.permissions}),
    },
  ];

  if (!company) {
    return <Loader/>;
  }

  const handleBackToList = () => {
    setAddEditMode(false);
    setAddSelfInvoicingEditMode(false);
    setSelectedPermissions(PERMISSIONS_PAGE_CONSTS.DEFAULT_PERMISSIONS_MODEL);
    setIsEditMode(false);
  };

  const handleOnPermissionsSuccess = () => {
    handleBackToList();
  };

  if (authorizedInKSeF === KSeFAuthResult.NotAuthorized || !(credentialRead || credentialManage))
    return (
      <PageContent>
        <div className="flex flex-col gap-2 lg:ml-[40px]">
          <Breadcrumbs breadcrumbs={breadcrumbs}/>
          <Header>{intl.formatMessage({id: Tr.permissions})}</Header>
          <NotAuthorizedInKSeF/>
        </div>
      </PageContent>
    );

  if (addEditMode)
    return <AddEditPermission isEditMode={isEditMode} onSubmit={handleOnPermissionsSuccess}
                              handleBack={handleBackToList}/>;

  if (addSelfInvoicingEditMode)
    return <AddEditSelfInvoicing isEditMode={isEditMode} onSubmit={handleOnPermissionsSuccess}
                                 handleBack={handleBackToList}/>;

  const getPermissionTable = () => {
    if (isRegisterPreview) {
      return <PermissionsRegisterView subscribeToRefresh={subscribeToRefresh}/>;
    }
    return (
      <PermissionsView
        subscribeToRefresh={subscribeToRefresh}
        isFromKsef={isFromKsef}
        onLoading={setIsLoading}
        onEditClicked={(userPermissions: CredentialsFragment, element: HTMLElement) => {
          setSingleActionAnchorEl(element);
          setIsSingleActionMenuVisible(true);
          setSelectedPermissions(userPermissions);
          grantPermissionsService.setPermissions(userPermissions);
        }}
        onDataUpdate={data => {
          data?.GetPermissionsKSeF.FromKsef && setIsFromKsef(data.GetPermissionsKSeF.FromKsef);
          setDataExpired(data?.GetPermissionsKSeF.LastUpdated);
        }}
        areActionsDisabled={!credentialManage || !packageStatistics.subscriptionIsActive}
        setIsEditMode={setIsEditMode}
        setAddEditMode={setAddEditMode}
        isSingleActionMenuVisible={isSingleActionMenuVisible}
        setIsSingleActionMenuVisible={setIsSingleActionMenuVisible}
        singleActionAnchorEl={singleActionAnchorEl}
        data={selectedPermissions}
        setAddSelfInvoicingEditMode={setAddSelfInvoicingEditMode}
      />
    );
  };

  return <PageContent>
    <div className="flex flex-col gap-2 lg:ml-[40px]">
      <Breadcrumbs breadcrumbs={breadcrumbs} className="mb-[10px]" testId="permissionsPage-breadcrumbs"/>
      <div className="flex flex-row justify-between items-center w-full mb-2 flex-wrap">
        <Header className="mr-[16px]">{intl.formatMessage({id: Tr.permissions})}</Header>
        <div className="flex items-center">
          <ButtonSecondary
            testId="addSelfInvoicingButton"
            disabled={!credentialManage || !packageStatistics.subscriptionIsActive}
            text={intl.formatMessage({id: Tr.CredentialsSelfInvoicing})}
            onClick={() => setAddSelfInvoicingEditMode(true)}
            className="mr-[10px] [&>span]:truncate max-w-100% sm:w-auto w-[140px] text-left"
          />
          <ButtonPrimary
            testId="addPermissionsButton"
            disabled={!credentialManage || !packageStatistics.subscriptionIsActive}
            text={intl.formatMessage({id: Tr.AddPermissions})}
            onClick={() => setAddEditMode(true)}
            className={'[&>span]:truncate max-w-100% sm:w-auto w-[160px] text-left'}
          />
        </div>
      </div>
      <div className="flex flex-col w-full">
        {!credentialRead && (
          <FormattedMessage
            id={Tr.authorizedManagePermissionsOnlyPageDesc}
            values={{companyName: <b>{company.currentEnvironment?.Company?.Name}</b>}}
          />
        )}
        <div className="flex flex-row justify-start items-center mb-2">
          {credentialRead && (
            <>
              <Tab
                className={'max-w-100% md:w-auto w-[140px]'}
                text={
                  <p className="truncate max-w-100% md:w-auto w-[120px]">
                    {intl.formatMessage({id: Tr.CurrentKSeFPermissions})}
                  </p>
                }
                test-id="currentKSeFPermissionsTab"
                onClick={() => setIsRegisterPreview(false)}
                isActive={!isRegisterPreview}
              />
              <Tab
                className={'max-w-100% md:w-auto w-[140px]'}
                text={
                  <div className="flex items-center">
                    <p className={'truncate max-w-100% md:w-auto w-[120px]'}>{intl.formatMessage({id: Tr.RegisteredPermissionsSymfonyKSeF})}</p>
                    <Tooltip
                      position="right"
                      text={
                        <div>
                          <h1 className="mb-[10px] font-bold">{intl.formatMessage({id: Tr.permissionRegisterButtonTooltip_Header})}</h1>
                          <div>{intl.formatMessage({id: Tr.permissionRegisterButtonTooltip_Content})}</div>
                        </div>}
                    >
                      <Icon
                        svg={IconSvg.INFO}
                        size={IconSize.MD}
                        variant={IconVariant.CONTOUR}
                        color={IconColor.BLUE1_500}
                        className="ml-[10px]"
                      />
                    </Tooltip>
                  </div>
                }
                test-id="registeredPermissionsTab"
                onClick={() => setIsRegisterPreview(true)}
                isActive={isRegisterPreview}
              />
            </>
          )}
        </div>
        {credentialRead && getPermissionTable()}
      </div>
    </div>
  </PageContent>;
});
