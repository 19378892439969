import {Tr} from '@symfonia-ksef/locales/keys';
import {downloadedInvoicesResultService} from '../../../../state/rootRepository';
import {intl} from '../../../root/IntlProvider';
import {FormattedMessage} from 'react-intl';
import {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  ButtonPrimarySize,
  ButtonPrimaryWidth,
  ButtonSecondarySize,
  ButtonSecondaryWidth,
  Modal,
} from '@symfonia/brandbook';

import {GetInvoicesForm} from '../../components/GetInvoicesForm/GetInvoicesForm';
import {ModalHeaderWithIcon} from '../../components/ModalHeaderWithIcon';
import {getModalMaxWidth} from '@symfonia/symfonia-ksef-components';
import {AppRoutes} from '../../../root/Routes';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const InvoiceDatePickerModal: FC = observer(() => {
  if (!downloadedInvoicesResultService.Internal || !downloadedInvoicesResultService.External) return null;
  const selectedCompany = earchiveState.company.currentEnvironment;
  const authRoute = AppRoutes.eArchive.address + '/' + selectedCompany?.Tenant.Id + '/company/' + selectedCompany?.EnvironmentId + AppRoutes.accountAuthentication.address;

  const {
    open,
    setOpen,
    hasAlreadyDownloaded,
  } = downloadedInvoicesResultService;

  const {
    resetValidationError: resetInternalValidationError,
    resetDate: resetInternalDate,
    dateRange: internalDateRange,
    checked: isInternalChecked,
    shouldBeDisabled: internalShouldBeDisabled,
    isDirtyAndValid: isDirtyAndValidInternal,
    isMaxDateRangeExceeded: isMaxDateRangeExceededInternal,
  } = downloadedInvoicesResultService.Internal ?? {};

  const {
    resetValidationError: resetExternalValidationError,
    resetDate: resetExternalDate,
    dateRange: externalDateRange,
    checked: isExternalChecked,
    shouldBeDisabled: externalShouldBeDisabled,
    isDirtyAndValid: isDirtyAndValidExternal,
    isMaxDateRangeExceeded: isMaxDateRangeExceededExternal,
  } = downloadedInvoicesResultService.External ?? {};

  const emptyRange = {DateFrom: null, DateTo: null};

  const onSubmit = async () => {
    await downloadedInvoicesResultService.jobRunner.configure({
      InternalEnabled: !!isInternalChecked,
      ExternalEnabled: !!isExternalChecked,
      External: isExternalChecked ? externalDateRange : emptyRange,
      Internal: isInternalChecked ? internalDateRange : emptyRange,
    }).fetch();
    onCancel(false);
  };

  function onCancel(closeModal: boolean = true) {
    closeModal && setOpen();
    resetExternalValidationError(undefined);
    resetInternalValidationError(undefined);
  }

  const isNewUser = !hasAlreadyDownloaded;

  const modalContentSize = useMemo<string>(() => {
    return getModalMaxWidth();
  }, []);

  return <Modal
    className={modalContentSize}
    okButton={{
      className: 'basis-1/2',
      width: ButtonPrimaryWidth.BASE,
      size: ButtonPrimarySize.SM,
      text: intl.formatMessage({id: Tr.download}),
      disabled: (internalShouldBeDisabled || externalShouldBeDisabled || isMaxDateRangeExceededInternal || isMaxDateRangeExceededExternal || (!isExternalChecked && !isInternalChecked) || (!isDirtyAndValidInternal && !isDirtyAndValidExternal)),
      onClick: onSubmit,
    }}
    cancelButton={{
      className: 'basis-1/2',
      width: ButtonSecondaryWidth.BASE,
      size: ButtonSecondarySize.SM,
      text: intl.formatMessage({id: Tr.cancelButton}),
    }}
    title={
      <ModalHeaderWithIcon
        title={intl.formatMessage({id: isNewUser ? Tr.invoicesDatesHeaderNew : Tr.invoicesDatesHeader})}
        tooltipContent={<FormattedMessage id={Tr.invoicesDatesTooltip} values={{br: <br/>, dateRange: intl.formatMessage({id: isNewUser ? Tr.dateRange2Years : Tr.dateRange90days})}}/>}
      />}
    show={open}
    onClose={() => onCancel()}>
    <GetInvoicesForm/>
  </Modal>;

});
