import {useIntl} from 'react-intl';
import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {PostedDecreeResponseFilterKeys} from '../../../../../earchive/pages/Account/Posting/pages/PostingConfiguration/CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {Tr} from '@symfonia-ksef/locales/keys';
import {InvoiceTypeGroupEnum} from '@symfonia-ksef/graphql';
import {
  convertToDropdownListOption,
  convertToFilterItems,
  convertToMapDropdownListOption,
  convertToMultiSelectType,
} from '../../../../../common/helpers/baseFilterHelpers';
import {DateRangeFilterEnumPosting, RangeFilterEnum} from '@symfonia-ksef/state/filters/BaseFilterState';
import {
  Accordion,
  AccordionSize,
  DatePicker,
  MultipleTextInputFilter,
  MultiSelectFilter,
  NumberPicker,
} from '@symfonia/symfonia-ksef-components';
import {DropdownListOption} from '@symfonia/symfonia-ksef-components';
import {InputWidth} from '@symfonia/brandbook';
import {FilterItems} from '@symfonia/symfonia-ksef-components';
import {IPostingTableFilterState} from '../State/IPostingTableFilterState';

export interface PostingTableFiltersConfigProps {
  state: IPostingTableFilterState;
}

export const PostingTableFiltersConfig: FC<PostingTableFiltersConfigProps> = observer(({state}) => {
  const intl = useIntl();
  if (!state) {
    return <></>;
  }
  const postingInvoiceTypeGroup = state.activeFilters.get(PostedDecreeResponseFilterKeys.InvoiceTypeGroup);
  const postingContractorId = state.activeFilters.get(PostedDecreeResponseFilterKeys.ContractorId);
  const postingIdentifier = state.activeFilters.get(PostedDecreeResponseFilterKeys.Nip);
  const postingGrossValue = state.activeFilters.get(PostedDecreeResponseFilterKeys.GrossValue);
  const postingPostingDate = state.activeFilters.get(PostedDecreeResponseFilterKeys.CreatedAt);
  const typeGroup = [InvoiceTypeGroupEnum.Purchase, InvoiceTypeGroupEnum.Sales];

  return (
    <>
      <Accordion
        group={'categoryName'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.categoryName}) +
          (postingInvoiceTypeGroup?.values !== undefined && postingInvoiceTypeGroup?.values?.length !== 0
            ? ' (' + postingInvoiceTypeGroup?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(typeGroup, el => ({
            label: intl.formatMessage({id: Tr[el]}),
            value: el,
          }))}
          items={convertToDropdownListOption(
            state.pillsList.get(PostedDecreeResponseFilterKeys.InvoiceTypeGroup)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.handleSelectWithTypeConversion(selected, PostedDecreeResponseFilterKeys.InvoiceTypeGroup)
          }
        />
      </Accordion>
      <Accordion
        group={'ContractorId'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.ContractorId}) +
          (postingContractorId?.values !== undefined && postingContractorId?.values?.length !== 0
            ? ' (' + postingContractorId?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(state.contractors, el => ({
            label: el.value,
            value: el.key,
          }))}
          items={convertToDropdownListOption(
            state.pillsList.get(PostedDecreeResponseFilterKeys.ContractorId)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.handleSelectByKey(
              convertToMultiSelectType(selected, el => ({
                value: el?.label ?? '',
                key: el?.value,
              })),
              PostedDecreeResponseFilterKeys.ContractorId,
            )
          }
        />
      </Accordion>
      <Accordion
        group={'identifierSender'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.identifierSender}) +
          (postingIdentifier?.values !== undefined && postingIdentifier?.values?.length !== 0
            ? ' (' + postingIdentifier?.values.length + ')'
            : '')
        }
      >
        <MultipleTextInputFilter
          itemLabel={intl.formatMessage({id: 'Identifier'})}
          filterItems={
            postingIdentifier?.pills !== undefined && postingIdentifier?.pills?.length > 0
              ? convertToFilterItems(postingIdentifier?.pills, el => ({
                  value: el.value,
                  key: el.key,
                }))
              : undefined
          }
          setFilterItems={(items?: FilterItems) => {
            state.handleSelectByValue(
              convertToMultiSelectType(items, el => ({
                value: el?.value ?? '',
                key: el?.key.toString(),
              })),
              PostedDecreeResponseFilterKeys.Nip,
            );
          }}
          itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
          addButtonLabel={intl.formatMessage({id: 'addAnother'})}
          inputWidth={InputWidth.FULL}
        />
      </Accordion>
      <Accordion group={'GrossValue'} size={AccordionSize.SM} title={intl.formatMessage({id: Tr.GrossValue})}>
        <NumberPicker
          setValue={v => state.setRangeFilter(PostedDecreeResponseFilterKeys.GrossValue, v, RangeFilterEnum)}
          labelSpecific={intl.formatMessage({id: Tr.grossValueSearchAmount})}
          labelRange={intl.formatMessage({id: Tr.range})}
          placeholders={{
            from: intl.formatMessage({id: Tr.from}),
            to: intl.formatMessage({id: Tr.to}),
            specific: intl.formatMessage({id: Tr.searchAmount}),
          }}
          valueFrom={postingGrossValue?.values[0]?.from ?? ''}
          valueTo={postingGrossValue?.values[0]?.to}
          valueSpecific={postingGrossValue?.values[0]?.specific}
        />
      </Accordion>
      <Accordion group={'PostingDate'} size={AccordionSize.SM} title={intl.formatMessage({id: Tr.PostingDate})}>
        <DatePicker
          startDate={postingPostingDate?.values[0]?.from ?? undefined}
          endDate={postingPostingDate?.values[0]?.to ?? undefined}
          specificDate={postingPostingDate?.values[0].specific ?? undefined}
          setDate={v => state.setRangeFilter(PostedDecreeResponseFilterKeys.CreatedAt, v, DateRangeFilterEnumPosting)}
          singleDateLabel={intl.formatMessage({id: Tr.dateLabel})}
          rangeStartDateLabel={intl.formatMessage({id: Tr.dateFrom})}
          singleDateInputLabel={intl.formatMessage({id: Tr.selectSpecificDate})}
          rangeDateInputLabel={intl.formatMessage({id: Tr.selectDate})}
          setValidationError={state.setHasError}
        />
      </Accordion>
    </>
  );
});
