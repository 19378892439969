import {useIntl} from 'react-intl';
import {InvoiceBound, RodzajFaktury, CurrencyCodeEnum} from '@symfonia-ksef/graphql';
import React, {FC} from 'react';
import {IFiltersState} from '../../../pages/Documents/state/IFiltersState';
import {ModulesEnum} from '../../../../common/enums/ModulesEnum';
import {observer} from 'mobx-react-lite';
import {
  FilterItems,
  Accordion,
  AccordionSize,
  DatePicker,
  DropdownListOption,
  MultipleTextInputFilter,
  MultiSelectFilter,
  NumberPicker,
  ExtendedDatePicker,
} from '@symfonia/symfonia-ksef-components';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  convertToDropdownListOption,
  convertToFilterItems,
  convertToMapDropdownListOption,
  convertToMultiSelectType,
  hasDefinedValues,
} from '../../../../common/helpers/baseFilterHelpers';
import {InputWidth} from '@symfonia/brandbook';
import {
  DateRangeFilterEnumIssueDate,
  DateRangeFilterEnumKsefIssueDate,
  RangeFilterEnumNetValue,
  RangeFilterEnumVatValue,
  RangeFilterEnumGrossValue,
} from '@symfonia-ksef/state/filters/BaseFilterState';
import {getDefaultKsefStatusesList, getDefaultPostingStatusesList} from '../../../../common/helpers/filterHelpers';
import {InvoicesFilterKeys, InvoicesFiltersState} from '../../../modules/Invoices/InvoicesFilters/InvoicesFiltersState';
import {EMPTY_VALUE_KEY} from '../../../modules/Invoices/InvoicesFilters/InvoicesFiltersUtils';

export interface FiltersConfigProps {
  stateMobx: IFiltersState;
  invoiceBound: InvoiceBound;
  state: InvoicesFiltersState;
}

export const FiltersConfig: FC<FiltersConfigProps> = observer(({stateMobx, invoiceBound, state}) => {
  const intl = useIntl();

  const numbers = state.activeFilters.get(InvoicesFilterKeys.Numbers);
  const invoiceDocumentType = state.activeFilters.get(InvoicesFilterKeys.DocumentTypes);
  const issuerNames = state.activeFilters.get(InvoicesFilterKeys.IssuerNames);
  const nipsIssuer = state.activeFilters.get(InvoicesFilterKeys.IssuerNips);
  const nipsRecipient = state.activeFilters.get(InvoicesFilterKeys.RecipientNips);
  const recipientsNames = state.activeFilters.get(InvoicesFilterKeys.RecipientNames);
  const netValue = state.activeFilters.get(InvoicesFilterKeys.NetValue);
  const vatValue = state.activeFilters.get(InvoicesFilterKeys.VatValue);
  const grossValue = state.activeFilters.get(InvoicesFilterKeys.GrossValue);
  const currency = state.activeFilters.get(InvoicesFilterKeys.Currencies);
  const dateOfIssue = state.activeFilters.get(InvoicesFilterKeys.DateOfIssue);
  const ksefDate = state.activeFilters.get(InvoicesFilterKeys.KsefDate);
  const ksefNumbers = state.activeFilters.get(InvoicesFilterKeys.KsefNumbers);
  const registrationNumber = state.activeFilters.get(InvoicesFilterKeys.RegistrationNumber);
  const ksefStatus = state.activeFilters.get(InvoicesFilterKeys.KSeFStatuses);
  const invoicePostingStatus = state.activeFilters.get(InvoicesFilterKeys.InvoicePostingStatuses);
  
  const emptyFieldTranslation = intl.formatMessage({id: Tr.emptyField});

  return (
    <>
      <Accordion
        group={'InvoiceNumber'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.InvoiceNumber}) +
          (numbers?.values !== undefined && numbers?.values?.length !== 0 ? ' (' + numbers?.values.length + ')' : '')
        }
        expanded={!!numbers?.values.length}
        testId="invoiceFiltersNumberAccordion"
      >
        <MultipleTextInputFilter
          filterItems={
            numbers?.pills !== undefined && numbers?.pills?.length > 0
              ? convertToFilterItems(numbers?.pills, el => ({
                  value: el.value,
                  key: el.key,
                }))
              : undefined
          }
          setFilterItems={(items?: FilterItems) => {
            state.handleSelectByValue(
              convertToMultiSelectType(items, el => ({
                value: el?.value ?? '',
                key: el?.key.toString(),
              })),
              InvoicesFilterKeys.Numbers,
            );
          }}
          itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
          addButtonLabel={intl.formatMessage({id: 'addAnother'})}
          inputWidth={InputWidth.FULL}
          testId="invoiceFiltersNumberInput"
          preventUpdateOnError={true}
          disableValidation
        />
      </Accordion>

      <Accordion
        group={'invoiceDocumentType'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.invoiceDocumentType}) +
          (invoiceDocumentType?.values !== undefined && invoiceDocumentType?.values?.length !== 0
            ? ' (' + invoiceDocumentType?.values.length + ')'
            : '')
        }
        expanded={!!state.pillsList.get(InvoicesFilterKeys.DocumentTypes)?.values}
        testId="invoiceFiltersDocumentTypeAccordion"
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(Object.values(RodzajFaktury), el => ({
            label: el,
            value: el,
          }))}
          items={convertToDropdownListOption(invoiceDocumentType?.values, el => ({
            label: el,
            value: el,
          }))}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.DocumentTypes)
          }
          testId="invoiceFiltersDocumentTypSelect"
          useSearch
        />
      </Accordion>

      <Accordion
        group={'IssuerNames'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.IssuerNames}) +
          (issuerNames?.values !== undefined && issuerNames?.values?.length !== 0
            ? ' (' + issuerNames?.values.length + ')'
            : '')
        }
        expanded={!!state.pillsList.get(InvoicesFilterKeys.IssuerNames)?.values}
        testId="invoiceFiltersIssuerNamesAccordion"
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(state.issuers, el => ({
            label: el.IssuerName ? el.IssuerName : emptyFieldTranslation,
            value: el.IssuerName ? el.IssuerName : EMPTY_VALUE_KEY,
          }), true)}
          items={convertToDropdownListOption(issuerNames?.values, el => ({
            label: el,
            value: el,
          }))}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.IssuerNames)
          }
          testId="invoiceFiltersIssuerNamesSelect"
          useSearch
        />
      </Accordion>

      {(invoiceBound === InvoiceBound.External) && (
        <Accordion
          group={'IssuerNip'}
          size={AccordionSize.SM}
          title={
            intl.formatMessage({id: Tr.IssuerNip}) +
            (nipsIssuer?.values !== undefined && nipsIssuer?.values?.length !== 0
              ? ' (' + nipsIssuer?.values.length + ')'
              : '')
          }
          expanded={!!nipsIssuer?.values.length}
          testId="invoiceFiltersIssuerNipAccordion"
        >
          <MultipleTextInputFilter
            filterItems={
              nipsIssuer?.pills !== undefined && nipsIssuer?.pills?.length > 0
                ? convertToFilterItems(nipsIssuer?.pills, el => ({
                    value: el.value,
                    key: el.key,
                  }))
                : undefined
            }
            setFilterItems={(items?: FilterItems) => {
              state.handleSelectByValue(
                convertToMultiSelectType(items, el => ({
                  value: el?.value ?? '',
                  key: el?.key.toString(),
                })),
                InvoicesFilterKeys.IssuerNips,
              );
            }}
            itemPlaceholder={intl.formatMessage({id: Tr.SearchSpecificNumber})}
            addButtonLabel={intl.formatMessage({id: Tr.addAnother})}
            inputWidth={InputWidth.FULL}
            testId="invoiceFiltersIssuerNipInput"
            validationPattern={/^\w{1,15}$/}
            preventUpdateOnError
            setValidationError={state.setHasError}
            hasError={state.hasError}
          />
        </Accordion>
      )}

      <Accordion
        group={'RecipientNames'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.RecipientNames}) +
          (recipientsNames?.values !== undefined && recipientsNames?.values?.length !== 0
            ? ' (' + recipientsNames?.values.length + ')'
            : '')
        }
        expanded={!!state.pillsList.get(InvoicesFilterKeys.RecipientNames)?.values.length}
        testId="invoiceFiltersRecipientNamesAccordion"
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(state.recipients, el => ({
            label: el.RecipientName ? el.RecipientName : emptyFieldTranslation,
            value: el.RecipientName ? el.RecipientName : EMPTY_VALUE_KEY,
          }), true)}
          items={convertToDropdownListOption(
            recipientsNames?.values,
            el => ({
              label: el,
              value: el,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.RecipientNames)
          }
          testId="invoiceFiltersRecipientNamesSelect"
          useSearch
        />
      </Accordion>

      {(invoiceBound === InvoiceBound.Internal) && (
        <Accordion
          group={'RecipientNip'}
          size={AccordionSize.SM}
          title={
            intl.formatMessage({id: Tr.RecipientNip}) +
            (nipsRecipient?.values !== undefined && nipsRecipient?.values?.length !== 0
              ? ' (' + nipsRecipient?.values.length + ')'
              : '')
          }
          expanded={!!nipsRecipient?.values.length}
          testId="invoiceFiltersRecipientNipAccordion"
        >
          <MultipleTextInputFilter
            filterItems={
              nipsRecipient?.pills !== undefined && nipsRecipient?.pills?.length > 0
                ? convertToFilterItems(nipsRecipient?.pills, el => ({
                    value: el.value,
                    key: el.key,
                  }))
                : undefined
            }
            setFilterItems={(items?: FilterItems) => {
              state.handleSelectByValue(
                convertToMultiSelectType(items, el => ({
                  value: el?.value ?? '',
                  key: el?.key.toString(),
                })),
                InvoicesFilterKeys.RecipientNips,
              );
            }}
            itemPlaceholder={intl.formatMessage({id: Tr.SearchSpecificNumber})}
            addButtonLabel={intl.formatMessage({id: Tr.addAnother})}
            inputWidth={InputWidth.FULL}
            testId="invoiceFiltersRecipientNipInput"
            validationPattern={/^\w{1,15}$/}
            preventUpdateOnError
            setValidationError={state.setHasError}
            hasError={state.hasError}
          />
        </Accordion>
      )}

      <Accordion
        group={'NetValue'}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.NetValue})}
        expanded={hasDefinedValues(netValue?.values[0]?.from, netValue?.values[0]?.to, netValue?.values[0].specific)}
        testId="invoiceFiltersNetValueAccordion"
      >
        <NumberPicker
          setValue={v => state.setRangeFilter(InvoicesFilterKeys.NetValue, v, RangeFilterEnumNetValue)}
          labelRange={intl.formatMessage({id: Tr.range})}
          setValidationError={state.setHasError}
          placeholders={{
            from: intl.formatMessage({id: Tr.from}),
            to: intl.formatMessage({id: Tr.to}),
            specific: intl.formatMessage({id: Tr.searchAmount}),
          }}
          valueFrom={netValue?.values[0]?.from ?? ''}
          valueTo={netValue?.values[0]?.to ?? ''}
          valueSpecific={netValue?.values[0]?.specific}
          testId="invoiceFiltersNetValuePicker"
        />
      </Accordion>

      <Accordion
        group={'VatValue'}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.VatValue})}
        expanded={hasDefinedValues(vatValue?.values[0]?.from, vatValue?.values[0]?.to, vatValue?.values[0].specific)}
        testId="invoiceFiltersVatValueAccordion"
      >
        <NumberPicker
          setValue={v => state.setRangeFilter(InvoicesFilterKeys.VatValue, v, RangeFilterEnumVatValue)}
          labelRange={intl.formatMessage({id: Tr.range})}
          setValidationError={state.setHasError}
          placeholders={{
            from: intl.formatMessage({id: Tr.from}),
            to: intl.formatMessage({id: Tr.to}),
            specific: intl.formatMessage({id: Tr.searchAmount}),
          }}
          valueFrom={vatValue?.values[0]?.from ?? ''}
          valueTo={vatValue?.values[0]?.to ?? ''}
          valueSpecific={vatValue?.values[0]?.specific}
          testId="invoiceFiltersVatValuePicker"
        />
      </Accordion>

      <Accordion
        group={'GrossValue'}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.GrossValue})}
        expanded={hasDefinedValues(grossValue?.values[0]?.from || grossValue?.values[0]?.to || grossValue?.values[0].specific)}
        testId="invoiceFiltersGrossValueAccordion"
      >
        <NumberPicker
          setValue={v => state.setRangeFilter(InvoicesFilterKeys.GrossValue, v, RangeFilterEnumGrossValue)}
          labelRange={intl.formatMessage({id: Tr.range})}
          setValidationError={state.setHasError}
          placeholders={{
            from: intl.formatMessage({id: Tr.from}),
            to: intl.formatMessage({id: Tr.to}),
            specific: intl.formatMessage({id: Tr.searchAmount}),
          }}
          valueFrom={grossValue?.values[0]?.from ?? ''}
          valueTo={grossValue?.values[0]?.to ?? ''}
          valueSpecific={grossValue?.values[0]?.specific}
          testId="invoiceFiltersGrossValuePicker"
        />
      </Accordion>

      <Accordion
        group={'currency'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.invoiceCurrency}) +
          (currency?.values !== undefined && currency?.values?.length !== 0
            ? ' (' + currency?.values.length + ')'
            : '')
        }
        expanded={!!state.pillsList.get(InvoicesFilterKeys.Currencies)?.values}
        testId="invoiceFiltersCurrencyCodeAccordion"
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(Object.values(CurrencyCodeEnum), el => ({
            label: el,
            value: el,
          }))}
          items={convertToDropdownListOption(currency?.values, el => ({
            label: el,
            value: el,
          }))}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.Currencies)
          }
          testId="invoiceFiltersCurrencyCodeSelect"
          useSearch
        />
      </Accordion>

      {invoiceBound === InvoiceBound.Internal && (
        <Accordion
          group={'DateOfIssue'}
          size={AccordionSize.SM}
          title={intl.formatMessage({id: Tr.DateOfIssue})}
          expanded={dateOfIssue?.values[0]?.from || dateOfIssue?.values[0]?.to || dateOfIssue?.values[0]?.specific}
          testId="invoiceFiltersDateOfIssueAccordion"
        >
          <DatePicker
            startDate={dateOfIssue?.values[0]?.from ?? undefined}
            endDate={dateOfIssue?.values[0]?.to ?? undefined}
            specificDate={dateOfIssue?.values[0].specific ?? undefined}
            setDate={v => state.setRangeFilter(InvoicesFilterKeys.DateOfIssue, v, DateRangeFilterEnumIssueDate)}
            rangeStartDateLabel={intl.formatMessage({id: Tr.dateFromTo})}
            singleDateInputLabel={intl.formatMessage({id: Tr.selectSpecificDate})}
            rangeDateInputLabel={intl.formatMessage({id: Tr.range})}
            rangeDateFromLabel={intl.formatMessage({id: Tr.from})}
            rangeDateToLabel={intl.formatMessage({id: Tr.to})}
            setValidationError={state.setHasError}
            testId="invoiceFiltersDateOfIssueDatePicker"
          />
        </Accordion>
      )}

      <Accordion
        group={'ksefDate'}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.invoiceKsefIssueDate})}
        expanded={ksefDate?.values[0].from || ksefDate?.values[0].to || ksefDate?.values[0].specific}
        testId="invoiceFilterKsefDateAccordion"
      >
        <ExtendedDatePicker
          startDate={ksefDate?.values[0]?.from ?? undefined}
          endDate={ksefDate?.values[0]?.to ?? undefined}
          specificDate={ksefDate?.values[0].specific ?? undefined}
          inputMode={ksefDate?.values[0].rangeType ?? undefined}
          setDate={v => state.setRangeFilter(InvoicesFilterKeys.KsefDate, v, DateRangeFilterEnumKsefIssueDate)}
          singleDateInputLabel={intl.formatMessage({id: Tr.selectSpecificDate})}
          rangeDateInputLabel={intl.formatMessage({id: Tr.range})}
          rangeDateFromLabel={intl.formatMessage({id: Tr.from})}
          rangeDateToLabel={intl.formatMessage({id: Tr.to})}
          setValidationError={state.setHasError}
          calendarModeDay={intl.formatMessage({id: Tr.calendarModeDay})}
          calendarModeMonth={intl.formatMessage({id: Tr.calendarModeMonth})}
          calendarModeYear={intl.formatMessage({id: Tr.calendarModeYear})}
          testId="invoiceFiltersKsefDatePicker"
        />
      </Accordion>

      <Accordion
        group={'KsefNumbers'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.KsefNumber}) +
          (ksefNumbers?.values !== undefined && ksefNumbers?.values?.length !== 0
            ? ' (' + ksefNumbers?.values.length + ')'
            : '')
        }
        expanded={!!ksefNumbers?.values.length}
        testId="invoiceFilterKsefNumbersAccordion"
      >
        <MultipleTextInputFilter
          filterItems={
            ksefNumbers?.pills !== undefined && ksefNumbers?.pills?.length > 0
              ? convertToFilterItems(ksefNumbers?.pills, el => ({
                  value: el.value,
                  key: el.key,
                }))
              : undefined
          }
          setFilterItems={(items?: FilterItems) => {
            state.handleSelectByValue(
              convertToMultiSelectType(items, el => ({
                value: el?.value ?? '',
                key: el?.key.toString(),
              })),
              InvoicesFilterKeys.KsefNumbers,
            );
          }}
          itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
          addButtonLabel={intl.formatMessage({id: 'addAnother'})}
          inputWidth={InputWidth.FULL}
          testId="invoiceFiltersKsefNumbersInput"
          preventUpdateOnError={true}
          disableValidation
        />
      </Accordion>
      {invoiceBound === InvoiceBound.Internal && (
        <Accordion
          group={'KSeFStatuses'}
          size={AccordionSize.SM}
          title={
            intl.formatMessage({id: Tr.invoiceKsefStatus}) +
            (ksefStatus?.values !== undefined && ksefStatus?.values?.length !== 0
              ? ' (' + ksefStatus?.values.length + ')'
              : '')
          }
          expanded={!!state.pillsList.get(InvoicesFilterKeys.KSeFStatuses)?.values}
          testId="invoiceFilterKsefStatusesAccordion"
        >
          <MultiSelectFilter
            options={convertToMapDropdownListOption(getDefaultKsefStatusesList(), el => ({
              label: el.value,
              value: el.key,
            }))}
            items={convertToDropdownListOption(ksefStatus?.values, el => ({
              label: el,
              value: el,
            }))}
            onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
              state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.KSeFStatuses)
            }
            testId="invoiceFiltersKsefStatusesSelect"
            useSearch
          />
        </Accordion>
      )}
      {stateMobx.invoicePageState.documentsState.earchiveState.moduleStatusStore.getModule(ModulesEnum.autoPosting)
        ?.isModulePurchased && (
        <Accordion
          group={'registrationNumber'}
          size={AccordionSize.SM}
          title={
            intl.formatMessage({id: Tr.registrationNumber}) +
            (registrationNumber?.values !== undefined && registrationNumber?.values?.length !== 0
              ? ' (' + registrationNumber?.values.length + ')'
              : '')
          }
          expanded={!!registrationNumber?.values.length}
          testId="invoiceFiltersRegistrationNumberAccordion"
        >
          <MultipleTextInputFilter
            filterItems={
              registrationNumber?.pills !== undefined && registrationNumber?.pills?.length > 0
                ? convertToFilterItems(registrationNumber?.pills, el => ({
                    value: el.value,
                    key: el.key,
                  }))
                : undefined
            }
            setFilterItems={(items?: FilterItems) => {
              state.handleSelectByValue(
                convertToMultiSelectType(items, el => ({
                  value: el?.value ?? '',
                  key: el?.key.toString(),
                })),
                InvoicesFilterKeys.RegistrationNumber,
              );
            }}
            itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
            addButtonLabel={intl.formatMessage({id: 'addAnother'})}
            inputWidth={InputWidth.FULL}
            testId="invoiceFiltersRegistrationNumberInput"
            preventUpdateOnError={true}
            disableValidation
          />
        </Accordion>
      )}
      {stateMobx.invoicePageState.documentsState.earchiveState.moduleStatusStore.getModule(ModulesEnum.autoPosting)
        ?.isModulePurchased && (
        <Accordion
          group={'invoicePostingStatus'}
          size={AccordionSize.SM}
          title={
            intl.formatMessage({id: Tr.invoicePostingStatus}) +
            (invoicePostingStatus?.values !== undefined && invoicePostingStatus?.values?.length !== 0
              ? ' (' + invoicePostingStatus?.values.length + ')'
              : '')
          }
          expanded={!!state.pillsList.get(InvoicesFilterKeys.InvoicePostingStatuses)?.values}
          testId="invoiceFiltersInvoicePostingStatusAccordion"
        >
          <MultiSelectFilter
            options={convertToMapDropdownListOption(
              getDefaultPostingStatusesList().filter(el => el.key !== 'UNDEFINED'),
              el => ({
                label: el.value,
                value: el.key,
              }),
            )}
            items={convertToDropdownListOption(
              invoicePostingStatus?.values,
              el => ({
                label: el,
                value: el,
              }),
            )}
            onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
              state.handleSelectWithTypeConversion(selected, InvoicesFilterKeys.InvoicePostingStatuses)
            }
            testId="invoiceFiltersInvoicePostingStatusSelect"
          />
        </Accordion>
      )}
    </>
  );
});
