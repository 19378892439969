import {FC, PropsWithChildren, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {authState} from '@symfonia-ksef/state/rootRepository';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';

export const AuthGuard: FC<PropsWithChildren> = observer(({children}) => {
  const {isAuthenticated} = authState;
  useEffect(() => {
    if (!isAuthenticated) {
      authState.handleAuth().catch((err) => authState.handleAuthProcessingError(err));
    }
  }, [isAuthenticated]);

  return <>
    {
      isAuthenticated
      ? <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      : <UnauthenticatedTemplate/>
    }
  </>;
});
