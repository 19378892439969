import {FormattedMessage} from "react-intl";

interface InsideTextProps {
  id: string;
  isAdmin: boolean;
  available: number | string;
  max: number | string;
  percent: string;
}

export const InsideText = ({id, isAdmin, available, max, percent}: InsideTextProps) => (
    <div className="flex flex-col items-center">
      <FormattedMessage id={id}/>
      <div className="flex flex-wrap justify-center items-center">
        {isAdmin ? (
          <>
            <p className="font-bold">{available}/</p>
            <p className="font-bold">{max}</p>
          </>
        ) : (
          <p className="font-bold">{percent}%</p>
        )}
      </div>
    </div>
  );