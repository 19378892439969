import {observer} from 'mobx-react-lite';
import {FilehubAlert} from '../components/FilehubAlert';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {FilehubFile} from '../modals/FilehubAddAttachmentModal';
import {AlertVariant} from '@symfonia/brandbook';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {redirectToSymfoniaShop} from '../../common/helpers/redirectToSymfoniaShop';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {returnStringFromRegex} from './utils';
import {fileExtensionRegex} from './extensions';
import {noop} from 'lodash';

type FilehubAlertBuilderProps = {
  containsFolder: boolean;
  filesWithExceededFileSize: FilehubFile[];
  invalidFiles: FilehubFile[];
  attachmentsContainsFolder: FilehubFile[];
  filesWithInvalidCharacters: FilehubFile[];
  invalidCharacters: string[];
  state: IFilehubState;
};

const FilehubFileUploaderAlertBuilder = observer(
  ({
    containsFolder = false,
    filesWithExceededFileSize = [],
    invalidFiles = [],
    attachmentsContainsFolder = [],
    filesWithInvalidCharacters = [],
    invalidCharacters = [],
    state,
  }: FilehubAlertBuilderProps) => {

    const areInvalidFiles = invalidFiles.length !== 0;
    const containsInvalidCharacters = filesWithInvalidCharacters.length !== 0;
    const containsExceededFileSize = filesWithExceededFileSize.length !== 0;
    const attachmentAddedSuccess = state.successfulAddRequest === true;
    const attachmentAddedHasStatus = state.successfulAddRequest !== null;

    if (!attachmentAddedHasStatus) {
      if (!areInvalidFiles && !containsFolder && !containsInvalidCharacters && !containsExceededFileSize) return <></>;
    }

    const moreThanOneFileAdded = state.addAttachmentsList.length > 1;
    const hasSizeFilesError = state.errorSizeFileList.length > 0;
    const hasMultipleSizeFilesError = state.errorSizeFileList.length > 1;
    const moreThanOneInvalidFile = invalidFiles.length > 1;

    const clearRequestAttachments = () => {
      state.setAddAttachmentsList([]);
      state.setSuccessfulAddRequest(null);
      state.setErrorSizeFileList([]);
    };

    const getDescription = (): React.ReactNode => {
      switch (true) {
        case containsFolder:
          return intl.formatMessage(
            {id: Tr.cantAddFolder},
            {
              br: <br/>,
              files: `${attachmentsContainsFolder.map(({file: {name}}) => name)}`,
            },
          );
        case areInvalidFiles:
          return intl.formatMessage(
            {id: moreThanOneInvalidFile ? Tr.cantAddFiles : Tr.cantAddFile},
            {
              b: chunks => <strong>{chunks}</strong>,
              br: <br/>,
              files: `${invalidFiles.map(({file: {name}}) => name)}`,
              format: `${invalidFiles.map(({file: {name}, fileType: {extension}}) => extension || returnStringFromRegex(fileExtensionRegex, name))}`,
            },
          );
        case containsInvalidCharacters:
          return intl.formatMessage(
            {id: Tr.filehubCantAddFileWithInvalidCharacters},
            {
              br: <br/>,
              files: `${filesWithInvalidCharacters.map(({file: {name}}) => name)}`,
              invalidCharacters: invalidCharacters.join(', '),
            },
          );
        case containsExceededFileSize:
          return intl.formatMessage(
            {id: Tr.filehubCantAddFileWithFileSizeExceeded},
            {
              b: chunks => <strong>{chunks}</strong>,
              br: <br/>,
              files: `${filesWithExceededFileSize.map(({file: {name}}) => name)}`,
            },
          );
        case attachmentAddedSuccess:
          return state?.addAttachmentsList.map(file => <div className="mt-[6px] break-all">{file}</div>);
        case hasSizeFilesError:
          return intl.formatMessage(
            {id: hasMultipleSizeFilesError ? Tr.filehubSizeLimitContentMultiple : Tr.filehubSizeLimitContentSingle},
            {
              br: <br/>,
              fileName: `${state?.errorSizeFileList.map(file => file)}`,
              b: chunks => <strong>{chunks}</strong>,
              link: chunks => (
                <a
                  href="aa"
                  onClick={() => redirectToSymfoniaShop(earchiveState.packageStatistics)}
                  className="font-bold text-red cursor-pointer"
                >
                  {chunks}
                </a>
              ),
            },
          );
      }
      return null;
    };
    const getTitle = (): string => {
      switch (true) {
        case containsFolder:
          return intl.formatMessage({id: Tr.uploadFoldersProhibited});
        case areInvalidFiles:
          return intl.formatMessage({id: Tr.formatOfFile});
        case containsInvalidCharacters:
          return intl.formatMessage({id: Tr.filehubUploadFilesWithInvalidCharacters});
        case containsExceededFileSize:
          return intl.formatMessage({id: Tr.filehubUploadFilesWithFileSizeExceeded});
        case attachmentAddedHasStatus:
          return attachmentAddedSuccess
            ? moreThanOneFileAdded
              ? intl.formatMessage({id: Tr.attachmentsAddedSuccess})
              : intl.formatMessage({id: Tr.attachmentAddedSuccess})
            : moreThanOneFileAdded
              ? intl.formatMessage({id: Tr.attachmentsAddedError})
              : intl.formatMessage({id: Tr.attachmentAddedError});
        case hasSizeFilesError:
          return intl.formatMessage({id: Tr.filehubSizeLimitHeader});
      }

      return '';
    };

    return (
      <FilehubAlert
        className="mt-[16px]"
        description={getDescription()}
        title={getTitle()}
        variant={attachmentAddedSuccess ? AlertVariant.SUCCESS : AlertVariant.ERROR}
        onClose={attachmentAddedHasStatus ? clearRequestAttachments : noop}
      />
    );
  },
);

export default FilehubFileUploaderAlertBuilder;
