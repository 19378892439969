import {ButtonPrimary, ButtonPrimaryVariant, ButtonSecondary, Modal} from '@symfonia/brandbook';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {observer} from 'mobx-react-lite';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useFilehubMethods} from '../table/useFilehubMethods';
import {useState} from 'react';
import {intlFunction} from '../utils/utils';
import {InvoicesService} from '../../earchive/modules/Invoices/Invoices.service';

type Props = {
  clearSelection: () => void;
  invoicesService: InvoicesService;
  state: IFilehubState;
};

const FilehubDeleteAttachmentModal = observer(({clearSelection, invoicesService, state}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {deleteAttachmentAction} = useFilehubMethods(state, clearSelection);
  const moreThanOne = state.deleteAttachmentsIdsList.length > 1;

  const handleModalClose = () => {
    state.setIsDeleteAttachmentModalOpen(false);
  };

  const deleteAttachments = async () => {
    setIsLoading(true);

    try {
      await deleteAttachmentAction(state.deleteAttachmentsIdsList);
      await invoicesService.repository.fetch();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      state.setIsDeleteAttachmentModalOpen(false);
    }
  };

  return (
    <Modal
      className="w-[600px] p-[16px]"
      onClose={handleModalClose}
      show={state.isDeleteAttachmentModalOpen}
      title={intl.formatMessage({id: Tr.deletingAttachments})}
    >
      <div className="flex flex-col gap-[24px]">
        <div>{intlFunction(moreThanOne ? Tr.confirmDeletingAttachments : Tr.confirmDeletingAttachment)}</div>
        <div className="break-all">
          {state.deleteAttachmentsList.map(attachment => (
            <>
              <strong>{attachment.FileName}</strong>
              <br/>
            </>
          ))}
        </div>
        <div className="flex justify-center gap-[16px]">
          <ButtonSecondary
            className="w-full"
            disabled={isLoading}
            onClick={handleModalClose}
            text={intl.formatMessage({id: Tr.cancelButton})}
          />
          <ButtonPrimary
            className="w-full"
            loading={isLoading}
            onClick={deleteAttachments}
            text={intl.formatMessage({id: Tr.removeButton})}
            variant={ButtonPrimaryVariant.RED}
          />
        </div>
      </div>
    </Modal>
  );
});

export default FilehubDeleteAttachmentModal;
