import {Tr} from '@symfonia-ksef/locales/keys';
import {FormattedMessage, useIntl} from 'react-intl';
import {PostingConfigurationActions} from '../components/PostingConfigurationActions';
import {FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {ISettingsState} from './state/ISettingsState';
import {usePostingConfigurationStatus} from '../../../../../../hooks/usePostingConfigurationStatus';
import {Breadcrumbs, CheckboxSize, RadioButtonGroup, Toggle} from '@symfonia/brandbook';
import {Header} from '../../../../../../../root/components/Header';
import {Text} from '../../../../../../../root/components/Text';
import {useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {usePageTitle} from '../../../../../../../common';

export interface SettingsProps {
  state: ISettingsState;
}

export const Settings: FC<SettingsProps> = observer(({state}) => {
  const {setPageTitle} = usePageTitle();
  const intl = useIntl();
  const {getRequiredConfigurationStatusForFinancialYearId} = usePostingConfigurationStatus();

  const {isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);
  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: isPhone ? '...' : intl.formatMessage({id: Tr.automaticDecrees}),
    },
    {
      label: intl.formatMessage({id: Tr.postingSettings}),
    },
  ];

  const checkboxes = [
    {label: intl.formatMessage({id: Tr.possibilityToAcceptAllDecrees}), value: 'true'},
    {label: intl.formatMessage({id: Tr.lackOfPossibilityToAcceptOwnDecrees}), value: 'false'},
  ];

  const handleSave = async () => {
    await Promise.all([state.saveSettings(), earchiveState.company.fetch()]);
  };

  useEffect(() => {
    state.getAutopostingSettings();
    if (state.postingConfigurationState.isFinancialYearActive) getRequiredConfigurationStatusForFinancialYearId();
  }, []);

  setPageTitle(intl.formatMessage({id: Tr.postingSettings}));

  return (
    <div className="flex flex-col justify-between lg:mx-[40px] flex-auto">
      <div className="flex flex-col gap-2 h-full">
        <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'}/>
        <Header className={'flex items-start whitespace-nowrap'}>
          {intl.formatMessage({id: Tr.postingSettingsTitle})}
        </Header>
        <main className="flex flex-col gap-6 justify-start my-6">
          <section className="w-full lg:max-w-1/2 grid gap-col-4 row-gap-0 items-center">
            <Toggle
              value=""
              label={<FormattedMessage id={Tr.autoPostingDocuments}/>}
              onChange={checked => state.setPostingAutomation(checked)}
              checked={state.postingAutomation}
              size={CheckboxSize.SM}
            />
            <div className={'inline-flex mt-[8px]'}>
              <div className="flex-none w-[48px]"/>
              <Text className="ml-[4px]" small>
                <FormattedMessage id={Tr.autoPostingDocumentsDescription} values={{br: <br/>}}/>
              </Text>
            </div>
          </section>
          <section className="w-full lg:max-w-1/2 grid gap-col-4 row-gap-0 items-center mt-4">
            <div className={'col-12'}>
              <Toggle
                value=""
                label={<FormattedMessage id={Tr.confirmDocsBeforeSendingToFK}/>}
                onChange={checked => state.setConfirmDocsBeforeSendingToFK(checked)}
                checked={state.confirmDocsBeforeSendingToFK}
                size={CheckboxSize.SM}
              />
            </div>
            <div className="col-12 inline-flex mt-[8px]">
              <div className="flex-none w-[48px]"/>
              <div className="mt-1 ml-[4px]">
                <RadioButtonGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  checkboxes={checkboxes}
                  disabled={!state.confirmDocsBeforeSendingToFK}
                  name="radio-buttons-group"
                  onChange={value => state.setAcceptAllDecrees(value[0] === 'true')}
                  size={CheckboxSize.SM}
                  value={[`${state.acceptDecrees}`]}
                />
                <div className="mt-[8px]">
                  <Text small>
                    <FormattedMessage id={Tr.confirmDocsBeforeSendingToFKDescription} values={{br: <br/>}}/>
                  </Text>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <PostingConfigurationActions
        onPrimaryButtonClick={handleSave}
        onSecondaryButtonClick={() => state.resetChanges()}
        primaryButtonText={intl.formatMessage({id: Tr.save})}
        secondaryButtonText={intl.formatMessage({id: Tr.cancelButton})}
        primaryButtonDisabled={!state.isModified}
        secondaryButtonDisabled={!state.isModified}
        buttonFixed={true}
      />
    </div>
  );
});
