import React from 'react';
import {Icon, IconColor, IconSize, IconSvg} from '@symfonia/brandbook';
import {dateParser} from '../../helpers';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';

interface PackageWarning {
	enabled: boolean;
	isPackageExpiring: boolean | null;
	subscriptionIsInactive: boolean | null;
	textId?: Tr,
	packageDateIsEnding: boolean | null;
	DateTo?: Date;
	invoicePackageIsEnding: boolean;
	invoicesLeft: number | null;
	storagePackageIsEnding: boolean;
	availableStorageSize: string;
	deactivationDate: Date | null;
	expiringThreshold: number;
}

const PackageWarning = ({
  enabled,
  isPackageExpiring,
  subscriptionIsInactive,
  textId,
  packageDateIsEnding,
  DateTo,
  invoicePackageIsEnding,
  invoicesLeft,
  storagePackageIsEnding,
  availableStorageSize,
  deactivationDate,
	expiringThreshold,
}: PackageWarning) => {
  const {formatMessage} = useIntl();

  const getPackageEndingContent = () => {
    const shouldShowContent = 
      enabled && 
      isPackageExpiring && 
      !subscriptionIsInactive && 
      textId;
  
    if (!shouldShowContent) return '';
  
    const expiryDate = packageDateIsEnding && DateTo ? dateParser(DateTo) : null;
    
    const getInvoiceCount = () => {
      if (!invoicePackageIsEnding) return null;
      return invoicesLeft;
    };
  
    const getDiskSpace = () => {
      if (!storagePackageIsEnding) return null;
      return availableStorageSize;
    };

    return formatMessage({id: textId}, {
      expiryDate,
      invoiceCount: getInvoiceCount(),
      diskSpace: getDiskSpace(),
      percentageLeft: expiringThreshold,
      dateFrom: deactivationDate && dateParser(deactivationDate)
    });
  };

  const packageEndingContent = getPackageEndingContent();

  if (!packageEndingContent) return null;

  return (
    <div className="flex justify-between">
      <Icon svg={IconSvg.INFO} color={IconColor.ORANGE_500} size={IconSize.MD}/>
      <div className="ml-[12px] w-[90%]">{packageEndingContent}</div>
    </div>
  );
};

export default PackageWarning;