import {KSeFStatus, WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {
  NotificationDataParser,
  NotificationDataType,
} from '../../../../../../services/helpers/NotificationDataParsers';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ReactNode} from 'react';
import {AlertConfig} from '../../../../../../services/helpers/AlertService';
import {intl} from '../../../../../root/IntlProvider';
import {
  errorsManager,
  keysTransformers,
} from '../../../../../../services/ErrorHandlerServices/NotificationErrorServices/ErrorsManager';
import {dateParser} from '../../../../../common';
import {ksefEventParserManager} from '@symfonia-ksef/state/KSeFSubscriptionServices/KSeFEventsParsersManager';

const ksefStatus: Record<KSeFStatus, number> = {
  [KSeFStatus.InterruptedPending]: -2,
  [KSeFStatus.InterruptedSending]: -1,
  [KSeFStatus.NotSent]: -0,
  [KSeFStatus.Sending]: 1,
  [KSeFStatus.Pending]: 2,
  [KSeFStatus.Approved]: 3,
  [KSeFStatus.Rejected]: 4,
  [KSeFStatus.Error]: 5,
  [KSeFStatus.Ocr]: 6,
};


const ksefStatusMap = new Map<number, KSeFStatus>(Object.entries(ksefStatus).map(entry => entry.reverse() as [number, KSeFStatus]));

const EVENT_TYPES_TRANSLATIONS: Partial<Record<WebsocketNotificationType, Tr>> = Object.freeze({
  [WebsocketNotificationType.GetUpo]: Tr.GetUPO,
  [WebsocketNotificationType.UnauthorizedInKsef]: Tr.UnauthorizedInKSeF,
  [WebsocketNotificationType.AuthorizeInKsef]: Tr.AuthorizeInKSeF,
  [WebsocketNotificationType.AuthorizeInKsefExternalToken]: Tr.AuthorizeInKSeFExternalToken,
  [WebsocketNotificationType.UnauthorizedInKsefExternalToken]: Tr.UnauthorizedInKSeFExternalToken,
  [WebsocketNotificationType.AutoUnauthorizedInKseFExternalToken]: Tr.AutoUnauthorizedInKSeFExternalToken,
  [WebsocketNotificationType.TerminateSession]: Tr.TerminateSession,
  [WebsocketNotificationType.DownloadInvoices]: Tr.DownloadInvoices,
  [WebsocketNotificationType.GrantPermission]: Tr.PermissionOperation,
  [WebsocketNotificationType.AutoFetchingInvoices]: Tr.AutoFetchingInvoices,
  [WebsocketNotificationType.AutoSendingInvoices]: Tr.AutoSendingInvoices,
  [WebsocketNotificationType.SendingInvoices]: Tr.SendingInvoices,
  [WebsocketNotificationType.RegistrationNumberCreated]: Tr.RegistrationNumberCreated,
  [WebsocketNotificationType.InvoicePostingStatusChanged]: Tr.InvoicePostingStatusChanged,
  [WebsocketNotificationType.UploadInvoices]: Tr.UploadInvoices,
  [WebsocketNotificationType.OcrInvoiceImported]: Tr.OcrInvoiceImported,
  [WebsocketNotificationType.PostInvoicesFailed]: Tr.PostInvoicesFailed,
  [WebsocketNotificationType.DeleteInvoices]: Tr.deleteInvoiceFinished,
  [WebsocketNotificationType.WhiteListValidation]: Tr.whiteListValidation,
  [WebsocketNotificationType.SyncPermission]: Tr.SyncPermissionKSeF,
});

export function getNotificationTypeName(notification: Pick<WebSocketNotificationFragment, 'Type'>): string {
  return intl.formatMessage({id: EVENT_TYPES_TRANSLATIONS[notification.Type] ?? Tr.unknownNotificationType});
}

export abstract class KSeFEventsConverter<T extends WebsocketNotificationType = WebsocketNotificationType, P = any> {
  protected readonly parser!: NotificationDataParser<T>;

  protected constructor(protected readonly type: T, protected readonly event: WebSocketNotificationFragment) {
    if (this.type !== this.event.Type) {
      throw Error(`Incompatible event parser type. Event has to be type of ${this.type} but it is ${this.event.Type}`);
    }
    const parser = ksefEventParserManager.getParser(this.event.NotificationId, this.type);
    if (!parser) {
      throw Error(`Event parser for notification with ${this.event.NotificationId} id and ${this.event.Type} type is not found.`);
    }
    this.parser = parser;
    this.parser.setMapper<P>((from) => this.mapper(from));
  }

  public abstract get actionElement(): { icon: string, tooltipText: Tr } | undefined

  public abstract get notification(): ReactNode;

  public get status(): Tr.info | Tr.error {
    return this.model.hasError ? Tr.error : Tr.info;
  }

  public get typeName(): string {
    return getNotificationTypeName(this.event);
  }

  public abstract get model(): { data: NotificationDataType<T> | null, hasError: boolean }

  public static getMappedKSeFStatus(key: number): KSeFStatus {
    return ksefStatusMap.get(key) ?? KSeFStatus.Error;
  }

  public abstract action(): void | Promise<void>

  protected mapper(from: P): NotificationDataType<T> | null {
    return from as NotificationDataType<T> | null;
  }

  protected createNotification(fallback: AlertConfig): ReactNode {
    const {formatMessage} = intl;
    const {
      alertConfig: {
        id,
        values,
        timestamp,
        notification: message,
      },
    } = errorsManager.get(keysTransformers.lowercase(this.event), fallback);
    const time = timestamp && dateParser(new Date(timestamp), true);
    const messageNotification = message ?? formatMessage({id: id}, values);
    if (time) {
      return messageNotification + ` ${formatMessage({id: Tr.ksefRequestLimitTime}, {time})}`;
    }
    return messageNotification;
  }
}
