import {Icon, IconSvg} from '@symfonia/brandbook';
import {getSize} from '../utils/utils';
import {audioExtensions, documentExtensions, imageExtensions, videoExtensions} from '../utils/extensions';
import FilehubFileUploaderNotificationBuilder from '../utils/FilehubFileUploaderNotificationBuilder';
import {FilehubFile} from '../modals/FilehubAddAttachmentModal';

type Props = {
  uploadFile: FilehubFile;
  handleRemoveFile: (deletedId: string) => void;
};

const getIconForFileType = (fileName: string): IconSvg => {
  const extension = fileName.split('.').pop()?.toLowerCase();

  if (!extension) return IconSvg.LIBRARY_BOOKS;

  if (documentExtensions.includes(extension)) return IconSvg.PICTURE_AS_PDF;
  if (imageExtensions.includes(extension)) return IconSvg.IMAGE;
  if (videoExtensions.includes(extension)) return IconSvg.VIDEO_LIBRARY;
  if (audioExtensions.includes(extension)) return IconSvg.AUDIOTRACK;

  return IconSvg.LIBRARY_BOOKS;
};

const FilehubUploadFile = ({handleRemoveFile, uploadFile}: Props) => {
  const {containsExceededFileSize, containsInvalidCharacters, file, id, fileType} = uploadFile;

  const {isValid, isFolder} = fileType;

  const icon = getIconForFileType(file.name);

  return (
    <>
      <div
        key={id}
        className={`mt-2 py-1 px-3 border border-solid ${
          isValid && !isFolder && !containsInvalidCharacters && !containsExceededFileSize
            ? 'border-grey-200'
            : 'border-red-500'
        } flex justify-between items-center rounded-xl`}
      >
        <div className="flex flex-row gap-[26px] items-center">
          <div className="flex-none w-6">
            <div className="bg-grey-100 p-[6px] w-[40px] h-[40px] rounded-full overflow-hidden flex items-center justify-center">
              <Icon svg={icon}/>
            </div>
          </div>
          <div>
            <div className="break-all font-semibold">{file.name}</div>
            <div>{getSize(file.size)}</div>
          </div>
        </div>
        <div className="flex items-center cursor-pointer">
          <Icon className="p-[2px] w-[30px] h-[30px]" onClick={() => handleRemoveFile(id)} svg={IconSvg.CLOSE}/>
        </div>
      </div>
      <FilehubFileUploaderNotificationBuilder
        fileType={fileType}
        containsExceededFileSize={containsExceededFileSize}
        containsInvalidCharacters={containsInvalidCharacters}
        file={file}
      />
    </>
  );
};

export default FilehubUploadFile;
