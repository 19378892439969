import {action, computed, IObservableArray, makeObservable, observable, runInAction, when} from 'mobx';
import {
  AccountResponse,
  AccountTypeEnum,
  CategoryViewModel,
  DecreeResponse,
  InvoiceDocumentTypeResponse,
  InvoiceTypeGroupEnum,
  VatRegistriesResponse,
} from '@symfonia-ksef/graphql';
import {GetDecreeDetailsQueryFunction} from '../queries/GetDecreeDetailsQueryFunction';
import {IPostingDetailsState} from './IPostingDetailsState';
import {IPostingDetailsStore} from './IPostingDetailsStore';
import {GetDocumentTypesQueryFunction} from '../queries/GetDocumentTypesQueryFunction';
import {GetVatRegistriesQueryFunction} from '../queries/GetVatRegistriesQueryFunction';
import {GetRecalculatedDecreeQueryFunction} from '../queries/GetRecalculatedDecreeQueryFunction';
import {GetAccountsForPostingQueryFunction} from '../queries/GetAccountsForPostingQueryFunction';
import {GetCategoriesQueryFunction} from '../queries/GetCategoriesQueryFunction';
import {intl} from '../../../../root/IntlProvider';
import {MultiSelectModel} from '@symfonia/symfonia-ksef-components';
import {Tr} from '@symfonia-ksef/locales/keys';
import {TreeViewDropdownModel} from '../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {convertToTreeViewDropdownModel} from '../../../../common/helpers/baseFilterHelpers';

export class PostingDetailsStore implements IPostingDetailsStore {
  @observable
  public postingDetails: DecreeResponse | undefined;

  @observable
  public vatRegistries: IObservableArray<VatRegistriesResponse> = observable([]);

  @observable
  public accounts: IObservableArray<AccountResponse> = observable([]);

  @observable
  public categories: IObservableArray<CategoryViewModel> = observable([]);

  @observable
  public documentTypesList: IObservableArray<InvoiceDocumentTypeResponse> = observable([]);

  @computed
  public get isLoading(): boolean {
    return this.requestsInProgress > 0;
  }

  private previousCompanyId: string;

  @observable
  private requestsInProgress: number = 0;

  public postingDetailsState: IPostingDetailsState;

  constructor(postingDetailsState: IPostingDetailsState) {
    makeObservable(this);

    this.postingDetailsState = postingDetailsState;
    this.previousCompanyId = this.postingDetailsState.postingState.earchiveState.companyId;

    when(
      () => this.postingDetailsState.postingState.earchiveState.companyId !== this.previousCompanyId,
      () => this.resetStore(),
    );
  }

  @computed
  public get accountsAsTreeNodes(): TreeViewDropdownModel[] {
    return convertToTreeViewDropdownModel(this.accounts, el => ({
      value: el?.Number + ' ' + el?.Name,
      key: el?.Id,
      isSelectable: el.IsFinal && !el.IsDisabled,
      isDisabled: el.IsDisabled,
      isHidden: false,
      parentId: el?.ParentId,
      childrenIds: el?.ChildrenIds,
    }));
  }

  @action
  public fetchPostingDetail() {
    if (!this.postingDetailsState.decreeId) throw new Error('Decree id must be set');

    this.addRequestInProgress();

    GetDecreeDetailsQueryFunction(
      this.postingDetailsState.decreeId,
      this.postingDetailsState.postingState.earchiveState.companyId,
    )
      .then(data => {
        this.setPostingDetails(data);
        if (data !== undefined) {
          this.fetchDocumentTypes();
          this.postingDetailsState.setIsSalesInvoice(this.postingDetails!.InvoiceTypeGroup!);
        }
      })
      .then(() => {
        if (this.postingDetails?.DocumentType) {
          this.postingDetailsState.changeDocumentType({
            value: this.postingDetails?.DocumentType?.Symbol + ' - ' + this.postingDetails?.DocumentType?.Name ?? '-',
            key: this.postingDetails?.DocumentType?.Id,
          });
        } else {
          const notSelectedOption = {value: intl.formatMessage({id: Tr.choose}), key: ''} as MultiSelectModel;
          this.postingDetailsState.changeDocumentType(notSelectedOption);
        }
      })
      .then(() => this.postingDetailsState.changeStatus(this.postingDetails?.PostingStatus))
      .finally(() => {
        this.removeRequestInProgress();
      });
  }

  @action
  public fetchDocumentTypes() {
    if (!this.postingDetails?.FinancialYearId) throw new Error('FinancialYearId must be set');
    this.addRequestInProgress();
    GetDocumentTypesQueryFunction(
      this.postingDetails?.FinancialYearId,
      this.postingDetailsState.postingState.earchiveState.companyId,
      this.postingDetails?.InvoiceTypeGroup ?? InvoiceTypeGroupEnum.Purchase,
    )
      .then(data => {
        this.documentTypesList.replace(data);
      })
      .finally(() => {
        this.removeRequestInProgress();
      });
  }

  @action
  public async fetchPostingVatRegistries() {
    this.addRequestInProgress();

    try {
      const data = await GetVatRegistriesQueryFunction(this.postingDetailsState.postingState.earchiveState.companyId);
      this.setVatRegistries(data);
    } finally {
      this.removeRequestInProgress();
    }
  }

  @action
  public fetchRecalculatedDecree() {
    if (!this.postingDetailsState.decreeId) throw new Error('Decree id must be set');
    this.addRequestInProgress();
    GetRecalculatedDecreeQueryFunction(this.postingDetailsState.postingState.earchiveState.companyId, {
      DecreeId: this.postingDetailsState.decreeId,
      CompanyId: this.postingDetailsState.postingState.earchiveState.companyId,
      CategoryAccountChanges: this.postingDetailsState.categoryAccountChangeArray,
      PositionCategoryChanges: this.postingDetailsState.positionCategoryChangeArray,
      PositionAccountChanges: this.postingDetailsState.positionAccountChangesArray,
      PositionVehicleUsageTypeChanges: this.postingDetailsState.positionVehicleUsageTypeChangeArray,
    })
      .then(decree => {
        if (decree) {
          this.setPostingDetails(decree);
        }
      })
      .finally(() => {
        this.removeRequestInProgress();
      });
  }

  @action
  public async fetchAccounts(accountType: AccountTypeEnum) {
    this.addRequestInProgress();
    if (!this.postingDetailsState.decreeId) throw new Error('Decree id must be set');
    return await GetAccountsForPostingQueryFunction(
      this.postingDetailsState.decreeId,
      this.postingDetailsState.postingState.earchiveState.companyId,
      accountType,
    ).then(accounts => {
      if (accounts) {
        this.setAccounts(accounts);
      }
    })
    .finally(() => {
      this.removeRequestInProgress();
    });
  }

  @action
  public async fetchCategories() {
    return await GetCategoriesQueryFunction(this.postingDetailsState.postingState.earchiveState.companyId, this.postingDetails?.FinancialYearId).then(
      categories => {
        if (categories) {
          this.categories.replace(categories);
        }
      },
    );
  }

  @action
  private setPostingDetails(details: DecreeResponse) {
    this.postingDetails = details;
  }

  @action
  private setVatRegistries(registries: VatRegistriesResponse[]) {
    this.vatRegistries.replace(registries);
  }

  @action
  private setAccounts(accounts: AccountResponse[]) {
    this.accounts.replace(accounts);
  }

  @action
  private resetStore() {
    this.accounts.clear();
    this.categories.clear();
    this.vatRegistries.clear();
    this.postingDetails = undefined;
  }

  /**
   * Use only in fetch methods that should block interactivity until finished
   */
  private addRequestInProgress() {
    runInAction(() => {
      this.requestsInProgress++;
    });
  }

  private removeRequestInProgress() {
    runInAction(() => {
      this.requestsInProgress > 0 ? this.requestsInProgress-- : (this.requestsInProgress = 0);
    });
  }
}
