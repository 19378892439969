import React, {FC, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {ButtonTertiary, Spinner} from '@symfonia/brandbook';
import image from '../images/icon.png';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {observer} from 'mobx-react-lite';
import {FilehubFile} from '../modals/FilehubAddAttachmentModal';
import {FormattedMessage} from 'react-intl';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {useGetAttachmentSettingsQuery} from '@symfonia-ksef/graphql';
import {
  checkContainsInvalidCharacters,
  forbiddenExtensions,
  invalidCharacters,
  validFileTypes,
} from '../utils/extensions';
import {checkIsFolder, checkFileSizeExceeded} from '../utils/utils';
import FilehubUploadFile from './FilehubUploadFile';
import FilehubFileUploaderAlertBuilder from '../utils/FilehubFileUploaderAlertBuilder';
import {fileTypeFromBlob} from 'file-type';
import {intl} from '../../root/IntlProvider';

export enum FilehubUploadFileWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
}

export type FilehubUploadFileProps = {
  className?: string;
  width?: FilehubUploadFileWidth;
  setFiles: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  files: FilehubFile[];
  setInvalidFiles: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  setAttachmentsContainsFolder: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  setFilesWithInvalidCharacters: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  setFilesWithExceededFileSize: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  invalidFiles: FilehubFile[];
  attachmentsContainsFolder: FilehubFile[];
  filesWithInvalidCharacters: FilehubFile[];
  filesWithExceededFileSize: FilehubFile[];
  state: IFilehubState;
  isLoading: boolean;
};

export const FilehubFileUploader: FC<FilehubUploadFileProps> = observer(
  ({
    className = undefined,
    setFiles,
    setInvalidFiles,
    setAttachmentsContainsFolder,
    setFilesWithInvalidCharacters,
    setFilesWithExceededFileSize,
    files,
    invalidFiles,
    attachmentsContainsFolder,
    filesWithInvalidCharacters,
    filesWithExceededFileSize,
    isLoading,
    state,
    width = FilehubUploadFileWidth.BASE,
  }) => {
    const [dragActive, setDragActive] = useState<boolean>(true);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const {
      company: {companyId},
    } = earchiveState;

    const {length: allFilesLength} = files;
    const {length: allInvalidFilesLength} = invalidFiles;
    const {length: allContainsFolderLength} = attachmentsContainsFolder;
    const {length: allContainsInvalidCharactersLength} = filesWithInvalidCharacters;
    const {length: allContainsExceededFileSizeLength} = filesWithExceededFileSize;
    const areFiles = allFilesLength > 0;
    const areInvalidFiles = allInvalidFilesLength !== 0;
    const containsFolder = allContainsFolderLength !== 0;
    const containsInvalidCharacters = allContainsInvalidCharactersLength !== 0;
    const containsExceededFileSize = allContainsExceededFileSizeLength !== 0;
    const isUploadingDisabled =
      areInvalidFiles || containsFolder || containsInvalidCharacters || containsExceededFileSize;

    useEffect(() => {
      const invalidFiles = files.filter(({fileType: {isValid}}) => !isValid);
      setInvalidFiles(invalidFiles);
      const containsFolder = files.filter(({fileType: {isFolder}}) => isFolder);
      setAttachmentsContainsFolder(containsFolder);
      const containsInvalidCharacters = files.filter(({containsInvalidCharacters}) => containsInvalidCharacters);
      setFilesWithInvalidCharacters(containsInvalidCharacters);
      const containsExceededFileSize = files.filter(({containsExceededFileSize}) => containsExceededFileSize);
      setFilesWithExceededFileSize(containsExceededFileSize);
    }, [files]);

    const {data, loading} = useGetAttachmentSettingsQuery({
      context: {
        envId: companyId,
      },
    });

    const {GetAttachmentSettings: {AllowedAttachmentMimeTypes: allowedAttachmentMimeTypes = validFileTypes} = {}} =
      data || {};

    const checkFileType = async (file: File) => {
      const isFolder = await checkIsFolder(file);

      if (isFolder) {
        return {
          isFolder,
          isValid: true,
          mimeType: "folder",
          extension: "folder",
        };
      }

      const fileMIMETypeObject = await fileTypeFromBlob(file);

      const {name, type} = file;

      const {mime, ext} = fileMIMETypeObject || {};

      const isValidExtension = !forbiddenExtensions.some(extension => name.endsWith(extension));
      const isValidFileType =
      mime === undefined
          ? allowedAttachmentMimeTypes.some(allowedType => allowedType === type)
          : allowedAttachmentMimeTypes.some(allowedType => allowedType === mime);
      
      return {
        isFolder,
        isValid: isValidExtension && isValidFileType,
        mimeType: mime,
        extension: ext && `.${ext}`  
      };
    };

    const handleDrag = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };

    const handleUpload = async (files: FileList) => {
      const addedFiles = await Promise.all(
        Array.from(files).map(async file => ({
          file,
          id: crypto.randomUUID(),
          fileType: await checkFileType(file),
          containsInvalidCharacters: checkContainsInvalidCharacters(file.name),
          containsExceededFileSize: checkFileSizeExceeded(file.size),
        })),
      );

      setFiles(currentFiles => [...currentFiles, ...addedFiles]);
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (areInvalidFiles) return;

      setDragActive(false);

      if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
        handleUpload(e.dataTransfer.files);
      }
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (e.target.files && e.target.files[0]) {
        await handleUpload(e.target.files);
        e.target.value = '';
      }
    };

    const onButtonClick = () => {
      inputRef.current?.click();
    };

    const handleRemoveFile = (idDeleted: string) => {
      if (!isLoading) {
        setFiles(files => files.filter(({id}) => id !== idDeleted));
      }
    };

    const styles = {
      component: clsx(className, {
        'w-full': width === FilehubUploadFileWidth.FULL,
        'w-fit': width === FilehubUploadFileWidth.FIT,
        'w-[419px]': width === FilehubUploadFileWidth.BASE,
      }),
    };

    return (
      <div className={styles.component}>
        <div
          className="py-4 flex flex-col items-center border border-dashed border-grey-300 rounded-2xl font-quicksand mt-2"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          id="drag-file-element"
        >
          {!loading && (
            <>
              <img src={image} alt="" draggable={false}/>
              <span className="text-base font-bold">{intl.formatMessage({id: Tr.dragAndDropHere})}</span>
              <span>
                <form id="form-file-upload" onDragEnter={e => handleDrag(e)} onSubmit={e => e.preventDefault()}>
                  <input
                    disabled={isUploadingDisabled}
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple
                    onChange={e => handleChange(e)}
                    style={{display: 'none'}}
                  />
                  <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
                    {intl.formatMessage({id: Tr.or})} <ButtonTertiary disabled={isUploadingDisabled} text={intl.formatMessage({id: Tr.chooseFile})} onClick={onButtonClick}/>
                  </label>
                </form>
              </span>
            </>
          )}
          {loading && (
            <div className="w-full h-[230px] flex justify-center items-center">
              <div>
                <Spinner/>
              </div>
            </div>
          )}
        </div>
        {areFiles && (
          <div className="flex justify-end my-[12px]">
            <div>
              <FormattedMessage
                id={Tr.numberOfAddedDocuments}
                values={{
                  countDocuments: (
                    <strong>
                      {allFilesLength - allInvalidFilesLength}/{allFilesLength}
                    </strong>
                  ),
                }}
              />
            </div>
          </div>
        )}
        <div className="mt-[12px]">
          {areFiles && files.map(file => <FilehubUploadFile uploadFile={file} handleRemoveFile={handleRemoveFile}/>)}
        </div>
        <FilehubFileUploaderAlertBuilder
          containsFolder={containsFolder}
          filesWithExceededFileSize={filesWithExceededFileSize}
          invalidFiles={invalidFiles}
          attachmentsContainsFolder={attachmentsContainsFolder}
          filesWithInvalidCharacters={filesWithInvalidCharacters}
          invalidCharacters={invalidCharacters}
          state={state}
        />
      </div>
    );
  },
);
