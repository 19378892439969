import {observer} from 'mobx-react-lite';
import React, {FC, useCallback, useState} from 'react';
import {ButtonTertiary, ButtonTertiarySize, Icon, IconColor, IconSize, IconSvg, IconVariant, InputWidth, Tooltip} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {FormattedMessage, useIntl} from 'react-intl';
import {PermissionsService} from '../Permissions.service';
import _ from 'lodash';
import {
  FilterItems,
  usePrevious,
  Accordion,
  AccordionSize,
  FiltersDrawer,
  MultipleTextInputFilter,
  MultiSelectFilter,
} from '@symfonia/symfonia-ksef-components';
import {convertToDropdownListOption, convertToFilterItems, convertToMultiSelectType} from '../../../../common/helpers/baseFilterHelpers';
import {PermissionsFilterKeys} from './PermissionsFiltersState';
import {FiltersPills} from '../../../../root/components/FiltersPills';
import {SearchInput} from '../../../../root/components/SearchInput';
import {useDownloadPermissionsReport} from '../../../hooks/useDownloadPermissionsReport';
import {DownloadReport, OnReportConfirm} from '../../../components/DownloadReport/DownloadReport';
import {FiltersBar} from '../../../components/FiltersBar/FiltersBar';
import {syncPermissionKSeFService} from '@symfonia-ksef/state/rootRepository';

export const PermissionsFilters: FC<{ page: PermissionsService }> = observer(({page}) => {
  const intl = useIntl();
  const {syncHour, syncDate, isAnySyncDateAvailable} = page;
  const {download} = useDownloadPermissionsReport(page.filters, page.searchService.searchValue);

  const handleFilter = useCallback(() => {
    setMainFilterMenuOpen(false);
    page.filters.startFiltering();
    page.filters.storage.saveToStorage(page.filters.activeFilters);
    page.repository.fetch();
  }, [page]);

  const clearFilters = useCallback(() => {
    page.filters.handleClearMenuFilter();
    page.repository.fetch();
  }, [page]);

  const previousFilters = usePrevious(page.filters.getActiveFilters);
  const wasFilterChanged = !_.isEqual(_.flatMap(previousFilters), _.flatMap(page.filters.getChangedFilters));
  const rowsActive = !!page.tableService.rows.length;
  const [mainFilterMenuOpen, setMainFilterMenuOpen] = useState<boolean>(false);
  const downloadActive = rowsActive && !page.repository.loading;

  const handleOpenAndLoad = useCallback((v: boolean) => {
    setMainFilterMenuOpen(v);
  }, []);

  const credentials = page.filters.activeFilters.get(PermissionsFilterKeys.Credentials);
  const identifiers = page.filters.activeFilters.get(PermissionsFilterKeys.Identifiers);

  const onConfirm = useCallback<OnReportConfirm>((reportType) => {
    reportType && download({reportFileType: reportType});
  }, [download]);

  return <>
    <FiltersDrawer
      hasError={page.filters.hasError}
      isFiltersMenuOpened={mainFilterMenuOpen}
      openFiltersMenu={handleOpenAndLoad}
      handleFilter={handleFilter}
      clearFilters={clearFilters}
      loading={page.repository.loading}
      tableLabel={intl.formatMessage({id: Tr.tableLabel})}
      closeLabel={intl.formatMessage({id: Tr.closeLabel})}
      filterLabel={intl.formatMessage({id: Tr.filterLabel})}
      clearButtonLabel={intl.formatMessage({id: Tr.clearAllLabel})}
      filtersTouched={wasFilterChanged}
      filtersModified={wasFilterChanged}
      onClose={() => {
        setMainFilterMenuOpen(false);
        page.filters.resetFilterToStorage();
      }}
    >
      <Accordion
        group={PermissionsFilterKeys.Credentials}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.permissions}) +
          (credentials?.values?.length ? ' (' + credentials?.values.length + ')' : '')}
        expanded={!!credentials?.values.length}
      >
        <MultiSelectFilter
          options={page.filters.credentialsDropdownMap}
          items={convertToDropdownListOption(credentials?.values, credential => ({
            value: credential,
            label: credential,
          }))}
          onSelected={(selected) => page.filters.handleSelectWithTypeConversion(selected, PermissionsFilterKeys.Credentials)}
          useSearch
        />
      </Accordion>
      <Accordion
        group={PermissionsFilterKeys.Identifiers}
        size={AccordionSize.SM}
        title={intl.formatMessage({id: Tr.forWho}) + (identifiers?.values?.length ? ' (' + identifiers?.values.length + ')' : '')}
        expanded={!!identifiers?.values.length}
      >
        <MultipleTextInputFilter
          itemLabel={intl.formatMessage({id: Tr.NIPPesel})}
          filterItems={identifiers?.pills?.length
            ? convertToFilterItems(identifiers.pills,
              el => ({
                value: el.value,
                key: el.key,
              }),
            )
            : undefined}
          setFilterItems={(items?: FilterItems) => {
            page.filters.handleSelectByValue(convertToMultiSelectType(items, el => ({
                value: el?.value ?? '',
                key: el?.key.toString(),
              }),
            ), PermissionsFilterKeys.Identifiers);
          }}
          itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
          addButtonLabel={intl.formatMessage({id: 'addAnother'})}
          inputWidth={InputWidth.FULL}
          validationPattern={/^\d+$/}
          preventUpdateOnError
          setValidationError={page.filters.setHasError}
          hasError={page.filters.hasError}
        />
      </Accordion>
    </FiltersDrawer>
    <div className="flex items-center flex-wrap">
      <div className="max-width-[100px] mr-[8px] my-[10px]">
        <SearchInput translationKey={Tr.search} service={page.searchService}/>
      </div>
      <FiltersBar
        isFiltersMenuOpened={mainFilterMenuOpen}
        openFiltersMenu={handleOpenAndLoad}
        tableLength={page.tableService.rows.length}
        filtersApplied={wasFilterChanged}
        testId='permissionsFiltersMenuOpenButton'
      />
      {page.filters.filtersIsActive && <ButtonTertiary
        className="mx-[8px] mt-[4px]"
        size={ButtonTertiarySize.SM}
        onClick={() => clearFilters()}
        text={intl.formatMessage({id: Tr.clearFiltersLabel})}
        lIcon={IconSvg.CLOSE}
        testId='permissionsFiltersClearButton'
      />}
      <DownloadReport
        onConfirm={onConfirm}
        disabled={!downloadActive}
        filtersRequired={false}
      />
      <div className="ml-auto flex items-center">
        <ButtonTertiary
          testId="syncWithKSeFButton"
          text={intl.formatMessage({id: Tr.SyncWithKSeF})}
          onClick={() => syncPermissionKSeFService.jobRunner.fetch()}
          className={'[&>span]:truncate max-w-100% sm:w-auto w-[160px] text-left'}
          loading={syncPermissionKSeFService.jobRunner.loading || syncPermissionKSeFService.jobRunner.isPending}
          lIcon={IconSvg.AUTORENEW}
        />
        {isAnySyncDateAvailable && (
          <Tooltip
            position="right"
            text={
              <FormattedMessage
                id={Tr.syncWithKSeFTooltip}
                values={{
                  date: syncDate,
                  hour: syncHour,
                  b: chunks => <strong>{chunks}</strong>,
                }}
              />
            }
          >
          <Icon
            svg={IconSvg.INFO}
            size={IconSize.MD}
            variant={IconVariant.CONTOUR}
            color={IconColor.BLUE1_500}
            className="ml-[10px]"
          />
          </Tooltip>)
        }
      </div>
      <FiltersPills className="w-full" pills={page.filters.pills} maxVisiblePills={5}/>
    </div>
  </>;
});
