import {JobRunnerManager, JobRunnerManagerI} from '../../services/JobRunnerManager';
import {SyncPermissionKSeFMutationVariables} from '@symfonia-ksef/graphql';
import {EventJobRunnerI} from '../../services/EventJobRunner';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';
import {SyncPermissionKSeFJobRunner} from '../../services/KSeFJobRunners/SyncPermissionKSeFJobRunner';

export class SyncPermissionKSeFService {
  private jobRunnerManager: JobRunnerManagerI<SyncPermissionKSeFMutationVariables>;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    this.jobRunnerManager = new JobRunnerManager<SyncPermissionKSeFMutationVariables>(() => new SyncPermissionKSeFJobRunner(envObserver, earchiveState), envObserver);
  }

  public get jobRunner(): EventJobRunnerI<SyncPermissionKSeFMutationVariables> {
    return this.jobRunnerManager.jobRunner;
  }
}
