import React, {ReactElement, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {ExtendedTableServiceI} from '../services/TableServices/ExtendedTableService';
import {
  Dropdown,
  DropdownListOptions,
  DropdownSize,
  DropdownWidth,
  EmptyTable,
  Pagination,
  TableRow,
  TableRowHeight,
  TableSort,
} from '@symfonia/brandbook';
import {AnyObject} from 'yup/es/types';
import {BaseVariables} from '../services/TableServices/DataSourceTableService';
import {LinearProgress} from '@mui/material';
import '../../../styles.scss';
import {Table} from '@symfonia/symfonia-ksef-components';

export type ExtendedTableProps<
  Row extends AnyObject,
  Key extends string = string,
  Data extends AnyObject = AnyObject,
  Variables extends BaseVariables = BaseVariables,
  Context extends AnyObject = AnyObject,
> = {
  service: ExtendedTableServiceI<Row, Key, Data, Variables, Context>;
  rowHeight?: TableRowHeight;
  loaderEnabled?: boolean;
  className?: string;
  bottomComponent?: ReactElement;
  emptyTableText?: string;
};
export const ExtendedTable = observer(
  <
    Row extends AnyObject,
    Key extends string = string,
    Data extends AnyObject = AnyObject,
    Variables extends BaseVariables = BaseVariables,
    Context extends AnyObject = AnyObject,
  >({
    service,
    rowHeight,
    loaderEnabled,
    className,
    bottomComponent,
    emptyTableText
  }: ExtendedTableProps<Row, Key, Data, Variables, Context>): JSX.Element => {
    const sizeOptions = useMemo<DropdownListOptions>(
      () =>
        [5, 10, 20, 50].map(size => ({
          label: String(size),
          value: String(size),
        })),
      [],
    );

    const currentSize = useMemo<Array<string>>(() => [String(service.size)], [service.size]);

    const onSortChange = useCallback(
      (sortBy: TableSort) =>
        service.setSorting({
          sortBy,
          resetPagination: true,
        }),
      [service],
    );

    const onSizeChange = useCallback(([value]: string[]) => service.setPagination({size: +value, page: 1}), [service]);

    const onPageChange = useCallback((page: number) => service.setPagination({page}), [service]);

    const rows = useMemo(
      () =>
        service.matrix.length ? (
          service.matrix.map(({row, cells, onClick}) => {
            return (
              <TableRow
                key={String(row.key)}
                children={cells}
                className={service.rowClassName(row)}
                columnClassName="customTableCell"
                onClick={e => onClick?.(row, service.context, e)}
              />
            );
          })
        ) : (
          <TableRow caption>
            <EmptyTable text={emptyTableText}/>
          </TableRow>
        ),
      [service.matrix, service.context],
    );

    const pageChangeHandler = (newPage: number) => {
      const pageCap = service.pagesCapacity || 10;
      const page = Math.min(Math.max(newPage, 1), pageCap);
      onPageChange(page);
    };

    return (
      <div className={className}>
        {loaderEnabled && <div className="h-[4px]">{service.loading && <LinearProgress/>}</div>}
        <div className="mb-[8px] relative">
          <Table
            tableRowHeight={rowHeight ?? TableRowHeight.MD}
            header={service.headers}
            sortBy={service.sortBy}
            onSortChange={onSortChange}
            lastColumnSticky={service.actionIsSticky}
            bottomComponent={bottomComponent}
          >
            {rows}
          </Table>
        </div>
        {service.activatedServices.pagination && service.matrix.length > 0 && (
          <div className="flex justify-between">
            <Dropdown
              size={DropdownSize.SM}
              options={sizeOptions}
              value={currentSize}
              onChange={onSizeChange}
              width={DropdownWidth.FIT}
            />
            <Pagination
              pageCount={service.pagesCapacity ?? 10}
              currentPage={service.currentPage}
              onChange={pageChangeHandler}
            />
          </div>
        )}
      </div>
    );
  },
);
