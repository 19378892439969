import {EventJobRunner, EventJobRunnerI} from '../EventJobRunner';
import {
  DeleteInvoicesDocument,
  DeleteInvoicesMutation,
  DeleteInvoicesMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';
import {computed} from 'mobx';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export interface DeleteInvoicesJobRunnerI {
  invoiceIds: string[];

  run(config?: DeleteInvoicesMutationVariables): Promise<void>;
}

export class DeleteInvoicesJobRunner extends EventJobRunner<DeleteInvoicesMutationVariables, keyof DeleteInvoicesMutation> implements EventJobRunnerI<DeleteInvoicesMutationVariables>, DeleteInvoicesJobRunnerI {

  private prevForce?: boolean;

  private force: boolean = false;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('DeleteInvoices', WebsocketNotificationType.DeleteInvoices, DeleteInvoicesDocument, envObserver, earchiveState);
    this.configureAlerts({
        success: (status) => ({
          id: Tr.deleteInvoiceInProgress,
        }),
        error: (errors, errorMessage) => ({
          id: Tr.deleteInvoicesError,
        }),
      },
    );
  }

  @computed
  public get invoiceIds(): string[] {
    return this.variables.InvoiceIds;
  }

  public async run(config?: DeleteInvoicesMutationVariables): Promise<void> {
    if (this.prevForce === false) {
      this.setForce(true);
    }
    config && this.configure(config);
    await this.fetch();
  }

  private setForce(force: boolean, opt?: { reset: boolean }): this {
    this.prevForce = opt?.reset ? undefined : this.force;
    this.force = force;
    return this;
  }
}
