import React, {FC, PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {Tr} from '@symfonia-ksef/locales/keys';
import {getDecimal, getPackageEndingTooltip} from '../../../common/helpers/packageStatisticsHelper';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import {IconColor, IconSvg} from '@symfonia/brandbook';
import {AccountLimits, IconWithTooltip} from '@symfonia/symfonia-ksef-components';
import {redirectToSymfoniaShop} from '../../../common/helpers/redirectToSymfoniaShop';
import {
  PackageStatisticsRepositoryInterface,
} from '@symfonia-ksef/state/PackageStatisticsState/PackageStatisticsRepository';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import PackageWarning from '../../../common/components/PackageInfo/PackageWarning';


type PackageSubscriptionProps =
  PropsWithChildren
  & { packageStatistics: PackageStatisticsRepositoryInterface, isAdmin: boolean, isDemo: boolean, packageUsageFeedbackEnabled: boolean }

export const PackageSubscription: FC<PackageSubscriptionProps> = observer(({
                                                                             packageStatistics,
                                                                             isAdmin,
                                                                           }) => {
  const {
    state: {DateTo, Items: Packages},
    subscriptionIsActive,
    packageDateIsEnding,
    invoicePackageIsEnding,
    invoicesLeft,
    availableStorageSize,
    storagePackageIsEnding,
    isPackageUsed,
    deactivationDate,
    subscriptionIsInactive,
    enabled,
    loading,
    currentStorageUsage,
    maxStorageSize,
    currentInvoicesUsage,
    currentInvoicesPercentageUsage,
    currentStoragePercentageUsage,
    invoicesPercentageLeft,
    storagePercentageLeft,
    maxInvoicesSize,
    availableInvoicesSize,
    packageThresholds,
    expiringThreshold,
    invoicesPackageUsed,
    storagePackageUsed,
  } = packageStatistics;
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const storageOrSubscriptionNotActive = storagePackageUsed || !subscriptionIsActive;
  const invoicesOrSubscriptionNotActive = invoicesPackageUsed || !subscriptionIsActive;

  const adminUsage = [
    {
      limit: maxInvoicesSize,
      usage: currentInvoicesUsage,
      formatted: {
        usage: invoicesOrSubscriptionNotActive ? 0 : availableInvoicesSize,
        limit: maxInvoicesSize,
      },
      inactive: invoicesOrSubscriptionNotActive,
      lineLabel: intl.formatMessage({id: 'availableInvoices'})
    },
    {
      limit: maxStorageSize.bytes,
      usage: currentStorageUsage.bytes,
      formatted: {
        usage: storageOrSubscriptionNotActive ? '0,00' : availableStorageSize.valueWithoutUnit,
        limit: maxStorageSize.valueWithSpace,
      },
      inactive: storageOrSubscriptionNotActive,
      lineLabel: intl.formatMessage({id: 'availableSpace'})
    },
  ];

  const userUsage = [
    {
      limit: 100,
      usage: currentInvoicesPercentageUsage,
      formatted: {
        usage: `${invoicesOrSubscriptionNotActive ? '0,00' : getDecimal(invoicesPercentageLeft)}%`,
        limit: "100,00%"
      },
      inactive: invoicesOrSubscriptionNotActive,
      lineLabel: intl.formatMessage({id: 'availableInvoices'})
    },
    {
      limit: 100,
      usage: currentStoragePercentageUsage,
      formatted: {
        usage: `${storageOrSubscriptionNotActive ? '0,00' : getDecimal(storagePercentageLeft)}%`,
        limit: "100,00%"
      },
      inactive: storageOrSubscriptionNotActive,
      lineLabel: intl.formatMessage({id: 'availableSpace'})
    },
  ];

  const textContent = getPackageEndingTooltip(isPackageUsed, subscriptionIsActive, invoicePackageIsEnding, storagePackageIsEnding, packageDateIsEnding, isAdmin);
  const isPackageExpiring = invoicePackageIsEnding || storagePackageIsEnding || packageDateIsEnding;
  const {tenantId, companyId} = earchiveState.company;

  const redirect = () => {
    const redirectionLocation = `/earchive/${tenantId}/company/${companyId}/account/tenant`;
    tenantId && companyId && redirectionLocation !== location.pathname && navigate(redirectionLocation);
  };

  const getPackageEndingContent = () => {
    return (
      <div className="mt-[12px] text-xs">
        <PackageWarning
          enabled={enabled}
          textId={textContent?.content}
          isPackageExpiring={isPackageExpiring}
          subscriptionIsInactive={subscriptionIsInactive}
          packageDateIsEnding={packageDateIsEnding}
          DateTo={DateTo}
          invoicePackageIsEnding={invoicePackageIsEnding}
          invoicesLeft={invoicesLeft}
          storagePackageIsEnding={storagePackageIsEnding}
          availableStorageSize={availableStorageSize?.value}
          deactivationDate={deactivationDate}
          expiringThreshold={expiringThreshold}
        />
      </div>
    );
  };

  return <>
    {<>
      <AccountLimits
        dateTo={DateTo}
        dateLabel={intl.formatMessage({id: Tr.activePackageUntil})}
        packageUsage={isAdmin ? adminUsage : userUsage}
        redirect={redirect}
        limitsEnable={!subscriptionIsInactive}
        loading={loading}
        packageEndingContent={getPackageEndingContent()}
        thresholds={packageThresholds}
      />
      {isAdmin &&
        <IconWithTooltip
          tooltipWidth="auto"
          icon={{icon: IconSvg.LOCAL_GROCERY_STORE, color: IconColor.GREY_500}}
          tooltipContent={intl.formatMessage({id: Tr.buyPackage})}
          onClick={() => redirectToSymfoniaShop(earchiveState.packageStatistics)}
        />
      }
    </>}
    <div className="flex-separator"/>
  </>;
});

