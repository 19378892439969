import React, {FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useModule} from '../../../root/services/MobXServices/BaseModule';
import {ExtendedTable} from '../../../root/components/ExtendedTable';
import {CredentialsFragment, GetPermissionsKSeFQuery} from '@symfonia-ksef/graphql';
import {PermissionsService} from './Permissions.service';
import {SinglePermissionActionMenu} from '../../components/Permissions/ActionMenus/SinglePermissionsActionMenu';
import {PermissionsFilters} from './PermissionsFIlters/PermissionsFilters';

type PermissionsViewProps = {
  onEditClicked: (userPermissions: CredentialsFragment, element: HTMLElement) => void;
  onDataUpdate: (data: GetPermissionsKSeFQuery | undefined) => void;
  onLoading?: (loading: boolean) => void;
  isFromKsef: boolean;
  areActionsDisabled?: boolean;
  subscribeToRefresh?: (download: () => void) => (() => void) | undefined;
  setAddEditMode: (isEditMode: boolean) => void,
  setIsEditMode: (isEditMode: boolean) => void,
  isSingleActionMenuVisible: boolean,
  setIsSingleActionMenuVisible: (isEditMode: boolean) => void,
  singleActionAnchorEl: HTMLElement | undefined,
  data: CredentialsFragment,
  setAddSelfInvoicingEditMode: (isAddSelfInvoicingEditMode: boolean) => void,
}

export const PermissionsView: FC<PermissionsViewProps> = observer(({
                                                                     onEditClicked,
                                                                     areActionsDisabled,
                                                                     setIsEditMode,
                                                                     setAddEditMode,
                                                                     isSingleActionMenuVisible,
                                                                     setIsSingleActionMenuVisible,
                                                                     singleActionAnchorEl,
                                                                     data,
                                                                     setAddSelfInvoicingEditMode,
                                                                     subscribeToRefresh
                                                                   }) => {
  const module = useModule(() => new PermissionsService(onEditClicked, areActionsDisabled));

  useEffect(() => {
    return subscribeToRefresh?.(() => module.repository.fetch());
  }, [subscribeToRefresh, module.repository]);
  
  return <>
    <SinglePermissionActionMenu
      setIsEditMode={setIsEditMode}
      setAddEditMode={setAddEditMode}
      isSingleActionMenuVisible={isSingleActionMenuVisible}
      setIsSingleActionMenuVisible={setIsSingleActionMenuVisible}
      singleActionAnchorEl={singleActionAnchorEl}
      data={data}
      setAddSelfInvoicingEditMode={setAddSelfInvoicingEditMode}
    />
    <PermissionsFilters page={module}/>
    <ExtendedTable
      service={module.tableService}
      loaderEnabled
      emptyTableText={'Brak elementów do wyświetlenia. Kliknij Synchronizuj z KSeF aby zaktualizować dane.'}
    />
  </>;
});
