import {observer} from 'mobx-react-lite';
import {AnyObject} from 'yup/es/types';
import {Action, MenuAction, MultipleActionsConfig} from '../../types/MenuActions';
import Button from '@mui/material/Button/Button';
import {Icon, IconColor, Tooltip, TooltipPosition} from '@symfonia/brandbook';
import React, {Key, useCallback, useMemo, useState} from 'react';
import {DesktopContextualMenu} from '../ContextualMenu/DesktopContextualMenu';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

export const DesktopMultipleActionsItems = observer(<T extends AnyObject = AnyObject>({
                                                                                        selectionState,
                                                                                        actions,
                                                                                      }: MultipleActionsConfig<T>) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [nestedMenuState, setNestedMenuState] = useState<{ isOpen: boolean, key?: Key }>({isOpen: false});


  const closeMenu = useCallback(() => {
    setAnchorElement(null);
    setNestedMenuState({isOpen: false});
  }, []);

  const openMenu = useCallback((event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement>, key?: Key) => {
    setAnchorElement(event.currentTarget);
    setNestedMenuState({isOpen: true, key});
  }, []);

  const createContextualMenuActions = useCallback((action: Action<T>): MenuAction[] => {
    if (!action.children?.length) {
      return [];
    }
    return action.children.map<MenuAction>(({onClick, ...child}) => ({
      ...child,
      onClick: () => {
        onClick(selectionState);
        closeMenu();
      },
    }));
  }, [closeMenu, selectionState]);

  const desktopActions = useMemo(() => {
    const nestedItems: JSX.Element[] = [];
    const items: JSX.Element[] = [];

    for (const action of actions.filter(action => !action.hidden)) {
      const buttonElement = action.icon ?
        <Icon
          {...action.iconOptions}
          svg={action.icon}
          color={action.disabled ? IconColor.GREY_300 : action.iconOptions?.color}
          testId={action.testId}
          withHover={!action.disabled}
          onClick={(e) => {
            if (action.disabled) return;
            action?.onClick?.(selectionState);
            if (nestedMenuState.isOpen) {
              closeMenu();
              return;
            }
            action.children?.length && openMenu(e, action.key);
          }}
        />
        : 
        <Button
          key={action.key}
          disabled={action.disabled}
          endIcon={action.children?.length ? <KeyboardArrowDown/> : undefined}
          onClick={(e) => {
            action?.onClick?.(selectionState);
            if (nestedMenuState.isOpen) {
              closeMenu();
              return;
            }
            action.children?.length && openMenu(e, action.key);
          }}
        >
          {action.label}
        </Button>;

      const actionElement = action.tooltip ?
        <Tooltip
          key={`Tooltip-${action.key}`}
          text={action.tooltip}
          position={TooltipPosition.TOP}
        >
          <div className="flex items-center">{buttonElement}</div>
        </Tooltip> : buttonElement;

      items.push(actionElement);

      if (action.children?.length) {
        const isOpen = nestedMenuState.isOpen && action.key === nestedMenuState?.key;
        nestedItems.push(<DesktopContextualMenu
          key={`Menu-${action.key}`}
          isOpen={isOpen}
          onClose={closeMenu}
          anchorEl={anchorElement}
          actions={createContextualMenuActions(action)}
        />);
      }
    }

    return [...items, ...nestedItems];

  }, [actions, openMenu, createContextualMenuActions, selectionState, nestedMenuState, closeMenu, anchorElement]);

  return <div className="flex items-center gap-[24px]">{desktopActions}</div>;
});
