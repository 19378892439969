import {ContextualMenu} from '../../../common/components/ContextualMenu/ContextualMenu';
import {IconSvg, IconVariant} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../../root/IntlProvider';
import {useState} from 'react';

const FilehubShareTableMenu = () => {
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setButtonRef(e.currentTarget);
    setIsOpen(isOpen => !isOpen);
  };

  const actions = [
    {
      icon: IconSvg.FOLDER_OPEN,
      key: Tr.details,
      label: intl.formatMessage({id: Tr.details}),
      onClick: () => {
        console.log('Szczegóły');
      },
    },
    {
      icon: IconSvg.LINK_OFF,
      key: Tr.disable,
      label: intl.formatMessage({id: Tr.disable}),
      onClick: () => {
        console.log('Disable');
      },
    },
    {
      icon: IconSvg.LINK,
      key: Tr.enable,
      label: intl.formatMessage({id: Tr.enable}),
      onClick: () => {
        console.log('Enable');
      },
    },
    {
      icon: IconSvg.SHARE,
      key: Tr.share,
      label: intl.formatMessage({id: Tr.share}),
      onClick: () => {
        console.log('Share');
      },
    },
    {
      icon: IconSvg.DELETE,
      key: Tr.removeButton,
      label: intl.formatMessage({id: Tr.removeButton}),
      onClick: () => {
        console.log('Delete');
      },
    },
  ];

  return (
    <div>
      <div>
        <button onClick={e => handleButtonClick(e)}>Open Menu</button>
      </div>
      <ContextualMenu actions={actions} anchorEl={buttonRef} isOpen={isOpen} onClose={() => setIsOpen(false)}/>
    </div>
  );
};

export default FilehubShareTableMenu;
