import {usePageTitle} from '../../common/hooks/usePageTitle';
import {FormattedMessage} from 'react-intl';
import {Tr} from '../../../locales/translationKeys';
import {AppRoutes} from '../../root/Routes';
import {intl} from '../../root/IntlProvider';
import {isDesktop} from '../../common/helpers/checkCurrentPlatform';
import {EnvironmentType} from '@symfonia-ksef/graphql';
import {Modal} from '@symfonia/brandbook';
import {useMemo} from 'react';
import {getModalMaxWidth} from '@symfonia/symfonia-ksef-components';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const UserNotAuthorized = observer(() => {
  const {environments} = earchiveState.environments;
  const companies = environments.filter(el => el.Type === EnvironmentType.Company);
  const {setPageTitle} = usePageTitle();

  setPageTitle(intl.formatMessage({id: Tr.accessForbiddenTitle}));

  const modalContentSize = useMemo<string>(() => {
    return getModalMaxWidth();
  }, []);

  return (
    <Modal
      show={true}
      title={<FormattedMessage id={companies.length > 0 ? Tr.userNotAuthorized_title : Tr.noCompanyAssigned_title}/>}
      okButton={!isDesktop && companies.length > 0 ? {
        text: intl.formatMessage({id: Tr.chooseCompanyRedirect}),
        onClick: () => {
          earchiveState.company.removeStoredCompanyId();
          window.open(document.location.origin + '/' + AppRoutes.hub.address, '_self')?.focus();
        }
      } : undefined}
      centerButtons={true}
      showCloseIcon={false}
      className={modalContentSize}
    >
      <div className="font-quicksand text-[16px] leading-[24px]">
        <FormattedMessage id={companies.length > 0 ? Tr.userNotAuthorized_content : Tr.noCompanyAssigned_content}
                          values={{
                            br: <br/>,
                          }}
        />
      </div>
    </Modal>
  );
});
