import React from 'react';
import {Toggle} from '@symfonia/brandbook';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ColumnSetting, InvoiceSum} from '../helpers/invoicesUtils';
import {invoiceCurrency} from '../../../../../modules/common';
interface TableSumProps {
  isOn: boolean;
  handleChange: (checked: boolean) => void;
  data: InvoiceSum[];
  columns: ColumnSetting[];
}

const updatedColumns = (columns: ColumnSetting[], data: InvoiceSum[], idx: number) => {
  const sortedColumns = columns.sort((a, b) => {
    if (a.order === undefined) return 1;
    if (b.order === undefined) return -1;
    return a.order - b.order;
  });
  return sortedColumns.map(column => {
    const matchingValue = data[idx][column.key as keyof InvoiceSum];
    return {...column, value: matchingValue};
  });
};

const findPreviousValuePosition = <T extends { order?: number; value?: number }>(arr: T[]): number | null | undefined => {
  let previousOrder = null;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].value !== undefined) {
      return previousOrder;
    }
    previousOrder = arr[i].order;
  }
  return null;
};

export const TableSum = ({isOn, handleChange, data, columns}: TableSumProps) => {
  const intl = useIntl();
  const rowData = isOn ? data : data.slice(0, 1);

  return <div>
    {
      rowData.map((row, idx) => (
        <div key={row.Currency} className="flex items-center h-[48px] border-b-[1px] border-b-grey-100">
          <div className="pl-[16px] w-[34px]"/>
          {
            updatedColumns(columns, data, idx).map((col: ColumnSetting, _, array) => {
              const rowKeys = Object.keys(row);
              const dataForColumn = rowKeys.includes(col.key) && data[idx][col.key as keyof InvoiceSum];
              const buttonOrder = findPreviousValuePosition(array);
              const formattedData = typeof dataForColumn === "number" ? invoiceCurrency.format(dataForColumn) : dataForColumn;
              
              return (
                <div key={col.key} className={`${col.width} font-bold text-sm px-[16px] ${col.alignRight ? 'text-right' : 'text-left'}`}>
                  {idx === 0 && col.order === buttonOrder && (
                    <Toggle
                      onChange={checked => handleChange(checked)}
                      value={'InvoicesSum'}
                      checked={isOn}
                      label={`${intl.formatMessage({id: Tr.sum})}:`}
                      testId="invoicesSumToggle"
                    />
                  )}
                  {formattedData ? isOn ? formattedData : '--' : null}
                </div>
              );
            })
          }
        </div>
      ))
    }
    </div>;
};