import {Navigate} from 'react-router-dom';
import {EnvironmentStatus, EnvironmentType, TenantStatus} from '@symfonia-ksef/graphql';
import {AppRoutes} from '../../root/Routes';
import {isDesktop} from '../../common/helpers/checkCurrentPlatform';
import {DesktopRedirect} from './DesktopRedirect';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {FC, ReactNode} from 'react';
import {CompanyNotFound} from './CompanyNotFound/CompanyNotFound';
import {CompanyInactive} from './CompanyInactive&PendingApproval/CompanyInactive';
import {CompanyPending} from './CompanyInactive&PendingApproval/CompanyPending';
import {TenantRejected} from '../../common/components/TenantRejected/TenantRejected';

export const SavedOrFirstCompanyIdGuard : FC<{children: ReactNode}> = observer(({children}) => {
  const {company, environments: {activeTenants, activeCompanies, companies, tenants}} = earchiveState;
  const storageCompanyId = company.getCompanyIdFromStore();
  const firstTenant = earchiveState.environments.data?.Environments?.find(env => env?.Type === EnvironmentType.Tenant);
  const firstCompany = earchiveState.environments?.data?.Environments?.find(env => env?.Type === EnvironmentType.Company);
  const atLeastOneCompany = companies.length >= 1;
  //redirect to company, which id is saved in storage (id is removed from storage on logout)
  if (storageCompanyId) {
    const env = earchiveState.environments.getEnvironmentById(storageCompanyId);
    //remove id from store when there is no company assigned to user, but in storage there is id saved, i.e. id of another company
    if (env?.Type !== EnvironmentType.Company) {
      company.removeStoredCompanyId();
      return <Navigate to={'/'}/>;
    }

    return <Navigate
      to={AppRoutes.eArchive.address + '/' + env?.Tenant?.Id + '/company/' + env?.EnvironmentId + '/documents/purchase'}
      replace/>;
  }
  if (isDesktop) {
    return <DesktopRedirect/>;
  }

  //navigate to create tenant page - when there is no active tenants and companies assigned to user
  if (tenants.length === 0 && companies.length === 0) {
    document.location.href = AppRoutes.administrationTenant.address;
    return null;
  }

  //navigate to company - if user has only one active company
  if (activeTenants.length === 1 && activeCompanies.length === 1) {
    return <Navigate
      to={AppRoutes.eArchive.address + '/' + activeTenants[0].Id + '/company/' + activeCompanies[0].EnvironmentId}/>;
  }


  if (tenants.length === 1) {
    if (firstTenant?.Status === EnvironmentStatus.Inactive) {
      return <CompanyInactive companyName={firstCompany?.Company?.Name ?? ''}/>;
    }

    if (firstTenant?.Status === EnvironmentStatus.PendingApproval) {
      return <CompanyPending companyName={firstCompany?.Company?.Name ?? ''}/>;
    }

    if (firstTenant?.Tenant?.Status === TenantStatus.Rejected) {
      return <TenantRejected tenantName={firstTenant?.Tenant?.Name ?? ''}/>;
    }
  }

  //check if user has at least one company assigned - typescript null checking
  if (!atLeastOneCompany) {
    return <CompanyNotFound/>;
  }

  //default redirect if user has one company
  return <>{children}</>;
});
