import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {Modal} from '@symfonia/brandbook';
import {intl} from '../../root/IntlProvider';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {FilehubTable} from '../table/FilehubTable';
import {InvoicesService} from '../../earchive/modules/Invoices/Invoices.service';
import FilehubModalAlertBuilder from './FilehubModalAlertBuilder';

type Props = {
  invoicesService: InvoicesService;
  onClose: () => void;
  state: IFilehubState;
};

const FileHubModal = observer(({invoicesService, onClose, state}: Props) => (
  <Modal
    className="w-[1100px] xl:w-[1000px] min-h-[400px] p-[16px] overflow-y-hidden"
    onClose={onClose}
    show={state.isFileHubOpen}
    title={intl.formatMessage({id: Tr.attachmentsList})}
  >
    <div className="flex flex-col gap-[24px]">
      <FilehubTable invoicesService={invoicesService} state={state}/>
      <FilehubModalAlertBuilder state={state}/>
    </div>
  </Modal>
));

export default FileHubModal;
