import {ExtendedTable} from '../../../../../../../../root/components/ExtendedTable';
import React, {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {IVehicleState} from '../state/IVehicleState';
import {VehiclesPageService} from '../VehiclesPage.service';
import {Loader} from '../../../../../../../../common/components/Loader/Loader';
import {intl} from '../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {usePrevious} from '@symfonia/symfonia-ksef-components';
import _ from 'lodash';
import {VehiclesTableFiltersConfig} from './VehicleTableFiltersConfig';
import {FiltersPills} from '../../../../../../../../root/components/FiltersPills';
import {VehiclesActionMenu} from './VehiclesTableActionMenu';
import {FiltersDrawer} from '@symfonia/symfonia-ksef-components';

interface VehiclesTableProps {
  state: IVehicleState;
  pageService: VehiclesPageService;
  filtersMenuIsOpen: boolean;

  setFiltersMenuIsOpen(isOpen: boolean): void;
}

export const VehiclesTable: FC<VehiclesTableProps> = observer(
  ({state, pageService, setFiltersMenuIsOpen, filtersMenuIsOpen}) => {
    const filterState = state.filterState;
    const previousFilters = usePrevious(filterState.getActiveFilters);
    const wasFilterChanged = !_.isEqual(_.flatMap(previousFilters), _.flatMap(filterState.getChangedFilters));

    const handleFilter = useCallback(() => {
      setFiltersMenuIsOpen(false);
      filterState?.startFiltering();
      filterState.storage.saveToStorage(filterState.activeFilters);
      pageService.repository.fetch();
    }, [filterState, pageService, setFiltersMenuIsOpen]);

    if (!filterState) {
      return <Loader/>;
    }

    return (
      <>
        <VehiclesActionMenu state={state}/>
        <FiltersDrawer
          hasError={filterState.hasError}
          isFiltersMenuOpened={filtersMenuIsOpen}
          openFiltersMenu={v => setFiltersMenuIsOpen(v)}
          handleFilter={handleFilter}
          clearFilters={filterState.handleClearMenuFilter}
          tableLabel={intl.formatMessage({id: Tr.tableLabel})}
          closeLabel={intl.formatMessage({id: Tr.closeLabel})}
          filterLabel={intl.formatMessage({id: Tr.filterLabel})}
          clearButtonLabel={intl.formatMessage({id: Tr.clearAllLabel})}
          filtersTouched={wasFilterChanged}
          filtersModified={wasFilterChanged}
          onClose={() => {
            setFiltersMenuIsOpen(false);
            filterState.resetFilterToStorage();
          }}
        >
          <VehiclesTableFiltersConfig state={state}/>
        </FiltersDrawer>
        <FiltersPills pills={pageService.pills} maxVisiblePills={10}/>
        <ExtendedTable service={pageService.tableService} loaderEnabled className="w-full"/>
      </>
    );
  },
);
