import {
  CompanyAssigmentListItemFragment,
  GetCompaniesForOrganisationQuery,
  GetCompaniesForOrganisationQueryVariables,
} from '@symfonia-ksef/graphql';
import {Columns} from '../../../services/TableServices/BaseTableService';
import {intl} from '../../../IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {Icon, IconColor, IconSvg, TableSort, TableSortDirection, TooltipPosition} from '@symfonia/brandbook';
import {SelectCompanyButton} from '../components/SelectCompanyButton';
import {
  BaseTableDataRepositoryServiceI,
  DefaultDataSourceHandler,
} from '../../../services/TableServices/DataSourceTableService';
import {IconWithTooltip} from '@symfonia/symfonia-ksef-components';

export type CompanyRow = CompanyAssigmentListItemFragment
export type CompanyContext = { title: string, tenantId: string }
export type CompanyColumns = Columns<CompanyRow, CompanyContext>


export class CompanyDataSourceHandler extends DefaultDataSourceHandler<'GetCompaniesForOrganisation', GetCompaniesForOrganisationQuery, CompanyRow, GetCompaniesForOrganisationQueryVariables> {
  constructor(private wrapper: { getTenantId(): string | undefined }, public repository: BaseTableDataRepositoryServiceI<'GetCompaniesForOrganisation', GetCompaniesForOrganisationQuery, GetCompaniesForOrganisationQueryVariables>) {
    super(repository);
  }

  public override dataMapper(data: GetCompaniesForOrganisationQuery['GetCompaniesForOrganisation'] | null): Array<CompanyRow> {
    return !data?.TenantAssignments ? [] : (data.TenantAssignments.find(org => org?.TenantId === this.wrapper.getTenantId())?.CompanyAssignments?.filter?.(Boolean) ?? []) as Array<CompanyRow>;
  }

  public override getTotalCount(data: GetCompaniesForOrganisationQuery['GetCompaniesForOrganisation']): number {
    return data.TotalCompanysCount;
  }
}

export const companyTableKeysFactory = (row: CompanyRow): string => row.CompanyId;

export const companySortingConfig: TableSort = {
  name: 'CompanyName',
  direction: TableSortDirection.ASC,
};

export const companyInitialColumns: CompanyColumns = {
  CompanyId: {
    asAction: true,
    header: '',
    order: 7,
    width: 'w-[120px]',
    cell: (row, column, key, context) => <SelectCompanyButton key={key} companyId={row.CompanyId}
                                                              tenantId={context.tenantId}
                                                              text={context.title}
                                                              testId={`selectCompanyButtonHub-${row.CompanyName}`}/>,
  },
  Identifier: {
    header: intl.formatMessage({id: Tr.NIP}),
    sortable: true,
    order: 1,
    cell: (row, column, key) => <span key={key}>{row.Identifier}</span>,
    width: 'w-[100px]',
  },
  CompanyName: {
    header: intl.formatMessage({id: Tr.companyName}),
    sortable: true,
    order: 2,
    cell: (row, column, key) => <span key={key}>{row.CompanyName}</span>,
    width: 'w-[600px]',
  },
  SalesInvoicesCount: {
    sortable: false,
    order: 3,
    header: intl.formatMessage({id: Tr.salesInvoices}),
    cell: (row, column, key) => <span key={key}>{row.SalesInvoicesCount}</span>,
    width: 'w-[230px]',
    content: (
      <div className="flex justify-start items-center gap-[4px]">
        <FormattedMessage id={Tr.salesInvoices}/>
        <IconWithTooltip
          tooltipWidth="auto"
          btnClass="flex items-center m-0 z-0"
          icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
          tooltipPosition={TooltipPosition.TOP}
          tooltipContent={<p>{intl.formatMessage({id: Tr.salesInvoices_Tooltip}, {br: <br/>})}</p>}
        />
    </div>)
  },
  PurchaseInvoicesCount: {
    sortable: false,
    order: 4,
    header: intl.formatMessage({id: Tr.purchaseInvoices}),
    cell: (row, column, key) => <span key={key}>{row.PurchaseInvoicesCount}</span>,
    width: 'w-[230px]',
    content: (
      <div className="flex justify-start items-center gap-[4px]">
        <FormattedMessage id={Tr.purchaseInvoices}/>
        <IconWithTooltip
          tooltipWidth="auto"
          btnClass="flex items-center m-0 z-0"
          icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
          tooltipPosition={TooltipPosition.TOP}
          tooltipContent={<p>{intl.formatMessage({id: Tr.purchaseInvoices_Tooltip}, {br: <br/>})}</p>}
        />
    </div>)
  },
  HasKSeFAuthorization: {
    order: 5,
    sortable: false,
    header: intl.formatMessage({id: Tr.authentication}),
    cell: (row, column, key) => {
      const hasAuthorization = row.HasKSeFAuthorization;
      return (
      <div className="flex items-center gap-[4px]">
        <Icon
          key={key}
          svg={hasAuthorization ? IconSvg.CHECK_CIRCLE_OUTLINE : IconSvg.CANCEL}
          color={hasAuthorization ? IconColor.GREEN_500 : IconColor.GREY_500}
        />
        <p>{intl.formatMessage({id: hasAuthorization ? Tr.yes : Tr.no})}</p>
      </div>);
    },
    width: 'w-[200px]',
    content: (
      <div className="flex justify-start items-center gap-[4px]">
        <FormattedMessage id={Tr.authentication}/>
        <IconWithTooltip
          tooltipWidth="auto"
          btnClass="flex items-center m-0 z-0"
          icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
          tooltipPosition={TooltipPosition.TOP}
          tooltipContent={<p>{intl.formatMessage({id: Tr.authorizationStatus}, {br: <br/>})}</p>}
        />
    </div>)
  },
  IsDemo: {
    order: 6,
    sortable: true,
    header: intl.formatMessage({id: Tr.connectionWithServer}),
    cell: (row, column, key) => <FormattedMessage key={key} id={row.IsDemo ? Tr.DEMO : Tr.PROD}/>,
    width: 'w-[320px]',
    content: (
      <div className="flex justify-start items-center gap-[4px]">
        <FormattedMessage id={Tr.connectionWithServer}/>
        <IconWithTooltip
          tooltipWidth="auto"
          btnClass="flex items-center m-0 z-0"
          icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
          tooltipPosition={TooltipPosition.TOP}
          tooltipContent={
            <>
              <b>{intl.formatMessage({id: Tr.DEMO}, {br: <br/>})}</b>
              <p>{intl.formatMessage({id: Tr.connectionWithServer_Tooltip_Demo}, {br: <br/>})}</p>
              <br/>
              <b>{intl.formatMessage({id: Tr.PROD}, {br: <br/>})}</b>
              <p>{intl.formatMessage({id: Tr.tooltipProd_Content}, {br: <br/>})}</p>
            </>
          }
        />
    </div>)
  },
};

export const companyInitialColumnsMobile: CompanyColumns = {
  CompanyId: {
    asAction: true,
    header: '',
    order: 7,
    width: 'w-[45px]',
    cell: (row, column, key, context) => <SelectCompanyButton key={key} companyId={row.CompanyId}
                                                              tenantId={context.tenantId}
                                                              text={''}/>,
  },
  Identifier: {
    header: intl.formatMessage({id: Tr.NIP}),
    sortable: true,
    order: 1,
    cell: (row, column, key) => <span key={key}>{row.Identifier}</span>,
    width: 'w-[100px]',
  },
  CompanyName: {
    header: intl.formatMessage({id: Tr.companyName}),
    sortable: true,
    order: 2,
    cell: (row, column, key) => <span key={key}>{row.CompanyName}</span>,
    width: 'w-[600px]',
  },
  SalesInvoicesCount: {
    sortable: false,
    order: 3,
    header: intl.formatMessage({id: Tr.salesInvoices}),
    cell: (row, column, key) => <span key={key}>{row.SalesInvoicesCount}</span>,
    width: 'w-[200px]',
    content: (
      <div className="flex justify-start items-center gap-[4px]">
        <FormattedMessage id={Tr.salesInvoices}/>
        <IconWithTooltip
          tooltipWidth="auto"
          btnClass="flex items-center m-0 z-0"
          icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
          tooltipPosition={TooltipPosition.TOP}
          tooltipContent={<p>{intl.formatMessage({id: Tr.salesInvoices_Tooltip}, {br: <br/>})}</p>}
        />
    </div>)
  },
  PurchaseInvoicesCount: {
    sortable: false,
    order: 4,
    header: intl.formatMessage({id: Tr.purchaseInvoices}),
    cell: (row, column, key) => <span key={key}>{row.PurchaseInvoicesCount}</span>,
    width: 'w-[200px]',
    content: (
      <div className="flex justify-start items-center gap-[4px]">
        <FormattedMessage id={Tr.purchaseInvoices}/>
        <IconWithTooltip
          tooltipWidth="auto"
          btnClass="flex items-center m-0 z-0"
          icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
          tooltipPosition={TooltipPosition.TOP}
          tooltipContent={<p>{intl.formatMessage({id: Tr.purchaseInvoices_Tooltip}, {br: <br/>})}</p>}
        />
    </div>)
  },
  HasKSeFAuthorization: {
    order: 5,
    sortable: false,
    header: intl.formatMessage({id: Tr.authentication}),
    cell: (row, column, key) => {
      const hasAuthorization = row.HasKSeFAuthorization;
      return (
      <div className="flex items-center gap-[4px]">
        <Icon
          key={key}
          svg={hasAuthorization ? IconSvg.CHECK_CIRCLE_OUTLINE : IconSvg.CANCEL}
          color={hasAuthorization ? IconColor.GREEN_500 : IconColor.GREY_500}
        />
        <p>{intl.formatMessage({id: hasAuthorization ? Tr.yes : Tr.no})}</p>
      </div>);
    },
    width: 'w-[200px]',
    content: (
      <div className="flex justify-start items-center gap-[4px]">
        <FormattedMessage id={Tr.authentication}/>
        <IconWithTooltip
          tooltipWidth="auto"
          btnClass="flex items-center m-0 z-0"
          icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
          tooltipPosition={TooltipPosition.TOP}
          tooltipContent={<p>{intl.formatMessage({id: Tr.authorizationStatus}, {br: <br/>})}</p>}
        />
    </div>)
  },
  IsDemo: {
    order: 6,
    sortable: true,
    header: intl.formatMessage({id: Tr.connectionWithServer}),
    cell: (row, column, key) => <FormattedMessage key={key} id={row.IsDemo ? Tr.DEMO : Tr.PROD}/>,
    width: 'w-[200px]',
    content: (
      <div className="flex justify-start items-center gap-[4px]">
        <FormattedMessage id={Tr.connectionWithServer}/>
        <IconWithTooltip
          tooltipWidth="auto"
          btnClass="flex items-center m-0 z-0"
          icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
          tooltipPosition={TooltipPosition.TOP}
          tooltipContent={
            <>
              <b>{intl.formatMessage({id: Tr.DEMO}, {br: <br/>})}</b>
              <p>{intl.formatMessage({id: Tr.connectionWithServer_Tooltip_Demo}, {br: <br/>})}</p>
              <br/>
              <b>{intl.formatMessage({id: Tr.PROD}, {br: <br/>})}</b>
              <p>{intl.formatMessage({id: Tr.tooltipProd_Content}, {br: <br/>})}</p>
            </>
          }
        />
    </div>)
  },
};