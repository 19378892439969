import {MultiSelectModel} from '@symfonia/symfonia-ksef-components';
import {action, autorun, computed, makeObservable, observable} from 'mobx';
import {CategoryDetailsFiltersState} from './CategoryDetailsFiltersState';
import {
  CategoryViewModel,
  InvoiceTypeGroupEnum,
} from '@symfonia-ksef/graphql';
import {intl} from '../../../../../../../../root/IntlProvider';
import {Tr} from '../../../../../../../../../locales/translationKeys';
import {convertToMultiSelectType} from '../../../../../../../../common/helpers/baseFilterHelpers';
import {ICategoryDetailsState} from './ICategoryDetailsState';
import {IPostingConfigurationState} from '../../IPostingConfigurationState';
import {ICategoryDetailsStore} from './ICategoryDetailsStore';
import {CategoryDetailsStore} from './CategoryDetailsStore';
import {CategoryRulePositionModel, CategoryRuleUserModel} from '../queries/GetCategoryRulesFilterDataQueryFunction';

export class CategoryDetailsState implements ICategoryDetailsState {
  @observable
  isLoading = false;

  @observable
  users: MultiSelectModel[] = [];

  @observable
  categories: MultiSelectModel[] = [];

  @observable
  expressions: MultiSelectModel[] = [];

  @observable
  selectedInvoiceMenuAnchorEl: HTMLElement | undefined;

  @observable
  selectedInvoiceMenuOpened = false;

  filterState: CategoryDetailsFiltersState;
  postingConfigurationState: IPostingConfigurationState;
  categoryDetailsStore: ICategoryDetailsStore;

  constructor(postingConfigurationState: IPostingConfigurationState) {
    makeObservable(this);
    this.filterState = new CategoryDetailsFiltersState(this);
    this.categoryDetailsStore = new CategoryDetailsStore(this);
    this.postingConfigurationState = postingConfigurationState;

    autorun(() => {
      if (this.categoryDetailsStore.categories.length &&
         this.categoryDetailsStore.users.length &&
         this.categoryDetailsStore.positions.length) {
        this.setCategories(this.categoryDetailsStore.categories);
        this.setPositions(this.categoryDetailsStore.positions);
        this.setUsers(this.categoryDetailsStore.users);
      }
    });
  }

  @computed
  get typeFilterObject() {
    return convertToMultiSelectType(
      Object.entries(InvoiceTypeGroupEnum).filter(x => x[1] !== InvoiceTypeGroupEnum.Undefined),
      ([x, y]) => ({
        value: intl.formatMessage({id: Tr[y]}),
        key: x.toUpperCase(),
      }),
      true,
    );
  }

  @action setSelectedInvoiceMenuAnchorEl(selectedInvoiceMenuAnchorEl: HTMLElement | undefined) {
    this.selectedInvoiceMenuAnchorEl = selectedInvoiceMenuAnchorEl;
  }

  @action setSelectedInvoiceMenuOpened(selectedInvoiceMenuOpened: boolean) {
    this.selectedInvoiceMenuOpened = selectedInvoiceMenuOpened;
  }

  @action refreshData() {
    this.setUsers(this.categoryDetailsStore.users);
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setCategories(categories: CategoryViewModel[]) {
    this.filterState.categories.replace(
      convertToMultiSelectType(
        categories,
        el => ({
          value: el.Name,
          key: el.Id as string,
        }),
        true,
      ),
    );
  }

  @action
  setPositions(positions: CategoryRulePositionModel[]) {
    this.filterState.positions.replace(
      convertToMultiSelectType(
        positions,
        el => ({
          value: el.PositionName,
          key: el.Id as string,
        }),
        true,
      ),
    );
  }

  @action
  setUsers(users: CategoryRuleUserModel[]) {
    this.filterState.users.replace(
      convertToMultiSelectType(
        users,
        el => ({
          value: el.UserFirstName + ' ' + el.UserLastName,
          key: el.UserId as string,
        }),
        true,
      ),
    );
  }
}

