export const documentExtensions = ['pdf'];
export const fileExtensionRegex = /\.[^.]+$/;
export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
export const videoExtensions = ['mp4', 'avi', 'mkv'];
export const audioExtensions = ['mp3', 'wav', 'aac'];
export const forbiddenExtensions = ['.exe', '.com', '.bat', '.app', '.bin', '.php'];
export const invalidCharacters = ['\\', '/', ':', '*', '?', '"', '<', '>', '|', '#', '%'];

export const validFileTypes = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'text/plain',
  'image/svg+xml',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/xml',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-zip-compressed',
];

export const checkContainsInvalidCharacters = (fileName: string): boolean => {
  return invalidCharacters.some(char => fileName.includes(char));
};
