import {BaseEventConsumer, IEventConsumerPublic} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {downloadedInvoicesResultService} from '../../state/rootRepository';
import {Tr} from '../../locales/translationKeys';

export class SyncPermissionKSeFEventConsumer extends BaseEventConsumer implements IEventConsumerPublic {
  constructor() {
    super(WebsocketNotificationType.SyncPermission, downloadedInvoicesResultService, {
      success:  {id: Tr.syncPermissionKSeFSuccess},
      error: {id: Tr.syncPermissionKSeFError},
    });

  }
}
