import {Icon, IconSize} from '@symfonia/brandbook';
import {IAction} from './FilehubMultipleActionsBar';

type Props = {
  actions: IAction[];
};

const FilehubDesktopMultipleActionsItems = ({actions}: Props) => {
  return (
    <div className="flex flex-inline gap-[16px] content-center">
      {actions.map(({color, key, icon, onClick, disabled}) => (
        <div key={key} className='max-h-6'>
          <Icon className={!disabled ? "cursor-pointer" : "cursor-default"} size={IconSize.LG} color={color} svg={icon} onClick={!disabled ? onClick : () => {return false;}}/>
        </div>
      ))}
    </div>
  );
};

export default FilehubDesktopMultipleActionsItems;
