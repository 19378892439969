import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Icon, IconColor, IconSize, IconSvg, Tooltip} from '@symfonia/brandbook';

export const sharedPageTableConfig = (
  handleHeaderCheckboxSelect: (checked: boolean) => void,
  allSelectedElements: boolean,
) => [
  {
    name: 'checkbox',
    checkbox: {
      name: 'checkbox-1',
      value: 'checkbox-1',
      checked: allSelectedElements,
      onChange: (checked: boolean) => {
        handleHeaderCheckboxSelect(checked);
      },
    },
    width: 'w-[50px]',
  },
  {
    name: Tr.invoiceOwnNumber,
    title: intl.formatMessage({id: Tr.invoiceOwnNumber}),
    width: 'w-[240px]',
  },
  {
    name: Tr.publicAttachments,
    title: intl.formatMessage({id: Tr.sharePublicAtachments}),
    width: 'w-[250px]',
  },
  {
    name: Tr.sharesFilesSize,
    title: intl.formatMessage({id: Tr.sharesFilesSize}),
    width: 'w-[150px]',
  },
  {
    name: Tr.sharesShareStartDate,
    title: intl.formatMessage({id: Tr.sharesShareStartDate}),
    width: 'w-[150px]',
  },
  {
    name: Tr.sharesShareStatus,
    title: intl.formatMessage({id: Tr.sharesShareStatus}),
    width: 'w-[250px]',
  },
  {
    content: (
      <>
        {intl.formatMessage({id: Tr.link})}{' '}
        <Tooltip text={intl.formatMessage({id: Tr.shareLinkTooltipText}, {br: <br/>})}>
          <Icon className="ml-[8px]" color={IconColor.BLUE1_400} size={IconSize.MD} svg={IconSvg.INFO}/>
        </Tooltip>
      </>
    ),
    name: Tr.link,
    width: 'w-[250px]',
  },
  {
    name: Tr.pinProtection,
    title: intl.formatMessage({id: Tr.pinProtection}),
    width: 'w-[150px]',
  },
  {
    name: Tr.actions,
    width: 'w-[50px]',
  },
];
