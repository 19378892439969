import {PostingStatusEnum} from '@symfonia-ksef/graphql';
import {PostingTableState} from './PostingTableState';
import {autorun, IObservableArray, makeObservable, observable} from 'mobx';
import {MultiSelectModel} from '@symfonia/symfonia-ksef-components';
import {IPostingTableFilterState} from './IPostingTableFilterState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {BaseSideMenuFilterState} from '@symfonia-ksef/state/filters/BaseSideMenuFilterState';
import {PostedDecreeResponseFilterKeys} from '../../../../../earchive/pages/Account/Posting/pages/PostingConfiguration/CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {FilterScopeType, ListFilterType} from '@symfonia-ksef/state/filters/BaseFilterState';


export class PostingTableFilterState
  extends BaseSideMenuFilterState<PostingTableState, PostedDecreeResponseFilterKeys>
  implements IPostingTableFilterState {

  @observable
  public contractors: IObservableArray<MultiSelectModel> = observable([]);

  constructor(parent: PostingTableState) {
    super(parent);
    this.defaultFilters = [
      {
        key: PostedDecreeResponseFilterKeys.PostingStatus,
        value: {
          values: [PostingStatusEnum.DecreeSent, PostingStatusEnum.Posted, PostingStatusEnum.Incomplete, PostingStatusEnum.NoAction, PostingStatusEnum.DecreeDownloaded],
          isActive: true,
          type: FilterScopeType.Enum,
          isPageFilter: true,
        },
      },
      {
        key: PostedDecreeResponseFilterKeys.IsAccepted,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.Boolean,
          isPageFilter: true,
        },
      },
      {
        key: PostedDecreeResponseFilterKeys.InvoiceTypeGroup,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.Enum,
          isPageFilter: true,
          translationKey: Tr.documentType,
        },
      },
      {
        key: PostedDecreeResponseFilterKeys.Nip,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.String,
          isPageFilter: true,
          translationKey: Tr.identifierSender,
        },
      },
      {
        key: PostedDecreeResponseFilterKeys.ContractorId,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.UUIDs,
          isPageFilter: true,
        },
      },
      {
        key: PostedDecreeResponseFilterKeys.CreatedAt,
        value: {
          values: [{
            from: undefined,
            to: undefined,
            specific: undefined,
          }],
          isActive: false,
          isRangeFilter: true,
          type: FilterScopeType.Date,
        },
      },
      {
        key: PostedDecreeResponseFilterKeys.GrossValue,
        value: {
          values: [{
            from: undefined,
            to: undefined,
            specific: undefined,
          }],
          isActive: false,
          isRangeFilter: true,
          type: FilterScopeType.Range,
        },
      },
    ];
    makeObservable(this);
    this.setDefaultValues();

    autorun(() => this.parent.postingState.earchiveState.companyId !== '' ? this.parent.postingStore.fetchContractorsFilterData() : null);
  }

  protected override setDefaultValues() {
    let filterToCreate: ListFilterType = this.storage.loadFromStorage();

    if (!filterToCreate || !filterToCreate.length) {
      filterToCreate = this.defaultFilters;
    }

    if (!this.parent.acceptBeforeSendToFKIsActive) {
      filterToCreate = filterToCreate.filter(x => x.key !== PostedDecreeResponseFilterKeys.IsAccepted);
    }

    filterToCreate.map(f => {
      if (Object.keys(PostedDecreeResponseFilterKeys).find(c => c === f.key)) {
        this.setActiveFilters(f.key as PostedDecreeResponseFilterKeys, f.value);
      }
    });
    this.createPills();
  }

}
