import {FC} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {
  ButtonPrimary,
  ButtonPrimaryVariant,
  ButtonPrimaryWidth,
  ButtonSecondary,
  ButtonSecondaryWidth,
  Modal,
} from '@symfonia/brandbook';

interface IConfirmationModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: Tr;
  text: Tr;
}

const FilehubConfirmationModal: FC<IConfirmationModalProps> = ({
  visible,
  onClose,
  onConfirm,
  title,
  text
}) => (
  <Modal
    className="w-[450px] p-[16px]"
    show={visible}
    title={intl.formatMessage({id: title})}
    onClose={onClose}
  >
    <div>
      {intl.formatMessage({id: text})}
    </div>
    <div className="flex gap-[10px] mt-[16px]">
      <ButtonSecondary
        text={intl.formatMessage({id: Tr.no})}
        onClick={onClose}
        width={ButtonSecondaryWidth.FULL}
      />
      <ButtonPrimary
        text={intl.formatMessage({id: Tr.yes})}
        onClick={onConfirm}
        width={ButtonPrimaryWidth.FULL}
        variant={ButtonPrimaryVariant.RED}
      />
    </div>
  </Modal>
);

export default FilehubConfirmationModal;
