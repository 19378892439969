import {EventJobRunner} from '../EventJobRunner';
import {
    SyncPermissionKSeFDocument,
    SyncPermissionKSeFMutation,
    SyncPermissionKSeFMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {InitializerType} from '../helpers/fetchMatchedAction';
import {Tr} from '@symfonia-ksef/locales/keys';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class SyncPermissionKSeFJobRunner extends EventJobRunner<SyncPermissionKSeFMutationVariables, keyof SyncPermissionKSeFMutation> {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('SyncPermissionKSeF', WebsocketNotificationType.SyncPermission, SyncPermissionKSeFDocument, envObserver, earchiveState, InitializerType.Mutation);
    this.configureAlerts({
        success: (status) => ({
          id: Tr.syncPermissionKSeFInProgress,
        }),
        error: (errors, errorMessage) => ({
          id: Tr.syncPermissionKSeFError,
        }),
      },
    );
  }

  public override checkIsReady(): boolean {
    return !!this.envId;
  }
}

