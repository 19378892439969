import {observer} from 'mobx-react-lite';
import {Notification} from '@symfonia/brandbook';
import {NotificationSize, NotificationVariant} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {fileExtensionRegex} from './extensions';
import {returnStringFromRegex} from './utils';
import {FilehubFile} from '../modals/FilehubAddAttachmentModal';

type NotificationBuilderProps = Omit<FilehubFile, "id">

const FilehubFileUploaderNotificationBuilder = observer(({fileType, containsInvalidCharacters, containsExceededFileSize,file}: NotificationBuilderProps) => {

  const {isValid, extension, isFolder} = fileType;

  if (isValid && !isFolder && !containsInvalidCharacters && !containsExceededFileSize)
    return <></>;

  const getNotificationText = (): string => {
    switch (true) {
      case !isValid:
        return `${intl.formatMessage({id: Tr.formatOfFile})} ${extension || returnStringFromRegex(fileExtensionRegex, file.name)}`;
      case isFolder:
        return intl.formatMessage({id: Tr.uploadFoldersProhibited});
      case containsInvalidCharacters:
        return intl.formatMessage({id: Tr.filehubUploadFilesWithInvalidCharacters});
      case containsExceededFileSize:
        return intl.formatMessage({id: Tr.filehubUploadFilesWithFileSizeExceeded});
    }
    return '';
  };

  return (
    <Notification
      className="my-[6px]"
      size={NotificationSize.SM}
      text={getNotificationText()}
      variant={NotificationVariant.ERROR}
    />
  );
});

export default FilehubFileUploaderNotificationBuilder;
