import {
  GetCompaniesForOrganisationDocument,
  GetCompaniesForOrganisationQuery,
  GetCompaniesForOrganisationQueryVariables,
  GetStatisticsForCompanyDocument,
  GetStatisticsForCompanyQuery,
 } from '@symfonia-ksef/graphql';
import {computed, makeObservable} from 'mobx';
import {BaseRepository, BaseRepositoryI} from '../../modules/root/services/MobXServices/Repository/BaseRepository';
import {AnyObject} from 'yup/es/types';
import {apolloClient} from '../../modules/root/providers/GraphQLProvider';

export interface CompaniesRepositoryI extends BaseRepositoryI<'GetCompaniesForOrganisation', GetCompaniesForOrganisationQuery, GetCompaniesForOrganisationQueryVariables> {

  get organizations(): GetCompaniesForOrganisationQuery['GetCompaniesForOrganisation']['TenantAssignments'];

  get settledParameters(): Partial<GetCompaniesForOrganisationQueryVariables>;

  getCompaniesByTenantId(id: string): any[];
}

type StatisticsForCompany = { HasKSeFAuthorization: boolean, PurchaseInvoicesCount: number, SalesInvoicesCount: number };

export class CompaniesRepository<Key extends string = string, Data extends Record<Key, any> = Record<Key, AnyObject>, Variables extends AnyObject = AnyObject> extends BaseRepository<'GetCompaniesForOrganisation', GetCompaniesForOrganisationQuery, GetCompaniesForOrganisationQueryVariables> implements CompaniesRepositoryI {
  constructor() {
    super('GetCompaniesForOrganisation', GetCompaniesForOrganisationDocument);
    this.configure({SearchText: ''});
    makeObservable(this);
  }

  @computed
  get settledParameters(): Partial<GetCompaniesForOrganisationQueryVariables> {
    return this.variables;
  }

  @computed
  public get organizations() {
    return this.data?.TenantAssignments ?? [];
  }

  protected mapResponseData(data: Data[Key]): Data[Key] {
    data.TenantAssignments?.forEach((tenant: { CompanyAssignments: any[]; }) => {
      tenant?.CompanyAssignments?.forEach(company => {
         if (company !== undefined)
         {
            company.HasKSeFAuthorization = null;
            company.PurchaseInvoicesCount = null;
            company.SalesInvoicesCount = null;
          }
      });
    });
    return data;
  }

  protected async getStatisticsForCompany(companyId: string) : Promise<StatisticsForCompany | null> {
    let statistics: StatisticsForCompany | null = null;
    const companyEnvironmentId = this.envId;
    const response = await apolloClient.query<GetStatisticsForCompanyQuery>({
      query: GetStatisticsForCompanyDocument,
      context: {envId: companyEnvironmentId},
      variables: {
        CompanyId: companyId,
      },
    });

    if (!response.error && response.data) {
      statistics = {HasKSeFAuthorization: response.data.GetStatisticsForCompany.HasKSeFAuthorization,
        PurchaseInvoicesCount: response.data.GetStatisticsForCompany.PurchaseInvoicesCount,
        SalesInvoicesCount: response.data.GetStatisticsForCompany.SalesInvoicesCount
       };
    }

    return statistics;
  }

  protected async afterFetch(data: Data[Key] | null, wholeData: GetCompaniesForOrganisationQuery | null, forced: boolean): Promise<void> {
    data?.TenantAssignments?.forEach((tenant: { CompanyAssignments: any[]; }) => {
      tenant?.CompanyAssignments?.forEach(async company => {
        if (company !== undefined) {
          const response = await this.getStatisticsForCompany(company.CompanyId);
          if (response) {
            company.HasKSeFAuthorization = response?.HasKSeFAuthorization;
            company.PurchaseInvoicesCount = response?.PurchaseInvoicesCount;
            company.SalesInvoicesCount = response?.SalesInvoicesCount;
    
            if (this.data) {
              this.set({...this.data});
            }
          }
        }
      });
    });

    return;
  }


  public override checkIsReady(): boolean {
    return typeof this.variables.Skip === 'number' && this.variables.Take;
  }

  public getCompaniesByTenantId(id: string) {
    return (this.data?.TenantAssignments?.find(tenant => tenant?.TenantId === id)?.CompanyAssignments?.filter(Boolean) ?? []) as any[];
  }
}
