import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {ReactNode} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';

export class SyncPermissionKSeFEventConverter extends KSeFEventsConverter<WebsocketNotificationType.SyncPermission> {
  constructor(event: WebSocketNotificationFragment) {
    super(WebsocketNotificationType.SyncPermission, event);
  }

  public get actionElement(): undefined {
    return;
  }

  public get notification(): ReactNode {
    return this.createNotification({id: this.model.hasError ? Tr.syncPermissionKSeFError : Tr.syncPermissionKSeFSuccess});
  }

  public get model(): { data: null; hasError: boolean } {
    return {data: null, hasError: !!this.event.ErrorType};
  }

  public action(): void {
    return;
  }
}
