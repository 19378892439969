import {action, computed, makeObservable, observable} from 'mobx';
import {ObservableSubscription} from '@apollo/client';
import {l} from '../../modules/logger/loggerInstance';
import {
  Package,
  PackageStatisticsInterface,
  PackageStatisticsModel,
  PackageStatisticsState,
} from './PackageStatisticsModel';
import {
  GetPackageStatisticDocument,
  GetPackageStatisticQuery,
  GetPackageStatisticQueryVariables,
  PackageStatisticsItemFragment,
  PackageType,
  SetSubscriptionTenantDocument,
  SetSubscriptionTenantMutation,
  SetSubscriptionTenantMutationVariables,
  SubscriptionType,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../modules/root/providers/GraphQLProvider';
import {GraphQLError} from 'graphql/index';
import {isEmpty} from 'ramda';
import {BytesConverter, Unit} from '../../modules/common/helpers/BytesConverter';
import {EnvObserverI, EnvSubscriber} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';

export const ERROR_CODES = Object.freeze(['SUBSCRIPTION_NOT_FOUND']);
export const IGNORE_ERRORS_PATTERNS = Object.freeze(['Not found subscription']);
export const EXTENDED_IGNORE_ERRORS_PATTERNS = Object.freeze([...IGNORE_ERRORS_PATTERNS, 'Cannot get blob size', 'Subskrypcja wygasła', 'Nie znaleziono subskrypcji dla organizacji']);

export class PackageStatisticsRepository implements PackageStatisticsRepositoryInterface {

  @observable
  public initialized = false;

  @observable
  public loading = false;

  @observable
  public loadingError?: string;

  @observable
  public loaded: boolean = false;

  @observable
  public subscriptionNotFound = false;

  @observable
  public tenantId: string = '';

  @observable
  public enabled = false;

  private readonly SUSPENDED_SUBSCRIPTION_DAYS = 90;

  private readonly ONE_DAY = 1000 * 60 * 60 * 24;

  private readonly model: PackageStatisticsInterface;

  public readonly packageThresholds: [number, number, number] = [0, 80, 99.99];

  public readonly expiringThreshold = 100 - this.packageThresholds[1];

  private observableQuery: ObservableSubscription | null = null;

  protected constructor(private envObserver: EnvObserverI, initialState?: PackageStatisticsState) {
    this.model = new PackageStatisticsModel(initialState);
    makeObservable(this);
    this.envObserver.subscribeTenantId(this.envSubscriber, true);
  }

  @computed
  public get shouldGetSerialNumber() {
    return this.state?.SerialNumber ? !!this.state?.SerialNumber : null;
  }

  @computed
  public get subscriptionIsSuspended() {
    const todayTimestamp = (new Date()).setHours(0, 0, 0, 0);
    return this.state.DateTo ? Math.round((todayTimestamp - this.state.DateTo.setHours(0, 0, 0, 0)) / this.ONE_DAY) <= this.SUSPENDED_SUBSCRIPTION_DAYS && !this.subscriptionIsActive : null;
  }

  @computed
  public get currentStorageUsage(): BytesConverter {
    return BytesConverter.format(this.state.CurrentStorageCount);
  }

  @computed
  public get maxStorageSize(): BytesConverter {
    const storageAvailable = this.state.Items?.find(el => el.PackageTypeEnum === PackageType.Storage);
    return BytesConverter.format(this.state.Items && this.state.Items.length > 0 && storageAvailable ? (this.state.Items.filter(el => el.PackageTypeEnum === PackageType.Storage)[0]).PackageValue : 0);
  }

  @computed
  public get subscriptionIsActive() {
    return this.checkSubscriptionWasActiveFor(Date.now(), this.state.DateTo);
  }

  @computed
  public get subscriptionIsInactive() {
    return this.state.DateTo ? !this.subscriptionIsSuspended && !this.subscriptionIsActive : null;
  }

  @computed
  public get invoicePackageIsEnding() {
    return this.checkPackageIsEnding(this.invoicePackage, this.state.CurrentInvoicesCount) || this.checkPercentageIsEnding(this.currentInvoicesPercentageUsage);
  }

  @computed
  public get storagePackageIsEnding() {
    return this.checkPackageIsEnding(this.storagePackage, this.state.CurrentStorageCount) || this.checkPercentageIsEnding(this.currentStoragePercentageUsage);
  }

  @computed
  public get packageDateIsEnding() {
    const datePackageLimit = 45;
    const todayTimestamp = (new Date()).setHours(0, 0, 0, 0);
    return this.state.DateTo ? Math.round((this.state.DateTo.setHours(0, 0, 0, 0) - todayTimestamp + this.ONE_DAY) / this.ONE_DAY) <= datePackageLimit : null;
  }

  @computed
  public get invoicePackage(): Package | undefined {
    return this.findPackage(PackageType.Invoice);
  }

  @computed
  public get storagePackage(): Package | undefined {
    return this.findPackage(PackageType.Storage);
  }

  @computed
  public get deactivationDate(): Date | null {
    return this.state.DateTo ? new Date(this.state.DateTo?.getTime() + this.ONE_DAY) : null;
  }

  @computed
  public get invoicesLeft() {
    const invoicesLeft = this.getPackageValueLeft(this.invoicePackage, this.state.CurrentInvoicesCount);
    return invoicesLeft !== null && invoicesLeft <= 0 ? 0 : invoicesLeft;
  }

  @computed
  public get storageLeft() {
    return this.getPackageValueLeft(this.storagePackage, this.state.CurrentStorageCount, 2);
  }

  @computed
  public get isPackageUsed() {
    return this.invoicesPackageUsed || this.storagePackageUsed;
  }

  @computed
  public get invoicesPackageUsed() {
    return (this.invoicesLeft !== null && this.invoicesLeft <= 0) || this.currentInvoicesPercentageUsage >= 100;
  }

  @computed
  public get storagePackageUsed() {
    return (this.storageLeft !== null && this.storageLeft <= 0) || this.currentStoragePercentageUsage >= 100;
  }

  @computed
  public get isPackageNotActive() {
    return this.isPackageUsed || this.subscriptionIsActive === false;
  }

  @computed
  public get outOfLimit() {
    return this.invoicesLeft !== null && this.invoicesLeft <= 0 || this.storageLeft !== null && this.storageLeft <= 0;
  }

  public get state() {
    return this.model;
  }

  @computed
  public get availableStorageSize(): BytesConverter {
    return BytesConverter.format(this.maxStorageSize.bytes - this.currentStorageUsage.bytes, {unit: Unit.GB});
  }

  @computed
  public get currentInvoicesUsage(): number {
    return this.state.CurrentInvoicesCount ?? 0;
  }

  @computed
  public get currentInvoicesPercentageUsage(): number {
    return this.state.InvoicesUsedPercentage ?? 0;
  }

  @computed
  public get currentStoragePercentageUsage(): number {
    return this.state.StorageUsedPercentage ?? 0;
  }

  @computed
  public get storagePercentageLeft(): number {
    return Number((100 - this.currentStoragePercentageUsage).toFixed(2));
  }

  @computed
  public get invoicesPercentageLeft(): number {
    return Number((100 - this.currentInvoicesPercentageUsage).toFixed(2));
  }

  @computed
  public get maxInvoicesSize(): number {
    const invoicesAvailable = this.state.Items?.find(el => el.PackageTypeEnum === PackageType.Invoice);
    return (this.state.Items && this.state.Items.length > 0 && invoicesAvailable ? (this.state.Items.filter(el => el.PackageTypeEnum === PackageType.Invoice)[0]).PackageValue : 0) ?? 0;
  }

  @computed
  public get availableInvoicesSize(): number {
    return Math.max(0, this.maxInvoicesSize - this.currentInvoicesUsage ?? 0);
  }

  @computed
  public get subscriptionType(): SubscriptionType {
    return this.state.Items?.find?.(item => item.Subscription.SubscriptionType)?.Subscription?.SubscriptionType ?? SubscriptionType.Freemium;
  }

  public static create(envObserver: EnvObserverI, initialState?: PackageStatisticsInterface): PackageStatisticsRepositoryInterface {
    return new PackageStatisticsRepository(envObserver, initialState);
  }

  public checkSubscriptionWasActiveFor(timestamp: number, date?: Date): boolean | null {
    date ??= this.state.DateTo;
    const dayTimestamp = (new Date(timestamp)).setHours(0, 0, 0, 0);
    return date ? (Math.round(date.setHours(0, 0, 0, 0) + this.ONE_DAY - dayTimestamp) / this.ONE_DAY) > 0 : null;
  }

  public setState(state: PackageStatisticsState) {
    this.model.set(state);
    this.initialize();
  }

  @action.bound
  public async load(handlers?: LoadingHandlers) {
    this.observableQuery?.unsubscribe();
    this.observableQuery = null;
    this.setLoading(true);
    const query = apolloClient.watchQuery<GetPackageStatisticQuery, GetPackageStatisticQueryVariables>({
      query: GetPackageStatisticDocument,
      context: {
        envId: this.envObserver.currentEnv.companyId,
      },
      variables: {TenantId: this.envObserver.currentEnv.tenantId},
    });
    this.observableQuery = query.subscribe(async ({data, loading, errors}) => {
      if (errors) {
        l.error('Błąd wczytywania statystyk pakietu', errors, errors);
        const [{extensions}] = errors;
        const code = (extensions as { code?: string })?.code;
        const message = (extensions as { message?: string })?.message;
        this.setSubscriptionNotFound(code);
        this.subscriptionNotFound || this.setLoadingError(handlers?.mapErrorMessage?.([...errors]) ?? message);
      } else if (data && !isEmpty(data)) {
        this.setState(this.mapResponseToState(data));
        this.setTenantId(this.envObserver.currentEnv.tenantId ?? '');
        this.setSubscriptionNotFound(null);
        this.setLoadingError(undefined);
      }
      this.setLoading(false);
      this.setLoaded(true);
      this.initialize();
      this.observableQuery = null;
      await handlers?.onLoad?.(this);
    });
  }

  @action.bound
  public reset() {
    this.setLoading(false);
    this.model.reset();
    this.initialize(false);
    this.setSubscriptionNotFound(null);
    this.setLoaded(false);
  }

  public async sendSerialNumber(SerialNumber: string, {
    onSend,
    onError,
  }: SendSerialNumberHandlers) {

    const {
      data,
      errors,
    } = await apolloClient.mutate<SetSubscriptionTenantMutation, SetSubscriptionTenantMutationVariables>({
      mutation: SetSubscriptionTenantDocument,
      context: {envId: this.envObserver.currentEnv.tenantId},
      variables: {TenantId: this.envObserver.currentEnv.tenantId, SerialNumber},
      errorPolicy: 'all',
    });

    if (errors) {
      l.error('Błąd dodawania numeru seryjnego', errors, errors);
      onError?.([...errors]);
      return;
    }

    if (data) {
      this.setState({SerialNumber});
      await onSend?.(this);
    }
  }

  @action.bound
  public setEnabled(enabled: boolean): void {
    this.enabled = enabled;
  }

  @action.bound
  protected setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @action.bound
  protected setLoadingError(error?: string): void {
    this.loadingError = error;
  }

  @action.bound
  protected setSubscriptionNotFound(code?: string | null): void {
    if (code === null) {
      this.subscriptionNotFound = false;
      return;
    }
    this.subscriptionNotFound = code ? ERROR_CODES.some(pattern => code.includes(pattern)) : true;
  }

  protected mapResponseToState(response: GetPackageStatisticQuery): PackageStatisticsState {
    const {DateTo, Items, ...rest} = response.GetPackageStatistic;
    const items = Items?.filter(Boolean).reduce(
      (packages, currentPackage) => {
        const existingPackage = packages.find((pcg: PackageStatisticsItemFragment) => pcg.PackageTypeEnum === currentPackage.PackageTypeEnum);
        if (existingPackage) {
          return packages.map((pcg) => pcg === existingPackage ? {
              ...pcg,
              PackageValue: String(+pcg.PackageValue + +currentPackage.PackageValue),
            } : pcg,
          );
        }
        return [...packages, currentPackage];
      }, [] as PackageStatisticsItemFragment[]);

    return {
      ...rest,
      DateTo: DateTo ? new Date(DateTo) : DateTo,
      Items: items?.map((item) => ({
        ...item,
        PackageValue: item?.PackageValue ? +item.PackageValue : undefined,
      })) as Package[],
    };
  }


  private readonly envSubscriber: EnvSubscriber = () => {
    if (this.envObserver.currentEnv.companyId && this.envObserver.currentEnv.tenantId) {
      this.reset();
      this.load();
    }
  };

  @action.bound
  private setTenantId(tenantId: string): void {
    this.tenantId = tenantId;
  }

  @action
  private setLoaded(loaded: boolean): void {
    this.loaded = loaded;
  }

  private checkPackageIsEnding(targetPackage: Package | undefined, value: number | undefined, limit: number = 0.8): boolean {
    return !!(value && targetPackage && (value / +(targetPackage.PackageValue ?? 0) > limit));
  }

  private checkPercentageIsEnding(current: number) {
    return 100 - current < 100 - this.packageThresholds[1];
  }

  private findPackage(type: PackageType): Package | undefined {
    return this.state.Items?.find((pcg) => pcg.PackageTypeEnum === type);
  }

  private getPackageValueLeft(targetPackage: Package | undefined, value: number | undefined, rounding?: number): number | null {
    return value !== undefined && targetPackage?.PackageValue ? +(targetPackage.PackageValue - value).toFixed(rounding) : null;
  }

  @action.bound
  private initialize(isInitialized?: boolean) {
    this.initialized = isInitialized ?? true;
  }
}

export type LoadStatisticsArgs = { tenantId: string, envId: string }

export type LoadingHandlers = { mapErrorMessage?: (errors: GraphQLError[]) => string | undefined, onLoad?: (packageStatistics: PackageStatisticsRepositoryInterface) => Promise<void> | void }
export type SendSerialNumberHandlers = { onError?: (errors: GraphQLError[]) => void, onSend?: (packageStatistics: PackageStatisticsRepositoryInterface) => Promise<void> | void }

export interface PackageStatisticsRepositoryInterface {
  tenantId: string
  state: PackageStatisticsState;
  enabled: boolean;
  initialized: boolean;
  loaded: boolean;
  loading: boolean;
  subscriptionNotFound: boolean;
  subscriptionIsActive: boolean | null;
  subscriptionIsInactive: boolean | null;
  subscriptionIsSuspended: boolean | null;
  shouldGetSerialNumber: boolean | null;
  invoicePackageIsEnding: boolean;
  storagePackageIsEnding: boolean;
  packageDateIsEnding: boolean | null;
  invoicesLeft: number | null;
  storageLeft: number | null;
  storagePackageUsed: boolean;
  invoicesPackageUsed: boolean;
  isPackageUsed: boolean;
  isPackageNotActive: boolean;
  loadingError?: string;
  outOfLimit: boolean,
  deactivationDate: Date | null;
  packageThresholds: [number, number, number];
  expiringThreshold: number;

  get subscriptionType(): SubscriptionType

  get currentStorageUsage(): BytesConverter;

  get maxStorageSize(): BytesConverter;

  get availableStorageSize(): BytesConverter

  get currentInvoicesUsage(): number

  get currentInvoicesPercentageUsage(): number

  get currentStoragePercentageUsage(): number

  get storagePercentageLeft(): number

  get invoicesPercentageLeft(): number

  get maxInvoicesSize(): number

  get availableInvoicesSize(): number

  setState(state: PackageStatisticsState): void;

  checkSubscriptionWasActiveFor(timestamp: number, date?: Date): boolean | null

  load(handlers?: LoadingHandlers): Promise<void>;

  sendSerialNumber(serialNumber: string, handlers?: SendSerialNumberHandlers): Promise<void>;

  setEnabled(enabled: boolean): void;

  reset(): void;
}

