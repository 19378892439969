import {action, computed, makeObservable, observable} from 'mobx';
import {calculateInvoiceSums, ColumnSetting, InvoiceSum} from '../../modules/earchive/modules/Invoices/helpers/invoicesUtils';
import {BaseModule} from '../../modules/root/services/MobXServices/BaseModule';
import {InvoicesTableI} from '../../modules/earchive/modules/Invoices/InvoicesTable/invoicesTableConfig';
import {InvoicesSumPersistService, InvoicesSumPersistServiceInterface} from './InvoicesSumPersistService';

export interface InvoicesSumInterface {
  envId: string | null;
  companyId: string | null;
  isOn: boolean;
  setSumEnabled: (state: boolean) => void;
  sumData: InvoiceSum[];
  columnsForSum: ColumnSetting[];
}

export class InvoicesSumState extends BaseModule implements InvoicesSumInterface {
  @observable
  public envId: string | null = null;

  @observable
  public companyId: string | null = null;

  @observable
  public isOn = false;
  
  private persistService: InvoicesSumPersistServiceInterface;

  constructor(
    private readonly tableService: InvoicesTableI,
    storageKey: string,
    {envId, companyId}: {envId: string | null; companyId: string | null}
  ) {
    super();
    makeObservable(this);
    this.envId = envId;
    this.companyId = companyId;
    this.persistService = new InvoicesSumPersistService(storageKey, this);
    this.persistService.load();
  }

  @action.bound
  public setSumEnabled(state: boolean): void {
    this.isOn = state;
    this.persistService.save({isOn: this.isOn});
  }

  @computed
  public get sumData() {
    const rowsToSum = this.tableService?.selected?.length ? this.tableService?.selected : this.tableService?.rows;
    return calculateInvoiceSums(rowsToSum);
  }

  @computed
  public get columnsForSum() {
    const columns = this.tableService.columns;
    return (Object.keys(columns) as Array<keyof typeof columns>)
      .filter(key => !columns[key]?.hidden)
      .map(key => {
        return {
          key: key,
          width: columns[key]?.width,
          order: columns[key]?.order,
          alignRight: columns[key]?.alignRight
        };
      });
  }

}

