import {InvoiceDto} from "@symfonia-ksef/graphql";

export type InvoiceDtoPartial = Partial<InvoiceDto>;
export type InvoiceSum = Pick<InvoiceDtoPartial, "Currency" | "GrossValue" | "NetValue" | "VatValue">;
export interface ColumnSetting {
	key: keyof InvoiceDtoPartial,
	width?: string,
	order?: number,
	alignRight?: boolean,
}

const MAIN_CURRENCY = "PLN";

const roundNumberToDecimal = (num: number, decimal = 2) => Number(num.toFixed(decimal));
  
export function calculateInvoiceSums(invoices: InvoiceDtoPartial[]): InvoiceSum[] {
  const sumsByCurrency = aggregateInvoiceSums(invoices);
  const sumsList = Object.values(sumsByCurrency);
  return sortCurrencySums(sumsList);
}
  
export function aggregateInvoiceSums(invoices: InvoiceDtoPartial[]): Record<string, InvoiceSum> {
  return invoices.reduce<Record<string, InvoiceDtoPartial>>((sums, invoice) => {
    const {Currency, GrossValue, NetValue, VatValue} = invoice;

    if (Currency) {
      if (!sums[Currency]) {
          sums[Currency] = {Currency, GrossValue: 0, NetValue: 0, VatValue: 0};
      }
      sums[Currency].GrossValue += GrossValue;
      sums[Currency].NetValue += NetValue;
      sums[Currency].VatValue += VatValue;
    }

    Object.keys(sums).forEach(currency => {
      sums[currency].GrossValue = roundNumberToDecimal(sums[currency].GrossValue);
      sums[currency].NetValue = roundNumberToDecimal(sums[currency].NetValue);
      sums[currency].VatValue = roundNumberToDecimal(sums[currency].VatValue);
    });

    return sums;
  }, {});
}
  
function sortCurrencySums(sums: InvoiceDtoPartial[]): InvoiceSum[] {
  return sums.sort((a, b) => {
    if (a.Currency === MAIN_CURRENCY) return -1;
    if (b.Currency === MAIN_CURRENCY) return 1;
    return 0;
  });
}