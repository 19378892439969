import {FormattedMessage} from 'react-intl';
import {EArchiveNotificationType, EnvironmentType, PlatformNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Toggle} from '@symfonia/brandbook';
import {Text} from '../../../root/components/Text';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const adminDefaultList = [
  'COMPANY_CREATED_IN_TENANT',
  'COMPANY_INFO_EDITED',
  'NONE',
  'ORGANIZATION_CREATED',
  'TENANT_INFO_EDITED',
  'USER_ASSIGNED_TO_COMPANY',
];

export interface NotificationsListProps<T> {
  email: string;
  userNotifications: Array<string | undefined | null> | undefined | null;
  removeNotification: (n: T) => void;
  addNotification: (n: T) => void;
  loading: boolean;
}

const defaultList = [
  PlatformNotificationType.TenantDeleted,
  PlatformNotificationType.CompanyDeleted,
  PlatformNotificationType.TenantDeactivated,
  PlatformNotificationType.OrganizationCreated,
  PlatformNotificationType.TenantDeactivated,
  PlatformNotificationType.TenantActivated,
  PlatformNotificationType.TenantRejected,
  EArchiveNotificationType.AuthorizationOfCompanyFailed,
  EArchiveNotificationType.AutoUnauthorizationUserInKSeF,
  EArchiveNotificationType.InvoicesNotFetched,
];

enum NotificationsOrder {
  'NONE' = 0,
  'AUTHORIZATION_OF_COMPANY_SUCCEEDED' = 1,
  'DOWNLOAD_INVOICES_FROM_K_SE_F' = 2,
  'SEND_INVOICES_TO_K_SE_F' = 3,
  'DOWNLOAD_UPO_SUCCEEDED' = 4,
  'PERMISSIONS_EDITED' = 5,
  'AUTHORIZATION_OF_COMPANY_FAILED' = 6,
}

function sortEArchiveNotificationsByOrder(): string[] {
  return Object.values(EArchiveNotificationType).sort(
    (a, b) =>
      NotificationsOrder[a as keyof typeof NotificationsOrder] -
      NotificationsOrder[b as keyof typeof NotificationsOrder],
  );
}

export const NotificationsList = observer(({
                                             email,
                                             userNotifications,
                                             removeNotification,
                                             addNotification,
                                             loading,
                                           }: NotificationsListProps<EArchiveNotificationType | PlatformNotificationType>) => {

  const isCompanyEnvironment = earchiveState.tenant.checkEnvironmentType(EnvironmentType.Company);
  const list = isCompanyEnvironment
    ? (sortEArchiveNotificationsByOrder() as EArchiveNotificationType[])
    : Object.values(PlatformNotificationType).filter(el => adminDefaultList.find(i => i === el));

  const removeOrAddNotification = (e: boolean, notification: string | undefined) => {
    const notificationType = notification as EArchiveNotificationType;
    e ? handleAddNotification(notificationType) : handleRemoveNotification(notificationType);
  };

  const handleAddNotification = async (notification: EArchiveNotificationType) => {
    if (notification === EArchiveNotificationType.AuthorizationOfCompanySucceeded) {
        await addNotification(EArchiveNotificationType.AuthorizationOfCompanySucceeded);
        await addNotification(EArchiveNotificationType.AuthorizationOfCompanyFailed);
    } else {
      addNotification(notification);
    }
  };

  const handleRemoveNotification = async (notification: EArchiveNotificationType) => {
    if (notification === EArchiveNotificationType.AuthorizationOfCompanySucceeded) {
        await removeNotification(EArchiveNotificationType.AuthorizationOfCompanySucceeded);
        await removeNotification(EArchiveNotificationType.AuthorizationOfCompanyFailed);
    } else {
      removeNotification(notification);
    }
  };

  return (
    <div className="mt-[40px]">
      <Text>
        <FormattedMessage
          id={Tr.emailNotificationContent}
          values={{
            br: <br/>,
            company: isCompanyEnvironment ? 'firmy i ' : '',
            span: <span style={{fontWeight: 'bold'}}>{email}</span>,
          }}
        />
      </Text>
      <div className="grid grid-cols-1 gap-y-5 mt-8">
        {userNotifications !== undefined &&
          list.map(notification => {
            const isOn = !!userNotifications?.find(e => e === notification);
            if (notification === 'NONE') {
              return null;
            }
            if (defaultList.includes(notification)) {
              return null;
            }
            return (
              <div key={notification + 'NotificationsList'}>
                <div className="flex items-center justify-between w-full">
                  <Toggle
                    disabled={loading}
                    value=""
                    checked={isOn}
                    label={<FormattedMessage id={notification}/>}
                    onChange={checked => removeOrAddNotification(checked, notification)}
                    testId={`${notification}-${isOn ? 'ON' : 'OFF'}`}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
});
