import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  Long: any;
  URL: any;
  UUID: any;
  UnsignedInt: any;
  Upload: any;
};

export type AcceptBeforeSendToFkParams = {
  Scope: AcceptBeforeSendToFkScopeEnum;
};

export enum AcceptBeforeSendToFkScopeEnum {
  AllDecrees = 'ALL_DECREES',
  ExceptOwns = 'EXCEPT_OWNS',
  Undefined = 'UNDEFINED'
}

export type AcceptBeforeSendToFkSetting = {
  CanAcceptOwn: Scalars['Boolean'];
  IsActive: Scalars['Boolean'];
};

export type AcceptBeforeSendToFkSettingResponse = {
  IsActive: Scalars['Boolean'];
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Params?: Maybe<AcceptBeforeSendToFkParams>;
  SettingId: Scalars['UUID'];
  SettingType: SettingTypeEnum;
};

export type AcceptDecreeMutationResponse = {
  IsDone: Scalars['Boolean'];
};

export type AcceptTenantResponse = {
  Accepted: Scalars['Boolean'];
};

export type AccountResponse = {
  AccountType: AccountTypeEnum;
  Children?: Maybe<Array<AccountResponse>>;
  ChildrenIds?: Maybe<Array<Scalars['UUID']>>;
  FinancialYearId: Scalars['UUID'];
  Id: Scalars['UUID'];
  IsDisabled: Scalars['Boolean'];
  IsFinal: Scalars['Boolean'];
  Name: Scalars['String'];
  Number: Scalars['String'];
  Parent?: Maybe<AccountResponse>;
  ParentId?: Maybe<Scalars['UUID']>;
};

export type AccountSettlementsChangesInput = {
  AccountId?: InputMaybe<Scalars['UUID']>;
  SettlementId: Scalars['UUID'];
};

export enum AccountTypeEnum {
  Document = 'DOCUMENT',
  Kup = 'KUP',
  Nkup = 'NKUP',
  Undefined = 'UNDEFINED',
  Vat = 'VAT'
}

export type AccountTypeEnumOperationFilterInput = {
  eq?: InputMaybe<AccountTypeEnum>;
  in?: InputMaybe<Array<AccountTypeEnum>>;
  neq?: InputMaybe<AccountTypeEnum>;
  nin?: InputMaybe<Array<AccountTypeEnum>>;
};

export type AccountViewModel = {
  AccountType: AccountTypeEnum;
  Categories: Array<CategoryViewModel>;
  Children: Array<AccountViewModel>;
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  Id: Scalars['UUID'];
  IsDisabled: Scalars['Boolean'];
  IsFinal: Scalars['Boolean'];
  Name: Scalars['String'];
  Number: Scalars['String'];
  ParentId?: Maybe<Scalars['UUID']>;
  Settlements: Array<SettlementsViewModel>;
};

export type AccountViewModelFilterInput = {
  AccountType?: InputMaybe<AccountTypeEnumOperationFilterInput>;
  Categories?: InputMaybe<ListFilterInputTypeOfCategoryViewModelFilterInput>;
  Children?: InputMaybe<ListFilterInputTypeOfAccountViewModelFilterInput>;
  CompanyId?: InputMaybe<UuidOperationFilterInput>;
  FinancialYearId?: InputMaybe<UuidOperationFilterInput>;
  Id?: InputMaybe<UuidOperationFilterInput>;
  IsDisabled?: InputMaybe<BooleanOperationFilterInput>;
  IsFinal?: InputMaybe<BooleanOperationFilterInput>;
  Name?: InputMaybe<StringOperationFilterInput>;
  Number?: InputMaybe<StringOperationFilterInput>;
  ParentId?: InputMaybe<UuidOperationFilterInput>;
  Settlements?: InputMaybe<ListFilterInputTypeOfSettlementsViewModelFilterInput>;
  and?: InputMaybe<Array<AccountViewModelFilterInput>>;
  or?: InputMaybe<Array<AccountViewModelFilterInput>>;
};

export type ActivateAutoPostingModuleResponse = {
  IsDone: Scalars['Boolean'];
};

export type ActivateTenantResponse = {
  Activated: Scalars['Boolean'];
};

export type AddAttachmentInput = {
  File?: InputMaybe<Scalars['Upload']>;
  InvoiceId: Scalars['UUID'];
  IsPublic: Scalars['Boolean'];
};

export type AddAttachmentResponse = {
  CompanyId: Scalars['UUID'];
  CreatedAt: Scalars['DateTime'];
  CreatedBy: Scalars['UUID'];
  FileName?: Maybe<Scalars['String']>;
  Id: Scalars['UUID'];
  IsPublic: Scalars['Boolean'];
  Size: Scalars['Long'];
  SourceId: Scalars['UUID'];
};

export type AddCloudServiceAvailabilityInput = {
  /** Nazwa modułu */
  CloudModuleType?: InputMaybe<Scalars['String']>;
  /** Indetyfikator firmy */
  CompanyId: Scalars['UUID'];
};

/** Response Id */
export type AddCloudServiceAvailabilityResponse = {
  /** Identyfikator nowego rekordu */
  Id: Scalars['UUID'];
};

/** Dane adresowe firmy */
export type Address = {
  /** Adres */
  AddressLine: Scalars['String'];
  /** Miasto */
  City: Scalars['String'];
  /** Kraj */
  Country?: Maybe<Scalars['String']>;
  /** Województwo */
  State?: Maybe<Scalars['String']>;
  /** Kod pocztowy */
  ZipCode: Scalars['String'];
};

/** Dane adresowe firmy */
export type AddressFilterInput = {
  /** Adres */
  AddressLine?: InputMaybe<StringOperationFilterInput>;
  /** Miasto */
  City?: InputMaybe<StringOperationFilterInput>;
  /** Kraj */
  Country?: InputMaybe<StringOperationFilterInput>;
  /** Województwo */
  State?: InputMaybe<StringOperationFilterInput>;
  /** Kod pocztowy */
  ZipCode?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AddressFilterInput>>;
  or?: InputMaybe<Array<AddressFilterInput>>;
};

/** Dane adresowe firmy */
export type AddressInput = {
  /** Adres */
  AddressLine: Scalars['String'];
  /** Miasto */
  City: Scalars['String'];
  /** Kraj */
  Country?: InputMaybe<Scalars['String']>;
  /** Województwo */
  State?: InputMaybe<Scalars['String']>;
  /** Kod pocztowy */
  ZipCode: Scalars['String'];
};

/** Dane adresowe firmy */
export type AddressSortInput = {
  /** Adres */
  AddressLine?: InputMaybe<SortEnumType>;
  /** Miasto */
  City?: InputMaybe<SortEnumType>;
  /** Kraj */
  Country?: InputMaybe<SortEnumType>;
  /** Województwo */
  State?: InputMaybe<SortEnumType>;
  /** Kod pocztowy */
  ZipCode?: InputMaybe<SortEnumType>;
};

/** Adnotacje do faktury */
export type Adnotacje = {
  /** Procedura marży */
  Marza?: Maybe<TMarza>;
  /** W przypadku dostawy towarów lub świadczenia usług, w odniesieniu do których obowiązek podatkowy powstaje zgodnie z art. 19a ust. 5 pkt 1 lub art. 21 ust. 1 ustawy */
  MetodaKasowa: Scalars['Boolean'];
  /**
   * Odwrotne obciążenie polega na przerzuceniu obowiązku rozliczania podatku VAT ze sprzedawcy na nabywcę.
   * Listę towarów dla transakcji krajowych, co do których ma zastosowanie procedura odwrotnego obciążenia, zawiera załącznik nr 11 do ustawy o VAT.
   * Natomiast lista usług objętych procedurą odwrotnego obciążenia została umieszczona w załączniku nr 14 do ustawy.
   */
  OdwrotneObciazenie: Scalars['Boolean'];
  /**
   * W przypadku faktur, w których kwota należności ogółem przekracza kwotę 15 000 zł lub jej równowartość wyrażoną w walucie obcej,
   * obejmujących dokonaną na rzecz podatnika dostawę towarów lub świadczenie usług,
   * o których mowa w załączniku nr 15 do ustawy - wyrazy "mechanizm podzielonej płatności",
   * przy czym do przeliczania na PLN kwot wyrażonych w walucie obcej stosuje się zasady przeliczania kwot, stosowane w celu określenia podstawy opodatkowania
   */
  PodzielonaPlatnosc: Scalars['Boolean'];
  /** Powód zwolnienia z podatku */
  PowodZwolnienia?: Maybe<TPowodZwolnienia>;
  /** W przypadku wystąpienia procedur marży, o których mowa w art. 119 lub 120 ustawy */
  ProceduraMarzy: Scalars['Boolean'];
  /** W przypadku faktur wystawianych w procedurze uproszczonej przez drugiego w kolejności podatnika, o którym mowa w art. 135 ust. 1 pkt 4 lit. b i c oraz ust. 2, zawierającej adnotację, o której mowa w art. 136 ust. 1 pkt 1 i stwierdzenie, o którym mowa w art. 136 ust. 1 pkt 2 ustawy */
  ProceduraUproszczona: Scalars['Boolean'];
  /** W przypadku faktur, o których mowa w art. 106d ust. 1 ustawy */
  Samofakturowanie: Scalars['Boolean'];
  /** Czy jest to środek trwały */
  SrodekTrwaly: Scalars['Boolean'];
  /** Informacja o środku trwałym */
  SrodekTrwalyInfo?: Maybe<TSrodekTrwaly>;
  /** W przypadku dostawy towarów lub świadczenia usług zwolnionych od podatku na podstawie art. 43 ust. 1, art. 113 ust. 1 i 9 albo przepisów wydanych na podstawie art. 82 ust. 3 ustawy lub na podstawie innych przepisów */
  ZwolnieniaPodmiotowe: Scalars['Boolean'];
};

/** Adnotacje do faktury */
export type AdnotacjeInput = {
  /** Procedura marży */
  Marza?: InputMaybe<TMarzaInput>;
  /** W przypadku dostawy towarów lub świadczenia usług, w odniesieniu do których obowiązek podatkowy powstaje zgodnie z art. 19a ust. 5 pkt 1 lub art. 21 ust. 1 ustawy */
  MetodaKasowa: Scalars['Boolean'];
  /**
   * Odwrotne obciążenie polega na przerzuceniu obowiązku rozliczania podatku VAT ze sprzedawcy na nabywcę.
   * Listę towarów dla transakcji krajowych, co do których ma zastosowanie procedura odwrotnego obciążenia, zawiera załącznik nr 11 do ustawy o VAT.
   * Natomiast lista usług objętych procedurą odwrotnego obciążenia została umieszczona w załączniku nr 14 do ustawy.
   */
  OdwrotneObciazenie: Scalars['Boolean'];
  /**
   * W przypadku faktur, w których kwota należności ogółem przekracza kwotę 15 000 zł lub jej równowartość wyrażoną w walucie obcej,
   * obejmujących dokonaną na rzecz podatnika dostawę towarów lub świadczenie usług,
   * o których mowa w załączniku nr 15 do ustawy - wyrazy "mechanizm podzielonej płatności",
   * przy czym do przeliczania na PLN kwot wyrażonych w walucie obcej stosuje się zasady przeliczania kwot, stosowane w celu określenia podstawy opodatkowania
   */
  PodzielonaPlatnosc: Scalars['Boolean'];
  /** Powód zwolnienia z podatku */
  PowodZwolnienia?: InputMaybe<TPowodZwolnieniaInput>;
  /** W przypadku wystąpienia procedur marży, o których mowa w art. 119 lub 120 ustawy */
  ProceduraMarzy: Scalars['Boolean'];
  /** W przypadku faktur wystawianych w procedurze uproszczonej przez drugiego w kolejności podatnika, o którym mowa w art. 135 ust. 1 pkt 4 lit. b i c oraz ust. 2, zawierającej adnotację, o której mowa w art. 136 ust. 1 pkt 1 i stwierdzenie, o którym mowa w art. 136 ust. 1 pkt 2 ustawy */
  ProceduraUproszczona: Scalars['Boolean'];
  /** W przypadku faktur, o których mowa w art. 106d ust. 1 ustawy */
  Samofakturowanie: Scalars['Boolean'];
  /** Czy jest to środek trwały */
  SrodekTrwaly: Scalars['Boolean'];
  /** Informacja o środku trwałym */
  SrodekTrwalyInfo?: InputMaybe<TSrodekTrwalyInput>;
  /** W przypadku dostawy towarów lub świadczenia usług zwolnionych od podatku na podstawie art. 43 ust. 1, art. 113 ust. 1 i 9 albo przepisów wydanych na podstawie art. 82 ust. 3 ustawy lub na podstawie innych przepisów */
  ZwolnieniaPodmiotowe: Scalars['Boolean'];
};

/** Adnotacje do faktury */
export type AdnotacjeV2 = {
  /** Procedura marży */
  Marza?: Maybe<TMarzaV2>;
  /** W przypadku dostawy towarów lub świadczenia usług, w odniesieniu do których obowiązek podatkowy powstaje zgodnie z art. 19a ust. 5 pkt 1 lub art. 21 ust. 1 ustawy */
  MetodaKasowa: Scalars['Boolean'];
  /** Informacja o środku trwałym */
  NoweSrodkiTransportu?: Maybe<DostawaNowychSrodkowTransportuV2>;
  /**
   * Odwrotne obciążenie polega na przerzuceniu obowiązku rozliczania podatku VAT ze sprzedawcy na nabywcę.
   * Listę towarów dla transakcji krajowych, co do których ma zastosowanie procedura odwrotnego obciążenia, zawiera załącznik nr 11 do ustawy o VAT.
   * Natomiast lista usług objętych procedurą odwrotnego obciążenia została umieszczona w załączniku nr 14 do ustawy.
   */
  OdwrotneObciazenie: Scalars['Boolean'];
  /**
   * W przypadku faktur, w których kwota należności ogółem przekracza kwotę 15 000 zł lub jej równowartość wyrażoną w walucie obcej,
   * obejmujących dokonaną na rzecz podatnika dostawę towarów lub świadczenie usług,
   * o których mowa w załączniku nr 15 do ustawy - wyrazy "mechanizm podzielonej płatności",
   * przy czym do przeliczania na PLN kwot wyrażonych w walucie obcej stosuje się zasady przeliczania kwot, stosowane w celu określenia podstawy opodatkowania
   */
  PodzielonaPlatnosc: Scalars['Boolean'];
  /** Powód zwolnienia z podatku */
  PowodZwolnienia?: Maybe<TPowodZwolnieniaV2>;
  /** W przypadku wystąpienia procedur marży, o których mowa w art. 119 lub 120 ustawy */
  ProceduraMarzy: Scalars['Boolean'];
  /** W przypadku faktur wystawianych w procedurze uproszczonej przez drugiego w kolejności podatnika, o którym mowa w art. 135 ust. 1 pkt 4 lit. b i c oraz ust. 2, zawierającej adnotację, o której mowa w art. 136 ust. 1 pkt 1 i stwierdzenie, o którym mowa w art. 136 ust. 1 pkt 2 ustawy */
  ProceduraUproszczona: Scalars['Boolean'];
  /** W przypadku faktur, o których mowa w art. 106d ust. 1 ustawy */
  Samofakturowanie: Scalars['Boolean'];
  /** W przypadku dostawy towarów lub świadczenia usług zwolnionych od podatku na podstawie art. 43 ust. 1, art. 113 ust. 1 i 9 albo przepisów wydanych na podstawie art. 82 ust. 3 ustawy lub na podstawie innych przepisów */
  ZwolnieniaPodmiotowe: Scalars['Boolean'];
};

/** Adnotacje do faktury */
export type AdnotacjeV2Input = {
  /** Procedura marży */
  Marza?: InputMaybe<TMarzaV2Input>;
  /** W przypadku dostawy towarów lub świadczenia usług, w odniesieniu do których obowiązek podatkowy powstaje zgodnie z art. 19a ust. 5 pkt 1 lub art. 21 ust. 1 ustawy */
  MetodaKasowa: Scalars['Boolean'];
  /** Informacja o środku trwałym */
  NoweSrodkiTransportu?: InputMaybe<DostawaNowychSrodkowTransportuV2Input>;
  /**
   * Odwrotne obciążenie polega na przerzuceniu obowiązku rozliczania podatku VAT ze sprzedawcy na nabywcę.
   * Listę towarów dla transakcji krajowych, co do których ma zastosowanie procedura odwrotnego obciążenia, zawiera załącznik nr 11 do ustawy o VAT.
   * Natomiast lista usług objętych procedurą odwrotnego obciążenia została umieszczona w załączniku nr 14 do ustawy.
   */
  OdwrotneObciazenie: Scalars['Boolean'];
  /**
   * W przypadku faktur, w których kwota należności ogółem przekracza kwotę 15 000 zł lub jej równowartość wyrażoną w walucie obcej,
   * obejmujących dokonaną na rzecz podatnika dostawę towarów lub świadczenie usług,
   * o których mowa w załączniku nr 15 do ustawy - wyrazy "mechanizm podzielonej płatności",
   * przy czym do przeliczania na PLN kwot wyrażonych w walucie obcej stosuje się zasady przeliczania kwot, stosowane w celu określenia podstawy opodatkowania
   */
  PodzielonaPlatnosc: Scalars['Boolean'];
  /** Powód zwolnienia z podatku */
  PowodZwolnienia?: InputMaybe<TPowodZwolnieniaV2Input>;
  /** W przypadku wystąpienia procedur marży, o których mowa w art. 119 lub 120 ustawy */
  ProceduraMarzy: Scalars['Boolean'];
  /** W przypadku faktur wystawianych w procedurze uproszczonej przez drugiego w kolejności podatnika, o którym mowa w art. 135 ust. 1 pkt 4 lit. b i c oraz ust. 2, zawierającej adnotację, o której mowa w art. 136 ust. 1 pkt 1 i stwierdzenie, o którym mowa w art. 136 ust. 1 pkt 2 ustawy */
  ProceduraUproszczona: Scalars['Boolean'];
  /** W przypadku faktur, o których mowa w art. 106d ust. 1 ustawy */
  Samofakturowanie: Scalars['Boolean'];
  /** W przypadku dostawy towarów lub świadczenia usług zwolnionych od podatku na podstawie art. 43 ust. 1, art. 113 ust. 1 i 9 albo przepisów wydanych na podstawie art. 82 ust. 3 ustawy lub na podstawie innych przepisów */
  ZwolnieniaPodmiotowe: Scalars['Boolean'];
};

/** Dane adresowe podmiotów wyszczególnionych na fakturze */
export type Adres = {
  /** Globalny numer lokalizacyjny */
  GLN?: Maybe<Scalars['String']>;
  /** Gmina */
  Gmina?: Maybe<Scalars['String']>;
  /** Kod kraju */
  KodKraju?: Maybe<KodKraju>;
  /** Kod pocztowy */
  KodPocztowy?: Maybe<Scalars['String']>;
  /** Miejscowość */
  Miejscowosc?: Maybe<Scalars['String']>;
  /** Numer domu */
  NrDomu?: Maybe<Scalars['String']>;
  /** Numer lokalu */
  NrLokalu?: Maybe<Scalars['String']>;
  /** Poczta */
  Poczta?: Maybe<Scalars['String']>;
  /** Powiat */
  Powiat?: Maybe<Scalars['String']>;
  /** Ulica */
  Ulica?: Maybe<Scalars['String']>;
  /** Województwo */
  Wojewodztwo?: Maybe<Scalars['String']>;
};

/** Dane adresowe podmiotów wyszczególnionych na fakturze */
export type AdresInput = {
  /** Globalny numer lokalizacyjny */
  GLN?: InputMaybe<Scalars['String']>;
  /** Gmina */
  Gmina?: InputMaybe<Scalars['String']>;
  /** Kod kraju */
  KodKraju?: InputMaybe<KodKraju>;
  /** Kod pocztowy */
  KodPocztowy?: InputMaybe<Scalars['String']>;
  /** Miejscowość */
  Miejscowosc?: InputMaybe<Scalars['String']>;
  /** Numer domu */
  NrDomu?: InputMaybe<Scalars['String']>;
  /** Numer lokalu */
  NrLokalu?: InputMaybe<Scalars['String']>;
  /** Poczta */
  Poczta?: InputMaybe<Scalars['String']>;
  /** Powiat */
  Powiat?: InputMaybe<Scalars['String']>;
  /** Ulica */
  Ulica?: InputMaybe<Scalars['String']>;
  /** Województwo */
  Wojewodztwo?: InputMaybe<Scalars['String']>;
};

/** Dane adresowe podmiotów wyszczególnionych na fakturze */
export type AdresV2 = {
  /**
   * Adres [Address].
   * Typ znakowy ograniczony do 512 znaków.
   * Minimum length: 1.
   * Maximum length: 512.
   */
  AdresL1?: Maybe<Scalars['String']>;
  /**
   * Adres [Address].
   * Typ znakowy ograniczony do 512 znaków.
   * Minimum length: 1.
   * Maximum length: 512.
   */
  AdresL2?: Maybe<Scalars['String']>;
  /** Globalny numer lokalizacyjny */
  GLN?: Maybe<Scalars['String']>;
  /** Kod kraju */
  KodKraju?: Maybe<KodKraju>;
};

/** Dane adresowe podmiotów wyszczególnionych na fakturze */
export type AdresV2Input = {
  /**
   * Adres [Address].
   * Typ znakowy ograniczony do 512 znaków.
   * Minimum length: 1.
   * Maximum length: 512.
   */
  AdresL1?: InputMaybe<Scalars['String']>;
  /**
   * Adres [Address].
   * Typ znakowy ograniczony do 512 znaków.
   * Minimum length: 1.
   * Maximum length: 512.
   */
  AdresL2?: InputMaybe<Scalars['String']>;
  /** Globalny numer lokalizacyjny */
  GLN?: InputMaybe<Scalars['String']>;
  /** Kod kraju */
  KodKraju?: InputMaybe<KodKraju>;
};

/** Status klucza API na podstawie wpisu w tabeli ApiKeyStatus */
export enum ApiKeyStatusType {
  /** Dla wpisu w tabeli ApiKeyStatus z flagą IsEnabled == false */
  Disabled = 'DISABLED',
  /** Dla wpisu w tabeli ApiKeyStatus z flagą IsEnabled == true */
  Enabled = 'ENABLED',
  /** Dla braku wpisu w tabeli ApiKeyStatus */
  NeverEnabled = 'NEVER_ENABLED'
}

export type ApiKeyViewModel = {
  CompanyName?: Maybe<Scalars['String']>;
  CreatedAt?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  Id: Scalars['UUID'];
  IsActive: Scalars['Boolean'];
  Key?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type ApiKeysViewModelResponse = {
  ApiKeyStatusType: ApiKeyStatusType;
  Items?: Maybe<Array<Maybe<ApiKeyViewModel>>>;
};

export type AppStream = {
  AppStreamApplications: Array<AppStreamApplication>;
  AppStreamDbBackups: Array<AppStreamDbBackup>;
  Company: Company;
  CompanyId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  ExpiredDate?: Maybe<Scalars['DateTime']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  LicenseStatus: AppStreamLicenseStatus;
  LicenseType: AppStreamLicenseType;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  ProvisioningStatus: AppStreamProvisioningStatus;
};

export type AppStreamApplication = {
  AppStream: AppStream;
  AppStreamId: Scalars['UUID'];
  ApplicationType: Scalars['String'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppStreamApplicationFilterInput = {
  AppStream?: InputMaybe<AppStreamFilterInput>;
  AppStreamId?: InputMaybe<UuidOperationFilterInput>;
  ApplicationType?: InputMaybe<StringOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AppStreamApplicationFilterInput>>;
  or?: InputMaybe<Array<AppStreamApplicationFilterInput>>;
};

export type AppStreamApplicationInput = {
  AppStream: AppStreamInput;
  AppStreamId: Scalars['UUID'];
  ApplicationType: Scalars['String'];
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<Scalars['String']>;
};

export type AppStreamBackup = {
  AppStreamRestores?: Maybe<Array<Maybe<AppStreamRestore>>>;
  BackupDate: Scalars['DateTime'];
  CreatedBy?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  TaskId: Scalars['UUID'];
  Time?: Maybe<Scalars['Int']>;
};

export type AppStreamBackupResponse = {
  ActiveSessionsLength?: Maybe<Scalars['Int']>;
  ActiveUsers?: Maybe<Array<Scalars['String']>>;
  CompanyId: Scalars['UUID'];
  CorrelationId?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  Status: Scalars['Int'];
  Task?: Maybe<Scalars['UUID']>;
  TenantId: Scalars['UUID'];
};

/** AppStream statusy kopii zapasowej */
export enum AppStreamBackupStatus {
  /** Anulowane */
  Cancelled = 'CANCELLED',
  /** Zadanie zlecone */
  Created = 'CREATED',
  /** Zakończone z błedem */
  Error = 'ERROR',
  /** W trakcie wykonywania */
  InProgress = 'IN_PROGRESS',
  /** Zakończone z sukcesem */
  Success = 'SUCCESS'
}

export type AppStreamBackupStatusOperationFilterInput = {
  eq?: InputMaybe<AppStreamBackupStatus>;
  in?: InputMaybe<Array<AppStreamBackupStatus>>;
  neq?: InputMaybe<AppStreamBackupStatus>;
  nin?: InputMaybe<Array<AppStreamBackupStatus>>;
};

export type AppStreamDbBackup = {
  AppStream?: Maybe<AppStream>;
  AppStreamDbRestores?: Maybe<Array<Maybe<AppStreamDbRestore>>>;
  AppStreamId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  Status: AppStreamBackupStatus;
  TaskId: Scalars['UUID'];
};

export type AppStreamDbBackupFilterInput = {
  AppStream?: InputMaybe<AppStreamFilterInput>;
  AppStreamDbRestores?: InputMaybe<ListFilterInputTypeOfAppStreamDbRestoreFilterInput>;
  AppStreamId?: InputMaybe<UuidOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  FileName?: InputMaybe<StringOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  Status?: InputMaybe<AppStreamBackupStatusOperationFilterInput>;
  TaskId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<AppStreamDbBackupFilterInput>>;
  or?: InputMaybe<Array<AppStreamDbBackupFilterInput>>;
};

export type AppStreamDbBackupInput = {
  AppStream?: InputMaybe<AppStreamInput>;
  AppStreamDbRestores?: InputMaybe<Array<InputMaybe<AppStreamDbRestoreInput>>>;
  AppStreamId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<Scalars['String']>;
  FileName?: InputMaybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Status: AppStreamBackupStatus;
  TaskId: Scalars['UUID'];
};

export type AppStreamDbRestore = {
  AppStreamDbBackup?: Maybe<AppStreamDbBackup>;
  AppStreamDbBackupId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  Status: AppStreamBackupStatus;
  TaskId: Scalars['UUID'];
};

export type AppStreamDbRestoreFilterInput = {
  AppStreamDbBackup?: InputMaybe<AppStreamDbBackupFilterInput>;
  AppStreamDbBackupId?: InputMaybe<UuidOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  Status?: InputMaybe<AppStreamBackupStatusOperationFilterInput>;
  TaskId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<AppStreamDbRestoreFilterInput>>;
  or?: InputMaybe<Array<AppStreamDbRestoreFilterInput>>;
};

export type AppStreamDbRestoreInput = {
  AppStreamDbBackup?: InputMaybe<AppStreamDbBackupInput>;
  AppStreamDbBackupId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<Scalars['String']>;
  Status: AppStreamBackupStatus;
  TaskId: Scalars['UUID'];
};

export type AppStreamFilterInput = {
  AppStreamApplications?: InputMaybe<ListFilterInputTypeOfAppStreamApplicationFilterInput>;
  AppStreamDbBackups?: InputMaybe<ListFilterInputTypeOfAppStreamDbBackupFilterInput>;
  Company?: InputMaybe<CompanyFilterInput>;
  CompanyId?: InputMaybe<UuidOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  ExpiredDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  LicenseStatus?: InputMaybe<AppStreamLicenseStatusOperationFilterInput>;
  LicenseType?: InputMaybe<AppStreamLicenseTypeOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  ProvisioningStatus?: InputMaybe<AppStreamProvisioningStatusOperationFilterInput>;
  and?: InputMaybe<Array<AppStreamFilterInput>>;
  or?: InputMaybe<Array<AppStreamFilterInput>>;
};

export type AppStreamInput = {
  AppStreamApplications: Array<AppStreamApplicationInput>;
  AppStreamDbBackups: Array<AppStreamDbBackupInput>;
  Company: CreateCompanyRequest;
  CompanyId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<Scalars['String']>;
  ExpiredDate?: InputMaybe<Scalars['DateTime']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  LicenseStatus: AppStreamLicenseStatus;
  LicenseType: AppStreamLicenseType;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<Scalars['String']>;
  ProvisioningStatus: AppStreamProvisioningStatus;
};

/** Status licencji App Stream */
export enum AppStreamLicenseStatus {
  /** Licencja wygasła */
  Expired = 'EXPIRED',
  /** Błąd podczas nadawania licencji */
  Failed = 'FAILED',
  /** Licencja nadana */
  Granted = 'GRANTED',
  /** Oczekiwanie na nadanie licencji */
  Waiting = 'WAITING'
}

export type AppStreamLicenseStatusOperationFilterInput = {
  eq?: InputMaybe<AppStreamLicenseStatus>;
  in?: InputMaybe<Array<AppStreamLicenseStatus>>;
  neq?: InputMaybe<AppStreamLicenseStatus>;
  nin?: InputMaybe<Array<AppStreamLicenseStatus>>;
};

/** Typ licencji App Stream */
export enum AppStreamLicenseType {
  /** Płatna */
  Paid = 'PAID',
  /** Próbna */
  Trial = 'TRIAL'
}

export type AppStreamLicenseTypeOperationFilterInput = {
  eq?: InputMaybe<AppStreamLicenseType>;
  in?: InputMaybe<Array<AppStreamLicenseType>>;
  neq?: InputMaybe<AppStreamLicenseType>;
  nin?: InputMaybe<Array<AppStreamLicenseType>>;
};

export type AppStreamModel = {
  ApplicationType?: Maybe<Scalars['String']>;
  LicenseStatus: AppStreamLicenseStatus;
  LicenseType: AppStreamLicenseType;
  ProvisioningStatus: AppStreamProvisioningStatus;
};

/** Status dostarczenia App Stream */
export enum AppStreamProvisioningStatus {
  /** Błąd w trakcie uruchamiania usługi */
  Failed = 'FAILED',
  /** W trakcie uruchamiania usługi */
  InProgress = 'IN_PROGRESS',
  /** Usługa zablokowana */
  Locked = 'LOCKED',
  /** Usługa nie istnieje */
  NotExists = 'NOT_EXISTS',
  /** Usługa uruchomiona */
  Ready = 'READY'
}

export type AppStreamProvisioningStatusOperationFilterInput = {
  eq?: InputMaybe<AppStreamProvisioningStatus>;
  in?: InputMaybe<Array<AppStreamProvisioningStatus>>;
  neq?: InputMaybe<AppStreamProvisioningStatus>;
  nin?: InputMaybe<Array<AppStreamProvisioningStatus>>;
};

export type AppStreamRestore = {
  CreatedBy?: Maybe<Scalars['String']>;
  RestoreDate: Scalars['DateTime'];
  Status?: Maybe<Scalars['String']>;
  Time?: Maybe<Scalars['Int']>;
};

export type AppStreamSortInput = {
  Company?: InputMaybe<CompanySortInput>;
  CompanyId?: InputMaybe<SortEnumType>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<SortEnumType>;
  ExpiredDate?: InputMaybe<SortEnumType>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<SortEnumType>;
  LicenseStatus?: InputMaybe<SortEnumType>;
  LicenseType?: InputMaybe<SortEnumType>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<SortEnumType>;
  ProvisioningStatus?: InputMaybe<SortEnumType>;
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

export type ArchiveCategoryMutationResponse = {
  IsDone: Scalars['Boolean'];
};

/** Reprezentuje załącznik, czyli dokument przypisany do faktury z serwisu FileHub */
export type Attachment = {
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Data utworzenia */
  CreatedAt: Scalars['DateTime'];
  /** Guid użytkownika tworzącego załącznik */
  CreatedBy: Scalars['UUID'];
  /** Email użytkownika tworzącego załącznik */
  CreatedByEmail?: Maybe<Scalars['String']>;
  /** Nazwa pliku */
  FileName?: Maybe<Scalars['String']>;
  /** Id załącznika */
  Id: Scalars['UUID'];
  /** ID faktury nadany przez Symfonia KSeF */
  InvoiceId: Scalars['UUID'];
  /** True - jeśli załącznik jest publiczny */
  IsPublic: Scalars['Boolean'];
  /** Data modyfikacji */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** Guid użytkownika ostatnio modyfikującego załącznik */
  ModifiedBy?: Maybe<Scalars['UUID']>;
  /** Email użytkownika ostatnio modyfikującego załącznik */
  ModifiedByEmail?: Maybe<Scalars['String']>;
  /** Rozmiar załącznika */
  Size: Scalars['Long'];
};

export type AuthorizeInKSeFWithTokenResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

/** Usługa automatycznego pobierania faktur z KSeF */
export type AutoFetchingInvoices = {
  /** Faktury wcześniejsze niż ustawiona data nie będą pobierane */
  DownloadLimitDate?: Maybe<Scalars['DateTime']>;
  /** Stan pobierania faktur zakupowych */
  ExternalEnabled: Scalars['Boolean'];
  /** Stan pobierania faktur sprzedażowych */
  InternalEnabled: Scalars['Boolean'];
  /** Adres email użytkownika który ostatni modyfikował działanie usługi */
  LastModifiedBy?: Maybe<Scalars['String']>;
  /** Data ostatniej modyfikacji */
  LastModifiedDate?: Maybe<Scalars['DateTime']>;
  /** Czas w minutach */
  Minutes?: Maybe<Scalars['Int']>;
  /** Stan usługi automatycznego pobierania faktur */
  Status: Scalars['Boolean'];
  /** Typ pobierania */
  Type?: Maybe<KSeFAutomaticOperationType>;
};

export type AutoPostingOfInvoicesSettingResponse = {
  IsActive: Scalars['Boolean'];
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SettingId: Scalars['UUID'];
  SettingType: SettingTypeEnum;
};

export type AutoPostingSettingChangeInput = {
  IsActive: Scalars['Boolean'];
  Params?: InputMaybe<Scalars['String']>;
  SettingId: Scalars['UUID'];
};

export type AutoPostingSettingsResponse = {
  AcceptBeforeSendToFK?: Maybe<AcceptBeforeSendToFkSettingResponse>;
  AutoPostingOfInvoices?: Maybe<AutoPostingOfInvoicesSettingResponse>;
};

/** Ustawienia modułu automatycznych dekretacji */
export type AutoPostingUserPropertiesType = {
  /** Funkcjonalność wymuszająca akceptacje dekretu przed wysłaniem do systemu Symfonia FK. */
  AcceptBeforeSendToFK?: Maybe<AcceptBeforeSendToFkSetting>;
  /** Status modułu automatycznych dekretacji. Jeżeli 'true' - moduł jest aktywny i skonfigurowany. 'false' - moduł albo nie jest aktywny albo nie jest skonfigurowany. */
  Status: Scalars['Boolean'];
};

/** Usługa automatycznego wysyłania faktur do KSeF */
export type AutoSendingInvoices = {
  /** Adres email użytkownika który ostatni modyfikował działanie usługi */
  LastModifiedBy?: Maybe<Scalars['String']>;
  /** Data ostatniej modyfikacji */
  LastModifiedDate?: Maybe<Scalars['DateTime']>;
  /** Czas w minutach */
  Minutes?: Maybe<Scalars['Int']>;
  /** Stan usługi automatycznego pobierania faktur */
  Status: Scalars['Boolean'];
  /** Typ wysyłki */
  Type?: Maybe<KSeFAutomaticOperationType>;
};

/** Pojedynczy rekord faktury z pakietu */
export type BatchResponseItem = {
  /** Lista błędów */
  Errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID faktury nadany przez Symfonia KSeF */
  InvoiceId?: Maybe<Scalars['UUID']>;
  /** Numer faktury */
  InvoiceNumber?: Maybe<Scalars['String']>;
  /** Data wystawienia */
  InvoicingDate: Scalars['DateTime'];
  /** Nip nabywcy */
  RecipientNip?: Maybe<Scalars['String']>;
  /** True - jeżeli faktura została od razu wysłana do KSeF */
  SentToKSeF: Scalars['Boolean'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type CancelBackupResponse = {
  Result: Scalars['Boolean'];
};

/** Model odpowiedzi WebSocket */
export type CancelWebsocketNotification = {
  /** Status wykonanej operacji */
  Success: Scalars['Boolean'];
};

export type CategoryAccountChangeRequestInput = {
  AccountId: Scalars['UUID'];
  CategoryId?: InputMaybe<Scalars['UUID']>;
  DecreeLineAccountType: AccountTypeEnum;
  DecreeLineType: DecreeLineTypeEnum;
};

export type CategoryAccountRequestInput = {
  AccountId?: InputMaybe<Scalars['UUID']>;
  AccountType: AccountTypeEnum;
  CategoryId: Scalars['UUID'];
};

export type CategoryRuleResponse = {
  CurrentCategoryName: Scalars['String'];
  Id: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  IsActive: Scalars['Boolean'];
  ModifiedAt: Scalars['DateTime'];
  PositionName: Scalars['String'];
  PreviousCategoryName: Scalars['String'];
  UserFirstName: Scalars['String'];
  UserId?: Maybe<Scalars['UUID']>;
  UserLastName: Scalars['String'];
};

export type CategoryRuleUserResponse = {
  UserFirstName: Scalars['String'];
  UserId?: Maybe<Scalars['UUID']>;
  UserLastName: Scalars['String'];
};

export type CategoryRuleViewModel = {
  Category?: Maybe<CategoryViewModel>;
  CompanyId: Scalars['UUID'];
  Id: Scalars['UUID'];
  IsActive: Scalars['Boolean'];
  ModifiedAt: Scalars['DateTime'];
  PositionNames?: Maybe<Array<Scalars['String']>>;
  PreviousCategory?: Maybe<CategoryViewModel>;
  UserId: Scalars['UUID'];
};

export type CategoryRuleViewModelFilterInput = {
  Category?: InputMaybe<CategoryViewModelFilterInput>;
  CompanyId?: InputMaybe<UuidOperationFilterInput>;
  Id?: InputMaybe<UuidOperationFilterInput>;
  IsActive?: InputMaybe<BooleanOperationFilterInput>;
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  PositionNames?: InputMaybe<ListStringOperationFilterInput>;
  PreviousCategory?: InputMaybe<CategoryViewModelFilterInput>;
  UserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<CategoryRuleViewModelFilterInput>>;
  or?: InputMaybe<Array<CategoryRuleViewModelFilterInput>>;
};

export type CategoryRulesFiltersInput = {
  CategoryNames?: InputMaybe<Array<Scalars['String']>>;
  InvoiceTypeGroup?: InputMaybe<Array<InvoiceTypeGroupEnum>>;
  IsActive?: InputMaybe<Scalars['Boolean']>;
  ModifiedAt?: InputMaybe<DateRangeSchemaModelInput>;
  PositionName?: InputMaybe<Array<Scalars['String']>>;
  UserIds?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type CategoryRulesPositionsResponse = {
  Id: Scalars['UUID'];
  PositionName: Scalars['String'];
};

export enum CategoryTypeEnum {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
  Undefined = 'UNDEFINED'
}

export type CategoryTypeEnumOperationFilterInput = {
  eq?: InputMaybe<CategoryTypeEnum>;
  in?: InputMaybe<Array<CategoryTypeEnum>>;
  neq?: InputMaybe<CategoryTypeEnum>;
  nin?: InputMaybe<Array<CategoryTypeEnum>>;
};

export type CategoryViewModel = {
  Accounts: Array<AccountViewModel>;
  CompanyId: Scalars['UUID'];
  CurrentCategoryRules: Array<CategoryRuleViewModel>;
  FinancialYearId: Scalars['UUID'];
  Id: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  IsArchived: Scalars['Boolean'];
  MlKey?: Maybe<Scalars['String']>;
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  Name: Scalars['String'];
  PreviousCategoryRules: Array<CategoryRuleViewModel>;
  Type: CategoryTypeEnum;
};


export type CategoryViewModelAccountsArgs = {
  where?: InputMaybe<AccountViewModelFilterInput>;
};

export type CategoryViewModelFilterInput = {
  Accounts?: InputMaybe<ListFilterInputTypeOfAccountViewModelFilterInput>;
  CompanyId?: InputMaybe<UuidOperationFilterInput>;
  CurrentCategoryRules?: InputMaybe<ListFilterInputTypeOfCategoryRuleViewModelFilterInput>;
  FinancialYearId?: InputMaybe<UuidOperationFilterInput>;
  Id?: InputMaybe<UuidOperationFilterInput>;
  InvoiceTypeGroup?: InputMaybe<InvoiceTypeGroupEnumOperationFilterInput>;
  IsArchived?: InputMaybe<BooleanOperationFilterInput>;
  MlKey?: InputMaybe<StringOperationFilterInput>;
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  Name?: InputMaybe<StringOperationFilterInput>;
  PreviousCategoryRules?: InputMaybe<ListFilterInputTypeOfCategoryRuleViewModelFilterInput>;
  Type?: InputMaybe<CategoryTypeEnumOperationFilterInput>;
  and?: InputMaybe<Array<CategoryViewModelFilterInput>>;
  or?: InputMaybe<Array<CategoryViewModelFilterInput>>;
};

export type CategoryViewModelSortInput = {
  CompanyId?: InputMaybe<SortEnumType>;
  FinancialYearId?: InputMaybe<SortEnumType>;
  Id?: InputMaybe<SortEnumType>;
  InvoiceTypeGroup?: InputMaybe<SortEnumType>;
  IsArchived?: InputMaybe<SortEnumType>;
  MlKey?: InputMaybe<SortEnumType>;
  ModifiedAt?: InputMaybe<SortEnumType>;
  Name?: InputMaybe<SortEnumType>;
  Type?: InputMaybe<SortEnumType>;
};

export type ChangeCategoryNameMutationResponse = {
  IsDone: Scalars['Boolean'];
};

export enum CloudModuleType {
  Admin = 'ADMIN',
  Appstream = 'APPSTREAM',
  Earchive = 'EARCHIVE',
  Ebiuro = 'EBIURO',
  Ksef = 'KSEF',
  N2P = 'N2P',
  Windykacja = 'WINDYKACJA'
}

export type CloudServiceAvailability = {
  CloudModuleType?: Maybe<Scalars['String']>;
  Company?: Maybe<Company>;
  CompanyId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CloudServiceAvailabilityFilterInput = {
  CloudModuleType?: InputMaybe<StringOperationFilterInput>;
  Company?: InputMaybe<CompanyFilterInput>;
  CompanyId?: InputMaybe<UuidOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CloudServiceAvailabilityFilterInput>>;
  or?: InputMaybe<Array<CloudServiceAvailabilityFilterInput>>;
};

export type CloudServiceViewModel = {
  Code: CloudModuleType;
  /** @deprecated This property is obsolete. Use UserHasAccess property instead. */
  IsActive: Scalars['Boolean'];
  /** @deprecated This property is obsolete. Use ModuleIsActive property instead. */
  IsEnabled: Scalars['Boolean'];
  ModuleIsActive: Scalars['Boolean'];
  Name?: Maybe<Scalars['String']>;
  Url?: Maybe<Scalars['String']>;
  UserHasAccess: Scalars['Boolean'];
};

export type CloudViewModel = {
  AppStreams: Array<AppStreamModel>;
  modules?: Maybe<Array<Maybe<CloudServiceViewModel>>>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

/** A segment of a collection. */
export type CompaniesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<Company>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Firma zarządzana przez organizację */
export type Company = {
  /** Dane adresowe firmy */
  Address?: Maybe<Address>;
  /** AppStream firmy */
  AppStream?: Maybe<AppStream>;
  /** Lista aktywnych modułów firmy */
  CloudServiceAvailibilities?: Maybe<Array<Maybe<CloudServiceAvailability>>>;
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Określa czy firma działa w trybie Demo (połączenie z https://ksef-demo.mf.gov.pl/) */
  IsDemo: Scalars['Boolean'];
  /** Czy firma główna w organizacji */
  IsMainCompany: Scalars['Boolean'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** To pole niedługo zostanie usunięte */
  NIP?: Maybe<Scalars['String']>;
  /** Nazwa firmy */
  Name: Scalars['String'];
  /** Lista ról przypisanych do firmy */
  RoleAssignments?: Maybe<Array<Maybe<RoleAssignment>>>;
  /** Organizacja, do której należy firma */
  Tenant?: Maybe<TenantResponse>;
  /** Id organizacji, do której należy firma */
  TenantId: Scalars['UUID'];
  /** Numer VAT UE */
  VatNumberUE?: Maybe<Scalars['String']>;
};

export type CompanyAssignmentQueryModel = {
  CompanyId: Scalars['UUID'];
  CompanyName?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
};

/** Statystyka firmy */
export type CompanyAssignmentViewModel = {
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Nazwa firmy */
  CompanyName?: Maybe<Scalars['String']>;
  /** Określa, czy w tej firmie użytkownik posiada autoryzację w KSeF */
  HasKSeFAuthorization: Scalars['Boolean'];
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Określa, czy firma działa w trybie Demo (połączenie z https://ksef-demo.mf.gov.pl/) */
  IsDemo: Scalars['Boolean'];
  /** Liczba faktur zakupowych */
  PurchaseInvoicesCount: Scalars['Long'];
  /** Nazwa roli */
  RoleName?: Maybe<Scalars['String']>;
  /** Liczba faktur sprzedażowych */
  SalesInvoicesCount: Scalars['Long'];
};

/** dane firmy */
export type CompanyInfoViewModel = {
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Nazwa firmy */
  CompanyName?: Maybe<Scalars['String']>;
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Określa, czy firma działa w trybie Demo (połączenie z https://ksef-demo.mf.gov.pl/) */
  IsDemo: Scalars['Boolean'];
  /** Nazwa roli */
  RoleName?: Maybe<Scalars['String']>;
};

/** Odpowiedź */
export type CompanyExistsQueryResponse = {
  /** True jeżeli istnieje */
  Exists: Scalars['Boolean'];
};

/** Firma zarządzana przez organizację */
export type CompanyFilterInput = {
  /** Dane adresowe firmy */
  Address?: InputMaybe<AddressFilterInput>;
  /** AppStream firmy */
  AppStream?: InputMaybe<AppStreamFilterInput>;
  /** Lista aktywnych modułów firmy */
  CloudServiceAvailibilities?: InputMaybe<ListFilterInputTypeOfCloudServiceAvailabilityFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<StringOperationFilterInput>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType?: InputMaybe<IdentifierTypeOperationFilterInput>;
  /** Określa czy firma działa w trybie Demo (połączenie z https://ksef-demo.mf.gov.pl/) */
  IsDemo?: InputMaybe<BooleanOperationFilterInput>;
  /** Czy firma główna w organizacji */
  IsMainCompany?: InputMaybe<BooleanOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  /** To pole niedługo zostanie usunięte */
  NIP?: InputMaybe<StringOperationFilterInput>;
  /** Nazwa firmy */
  Name?: InputMaybe<StringOperationFilterInput>;
  /** Lista ról przypisanych do firmy */
  RoleAssignments?: InputMaybe<ListFilterInputTypeOfRoleAssignmentFilterInput>;
  /** Organizacja, do której należy firma */
  Tenant?: InputMaybe<TenantFilterInput>;
  /** Id organizacji, do której należy firma */
  TenantId?: InputMaybe<UuidOperationFilterInput>;
  /** Numer VAT UE */
  VatNumberUE?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<CompanyFilterInput>>;
  or?: InputMaybe<Array<CompanyFilterInput>>;
};

/** Stan serwisu realizującego zadania w KSeF */
export enum CompanyKsefServicesStatusEnum {
  /** Aktywny */
  Active = 'ACTIVE',
  /** Nieaktywny */
  Inactive = 'INACTIVE'
}

export enum CompanyNip {
  Nip5291529899 = 'NIP5291529899',
  Nip5665750476 = 'NIP5665750476'
}

/** Firma zarządzana przez organizację */
export type CompanySortInput = {
  /** Dane adresowe firmy */
  Address?: InputMaybe<AddressSortInput>;
  /** AppStream firmy */
  AppStream?: InputMaybe<AppStreamSortInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<SortEnumType>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<SortEnumType>;
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<SortEnumType>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType?: InputMaybe<SortEnumType>;
  /** Określa czy firma działa w trybie Demo (połączenie z https://ksef-demo.mf.gov.pl/) */
  IsDemo?: InputMaybe<SortEnumType>;
  /** Czy firma główna w organizacji */
  IsMainCompany?: InputMaybe<SortEnumType>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<SortEnumType>;
  /** To pole niedługo zostanie usunięte */
  NIP?: InputMaybe<SortEnumType>;
  /** Nazwa firmy */
  Name?: InputMaybe<SortEnumType>;
  /** Organizacja, do której należy firma */
  Tenant?: InputMaybe<TenantSortInput>;
  /** Id organizacji, do której należy firma */
  TenantId?: InputMaybe<SortEnumType>;
  /** Numer VAT UE */
  VatNumberUE?: InputMaybe<SortEnumType>;
};

export type CompanyVehicleViewModel = {
  Description?: Maybe<Scalars['String']>;
  Id: Scalars['UUID'];
  RegistrationNumber: Scalars['String'];
  VehicleUsageType: VehicleUsageTypeEnum;
};

export type CompanyVehicleViewModelFilterInput = {
  Description?: InputMaybe<StringOperationFilterInput>;
  Id?: InputMaybe<UuidOperationFilterInput>;
  RegistrationNumber?: InputMaybe<StringOperationFilterInput>;
  VehicleUsageType?: InputMaybe<VehicleUsageTypeEnumOperationFilterInput>;
  and?: InputMaybe<Array<CompanyVehicleViewModelFilterInput>>;
  or?: InputMaybe<Array<CompanyVehicleViewModelFilterInput>>;
};

export type CompanyVehicleViewModelSortInput = {
  Description?: InputMaybe<SortEnumType>;
  Id?: InputMaybe<SortEnumType>;
  RegistrationNumber?: InputMaybe<SortEnumType>;
  VehicleUsageType?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type CompanyVehiclesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<CompanyVehicleViewModel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type CompanyVehiclesFiltersInput = {
  Id?: InputMaybe<Scalars['UUID']>;
  RegistrationNumbers?: InputMaybe<Array<Scalars['String']>>;
  VehicleUsageTypes?: InputMaybe<Array<VehicleUsageTypeEnum>>;
};

export type CompanyVehiclesResponse = {
  Description?: Maybe<Scalars['String']>;
  Id: Scalars['UUID'];
  RegistrationNumber: Scalars['String'];
  VehicleUsageType: VehicleUsageTypeEnum;
};

export type ConfigurationStatusResponse = {
  IsRequiredConfiguration: Scalars['Boolean'];
  RequiredModulesToFill?: Maybe<Array<RequiredModulesToFillEnum>>;
};

/** Email kontrahenta */
export type ContractorEmailItemInput = {
  /** Czy przeznaczony do usunięcia */
  Deleted?: InputMaybe<Scalars['Boolean']>;
  /** Adres email */
  Email?: InputMaybe<Scalars['String']>;
  /** Id obiektu */
  Id?: InputMaybe<Scalars['UUID']>;
  /** Czy wybrany przez użytkownika jako preferowany */
  Preferred: Scalars['Boolean'];
};

/** Obiekt filtra */
export type ContractorFiltersFieldsInput = {
  /** Lista numerów NIP */
  Identifier?: InputMaybe<Array<Scalars['String']>>;
  /** Kontekst faktury */
  InvoiceId?: InputMaybe<Scalars['UUID']>;
  /** Typ kontrahenta */
  SupplierType?: InputMaybe<Scalars['Int']>;
};

/** Aktualizacja danych kontrahenta */
export type ContractorManualUpdateItemInput = {
  /** Lista adresów email */
  EmailList?: InputMaybe<Array<ContractorEmailItemInput>>;
  /** Id kontrahenta */
  Id: Scalars['UUID'];
  /** Lista telefonów */
  PhoneList?: InputMaybe<Array<ContractorPhoneItemInput>>;
  /** Typ kontrahenta jako dostawcy towaru/usługi */
  SupplierType: Scalars['Int'];
};

export enum ContractorOriginTypeEnum {
  Foreign = 'FOREIGN',
  National = 'NATIONAL',
  Undefined = 'UNDEFINED'
}

/** Telefon kontrahenta */
export type ContractorPhoneItemInput = {
  /** Czy przeznaczony do usunięcia */
  Deleted?: InputMaybe<Scalars['Boolean']>;
  /** Id obiektu */
  Id?: InputMaybe<Scalars['UUID']>;
  /** Numer telefonu */
  Phone?: InputMaybe<Scalars['String']>;
  /** Czy wybrany przez użytkownika jako preferowany */
  Preferred: Scalars['Boolean'];
};

export enum ContractorRelatedTypeEnum {
  NotRelated = 'NOT_RELATED',
  Related = 'RELATED',
  Undefined = 'UNDEFINED'
}

export type ContractorResponse = {
  ContractorOriginType: ContractorOriginTypeEnum;
  ContractorRelatedType: ContractorRelatedTypeEnum;
  Id: Scalars['UUID'];
  Identifier: Scalars['String'];
  IdentifierType: PostingIdentifierTypeEnum;
  Name: Scalars['String'];
  Place?: Maybe<Scalars['String']>;
  PostCode?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
};

/** Ustawienia kontrahentów */
export type ContractorSettings = {
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Lista adresów email powiązanych z kontrahentem */
  Emails?: Maybe<Array<Maybe<ContractorSettingsEmail>>>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Numer identyfikacji ewidencyjnej/podatkowej kontrahenta */
  Identifier?: Maybe<Scalars['String']>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Nazwa kontrahenta */
  Name?: Maybe<Scalars['String']>;
  /** Lista telefonów powiązanych z kontrahentem */
  Phones?: Maybe<Array<Maybe<ContractorSettingsPhone>>>;
  /** Typ kontrahenta jako dostawcy towaru/usługi */
  SupplierType: SupplierType;
};

/** Ustawienia kontrahentów */
export type ContractorSettingsEmail = {
  /** Ustawienia kontrahenta */
  ContractorSettings: ContractorSettings;
  /** Identyfikator ustawienia */
  ContractorSettingsId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Adres email kontrahenta */
  Email?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Zablokowany do zmiany */
  Persistent: Scalars['Boolean'];
  /** Preferowany */
  Preferred: Scalars['Boolean'];
};

/** Ustawienia kontrahentów */
export type ContractorSettingsPhone = {
  /** Ustawienia kontrahenta */
  ContractorSettings: ContractorSettings;
  /** Identyfikator ustawienia */
  ContractorSettingsId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Zablokowany do zmiany */
  Persistent: Scalars['Boolean'];
  /** Numer telefonu */
  Phone?: Maybe<Scalars['String']>;
  /** Preferowany */
  Preferred: Scalars['Boolean'];
};

export type ContractorSortInput = {
  CompanyId?: InputMaybe<SortEnumType>;
  ContractorOriginTypeId?: InputMaybe<SortEnumType>;
  ContractorRelatedTypeId?: InputMaybe<SortEnumType>;
  GlobalId?: InputMaybe<SortEnumType>;
  Id?: InputMaybe<SortEnumType>;
  Identifier?: InputMaybe<SortEnumType>;
  IsActive?: InputMaybe<SortEnumType>;
  Name?: InputMaybe<SortEnumType>;
  Nip?: InputMaybe<SortEnumType>;
  Pesel?: InputMaybe<SortEnumType>;
  Position?: InputMaybe<SortEnumType>;
  PostingIdentifierType?: InputMaybe<SortEnumType>;
  Regon?: InputMaybe<SortEnumType>;
};

export type ContractorViewModel = {
  Id: Scalars['UUID'];
  Identifier: Scalars['String'];
  Name: Scalars['String'];
};

export type ContractorViewModelFilterInput = {
  Id?: InputMaybe<UuidOperationFilterInput>;
  Identifier?: InputMaybe<StringOperationFilterInput>;
  Name?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ContractorViewModelFilterInput>>;
  or?: InputMaybe<Array<ContractorViewModelFilterInput>>;
};

export type ContractorViewModelSortInput = {
  Id?: InputMaybe<SortEnumType>;
  Identifier?: InputMaybe<SortEnumType>;
  Name?: InputMaybe<SortEnumType>;
};

/** Dane kontrahenta */
export type ContractorsDto = {
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** Email użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Lista adresów email powiązanych z kontrahentem */
  Emails?: Maybe<Array<Maybe<ContractorSettingsEmail>>>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Numer NIP */
  Identifier?: Maybe<Scalars['String']>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** Email użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Nazwa kontrahenta */
  Name?: Maybe<Scalars['String']>;
  /** Lista telefonów powiązanych z kontrahentem */
  Phones?: Maybe<Array<Maybe<ContractorSettingsPhone>>>;
  /** Typ kontrahenta jako dostawcy towaru/usługi */
  SupplierType: Scalars['Int'];
  /** Lista kont kontrahenta oraz wyniki sprawdzenia na białej liście */
  WhiteListStatus?: Maybe<WhiteListStatusDtoModel>;
};

export type CreateAppStreamLinkQueryResponse = {
  StreamingUrl?: Maybe<Scalars['URL']>;
};

export type CreateCategoryMutationResponse = {
  IsDone: Scalars['Boolean'];
  NewCategoryId?: Maybe<Scalars['UUID']>;
};

/** Dane nowego klucza dla company */
export type CreateCompanyApiKeyInput = {
  /** Company Id */
  CompanyId: Scalars['UUID'];
  /** Nazwa klucza */
  Name?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyApiKeyResponse = {
  CompanyId?: Maybe<Scalars['UUID']>;
  Key?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TenantId: Scalars['UUID'];
};

/** Firma zarządzana przez organizację */
export type CreateCompanyRequest = {
  /** Dane adresowe firmy */
  Address?: InputMaybe<AddressInput>;
  /** AppStream firmy */
  AppStream?: InputMaybe<AppStreamInput>;
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Określa czy firma działa w trybie Demo (połączenie z https://ksef-demo.mf.gov.pl/) */
  IsDemo: Scalars['Boolean'];
  /** To pole niedługo zostanie usunięte */
  NIP?: InputMaybe<Scalars['String']>;
  /** Nazwa firmy */
  Name: Scalars['String'];
  /** Lista ról przypisanych do firmy */
  RoleAssignments?: InputMaybe<Array<InputMaybe<RoleAssignmentInput>>>;
  /** Organizacja, do której należy firma */
  Tenant?: InputMaybe<TenantInput>;
  /** Id organizacji, do której należy firma */
  TenantId: Scalars['UUID'];
  /** Numer VAT UE */
  VatNumberUE?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyVehicleMutationResponse = {
  CompanyVehicle: CompanyVehiclesResponse;
};

/** Response */
export type CreateOrganizationResponse = {
  /** Firma utworzona razem z organizacją */
  Company?: Maybe<Company>;
  /** Tenant status */
  Status: TenantStatus;
  /** Identyfikator tenanta */
  TenantId: Scalars['UUID'];
};

/** Rola */
export type CreateRoleRequest = {
  /** Nazwa roli przezentowana w aplikacji */
  BusinessName: Scalars['String'];
  /** Nazwa roli */
  Name?: InputMaybe<Scalars['String']>;
  /** Nazwa systemu, którego dotyczy rola */
  SystemType: Scalars['String'];
};

/** Dane nowego klucza dla tenanta */
export type CreateTenantApiKeyInput = {
  /** Nazwa klucza */
  Name?: InputMaybe<Scalars['String']>;
};

export type CreateTenantApiKeyResponse = {
  CompanyId?: Maybe<Scalars['UUID']>;
  Key?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TenantId: Scalars['UUID'];
};

/** Dane nowej organizacji */
export type CreateTenantInput = {
  /** Organizacja jest biurem rachunkowym */
  AccountOffice?: InputMaybe<Scalars['Boolean']>;
  /** Dane adresowe */
  Address?: InputMaybe<AddressInput>;
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Numer subskrypcji */
  SubscriptionNumber?: InputMaybe<Scalars['String']>;
  /** Nazwa tenanta */
  TenantName?: InputMaybe<Scalars['String']>;
};

/** Dane nowego użytkownika */
export type CreateUserInput = {
  CloudConsent?: InputMaybe<Scalars['Boolean']>;
  CompanyRepresentationConsent?: InputMaybe<Scalars['Boolean']>;
  Email: Scalars['String'];
  FirstName?: InputMaybe<Scalars['String']>;
  LastName?: InputMaybe<Scalars['String']>;
  MarketingEmailConsent?: InputMaybe<Scalars['Boolean']>;
  MarketingEndDeviceConsent?: InputMaybe<Scalars['Boolean']>;
  MarketingPhoneConsent?: InputMaybe<Scalars['Boolean']>;
  NewsletterConsent?: InputMaybe<Scalars['Boolean']>;
  PersonalDataConsent?: InputMaybe<Scalars['Boolean']>;
  PersonalDataForPartnersConsent?: InputMaybe<Scalars['Boolean']>;
  PhoneNumber?: InputMaybe<Scalars['String']>;
  PrivacyPolicyConsent?: InputMaybe<Scalars['Boolean']>;
  TenantId: Scalars['UUID'];
};

/** Obiekt uprawnień w KSeF */
export type Credential = {
  /** Uprawnienie do odbierania faktur */
  CredentialsInvoiceRead: Scalars['Boolean'];
  /** Uprawnienie do wysyłki faktur */
  CredentialsInvoiceWrite: Scalars['Boolean'];
  /** Uprawnienie do nadawania uprawnień */
  CredentialsManage: Scalars['Boolean'];
  /** Uprawnienie do odczytu uprawnień */
  CredentialsRead: Scalars['Boolean'];
  /** Uprawnienie do samofakturowania */
  CredentialsSelfInvoicing: Scalars['Boolean'];
  /** Numer identyfikacji ewidencyjnej/podatkowej firmy, do której użytkownik ma uprawnienia w KSeF */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
};

/** Filtr uprawnień */
export type CredentialFiltersFieldsInput = {
  /** Uprawnienie do odbierania faktur */
  CredentialsInvoiceRead?: InputMaybe<Scalars['Boolean']>;
  /** Uprawnienie do wysyłki faktur */
  CredentialsInvoiceWrite?: InputMaybe<Scalars['Boolean']>;
  /** Uprawnienie do nadawania uprawnień */
  CredentialsManage?: InputMaybe<Scalars['Boolean']>;
  /** Uprawnienie do odczytu uprawnień */
  CredentialsRead?: InputMaybe<Scalars['Boolean']>;
  /** Uprawnienie do samofakturowania */
  CredentialsSelfInvoicing?: InputMaybe<Scalars['Boolean']>;
  /** Lista numerów identyfikacji ewidencyjnej/podatkowej */
  Identifiers?: InputMaybe<Array<Scalars['String']>>;
};

export enum CurrencyCodeEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ggp = 'GGP',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Imp = 'IMP',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jep = 'JEP',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

/** Zwraca informacje o bieżącej firmie */
export type CurrentCompanyViewModel = {
  /** Unikalny identyfikator */
  Id: Scalars['UUID'];
  /** Numer identyfikacyjny */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Określa czy firma działa w trybie Demo (połączenie z https://ksef-demo.mf.gov.pl/) */
  IsDemo: Scalars['Boolean'];
  /** Nazwa */
  Name?: Maybe<Scalars['String']>;
};

/** Zwraca informacje o bieżącym środowisku (kontekscie) */
export type CurrentEnvironmentViewModel = {
  /** Bieżący kontekst firmy */
  Company?: Maybe<CurrentCompanyViewModel>;
  /** Adres email */
  Email?: Maybe<Scalars['String']>;
  /** Id środowiska */
  EnvironmentId: Scalars['UUID'];
  /** Imię */
  FirstName?: Maybe<Scalars['String']>;
  /** Nazwisko */
  LastName?: Maybe<Scalars['String']>;
  /** Bieżący kontekst organizacji */
  Tenant?: Maybe<CurrentTenantViewModel>;
  /** Typ środowiska */
  Type: EnvironmentType;
  /** Id użytkownika */
  UserId: Scalars['UUID'];
  /** Ustawienia użytkownika */
  UserProperties?: Maybe<UserProperties>;
  /** Lista przypisanych ról */
  UserRoles?: Maybe<Array<Maybe<RoleInfo>>>;
};

/** Zwraca informacje o bieżącej organizacji */
export type CurrentTenantViewModel = {
  /** Unikalny identyfikator */
  Id: Scalars['UUID'];
  /** Numer identyfikacyjny */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Nazwa */
  Name?: Maybe<Scalars['String']>;
  /** Numer subskrypcji */
  SubscriptionNumber?: Maybe<Scalars['String']>;
};

export type CurrentUserPhotoResponse = {
  ImageUrl?: Maybe<Scalars['String']>;
};

export type CurrentUserViewModel = {
  Email?: Maybe<Scalars['String']>;
  EnvironmentId?: Maybe<Scalars['UUID']>;
  TenantAssignments?: Maybe<Array<Maybe<TenantAssignmentQueryModel>>>;
  UserPhoto: CurrentUserPhotoResponse;
};

/** Dane dotyczące faktury korygowanej */
export type DaneFaKorygowanej = {
  /** Data wystawienia faktury, której dotyczy faktura korygująca */
  DataWystFaKorygowanej: Scalars['DateTime'];
  /** Numer faktury korygowanej */
  NrFaKorygowanej?: Maybe<Scalars['String']>;
  /** Numer identyfikujący fakturę korygowaną w Krajowym Systemie e-Faktur (KSeF) */
  NrKSeFFaKorygowanej?: Maybe<Scalars['String']>;
};

/** Dane dotyczące faktury korygowanej */
export type DaneFaKorygowanejInput = {
  /** Data wystawienia faktury, której dotyczy faktura korygująca */
  DataWystFaKorygowanej: Scalars['DateTime'];
  /** Numer faktury korygowanej */
  NrFaKorygowanej?: InputMaybe<Scalars['String']>;
  /** Numer identyfikujący fakturę korygowaną w Krajowym Systemie e-Faktur (KSeF) */
  NrKSeFFaKorygowanej?: InputMaybe<Scalars['String']>;
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  neq?: InputMaybe<Scalars['Date']>;
  ngt?: InputMaybe<Scalars['Date']>;
  ngte?: InputMaybe<Scalars['Date']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  nlt?: InputMaybe<Scalars['Date']>;
  nlte?: InputMaybe<Scalars['Date']>;
};

/** Zakres dat Od - Do */
export type DateRangeSchemaModelInput = {
  /** Data Od */
  DateFrom?: InputMaybe<Scalars['Date']>;
  /** Data Do */
  DateTo?: InputMaybe<Scalars['Date']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

/** Zakres dat */
export type DateTimeRange = {
  /** Data od */
  DateFrom?: Maybe<Scalars['DateTime']>;
  /** Data do */
  DateTo?: Maybe<Scalars['DateTime']>;
};

/** Zakres dat */
export type DateTimeRangeInput = {
  /** Data od */
  DateFrom?: InputMaybe<Scalars['DateTime']>;
  /** Data do */
  DateTo?: InputMaybe<Scalars['DateTime']>;
};

export type DeactivateTenantResponse = {
  Deactivated: Scalars['Boolean'];
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type DecreeDetailsChangeRequestInput = {
  DocumentTypeId: Scalars['UUID'];
  PostingStatus: PostingStatusEnum;
};

export type DecreeLineResponse = {
  Account?: Maybe<AccountResponse>;
  AccountNameAndNumber: Scalars['String'];
  AccountType: AccountTypeEnum;
  CanAccountBeChanged: Scalars['Boolean'];
  CategoryId?: Maybe<Scalars['UUID']>;
  CategoryMlKey: Scalars['String'];
  CategoryName: Scalars['String'];
  Id: Scalars['UUID'];
  LineType: DecreeLineTypeEnum;
  Positions?: Maybe<Array<PositionDetailsResponse>>;
  Value: Scalars['Decimal'];
};

export enum DecreeLineTypeEnum {
  DocumentRelated = 'DOCUMENT_RELATED',
  PositionRelated = 'POSITION_RELATED',
  Tax = 'TAX',
  Undefined = 'UNDEFINED'
}

export type DecreeLineValueChangeRequestInput = {
  DecreeLineId: Scalars['UUID'];
  Value: Scalars['Decimal'];
};

export type DecreeResponse = {
  Contractor?: Maybe<ContractorResponse>;
  CurrencyCode: CurrencyCodeEnum;
  DateOfIssue: Scalars['DateTime'];
  DecreeLines: Array<DecreeLineResponse>;
  DocumentType?: Maybe<InvoiceDocumentTypeResponse>;
  FinancialYearId: Scalars['UUID'];
  Gross: Scalars['Decimal'];
  Id: Scalars['UUID'];
  InvoiceDocumentId: Scalars['UUID'];
  InvoiceNumber: Scalars['String'];
  InvoiceType: InvoiceTypeEnum;
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  IsAccepted: Scalars['Boolean'];
  KSeFDate?: Maybe<Scalars['DateTime']>;
  KSeFNumber?: Maybe<Scalars['String']>;
  Net: Scalars['Decimal'];
  OperationDate: Scalars['DateTime'];
  PaymentDate?: Maybe<Scalars['DateTime']>;
  PaymentType?: Maybe<PaymentTypeEnum>;
  PostingStatus: PostingStatusEnum;
  Vat: Scalars['Decimal'];
  VatSummaries: Array<VatSummaryResponse>;
};

export type DecreeViewModel = {
  Contractor?: Maybe<ContractorViewModel>;
  ContractorId?: Maybe<Scalars['UUID']>;
  CreatedAt: Scalars['DateTime'];
  CreatedBy?: Maybe<Scalars['UUID']>;
  GrossValue: Scalars['Decimal'];
  Id: Scalars['UUID'];
  InvoiceDocumentId: Scalars['UUID'];
  InvoiceNumber: Scalars['String'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  IsAccepted: Scalars['Boolean'];
  ModifiedAt: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['UUID']>;
  PostingStatus: PostingStatusEnum;
};

export type DecreeViewModelFilterInput = {
  Contractor?: InputMaybe<ContractorViewModelFilterInput>;
  ContractorId?: InputMaybe<UuidOperationFilterInput>;
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  CreatedBy?: InputMaybe<UuidOperationFilterInput>;
  GrossValue?: InputMaybe<DecimalOperationFilterInput>;
  Id?: InputMaybe<UuidOperationFilterInput>;
  InvoiceDocumentId?: InputMaybe<UuidOperationFilterInput>;
  InvoiceNumber?: InputMaybe<StringOperationFilterInput>;
  InvoiceTypeGroup?: InputMaybe<InvoiceTypeGroupEnumOperationFilterInput>;
  IsAccepted?: InputMaybe<BooleanOperationFilterInput>;
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  ModifiedBy?: InputMaybe<UuidOperationFilterInput>;
  PostingStatus?: InputMaybe<PostingStatusEnumOperationFilterInput>;
  and?: InputMaybe<Array<DecreeViewModelFilterInput>>;
  or?: InputMaybe<Array<DecreeViewModelFilterInput>>;
};

export type DecreeViewModelSortInput = {
  Contractor?: InputMaybe<ContractorViewModelSortInput>;
  ContractorId?: InputMaybe<SortEnumType>;
  CreatedAt?: InputMaybe<SortEnumType>;
  CreatedBy?: InputMaybe<SortEnumType>;
  GrossValue?: InputMaybe<SortEnumType>;
  Id?: InputMaybe<SortEnumType>;
  InvoiceDocumentId?: InputMaybe<SortEnumType>;
  InvoiceNumber?: InputMaybe<SortEnumType>;
  InvoiceTypeGroup?: InputMaybe<SortEnumType>;
  IsAccepted?: InputMaybe<SortEnumType>;
  ModifiedAt?: InputMaybe<SortEnumType>;
  ModifiedBy?: InputMaybe<SortEnumType>;
  PostingStatus?: InputMaybe<SortEnumType>;
};

export type DeleteApiKeyRequest = {
  Id: Scalars['UUID'];
};

/** Wynik operacji usunięcia klucza Api */
export type DeleteApiKeyResponse = {
  /** True - usunięto */
  Deleted: Scalars['Boolean'];
};

export type DeleteAttachmentResponse = {
  IsDone: Scalars['Boolean'];
};

export type DeleteCloudServiceAvailabilityRequest = {
  Id: Scalars['UUID'];
};

/** Wynik operacji usunięcia modułu */
export type DeleteCloudServiceAvailabilityResponse = {
  /** True - usunięto */
  Deleted: Scalars['Boolean'];
};

export type DeleteCompanyRequest = {
  Id: Scalars['UUID'];
};

export type DeleteInvoicesResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

export type DeleteRoleRequest = {
  Id: Scalars['UUID'];
};

/** Wynik operacji usunięcia organizacji */
export type DeleteTenantResponse = {
  /** True - usunięto */
  Deleted: Scalars['Boolean'];
};

export type DeleteUserRequestInput = {
  Id: Scalars['UUID'];
};

/** Status połączenia między aplikacjami */
export enum DesktopStatus {
  /** Połączono */
  Connected = 'CONNECTED',
  /**
   * Tworzona jest infrastruktura do komunikacji
   * @deprecated No longer supported.
   */
  CreatingInfrastructure = 'CREATING_INFRASTRUCTURE',
  /** Nie połączono */
  NotConnected = 'NOT_CONNECTED',
  /** Oczekiwanie na podłączenie agenta */
  WaitingForConnection = 'WAITING_FOR_CONNECTION'
}

export type DesktopStatusOperationFilterInput = {
  eq?: InputMaybe<DesktopStatus>;
  in?: InputMaybe<Array<DesktopStatus>>;
  neq?: InputMaybe<DesktopStatus>;
  nin?: InputMaybe<Array<DesktopStatus>>;
};

export enum DocumentTypeSettingTypeEnum {
  AdvanceBilling = 'ADVANCE_BILLING',
  Corrective = 'CORRECTIVE',
  CorrectiveAdvanceBilling = 'CORRECTIVE_ADVANCE_BILLING',
  Simplified = 'SIMPLIFIED',
  Undefined = 'UNDEFINED',
  Vat = 'VAT'
}

export type DocumentTypeSortInput = {
  Character?: InputMaybe<SortEnumType>;
  CompanyId?: InputMaybe<SortEnumType>;
  FinancialYear?: InputMaybe<FinancialYearSortInput>;
  FinancialYearId?: InputMaybe<SortEnumType>;
  Id?: InputMaybe<SortEnumType>;
  IsActive?: InputMaybe<SortEnumType>;
  Name?: InputMaybe<SortEnumType>;
  SourceId?: InputMaybe<SortEnumType>;
  Symbol?: InputMaybe<SortEnumType>;
  TransactionKind?: InputMaybe<SortEnumType>;
  VatRegister?: InputMaybe<VatRegisterSortInput>;
  VatRegisterId?: InputMaybe<SortEnumType>;
};

export type DocumentTypesSettingsRequestInput = {
  DocumentTypeId?: InputMaybe<Scalars['UUID']>;
  SettingsType: DocumentTypeSettingTypeEnum;
};

export type DocumentTypesSettingsResponse = {
  InvoiceDocumentTypeId: Scalars['UUID'];
  SettingType: DocumentTypeSettingTypeEnum;
};

/** Pola przeznaczone dla wykazywania dodatkowych danych na fakturze, w tym wymaganych przepisami prawa, dla których nie przewidziano innych pól/elementów */
export type DodatkowyOpisPolaV2 = {
  /**
   * Klucz.
   * Typ znakowy ograniczony do 256 znaków.
   * Minimum length: 1.
   * Maximum length: 256.
   */
  Klucz?: Maybe<Scalars['String']>;
  /**
   * Numer wiersza podany w polu NrWierszaFa lub NrWierszaZam, jeśli informacja odnosi się wyłącznie do danej pozycji faktury.
   * Liczby naturalne większe od zera.
   * Minimum exclusive value: 0.
   */
  NrWiersza?: Maybe<Scalars['String']>;
  /**
   * Wartość.
   * Typ znakowy ograniczony do 256 znaków.
   * Minimum length: 1.
   * Maximum length: 256.
   */
  Wartosc?: Maybe<Scalars['String']>;
};

/** Pola przeznaczone dla wykazywania dodatkowych danych na fakturze, w tym wymaganych przepisami prawa, dla których nie przewidziano innych pól/elementów */
export type DodatkowyOpisPolaV2Input = {
  /**
   * Klucz.
   * Typ znakowy ograniczony do 256 znaków.
   * Minimum length: 1.
   * Maximum length: 256.
   */
  Klucz?: InputMaybe<Scalars['String']>;
  /**
   * Numer wiersza podany w polu NrWierszaFa lub NrWierszaZam, jeśli informacja odnosi się wyłącznie do danej pozycji faktury.
   * Liczby naturalne większe od zera.
   * Minimum exclusive value: 0.
   */
  NrWiersza?: InputMaybe<Scalars['String']>;
  /**
   * Wartość.
   * Typ znakowy ograniczony do 256 znaków.
   * Minimum length: 1.
   * Maximum length: 256.
   */
  Wartosc?: InputMaybe<Scalars['String']>;
};

/** Środki trwałe - transport */
export type DostawaNowychSrodkowTransportuV2 = {
  /** Znacznik wewnątrzwspólnotowej dostawy nowych środków transportu */
  DostawaNowegoSrodkaTransportu: Scalars['Boolean'];
  /** Lista Nowych środków transportu */
  NoweSrodkiTransportu?: Maybe<Array<TNowySrodekTransportuV2>>;
  /**
   * Dotyczy dokumentu wywozu Nowego Środka Transportu z terytorium kraju.
   * Jeśli występuje obowiązek, o którym mowa w art. 42 ust. 5 ustawy, należy podać wartość "1", w przeciwnym przypadku - wartość "2"
   */
  WywozNowegSrodkaTransportu: Scalars['Boolean'];
};

/** Środki trwałe - transport */
export type DostawaNowychSrodkowTransportuV2Input = {
  /** Znacznik wewnątrzwspólnotowej dostawy nowych środków transportu */
  DostawaNowegoSrodkaTransportu: Scalars['Boolean'];
  /** Lista Nowych środków transportu */
  NoweSrodkiTransportu?: InputMaybe<Array<TNowySrodekTransportuV2Input>>;
  /**
   * Dotyczy dokumentu wywozu Nowego Środka Transportu z terytorium kraju.
   * Jeśli występuje obowiązek, o którym mowa w art. 42 ust. 5 ustawy, należy podać wartość "1", w przeciwnym przypadku - wartość "2"
   */
  WywozNowegSrodkaTransportu: Scalars['Boolean'];
};

/** Informacja o błędach w pobieraniu faktur z KSeF */
export type DownloadInvoiceItem = {
  /** Opis błędu */
  ErrorMessage?: Maybe<Scalars['String']>;
  /** Typ faktury */
  InvoiceBound?: Maybe<InvoiceBound>;
  /** Data nadania numeru KSeF */
  KSeFDate: Scalars['DateTime'];
  /** Numer KSeF */
  KSeFNumber?: Maybe<Scalars['String']>;
};

/** Informacja o fakturze */
export type DownloadInvoiceItemMessage = {
  /** Opis błędu */
  errorMessage?: Maybe<Scalars['String']>;
  /** Typ faktury */
  invoiceBound?: Maybe<InvoiceBound>;
  /** Data nadania numeru KSeF */
  kSeFDate: Scalars['DateTime'];
  /** Numer faktury w KSeF */
  kSeFNumber?: Maybe<Scalars['String']>;
};

/** Informacja o liczbie pobranych faktur z KSeF */
export type DownloadResult = {
  /** Lista błędów */
  ErrorItems?: Maybe<Array<Maybe<DownloadInvoiceItem>>>;
  /** Zlecony zakres dat dla faktur zakupowych */
  External?: Maybe<DateTimeRange>;
  /** Pobieranie faktur zakupowych */
  ExternalEnabled: Scalars['Boolean'];
  /** Liczba zaimportowanych/zapisanych faktur */
  Imported: Scalars['Int'];
  /** Zlecony zakres dat dla faktur sprzedażowych */
  Internal?: Maybe<DateTimeRange>;
  /** Pobieranie faktur sprzedażowych */
  InternalEnabled: Scalars['Boolean'];
  /** Lista pobranych faktur */
  SuccessItems?: Maybe<Array<Maybe<DownloadInvoiceItem>>>;
  /** Liczba pobranych xml-i faktur */
  SuccessfullyDownloadedInvoices: Scalars['Int'];
};

/** Stan procesu pobierania faktur */
export enum DownloadStateUserMessage {
  /** W trakcie pobierania faktur (xml) */
  Downloading = 'DOWNLOADING',
  /** Oczekuje na możliwość wysłania zapytania do KSeF */
  Pending = 'PENDING'
}

/** Typ notyfikacji e-mail */
export enum EArchiveNotificationType {
  /** Autoryzacja użytkownika w KSeF zakończone błędem */
  AuthorizationOfCompanyFailed = 'AUTHORIZATION_OF_COMPANY_FAILED',
  /** Autoryzacja użytkownika w KSeF zakończone sukcesem */
  AuthorizationOfCompanySucceeded = 'AUTHORIZATION_OF_COMPANY_SUCCEEDED',
  /** Automatyczne rozłączenie Użytkownika z KSeF */
  AutoUnauthorizationUserInKSeF = 'AUTO_UNAUTHORIZATION_USER_IN_K_SE_F',
  /** Pomyślne pobranie faktury z KSeF */
  DownloadInvoicesFromKSeF = 'DOWNLOAD_INVOICES_FROM_K_SE_F',
  /** Pomyślne pobranie UPO */
  DownloadUpoSucceeded = 'DOWNLOAD_UPO_SUCCEEDED',
  /** Nieudane automatyczne pobranie faktur z KSeF */
  InvoicesNotFetched = 'INVOICES_NOT_FETCHED',
  /** Wyłączone */
  None = 'NONE',
  /** Edytowano uprawnienia w KSeF */
  PermissionsEdited = 'PERMISSIONS_EDITED',
  /** Wysłano faktury do KSeF */
  SendInvoicesToKSeF = 'SEND_INVOICES_TO_K_SE_F'
}

/** Wynik operacji */
export type EArchiveUserPropertiesType = {
  /** Ustawienia automatycznego pobierania faktur */
  AutoFetchingInvoices: AutoFetchingInvoices;
  /** Ustawienia automatycznego wysyłania faktur */
  AutoSendingInvoices: AutoSendingInvoices;
  /** &gt;Ustawienie udostepnienia dostepu do pobierania faktur */
  SharedAccess: SharedAccess;
  /** &gt;Ustawienie automatycznego sprawdzania numerów rachunków kontrahentów na białej liście */
  WhiteListValidation: WhiteListValidation;
};

/** Status środowiska */
export enum EnvironmentStatus {
  /** Aktywny */
  Active = 'ACTIVE',
  /** Nieaktywny */
  Inactive = 'INACTIVE',
  /** Oczekujący na zatwierdzenie */
  PendingApproval = 'PENDING_APPROVAL',
  /** Usunięty */
  Removed = 'REMOVED',
  /**
   * Do usunięcia
   * @deprecated Do usunięcia
   */
  ToDelete = 'TO_DELETE'
}

/** Typ środowiska */
export enum EnvironmentType {
  /** Admin / BOK */
  Admin = 'ADMIN',
  /** Firma */
  Company = 'COMPANY',
  /** Niewymagane środowisko */
  None = 'NONE',
  /** Organizacja */
  Tenant = 'TENANT'
}

/** Właściwości środowiska */
export type EnvironmentViewModel = {
  /** Firma */
  Company?: Maybe<Company>;
  /** Id środowiska */
  EnvironmentId: Scalars['UUID'];
  /** Określa, czy instancja środowiska jest wersją Demo */
  IsDemo: Scalars['Boolean'];
  /** Nazwa środowiska */
  Name?: Maybe<Scalars['String']>;
  /** Status */
  Status: EnvironmentStatus;
  /** Organizacja */
  Tenant?: Maybe<TenantResponse>;
  /** Typ środowiska */
  Type: EnvironmentType;
};

export type ExtendedFiltersFieldsInput = {
  /** Filtrowanie od daty utworzenia faktury */
  CreatedAfter?: InputMaybe<Scalars['DateTime']>;
  /** Filtrowanie od daty utworzenia id faktury */
  CreatedAfterInvoiceId?: InputMaybe<Scalars['UUID']>;
  /** Filtorwanie po liście walut */
  Currencies?: InputMaybe<Array<Scalars['String']>>;
  /** Zakres dat wystawienia faktur */
  DateOfIssue?: InputMaybe<DateRangeSchemaModelInput>;
  /** Filtrowanie po rodzajach faktur */
  DocumentTypes?: InputMaybe<Array<RodzajFaktury>>;
  /** Tylko faktury, które mają status "Pobrane z KSeF" */
  Downloaded?: InputMaybe<Scalars['Boolean']>;
  /** Filtrowanie po systemach, które pobrały fakturę */
  DownloadedBy?: InputMaybe<Array<SymfoniaSystem>>;
  /** Tylko faktury zakupowe */
  Foreign?: InputMaybe<Scalars['Boolean']>;
  /** Zakres kwoty brutto faktur */
  GrossValue?: InputMaybe<NumberRangeSchemaModelOfDecimalInput>;
  /** Filtrowanie po liście id faktur w Symfonia KSeF */
  InvoiceIds?: InputMaybe<Array<Scalars['UUID']>>;
  /** Filtrowanie po statusach dekretacji */
  InvoicePostingStatuses?: InputMaybe<Array<InvoicePostingStatus>>;
  /** Filtrowanie po nazwach sprzedawców */
  IssuerNames?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie po numerach NIP sprzedawców */
  IssuerNips?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie po statusach faktur w KSeF */
  KSeFStatuses?: InputMaybe<Array<KSeFStatus>>;
  /** Zakres dat faktur wysłanych do KSeF */
  KsefDate?: InputMaybe<DateRangeSchemaModelInput>;
  /** Filtrowanie po liście numerów nadanych fakturom w KSeF */
  KsefNumbers?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie od daty modyfikacji faktury */
  ModifiedAfter?: InputMaybe<Scalars['DateTime']>;
  /** Zakres kwoty netto faktur */
  NetValue?: InputMaybe<NumberRangeSchemaModelOfDecimalInput>;
  /** Filtrowanie po numerach faktur */
  Numbers?: InputMaybe<Array<Scalars['String']>>;
  /** Tylko faktury sprzedażowe */
  Own?: InputMaybe<Scalars['Boolean']>;
  /** Tylko faktury w statusie "Do dekretacji" */
  ReadyForPosting?: InputMaybe<Scalars['Boolean']>;
  /** Filtrowanie po nazwach odbiorców */
  RecipientNames?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie po numerach NIP odbiorców */
  RecipientNips?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie po numerach ewidencyjnych */
  RegistrationNumbers?: InputMaybe<Array<Scalars['String']>>;
  /** Tylko faktury, które mają status "Wysłane do KSeF" */
  Sent?: InputMaybe<Scalars['Boolean']>;
  /** Tylko faktury z dekretami w statusie "Do akceptacji" */
  ToAccept?: InputMaybe<Scalars['Boolean']>;
  /** Tylko faktury, które mają status "Do pobrania z KSeF" */
  ToDownload?: InputMaybe<Scalars['Boolean']>;
  /** Tylko faktury, które mają status "Do wysłania do KSeF" */
  ToSend?: InputMaybe<Scalars['Boolean']>;
  /** Zakres kwoty podatku vat faktur */
  VatValue?: InputMaybe<NumberRangeSchemaModelOfDecimalInput>;
};

/** Obiekt faktury w wersji domenowej Symfonii dla schematu V11089 */
export type Faktura = {
  /** Inne adnotacje na fakturze */
  Adnotacje: Adnotacje;
  /** Korekta, obowiązkowa, gdy typ faktury to: KOR, KOR_ZAL lub KOR_ROZ */
  Korekta?: Maybe<Korekta>;
  /** Nabywca */
  Nabywca: Podmiot;
  /** Nagłówek */
  Naglowek: Naglowek;
  /** Płatność (nieobowiązkowe) */
  Platnosc?: Maybe<Platnosc>;
  /** Podmiot upoważniony (nieobowiązkowe) */
  PodmiotUpowazniony?: Maybe<PodmiotUpowazniony>;
  /** Podsumowanie VAT */
  PodsumowanieVat: Array<PodsumowanieVat>;
  /** Dodatkowe rozliczenia na fakturze (nieobowiązkowe) */
  Rozliczenie?: Maybe<Rozliczenie>;
  /** Sprzedawca */
  Sprzedawca: Podmiot;
  /** Numery podmiotu lub grupy podmiotów w innych rejestrach i bazach danych (nieobowiązkowe) */
  Stopka?: Maybe<Stopka>;
  /** Lista podmiotów trzecich (nieobowiązkowe) */
  TrzeciPodmiot?: Maybe<Array<TrzeciPodmiot>>;
  /** Warunki transakcji (nieobowiązkowe) */
  WarunkiTransakcji?: Maybe<WarunkiTransakcji>;
  /**
   * Wykorzystana wersja DTO
   * Nazewnictwo zgodnie ze standardem : V1, V2, V3, itd.
   */
  WersjaDTO?: Maybe<Scalars['String']>;
  /** Lista wierszy faktury */
  Wiersze: Array<Wiersz>;
  /**
   * Zamówienie lub umowa, o których mowa w art. 106f ust. 1 pkt 4 ustawy (dla faktur zaliczkowych) w walucie, w której wystawiono fakturę zaliczkową.
   * W przypadku faktury korygującej fakturę zaliczkową należy prezentować różnice wynikające z korekty poszczególnych pozycji zamówienia lub umowy lub storna poszczególnych korygowanych pozycji zamówienia lub umowy i prawidłowe wartości korygowanych pozycji jako osobne wiersze, jeśli korekta dotyczy wartości zamówienia.
   * W przypadku faktur korygujących faktury zaliczkowe, jeśli korekta nie dotyczy wartości zamówienia i jednocześnie zmienia wysokość podstawy opodatkowania lub podatku, należy wprowadzić zapis stornujący dany wiersz wg stanu przed korektą i zapis przywracający wartość zamówienia w celu potwierdzenia braku zmiany jego wartości
   */
  Zamowienie?: Maybe<Zamowienie>;
};

/** Obiekt faktury w wersji domenowej Symfonii dla schematu V11089 */
export type FakturaInput = {
  /** Inne adnotacje na fakturze */
  Adnotacje: AdnotacjeInput;
  /** Korekta, obowiązkowa, gdy typ faktury to: KOR, KOR_ZAL lub KOR_ROZ */
  Korekta?: InputMaybe<KorektaInput>;
  /** Nabywca */
  Nabywca: PodmiotInput;
  /** Nagłówek */
  Naglowek: NaglowekInput;
  /** Płatność (nieobowiązkowe) */
  Platnosc?: InputMaybe<PlatnoscInput>;
  /** Podmiot upoważniony (nieobowiązkowe) */
  PodmiotUpowazniony?: InputMaybe<PodmiotUpowaznionyInput>;
  /** Podsumowanie VAT */
  PodsumowanieVat: Array<PodsumowanieVatInput>;
  /** Dodatkowe rozliczenia na fakturze (nieobowiązkowe) */
  Rozliczenie?: InputMaybe<RozliczenieInput>;
  /** Sprzedawca */
  Sprzedawca: PodmiotInput;
  /** Numery podmiotu lub grupy podmiotów w innych rejestrach i bazach danych (nieobowiązkowe) */
  Stopka?: InputMaybe<StopkaInput>;
  /** Lista podmiotów trzecich (nieobowiązkowe) */
  TrzeciPodmiot?: InputMaybe<Array<TrzeciPodmiotInput>>;
  /** Warunki transakcji (nieobowiązkowe) */
  WarunkiTransakcji?: InputMaybe<WarunkiTransakcjiInput>;
  /**
   * Wykorzystana wersja DTO
   * Nazewnictwo zgodnie ze standardem : V1, V2, V3, itd.
   */
  WersjaDTO?: InputMaybe<Scalars['String']>;
  /** Lista wierszy faktury */
  Wiersze: Array<WierszInput>;
  /**
   * Zamówienie lub umowa, o których mowa w art. 106f ust. 1 pkt 4 ustawy (dla faktur zaliczkowych) w walucie, w której wystawiono fakturę zaliczkową.
   * W przypadku faktury korygującej fakturę zaliczkową należy prezentować różnice wynikające z korekty poszczególnych pozycji zamówienia lub umowy lub storna poszczególnych korygowanych pozycji zamówienia lub umowy i prawidłowe wartości korygowanych pozycji jako osobne wiersze, jeśli korekta dotyczy wartości zamówienia.
   * W przypadku faktur korygujących faktury zaliczkowe, jeśli korekta nie dotyczy wartości zamówienia i jednocześnie zmienia wysokość podstawy opodatkowania lub podatku, należy wprowadzić zapis stornujący dany wiersz wg stanu przed korektą i zapis przywracający wartość zamówienia w celu potwierdzenia braku zmiany jego wartości
   */
  Zamowienie?: InputMaybe<ZamowienieInput>;
};

/** Obiekt faktury w wersji domenowej Symfonii dla schematu V12648 */
export type FakturaV2 = {
  /** Inne adnotacje na fakturze */
  Adnotacje: AdnotacjeV2;
  /** Korekta, obowiązkowa gdy typ faktury to: KOR, KOR_ZAL lub KOR_ROZ */
  Korekta?: Maybe<KorektaV2>;
  /** Nabywca */
  Nabywca: PodmiotV2;
  /** Nagłówek */
  Naglowek: NaglowekV2;
  /** Płatność (nieobowiązkowe) */
  Platnosc?: Maybe<PlatnoscV2>;
  /** Podmiot upoważniony (nieobowiązkowe) */
  PodmiotUpowazniony?: Maybe<PodmiotUpowaznionyV2>;
  /** Podsumowanie VAT */
  PodsumowanieVat: Array<PodsumowanieVat>;
  /** Dodatkowe rozliczenia na fakturze (nieobowiązkowe) */
  Rozliczenie?: Maybe<Rozliczenie>;
  /** Sprzedawca */
  Sprzedawca: PodmiotV2;
  /** Numery podmiotu lub grupy podmiotów w innych rejestrach i bazach danych (nieobowiązkowe) */
  Stopka?: Maybe<StopkaV2>;
  /** Lista podmiotów trzecich (nieobowiązkowe) */
  TrzeciPodmiot?: Maybe<Array<TrzeciPodmiotV2>>;
  /** Warunki transakcji (nieobowiązkowe) */
  WarunkiTransakcji?: Maybe<WarunkiTransakcjiV2>;
  /**
   * Wykorzystana wersja DTO
   * Nazewnictwo zgodnie ze standardem : V1, V2, V3, itd.
   */
  WersjaDTO?: Maybe<Scalars['String']>;
  /** Lista wierszy faktury */
  Wiersze: Array<WierszV2>;
  /**
   * Zamówienie lub umowa, o których mowa w art. 106f ust. 1 pkt 4 ustawy (dla faktur zaliczkowych) w walucie, w której wystawiono fakturę zaliczkową.
   * W przypadku faktury korygującej fakturę zaliczkową należy prezentować różnice wynikające z korekty poszczególnych pozycji zamówienia lub umowy lub storna poszczególnych korygowanych pozycji zamówienia lub umowy i prawidłowe wartości korygowanych pozycji jako osobne wiersze jeśli korekta dotyczy wartości zamówienia.
   * W przypadku faktur korygujących faktury zaliczkowe, jeśli korekta nie dotyczy wartości zamówienia i jednocześnie zmienia wysokość podstawy opodatkowania lub podatku, należy wprowadzić zapis stornujący dany wiersz wg stanu przed korektą i zapis przywracający wartość zamówienia w celu potwierdzenia braku zmiany jego wartości
   */
  Zamowienie?: Maybe<ZamowienieV2>;
};

/** Obiekt faktury w wersji domenowej Symfonii dla schematu V12648 */
export type FakturaV2Input = {
  /** Inne adnotacje na fakturze */
  Adnotacje: AdnotacjeV2Input;
  /** Korekta, obowiązkowa gdy typ faktury to: KOR, KOR_ZAL lub KOR_ROZ */
  Korekta?: InputMaybe<KorektaV2Input>;
  /** Nabywca */
  Nabywca: PodmiotV2Input;
  /** Nagłówek */
  Naglowek: NaglowekV2Input;
  /** Płatność (nieobowiązkowe) */
  Platnosc?: InputMaybe<PlatnoscV2Input>;
  /** Podmiot upoważniony (nieobowiązkowe) */
  PodmiotUpowazniony?: InputMaybe<PodmiotUpowaznionyV2Input>;
  /** Podsumowanie VAT */
  PodsumowanieVat: Array<PodsumowanieVatInput>;
  /** Dodatkowe rozliczenia na fakturze (nieobowiązkowe) */
  Rozliczenie?: InputMaybe<RozliczenieInput>;
  /** Sprzedawca */
  Sprzedawca: PodmiotV2Input;
  /** Numery podmiotu lub grupy podmiotów w innych rejestrach i bazach danych (nieobowiązkowe) */
  Stopka?: InputMaybe<StopkaV2Input>;
  /** Lista podmiotów trzecich (nieobowiązkowe) */
  TrzeciPodmiot?: InputMaybe<Array<TrzeciPodmiotV2Input>>;
  /** Warunki transakcji (nieobowiązkowe) */
  WarunkiTransakcji?: InputMaybe<WarunkiTransakcjiV2Input>;
  /**
   * Wykorzystana wersja DTO
   * Nazewnictwo zgodnie ze standardem : V1, V2, V3, itd.
   */
  WersjaDTO?: InputMaybe<Scalars['String']>;
  /** Lista wierszy faktury */
  Wiersze: Array<WierszV2Input>;
  /**
   * Zamówienie lub umowa, o których mowa w art. 106f ust. 1 pkt 4 ustawy (dla faktur zaliczkowych) w walucie, w której wystawiono fakturę zaliczkową.
   * W przypadku faktury korygującej fakturę zaliczkową należy prezentować różnice wynikające z korekty poszczególnych pozycji zamówienia lub umowy lub storna poszczególnych korygowanych pozycji zamówienia lub umowy i prawidłowe wartości korygowanych pozycji jako osobne wiersze jeśli korekta dotyczy wartości zamówienia.
   * W przypadku faktur korygujących faktury zaliczkowe, jeśli korekta nie dotyczy wartości zamówienia i jednocześnie zmienia wysokość podstawy opodatkowania lub podatku, należy wprowadzić zapis stornujący dany wiersz wg stanu przed korektą i zapis przywracający wartość zamówienia w celu potwierdzenia braku zmiany jego wartości
   */
  Zamowienie?: InputMaybe<ZamowienieV2Input>;
};

/** Element Faktura zaliczkowa */
export type FakturaZaliczkowaV2 = {
  /**
   * Numer faktury zaliczkowej wystawionej poza KSeF. Pole obowiązkowe dla faktury wystawianej po wydaniu towaru lub wykonaniu usługi, o której mowa w art. 106f ust. 3 ustawy i ostatniej z faktur, o której mowa w art. 106f ust. 4 ustawy.
   * Typ znakowy ograniczony do 256 znaków.
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NrFaZaliczkowej?: Maybe<Scalars['String']>;
  /**
   * Numer identyfikujący fakturę zaliczkową w KSeF. Pole obowiązkowe w przypadku, gdy faktura zaliczkowa była wystawiona za pomocą KSeF.
   * Numer identyfikujący fakturę w Krajowym Systemie e-Faktur (KSeF).
   * Pattern: ([1-9]((\d[1-9])|([1-9]\d))\d{7}|M\d{9}|[A-Z]{3}\d{7})-(20[2-9][0-9]|2[1-9][0-9]{2}|[3-9][0-9]{3})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])-([0-9A-F]{6})-?([0-9A-F]{6})-([0-9A-F]{2}).
   */
  NrKSeFFaZaliczkowej?: Maybe<Scalars['String']>;
  /** Znacznik faktury zaliczkowej wystawionej poza KSeF */
  NrKSeFZN?: Maybe<Scalars['Boolean']>;
};

/** Element Faktura zaliczkowa */
export type FakturaZaliczkowaV2Input = {
  /**
   * Numer faktury zaliczkowej wystawionej poza KSeF. Pole obowiązkowe dla faktury wystawianej po wydaniu towaru lub wykonaniu usługi, o której mowa w art. 106f ust. 3 ustawy i ostatniej z faktur, o której mowa w art. 106f ust. 4 ustawy.
   * Typ znakowy ograniczony do 256 znaków.
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NrFaZaliczkowej?: InputMaybe<Scalars['String']>;
  /**
   * Numer identyfikujący fakturę zaliczkową w KSeF. Pole obowiązkowe w przypadku, gdy faktura zaliczkowa była wystawiona za pomocą KSeF.
   * Numer identyfikujący fakturę w Krajowym Systemie e-Faktur (KSeF).
   * Pattern: ([1-9]((\d[1-9])|([1-9]\d))\d{7}|M\d{9}|[A-Z]{3}\d{7})-(20[2-9][0-9]|2[1-9][0-9]{2}|[3-9][0-9]{3})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])-([0-9A-F]{6})-?([0-9A-F]{6})-([0-9A-F]{2}).
   */
  NrKSeFFaZaliczkowej?: InputMaybe<Scalars['String']>;
  /** Znacznik faktury zaliczkowej wystawionej poza KSeF */
  NrKSeFZN?: InputMaybe<Scalars['Boolean']>;
};

/** Filtr faktur */
export type FilterFieldsInput = {
  /** Id firmy */
  CompanyId?: InputMaybe<Scalars['UUID']>;
  /** Data wystawienia faktury */
  DateOfIssue?: InputMaybe<DateRangeSchemaModelInput>;
  /** System docelowy */
  DestinationSystem?: InputMaybe<Scalars['String']>;
  /** Rodzaj faktury */
  DocumentType?: InputMaybe<RodzajFaktury>;
  /** Kod systemu Symfonii, który pobrał fakturę z Symfonia KSeF */
  Downloaded?: InputMaybe<SymfoniaSystem>;
  /** Nazwa pliku XML faktury */
  FileName?: InputMaybe<Scalars['String']>;
  /** Wartość faktury brutto */
  GrossValue?: InputMaybe<Scalars['Decimal']>;
  /** Rodzaj faktury powiązanej */
  InvoiceBound?: InputMaybe<InvoiceBound>;
  /** Nazwa sprzedawcy */
  IssuerName?: InputMaybe<Scalars['String']>;
  /** Numer NIP sprzedawcy */
  IssuerNip?: InputMaybe<Scalars['String']>;
  /** Numer referencyjny elementu w KSeF podczas wysyłania faktury */
  KSeFElementReferenceNumber?: InputMaybe<Scalars['String']>;
  /** Numer referencyjny sesji w KSeF podczas wysyłania faktury */
  KSeFSessionReferenceNumber?: InputMaybe<Scalars['String']>;
  /** Status dokumentu w systemie KSeF */
  KSeFStatus?: InputMaybe<KSeFStatus>;
  /** Opis błędu nadany przez system KSeF, o ile wystąpił */
  KSeFStatusErrorDescription?: InputMaybe<Scalars['String']>;
  /** Data wysłania do KSeF */
  KsefDate?: InputMaybe<DateRangeSchemaModelInput>;
  /** Numer nadany fakturze przez KSeF */
  KsefNumber?: InputMaybe<Scalars['String']>;
  /** Numer faktury */
  Number?: InputMaybe<Scalars['String']>;
  /** Adres email użytkownika, który zlecił wysyłkę faktury do KSeF */
  Owner?: InputMaybe<Scalars['String']>;
  /** Nazwa nabywcy */
  RecipientName?: InputMaybe<Scalars['String']>;
  /** Numer NIP nabywcy */
  RecipientNip?: InputMaybe<Scalars['String']>;
};

export type FiltersFieldsInput = {
  /** Filtorwanie po liście walut */
  Currencies?: InputMaybe<Array<Scalars['String']>>;
  /** Zakres dat wystawienia faktur */
  DateOfIssue?: InputMaybe<DateRangeSchemaModelInput>;
  /** Filtrowanie po rodzajach faktur */
  DocumentTypes?: InputMaybe<Array<RodzajFaktury>>;
  /** Tylko faktury, które mają status "Pobrane z KSeF" */
  Downloaded: Scalars['Boolean'];
  /** Filtrowanie po systemach, które pobrały fakturę */
  DownloadedBy?: InputMaybe<Array<SymfoniaSystem>>;
  /** Tylko faktury zakupowe */
  Foreign: Scalars['Boolean'];
  /** Zakres kwoty brutto faktur */
  GrossValue?: InputMaybe<NumberRangeSchemaModelOfDecimalInput>;
  /** Filtrowanie po liście id faktur w Symfonia KSeF */
  InvoiceIds?: InputMaybe<Array<Scalars['UUID']>>;
  /** Filtrowanie po statusach dekretacji */
  InvoicePostingStatuses?: InputMaybe<Array<InvoicePostingStatus>>;
  /** Filtrowanie po nazwach sprzedawców */
  IssuerNames?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie po numerach NIP sprzedawców */
  IssuerNips?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie po statusach faktur w KSeF */
  KSeFStatuses?: InputMaybe<Array<KSeFStatus>>;
  /** Zakres dat faktur wysłanych do KSeF */
  KsefDate?: InputMaybe<DateRangeSchemaModelInput>;
  /** Filtrowanie po liście numerów nadanych fakturom w KSeF */
  KsefNumbers?: InputMaybe<Array<Scalars['String']>>;
  /** Zakres kwoty netto faktur */
  NetValue?: InputMaybe<NumberRangeSchemaModelOfDecimalInput>;
  /** Filtrowanie po numerach faktur */
  Numbers?: InputMaybe<Array<Scalars['String']>>;
  /** Tylko faktury sprzedażowe */
  Own: Scalars['Boolean'];
  /** Tylko faktury w statusie "Do dekretacji" */
  ReadyForPosting: Scalars['Boolean'];
  /** Filtrowanie po nazwach odbiorców */
  RecipientNames?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie po numerach NIP odbiorców */
  RecipientNips?: InputMaybe<Array<Scalars['String']>>;
  /** Filtrowanie po numerach ewidencyjnych */
  RegistrationNumbers?: InputMaybe<Array<Scalars['String']>>;
  /** Tylko faktury, które mają status "Wysłane do KSeF" */
  Sent: Scalars['Boolean'];
  /** Tylko faktury z dekretami w statusie "Do akceptacji" */
  ToAccept: Scalars['Boolean'];
  /** Tylko faktury, które mają status "Do pobrania z KSeF" */
  ToDownload: Scalars['Boolean'];
  /** Tylko faktury, które mają status "Do wysłania do KSeF" */
  ToSend: Scalars['Boolean'];
  /** Zakres kwoty podatku vat faktur */
  VatValue?: InputMaybe<NumberRangeSchemaModelOfDecimalInput>;
};

export type FiltersFieldsIssuerInput = {
  Foreign: Scalars['Boolean'];
  Own: Scalars['Boolean'];
};

export type FiltersFieldsRecipientsInput = {
  Foreign: Scalars['Boolean'];
  Own: Scalars['Boolean'];
};

export type FinancialYearResponse = {
  BeginDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Id: Scalars['UUID'];
  IsActive: Scalars['Boolean'];
  IsConfigured: Scalars['Boolean'];
  LastSyncDate: Scalars['DateTime'];
  Name: Scalars['String'];
  Status: FinancialYearStatusEnum;
};

export type FinancialYearSortInput = {
  BeginDate?: InputMaybe<SortEnumType>;
  CompanyId?: InputMaybe<SortEnumType>;
  EndDate?: InputMaybe<SortEnumType>;
  Id?: InputMaybe<SortEnumType>;
  IsActive?: InputMaybe<SortEnumType>;
  IsConfigured?: InputMaybe<SortEnumType>;
  ModifiedAt?: InputMaybe<SortEnumType>;
  Name?: InputMaybe<SortEnumType>;
  SourceId?: InputMaybe<SortEnumType>;
  Status?: InputMaybe<SortEnumType>;
};

export enum FinancialYearStatusEnum {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Undefined = 'UNDEFINED'
}

/** Formy płatności na fakturze */
export enum FormaPlatnosci {
  /** bon */
  Bon = 'BON',
  /** czek */
  Czek = 'CZEK',
  /** gotówka */
  Gotowka = 'GOTOWKA',
  /** karta */
  Karta = 'KARTA',
  /** kredyt */
  Kredyt = 'KREDYT',
  /** mobilna */
  Mobilna = 'MOBILNA',
  /** przelew */
  Przelew = 'PRZELEW'
}

/** Kody GTU */
export enum Gtu {
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. a rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_01 = 'GTU_01',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. b rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_02 = 'GTU_02',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. c rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_03 = 'GTU_03',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. d rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_04 = 'GTU_04',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. e rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_05 = 'GTU_05',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. f rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_06 = 'GTU_06',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. g rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_07 = 'GTU_07',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. h rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_08 = 'GTU_08',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. i rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_09 = 'GTU_09',
  /** Dostawa towarów, o których mowa w § 10 ust. 3 pkt 1 lit. j rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_10 = 'GTU_10',
  /** Świadczenie usług, o których mowa w § 10 ust. 3 pkt 2 lit. a rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_11 = 'GTU_11',
  /** Świadczenie usług, o których mowa w § 10 ust. 3 pkt 2 lit. b rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_12 = 'GTU_12',
  /** Świadczenie usług, o których mowa w § 10 ust. 3 pkt 2 lit. c rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Gtu_13 = 'GTU_13'
}

export enum GtuCodeEnum {
  Gtu_01 = 'GTU_01',
  Gtu_02 = 'GTU_02',
  Gtu_03 = 'GTU_03',
  Gtu_04 = 'GTU_04',
  Gtu_05 = 'GTU_05',
  Gtu_06 = 'GTU_06',
  Gtu_07 = 'GTU_07',
  Gtu_08 = 'GTU_08',
  Gtu_09 = 'GTU_09',
  Gtu_10 = 'GTU_10',
  Gtu_11 = 'GTU_11',
  Gtu_12 = 'GTU_12',
  Gtu_13 = 'GTU_13'
}

/** Link do pobrania spakowanych załączników wraz z nazwą pliku */
export type GenerateZippedInvoiceAttachmentsResponse = {
  FileName?: Maybe<Scalars['String']>;
  Link?: Maybe<Scalars['String']>;
};

export type GetAccountsForPostingResponse = {
  AccountsPlan: Array<AccountResponse>;
};

export type GetAccountsResponse = {
  AccountsPlan: Array<AccountResponse>;
};

export type GetAttachmentByIdResponse = {
  Attachment?: Maybe<Attachment>;
};

/** Link do pobrania żądanego załącznika */
export type GetAttachmentLinkResponse = {
  Link?: Maybe<Scalars['String']>;
};

/** Wynik operacji pobrania ustawień załączników */
export type GetAttachmentSettingsResponse = {
  /** Lista dozwolonych rozszerzeń */
  AllowedAttachmentExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Lista dozwolonych nagłówków */
  AllowedAttachmentMimeTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Wynik operacji pobrania załączników */
export type GetAttachmentsResponse = {
  /** Lista załączników */
  Attachments?: Maybe<Array<Maybe<Attachment>>>;
};

export type GetAutoPostingModuleStatusQueryResponse = {
  ConfigurationStatus: ConfigurationStatusResponse;
  ModuleSettings: InitializationStatusResponse;
};

export type GetAutoPostingSettingsResponse = {
  AutoPostingSettings: AutoPostingSettingsResponse;
};

export type GetBackupInfoQueryResponse = {
  AppStreamBackups?: Maybe<Array<Maybe<AppStreamBackup>>>;
};

export type GetBitEnumValuesResponse = {
  enumMap: Array<KeyValuePairOfStringAndString>;
};

/** A segment of a collection. */
export type GetCategoriesViewModelCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<CategoryViewModel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type GetCategoryRulesResponse = {
  Items: Array<CategoryRuleResponse>;
  TotalCount?: Maybe<Scalars['Long']>;
};

export type GetCategoryRulesUsersAndPositionsResponse = {
  Positions: Array<CategoryRulesPositionsResponse>;
  UsersList: Array<CategoryRuleUserResponse>;
};

/** Dane zawierające statystyki faktur we wszystkich firmach w organizacji */
export type GetCompanyStatisticsResponse = {
  /** Organizacje */
  TenantAssignments?: Maybe<Array<Maybe<TenantAssignmentViewModel>>>;
  /** Liczba firm w bieżącej organizacji */
  TotalCompanysCount: Scalars['Long'];
  /** Liczba organizacji do których przypisany jest bieżący użytkownik */
  TotalTenantsCount: Scalars['Long'];
};

/** Dane zawierające statystyki faktur we wszystkich firmach w organizacji */
export type GetCompaniesForOrganisationResponse = {
  /** Organizacje */
  TenantAssignments?: Maybe<Array<Maybe<TenantInfoViewModel>>>;
  /** Liczba firm w bieżącej organizacji */
  TotalCompanysCount: Scalars['Long'];
  /** Liczba organizacji do których przypisany jest bieżący użytkownik */
  TotalTenantsCount: Scalars['Long'];
};

export type GetCompanyVehicleByIdResponse = {
  Vehicle: CompanyVehiclesResponse;
};

export type GetCompanyVehiclesResponse = {
  Items: Array<CompanyVehiclesResponse>;
  TotalCount?: Maybe<Scalars['Long']>;
};

export type GetContractorsSegment = {
  /** Ilość znalezionych kontrahentów wg zadanych filtrów z puli TotalCount */
  FilteredCount?: Maybe<Scalars['Long']>;
  /** Lista znalezionych kontrahentów */
  Items?: Maybe<Array<ContractorsDto>>;
  /** Ilość znalezionych kontrahentów z podziałem na zakupowe / sprzedażowe oraz wysłane / do wysłania */
  TotalCount?: Maybe<Scalars['Long']>;
};

/** A segment of a collection. */
export type GetContractorsViewModelCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<ContractorViewModel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Wynik operacji pobrania aktualnych wersji schemy faktury KSeF Ministerstwa Finansów */
export type GetCurrentSchemaVersionResponse = {
  /** Lista aktualnych wersji schemy KSeF */
  SchemaVersion?: Maybe<Array<KsefXsdVersion>>;
};

export type GetDecreeDetailsQueryResponse = {
  Decree: DecreeResponse;
};

export type GetDocumentTypesSettingsQueryResponse = {
  DocumentTypesVatRegistries: Array<DocumentTypesSettingsResponse>;
};

/** Wynik operacji pobrania listy obsługiwanych typów notyfikacji e-mailowych */
export type GetEArchiveNotificationsResponse = {
  /** Lista typów notyfikacji e-mailowych */
  Notifications?: Maybe<Array<EArchiveNotificationType>>;
};

export type GetEnumValuesResponse = {
  enumMap: Array<KeyValuePairOfStringAndString>;
};

export type GetFinancialYearsResponse = {
  FinancialYears: Array<FinancialYearResponse>;
};

export type GetInvoiceDocumentTypesResponse = {
  DocumentTypeResponse: Array<InvoiceDocumentTypeResponse>;
};

/** Faktury zbiorcze PDF w archiwum ZIP. */
export type GetInvoiceFileContentActionGroupType = {
  /** Typ pliku */
  MimeType?: Maybe<Scalars['String']>;
  /** Tablica bajtów Archiwum ZIP */
  ZipContent?: Maybe<Scalars['String']>;
  /** Nazwa archiwum ZIP */
  ZipName?: Maybe<Scalars['String']>;
};

/** Faktura w formacie HTML oraz PDF */
export type GetInvoiceFileContentSingleType = {
  /** Widok HTML faktury */
  HtmlContent: Scalars['String'];
  /** Typ pliku */
  MimeType: Scalars['String'];
  /** Tablica bajtów pliku pdf */
  PdfContent: Scalars['String'];
  /** Nazwa pliku pdf */
  PdfName: Scalars['String'];
};

export type GetInvoicesExtendedSegment = {
  /** Ilość znalezionych obiektów faktów wg. zadanych filtrów z puli TotalCount */
  FilteredCount?: Maybe<Scalars['Long']>;
  /** Lista znalezionych obiektów faktur */
  Items?: Maybe<Array<InvoiceDto>>;
  /** Ilość znalezionych obiektów faktur z podziałem na zakupowe / sprzedażowe oraz wysłane / do wysłania */
  TotalCount?: Maybe<Scalars['Long']>;
  /** Podsumowanie z podziałem na kwoty Netto, Brutto, Vat w kontekście waluty */
  TotalSummary?: Maybe<Array<InvoiceTotalSummary>>;
};

export type GetInvoicesSegment = {
  /** Ilość znalezionych obiektów faktur wg zadanych filtrów z puli TotalCount */
  FilteredCount?: Maybe<Scalars['Long']>;
  /** Lista znalezionych obiektów faktur */
  Items?: Maybe<Array<InvoiceDto>>;
  /** Ilość znalezionych obiektów faktur z podziałem na zakupowe / sprzedażowe oraz wysłane / do wysłania */
  TotalCount?: Maybe<Scalars['Long']>;
  /** Podsumowanie z podziałem na kwoty Netto, Brutto, Vat w kontekście waluty */
  TotalSummary?: Maybe<Array<InvoiceTotalSummary>>;
};

export type GetIssuerInvoicesSegment = {
  Items?: Maybe<Array<Issuer>>;
  TotalCount?: Maybe<Scalars['Long']>;
};

/** Status operacji */
export type GetMissedWebsocketSubscriptionsResponse = {
  /** true - jeżeli proces uruchomiono pomyślnie */
  Success: Scalars['Boolean'];
};

/** Dane zawierające statystyki pakietów w organizacji */
export type GetPackageStatisticSegment = {
  /** Liczba wykonanych operacji na fakturach w organizacji, widoczne tylko dla administratora organizacji. */
  CurrentInvoicesCount?: Maybe<Scalars['Int']>;
  /** Ilość zajętego miejsca przez faktury w organizacji, widoczne tylko dla administratora organizacji. */
  CurrentStorageCount?: Maybe<Scalars['Decimal']>;
  /** Numer klienta */
  CustomerNumber?: Maybe<Scalars['String']>;
  /** Data obowiązywania subskrypcji */
  DateTo: Scalars['Date'];
  /** Adres email administratora zamawiającego subskrypcję */
  Email?: Maybe<Scalars['String']>;
  /** Procent wykorzystania pakietu faktur. 0 - dostępny cały pakiet, 100 - cały pakiet zużyty */
  InvoicesUsedPercentage?: Maybe<Scalars['Decimal']>;
  /** Lista pakietów w ramach subskrypcji */
  Items: Array<Package>;
  /** Numer seryjny subskrypcji */
  SerialNumber?: Maybe<Scalars['String']>;
  /** Status subskrypcji */
  Status?: Maybe<SubscriptionStatus>;
  /** Procent wykorzystania pakietu miejsca na dysku. 0 - dostępny cały pakiet, 100 - cały pakiet zużyty */
  StorageUsedPercentage?: Maybe<Scalars['Decimal']>;
  /** Numer subskrypcji */
  SubscriptionNumber?: Maybe<Scalars['String']>;
};

export type GetPermissionsKSeFResponseType = {
  /** Lista uprawnień */
  Credentials: Array<Credential>;
  /** Liczba pobranych rekordów */
  FilteredCount: Scalars['Long'];
  /** True - dane pobrane z KSeF, false - dane z cache */
  FromKsef: Scalars['Boolean'];
  /** Ostatnia aktualizacja danych z cache */
  LastUpdated?: Maybe<Scalars['DateTime']>;
  /** Suma rekordów */
  TotalCount: Scalars['Long'];
};

export type GetPlatformNotificationsResponse = {
  Notifications?: Maybe<Array<PlatformNotificationType>>;
};

export type GetPostingInvoicesReportContractorFilterInput = {
  Identifier?: InputMaybe<StringOperationFilterInput>;
  Name?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<GetPostingInvoicesReportContractorFilterInput>>;
  or?: InputMaybe<Array<GetPostingInvoicesReportContractorFilterInput>>;
};

export type GetPostingInvoicesReportFilterInput = {
  Contractor?: InputMaybe<GetPostingInvoicesReportContractorFilterInput>;
  ContractorId?: InputMaybe<UuidOperationFilterInput>;
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  Gross?: InputMaybe<DecimalOperationFilterInput>;
  InvoiceNumber?: InputMaybe<StringOperationFilterInput>;
  InvoiceTypeGroup?: InputMaybe<InvoiceTypeGroupEnumOperationFilterInput>;
  IsAccepted?: InputMaybe<BooleanOperationFilterInput>;
  PostingStatus?: InputMaybe<PostingStatusEnumOperationFilterInput>;
  and?: InputMaybe<Array<GetPostingInvoicesReportFilterInput>>;
  or?: InputMaybe<Array<GetPostingInvoicesReportFilterInput>>;
};

export type GetPostingInvoicesReportResponse = {
  FileContent: Scalars['String'];
  FileName: Scalars['String'];
  MimeType: Scalars['String'];
};

export type GetPostingInvoicesReportSortInput = {
  CompanyId?: InputMaybe<SortEnumType>;
  Contractor?: InputMaybe<ContractorSortInput>;
  ContractorId?: InputMaybe<SortEnumType>;
  CreatedAt?: InputMaybe<SortEnumType>;
  CreatedBy?: InputMaybe<SortEnumType>;
  Currency?: InputMaybe<SortEnumType>;
  DocumentType?: InputMaybe<DocumentTypeSortInput>;
  DocumentTypeId?: InputMaybe<SortEnumType>;
  FinancialYear?: InputMaybe<FinancialYearSortInput>;
  FinancialYearId?: InputMaybe<SortEnumType>;
  Gross?: InputMaybe<SortEnumType>;
  Id?: InputMaybe<SortEnumType>;
  InvoiceDate?: InputMaybe<SortEnumType>;
  InvoiceDocumentId?: InputMaybe<SortEnumType>;
  InvoiceNumber?: InputMaybe<SortEnumType>;
  InvoiceType?: InputMaybe<SortEnumType>;
  InvoiceTypeGroup?: InputMaybe<SortEnumType>;
  IsAccepted?: InputMaybe<SortEnumType>;
  KsefDate?: InputMaybe<SortEnumType>;
  KsefNumber?: InputMaybe<SortEnumType>;
  ModifiedAt?: InputMaybe<SortEnumType>;
  ModifiedBy?: InputMaybe<SortEnumType>;
  Net?: InputMaybe<SortEnumType>;
  PaymentDate?: InputMaybe<SortEnumType>;
  PaymentType?: InputMaybe<SortEnumType>;
  PostingStatus?: InputMaybe<SortEnumType>;
  ServicesReceivedDate?: InputMaybe<SortEnumType>;
  Vat?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type GetPostingInvoicesViewModelCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<DecreeViewModel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type GetRecalculatedDecreeResponse = {
  RecalculatedDecree: DecreeResponse;
};

export type GetRecipientInvoicesSegment = {
  Items?: Maybe<Array<Recipient>>;
  TotalCount?: Maybe<Scalars['Long']>;
};

export type GetRequiredConfigurationStatusResponse = {
  IsRequiredConfiguration: Scalars['Boolean'];
};

export type GetSettlementsResponse = {
  Settlements: Array<SettlementResponse>;
};

/** Represents a record that serves as the output of the handler for retrieving company statistics. */
export type GetStatisticsForCompanyResponse = {
  /** Indicates whether it is connected to KSeF. */
  HasKSeFAuthorization: Scalars['Boolean'];
  /** The number of purchase invoices. */
  PurchaseInvoicesCount: Scalars['Int'];
  /** The number of sales invoices. */
  SalesInvoicesCount: Scalars['Int'];
};

export type GetUserKSeFRequestForCredentialsResponseType = {
  /** Liczba pobranych rekordów */
  FilteredCount: Scalars['Long'];
  /** Lista procesowanych uprawnień */
  RequestedCredentials: Array<RequestedCredential>;
  /** Suma rekordów */
  TotalCount: Scalars['Long'];
};

export type GetVatRegistriesResponse = {
  VatRegistries: Array<VatRegistriesResponse>;
};

/** Model odpowiedzi WebSocket */
export type GetWebsocketNotificationModelExtendedResponse = {
  /** Id firmy której notyfikacja dotyczy */
  CompanyId: Scalars['UUID'];
  /** Detale błędu */
  ErrorContent?: Maybe<Array<KeyValuePairOfStringAndString>>;
  /** Typ błędu */
  ErrorType?: Maybe<WebsocketErrorType>;
  /** Czy notyfikacja zawiera treść do pobrania */
  HasDownloadableContent: Scalars['Boolean'];
  /** Treść wiadomości */
  Message?: Maybe<Scalars['String']>;
  /** Id notyfikacji */
  NotificationId: Scalars['UUID'];
  /** Obiekt odpowiedzi w formacie JSON */
  ResponseJson?: Maybe<Scalars['String']>;
  /** Obiekt odpowiedzi */
  ResponseModel?: Maybe<ResponseModel>;
  /** Typ uruchomienia zadania */
  SimultaneousRunType: WebsocketSimultaneousRunType;
  /** Status notyfikacji */
  State: SubscriptionStateEnum;
  /** Znacznik czasu notyfikacji */
  Timestamp: Scalars['DateTime'];
  /** Typ notyfikacji */
  Type: WebsocketNotificationType;
  /** Identyfikator użytkownika */
  UserId: Scalars['UUID'];
  /** Znacznik odczytania notyfikacji */
  Visited: Scalars['Boolean'];
};

/** Model odpowiedzi WebSocket */
export type GetWebsocketNotificationModelResponse = {
  /** Id firmy której notyfikacja dotyczy */
  CompanyId: Scalars['UUID'];
  /** Detale błędu */
  ErrorContent?: Maybe<Array<KeyValuePairOfStringAndString>>;
  /** Typ błędu */
  ErrorType?: Maybe<WebsocketErrorType>;
  /** Czy notyfikacja zawiera treść do pobrania */
  HasDownloadableContent: Scalars['Boolean'];
  /** Treść wiadomości */
  Message?: Maybe<Scalars['String']>;
  /** Id notyfikacji */
  NotificationId: Scalars['UUID'];
  /** Obiekt odpowiedzi w formacie JSON */
  ResponseJson?: Maybe<Scalars['String']>;
  /** Typ uruchomienia zadania */
  SimultaneousRunType: WebsocketSimultaneousRunType;
  /** Status notyfikacji */
  State: SubscriptionStateEnum;
  /** Znacznik czasu notyfikacji */
  Timestamp: Scalars['DateTime'];
  /** Typ notyfikacji */
  Type: WebsocketNotificationType;
  /** Identyfikator użytkownika */
  UserId: Scalars['UUID'];
  /** Znacznik odczytania notyfikacji */
  Visited: Scalars['Boolean'];
};

/** Status operacji */
export type GetWebsocketNotificationResponse = {
  /** Lista powiadomień zadanego typu */
  WebsocketNotification?: Maybe<Array<Maybe<WebsocketNotificationModel>>>;
};

export type GusResponseData = {
  AddressLine?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  ZipCode?: Maybe<Scalars['String']>;
};

/** Numer identyfikacji ewidencyjnej/podatkowej */
export enum IdentifierType {
  /** Numer NIP */
  Nip = 'NIP',
  /** Numer PESEL */
  Pesel = 'PESEL'
}

export type IdentifierTypeOperationFilterInput = {
  eq?: InputMaybe<IdentifierType>;
  in?: InputMaybe<Array<IdentifierType>>;
  neq?: InputMaybe<IdentifierType>;
  nin?: InputMaybe<Array<IdentifierType>>;
};

export enum InitializationStatusEnum {
  Failed = 'FAILED',
  InitializationCompleted = 'INITIALIZATION_COMPLETED',
  NotInitialized = 'NOT_INITIALIZED',
  Pending = 'PENDING',
  Undefined = 'UNDEFINED'
}

export type InitializationStatusResponse = {
  ErrorDetails?: Maybe<Scalars['String']>;
  InitializationStatusValue: InitializationStatusEnum;
  InitializationTaskTypeValue: InitializationTaskTypeEnum;
  ModuleSubscriptionStatusValue: ModuleSubscriptionStatusEnum;
};

export enum InitializationTaskTypeEnum {
  CompleteInitialization = 'COMPLETE_INITIALIZATION',
  IsDesktopConnected = 'IS_DESKTOP_CONNECTED',
  MapAccountsToNewValues = 'MAP_ACCOUNTS_TO_NEW_VALUES',
  SaveAccounts = 'SAVE_ACCOUNTS',
  SaveContractors = 'SAVE_CONTRACTORS',
  SaveDictionaries = 'SAVE_DICTIONARIES',
  SaveDictionaryElements = 'SAVE_DICTIONARY_ELEMENTS',
  SaveDocumentTypes = 'SAVE_DOCUMENT_TYPES',
  SaveFinancialYears = 'SAVE_FINANCIAL_YEARS',
  SaveVatRegistries = 'SAVE_VAT_REGISTRIES',
  Undefined = 'UNDEFINED'
}

/** Zapytanie o aktywację triala */
export type Input = {
  /** Moduł dla którego jest aktywowany trial */
  CloudModule: CloudModuleType;
};

export type InputRequest = {
  /** Unikalny identyfikator */
  Id: Scalars['UUID'];
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

/** Rekord faktury */
export type Invoice = {
  /** Liczba załączników podpiętych pod fakturę */
  AttachmentsCount: Scalars['Int'];
  /** Rozmiar załączników w bajtach */
  AttachmentsSize: Scalars['Long'];
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Temporary property, flag for contractor data scan. Will be removed after the contractors job is finished */
  ContractorAcquired?: Maybe<Scalars['Boolean']>;
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Skrót waluty */
  Currency?: Maybe<Scalars['String']>;
  /** Data wystawienia */
  DateOfIssue: Scalars['DateTime'];
  /** System docelowy */
  DestinationSystem?: Maybe<Scalars['String']>;
  /** Rodzaj faktury */
  DocumentType: RodzajFaktury;
  /** Kod systemu Symfonii, który pobrał fakturę z Symfonia KSeF */
  Downloaded?: Maybe<SymfoniaSystem>;
  /** Nazwa pliku XML zawierająca fakturę w schemacie obsługiwanym przez KSeF */
  FileName?: Maybe<Scalars['String']>;
  /** Rozmiar pliku XML w bajtach */
  FileSize: Scalars['Long'];
  /** Wartość faktury brutto */
  GrossValue: Scalars['Decimal'];
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Rodzaj faktury powiązanej */
  InvoiceBound: InvoiceBound;
  /** Status dekretacji faktury. */
  InvoicePostingStatus: InvoicePostingStatus;
  /** Czy dekret faktury jest zaakceptowany */
  IsDecreeAccepted: Scalars['Boolean'];
  /** Nazwa sprzedawcy */
  IssuerName?: Maybe<Scalars['String']>;
  /** Numer NIP sprzedawcy */
  IssuerNip?: Maybe<Scalars['String']>;
  /**
   * W przypadku, kiedy Owner w momencie wysyłania faktury nie jest autoryzowany w KSeF,
   * zostanie podjęta próba wysłania faktury za pomocą autoryzacji innego użytkownika, który posiada odpowiednie uprawnienia w KSeF do obsługi danej firmy,
   * o ile taki użytkownik istnieje
   */
  KSeFAuthUser?: Maybe<Scalars['String']>;
  /** Numer referencyjny elementu w KSeF podczas wysyłania faktury */
  KSeFElementReferenceNumber?: Maybe<Scalars['String']>;
  /** Numer referencyjny sesji w KSeF podczas wysyłania faktury */
  KSeFSessionReferenceNumber?: Maybe<Scalars['String']>;
  /** Status dokumentu w systemie KSeF */
  KSeFStatus: KSeFStatus;
  /** Opis błędu nadany przez system KSeF, o ile wystąpił */
  KSeFStatusErrorDescription?: Maybe<Scalars['String']>;
  /** Data wysłania do KSeF */
  KsefDate?: Maybe<Scalars['DateTime']>;
  /** Numer nadany fakturze przez KSeF */
  KsefNumber?: Maybe<Scalars['String']>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Wartość faktury netto */
  NetValue?: Maybe<Scalars['Decimal']>;
  /** Numer faktury */
  Number?: Maybe<Scalars['String']>;
  /** Adres e-mail użytkownika, który zlecił wysyłkę faktury do KSeF */
  Owner?: Maybe<Scalars['String']>;
  /** Liczba stron faktury OCR, w pozostałych przypadkach: 1 */
  PageCount: Scalars['Int'];
  /** Nazwa nabywcy */
  RecipientName?: Maybe<Scalars['String']>;
  /** Numer NIP nabywcy */
  RecipientNip?: Maybe<Scalars['String']>;
  /**
   * Numer ewidencyjny faktury.
   * Otrzymywany po wysłaniu faktury do Systemu Symfonia FK
   */
  RegistrationNumber?: Maybe<Scalars['String']>;
  /** Wersja schematu faktury KSeF */
  SchemaVersion: KsefXsdVersion;
  /** Skrócona data wystawienia */
  ShortDateOfIssue: Scalars['Date'];
  /** Skrócona data wysłania do KSeF */
  ShortKsefDate?: Maybe<Scalars['Date']>;
  /** Wartość VAT faktury */
  VatValue?: Maybe<Scalars['Decimal']>;
};

/** Rodzaj faktury powiązanej */
export enum InvoiceBound {
  /** Faktura zakupowa */
  External = 'EXTERNAL',
  /** Faktura sprzedażowa */
  Internal = 'INTERNAL'
}

export type InvoiceBoundOperationFilterInput = {
  eq?: InputMaybe<InvoiceBound>;
  in?: InputMaybe<Array<InvoiceBound>>;
  neq?: InputMaybe<InvoiceBound>;
  nin?: InputMaybe<Array<InvoiceBound>>;
};

export type InvoiceDocumentTypeResponse = {
  Id: Scalars['UUID'];
  Name: Scalars['String'];
  Symbol?: Maybe<Scalars['String']>;
  VatRegistryId?: Maybe<Scalars['UUID']>;
  VatRegistryName?: Maybe<Scalars['String']>;
};

export type InvoiceDto = {
  /** Liczba załączników dla faktury */
  AttachmentsCount: Scalars['Int'];
  /** Określa czy dla tej faktury można pobrać UPO */
  CanDownloadUpo: Scalars['Boolean'];
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** Email użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Kod Waluty */
  Currency?: Maybe<Scalars['String']>;
  /** Data wystawienia */
  DateOfIssue: Scalars['DateTime'];
  /** System docelowy */
  DestinationSystem?: Maybe<Scalars['String']>;
  /** Rodzaj faktury */
  DocumentType: RodzajFaktury;
  /** Kod systemu Symfonii, który pobrał fakturę z Symfonia KSeF */
  Downloaded?: Maybe<SymfoniaSystem>;
  /** Nazwa pliku XML zawierająca fakturę w schemacie obsługiwanym przez KSeF */
  FileName?: Maybe<Scalars['String']>;
  /** Wartość faktury brutto */
  GrossValue: Scalars['Decimal'];
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Rodzaj faktury powiązanej */
  InvoiceBound: InvoiceBound;
  /** Status dekretacji faktury */
  InvoicePostingStatus: InvoicePostingStatus;
  /** Informacja na temat akceptacji dekretacji faktury */
  IsDecreeAccepted: Scalars['Boolean'];
  /** Nazwa sprzedawcy */
  IssuerName?: Maybe<Scalars['String']>;
  /** Numer NIP sprzedawcy */
  IssuerNip?: Maybe<Scalars['String']>;
  /** Status dokumentu w systemie KSeF */
  KSeFStatus: KSeFStatus;
  /** Opis błędu nadany przez system KSeF, o ile wystąpił */
  KSeFStatusErrorDescription?: Maybe<Scalars['String']>;
  /** Data wysłania do KSeF */
  KsefDate?: Maybe<Scalars['DateTime']>;
  /** Numer nadany fakturze przez KSeF */
  KsefNumber?: Maybe<Scalars['String']>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** Email użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Wartość faktury netto */
  NetValue: Scalars['Decimal'];
  /** Numer faktury */
  Number?: Maybe<Scalars['String']>;
  /** Nazwa nabywcy */
  RecipientName?: Maybe<Scalars['String']>;
  /** Numer NIP nabywcy */
  RecipientNip?: Maybe<Scalars['String']>;
  /** Numer ewidencyjny faktury. Otrzymywany po wysłaniu faktury do Systemu Symfonia FK */
  RegistrationNumber?: Maybe<Scalars['String']>;
  /** Wersja schematu faktury KSeF */
  SchemaVersion: KsefXsdVersion;
  /** Wartość podatku vat */
  VatValue: Scalars['Decimal'];
  /** Wynik sprawdzenia rachunków bankowych kontrahenta na białej liście */
  WhiteListStatus?: Maybe<WhiteListStatusDtoModel>;
};

/** Rekord faktury */
export type InvoiceFilterInput = {
  /** Liczba załączników podpiętych pod fakturę */
  AttachmentsCount?: InputMaybe<IntOperationFilterInput>;
  /** Rozmiar załączników w bajtach */
  AttachmentsSize?: InputMaybe<LongOperationFilterInput>;
  /** Id firmy */
  CompanyId?: InputMaybe<UuidOperationFilterInput>;
  /** Temporary property, flag for contractor data scan. Will be removed after the contractors job is finished */
  ContractorAcquired?: InputMaybe<BooleanOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Skrót waluty */
  Currency?: InputMaybe<StringOperationFilterInput>;
  /** Data wystawienia */
  DateOfIssue?: InputMaybe<DateTimeOperationFilterInput>;
  /** System docelowy */
  DestinationSystem?: InputMaybe<StringOperationFilterInput>;
  /** Rodzaj faktury */
  DocumentType?: InputMaybe<RodzajFakturyOperationFilterInput>;
  /** Kod systemu Symfonii, który pobrał fakturę z Symfonia KSeF */
  Downloaded?: InputMaybe<NullableOfSymfoniaSystemOperationFilterInput>;
  /** Nazwa pliku XML zawierająca fakturę w schemacie obsługiwanym przez KSeF */
  FileName?: InputMaybe<StringOperationFilterInput>;
  /** Rozmiar pliku XML w bajtach */
  FileSize?: InputMaybe<LongOperationFilterInput>;
  /** Wartość faktury brutto */
  GrossValue?: InputMaybe<DecimalOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Rodzaj faktury powiązanej */
  InvoiceBound?: InputMaybe<InvoiceBoundOperationFilterInput>;
  /** Status dekretacji faktury. */
  InvoicePostingStatus?: InputMaybe<InvoicePostingStatusOperationFilterInput>;
  /** Czy dekret faktury jest zaakceptowany */
  IsDecreeAccepted?: InputMaybe<BooleanOperationFilterInput>;
  /** Nazwa sprzedawcy */
  IssuerName?: InputMaybe<StringOperationFilterInput>;
  /** Numer NIP sprzedawcy */
  IssuerNip?: InputMaybe<StringOperationFilterInput>;
  /**
   * W przypadku, kiedy Owner w momencie wysyłania faktury nie jest autoryzowany w KSeF,
   * zostanie podjęta próba wysłania faktury za pomocą autoryzacji innego użytkownika, który posiada odpowiednie uprawnienia w KSeF do obsługi danej firmy,
   * o ile taki użytkownik istnieje
   */
  KSeFAuthUser?: InputMaybe<StringOperationFilterInput>;
  /** Numer referencyjny elementu w KSeF podczas wysyłania faktury */
  KSeFElementReferenceNumber?: InputMaybe<StringOperationFilterInput>;
  /** Numer referencyjny sesji w KSeF podczas wysyłania faktury */
  KSeFSessionReferenceNumber?: InputMaybe<StringOperationFilterInput>;
  /** Status dokumentu w systemie KSeF */
  KSeFStatus?: InputMaybe<KSeFStatusOperationFilterInput>;
  /** Opis błędu nadany przez system KSeF, o ile wystąpił */
  KSeFStatusErrorDescription?: InputMaybe<StringOperationFilterInput>;
  /** Data wysłania do KSeF */
  KsefDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Numer nadany fakturze przez KSeF */
  KsefNumber?: InputMaybe<StringOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  /** Wartość faktury netto */
  NetValue?: InputMaybe<DecimalOperationFilterInput>;
  /** Numer faktury */
  Number?: InputMaybe<StringOperationFilterInput>;
  /** Adres e-mail użytkownika, który zlecił wysyłkę faktury do KSeF */
  Owner?: InputMaybe<StringOperationFilterInput>;
  /** Liczba stron faktury OCR, w pozostałych przypadkach: 1 */
  PageCount?: InputMaybe<IntOperationFilterInput>;
  /** Nazwa nabywcy */
  RecipientName?: InputMaybe<StringOperationFilterInput>;
  /** Numer NIP nabywcy */
  RecipientNip?: InputMaybe<StringOperationFilterInput>;
  /**
   * Numer ewidencyjny faktury.
   * Otrzymywany po wysłaniu faktury do Systemu Symfonia FK
   */
  RegistrationNumber?: InputMaybe<StringOperationFilterInput>;
  /** Wersja schematu faktury KSeF */
  SchemaVersion?: InputMaybe<KsefXsdVersionOperationFilterInput>;
  /** Skrócona data wystawienia */
  ShortDateOfIssue?: InputMaybe<DateOperationFilterInput>;
  /** Skrócona data wysłania do KSeF */
  ShortKsefDate?: InputMaybe<DateOperationFilterInput>;
  /** Wartość VAT faktury */
  VatValue?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<InvoiceFilterInput>>;
  or?: InputMaybe<Array<InvoiceFilterInput>>;
};

/** Powód, dla którego faktura nie może zostać usunięta. */
export enum InvoiceNonRemovableReason {
  /** Dokument został przesłany do systemów zewnętrznych. */
  AlreadyExported = 'ALREADY_EXPORTED',
  /** Wartość domyślna. */
  None = 'NONE',
  /** Dokument o podanym GUID nie został odnaleziony. */
  NotFound = 'NOT_FOUND',
  /** Dokument nie jest dodany przez aktualnego użytkownika. */
  NotOwner = 'NOT_OWNER',
  /** Błąd podczas usuwania dokumentu. */
  ServerError = 'SERVER_ERROR',
  /** Dokument posiada status w KSeF wykluczający usunięcie. */
  WrongStatus = 'WRONG_STATUS',
  /** Dokument nie jest plikiem typu: OCR. */
  WrongType = 'WRONG_TYPE'
}

/** Status zadekretowania faktury */
export enum InvoicePostingStatus {
  /** Pobrano do FK */
  DecreeDownloaded = 'DECREE_DOWNLOADED',
  /** Wysyłanie do FK */
  DecreeSent = 'DECREE_SENT',
  /** Brak */
  NotAllowed = 'NOT_ALLOWED',
  /** Zadekretowano */
  Posted = 'POSTED',
  /** W trakcie */
  Posting = 'POSTING',
  /** Do ponownej dekretacji */
  PostingFailed = 'POSTING_FAILED',
  /** Częściowa dekretacja */
  PostingIncomplete = 'POSTING_INCOMPLETE',
  /** Do dekretacji */
  ReadyForPosting = 'READY_FOR_POSTING',
  /** Wartość techniczna, nieprzeznaczona do użytku */
  Undefined = 'UNDEFINED'
}

export type InvoicePostingStatusOperationFilterInput = {
  eq?: InputMaybe<InvoicePostingStatus>;
  in?: InputMaybe<Array<InvoicePostingStatus>>;
  neq?: InputMaybe<InvoicePostingStatus>;
  nin?: InputMaybe<Array<InvoicePostingStatus>>;
};

/** Rekord faktury */
export type InvoiceSortInput = {
  /** Liczba załączników podpiętych pod fakturę */
  AttachmentsCount?: InputMaybe<SortEnumType>;
  /** Rozmiar załączników w bajtach */
  AttachmentsSize?: InputMaybe<SortEnumType>;
  /** Id firmy */
  CompanyId?: InputMaybe<SortEnumType>;
  /** Temporary property, flag for contractor data scan. Will be removed after the contractors job is finished */
  ContractorAcquired?: InputMaybe<SortEnumType>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<SortEnumType>;
  /** Skrót waluty */
  Currency?: InputMaybe<SortEnumType>;
  /** Data wystawienia */
  DateOfIssue?: InputMaybe<SortEnumType>;
  /** System docelowy */
  DestinationSystem?: InputMaybe<SortEnumType>;
  /** Rodzaj faktury */
  DocumentType?: InputMaybe<SortEnumType>;
  /** Kod systemu Symfonii, który pobrał fakturę z Symfonia KSeF */
  Downloaded?: InputMaybe<SortEnumType>;
  /** Nazwa pliku XML zawierająca fakturę w schemacie obsługiwanym przez KSeF */
  FileName?: InputMaybe<SortEnumType>;
  /** Rozmiar pliku XML w bajtach */
  FileSize?: InputMaybe<SortEnumType>;
  /** Wartość faktury brutto */
  GrossValue?: InputMaybe<SortEnumType>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<SortEnumType>;
  /** Rodzaj faktury powiązanej */
  InvoiceBound?: InputMaybe<SortEnumType>;
  /** Status dekretacji faktury. */
  InvoicePostingStatus?: InputMaybe<SortEnumType>;
  /** Czy dekret faktury jest zaakceptowany */
  IsDecreeAccepted?: InputMaybe<SortEnumType>;
  /** Nazwa sprzedawcy */
  IssuerName?: InputMaybe<SortEnumType>;
  /** Numer NIP sprzedawcy */
  IssuerNip?: InputMaybe<SortEnumType>;
  /**
   * W przypadku, kiedy Owner w momencie wysyłania faktury nie jest autoryzowany w KSeF,
   * zostanie podjęta próba wysłania faktury za pomocą autoryzacji innego użytkownika, który posiada odpowiednie uprawnienia w KSeF do obsługi danej firmy,
   * o ile taki użytkownik istnieje
   */
  KSeFAuthUser?: InputMaybe<SortEnumType>;
  /** Numer referencyjny elementu w KSeF podczas wysyłania faktury */
  KSeFElementReferenceNumber?: InputMaybe<SortEnumType>;
  /** Numer referencyjny sesji w KSeF podczas wysyłania faktury */
  KSeFSessionReferenceNumber?: InputMaybe<SortEnumType>;
  /** Status dokumentu w systemie KSeF */
  KSeFStatus?: InputMaybe<SortEnumType>;
  /** Opis błędu nadany przez system KSeF, o ile wystąpił */
  KSeFStatusErrorDescription?: InputMaybe<SortEnumType>;
  /** Data wysłania do KSeF */
  KsefDate?: InputMaybe<SortEnumType>;
  /** Numer nadany fakturze przez KSeF */
  KsefNumber?: InputMaybe<SortEnumType>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<SortEnumType>;
  /** Wartość faktury netto */
  NetValue?: InputMaybe<SortEnumType>;
  /** Numer faktury */
  Number?: InputMaybe<SortEnumType>;
  /** Adres e-mail użytkownika, który zlecił wysyłkę faktury do KSeF */
  Owner?: InputMaybe<SortEnumType>;
  /** Liczba stron faktury OCR, w pozostałych przypadkach: 1 */
  PageCount?: InputMaybe<SortEnumType>;
  /** Nazwa nabywcy */
  RecipientName?: InputMaybe<SortEnumType>;
  /** Numer NIP nabywcy */
  RecipientNip?: InputMaybe<SortEnumType>;
  /**
   * Numer ewidencyjny faktury.
   * Otrzymywany po wysłaniu faktury do Systemu Symfonia FK
   */
  RegistrationNumber?: InputMaybe<SortEnumType>;
  /** Wersja schematu faktury KSeF */
  SchemaVersion?: InputMaybe<SortEnumType>;
  /** Skrócona data wystawienia */
  ShortDateOfIssue?: InputMaybe<SortEnumType>;
  /** Skrócona data wysłania do KSeF */
  ShortKsefDate?: InputMaybe<SortEnumType>;
  /** Wartość VAT faktury */
  VatValue?: InputMaybe<SortEnumType>;
};

/** Podsumowanie z podziałem na kwoty Netto, Brutto, Vat w kontekście waluty */
export type InvoiceTotalSummary = {
  /** Waluta */
  Currency?: Maybe<Scalars['String']>;
  /** Suma kwot brutto */
  GrossValueSum: Scalars['Decimal'];
  /** Suma kwot netto */
  NetValueSum: Scalars['Decimal'];
  /** Suma kwot Vat */
  VatValueSum: Scalars['Decimal'];
};

export enum InvoiceTypeEnum {
  Kor = 'KOR',
  KorRoz = 'KOR_ROZ',
  KorZal = 'KOR_ZAL',
  Roz = 'ROZ',
  Undefined = 'UNDEFINED',
  Upr = 'UPR',
  Vat = 'VAT',
  Zal = 'ZAL'
}

export enum InvoiceTypeGroupEnum {
  Purchase = 'PURCHASE',
  Sales = 'SALES',
  Undefined = 'UNDEFINED'
}

export type InvoiceTypeGroupEnumOperationFilterInput = {
  eq?: InputMaybe<InvoiceTypeGroupEnum>;
  in?: InputMaybe<Array<InvoiceTypeGroupEnum>>;
  neq?: InputMaybe<InvoiceTypeGroupEnum>;
  nin?: InputMaybe<Array<InvoiceTypeGroupEnum>>;
};

/** A segment of a collection. */
export type InvoicesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<Invoice>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type InvoicesReportType = {
  FileContent: Scalars['String'];
  FileName: Scalars['String'];
  MimeType: Scalars['String'];
};

export type IsCompanyConnectedQueryResponse = {
  IsConnected: Scalars['Boolean'];
};

/** Wynik operacji */
export type IsDownloadInvoicesRunningResult = {
  IsRunning: Scalars['Boolean'];
};

export type Issuer = {
  InvoiceBound: InvoiceBound;
  IssuerName: Scalars['String'];
  IssuertNip: Scalars['String'];
};

/** Kolejność wyświetlania */
export type ItemsOrderInput = {
  /** Kolejność */
  Direction?: InputMaybe<OrderDirection>;
  /** Nazwa pola */
  FieldName?: InputMaybe<Scalars['String']>;
};

/** Status autoryzacji użytkownika w KSeF */
export enum KSeFAuthResult {
  /** Użytkownik zautoryzowany (Szafir) */
  Authorized = 'AUTHORIZED',
  /** Użytkownik zautoryzowany zewnętrznym tokenem */
  AuthorizedWithExternalToken = 'AUTHORIZED_WITH_EXTERNAL_TOKEN',
  /** Rozpoczęty proces autoryzacji */
  InProgress = 'IN_PROGRESS',
  /** Brak autoryzacji */
  NotAuthorized = 'NOT_AUTHORIZED'
}

/** Wynik operacji */
export type KSeFAutoFetchingInvoicesResponseType = {
  /** true - serwis aktywny */
  Success: Scalars['Boolean'];
};

/** Wynik operacji */
export type KSeFAutoSendingInvoicesResponseType = {
  /** true - serwis aktywny */
  Success: Scalars['Boolean'];
};

/** Typ automatycznego wysyłania faktur */
export enum KSeFAutomaticOperationType {
  /** Interwał czasowy */
  Interval = 'INTERVAL',
  /** Określona godzina */
  Time = 'TIME'
}

/** Model uprawnień w KSeF MF */
export type KSeFCredentialState = {
  /** Posiada wpis uprawnień */
  HasAny: Scalars['Boolean'];
  /** Może modyfikować uprawnienia */
  HasCredentialsManage: Scalars['Boolean'];
  /** Może odczytywać uprawienia */
  HasCredentialsRead: Scalars['Boolean'];
  /** Może odczytywać faktury */
  HasInvoiceRead: Scalars['Boolean'];
  /** Może dodawać i wysyłać faktury */
  HasInvoiceWrite: Scalars['Boolean'];
};

/** Typ identyfikatora podmiotu w KSeF Ministerstwa Finansów */
export enum KSeFIdentifierType {
  /** Token autoryzacyjny */
  AuthorizationToken = 'AUTHORIZATION_TOKEN',
  /** Odcisk palca certyfikatu (fingerprint) */
  CertificateFingerprint = 'CERTIFICATE_FINGERPRINT',
  /** Identyfikator indywidualny NIP (nip) */
  IndividualNip = 'INDIVIDUAL_NIP',
  /** Identyfikator instytucjonalny NIP (onip) */
  InstitutionalNip = 'INSTITUTIONAL_NIP',
  /** Identyfikator indywidualny PESEL (pesel) */
  Pesel = 'PESEL',
  /** Identyfikator instytucjonalny wewnętrzny (int) */
  System = 'SYSTEM'
}

export type KSeFInvoiceDtoType = {
  InvoiceId: Scalars['UUID'];
};

export type KSeFInvoiceDtov2Type = {
  InvoiceId: Scalars['UUID'];
};

/** Wynik operacji */
export type KSeFInvoiceWhiteListValidationResponseType = {
  /** true - serwis aktywny */
  Success: Scalars['Boolean'];
};

/** Faktura w formacie XML */
export type KSeFInvoiceXmlType = {
  /** Faktura XML */
  Content?: Maybe<Scalars['String']>;
  /** Nazwa pliku XML */
  FileName?: Maybe<Scalars['String']>;
};

/** Wynik operacji */
export type KSeFInvoicesDownloadSharedAccessResponseType = {
  /** true - serwis aktywny */
  Success: Scalars['Boolean'];
};

export enum KSeFOperatingStatus {
  /** KSeF niedostępny */
  Down = 'DOWN',
  /** KSeF dostępny */
  Operational = 'OPERATIONAL',
  /** Zbyt wiele czasu upłynęło od ostatniej odpowiedzi z KSeF */
  Unknown = 'UNKNOWN'
}

/** Informacja dotycząca stanu pracy KSeF */
export type KSeFOperatingStatusMessage = {
  /** Status serwisu KSeF */
  status: KSeFOperatingStatus;
  /** Data ustawienia statusu */
  statusDate: Scalars['DateTime'];
};

/** Wynik operacji modyfikacji uprawnień */
export enum KSeFRequestForCredentialResultType {
  /** Edycja uprawnień */
  Edit = 'EDIT',
  /** Nadanir uprawnień */
  Grant = 'GRANT',
  /** Nie wykonano żadnej operacji */
  None = 'NONE',
  /** Odebranie uprawnień */
  Revoke = 'REVOKE'
}

/** Typ żądania dotyczącego uprawnień */
export enum KSeFRequestForCredentialType {
  /** Nadanie uprawnienia */
  Grant = 'GRANT',
  /** Odwołanie uprawnienia */
  Revoke = 'REVOKE',
  /** Wartość serwisowa */
  Undefined = 'UNDEFINED'
}

/** Etapy procesu przetwarzania poświadczeń */
export enum KSeFRequestForCredentialsProcessingStatus {
  /** Autoryzacja procesu */
  Authorise = 'AUTHORISE',
  /** Błąd weryfikacji poprawności i spójności danych */
  Error1 = 'ERROR1',
  /** Błąd Autoryzacja procesu */
  Error2 = 'ERROR2',
  /** Błąd weryfikacji wyników podprocesu uwierzytelniania */
  Error3 = 'ERROR3',
  /** Błąd weryfikacji wyników podprocesu uwierzytelniania */
  Error4 = 'ERROR4',
  /** Błąd przetwarzania poświadczeń */
  Error5 = 'ERROR5',
  /** Błąd finalizacja i zakończenia procesu */
  Error6 = 'ERROR6',
  /** Finalizacja i zakończenie procesu */
  Finalize = 'FINALIZE',
  /** Poprawne zakończenie procesu */
  Finished = 'FINISHED',
  /** Przetwarzanie poświadczeń */
  Process = 'PROCESS',
  /** Weryfikacja poprawności i spójności danych */
  SanityCheck = 'SANITY_CHECK',
  /** Weryfikacja wyników podprocesu uwierzytelniania */
  Security = 'SECURITY'
}

/** Informacja o aktualnym stanie sesji w KSeF */
export type KSeFSessionStatusResponseType = {
  /** Znacznik aktywnej sesji */
  Active: Scalars['Boolean'];
  /** Termin wygaśnięcia sesji */
  ExpirationTime?: Maybe<Scalars['DateTime']>;
};

/** Sposób autoryzacji w KSeF MF */
export enum KSeFSignedType {
  /** Autoryzacja tokenem */
  ExternalToken = 'EXTERNAL_TOKEN',
  /** Autoryzacja podpisem */
  SessionSigned = 'SESSION_SIGNED'
}

/** Status faktur w KSeF */
export enum KSeFStatus {
  /** Zaakceptowana */
  Approved = 'APPROVED',
  /** Błąd */
  Error = 'ERROR',
  /** Przerwane sprawdzanie statusu faktur (brak autoryzacji) */
  InterruptedPending = 'INTERRUPTED_PENDING',
  /** Przerwane wysyłanie faktur (brak autoryzacji) */
  InterruptedSending = 'INTERRUPTED_SENDING',
  /** Nie wysłana */
  NotSent = 'NOT_SENT',
  /** Faktura OCR, nie podlega wysyłce do KSeF */
  Ocr = 'OCR',
  /** Przetwarzana */
  Pending = 'PENDING',
  /** Odrzucona */
  Rejected = 'REJECTED',
  /** W trakcie wysyłki */
  Sending = 'SENDING'
}

export type KSeFStatusOperationFilterInput = {
  eq?: InputMaybe<KSeFStatus>;
  in?: InputMaybe<Array<KSeFStatus>>;
  neq?: InputMaybe<KSeFStatus>;
  nin?: InputMaybe<Array<KSeFStatus>>;
};

/** Uprawnienia KSeF */
export enum KSeFTokenCredential {
  /** Nadawanie uprawnień */
  CredentialsManage = 'CREDENTIALS_MANAGE',
  /** Odczyt uprawnień */
  CredentialsRead = 'CREDENTIALS_READ',
  /** Odczyt faktur */
  InvoiceRead = 'INVOICE_READ',
  /** Zapis/wysyłanie faktur */
  InvoiceWrite = 'INVOICE_WRITE',
  PaymentConfirmationWrite = 'PAYMENT_CONFIRMATION_WRITE'
}

/** Status i uprawnienia użytkownika w KSeF */
export type KSeFUserPropertiesType = {
  /** Status autoryzacji użytkownika w KSeF */
  Authorized: KSeFAuthResult;
  AuthorizedAt?: Maybe<Scalars['DateTime']>;
  AuthorizedBy?: Maybe<Scalars['String']>;
  /** Uprawnienia użytkownika w KSeF */
  Credentials?: Maybe<Array<KSeFTokenCredential>>;
};

export type KeyValuePairOfStringAndString = {
  Key: Scalars['String'];
  Value: Scalars['String'];
};

export type KeyValuePairOfStringAndStringInput = {
  Key: Scalars['String'];
  Value: Scalars['String'];
};

/** Kody krajów używane w danych adresowych podmiotu na fakturze */
export enum KodKraju {
  /** ANDORA */
  Ad = 'AD',
  /** ZJEDNOCZONE EMIRATY ARABSKIE */
  Ae = 'AE',
  /** AFGANISTAN */
  Af = 'AF',
  /** ANTIGUA I BARBUDA */
  Ag = 'AG',
  /** ANGUILLA */
  Ai = 'AI',
  /** ALBANIA */
  Al = 'AL',
  /** ARMENIA */
  Am = 'AM',
  /** ANTYLE HOLENDERSKIE */
  An = 'AN',
  /** ANGOLA */
  Ao = 'AO',
  /** ANTARKTYDA */
  Aq = 'AQ',
  /** ARGENTYNA */
  Ar = 'AR',
  /** SAMOA AMERYKAŃSKIE */
  As = 'AS',
  /** AUSTRIA */
  At = 'AT',
  /** AUSTRALIA */
  Au = 'AU',
  /** ARUBA */
  Aw = 'AW',
  /** ALAND ISLANDS */
  Ax = 'AX',
  /** AZERBEJDŻAN */
  Az = 'AZ',
  /** BOŚNIA I HERCEGOWINA */
  Ba = 'BA',
  /** BARBADOS */
  Bb = 'BB',
  /** BANGLADESZ */
  Bd = 'BD',
  /** BELGIA */
  Be = 'BE',
  /** BURKINA FASO */
  Bf = 'BF',
  /** BUŁGARIA */
  Bg = 'BG',
  /** BAHRAJN */
  Bh = 'BH',
  /** BURUNDI */
  Bi = 'BI',
  /** BENIN */
  Bj = 'BJ',
  /** SAINT BARTHELEMY */
  Bl = 'BL',
  /** BERMUDY */
  Bm = 'BM',
  /** BRUNEI DARUSSALAM */
  Bn = 'BN',
  /** BOLIWIA */
  Bo = 'BO',
  /** BONAIRE, SINT EUSTATIUS I SABA */
  Bq = 'BQ',
  /** BRAZYLIA */
  Br = 'BR',
  /** BAHAMY */
  Bs = 'BS',
  /** BHUTAN */
  Bt = 'BT',
  /** WYSPA BOUVETA */
  Bv = 'BV',
  /** BOTSWANA */
  Bw = 'BW',
  /** BIAŁORUŚ */
  By = 'BY',
  /** BELIZE */
  Bz = 'BZ',
  /** KANADA */
  Ca = 'CA',
  /** WYSPY KOKOSOWE (KEELINGA) */
  Cc = 'CC',
  /** KONGO, REPUBLIKA DEMOKRATYCZNA */
  Cd = 'CD',
  /** REP.ŚRODKOWOAFRYKAŃSKA */
  Cf = 'CF',
  /** KONGO */
  Cg = 'CG',
  /** SZWAJCARIA */
  Ch = 'CH',
  /** WYBRZEŻE KOŚCI SŁONIOWEJ */
  Ci = 'CI',
  /** WYSPY COOKA */
  Ck = 'CK',
  /** CHILE */
  Cl = 'CL',
  /** KAMERUN */
  Cm = 'CM',
  /** CHINY */
  Cn = 'CN',
  /** KOLUMBIA */
  Co = 'CO',
  /** KOSTARYKA */
  Cr = 'CR',
  /** KUBA */
  Cu = 'CU',
  /** ZIELONY PRZYLĄDEK */
  Cv = 'CV',
  /** CURAÇAO */
  Cw = 'CW',
  /** WYSPA BOŻEGO NARODZENIA */
  Cx = 'CX',
  /** CYPR */
  Cy = 'CY',
  /** REPUBLIKA CZESKA */
  Cz = 'CZ',
  /** NIEMCY */
  De = 'DE',
  /** DŻIBUTI */
  Dj = 'DJ',
  /** DANIA */
  Dk = 'DK',
  /** DOMINIKA */
  Dm = 'DM',
  /** DOMINIKANA */
  Do = 'DO',
  /** ALGIERIA */
  Dz = 'DZ',
  /** EKWADOR */
  Ec = 'EC',
  /** ESTONIA */
  Ee = 'EE',
  /** EGIPT */
  Eg = 'EG',
  /** SAHARA ZACHODNIA */
  Eh = 'EH',
  /** ERYTREA */
  Er = 'ER',
  /** HISZPANIA */
  Es = 'ES',
  /** ETIOPIA */
  Et = 'ET',
  /** FINLANDIA */
  Fi = 'FI',
  /** FIDŻI REPUBLIKA */
  Fj = 'FJ',
  /** FALKLANDY */
  Fk = 'FK',
  /** MIKRONEZJA */
  Fm = 'FM',
  /** WYSPY OWCZE */
  Fo = 'FO',
  /** FRANCJA */
  Fr = 'FR',
  /** GABON */
  Ga = 'GA',
  /** WIELKA BRYTANIA */
  Gb = 'GB',
  /** GRENADA */
  Gd = 'GD',
  /** GRUZJA */
  Ge = 'GE',
  /** GUJANA FRANCUSKA */
  Gf = 'GF',
  /** GUERNSEY */
  Gg = 'GG',
  /** GHANA */
  Gh = 'GH',
  /** GIBRALTAR */
  Gi = 'GI',
  /** GRENLANDIA */
  Gl = 'GL',
  /** GAMBIA */
  Gm = 'GM',
  /** GWINEA */
  Gn = 'GN',
  /** GWADELUPA */
  Gp = 'GP',
  /** GWINEA RÓWNIKOWA */
  Gq = 'GQ',
  /** GRECJA */
  Gr = 'GR',
  /** POŁUDNIOWA GEORGIA I POŁUD.WYSPY SANDWICH */
  Gs = 'GS',
  /** GWATEMALA */
  Gt = 'GT',
  /** GUAM */
  Gu = 'GU',
  /** GWINEA-BISSAU */
  Gw = 'GW',
  /** GUJANA */
  Gy = 'GY',
  /** HONGKONG */
  Hk = 'HK',
  /** WYSPY HEARD I MCDONALD */
  Hm = 'HM',
  /** HONDURAS */
  Hn = 'HN',
  /** CHORWACJA */
  Hr = 'HR',
  /** HAITI */
  Ht = 'HT',
  /** WĘGRY */
  Hu = 'HU',
  /** INDONEZJA */
  Id = 'ID',
  /** IRLANDIA */
  Ie = 'IE',
  /** IZRAEL */
  Il = 'IL',
  /** WYSPA MAN */
  Im = 'IM',
  /** INDIE */
  In = 'IN',
  /** BRYTYJSKIE TERYTORIUM OCEANU INDYJSKIEGO */
  Io = 'IO',
  /** IRAK */
  Iq = 'IQ',
  /** IRAN */
  Ir = 'IR',
  /** ISLANDIA */
  Is = 'IS',
  /** WŁOCHY */
  It = 'IT',
  /** JERSEY */
  Je = 'JE',
  /** JAMAJKA */
  Jm = 'JM',
  /** JORDANIA */
  Jo = 'JO',
  /** JAPONIA */
  Jp = 'JP',
  /** KENIA */
  Ke = 'KE',
  /** KIRGISTAN */
  Kg = 'KG',
  /** KAMBODŻA */
  Kh = 'KH',
  /** KIRIBATI */
  Ki = 'KI',
  /** KOMORY */
  Km = 'KM',
  /** SAINT KITTS I NEVIS */
  Kn = 'KN',
  /** KOREAŃSKA REPUBLIKA LUDOWO-DEMOKRATYCZNA */
  Kp = 'KP',
  /** REPUBLIKA KOREI */
  Kr = 'KR',
  /** KUWEJT */
  Kw = 'KW',
  /** KAJMANY */
  Ky = 'KY',
  /** KAZACHSTAN */
  Kz = 'KZ',
  /** LAOS */
  La = 'LA',
  /** LIBAN */
  Lb = 'LB',
  /** SAINT LUCIA */
  Lc = 'LC',
  /** LIECHTENSTEIN */
  Li = 'LI',
  /** SRI LANKA */
  Lk = 'LK',
  /** LIBERIA */
  Lr = 'LR',
  /** LESOTHO */
  Ls = 'LS',
  /** LITWA */
  Lt = 'LT',
  /** LUKSEMBURG */
  Lu = 'LU',
  /** ŁOTWA */
  Lv = 'LV',
  /** LIBIA */
  Ly = 'LY',
  /** MAROKO */
  Ma = 'MA',
  /** MONAKO */
  Mc = 'MC',
  /** MOŁDOWA */
  Md = 'MD',
  /** CZARNOGÓRA */
  Me = 'ME',
  /** SAINT MARTIN */
  Mf = 'MF',
  /** MADAGASKAR */
  Mg = 'MG',
  /** WYSPY MARSHALLA */
  Mh = 'MH',
  /** MACEDONIA */
  Mk = 'MK',
  /** MALI */
  Ml = 'ML',
  /** MYANMAR (BURMA) */
  Mm = 'MM',
  /** MONGOLIA */
  Mn = 'MN',
  /** MAKAU */
  Mo = 'MO',
  /** MARIANY PÓŁNOCNE */
  Mp = 'MP',
  /** MARTYNIKA */
  Mq = 'MQ',
  /** MAURETANIA */
  Mr = 'MR',
  /** MONTSERRAT */
  Ms = 'MS',
  /** MALTA */
  Mt = 'MT',
  /** MAURITIUS */
  Mu = 'MU',
  /** MALEDIWY */
  Mv = 'MV',
  /** MALAWI */
  Mw = 'MW',
  /** MEKSYK */
  Mx = 'MX',
  /** MALEZJA */
  My = 'MY',
  /** MOZAMBIK */
  Mz = 'MZ',
  /** NAMIBIA */
  Na = 'NA',
  /** NOWA KALEDONIA */
  Nc = 'NC',
  /** NIGER */
  Ne = 'NE',
  /** NORFOLK */
  Nf = 'NF',
  /** NIGERIA */
  Ng = 'NG',
  /** NIKARAGUA */
  Ni = 'NI',
  /** NIDERLANDY (HOLANDIA) */
  Nl = 'NL',
  /** NORWEGIA */
  No = 'NO',
  /** NEPAL */
  Np = 'NP',
  /** NAURU */
  Nr = 'NR',
  /** NIUE */
  Nu = 'NU',
  /** NOWA ZELANDIA */
  Nz = 'NZ',
  /** OMAN */
  Om = 'OM',
  /** PANAMA */
  Pa = 'PA',
  /** PERU */
  Pe = 'PE',
  /** POLINEZJA FRANCUSKA */
  Pf = 'PF',
  /** PAPUA NOWA GWINEA */
  Pg = 'PG',
  /** FILIPINY */
  Ph = 'PH',
  /** PAKISTAN */
  Pk = 'PK',
  /** POLSKA */
  Pl = 'PL',
  /** SAINT PIERRE I MIQUELON */
  Pm = 'PM',
  /** PITCAIRN */
  Pn = 'PN',
  /** PORTORYKO */
  Pr = 'PR',
  /** OKUPOWANE TERYTORIUM PALESTYNY */
  Ps = 'PS',
  /** PORTUGALIA */
  Pt = 'PT',
  /** PALAU */
  Pw = 'PW',
  /** PARAGWAJ */
  Py = 'PY',
  /** KATAR */
  Qa = 'QA',
  /** REUNION */
  Re = 'RE',
  /** RUMUNIA */
  Ro = 'RO',
  /** SERBIA */
  Rs = 'RS',
  /** ROSJA */
  Ru = 'RU',
  /** RWANDA */
  Rw = 'RW',
  /** ARABIA SAUDYJSKA */
  Sa = 'SA',
  /** WYSPY SALOMONA */
  Sb = 'SB',
  /** SESZELE */
  Sc = 'SC',
  /** SUDAN */
  Sd = 'SD',
  /** SZWECJA */
  Se = 'SE',
  /** SINGAPUR */
  Sg = 'SG',
  /** ŚWIĘTA HELENA */
  Sh = 'SH',
  /** SŁOWENIA */
  Si = 'SI',
  /** SVALBARD I JAN MAYEN */
  Sj = 'SJ',
  /** SŁOWACJA */
  Sk = 'SK',
  /** SIERRA LEONE */
  Sl = 'SL',
  /** SAN MARINO */
  Sm = 'SM',
  /** SENEGAL */
  Sn = 'SN',
  /** SOMALIA */
  So = 'SO',
  /** SURINAM */
  Sr = 'SR',
  /** SUDAN POŁUDNIOWY */
  Ss = 'SS',
  /** WYSPY ŚWIĘTEGO TOMASZA I KSIĄŻĘCA */
  St = 'ST',
  /** SALWADOR */
  Sv = 'SV',
  /** WYSPA SINT MAARTEN (CZĘŚĆ HOLENDERSKA WYSPY) */
  Sx = 'SX',
  /** SYRIA */
  Sy = 'SY',
  /** SUAZI */
  Sz = 'SZ',
  /** WYSPY TURKS I CAICOS */
  Tc = 'TC',
  /** CZAD */
  Td = 'TD',
  /** FRANCUSKIE TERYTORIUM POŁUDNIOWE */
  Tf = 'TF',
  /** TOGO */
  Tg = 'TG',
  /** TAJLANDIA */
  Th = 'TH',
  /** TADŻYKISTAN */
  Tj = 'TJ',
  /** TOKELAU */
  Tk = 'TK',
  /** WSCHODNI TIMOR */
  Tl = 'TL',
  /** TURKMENISTAN */
  Tm = 'TM',
  /** TUNEZJA */
  Tn = 'TN',
  /** TONGA */
  To = 'TO',
  /** TURCJA */
  Tr = 'TR',
  /** TRYNIDAD I TOBAGO */
  Tt = 'TT',
  /** TUVALU */
  Tv = 'TV',
  /** TAJWAN */
  Tw = 'TW',
  /** TANZANIA */
  Tz = 'TZ',
  /** UKRAINA */
  Ua = 'UA',
  /** UGANDA */
  Ug = 'UG',
  /** MINOR */
  Um = 'UM',
  /** STANY ZJEDNOCZONE AMERYKI */
  Us = 'US',
  /** URUGWAJ */
  Uy = 'UY',
  /** UZBEKISTAN */
  Uz = 'UZ',
  /** WATYKAN */
  Va = 'VA',
  /** SAINT VINCENT I GRENADYNY */
  Vc = 'VC',
  /** WENEZUELA */
  Ve = 'VE',
  /** WYSPY DZIEWICZE-W.B. */
  Vg = 'VG',
  /** WYSPY DZIEWICZE-USA */
  Vi = 'VI',
  /** WIETNAM */
  Vn = 'VN',
  /** VANUATU */
  Vu = 'VU',
  /** WALLIS I FUTUNA */
  Wf = 'WF',
  /** SAMOA */
  Ws = 'WS',
  /** CEUTA */
  Xc = 'XC',
  /** ZJEDNOCZONE KRÓLESTWO (IRLANDIA PÓŁNOCNA) */
  Xi = 'XI',
  /** KOSOWO */
  Xk = 'XK',
  /** MELILLA */
  Xl = 'XL',
  /** JEMEN */
  Ye = 'YE',
  /** MAJOTTA */
  Yt = 'YT',
  /** REPUBLIKA POŁUDNIOWEJ AFRYKI */
  Za = 'ZA',
  /** ZAMBIA */
  Zm = 'ZM',
  /** ZIMBABWE */
  Zw = 'ZW'
}

/** Słownik kodów krajów */
export enum KodWaluty {
  /** DIRHAM ZEA */
  Aed = 'AED',
  /** AFGANI */
  Afn = 'AFN',
  /** LEK */
  All = 'ALL',
  /** DRAM */
  Amd = 'AMD',
  /** GULDEN ANTYLI HOLENDERSKICH */
  Ang = 'ANG',
  /** KWANZA */
  Aoa = 'AOA',
  /** PESO ARGENTYŃSKIE */
  Ars = 'ARS',
  /** DOLAR AUSTRALIJSKI */
  Aud = 'AUD',
  /** GULDEN ARUBAŃSKI */
  Awg = 'AWG',
  /** MANAT AZERBEJDŻAŃSKI */
  Azn = 'AZN',
  /** MARKA ZAMIENNA */
  Bam = 'BAM',
  /** DOLAR BARBADOSKI */
  Bbd = 'BBD',
  /** TAKA */
  Bdt = 'BDT',
  /** LEW */
  Bgn = 'BGN',
  /** DINAR BAHRAJSKI */
  Bhd = 'BHD',
  /** FRANK BURUNDYJSKI */
  Bif = 'BIF',
  /** DOLAR BERMUDZKI */
  Bmd = 'BMD',
  /** DOLAR BRUNEJSKI */
  Bnd = 'BND',
  /** BOLIWIANO */
  Bob = 'BOB',
  /** BOLIWIANO MVDOL */
  Bov = 'BOV',
  /** REAL */
  Brl = 'BRL',
  /** DOLAR BAHAMSKI */
  Bsd = 'BSD',
  /** NGULTRUM */
  Btn = 'BTN',
  /** PULA */
  Bwp = 'BWP',
  /** RUBEL BIAŁORUSKI */
  Byn = 'BYN',
  /** DOLAR BELIZEŃSKI */
  Bzd = 'BZD',
  /** DOLAR KANADYJSKI */
  Cad = 'CAD',
  /** FRANK KONGIJSKI */
  Cdf = 'CDF',
  /** FRANK SZWAJCARSKI VIR EURO */
  Che = 'CHE',
  /** FRANK SZWAJCARSKI */
  Chf = 'CHF',
  /** FRANK SZWAJCARSKI VIR FRANK */
  Chw = 'CHW',
  /** JEDNOSTKA ROZLICZENIOWA CHILIJSKA */
  Clf = 'CLF',
  /** PESO CHILIJSKIE */
  Clp = 'CLP',
  /** YUAN RENMINBI */
  Cny = 'CNY',
  /** PESO KOLUMBIJSKIE */
  Cop = 'COP',
  /** UNIDAD DE VALOR REAL KOLUMBILSKIE */
  Cou = 'COU',
  /** COLON KOSTARYKAŃSKI */
  Crc = 'CRC',
  /** PESO WYMIENIALNE */
  Cuc = 'CUC',
  /** PESO KUBAŃSKIE */
  Cup = 'CUP',
  /** ESCUDO REPUBLIKI ZIELONEGO PRZYLĄDKA */
  Cve = 'CVE',
  /** KORONA CZESKA */
  Czk = 'CZK',
  /** FRANK DŻIBUTI */
  Djf = 'DJF',
  /** KORONA DUŃSKA */
  Dkk = 'DKK',
  /** PESO DOMINIKAŃSKIE */
  Dop = 'DOP',
  /** DINAR ALGIERSKI */
  Dzd = 'DZD',
  /** FUNT EGIPSKI */
  Egp = 'EGP',
  /** NAKFA */
  Ern = 'ERN',
  /** BIRR */
  Etb = 'ETB',
  /** EURO */
  Eur = 'EUR',
  /** DOLAR FIDŻI */
  Fjd = 'FJD',
  /** FUNT FALKLANDZKI */
  Fkp = 'FKP',
  /** FUNT SZTERLING */
  Gbp = 'GBP',
  /** LARI */
  Gel = 'GEL',
  /** FUNT GUERNSEY */
  Ggp = 'GGP',
  /** GHANA CEDI */
  Ghs = 'GHS',
  /** FUNT GIBRALTARSKI */
  Gip = 'GIP',
  /** DALASI */
  Gmd = 'GMD',
  /** FRANK GWINEJSKI */
  Gnf = 'GNF',
  /** QUETZAL */
  Gtq = 'GTQ',
  /** DOLAR GUJAŃSKI */
  Gyd = 'GYD',
  /** DOLAR HONGKONGU */
  Hkd = 'HKD',
  /** LEMPIRA */
  Hnl = 'HNL',
  /** KUNA */
  Hrk = 'HRK',
  /** GOURDE */
  Htg = 'HTG',
  /** FORINT */
  Huf = 'HUF',
  /** RUPIA INDONEZYJSKA */
  Idr = 'IDR',
  /** SZEKEL */
  Ils = 'ILS',
  /** FUNT MANX */
  Imp = 'IMP',
  /** RUPIA INDYJSKA */
  Inr = 'INR',
  /** DINAR IRACKI */
  Iqd = 'IQD',
  /** RIAL IRAŃSKI */
  Irr = 'IRR',
  /** KORONA ISLANDZKA */
  Isk = 'ISK',
  /** FUNT JERSEY */
  Jep = 'JEP',
  /** DOLAR JAMAJSKI */
  Jmd = 'JMD',
  /** DINAR JORDAŃSKI */
  Jod = 'JOD',
  /** JEN */
  Jpy = 'JPY',
  /** SZYLING KENIJSKI */
  Kes = 'KES',
  /** SOM */
  Kgs = 'KGS',
  /** RIEL */
  Khr = 'KHR',
  /** FRANK KOMORÓW */
  Kmf = 'KMF',
  /** WON PÓŁNOCNO­KOREAŃSKI */
  Kpw = 'KPW',
  /** WON POŁUDNIOWO­KOREAŃSKI */
  Krw = 'KRW',
  /** DINAR KUWEJCKI */
  Kwd = 'KWD',
  /** DOLAR KAJMAŃSKI */
  Kyd = 'KYD',
  /** TENGE */
  Kzt = 'KZT',
  /** KIP */
  Lak = 'LAK',
  /** FUNT LIBAŃSKI */
  Lbp = 'LBP',
  /** RUPIA LANKIJSKA */
  Lkr = 'LKR',
  /** DOLAR LIBERYJSKI */
  Lrd = 'LRD',
  /** LOTI */
  Lsl = 'LSL',
  /** DINAR LIBIJSKI */
  Lyd = 'LYD',
  /** DIRHAM MAROKAŃSKI */
  Mad = 'MAD',
  /** LEJ MOŁDAWII */
  Mdl = 'MDL',
  /** ARIARY */
  Mga = 'MGA',
  /** DENAR */
  Mkd = 'MKD',
  /** KYAT */
  Mmk = 'MMK',
  /** TUGRIK */
  Mnt = 'MNT',
  /** PATACA */
  Mop = 'MOP',
  /** OUGUIYA */
  Mru = 'MRU',
  /** RUPIA MAURITIUSU */
  Mur = 'MUR',
  /** RUPIA MALEDIWSKA */
  Mvr = 'MVR',
  /** KWACHA MALAWIJSKA */
  Mwk = 'MWK',
  /** PESO MEKSYKAŃSKIE */
  Mxn = 'MXN',
  /** UNIDAD DE INVERSION (UDI) MEKSYKAŃSKIE */
  Mxv = 'MXV',
  /** RINGGIT */
  Myr = 'MYR',
  /** METICAL */
  Mzn = 'MZN',
  /** DOLAR NAMIBIJSKI */
  Nad = 'NAD',
  /** NAIRA */
  Ngn = 'NGN',
  /** CORDOBA ORO */
  Nio = 'NIO',
  /** KORONA NORWESKA */
  Nok = 'NOK',
  /** RUPIA NEPALSKA */
  Npr = 'NPR',
  /** DOLAR NOWOZELANDZKI */
  Nzd = 'NZD',
  /** RIAL OMAŃSKI */
  Omr = 'OMR',
  /** BALBOA */
  Pab = 'PAB',
  /** SOL */
  Pen = 'PEN',
  /** KINA */
  Pgk = 'PGK',
  /** PESO FILIPIŃSKIE */
  Php = 'PHP',
  /** RUPIA PAKISTAŃSKA */
  Pkr = 'PKR',
  /** ZŁOTY */
  Pln = 'PLN',
  /** GUARANI */
  Pyg = 'PYG',
  /** RIAL KATARSKI */
  Qar = 'QAR',
  /** LEJ RUMUŃSKI */
  Ron = 'RON',
  /** DINAR SERBSKI */
  Rsd = 'RSD',
  /** RUBEL ROSYJSKI */
  Rub = 'RUB',
  /** FRANK RWANDYJSKI */
  Rwf = 'RWF',
  /** RIAL SAUDYJSKI */
  Sar = 'SAR',
  /** DOLAR WYSP SALOMONA */
  Sbd = 'SBD',
  /** RUPIA SESZELSKA */
  Scr = 'SCR',
  /** FUNT SUDAŃSKI */
  Sdg = 'SDG',
  /** KORONA SZWEDZKA */
  Sek = 'SEK',
  /** DOLAR SINGAPURSKI */
  Sgd = 'SGD',
  /** FUNT ŚWIĘTEJ HELENY (ŚWIĘTA HELENA I WYSPA WNIEBOWSTĄPIENIA) */
  Shp = 'SHP',
  /** LEONE */
  Sll = 'SLL',
  /** SZYLING SOMALIJSKI */
  Sos = 'SOS',
  /** DOLAR SURINAMSKI */
  Srd = 'SRD',
  /** FUNT POŁUDNIOWOSUDAŃSKI */
  Ssp = 'SSP',
  /** DOBRA */
  Stn = 'STN',
  /** COLON SALWADORSKI (SV1) */
  Svc = 'SVC',
  /** FUNT SYRYJSKI */
  Syp = 'SYP',
  /** LILANGENI */
  Szl = 'SZL',
  /** BAT */
  Thb = 'THB',
  /** SOMONI */
  Tjs = 'TJS',
  /** MANAT TURKMEŃSKI */
  Tmt = 'TMT',
  /** DINAR TUNEZYJSKI */
  Tnd = 'TND',
  /** PAANGA */
  Top = 'TOP',
  /** LIRA TURECKA */
  Try = 'TRY',
  /** DOLAR TRYNIDADU I TOBAGO */
  Ttd = 'TTD',
  /** NOWY DOLAR TAJWAŃSKI */
  Twd = 'TWD',
  /** SZYLING TANZAŃSKI */
  Tzs = 'TZS',
  /** HRYWNA */
  Uah = 'UAH',
  /** SZYLING UGANDYJSKI */
  Ugx = 'UGX',
  /** DOLAR AMERYKAŃSKI */
  Usd = 'USD',
  /** DOLAR AMERYKAŃSKI (NEXT DAY) */
  Usn = 'USN',
  /** PESO EN UNIDADES INDEXADAS URUGWAJSKIE */
  Uyi = 'UYI',
  /** PESO URUGWAJSKIE */
  Uyu = 'UYU',
  /** PESO EN UNIDADES INDEXADAS URUGWAJSKIE */
  Uyw = 'UYW',
  /** SUM */
  Uzs = 'UZS',
  /** BOLIWAR SOBERANO */
  Ves = 'VES',
  /** DONG */
  Vnd = 'VND',
  /** VATU */
  Vuv = 'VUV',
  /** TALA */
  Wst = 'WST',
  /** FRANK CFA (BEAC) */
  Xaf = 'XAF',
  /** SREBRO */
  Xag = 'XAG',
  /** ZŁOTO */
  Xau = 'XAU',
  /** BOND MARKETS UNIT EUROPEAN COMPOSITE UNIT (EURCO) */
  Xba = 'XBA',
  /** BOND MARKETS UNIT EUROPEAN MONETARY UNIT (E.M.U.-6) */
  Xbb = 'XBB',
  /** BOND MARKETS UNIT EUROPEAN UNIT OF ACCOUNT 9 (E.U.A.-9) */
  Xbc = 'XBC',
  /** BOND MARKETS UNIT EUROPEAN UNIT OF ACCOUNT 17 (E.U.A.-17) */
  Xbd = 'XBD',
  /** DOLAR WSCHODNIO­KARAIBSKI */
  Xcd = 'XCD',
  /** SDR MIĘDZYNARODOWY FUNDUSZ WALUTOWY */
  Xdr = 'XDR',
  /** FRANK CFA (BCEAO) */
  Xof = 'XOF',
  /** PALLAD */
  Xpd = 'XPD',
  /** FRANK CFP */
  Xpf = 'XPF',
  /** PLATYNA */
  Xpt = 'XPT',
  /** SUCRE SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS SUCRE */
  Xsu = 'XSU',
  /** ADB UNIT OF ACCOUNT MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP */
  Xua = 'XUA',
  /** BRAK WALUTY */
  Xxx = 'XXX',
  /** RIAL JEMEŃSKI */
  Yer = 'YER',
  /** RAND */
  Zar = 'ZAR',
  /** KWACHA ZAMBIJSKA */
  Zmw = 'ZMW',
  /** DOLAR ZIMBABWE */
  Zwl = 'ZWL'
}

/** Korekta do faktury, obowiązkowa dla typów faktury KOR, KOR_ZAL lub KOR_ROZ */
export type Korekta = {
  /** Lista faktur korygowanych */
  FaKorygowana: Array<DaneFaKorygowanej>;
  /** Nabywca */
  Nabywca?: Maybe<Podmiot>;
  /**
   * Poprawny numer faktury korygowanej w przypadku, gdy przyczyną korekty jest błędny numer faktury korygowanej.
   * W polu NrFaKorygowanej należy wówczas wskazać ten błędny numer
   */
  NrFaKorygowany?: Maybe<Scalars['String']>;
  /**
   * Dla faktury korygującej, o której mowa w art. 106j ust. 3 ustawy - okres, do którego odnosi się udzielany opust lub obniżka,
   * w przypadku gdy podatnik udziela opustu lub obniżki ceny w odniesieniu do dostaw towarów lub usług dokonanych lub świadczonych na rzecz jednego odbiorcy w danym okresie
   */
  OkresFaKorygowanej?: Maybe<Scalars['String']>;
  /** Przyczyna korekty dla faktur korygujących */
  PrzyczynaKorekty?: Maybe<Scalars['String']>;
  /** Sprzedawca */
  Sprzedawca?: Maybe<Podmiot>;
  /** Typ skutku korekty w ewidencji */
  TypKorekty?: Maybe<TypKorekty>;
};

/** Korekta do faktury, obowiązkowa dla typów faktury KOR, KOR_ZAL lub KOR_ROZ */
export type KorektaInput = {
  /** Lista faktur korygowanych */
  FaKorygowana: Array<DaneFaKorygowanejInput>;
  /** Nabywca */
  Nabywca?: InputMaybe<PodmiotInput>;
  /**
   * Poprawny numer faktury korygowanej w przypadku, gdy przyczyną korekty jest błędny numer faktury korygowanej.
   * W polu NrFaKorygowanej należy wówczas wskazać ten błędny numer
   */
  NrFaKorygowany?: InputMaybe<Scalars['String']>;
  /**
   * Dla faktury korygującej, o której mowa w art. 106j ust. 3 ustawy - okres, do którego odnosi się udzielany opust lub obniżka,
   * w przypadku gdy podatnik udziela opustu lub obniżki ceny w odniesieniu do dostaw towarów lub usług dokonanych lub świadczonych na rzecz jednego odbiorcy w danym okresie
   */
  OkresFaKorygowanej?: InputMaybe<Scalars['String']>;
  /** Przyczyna korekty dla faktur korygujących */
  PrzyczynaKorekty?: InputMaybe<Scalars['String']>;
  /** Sprzedawca */
  Sprzedawca?: InputMaybe<PodmiotInput>;
  /** Typ skutku korekty w ewidencji */
  TypKorekty?: InputMaybe<TypKorekty>;
};

/** Obiekt Korekty */
export type KorektaV2 = {
  /** Lista faktur korygowanych */
  FaKorygowana: Array<DaneFaKorygowanej>;
  /**
   * Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w Dziale VI ustawy przed korektą.
   * Typ wykorzystywany do określenia ilości. Wartość numeryczna 22 znaki max, w tym 6 po przecinku.
   * Total number of digits: 22.
   * Total number of digits in fraction: 6.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,6})?.
   */
  KursWalutyKorektyFakturyZaliczkowej?: Maybe<Scalars['Decimal']>;
  /**
   * W przypadku korekt faktur zaliczkowych, kwota zapłaty przed korektą. W przypadku korekt faktur, o których mowa w art. 106f ust. 3 ustawy, kwota pozostała do zapłaty przed korektą.
   * Wartość numeryczna 18 znaków max, w tym 2 znaki po przecinku.
   * Total number of digits: 18.
   * Total number of digits in fraction: 2.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,2})?.
   */
  KwotaPrzedKorektaFakturyZaliczkowej?: Maybe<Scalars['Decimal']>;
  /** Nabywca */
  Nabywca?: Maybe<PodmiotV2>;
  /**
   * Poprawny numer faktury korygowanej w przypadku, gdy przyczyną korekty jest błędny numer faktury korygowanej.
   * W polu NrFaKorygowanej należy wówczas wskazać ten błędny numer
   */
  NrFaKorygowany?: Maybe<Scalars['String']>;
  /**
   * Dla faktury korygującej, o której mowa w art. 106j ust. 3 ustawy - okres, do którego odnosi się udzielany opust lub obniżka,
   * w przypadku gdy podatnik udziela opustu lub obniżki ceny w odniesieniu do dostaw towarów lub usług dokonanych lub świadczonych na rzecz jednego odbiorcy w danym okresie
   */
  OkresFaKorygowanej?: Maybe<Scalars['String']>;
  /** Przyczyna korekty dla faktur korygujących */
  PrzyczynaKorekty?: Maybe<Scalars['String']>;
  /** Sprzedawca */
  Sprzedawca?: Maybe<PodmiotV2>;
  /** Typ skutku korekty w ewidencji */
  TypKorekty?: Maybe<TypKorekty>;
};

/** Obiekt Korekty */
export type KorektaV2Input = {
  /** Lista faktur korygowanych */
  FaKorygowana: Array<DaneFaKorygowanejInput>;
  /**
   * Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w Dziale VI ustawy przed korektą.
   * Typ wykorzystywany do określenia ilości. Wartość numeryczna 22 znaki max, w tym 6 po przecinku.
   * Total number of digits: 22.
   * Total number of digits in fraction: 6.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,6})?.
   */
  KursWalutyKorektyFakturyZaliczkowej?: InputMaybe<Scalars['Decimal']>;
  /**
   * W przypadku korekt faktur zaliczkowych, kwota zapłaty przed korektą. W przypadku korekt faktur, o których mowa w art. 106f ust. 3 ustawy, kwota pozostała do zapłaty przed korektą.
   * Wartość numeryczna 18 znaków max, w tym 2 znaki po przecinku.
   * Total number of digits: 18.
   * Total number of digits in fraction: 2.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,2})?.
   */
  KwotaPrzedKorektaFakturyZaliczkowej?: InputMaybe<Scalars['Decimal']>;
  /** Nabywca */
  Nabywca?: InputMaybe<PodmiotV2Input>;
  /**
   * Poprawny numer faktury korygowanej w przypadku, gdy przyczyną korekty jest błędny numer faktury korygowanej.
   * W polu NrFaKorygowanej należy wówczas wskazać ten błędny numer
   */
  NrFaKorygowany?: InputMaybe<Scalars['String']>;
  /**
   * Dla faktury korygującej, o której mowa w art. 106j ust. 3 ustawy - okres, do którego odnosi się udzielany opust lub obniżka,
   * w przypadku gdy podatnik udziela opustu lub obniżki ceny w odniesieniu do dostaw towarów lub usług dokonanych lub świadczonych na rzecz jednego odbiorcy w danym okresie
   */
  OkresFaKorygowanej?: InputMaybe<Scalars['String']>;
  /** Przyczyna korekty dla faktur korygujących */
  PrzyczynaKorekty?: InputMaybe<Scalars['String']>;
  /** Sprzedawca */
  Sprzedawca?: InputMaybe<PodmiotV2Input>;
  /** Typ skutku korekty w ewidencji */
  TypKorekty?: InputMaybe<TypKorekty>;
};

/** Contains Ksef invoice versions. */
export enum KsefXsdVersion {
  /** Wartość serwisowa */
  Undefined = 'UNDEFINED',
  /** Wersja FA(1) */
  V11089 = 'V11089',
  /** Wersja FA(2) */
  V12648 = 'V12648'
}

export type KsefXsdVersionOperationFilterInput = {
  eq?: InputMaybe<KsefXsdVersion>;
  in?: InputMaybe<Array<KsefXsdVersion>>;
  neq?: InputMaybe<KsefXsdVersion>;
  nin?: InputMaybe<Array<KsefXsdVersion>>;
};

/** Rodzaje ładunku */
export enum Ladunek {
  /** Bańka */
  Banka = 'BANKA',
  /** Beczka */
  Beczka = 'BECZKA',
  /** Butla */
  Butla = 'BUTLA',
  /** Kanister */
  Kanister = 'KANISTER',
  /** Karton */
  Karton = 'KARTON',
  /** Klatka */
  Klatka = 'KLATKA',
  /** Kontener */
  Kontener = 'KONTENER',
  /** Kosz/koszyk */
  Kosz = 'KOSZ',
  /** Łubianka */
  Lubianka = 'LUBIANKA',
  /** Opakowanie zbiorcze */
  OpakowanieZbiorcze = 'OPAKOWANIE_ZBIORCZE',
  /** Paczka */
  Paczka = 'PACZKA',
  /** Pakiet */
  Pakiet = 'PAKIET',
  /** Paleta */
  Paleta = 'PALETA',
  /** Pojemnik */
  Pojemnik = 'POJEMNIK',
  /** Pojemnik do ładunków masowych w postaci płynnej */
  PojemnikDoLadunkowPlynnych = 'POJEMNIK_DO_LADUNKOW_PLYNNYCH',
  /** Pojemnik do ładunków masowych stałych */
  PojemnikDoLadunkowStalych = 'POJEMNIK_DO_LADUNKOW_STALYCH',
  /** Pudełko */
  Pudelko = 'PUDELKO',
  /** Puszka */
  Puszka = 'PUSZKA',
  /** Skrzynia */
  Skrzynia = 'SKRZYNIA',
  /** Worek */
  Worek = 'WOREK'
}

export type LicensePlateExistsResponse = {
  LicensePlateExists: Scalars['Boolean'];
};

export type ListFilterInputTypeOfAccountViewModelFilterInput = {
  all?: InputMaybe<AccountViewModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AccountViewModelFilterInput>;
  some?: InputMaybe<AccountViewModelFilterInput>;
};

export type ListFilterInputTypeOfAppStreamApplicationFilterInput = {
  all?: InputMaybe<AppStreamApplicationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppStreamApplicationFilterInput>;
  some?: InputMaybe<AppStreamApplicationFilterInput>;
};

export type ListFilterInputTypeOfAppStreamDbBackupFilterInput = {
  all?: InputMaybe<AppStreamDbBackupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppStreamDbBackupFilterInput>;
  some?: InputMaybe<AppStreamDbBackupFilterInput>;
};

export type ListFilterInputTypeOfAppStreamDbRestoreFilterInput = {
  all?: InputMaybe<AppStreamDbRestoreFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AppStreamDbRestoreFilterInput>;
  some?: InputMaybe<AppStreamDbRestoreFilterInput>;
};

export type ListFilterInputTypeOfCategoryRuleViewModelFilterInput = {
  all?: InputMaybe<CategoryRuleViewModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CategoryRuleViewModelFilterInput>;
  some?: InputMaybe<CategoryRuleViewModelFilterInput>;
};

export type ListFilterInputTypeOfCategoryViewModelFilterInput = {
  all?: InputMaybe<CategoryViewModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CategoryViewModelFilterInput>;
  some?: InputMaybe<CategoryViewModelFilterInput>;
};

export type ListFilterInputTypeOfCloudServiceAvailabilityFilterInput = {
  all?: InputMaybe<CloudServiceAvailabilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CloudServiceAvailabilityFilterInput>;
  some?: InputMaybe<CloudServiceAvailabilityFilterInput>;
};

export type ListFilterInputTypeOfCompanyFilterInput = {
  all?: InputMaybe<CompanyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CompanyFilterInput>;
  some?: InputMaybe<CompanyFilterInput>;
};

export type ListFilterInputTypeOfRoleAssignmentFilterInput = {
  all?: InputMaybe<RoleAssignmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<RoleAssignmentFilterInput>;
  some?: InputMaybe<RoleAssignmentFilterInput>;
};

export type ListFilterInputTypeOfSettlementsViewModelFilterInput = {
  all?: InputMaybe<SettlementsViewModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SettlementsViewModelFilterInput>;
  some?: InputMaybe<SettlementsViewModelFilterInput>;
};

export type ListFilterInputTypeOfTenantFilterInput = {
  all?: InputMaybe<TenantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TenantFilterInput>;
  some?: InputMaybe<TenantFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
};

/** Wynik operacji pobrania listy faktur */
export type ListInvoicesResponseType = {
  /** Lista faktur */
  Items?: Maybe<Array<Invoice>>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']>;
  gt?: InputMaybe<Scalars['Long']>;
  gte?: InputMaybe<Scalars['Long']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  lt?: InputMaybe<Scalars['Long']>;
  lte?: InputMaybe<Scalars['Long']>;
  neq?: InputMaybe<Scalars['Long']>;
  ngt?: InputMaybe<Scalars['Long']>;
  ngte?: InputMaybe<Scalars['Long']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  nlt?: InputMaybe<Scalars['Long']>;
  nlte?: InputMaybe<Scalars['Long']>;
};

/** Informacja o brakującej fakturze */
export type MissedInvoice = {
  /** Id faktury */
  InvoiceId: Scalars['UUID'];
  /** Numer faktury */
  InvoiceNumber?: Maybe<Scalars['String']>;
  /** Powód dla którego UPO nie zostało pobrane */
  Reason: UpoMissingInvoiceReason;
};

/** Rekord brakującej faktury */
export type MissedInvoices = {
  /** Id faktury */
  invoiceId: Scalars['UUID'];
  /** Numer faktury */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Powód braku */
  reason: UpoMissingInvoiceReason;
};

export enum ModuleSubscriptionStatusEnum {
  NotSubscribed = 'NOT_SUBSCRIBED',
  Subscribed = 'SUBSCRIBED',
  Undefined = 'UNDEFINED'
}

export type MultipleSendToKSeFResponseType = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

/** Required fields:  tenantId, firstname, lastname */
export type Mutation = {
  AcceptDecree: AcceptDecreeMutationResponse;
  AcceptTenant: AcceptTenantResponse;
  ActivateAutoPostingModule: ActivateAutoPostingModuleResponse;
  ActivateTenant: ActivateTenantResponse;
  /**
   * Mutacja do dodawania załączników do faktury korzystająca z serwisu FileHub.
   * Korzysta z interfejsu IFile, który pozwala na strumieniowanie plików.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  AddAttachment: AddAttachmentResponse;
  AddCloudServiceAvailability: CloudServiceAvailability;
  ArchiveCategory: ArchiveCategoryMutationResponse;
  /**
   * Uwierzytelnienie bieżącego użytkownika w KSeF Ministerstwa Finansów za pomocą podpisu w serwisie KSeF Ministerstwa Finansów.
   * Akceptuje podpisane żądanie uzyskane z metody GenerateInitRequestToLogin.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  AuthorizeInKSeF: StandardWebsocketResponse;
  /**
   * Uwierzytelnienie bieżącego użytkownika w KSeF Ministerstwa Finansów za pomocą tokena wygenerowanego w serwisie KSeF Ministerstwa Finansów.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  AuthorizeInKSeFWithToken: AuthorizeInKSeFWithTokenResponse;
  /**
   * Serwisowa mutacja. Metoda techniczna wykorzystywana do testów. Nie podlega udostępnianiu.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  AuthorizeTestCompanyInKSeF: Result;
  CancelBackup: CancelBackupResponse;
  /**
   * Anuluje trwającą operację powiązaną ze wskazaną notyfikacją websocket.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  CancelWebsocketNotification: CancelWebsocketNotification;
  ChangeCategoryName: ChangeCategoryNameMutationResponse;
  CreateCategory: CreateCategoryMutationResponse;
  CreateCompany?: Maybe<Company>;
  /** Utworzenie nowego klucza dla company */
  CreateCompanyApiKey: CreateCompanyApiKeyResponse;
  CreateCompanyVehicle: CreateCompanyVehicleMutationResponse;
  CreateOrganization: CreateOrganizationResponse;
  CreateRole?: Maybe<Role>;
  /** Utworzenie nowej organizacji */
  CreateTenant: TenantResponse;
  /** Utworzenie nowego klucza dla tenanta */
  CreateTenantApiKey: CreateCompanyApiKeyResponse;
  /** Utworzenie nowego usera */
  CreateUser: User;
  DeactivateTenant: DeactivateTenantResponse;
  DeleteApiKey: DeleteApiKeyResponse;
  /**
   * Mutacja do usuwania załączników do faktury korzystająca z serwisu FileHub.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  DeleteAttachment: DeleteAttachmentResponse;
  /** Usunięcie wpisu z tabeli CloudServiceAvailability (dostępność modułu) */
  DeleteCloudServiceAvailability: DeleteCloudServiceAvailabilityResponse;
  DeleteCompany: Scalars['Boolean'];
  /**
   * Metoda służąca do usuwania faktur z Symfonia KSeF.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  DeleteInvoices: DeleteInvoicesResponse;
  DeleteRole: Scalars['Boolean'];
  DeleteRoleAssignment: Scalars['Boolean'];
  /** Usuwanie organizacji */
  DeleteTenant: DeleteTenantResponse;
  /** Usunięcie użytkownika (odłączenie od organizacji) */
  DeleteUser: Scalars['Boolean'];
  /**
   * Zlecenie pobrania faktur z KSeF Ministerstwa Finansów (automatyczny harmonogram pobrań).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  DownloadInvoicesFromKSeF: DownloadResult;
  /**
   * Metoda służąca do wygenerowania żądania InitSessionRequest.
   *
   * EnvironmentId: [`NONE`]({{Types.EnvironmentType.NONE}})
   */
  GenerateInitRequestToLogin: Scalars['String'];
  /**
   * Mutacja do generowania skompresowanych załączników do faktury korzystająca z serwisu FileHub.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GenerateZippedInvoiceAttachments: GenerateZippedInvoiceAttachmentsResponse;
  /**
   * Inicjuje serwis automatycznego pobierania faktur z KSeF Ministerstwa Finansów w kontekście firmy.
   * Serwis pracuje w nocy, jest uruchamiany raz dziennie.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  KSeFAutoFetchingInvoices: KSeFAutoFetchingInvoicesResponseType;
  /**
   * Inicjuje serwis automatycznego wysyłania faktur do KSeF Ministerstwa Finansów w kontekście firmy.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  KSeFAutoSendingInvoices: KSeFAutoSendingInvoicesResponseType;
  /**
   * Inicjuje serwis automatycznego sprawdzania numerów rachunków bankowych kontrahentów na białej liście.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  KSeFInvoiceWhiteListValidation: KSeFInvoiceWhiteListValidationResponseType;
  /**
   * Inicjuje serwis pobierania faktur, innym kontom podłączonym do danej firmy KSeF Ministerstwa Finansów w kontekście firmy.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  KSeFInvoicesDownloadSharedAccess: KSeFInvoicesDownloadSharedAccessResponseType;
  /**
   * Zlecenie wysłania istniejących w Symfonia KSeF faktur do KSeF Ministerstwa Finansów.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  MultipleSendToKSeF: MultipleSendToKSeFResponseType;
  /**
   * Wysłanie wybranych faktur do Symfonia Finanse i Księgowość za pomocą szyny danych.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  PublishInvoices: PublishInvoicesResponse;
  /**
   * Zlecenie pobrania aktualnego statusu faktury z KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  RefreshInvoiceStatusByKSeFSession: RefreshedInvoicesListType;
  RejectTenant: RejectTenantResponse;
  /**
   * Usuwa uwierzytelnienie w KSeF Ministerstwa Finansów uprzednio dokonane za pomocą sesji interaktywnej (Szafir).
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  RemoveAuthorizationInKSeF: StandardWebsocketResponse;
  /**
   * Usuwa uwierzytelnienie w KSeF Ministerstwa Finansów uprzednio dokonane za pomocą zewnętrznego tokena (AuthorizeInKSeFWithToken).
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  RemoveAuthorizationWithExternalTokenInKSeF: RemoveAuthorizationExternalResult;
  RemoveCompanyVehicle: RemoveCompanyVehicleMutationResponse;
  /**
   * Wyłączanie notyfikacji e-mail dla użytkownika w firmie.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  RemoveEArchiveNotifications: RemoveEArchiveNotificationsResponse;
  RemovePlatformNotifications: RemovePlatformNotificationsResponse;
  RequestTrial: RequestTrialResponse;
  ResetModuleInitialization: ResetModuleInitializationResponse;
  RestoreBackup: RestoreBackupResponse;
  SaveAutoPostingSettings: SaveAutoPostingSettingsMutationResponse;
  SaveCategoriesAccounts: SaveCategoriesAccountsResponse;
  SaveChangesOnDecree: SaveChangesOnDecreeResponse;
  SaveDocumentTypesSettings: SaveDocumentTypeSettingsMutationResponse;
  SaveSettlements: SaveSettlementsResponse;
  /** Mutacja do wysyłania załączników do faktury w wiadomości Email. */
  SendInvoiceAttachmentsEmail: SendInvoiceAttachmentsEmailResponse;
  /**
   * Zlecenie dekretacji wybranych faktur.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SendInvoicesForPosting: Response;
  /**
   * Wysłanie faktury do systemu Symfonia KSeF oraz jeżeli parametr WyslijDoKsef ma wartość true, do KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   * @deprecated Dotyczy nieobowiązującej wersji schemy FA(1). Należy używać mutacji SendToEArchiveV2
   */
  SendToEArchive: SendToEArchiveResponseType;
  /**
   * Wysłanie listy faktur w wersji V2 do systemu Symfonia KSeF oraz jeżeli parametr WyslijDoKsef ma wartość true, do KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SendToEArchiveBatchV2: SendToEArchiveBatchV2ResponseType;
  /**
   * Wysłanie faktury w wersji V2 do systemu Symfonia KSeF oraz jeżeli parametr WyslijDoKsef ma wartość true, do KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SendToEArchiveV2: SendToEArchiveV2ResponseType;
  /**
   * Zlecenie wysłania istniejącej w Symfonia KSeF faktury do KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SendToKSeF: SendToKSeFResponseType;
  /**
   * Włączanie notyfikacji e-mail dla użytkownika w firmie.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SetEArchiveNotifications: SetEArchiveNotificationsResponse;
  /**
   * Serwisowa mutacja. Ustawia flagę systemu Symfonia, który pobrał fakturę.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SetInvoiceDownloaded: SetKSeFInvoiceDownloadedResponseType;
  SetPlatformNotifications: SetPlatformNotificationsResponse;
  /** Przypisanie roli dla użytkownika */
  SetRoleAssignment: RoleAssignment;
  /**
   * Serwisowa mutacja. Ustawia subskrybcję i pakiety zgodnie z żadaniem.
   *
   * EnvironmentId: [`ADMIN`]({{Types.EnvironmentType.ADMIN}})
   */
  SetSubscription: SetSubscriptionResponseType;
  /**
   * Serwisowa mutacja. Metoda powiazująca numer seryjny subskrybcji z organizacją.
   *
   * EnvironmentId: [`TENANT`]({{Types.EnvironmentType.TENANT}}) / [`ADMIN`]({{Types.EnvironmentType.ADMIN}})
   */
  SetSubscriptionTenant: SetSubscriptionTenantResponseType;
  /** Ustawia typ dostawcy dla kontrahenta */
  SetSupplierType: SetSupplierTypeHandlerResponse;
  /**
   * Oznacza podane notyfikacje WebSocket-owe jako przeczytane.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SetWebsocketNotificationAcknowledge: SetWebsocketNotificationAcknowledgeType;
  /**
   * Nadaje uprawnienia w KSeF Ministerstwa Finansów dla podanego identyfikatora.
   * Aby nadać uprawnienia w KSeF Ministerstwa Finansów, użytkownik musi być uwierzytelniony w KSeF Ministerstwa Finansów oraz posiadać uprawnienia do zarządzania uprawnieniami.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SubscribeGrantPermissionsInKSeF: SubscribeGrantPermissionsInKSeFResponse;
  /**
   * Zlecenie walidacji rachunków bankowych kontrahentów na białej liście
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SubscribeWhiteListCheck: SubscribeWhiteListCheckResponse;
  /**
   * Zlecenie synchronizacji uprawnień do KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SyncPermissionKSeF: SyncPermissionKSeFResponseType;
  /**
   * Zamyka bieżącą sesję w KSeF Ministerstwa Finansów.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  TerminateKSeFSession: TerminateKSeFSessionResponse;
  /**
   * Serwisowa mutacja. do testowania notyfikacji WebSocket-owych.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  TestCustomWebsocketNotification: TestCustomWebsocketNotificationResponse;
  TriggerAppStreamTrialUsed: TriggerAppStreamTrialUsedResponse;
  TriggerBackup: TriggerBackupResponse;
  /** Aktualizacja danych klucza api */
  UpdateApiKey: ApiKeyViewModel;
  /**
   * Mutacja do aktualizacji dostępności załącznika za pomocą serwisu FileHub.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  UpdateAttachmentAccessibility: UpdateAttachmentAccessibilityResponse;
  /**
   * Mutacja do aktualizacji dostępności załączników za pomocą serwisu FileHub.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  UpdateAttachmentsAccessibility: UpdateAttachmentsAccessibilityResponse;
  /** Aktualizacja danych firmy */
  UpdateCompany: Company;
  UpdateCompanyVehicle: UpdateCompanyVehicleMutationResponse;
  /**
   * Aktualizacja danych kontrahenta.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  UpdateContractor: UpdateContractorResponse;
  UpdateMyUser: User;
  /** Aktualizacja zdjęcia użytkownika */
  UpdateProfilePhoto: Scalars['Boolean'];
  UpdateRole?: Maybe<Role>;
  /** Aktualizacja użytkownika SymfoniaId */
  UpdateSymfoniaId: Scalars['Boolean'];
  /** Aktualizacja danych organizacji */
  UpdateTenant: TenantResponse;
  UpdateUser: User;
  /**
   * Wczytanie faktur w formacie XML do systemu Symfonia KSeF.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  UploadInvoices: UploadInvoicesResponse;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationAcceptDecreeArgs = {
  DecreeIds: Array<Scalars['UUID']>;
  UserId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationAcceptTenantArgs = {
  TenantId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationActivateTenantArgs = {
  TenantId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationAddAttachmentArgs = {
  Input: AddAttachmentInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationAddCloudServiceAvailabilityArgs = {
  Input: AddCloudServiceAvailabilityInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationArchiveCategoryArgs = {
  CategoryId: Scalars['UUID'];
  NextCategoryId?: InputMaybe<Scalars['UUID']>;
  ShouldMoveCategoryRules: Scalars['Boolean'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationAuthorizeInKSeFArgs = {
  InitSessionRequestSigned: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationAuthorizeInKSeFWithTokenArgs = {
  Token: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationAuthorizeTestCompanyInKSeFArgs = {
  CompanyNip: CompanyNip;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCancelBackupArgs = {
  TaskId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCancelWebsocketNotificationArgs = {
  notificationId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationChangeCategoryNameArgs = {
  CategoryId: Scalars['UUID'];
  CompanyId: Scalars['UUID'];
  Name: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateCategoryArgs = {
  CompanyId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  Name: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateCompanyArgs = {
  Input?: InputMaybe<CreateCompanyRequest>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateCompanyApiKeyArgs = {
  Input: CreateCompanyApiKeyInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateCompanyVehicleArgs = {
  CompanyId: Scalars['UUID'];
  Description?: InputMaybe<Scalars['String']>;
  RegistrationNumber: Scalars['String'];
  VehicleUsageType: VehicleUsageTypeEnum;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateOrganizationArgs = {
  AccountOffice?: InputMaybe<Scalars['Boolean']>;
  Address?: InputMaybe<AddressInput>;
  CloudConsent: Scalars['Boolean'];
  CompanyRepresentationConsent: Scalars['Boolean'];
  Identifier: Scalars['String'];
  IdentifierType: IdentifierType;
  MarketingEmailConsent: Scalars['Boolean'];
  MarketingEndDeviceConsent: Scalars['Boolean'];
  MarketingPhoneConsent: Scalars['Boolean'];
  Name: Scalars['String'];
  NewsletterConsent: Scalars['Boolean'];
  PersonalDataConsent: Scalars['Boolean'];
  PersonalDataForPartnersConsent: Scalars['Boolean'];
  PrivacyPolicyConsent: Scalars['Boolean'];
  StatisticsData?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  SubscriptionNumber?: InputMaybe<Scalars['String']>;
  UserFirstName: Scalars['String'];
  UserLastName: Scalars['String'];
  UserPhoneNumber: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateRoleArgs = {
  Input?: InputMaybe<CreateRoleRequest>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateTenantArgs = {
  Input: CreateTenantInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateTenantApiKeyArgs = {
  Input: CreateTenantApiKeyInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationCreateUserArgs = {
  Input: CreateUserInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeactivateTenantArgs = {
  TenantId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteApiKeyArgs = {
  Input: DeleteApiKeyRequest;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteAttachmentArgs = {
  Ids: Array<Scalars['UUID']>;
  InvoiceId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteCloudServiceAvailabilityArgs = {
  Input: DeleteCloudServiceAvailabilityRequest;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteCompanyArgs = {
  Input?: InputMaybe<DeleteCompanyRequest>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteInvoicesArgs = {
  invoiceIds: Array<Scalars['UUID']>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteRoleArgs = {
  Input?: InputMaybe<DeleteRoleRequest>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteRoleAssignmentArgs = {
  Input?: InputMaybe<InputRequest>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteTenantArgs = {
  TenantId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDeleteUserArgs = {
  Input: DeleteUserRequestInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationDownloadInvoicesFromKSeFArgs = {
  external?: InputMaybe<DateTimeRangeInput>;
  externalEnabled: Scalars['Boolean'];
  internal?: InputMaybe<DateTimeRangeInput>;
  internalEnabled: Scalars['Boolean'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationGenerateZippedInvoiceAttachmentsArgs = {
  Ids: Array<Scalars['UUID']>;
  IncludePDF: Scalars['Boolean'];
  IncludeXML: Scalars['Boolean'];
  InvoiceId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationKSeFAutoFetchingInvoicesArgs = {
  DownloadLimitDate?: InputMaybe<Scalars['DateTime']>;
  ExternalEnabled: Scalars['Boolean'];
  InternalEnabled: Scalars['Boolean'];
  Minutes: Scalars['Int'];
  Status: CompanyKsefServicesStatusEnum;
  Type: KSeFAutomaticOperationType;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationKSeFAutoSendingInvoicesArgs = {
  Status: CompanyKsefServicesStatusEnum;
  minutes: Scalars['Int'];
  type: KSeFAutomaticOperationType;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationKSeFInvoiceWhiteListValidationArgs = {
  Status: CompanyKsefServicesStatusEnum;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationKSeFInvoicesDownloadSharedAccessArgs = {
  Status: CompanyKsefServicesStatusEnum;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationMultipleSendToKSeFArgs = {
  InvoiceIds: Array<Scalars['UUID']>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationPublishInvoicesArgs = {
  InvoiceIds: Array<Scalars['UUID']>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationRefreshInvoiceStatusByKSeFSessionArgs = {
  SessionReferenceNumber: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationRejectTenantArgs = {
  Remarks: Scalars['String'];
  TenantId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationRemoveAuthorizationInKSeFArgs = {
  InitSessionRequestSigned: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationRemoveCompanyVehicleArgs = {
  Id: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationRemoveEArchiveNotificationsArgs = {
  EArchiveNotificationType: EArchiveNotificationType;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationRemovePlatformNotificationsArgs = {
  PlatformNotificationType: PlatformNotificationType;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationRequestTrialArgs = {
  Input: Input;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationRestoreBackupArgs = {
  FileName: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSaveAutoPostingSettingsArgs = {
  AutoPostingSettingChanges: Array<AutoPostingSettingChangeInput>;
  CompanyId: Scalars['UUID'];
  UserId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSaveCategoriesAccountsArgs = {
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  UpdatedCategoriesAccounts: Array<CategoryAccountRequestInput>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSaveChangesOnDecreeArgs = {
  CategoryAccountChanges?: InputMaybe<Array<CategoryAccountChangeRequestInput>>;
  CompanyId: Scalars['UUID'];
  DecreeId: Scalars['UUID'];
  DecreeLineValueChanges?: InputMaybe<Array<DecreeLineValueChangeRequestInput>>;
  DetailsChanges?: InputMaybe<DecreeDetailsChangeRequestInput>;
  PositionAccountChanges?: InputMaybe<Array<PositionAccountChangeRequestInput>>;
  PositionCategoryChanges?: InputMaybe<Array<PositionCategoryChangeRequestInput>>;
  PositionValueChanges?: InputMaybe<Array<PositionValueChangeRequestInput>>;
  PositionVehicleUsageTypeChanges?: InputMaybe<Array<PositionVehicleUsageTypeChangeRequestInput>>;
  ShouldRememberChanges: Scalars['Boolean'];
  UserId: Scalars['UUID'];
  VatSummaryChanges?: InputMaybe<Array<VatSummaryChangeRequestInput>>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSaveDocumentTypesSettingsArgs = {
  DocumentTypesSettings: Array<DocumentTypesSettingsRequestInput>;
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSaveSettlementsArgs = {
  AccountSettlementsChanges: Array<AccountSettlementsChangesInput>;
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSendInvoiceAttachmentsEmailArgs = {
  EmailAddresses: Array<Scalars['String']>;
  IncludePDF: Scalars['Boolean'];
  IncludeXML: Scalars['Boolean'];
  InvoiceId: Scalars['UUID'];
  Message: Scalars['String'];
  ShareName: Scalars['String'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSendInvoicesForPostingArgs = {
  InvoiceIds: Array<Scalars['UUID']>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSendToEArchiveArgs = {
  Faktura: FakturaInput;
  WyslijDoKsef: Scalars['Boolean'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSendToEArchiveBatchV2Args = {
  Faktury: Array<FakturaV2Input>;
  WyslijDoKsef: Scalars['Boolean'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSendToEArchiveV2Args = {
  Faktura: FakturaV2Input;
  WyslijDoKsef: Scalars['Boolean'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSendToKSeFArgs = {
  InvoiceId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSetEArchiveNotificationsArgs = {
  EArchiveNotificationType: EArchiveNotificationType;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSetInvoiceDownloadedArgs = {
  Downloaded: Scalars['Long'];
  InvoiceId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSetPlatformNotificationsArgs = {
  PlatformNotificationType: PlatformNotificationType;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSetRoleAssignmentArgs = {
  CompanyIds: Array<InputMaybe<Scalars['UUID']>>;
  RoleId: Scalars['UUID'];
  TenantId: Scalars['UUID'];
  UserId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSetSubscriptionArgs = {
  CustomerNumber: Scalars['String'];
  DateFrom: Scalars['Date'];
  DateTo: Scalars['Date'];
  Email: Scalars['String'];
  Identifier: Scalars['String'];
  IdentifierType: IdentifierType;
  Packages: Array<PackageInput>;
  SerialNumber: Scalars['String'];
  SubscriptionNumber: Scalars['String'];
  SubscriptionType: SubscriptionType;
  TenantId?: InputMaybe<Scalars['UUID']>;
  UniqueSubscriptionId?: InputMaybe<Scalars['UUID']>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSetSubscriptionTenantArgs = {
  SerialNumber: Scalars['String'];
  TenantId: Scalars['UUID'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSetSupplierTypeArgs = {
  SupplierTypeItems: Array<SupplierTypeItemInput>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSetWebsocketNotificationAcknowledgeArgs = {
  NotificationIds: Array<Scalars['UUID']>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSubscribeGrantPermissionsInKSeFArgs = {
  CredentialsInvoiceRead: Scalars['Boolean'];
  CredentialsInvoiceWrite: Scalars['Boolean'];
  CredentialsManage: Scalars['Boolean'];
  CredentialsRead: Scalars['Boolean'];
  CredentialsSelfInvoicing: Scalars['Boolean'];
  Identifier?: InputMaybe<Scalars['String']>;
  IdentifierType: IdentifierType;
  IsNew: Scalars['Boolean'];
  Name?: InputMaybe<Scalars['String']>;
  NipType?: InputMaybe<KSeFIdentifierType>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationSubscribeWhiteListCheckArgs = {
  Date: Scalars['DateTime'];
  InvoiceIds: Array<Scalars['UUID']>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationTestCustomWebsocketNotificationArgs = {
  model: WebsocketNotificationModelInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateApiKeyArgs = {
  Input: UpdateApiKeyInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateAttachmentAccessibilityArgs = {
  Id: Scalars['UUID'];
  InvoiceId: Scalars['UUID'];
  IsPublic: Scalars['Boolean'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateAttachmentsAccessibilityArgs = {
  Ids: Array<Scalars['UUID']>;
  InvoiceId: Scalars['UUID'];
  IsPublic: Scalars['Boolean'];
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateCompanyArgs = {
  Input: UpdateCompanyInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateCompanyVehicleArgs = {
  CompanyId: Scalars['UUID'];
  Description?: InputMaybe<Scalars['String']>;
  Id: Scalars['UUID'];
  RegistrationNumber: Scalars['String'];
  VehicleUsageType: VehicleUsageTypeEnum;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateContractorArgs = {
  Item: ContractorManualUpdateItemInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateMyUserArgs = {
  Input: UpdateMyUserInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateProfilePhotoArgs = {
  Input: UpdateProfilePhotoInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateRoleArgs = {
  Input?: InputMaybe<UpdateRoleRequest>;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateSymfoniaIdArgs = {
  Input: UpdateSymfoniaIdInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateTenantArgs = {
  Input: RequestInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUpdateUserArgs = {
  Input: UpdateUserInput;
};


/** Required fields:  tenantId, firstname, lastname */
export type MutationUploadInvoicesArgs = {
  invoices: Array<UploadItemInput>;
};

/** Właściwości środowisk dla bieżącego użytkownika */
export type MyEnvironmentsViewModelResponse = {
  /** Lista właściwości środowisk */
  Environments?: Maybe<Array<Maybe<EnvironmentViewModel>>>;
};

export type MyUserViewModelResponse = {
  Email?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  LastName?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
};

/** Nagłówek faktury */
export type Naglowek = {
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się w przypadku, gdy dla wszystkich pozycji faktury data jest wspólna
   */
  DataDostawy?: Maybe<Scalars['DateTime']>;
  /** Data wystawienia, z zastrzeżeniem art. 106na ust. 1 ustawy */
  DataWystawienia: Scalars['DateTime'];
  /** Pola rezerwowe przeznaczone dla wykazywania dodatkowych informacji na fakturze, w tym wymaganych przepisami prawa, dla których nie przewidziano innych pól/elementów */
  DodatkowyOpis?: Maybe<Array<KeyValuePairOfStringAndString>>;
  /** Faktura do paragonu */
  FP: Scalars['Boolean'];
  /** Trzyliterowy kod waluty (ISO-4217) w przypadkach, o których mowa w art. 31a ustawy */
  KodWaluty?: Maybe<KodWaluty>;
  /**
   * Kwota należności ogółem. W przypadku faktur zaliczkowych kwota zapłaty dokumentowana fakturą.
   * W przypadku faktur, o których mowa w art. 106f ust. 3 ustawy kwota pozostała do zapłaty.
   * W przypadku faktur korygujących korekta kwoty wynikającej z faktury korygowanej.
   * W przypadku, o którym mowa w art. 106j ust. 3 ustawy korekta kwot wynikających z faktur korygowanych.
   */
  KwotaBrutto: Scalars['Decimal'];
  /** Miejsce wystawienia faktury */
  MiejsceWystaw?: Maybe<Scalars['String']>;
  /** Pole obowiązkowe dla faktury wystawianej po wydaniu towaru lub wykonaniu usługi, o której mowa w art. 106f ust. 3 i ostatniej z faktur, o której mowa w art. 106f ust. 4 ustawy */
  NrFaZaliczkowej?: Maybe<Array<Scalars['String']>>;
  /** Kolejny numer faktury, nadany w ramach jednej lub więcej serii, który w sposób jednoznaczny identyfikuje fakturę */
  Numer?: Maybe<Scalars['String']>;
  /** Rodzaj faktury */
  RodzajFaktury?: Maybe<RodzajFaktury>;
  /** Istniejące powiązania między nabywcą a dokonującym dostawy towarów lub usługodawcą */
  TP: Scalars['Boolean'];
  /** Numery dokumentów magazynowych WZ (wydanie na zewnątrz) związane z fakturą */
  WZ?: Maybe<Array<Scalars['String']>>;
};

/** Nagłówek faktury */
export type NaglowekInput = {
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się w przypadku, gdy dla wszystkich pozycji faktury data jest wspólna
   */
  DataDostawy?: InputMaybe<Scalars['DateTime']>;
  /** Data wystawienia, z zastrzeżeniem art. 106na ust. 1 ustawy */
  DataWystawienia: Scalars['DateTime'];
  /** Pola rezerwowe przeznaczone dla wykazywania dodatkowych informacji na fakturze, w tym wymaganych przepisami prawa, dla których nie przewidziano innych pól/elementów */
  DodatkowyOpis?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  /** Faktura do paragonu */
  FP: Scalars['Boolean'];
  /** Trzyliterowy kod waluty (ISO-4217) w przypadkach, o których mowa w art. 31a ustawy */
  KodWaluty?: InputMaybe<KodWaluty>;
  /**
   * Kwota należności ogółem. W przypadku faktur zaliczkowych kwota zapłaty dokumentowana fakturą.
   * W przypadku faktur, o których mowa w art. 106f ust. 3 ustawy kwota pozostała do zapłaty.
   * W przypadku faktur korygujących korekta kwoty wynikającej z faktury korygowanej.
   * W przypadku, o którym mowa w art. 106j ust. 3 ustawy korekta kwot wynikających z faktur korygowanych.
   */
  KwotaBrutto: Scalars['Decimal'];
  /** Miejsce wystawienia faktury */
  MiejsceWystaw?: InputMaybe<Scalars['String']>;
  /** Pole obowiązkowe dla faktury wystawianej po wydaniu towaru lub wykonaniu usługi, o której mowa w art. 106f ust. 3 i ostatniej z faktur, o której mowa w art. 106f ust. 4 ustawy */
  NrFaZaliczkowej?: InputMaybe<Array<Scalars['String']>>;
  /** Kolejny numer faktury, nadany w ramach jednej lub więcej serii, który w sposób jednoznaczny identyfikuje fakturę */
  Numer?: InputMaybe<Scalars['String']>;
  /** Rodzaj faktury */
  RodzajFaktury?: InputMaybe<RodzajFaktury>;
  /** Istniejące powiązania między nabywcą a dokonującym dostawy towarów lub usługodawcą */
  TP: Scalars['Boolean'];
  /** Numery dokumentów magazynowych WZ (wydanie na zewnątrz) związane z fakturą */
  WZ?: InputMaybe<Array<Scalars['String']>>;
};

/** Nagłówek faktury */
export type NaglowekV2 = {
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się w przypadku, gdy dla wszystkich pozycji faktury data jest wspólna
   */
  DataDostawy?: Maybe<Scalars['DateTime']>;
  /** Data wystawienia, z zastrzeżeniem art. 106na ust. 1 ustawy */
  DataWystawienia: Scalars['DateTime'];
  /** Pola rezerwowe przeznaczone dla wykazywania dodatkowych informacji na fakturze, w tym wymaganych przepisami prawa, dla których nie przewidziano innych pól/elementów */
  DodatkowyOpis?: Maybe<Array<DodatkowyOpisPolaV2>>;
  /** Faktura do paragonu */
  FP: Scalars['Boolean'];
  /** Numery faktur zaliczkowych lub ich numery KSeF, jeśli zostały wystawione z użyciem KSeF */
  FakturaZaliczkowa?: Maybe<Array<FakturaZaliczkowaV2>>;
  /** Trzyliterowy kod waluty (ISO-4217) w przypadkach, o których mowa w art. 31a ustawy */
  KodWaluty?: Maybe<KodWaluty>;
  /**
   * Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w przepisach Działu VI ustawy na fakturach, o których mowa w art. 106b ust. 1 pkt 4 ustawy
   * Typ wykorzystywany do określenia ilości. Wartość numeryczna 22 znaki max, w tym 6 po przecinku
   * Total number of digits: 22.
   * Total number of digits in fraction: 6.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,6})?.
   */
  KursWalutyZaliczka?: Maybe<Scalars['Decimal']>;
  /**
   * Kwota należności ogółem. W przypadku faktur zaliczkowych kwota zapłaty dokumentowana fakturą.
   * W przypadku faktur, o których mowa w art. 106f ust. 3 ustawy kwota pozostała do zapłaty.
   * W przypadku faktur korygujących korekta kwoty wynikającej z faktury korygowanej.
   * W przypadku, o którym mowa w art. 106j ust. 3 ustawy korekta kwot wynikających z faktur korygowanych.
   */
  KwotaBrutto: Scalars['Decimal'];
  /**
   * Suma wartości sprzedaży netto ze stawką podstawową - aktualnie 23% albo 22%.
   * W przypadku faktur zaliczkowych, kwota zaliczki netto.
   * W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
   */
  KwotaNetto?: Maybe<Scalars['Decimal']>;
  /**
   * Kwota podatku od sumy wartości sprzedaży netto objętej stawką podstawową - aktualnie 23% albo 22%.
   * W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy.
   * W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
   */
  KwotaVat?: Maybe<Scalars['Decimal']>;
  /** Miejsce wystawienia faktury */
  MiejsceWystaw?: Maybe<Scalars['String']>;
  /** Kolejny numer faktury, nadany w ramach jednej lub więcej serii, który w sposób jednoznaczny identyfikuje fakturę */
  Numer?: Maybe<Scalars['String']>;
  /** Okres, którego dotyczy faktura w przypadkach, o których mowa w art. 19a ust. 3 zdanie pierwsze i ust. 4 oraz ust. 5 pkt 4 ustawy */
  OkresFaktury?: Maybe<ZakresDatV2>;
  /** Rodzaj faktury */
  RodzajFaktury?: Maybe<RodzajFaktury>;
  /** Istniejące powiązania między nabywcą a dokonującym dostawy towarów lub usługodawcą */
  TP: Scalars['Boolean'];
  /** Numery dokumentów magazynowych WZ (wydanie na zewnątrz) związane z fakturą */
  WZ?: Maybe<Array<Scalars['String']>>;
  /** Dane dla przypadków faktur dokumentujących otrzymanie więcej niż jednej płatności, o której mowa w art. 106b ust. 1 pkt 4 ustawy. W przypadku, gdy faktura, o której mowa w art. 106f ust. 3 ustawy dokumentuje jednocześnie otrzymanie części zapłaty przed dokonaniem czynności, różnica kwoty w polu P_15 i sumy poszczególnych pól P_15Z stanowi kwotę pozostałą ponad płatności otrzymane przed wykonaniem czynności udokumentowanej fakturą */
  ZaliczkaCzesciowa?: Maybe<Array<ZaliczkaCzesciowaV2>>;
  /** Informacja dodatkowa niezbędna dla rolników ubiegających się o zwrot podatku akcyzowego zawartego w cenie oleju napędowego */
  ZwrotAkcyzy?: Maybe<Scalars['Boolean']>;
};

/** Nagłówek faktury */
export type NaglowekV2Input = {
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się w przypadku, gdy dla wszystkich pozycji faktury data jest wspólna
   */
  DataDostawy?: InputMaybe<Scalars['DateTime']>;
  /** Data wystawienia, z zastrzeżeniem art. 106na ust. 1 ustawy */
  DataWystawienia: Scalars['DateTime'];
  /** Pola rezerwowe przeznaczone dla wykazywania dodatkowych informacji na fakturze, w tym wymaganych przepisami prawa, dla których nie przewidziano innych pól/elementów */
  DodatkowyOpis?: InputMaybe<Array<DodatkowyOpisPolaV2Input>>;
  /** Faktura do paragonu */
  FP: Scalars['Boolean'];
  /** Numery faktur zaliczkowych lub ich numery KSeF, jeśli zostały wystawione z użyciem KSeF */
  FakturaZaliczkowa?: InputMaybe<Array<FakturaZaliczkowaV2Input>>;
  /** Trzyliterowy kod waluty (ISO-4217) w przypadkach, o których mowa w art. 31a ustawy */
  KodWaluty?: InputMaybe<KodWaluty>;
  /**
   * Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w przepisach Działu VI ustawy na fakturach, o których mowa w art. 106b ust. 1 pkt 4 ustawy
   * Typ wykorzystywany do określenia ilości. Wartość numeryczna 22 znaki max, w tym 6 po przecinku
   * Total number of digits: 22.
   * Total number of digits in fraction: 6.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,6})?.
   */
  KursWalutyZaliczka?: InputMaybe<Scalars['Decimal']>;
  /**
   * Kwota należności ogółem. W przypadku faktur zaliczkowych kwota zapłaty dokumentowana fakturą.
   * W przypadku faktur, o których mowa w art. 106f ust. 3 ustawy kwota pozostała do zapłaty.
   * W przypadku faktur korygujących korekta kwoty wynikającej z faktury korygowanej.
   * W przypadku, o którym mowa w art. 106j ust. 3 ustawy korekta kwot wynikających z faktur korygowanych.
   */
  KwotaBrutto: Scalars['Decimal'];
  /**
   * Suma wartości sprzedaży netto ze stawką podstawową - aktualnie 23% albo 22%.
   * W przypadku faktur zaliczkowych, kwota zaliczki netto.
   * W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
   */
  KwotaNetto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Kwota podatku od sumy wartości sprzedaży netto objętej stawką podstawową - aktualnie 23% albo 22%.
   * W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy.
   * W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
   */
  KwotaVat?: InputMaybe<Scalars['Decimal']>;
  /** Miejsce wystawienia faktury */
  MiejsceWystaw?: InputMaybe<Scalars['String']>;
  /** Kolejny numer faktury, nadany w ramach jednej lub więcej serii, który w sposób jednoznaczny identyfikuje fakturę */
  Numer?: InputMaybe<Scalars['String']>;
  /** Okres, którego dotyczy faktura w przypadkach, o których mowa w art. 19a ust. 3 zdanie pierwsze i ust. 4 oraz ust. 5 pkt 4 ustawy */
  OkresFaktury?: InputMaybe<ZakresDatV2Input>;
  /** Rodzaj faktury */
  RodzajFaktury?: InputMaybe<RodzajFaktury>;
  /** Istniejące powiązania między nabywcą a dokonującym dostawy towarów lub usługodawcą */
  TP: Scalars['Boolean'];
  /** Numery dokumentów magazynowych WZ (wydanie na zewnątrz) związane z fakturą */
  WZ?: InputMaybe<Array<Scalars['String']>>;
  /** Dane dla przypadków faktur dokumentujących otrzymanie więcej niż jednej płatności, o której mowa w art. 106b ust. 1 pkt 4 ustawy. W przypadku, gdy faktura, o której mowa w art. 106f ust. 3 ustawy dokumentuje jednocześnie otrzymanie części zapłaty przed dokonaniem czynności, różnica kwoty w polu P_15 i sumy poszczególnych pól P_15Z stanowi kwotę pozostałą ponad płatności otrzymane przed wykonaniem czynności udokumentowanej fakturą */
  ZaliczkaCzesciowa?: InputMaybe<Array<ZaliczkaCzesciowaV2Input>>;
  /** Informacja dodatkowa niezbędna dla rolników ubiegających się o zwrot podatku akcyzowego zawartego w cenie oleju napędowego */
  ZwrotAkcyzy?: InputMaybe<Scalars['Boolean']>;
};

/** Opis faktury niepodlegającej usunięciu */
export type NotEligibleDeleteInvoices = {
  /** Data wystawienia faktury */
  invoiceDate?: Maybe<Scalars['DateTime']>;
  /** Id faktury w Symfonia KSeF */
  invoiceId: Scalars['UUID'];
  /** Numer faktury w Symfonia KSeF */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Przyczyna blokady usunięcia */
  reason: InvoiceNonRemovableReason;
};

export type NullableOfSymfoniaSystemOperationFilterInput = {
  eq?: InputMaybe<SymfoniaSystem>;
  in?: InputMaybe<Array<InputMaybe<SymfoniaSystem>>>;
  neq?: InputMaybe<SymfoniaSystem>;
  nin?: InputMaybe<Array<InputMaybe<SymfoniaSystem>>>;
};

export type NumberRangeSchemaModelOfDecimalInput = {
  From?: InputMaybe<Scalars['Decimal']>;
  To?: InputMaybe<Scalars['Decimal']>;
};

/** Obciążenie na fakturze */
export type Obciazenie = {
  /** Kwota doliczona do kwoty wykazanej w polu KwotaBrutto */
  KwotaObciazenia: Scalars['Decimal'];
  /** Powód obciążenia */
  PowodObciazenia?: Maybe<Scalars['String']>;
};

/** Obciążenie na fakturze */
export type ObciazenieInput = {
  /** Kwota doliczona do kwoty wykazanej w polu KwotaBrutto */
  KwotaObciazenia: Scalars['Decimal'];
  /** Powód obciążenia */
  PowodObciazenia?: InputMaybe<Scalars['String']>;
};

/** Odliczenie na fakturze */
export type Odliczenie = {
  /** Kwota odliczona od kwoty wykazanej w polu KwotaBrutto */
  KwotaOdliczenia: Scalars['Decimal'];
  /** Powód odliczenia */
  PowodOdliczenia?: Maybe<Scalars['String']>;
};

/** Odliczenie na fakturze */
export type OdliczenieInput = {
  /** Kwota odliczona od kwoty wykazanej w polu KwotaBrutto */
  KwotaOdliczenia: Scalars['Decimal'];
  /** Powód odliczenia */
  PowodOdliczenia?: InputMaybe<Scalars['String']>;
};

/** Wynik próby autoryzacji w KSeF */
export type OnAuthorizedInKSeFResultUserMessage = {
  /** Obecny status autoryzacji */
  result: KSeFAuthResult;
};

export type OnAutoPostingInitializationCompletedSubscriptionResponse = {
  isCompleted: Scalars['Boolean'];
};

export type OnAutoPostingInitializationStatusChangedResponse = {
  errorDetails?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  status: ProcessTaskStatusEnum;
  taskType: InitializationTaskTypeEnum;
};

/** Operacja nadania/usunięcia uprawnienia */
export type OnCredentialOperationResultUserMessage = {
  /** Opis wyniku operacji */
  description?: Maybe<Scalars['String']>;
  /** Numer referencyjny nadany przez KSeF */
  referenceNumber?: Maybe<Scalars['String']>;
  /** Typ pojedynczej operacji */
  requestType: KSeFRequestForCredentialType;
  /** Status operacji w KSeF */
  status: KSeFRequestForCredentialsProcessingStatus;
  /** Wynik wykonania operacji nadania uprawnień w KSeF */
  success: Scalars['Boolean'];
};

export type OnDeleteInvoicesResultUserMessage = {
  /** Liczba prawidłowo usuniętych faktur */
  deleted: Scalars['Int'];
  /** Lista faktur, które nie zostały usunięte. */
  errorItems?: Maybe<Array<Maybe<NotEligibleDeleteInvoices>>>;
  /** Lista ID faktur, które zostały przekazane do usunięcia. */
  invoicesId?: Maybe<Array<Scalars['UUID']>>;
};

/** Informacja o rezultacie operacji pobrania faktur z KSeF */
export type OnDownloadInvoicesResultUserMessage = {
  /** Lista błędnych faktur */
  errorItems?: Maybe<Array<Maybe<DownloadInvoiceItemMessage>>>;
  /** Zlecony zakres dat dla faktur zakupowych */
  external?: Maybe<DateTimeRange>;
  /** Pobieranie faktur sprzedażowych */
  externalEnabled: Scalars['Boolean'];
  /** Liczba pobranych faktur */
  imported: Scalars['Int'];
  /** Zlecony zakres dat dla faktur sprzedażowych */
  internal?: Maybe<DateTimeRange>;
  /** Pobieranie faktur sprzedażowych */
  internalEnabled: Scalars['Boolean'];
  /** Liczba sekund po którym skończy się state Pending */
  pendingTimeInSeconds: Scalars['Int'];
  /** Określa czy potrzebne jest odświeżenie widoku faktur */
  refreshNeeded: Scalars['Boolean'];
  /** Stan procesu pobierania faktur */
  state: DownloadStateUserMessage;
  /** Czas ustawienia nowego stanu (state) */
  stateTime: Scalars['DateTime'];
  /** Lista prawidłowych faktur */
  successItems?: Maybe<Array<Maybe<DownloadInvoiceItemMessage>>>;
};

/** Wynik pobrania UPO */
export type OnGetUpoResultUserMessage = {
  /** Zawartość pliku */
  fileContent?: Maybe<Scalars['String']>;
  /** Nazwa pliku */
  fileName?: Maybe<Scalars['String']>;
  /** Typ pliku */
  fileType: UpoFileType;
  /** Typ MIME */
  mimeType?: Maybe<Scalars['String']>;
  /** Lista brakujących faktur */
  missingInvoices?: Maybe<Array<Maybe<MissedInvoices>>>;
  /** Liczba wnioskowanych faktur */
  requestedInvoices?: Maybe<Array<Scalars['UUID']>>;
  /** Liczba odebranych faktur */
  returnedInvoices?: Maybe<Array<Scalars['UUID']>>;
};

/** Negatywny wynik dekretacji faktur */
export type OnGetWhiteListItemsStatusUserMessage = {
  /** Zlecona data realizacji sprawdzenia statusów na białej liście */
  date: Scalars['DateTime'];
  /** Lista faktur których nie udało sie sprawdzić serwisie */
  errorItems?: Maybe<Array<Maybe<WhiteListItem>>>;
  /** Lista Id faktur zleconych do sprawdzenia rechunków na białej liście */
  invoiceIds?: Maybe<Array<Scalars['UUID']>>;
  /** Lista faktur nie będących na białej liście */
  notWhiteListed?: Maybe<Array<Maybe<WhiteListItem>>>;
  /** Liczba sprawdzanych faktur */
  whiteListed?: Maybe<Array<Maybe<WhiteListItem>>>;
};

/** Wynik operacji nadania/usunięcia uprawnień */
export type OnGrantKSeFPermissionsResultUserMessage = {
  /** True, kiedy wszystkie operacje w zestawie zostały ukończone */
  completed: Scalars['Boolean'];
  /** Identyfikator podatkowy */
  identifier?: Maybe<Scalars['String']>;
  /** Typ identyfikatora */
  identifierType: IdentifierType;
  /** Typ operacji dla grupy uprawnień */
  operationType: KSeFRequestForCredentialResultType;
  /** Lista operacji */
  permissionList: Array<OnCredentialOperationResultUserMessage>;
};

/** Wynik zmiany statusu dekretacji faktur */
export type OnOcrInvoiceImportedUserMessage = {
  /** Numer faktury OCR */
  invoiceId: Scalars['UUID'];
};

/** Negatywny wynik dekretacji faktur */
export type OnPostInvoicesFailedUserMessage = {
  /** Numery faktur */
  invoiceNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Informacja o rezultacie operacji wysyłania faktur do KSeF */
export type OnSendingInvoicesResultUserMessage = {
  /** Lista faktur */
  invoices?: Maybe<Array<Maybe<SendingInvoiceItemMessage>>>;
};

/** Operacja rozłączenia sesji w KSeF */
export type OnTerminateKSeFSessionResultUserMessage = {
  /** Wynik operacji rozłączenia sesji w KSeF */
  result: Scalars['Boolean'];
};

/** Informacja o statusie wyłączenia autoryzacji w KSeF */
export type OnUnauthorizedKSeFResultUserMessage = {
  /** Flaga informująca o znalezieniu innego użytkownika z aktywną autoryzacją w kontekście firmy */
  authorizedUserFound?: Maybe<Scalars['Boolean']>;
  /** Uprawnienia użytkownika */
  credentials?: Maybe<KSeFCredentialState>;
  /** Liczba wstrzymanych faktur w statusie Oczekująca */
  interruptedPendingCount: Scalars['Int'];
  /** Liczba wstrzymanych faktur w statusie Do wysłania */
  interruptedSendingCount: Scalars['Int'];
  /** Wynik wyłączenia autoryzacji w KSeF */
  isUnauthorized: Scalars['Boolean'];
  /** Sposób autoryzacji */
  signedType: KSeFSignedType;
};

/** Operacja rozłączenia sesji w KSeF */
export type OnUploadInvoicesResultUserMessage = {
  /** Lista elementów zakończonych niepowodzeniem */
  errorItems?: Maybe<Array<Maybe<UploadInvoicesResultItem>>>;
  /** Lista elementów zakończonych powodzeniem */
  successItems?: Maybe<Array<Maybe<UploadInvoicesResultItem>>>;
};

/** Kolejność sortowania */
export enum OrderDirection {
  /** Rosnąco */
  Asc = 'ASC',
  /** Malejąco */
  Desc = 'DESC'
}

/** Oznaczenia dotyczące procedur dla faktur */
export enum OznaczenieProcedury {
  /** Oznaczenie dotyczące procedury, o której mowa § 10 ust. 4 pkt 12 rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  BMpvProwizja = 'B_MPV_PROWIZJA',
  /** Oznaczenie dotyczące procedury, o której mowa § 10 ust. 4 pkt 10 rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  BSpv = 'B_SPV',
  /** Oznaczenie dotyczące procedury, o której mowa § 10 ust. 4 pkt 11 rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  BSpvDostawa = 'B_SPV_DOSTAWA',
  /** Oznaczenie dotyczące procedury, o której mowa § 10 ust. 4 pkt 2b rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  Ied = 'IED',
  /** Oznaczenie dotyczące procedury, o której mowa § 10 ust. 4 pkt 8 rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  I_42 = 'I_42',
  /** Oznaczenie dotyczące procedury, o której mowa § 10 ust. 4 pkt 9 rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  I_63 = 'I_63',
  /** Oznaczenie dotyczące procedury, o której mowa § 10 ust. 4 pkt 5 rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  TtD = 'TT_D',
  /** Oznaczenie dotyczące procedury, o której mowa § 10 ust. 4 pkt 2a rozporządzenia w sprawie szczegółowego zakresu danych zawartych w deklaracjach podatkowych i w ewidencji w zakresie podatku od towarów i usług */
  WstoEe = 'WSTO_EE'
}

/** Pakiet subskrypcji */
export type Package = {
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Opis pakietu */
  PackageDescription?: Maybe<Scalars['String']>;
  /** Typ pakietu */
  PackageTypeEnum: PackageType;
  /** Wartość pakietu np.: liczba GB bądź liczba faktur lub inna usługa */
  PackageValue: Scalars['String'];
  /** Obiekt subskrypcji */
  Subscription: Subscription;
  /** Identyfikator subskrypcji */
  SubscriptionId: Scalars['UUID'];
};

export type PackageInput = {
  PackageDescription?: InputMaybe<Scalars['String']>;
  PackageTypeEnum: PackageType;
  PackageValue?: InputMaybe<Scalars['String']>;
};

/** Typ pakietu */
export enum PackageType {
  /** Limit faktur */
  Invoice = 'INVOICE',
  /** Dodatkowe usługi */
  Service = 'SERVICE',
  /** Limit miejsca */
  Storage = 'STORAGE'
}

export enum PaymentTypeEnum {
  BankTransfer = 'BANK_TRANSFER',
  Card = 'CARD',
  Cash = 'CASH',
  Check = 'CHECK',
  Credit = 'CREDIT',
  Mobile = 'MOBILE',
  Undefined = 'UNDEFINED',
  Voucher = 'VOUCHER'
}

export type PermissionsKSeFReportType = {
  FileContent?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  MimeType?: Maybe<Scalars['String']>;
};

export enum PlatformNotificationType {
  CompanyCreatedInTenant = 'COMPANY_CREATED_IN_TENANT',
  CompanyDeleted = 'COMPANY_DELETED',
  CompanyInfoEdited = 'COMPANY_INFO_EDITED',
  None = 'NONE',
  OrganizationCreated = 'ORGANIZATION_CREATED',
  TenantActivated = 'TENANT_ACTIVATED',
  TenantDeactivated = 'TENANT_DEACTIVATED',
  TenantDeleted = 'TENANT_DELETED',
  TenantInfoEdited = 'TENANT_INFO_EDITED',
  TenantRejected = 'TENANT_REJECTED',
  UserAssignedToCompany = 'USER_ASSIGNED_TO_COMPANY'
}

export type PlatformVersionQueryResponse = {
  GitCommit?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
};

/** Dane płatności na fakturze */
export type Platnosc = {
  /** Data zapłaty, jeśli do wystawienia faktury płatność została dokonana */
  DataZaplaty?: Maybe<Scalars['DateTime']>;
  /** Forma płatności */
  FormaPlatnosci?: Maybe<FormaPlatnosci>;
  /** Doprecyzowanie innej formy płatności */
  OpisPlatnosci?: Maybe<Scalars['String']>;
  /** Znacznik innej formy płatności: inna forma płatności */
  PlatnoscInna: Scalars['Boolean'];
  /** Lista numerów rachunków bankowych */
  RachunekBankowy?: Maybe<Array<TRachunekBankowy>>;
  /** Lista numerów rachunków bankowych faktora */
  RachunekBankowyFaktora?: Maybe<Array<TRachunekBankowy>>;
  /** Lista terminów płatności */
  TerminPlatnosci?: Maybe<Array<TTerminPlatnosci>>;
  /** Znacznik informujący, że kwota należności wynikająca z faktury została zapłacona */
  Zaplacono: Scalars['Boolean'];
  /** Lista zapłat częściowych */
  ZaplataCzesciowa?: Maybe<Array<TZaplataCzesciowa>>;
};

/** Dane płatności na fakturze */
export type PlatnoscInput = {
  /** Data zapłaty, jeśli do wystawienia faktury płatność została dokonana */
  DataZaplaty?: InputMaybe<Scalars['DateTime']>;
  /** Forma płatności */
  FormaPlatnosci?: InputMaybe<FormaPlatnosci>;
  /** Doprecyzowanie innej formy płatności */
  OpisPlatnosci?: InputMaybe<Scalars['String']>;
  /** Znacznik innej formy płatności: inna forma płatności */
  PlatnoscInna: Scalars['Boolean'];
  /** Lista numerów rachunków bankowych */
  RachunekBankowy?: InputMaybe<Array<TRachunekBankowyInput>>;
  /** Lista numerów rachunków bankowych faktora */
  RachunekBankowyFaktora?: InputMaybe<Array<TRachunekBankowyInput>>;
  /** Lista terminów płatności */
  TerminPlatnosci?: InputMaybe<Array<TTerminPlatnosciInput>>;
  /** Znacznik informujący, że kwota należności wynikająca z faktury została zapłacona */
  Zaplacono: Scalars['Boolean'];
  /** Lista zapłat częściowych */
  ZaplataCzesciowa?: InputMaybe<Array<TZaplataCzesciowaInput>>;
};

/** Element Płatność */
export type PlatnoscV2 = {
  /** Data zapłaty, jeśli do wystawienia faktury płatność została dokonana */
  DataZaplaty?: Maybe<Scalars['DateTime']>;
  /** Forma płatności */
  FormaPlatnosci?: Maybe<FormaPlatnosci>;
  /** Doprecyzowanie innej formy płatności */
  OpisPlatnosci?: Maybe<Scalars['String']>;
  /** Znacznik innej formy płatności: inna forma płatności */
  PlatnoscInna: Scalars['Boolean'];
  /** Lista numerów rachunków bankowych */
  RachunekBankowy?: Maybe<Array<TRachunekBankowyV2>>;
  /** Lista numerów rachunków bankowych faktora */
  RachunekBankowyFaktora?: Maybe<Array<TRachunekBankowyV2>>;
  /** Skonto */
  Skonto?: Maybe<TSkontoV2>;
  /** Lista terminów płatności */
  TerminPlatnosci?: Maybe<Array<TTerminPlatnosci>>;
  /** Znacznik informujący, że kwota należności wynikająca z faktury została zapłacona */
  Zaplacono: Scalars['Boolean'];
  /** Lista zapłat częściowych */
  ZaplataCzesciowa?: Maybe<Array<TZaplataCzesciowa>>;
  /** Znacznik informujący, że kwota należności wynikająca z faktury została zapłacona w części: 1 - zapłacono w części */
  ZnacznikZaplatyCzesciowej: Scalars['Boolean'];
};

/** Element Płatność */
export type PlatnoscV2Input = {
  /** Data zapłaty, jeśli do wystawienia faktury płatność została dokonana */
  DataZaplaty?: InputMaybe<Scalars['DateTime']>;
  /** Forma płatności */
  FormaPlatnosci?: InputMaybe<FormaPlatnosci>;
  /** Doprecyzowanie innej formy płatności */
  OpisPlatnosci?: InputMaybe<Scalars['String']>;
  /** Znacznik innej formy płatności: inna forma płatności */
  PlatnoscInna: Scalars['Boolean'];
  /** Lista numerów rachunków bankowych */
  RachunekBankowy?: InputMaybe<Array<TRachunekBankowyV2Input>>;
  /** Lista numerów rachunków bankowych faktora */
  RachunekBankowyFaktora?: InputMaybe<Array<TRachunekBankowyV2Input>>;
  /** Skonto */
  Skonto?: InputMaybe<TSkontoV2Input>;
  /** Lista terminów płatności */
  TerminPlatnosci?: InputMaybe<Array<TTerminPlatnosciInput>>;
  /** Znacznik informujący, że kwota należności wynikająca z faktury została zapłacona */
  Zaplacono: Scalars['Boolean'];
  /** Lista zapłat częściowych */
  ZaplataCzesciowa?: InputMaybe<Array<TZaplataCzesciowaInput>>;
  /** Znacznik informujący, że kwota należności wynikająca z faktury została zapłacona w części: 1 - zapłacono w części */
  ZnacznikZaplatyCzesciowej: Scalars['Boolean'];
};

/** Dane teleadresowe podatnika */
export type Podmiot = {
  /** Adres */
  Adres?: Maybe<Adres>;
  /** Adres korespondencyjny */
  AdresKoresp?: Maybe<Adres>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjne;
  /** Adres email */
  Email?: Maybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: Maybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: Maybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: Maybe<PrefixPodatnika>;
  /** Status podatnika */
  StatusPodatnika?: Maybe<TStatusPodatnika>;
  /** Numer telefonu */
  Telefon?: Maybe<Scalars['String']>;
};

/** Dane teleadresowe podatnika */
export type PodmiotInput = {
  /** Adres */
  Adres?: InputMaybe<AdresInput>;
  /** Adres korespondencyjny */
  AdresKoresp?: InputMaybe<AdresInput>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneInput;
  /** Adres email */
  Email?: InputMaybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: InputMaybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: InputMaybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: InputMaybe<PrefixPodatnika>;
  /** Status podatnika */
  StatusPodatnika?: InputMaybe<TStatusPodatnikaInput>;
  /** Numer telefonu */
  Telefon?: InputMaybe<Scalars['String']>;
};

/** Podmiot upoważniony widniejący na fakturze */
export type PodmiotUpowazniony = {
  /** Adres */
  Adres?: Maybe<Adres>;
  /** Adres korespondencyjny */
  AdresKoresp?: Maybe<Adres>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjne;
  /** Adres email */
  Email?: Maybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: Maybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: Maybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: Maybe<PrefixPodatnika>;
  /** Rola podmiotu */
  Rola: RolaPodmiotuUpowaznionego;
  /** Status podatnika */
  StatusPodatnika?: Maybe<TStatusPodatnika>;
  /** Numer telefonu */
  Telefon?: Maybe<Scalars['String']>;
};

/** Podmiot upoważniony widniejący na fakturze */
export type PodmiotUpowaznionyInput = {
  /** Adres */
  Adres?: InputMaybe<AdresInput>;
  /** Adres korespondencyjny */
  AdresKoresp?: InputMaybe<AdresInput>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneInput;
  /** Adres email */
  Email?: InputMaybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: InputMaybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: InputMaybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: InputMaybe<PrefixPodatnika>;
  /** Rola podmiotu */
  Rola: RolaPodmiotuUpowaznionego;
  /** Status podatnika */
  StatusPodatnika?: InputMaybe<TStatusPodatnikaInput>;
  /** Numer telefonu */
  Telefon?: InputMaybe<Scalars['String']>;
};

/** Podmiot upoważniony widniejący na fakturze */
export type PodmiotUpowaznionyV2 = {
  /** Adres */
  Adres?: Maybe<AdresV2>;
  /** Adres korespondencyjny */
  AdresKoresp?: Maybe<AdresV2>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneV2;
  /** Dane kontaktowe */
  DaneKontaktowe?: Maybe<Array<TDaneKontaktoweV2>>;
  /** Unikalny klucz powiązania danych nabywcy na fakturach korygujących, w przypadku gdy dane nabywcy na fakturze korygującej zmieniły się w stosunku do danych na fakturze korygowanej */
  IDNabywcy?: Maybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: Maybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: Maybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: Maybe<PrefixPodatnika>;
  /** Rola podmiotu */
  Rola: RolaPodmiotuUpowaznionego;
  /** Status podatnika */
  StatusPodatnika?: Maybe<TStatusPodatnikaV2>;
};

/** Podmiot upoważniony widniejący na fakturze */
export type PodmiotUpowaznionyV2Input = {
  /** Adres */
  Adres?: InputMaybe<AdresV2Input>;
  /** Adres korespondencyjny */
  AdresKoresp?: InputMaybe<AdresV2Input>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneV2Input;
  /** Dane kontaktowe */
  DaneKontaktowe?: InputMaybe<Array<TDaneKontaktoweV2Input>>;
  /** Unikalny klucz powiązania danych nabywcy na fakturach korygujących, w przypadku gdy dane nabywcy na fakturze korygującej zmieniły się w stosunku do danych na fakturze korygowanej */
  IDNabywcy?: InputMaybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: InputMaybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: InputMaybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: InputMaybe<PrefixPodatnika>;
  /** Rola podmiotu */
  Rola: RolaPodmiotuUpowaznionego;
  /** Status podatnika */
  StatusPodatnika?: InputMaybe<TStatusPodatnikaV2Input>;
};

/** Dane teleadresowe podatnika */
export type PodmiotV2 = {
  /** Adres */
  Adres?: Maybe<AdresV2>;
  /** Adres korespondencyjny */
  AdresKoresp?: Maybe<AdresV2>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneV2;
  /** Dane kontaktowe */
  DaneKontaktowe?: Maybe<Array<TDaneKontaktoweV2>>;
  /** Unikalny klucz powiązania danych nabywcy na fakturach korygujących, w przypadku gdy dane nabywcy na fakturze korygującej zmieniły się w stosunku do danych na fakturze korygowanej */
  IDNabywcy?: Maybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: Maybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: Maybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: Maybe<PrefixPodatnika>;
  /** Status podatnika */
  StatusPodatnika?: Maybe<TStatusPodatnikaV2>;
};

/** Dane teleadresowe podatnika */
export type PodmiotV2Input = {
  /** Adres */
  Adres?: InputMaybe<AdresV2Input>;
  /** Adres korespondencyjny */
  AdresKoresp?: InputMaybe<AdresV2Input>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneV2Input;
  /** Dane kontaktowe */
  DaneKontaktowe?: InputMaybe<Array<TDaneKontaktoweV2Input>>;
  /** Unikalny klucz powiązania danych nabywcy na fakturach korygujących, w przypadku gdy dane nabywcy na fakturze korygującej zmieniły się w stosunku do danych na fakturze korygowanej */
  IDNabywcy?: InputMaybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: InputMaybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: InputMaybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: InputMaybe<PrefixPodatnika>;
  /** Status podatnika */
  StatusPodatnika?: InputMaybe<TStatusPodatnikaV2Input>;
};

/** Podsumowanie VAT pozycji */
export type PodsumowanieVat = {
  /** Kwota VAT */
  KwotaVat: Scalars['Decimal'];
  /** W przypadku gdy faktura jest wystawiona w walucie obcej, kwota podatku VAT w PLN przeliczona według kursu */
  KwotaVatKurs: Scalars['Decimal'];
  /** Stawka podatku VAT */
  StawkaPodatku: StawkaPodatku;
  /** Wartość netto */
  WartoscNetto: Scalars['Decimal'];
};

/** Podsumowanie VAT pozycji */
export type PodsumowanieVatInput = {
  /** Kwota VAT */
  KwotaVat: Scalars['Decimal'];
  /** W przypadku gdy faktura jest wystawiona w walucie obcej, kwota podatku VAT w PLN przeliczona według kursu */
  KwotaVatKurs: Scalars['Decimal'];
  /** Stawka podatku VAT */
  StawkaPodatku: StawkaPodatku;
  /** Wartość netto */
  WartoscNetto: Scalars['Decimal'];
};

export type PositionAccountChangeRequestInput = {
  InvoicePositionId: Scalars['String'];
  NewAccountType: AccountTypeEnum;
};

export type PositionCategoryChangeRequestInput = {
  CategoryId: Scalars['UUID'];
  InvoicePositionId: Scalars['String'];
};

export type PositionDetailsResponse = {
  CanEditVehicles: Scalars['Boolean'];
  Id: Scalars['UUID'];
  InvoicePositionId: Scalars['String'];
  IsRound: Scalars['Boolean'];
  IsRuleCategory: Scalars['Boolean'];
  Name: Scalars['String'];
  Value: Scalars['Decimal'];
  VehicleId?: Maybe<Scalars['UUID']>;
};

export type PositionValueChangeRequestInput = {
  DecreeLinePositionId: Scalars['UUID'];
  Value: Scalars['Decimal'];
};

export type PositionVehicleUsageTypeChangeRequestInput = {
  VehicleId: Scalars['UUID'];
};

export enum PostingIdentifierTypeEnum {
  Nip = 'NIP',
  Pesel = 'PESEL',
  Regon = 'REGON',
  Undefined = 'UNDEFINED'
}

export enum PostingStatusEnum {
  DecreeDownloaded = 'DECREE_DOWNLOADED',
  DecreeSent = 'DECREE_SENT',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  NoAction = 'NO_ACTION',
  Posted = 'POSTED',
  Undefined = 'UNDEFINED'
}

export type PostingStatusEnumOperationFilterInput = {
  eq?: InputMaybe<PostingStatusEnum>;
  in?: InputMaybe<Array<PostingStatusEnum>>;
  neq?: InputMaybe<PostingStatusEnum>;
  nin?: InputMaybe<Array<PostingStatusEnum>>;
};

/** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
export enum PrefixPodatnika {
  /** Austria */
  At = 'AT',
  /** Belgia */
  Be = 'BE',
  /** Bułgaria */
  Bg = 'BG',
  /** Cypr */
  Cy = 'CY',
  /** Czechy */
  Cz = 'CZ',
  /** Niemcy */
  De = 'DE',
  /** Dania */
  Dk = 'DK',
  /** Estonia */
  Ee = 'EE',
  /** Grecja */
  El = 'EL',
  /** Hiszpania */
  Es = 'ES',
  /** Finlandia */
  Fi = 'FI',
  /** Francja */
  Fr = 'FR',
  /** Chorwacja */
  Hr = 'HR',
  /** Węgry */
  Hu = 'HU',
  /** Irlandia */
  Ie = 'IE',
  /** Włochy */
  It = 'IT',
  /** Litwa */
  Lt = 'LT',
  /** Luksemburg */
  Lu = 'LU',
  /** Łotwa */
  Lv = 'LV',
  /** Malta */
  Mt = 'MT',
  /** Holandia */
  Nl = 'NL',
  /** Polska */
  Pl = 'PL',
  /** Portugalia */
  Pt = 'PT',
  /** Rumunia */
  Ro = 'RO',
  /** Szwecja */
  Se = 'SE',
  /** Słowenia */
  Si = 'SI',
  /** Słowacja */
  Sk = 'SK',
  /** Irlandia Północna */
  Xi = 'XI'
}

/** Procedura VAT dla wiersza faktury */
export enum ProceduraVat {
  /** Stawka 0% stosowana w ramach sprzedaży krajowej */
  Procedura1 = 'PROCEDURA1',
  /** Stawka 0% - wewnątrzwspólnotowa dostawa towarów */
  Procedura2 = 'PROCEDURA2',
  /** Stawka 0% - eksport towarów */
  Procedura3 = 'PROCEDURA3',
  /** Dostawa towarów oraz świadczenie usług opodatkowane poza terytorium kraju */
  Procedura4 = 'PROCEDURA4',
  /** Świadczenie usług z art. 100 ust. 1 pkt 4 ustawy */
  Procedura5 = 'PROCEDURA5',
  /** Towar/usługa wymienione w załączniku 15 */
  Procedura6 = 'PROCEDURA6',
  /** Pozostała sprzedaż krajowa */
  Procedura7 = 'PROCEDURA7'
}

export enum ProcessTaskStatusEnum {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  NotStarted = 'NOT_STARTED',
  Pending = 'PENDING',
  Undefined = 'UNDEFINED'
}

export type PublishInvoicesResponse = {
  Failed: Scalars['Int'];
  Sent: Scalars['Int'];
};

export type Query = {
  ApiKeys: ApiKeysViewModelResponse;
  CloudModules: CloudViewModel;
  /** Lista firm w organizacji */
  Companies?: Maybe<CompaniesCollectionSegment>;
  /** Sprawdza czy firma istnieje */
  CompanyExists: CompanyExistsQueryResponse;
  /**
   * Zwraca statystyki faktur we wszystkich firmach w organizacji.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  CompanyStatistics: GetCompanyStatisticsResponse;
  /**
   * Zwraca wszystkie firmy w organizacji.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetCompaniesForOrganisation: GetCompaniesForOrganisationResponse;
  CompanyVehicles?: Maybe<CompanyVehiclesCollectionSegment>;
  /** Generator linków do AWS AppStream */
  CreateAppStreamLink: CreateAppStreamLinkQueryResponse;
  /** Informacja o bieżącym środowisku */
  CurrentEnvironment: CurrentEnvironmentViewModel;
  /** Informacja o bieżącym użytkowniku */
  CurrentUser: CurrentUserViewModel;
  GetAccounts: GetAccountsResponse;
  GetAccountsForPosting: GetAccountsForPostingResponse;
  /**
   * Query do pobierania załącznika o określonym Id korzystające z serwisu FileHub.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetAttachmentById: GetAttachmentByIdResponse;
  /**
   * Query zwracające link do pobrania załącznika o określonym Id korzystające z serwisu FileHub.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetAttachmentLink: GetAttachmentLinkResponse;
  /**
   * Query służące do pobierania globalnych ustawień załączników.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetAttachmentSettings: GetAttachmentSettingsResponse;
  /**
   * Query służące do pobierania załączników dla faktury korzystające z serwisu FileHub.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetAttachments: GetAttachmentsResponse;
  GetAutoPostingModuleStatus: GetAutoPostingModuleStatusQueryResponse;
  GetAutoPostingSettings: GetAutoPostingSettingsResponse;
  /** Inforacja o statusie backup'ów */
  GetBackupInfo: GetBackupInfoQueryResponse;
  /**
   * Pobiera wartości zdefiniowane w typie wyliczeniowym (enum) na podstawie podanej przez użytkownika nazwie w formie ciągu znaków.
   * Uwaga: Query `GetBitEnumValuesQuery` umożliwia pobieranie wartości wyłącznie dla enumów zdefiniowanych na predefiniowanej białej liście. Próba użycia metody z nazwą enuma nieobecną na liście zakończy się niepowodzeniem.
   *
   * EnvironmentId: [`Company`]({{Types.EnvironmentType.Company}})
   */
  GetBitEnumValues: GetBitEnumValuesResponse;
  GetCategoriesViewModel?: Maybe<GetCategoriesViewModelCollectionSegment>;
  GetCategoryRules: GetCategoryRulesResponse;
  GetCategoryRulesUsersAndPositions: GetCategoryRulesUsersAndPositionsResponse;
  GetCompanyVehicleById: GetCompanyVehicleByIdResponse;
  GetCompanyVehicles: GetCompanyVehiclesResponse;
  GetContractors: GetContractorsSegment;
  GetContractorsViewModel?: Maybe<GetContractorsViewModelCollectionSegment>;
  /**
   * Zwraca listę aktualnych wersji schematu faktury Symfonia KSeF.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetCurrentSchemaVersion: GetCurrentSchemaVersionResponse;
  GetDecreeDetails: GetDecreeDetailsQueryResponse;
  GetDocumentTypesSettings: GetDocumentTypesSettingsQueryResponse;
  /**
   * Zwraca listę włączonych notyfikacji e-mailowych dla użytkownika.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetEArchiveNotifications: GetEArchiveNotificationsResponse;
  /**
   * Pobiera wartości zdefiniowane w typie wyliczeniowym (enum) na podstawie podanej przez użytkownika nazwie w formie ciągu znaków.
   * Uwaga: Query `GetEnumValuesQuery` umożliwia pobieranie wartości wyłącznie dla enumów nie używanych jako zestawy bitowe. Enumy są zdefiniowane na predefiniowanej białej liście. Próba użycia metody z nazwą enuma nieobecną na liście zakończy się niepowodzeniem.
   *
   * EnvironmentId: [`Company`]({{Types.EnvironmentType.Company}})
   */
  GetEnumValues: GetEnumValuesResponse;
  GetFinancialYears: GetFinancialYearsResponse;
  /** Informacja o firmie z GUS */
  GetGusData: GusResponseData;
  GetInvoiceDocumentTypes: GetInvoiceDocumentTypesResponse;
  /**
   * Zwraca zbiorczy dokument ZIP z fakturami PDF, zgodnymi ze schematem obowiązującym w KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetInvoiceFileContentActionGroup: GetInvoiceFileContentActionGroupType;
  /**
   * Zwraca dokument PDF faktury zgodny ze schematem obowiązującym w KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetInvoiceFileContentSingle: GetInvoiceFileContentSingleType;
  /**
   * Pobiera listę faktur z Symfonia KSeF wg zadanych filtrów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetInvoices: GetInvoicesSegment;
  /**
   * Pobiera listę faktur z Symfonia KSeF wg zadanych filtrów
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetInvoicesExtended: GetInvoicesExtendedSegment;
  /**
   * Pobiera raport z przefiltrowanej listy faktur (csv, xlsx).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetInvoicesReport: InvoicesReportType;
  /**
   * Zwraca listę wystawców faktur z Symfonia KSeF.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetIssuerInvoices: GetIssuerInvoicesSegment;
  /**
   * Zwraca obiekt faktury w wersji domenowej Symfonii.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   * @deprecated Dotyczy nieobowiązującej wersji schemy FA(1)
   */
  GetKSeFInvoiceDTO: Faktura;
  /**
   * Zwraca obiekt faktury V2 w wersji domenowej Symfonii.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetKSeFInvoiceDTOV2: FakturaV2;
  /**
   * Zwraca dokument XML faktury zgodny ze schematem obowiązującym w KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetKSeFInvoiceXML: KSeFInvoiceXmlType;
  /**
   * Pobiera listę faktur wg kryteriów zadanych w filtrze.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetListInvoices: ListInvoicesResponseType;
  /**
   * Uruchamia ponowne wysłanie nieprzeczytanych notyfikacji websocket-owych.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetMissedWebsocketSubscriptions: GetMissedWebsocketSubscriptionsResponse;
  /**
   * Zwraca informacje o wykorzystaniu pakietu dla organizacji.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}}) / [`TENANT`]({{Types.EnvironmentType.TENANT}}) / [`ADMIN`]({{Types.EnvironmentType.ADMIN}})
   */
  GetPackageStatistic: GetPackageStatisticSegment;
  /**
   * Zwraca informacje o uprawnieniach bieżącego użytkownika,
   * zwracane są ostatnie zapisane w systemie uprawnienia dla tego użytkownika, a flaga FromKSeF przyjmuje wartość false.
   *
   * Uwaga! synchronizacja uprawnień z KSeF (fromKSeF = true) będzie wyłączona w następnej wersji API
   * Aby dokonać synchronizacji uprawnień użytkownika w KSeF MF należy użyć mutacji: SyncPermissionKSeFMutation
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetPermissionsKSeF: GetPermissionsKSeFResponseType;
  /**
   * Zwraca informacje o uprawnieniach bieżącego użytkownika w KSeF Ministerstwa Finansów w formacie csv lub xlsx.
   * Jeżeli połączenie z KSeF Ministerstwa Finansów zakończy się niepowodzeniem, zwracane są ostatnie zapisane w systemie uprawnienia dla tego użytkownika, a flaga FromKSeF przyjmuje wartość false.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetPermissionsKSeFReport: PermissionsKSeFReportType;
  GetPlatformNotifications: GetPlatformNotificationsResponse;
  GetPostingInvoicesReport: GetPostingInvoicesReportResponse;
  GetPostingInvoicesViewModel?: Maybe<GetPostingInvoicesViewModelCollectionSegment>;
  GetRecalculatedDecree: GetRecalculatedDecreeResponse;
  /**
   * Lista odbiorców faktur z Symfonia KSeF.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetRecipientInvoices: GetRecipientInvoicesSegment;
  GetRequiredConfigurationStatus: GetRequiredConfigurationStatusResponse;
  GetSettlements: GetSettlementsResponse;
  /**
   * Zwraca statystyki dla wybranej firmy.
   *
   * Response zawiera:
   * - Ilość faktur zakupowych (PurchaseInvoicesCount)
   * - Ilość faktur sprzedażowych (SalesInvoicesCount)
   * - Czy połączony z KSeF (HasKSeFAuthorization)
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}}) / [`TENANT`]({{Types.EnvironmentType.TENANT}})
   *
   *
   * **Returns:**
   * Statystyki dla wybranej firmy
   */
  GetStatisticsForCompany: GetStatisticsForCompanyResponse;
  /**
   * Pobiera Urzędowe Potwierdzenie Odbioru dla podanej listy faktur w formacie PDF lub XML.
   * Pobieranie może nastąpić tylko wtedy, kiedy sesja z KSeF Ministerstwa Finansów jest zamknięta, podczas otwartej sesji nie można pobrać UPO.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetUPO: UpoType;
  /**
   * Zwraca informacje o procesowaniu uprawnień bieżącego użytkownika w KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetUserKSeFRequestForCredentials: GetUserKSeFRequestForCredentialsResponseType;
  GetVatRegistries: GetVatRegistriesResponse;
  /**
   * Zwraca wynik operacji dla konkretnej notyfikacji WebSocket-owej. Wymaga podania ID notyfikacji.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetWebsocketNotificationModel: GetWebsocketNotificationModelResponse;
  /**
   * Zwraca wynik operacji dla konkretnej notyfikacji WebSocket-owej. Wymaga podania ID notyfikacji.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetWebsocketNotificationModelExtended: GetWebsocketNotificationModelExtendedResponse;
  /**
   * Zwraca listę notyfikacji WebSocket-owych o podanym statusie.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  GetWebsocketNotificationsList: GetWebsocketNotificationResponse;
  /**
   * Lista faktur w firmie w Symfonia KSeF.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  Invoices?: Maybe<InvoicesCollectionSegment>;
  IsCompanyConnected: IsCompanyConnectedQueryResponse;
  /**
   * Określa czy dla danej firmy trwa już proces pobierania faktur
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  IsDownloadInvoicesRunning: IsDownloadInvoicesRunningResult;
  /**
   * Zwraca informację o aktualnym stanie sesji w KSeF.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  KSeFSessionInfo: KSeFSessionStatusResponseType;
  LicensePlateExists: LicensePlateExistsResponse;
  /**
   * Pobiera listę wszystkich dostępnych środowisk dla bieżącego użytkownika.
   *
   * EnvironmentId: [`NONE`]({{Types.EnvironmentType.NONE}}) - nagłówek EnvironmentId nie jest wymagany
   */
  MyEnvironments: MyEnvironmentsViewModelResponse;
  /** Pobiera informacje o bieżącym użytkowniku. */
  MyUser: MyUserViewModelResponse;
  /** Pobiera aktualna wersje oraz hash commita */
  PlatformVersion: PlatformVersionQueryResponse;
  /**
   * Określa czy do pobrania UPO dla podanych fakur konieczne jest zamknięcie bieżącej sesji.
   * Zwraca true gdy konieczne jest wcześniejsze zakończenie sesji z KSeF w celu pobrania UPO.
   * Zwraca false gdy można rozpocząć pobieranie UPO.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  RequiresSessionTerminationForUPO: RequiresSessionTerminationForUpoResult;
  /** Lista ról przypisanych do bieżącego użytkownika */
  RoleAssignments?: Maybe<RoleAssignmentsCollectionSegment>;
  /** Lista ról */
  Roles?: Maybe<RolesCollectionSegment>;
  /**
   * Zwraca czas ostaniej aktualizacji sesji.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SessionStatus?: Maybe<Scalars['DateTime']>;
  /**
   * Zlecenie pobrania faktur z KSeF poprzez subskrypcję WebSocket.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SubscribeDownloadInvoicesFromKSeF: SubscribeDownloadInvoicesFromKSeFResponse;
  /**
   * Zlecenie pobrania UPO z KSeF.
   * Pobiera Urzędowe Potwierdzenie Odbioru dla podanej listy faktur w formacie PDF lub XML.
   * Pobieranie może nastąpić tylko wtedy, kiedy sesja z KSeF jest zamknięta. Po zleceniu pobrania UPO system zaczeka na zakończenie działań w bieżącej sesji KSeF i pobierze UPO.
   * Wynik zapytania należy sprawdzić poprzez query [`GetWebsocketNotificationModel`]({{Queries.GetWebsocketNotificationModel}}).
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}})
   */
  SubscribeGetUpo: SubscribeGetUpoResponse;
  TenantList: TenantListQueryResponse;
  /** Sprawdza czy Tenant istnieje o danym NIP */
  TenantNipExists: TenantNipExistsQueryResponse;
  /** Lista tenantów */
  Tenants?: Maybe<TenantsCollectionSegment>;
  TrialRequests: TrialRequestsResponse;
  /** Lista użytkowników organizacji */
  Users?: Maybe<UsersCollectionSegment>;
};


export type QueryCompaniesArgs = {
  order?: InputMaybe<Array<CompanySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyFilterInput>;
};


export type QueryCompanyExistsArgs = {
  Identifier: Scalars['String'];
  IdentifierType: IdentifierType;
  TenantId: Scalars['UUID'];
};


export type QueryCompanyStatisticsArgs = {
  SearchText?: InputMaybe<Scalars['String']>;
  TenantIdFilter?: InputMaybe<Scalars['UUID']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip?: InputMaybe<Scalars['Long']>;
  take?: InputMaybe<Scalars['Long']>;
};


export type QueryCompaniesForOrganisationArgs = {
  SearchText?: InputMaybe<Scalars['String']>;
  TenantIdFilter?: InputMaybe<Scalars['UUID']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip?: InputMaybe<Scalars['Long']>;
  take?: InputMaybe<Scalars['Long']>;
};


export type QueryCompanyVehiclesArgs = {
  CompanyId: Scalars['UUID'];
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<CompanyVehicleViewModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyVehicleViewModelFilterInput>;
};


export type QueryCreateAppStreamLinkArgs = {
  ApplicationId: Scalars['String'];
};


export type QueryGetAccountsArgs = {
  AccountType?: InputMaybe<AccountTypeEnum>;
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
};


export type QueryGetAccountsForPostingArgs = {
  AccountType?: InputMaybe<AccountTypeEnum>;
  CompanyId: Scalars['UUID'];
  DecreeId: Scalars['UUID'];
};


export type QueryGetAttachmentByIdArgs = {
  Id: Scalars['UUID'];
};


export type QueryGetAttachmentLinkArgs = {
  Id: Scalars['UUID'];
};


export type QueryGetAttachmentsArgs = {
  InvoiceId: Scalars['UUID'];
};


export type QueryGetAutoPostingSettingsArgs = {
  CompanyId: Scalars['UUID'];
};


export type QueryGetBitEnumValuesArgs = {
  EnumName?: InputMaybe<Scalars['String']>;
};


export type QueryGetCategoriesViewModelArgs = {
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<CategoryViewModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryViewModelFilterInput>;
};


export type QueryGetCategoryRulesArgs = {
  CompanyId: Scalars['UUID'];
  Filters?: InputMaybe<CategoryRulesFiltersInput>;
  FinancialYearId: Scalars['UUID'];
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
};


export type QueryGetCategoryRulesUsersAndPositionsArgs = {
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  IsActive: Scalars['Boolean'];
};


export type QueryGetCompanyVehicleByIdArgs = {
  VehicleId: Scalars['UUID'];
};


export type QueryGetCompanyVehiclesArgs = {
  CompanyId: Scalars['UUID'];
  Filters: CompanyVehiclesFiltersInput;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
};


export type QueryGetContractorsArgs = {
  ContractorFiltersFields?: InputMaybe<ContractorFiltersFieldsInput>;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
};


export type QueryGetContractorsViewModelArgs = {
  CompanyId: Scalars['UUID'];
  FromDecrees?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Array<ContractorViewModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContractorViewModelFilterInput>;
};


export type QueryGetDecreeDetailsArgs = {
  DecreeId: Scalars['UUID'];
};


export type QueryGetDocumentTypesSettingsArgs = {
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
};


export type QueryGetEnumValuesArgs = {
  EnumName?: InputMaybe<Scalars['String']>;
};


export type QueryGetFinancialYearsArgs = {
  CompanyId: Scalars['UUID'];
};


export type QueryGetGusDataArgs = {
  Nip: Scalars['String'];
};


export type QueryGetInvoiceDocumentTypesArgs = {
  FinancialYearId: Scalars['UUID'];
};


export type QueryGetInvoiceFileContentActionGroupArgs = {
  InvoiceIds: Array<Scalars['UUID']>;
  Token?: InputMaybe<Scalars['String']>;
};


export type QueryGetInvoiceFileContentSingleArgs = {
  InvoiceId: Scalars['UUID'];
  Token?: InputMaybe<Scalars['String']>;
};


export type QueryGetInvoicesArgs = {
  FiltersFields: FiltersFieldsInput;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
};


export type QueryGetInvoicesExtendedArgs = {
  FiltersFields: ExtendedFiltersFieldsInput;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
};


export type QueryGetInvoicesReportArgs = {
  FiltersFields: FiltersFieldsInput;
  ReportFileType: ReportFileTypeEnum;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
};


export type QueryGetIssuerInvoicesArgs = {
  FiltersFields: FiltersFieldsIssuerInput;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
};


export type QueryGetKSeFInvoiceDtoArgs = {
  InvoiceId: Scalars['UUID'];
};


export type QueryGetKSeFInvoiceDtov2Args = {
  InvoiceId: Scalars['UUID'];
};


export type QueryGetKSeFInvoiceXmlArgs = {
  InvoiceId: Scalars['UUID'];
};


export type QueryGetListInvoicesArgs = {
  FiltersFields: FilterFieldsInput;
};


export type QueryGetPackageStatisticArgs = {
  TenantId: Scalars['UUID'];
};


export type QueryGetPermissionsKSeFArgs = {
  CredentialFiltersFields?: InputMaybe<CredentialFiltersFieldsInput>;
  SearchText?: InputMaybe<Scalars['String']>;
  fromKSeF?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip?: InputMaybe<Scalars['Long']>;
  take?: InputMaybe<Scalars['Long']>;
};


export type QueryGetPermissionsKSeFReportArgs = {
  CredentialFiltersFields?: InputMaybe<CredentialFiltersFieldsInput>;
  ReportFileType: ReportFileTypeEnum;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip?: InputMaybe<Scalars['Long']>;
  take?: InputMaybe<Scalars['Long']>;
};


export type QueryGetPostingInvoicesReportArgs = {
  order?: InputMaybe<Array<GetPostingInvoicesReportSortInput>>;
  reportFileType: ReportFileTypeEnum;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GetPostingInvoicesReportFilterInput>;
};


export type QueryGetPostingInvoicesViewModelArgs = {
  CompanyId: Scalars['UUID'];
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<DecreeViewModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DecreeViewModelFilterInput>;
};


export type QueryGetRecalculatedDecreeArgs = {
  CategoryAccountChanges?: InputMaybe<Array<CategoryAccountChangeRequestInput>>;
  CompanyId: Scalars['UUID'];
  DecreeId: Scalars['UUID'];
  PositionAccountChanges?: InputMaybe<Array<PositionAccountChangeRequestInput>>;
  PositionCategoryChanges?: InputMaybe<Array<PositionCategoryChangeRequestInput>>;
  PositionVehicleUsageTypeChanges?: InputMaybe<Array<PositionVehicleUsageTypeChangeRequestInput>>;
};


export type QueryGetRecipientInvoicesArgs = {
  FiltersFields: FiltersFieldsRecipientsInput;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
};


export type QueryGetRequiredConfigurationStatusArgs = {
  CompanyId: Scalars['UUID'];
};


export type QueryGetSettlementsArgs = {
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
};


export type QueryGetStatisticsForCompanyArgs = {
  CompanyId: Scalars['UUID'];
};


export type QueryGetUpoArgs = {
  InvoiceIds: Array<Scalars['UUID']>;
  UpoFileType: UpoFileType;
};


export type QueryGetUserKSeFRequestForCredentialsArgs = {
  CredentialFiltersFields?: InputMaybe<RequestedCredentialFiltersFieldsInput>;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip?: InputMaybe<Scalars['Long']>;
  take?: InputMaybe<Scalars['Long']>;
};


export type QueryGetVatRegistriesArgs = {
  CompanyId: Scalars['UUID'];
};


export type QueryGetWebsocketNotificationModelArgs = {
  notificationId: Scalars['UUID'];
};


export type QueryGetWebsocketNotificationModelExtendedArgs = {
  notificationId: Scalars['UUID'];
};


export type QueryGetWebsocketNotificationsListArgs = {
  notificationStateType: WebsocketNotificationStateType;
};


export type QueryInvoicesArgs = {
  order?: InputMaybe<Array<InvoiceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InvoiceFilterInput>;
};


export type QueryLicensePlateExistsArgs = {
  CompanyId: Scalars['UUID'];
  RegistrationNumber: Scalars['String'];
};


export type QueryRequiresSessionTerminationForUpoArgs = {
  invoiceIds: Array<Scalars['UUID']>;
};


export type QueryRoleAssignmentsArgs = {
  order?: InputMaybe<Array<RoleAssignmentSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleAssignmentFilterInput>;
};


export type QueryRolesArgs = {
  order?: InputMaybe<Array<RoleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleFilterInput>;
};


export type QuerySubscribeDownloadInvoicesFromKSeFArgs = {
  external?: InputMaybe<DateTimeRangeInput>;
  externalEnabled: Scalars['Boolean'];
  internal?: InputMaybe<DateTimeRangeInput>;
  internalEnabled: Scalars['Boolean'];
};


export type QuerySubscribeGetUpoArgs = {
  InvoiceIds: Array<Scalars['UUID']>;
  UpoFileType: UpoFileType;
};


export type QueryTenantListArgs = {
  Email: Scalars['String'];
  Identifier: Scalars['String'];
};


export type QueryTenantNipExistsArgs = {
  Identifier: Scalars['String'];
};


export type QueryTenantsArgs = {
  order?: InputMaybe<Array<TenantSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TenantFilterInput>;
};


export type QueryUsersArgs = {
  order?: InputMaybe<Array<UserSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFilterInput>;
};

export type Recipient = {
  InvoiceBound: InvoiceBound;
  RecipientName: Scalars['String'];
  RecipientNip: Scalars['String'];
};

export type RefreshedInvoicesListType = {
  Result?: Maybe<Array<Scalars['UUID']>>;
};

export type RegistrationNumberCreated = {
  CompanyId: Scalars['UUID'];
  InvoiceId: Scalars['UUID'];
  RegistrationNumber?: Maybe<Scalars['String']>;
};

export type RejectTenantResponse = {
  Rejected: Scalars['Boolean'];
};

export type RemoveAuthorizationExternalResult = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

export type RemoveCompanyVehicleMutationResponse = {
  IsDone: Scalars['Boolean'];
};

/** Wynik operacji wyłączenia notyfikacji email dla użytkownika w firmie */
export type RemoveEArchiveNotificationsResponse = {
  /** true - operacja zakończona powodzeniem */
  Success: Scalars['Boolean'];
};

export type RemovePlatformNotificationsResponse = {
  Success: Scalars['Boolean'];
};

export enum ReportFileTypeEnum {
  Csv = 'CSV',
  Xlsx = 'XLSX'
}

/** Nowe dane organizacji */
export type RequestInput = {
  /** Organizacja jest biurem rachunkowym */
  AccountOffice?: InputMaybe<Scalars['Boolean']>;
  /** Dane adresowe */
  Address?: InputMaybe<AddressInput>;
  /** Id organizacji */
  Id: Scalars['UUID'];
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Nazwa organizacji */
  Name?: InputMaybe<Scalars['String']>;
  /** NIP UE */
  VatNumberUE?: InputMaybe<Scalars['String']>;
};

/** Odpowiedź */
export type RequestTrialResponse = {
  Success: Scalars['Boolean'];
};

/** Obiekt procesowanych uprawnień w KSeF */
export type RequestedCredential = {
  /** Data utworzenia */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** Uprawnienie do odbierania faktur */
  CredentialsInvoiceRead: Scalars['Boolean'];
  /** Uprawnienie do wysyłki faktur */
  CredentialsInvoiceWrite: Scalars['Boolean'];
  /** Uprawnienie do nadawania uprawnień */
  CredentialsManage: Scalars['Boolean'];
  /** Uprawnienie do odczytu uprawnień */
  CredentialsRead: Scalars['Boolean'];
  /** Uprawnienie do samofakturowania */
  CredentialsSelfInvoicing: Scalars['Boolean'];
  /** Numer identyfikacji ewidencyjnej/podatkowej firmy dla której użytkownik wysłał procesowanie uprawnień do KSeF */
  Identifier: Scalars['String'];
  /** Opis przetwarzania */
  ProcessingDescription?: Maybe<Scalars['String']>;
  /** Status operacji */
  ProcessingStatus: KSeFRequestForCredentialsProcessingStatus;
  /** Typ operacji */
  RequestType: KSeFRequestForCredentialType;
};

/** Filtr uprawnień */
export type RequestedCredentialFiltersFieldsInput = {
  /** Uprawnienie do odbierania faktur */
  CredentialsInvoiceRead?: InputMaybe<Scalars['Boolean']>;
  /** Uprawnienie do wysyłki faktur */
  CredentialsInvoiceWrite?: InputMaybe<Scalars['Boolean']>;
  /** Uprawnienie do nadawania uprawnień */
  CredentialsManage?: InputMaybe<Scalars['Boolean']>;
  /** Uprawnienie do odczytu uprawnień */
  CredentialsRead?: InputMaybe<Scalars['Boolean']>;
  /** Uprawnienie do samofakturowania */
  CredentialsSelfInvoicing?: InputMaybe<Scalars['Boolean']>;
  /** Lista numerów identyfikacji ewidencyjnej/podatkowej */
  Identifiers?: InputMaybe<Array<Scalars['String']>>;
};

export enum RequiredModulesToFillEnum {
  Category = 'CATEGORY',
  DocumentTypePurchase = 'DOCUMENT_TYPE_PURCHASE',
  DocumentTypeSales = 'DOCUMENT_TYPE_SALES',
  SettlementPurchase = 'SETTLEMENT_PURCHASE',
  SettlementSales = 'SETTLEMENT_SALES',
  Undefined = 'UNDEFINED'
}

/** Wynik operacji */
export type RequiresSessionTerminationForUpoResult = {
  Result: Scalars['Boolean'];
};

export type ResetModuleInitializationResponse = {
  IsDone: Scalars['Boolean'];
};

export type Response = {
  IsDone: Scalars['Boolean'];
};

/** Obiekt odpowiedzi notyfikacji */
export type ResponseModel = {
  /** Treść notyfikacji zakończenia żądania autoryzacji w KSeF MF */
  AuthorizedInKSeF?: Maybe<OnAuthorizedInKSeFResultUserMessage>;
  /** Treść notyfikacji zakończenia usuwania faktur */
  DeleteInvoices?: Maybe<OnDeleteInvoicesResultUserMessage>;
  /** Treść notyfikacji zakończenia żądania pobierania faktur */
  DownloadInvoices?: Maybe<OnDownloadInvoicesResultUserMessage>;
  /** Treść notyfikacji zakończenia żądania pobierania UPO */
  GetUPO?: Maybe<OnGetUpoResultUserMessage>;
  /** Treść notyfikacji zakończenia żądania nadania uprawnień */
  GrantPermission?: Maybe<OnGrantKSeFPermissionsResultUserMessage>;
  /** Treść notyfikacji zakończenia przetwarzania OCR */
  OcrInvoiceImported?: Maybe<OnOcrInvoiceImportedUserMessage>;
  /** Treść notyfikacji zakończenia wysylki dekretacji - zakończonej błędem */
  PostInvoicesFailed?: Maybe<OnPostInvoicesFailedUserMessage>;
  /** Treść notyfikacji zakończenia żądania nadania numeru ewidencyjnego */
  RegistrationNumberCreated?: Maybe<RegistrationNumberCreated>;
  /** Treść notyfikacji zakończenia żądania wysyłki faktur */
  SendingInvoices?: Maybe<OnSendingInvoicesResultUserMessage>;
  /** Treść notyfikacji zakończenia żądania zakończenia sesji KSeF MF */
  TerminateSession?: Maybe<OnTerminateKSeFSessionResultUserMessage>;
  /** Treść notyfikacji zakończenia żądania rozłączenia z KSeF MF */
  UnauthorizedInKsef?: Maybe<OnUnauthorizedKSeFResultUserMessage>;
  /** Treść notyfikacji zakończenia żądania importu XML */
  UploadInvoices?: Maybe<OnUploadInvoicesResultUserMessage>;
  /** Treść notyfikacji zakończenia walidacji kontrachentów na białej liście */
  WhiteListValidation?: Maybe<OnGetWhiteListItemsStatusUserMessage>;
};

export type RestoreBackupResponse = {
  Result?: Maybe<AppStreamBackupResponse>;
};

export type Result = {
  Result: Scalars['String'];
};

/** Rodzaj faktury określony przez Ministerstwo Finansów */
export enum RodzajFaktury {
  /** Faktura korygująca */
  Kor = 'KOR',
  /** Faktura korygująca fakturę wystawioną w związku z art. 106f ust. 3 ustawy */
  KorRoz = 'KOR_ROZ',
  /** Faktura korygująca fakturę dokumentującą otrzymanie zapłaty lub jej części przed dokonaniem czynności oraz fakturę wystawioną w związku z art. 106f ust. 4 ustawy */
  KorZal = 'KOR_ZAL',
  /** Faktura wystawiona w związku z art. 106f ust. 3 ustawy */
  Roz = 'ROZ',
  /** Faktura, o której mowa w art. 106e ust. 5 pkt 3 ustawy */
  Upr = 'UPR',
  /** Faktura podstawowa */
  Vat = 'VAT',
  /** Faktura dokumentująca otrzymanie zapłaty lub jej części przed dokonaniem czynności oraz faktura wystawiona w związku z art. 106f ust. 4 ustawy */
  Zal = 'ZAL'
}

export type RodzajFakturyOperationFilterInput = {
  eq?: InputMaybe<RodzajFaktury>;
  in?: InputMaybe<Array<RodzajFaktury>>;
  neq?: InputMaybe<RodzajFaktury>;
  nin?: InputMaybe<Array<RodzajFaktury>>;
};

/** Rodzaj zastosowanego transportu w przypadku dokonanej dostawy towarów */
export enum RodzajTransportu {
  /** Transport drogowy */
  Drogowy = 'DROGOWY',
  /** Stałe instalacje przesyłowe */
  InstalacjePrzesylowe = 'INSTALACJE_PRZESYLOWE',
  /** Transport kolejowy */
  Kolejowy = 'KOLEJOWY',
  /** Transport lotniczy */
  Lotniczy = 'LOTNICZY',
  /** Transport morski */
  Morski = 'MORSKI',
  /** Przesyłka pocztowa */
  PrzesylkaPocztowa = 'PRZESYLKA_POCZTOWA',
  /** Żegluga śródlądowa */
  ZeglugaSrodladowa = 'ZEGLUGA_SRODLADOWA'
}

/** Rola podmiotu trzeciego */
export enum RolaPodmiotu3 {
  /** Dodatkowy nabywca - w przypadku, gdy w fakturze występują dane kolejnych (innych niż wymieniony w sekcji Podmiot2) nabywców */
  DodatkowyNabywca = 'DODATKOWY_NABYWCA',
  /** Faktor - w przypadku, gdy w fakturze występują dane faktora */
  Faktor = 'FAKTOR',
  /** Odbiorca - w przypadku, gdy w fakturze występują dane jednostek wewnętrznych, oddziałów, jednostek budżetowych wyodrębnionych w ramach nabywcy, które same nie stanowią nabywcy w rozumieniu ustawy */
  Odbiorca = 'ODBIORCA',
  /** Dokonujący płatności - w przypadku, gdy w fakturze występują dane podmiotu regulującego zobowiązanie w miejsce nabywcy */
  Platnik = 'PLATNIK',
  /** Podmiot pierwotny - w przypadku, gdy w fakturze występują dane podmiotu będącego w stosunku do podatnika podmiotem przejętym lub przekształconym, który świadczył usługę lub dokonywał dostawy. Z wyłączeniem przypadków, o których mowa w art. 106j ust.2 pkt 3 ustawy, gdy dane te prezentowane są w sekcji Podmiot1K */
  PodmiotPierwotny = 'PODMIOT_PIERWOTNY',
  /** Wystawca faktury - w przypadku, gdy w fakturze występują dane podmiotu wystawiającego fakturę w imieniu podatnika. Nie dotyczy przypadku, gdy wystawcą faktury jest nabywca */
  WystawcaFaktury = 'WYSTAWCA_FAKTURY'
}

/** Rola podmiotu upoważnionego */
export enum RolaPodmiotuUpowaznionego {
  /** Organ egzekucyjny - w przypadku, o którym mowa w art. 106c pkt 1 ustawy */
  Item1 = 'ITEM1',
  /** Komornik sądowy - w przypadku, o którym mowa w art. 106c pkt 2 ustawy */
  Item2 = 'ITEM2',
  /** Przedstawiciel podatkowy - w przypadku, gdy w fakturze występują dane przedstawiciela podatkowego, o którym mowa w przepisach art. 18a - 18d ustawy */
  Item3 = 'ITEM3'
}

/** Rola */
export type Role = {
  /** Nazwa roli przezentowana w aplikacji */
  BusinessName: Scalars['String'];
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Nazwa roli */
  Name?: Maybe<Scalars['String']>;
  /** Nazwa systemu, którego dotyczy rola */
  SystemType: Scalars['String'];
};

/** Przypisanie roli */
export type RoleAssignment = {
  /** Obiekt Company */
  Company?: Maybe<Company>;
  /** Id firmy */
  CompanyId?: Maybe<Scalars['UUID']>;
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Rola */
  Role?: Maybe<Role>;
  /** Organizacja */
  Tenant?: Maybe<TenantResponse>;
  /** Id organizacji */
  TenantId: Scalars['UUID'];
  /** Użytkownik */
  User?: Maybe<User>;
  /** Id Użytkownika */
  UserId: Scalars['UUID'];
};

/** Przypisanie roli */
export type RoleAssignmentFilterInput = {
  /** Obiekt Company */
  Company?: InputMaybe<CompanyFilterInput>;
  /** Id firmy */
  CompanyId?: InputMaybe<UuidOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  /** Rola */
  Role?: InputMaybe<RoleFilterInput>;
  /** Organizacja */
  Tenant?: InputMaybe<TenantFilterInput>;
  /** Id organizacji */
  TenantId?: InputMaybe<UuidOperationFilterInput>;
  /** Użytkownik */
  User?: InputMaybe<UserFilterInput>;
  /** Id Użytkownika */
  UserId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<RoleAssignmentFilterInput>>;
  or?: InputMaybe<Array<RoleAssignmentFilterInput>>;
};

/** Przypisanie roli */
export type RoleAssignmentInput = {
  /** Obiekt Company */
  Company?: InputMaybe<CreateCompanyRequest>;
  /** Id firmy */
  CompanyId?: InputMaybe<Scalars['UUID']>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<Scalars['String']>;
  /** Rola */
  Role?: InputMaybe<CreateRoleRequest>;
  /** Organizacja */
  Tenant?: InputMaybe<TenantInput>;
  /** Id organizacji */
  TenantId: Scalars['UUID'];
  /** Użytkownik */
  User?: InputMaybe<UserInput>;
  /** Id Użytkownika */
  UserId: Scalars['UUID'];
};

/** Przypisanie roli */
export type RoleAssignmentSortInput = {
  /** Obiekt Company */
  Company?: InputMaybe<CompanySortInput>;
  /** Id firmy */
  CompanyId?: InputMaybe<SortEnumType>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<SortEnumType>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<SortEnumType>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<SortEnumType>;
  /** Rola */
  Role?: InputMaybe<RoleSortInput>;
  /** Organizacja */
  Tenant?: InputMaybe<TenantSortInput>;
  /** Id organizacji */
  TenantId?: InputMaybe<SortEnumType>;
  /** Użytkownik */
  User?: InputMaybe<UserSortInput>;
  /** Id Użytkownika */
  UserId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type RoleAssignmentsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<RoleAssignment>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Rola */
export type RoleFilterInput = {
  /** Nazwa roli przezentowana w aplikacji */
  BusinessName?: InputMaybe<StringOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  /** Nazwa roli */
  Name?: InputMaybe<StringOperationFilterInput>;
  /** Nazwa systemu, którego dotyczy rola */
  SystemType?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<RoleFilterInput>>;
  or?: InputMaybe<Array<RoleFilterInput>>;
};

export type RoleInfo = {
  Id: Scalars['UUID'];
  Name?: Maybe<Scalars['String']>;
};

/** Rola */
export type RoleSortInput = {
  /** Nazwa roli przezentowana w aplikacji */
  BusinessName?: InputMaybe<SortEnumType>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<SortEnumType>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<SortEnumType>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<SortEnumType>;
  /** Nazwa roli */
  Name?: InputMaybe<SortEnumType>;
  /** Nazwa systemu, którego dotyczy rola */
  SystemType?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type RolesCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<Role>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** Rozliczenie na fakturze */
export type Rozliczenie = {
  /** Obciążenia ujęte na fakturze */
  Obciazenia?: Maybe<Array<Maybe<Obciazenie>>>;
  /** Odliczenia ujęte na fakturze */
  Odliczenia?: Maybe<Array<Maybe<Odliczenie>>>;
};

/** Rozliczenie na fakturze */
export type RozliczenieInput = {
  /** Obciążenia ujęte na fakturze */
  Obciazenia?: InputMaybe<Array<InputMaybe<ObciazenieInput>>>;
  /** Odliczenia ujęte na fakturze */
  Odliczenia?: InputMaybe<Array<InputMaybe<OdliczenieInput>>>;
};

export type SaveAutoPostingSettingsMutationResponse = {
  IsDone: Scalars['Boolean'];
};

export type SaveCategoriesAccountsResponse = {
  IsDone: Scalars['Boolean'];
};

export type SaveChangesOnDecreeResponse = {
  IsChanged: Scalars['Boolean'];
};

export type SaveDocumentTypeSettingsMutationResponse = {
  IsDone: Scalars['Boolean'];
};

export type SaveSettlementsResponse = {
  IsDone: Scalars['Boolean'];
};

export type SendInvoiceAttachmentsEmailResponse = {
  IsDone: Scalars['Boolean'];
};

/** Wynik operacji wysłania pakietu faktur do Symfonia KSeF */
export type SendToEArchiveBatchV2ResponseType = {
  /** Lista faktur, których nie udało się zapisać w EArchiwum */
  ErrorItems?: Maybe<Array<Maybe<BatchResponseItem>>>;
  /** Lista faktur, która została zapisana w EArchiwum */
  SuccessItems?: Maybe<Array<Maybe<BatchResponseItem>>>;
};

/** Wynik operacji wysłania faktury do Symfonia KSeF */
export type SendToEArchiveResponseType = {
  /** Nadany unikalny numer faktury */
  Numer: Scalars['UUID'];
};

/** Wynik operacji wysłania faktury do Symfonia KSeF */
export type SendToEArchiveV2ResponseType = {
  /** Nadany unikalny numer faktury */
  Numer: Scalars['UUID'];
};

/** Zmiana statusu faktury na oczekujący na wysyłkę do KSeF */
export type SendToKSeFResponseType = {
  /** Id faktury */
  InvoiceId: Scalars['UUID'];
  /** Status faktury w KSeF */
  KSeFStatus: KSeFStatus;
};

/** Informacja o fakturze */
export type SendingInvoiceItemMessage = {
  /** Id faktury */
  invoiceId: Scalars['UUID'];
  /** Data faktury w KSeF */
  kSeFDate?: Maybe<Scalars['DateTime']>;
  /** Numer faktury w KSeF */
  kSeFNumber?: Maybe<Scalars['String']>;
  /** Status w KSeF */
  kSeFStatus: KSeFStatus;
  /** Opis błędu nadany przez system KSeF, o ile wystąpił */
  kSeFStatusErrorDescription?: Maybe<Scalars['String']>;
};

/** Wynik operacji włączania notyfikacji email dla użytkownika w firmie */
export type SetEArchiveNotificationsResponse = {
  /** true - operacja zakończona powodzeniem */
  Success: Scalars['Boolean'];
};

export type SetKSeFInvoiceDownloadedResponseType = {
  Success: Scalars['Boolean'];
};

export type SetPlatformNotificationsResponse = {
  Success: Scalars['Boolean'];
};

export enum SetSubscriptionErrorType {
  MultipleTenants = 'MULTIPLE_TENANTS',
  None = 'NONE',
  NoTenant = 'NO_TENANT',
  Unknown = 'UNKNOWN'
}

export type SetSubscriptionResponseType = {
  ErrorMessage?: Maybe<Scalars['String']>;
  ErrorType: SetSubscriptionErrorType;
  Success: Scalars['Boolean'];
};

export type SetSubscriptionTenantResponseType = {
  Success: Scalars['Boolean'];
};

/** Wynik operacji aktualizacji kontrahentów */
export type SetSupplierTypeHandlerResponse = {
  Result: Scalars['Boolean'];
};

/** Wynik operacji oznaczenia notyfikacji WebSocket-owej jako przeczytana */
export type SetWebsocketNotificationAcknowledgeType = {
  /** true - operacja zakończona powodzeniem */
  Success: Scalars['Boolean'];
};

export enum SettingTypeEnum {
  AcceptBeforeSendToFk = 'ACCEPT_BEFORE_SEND_TO_FK',
  AutoPostingOfInvoices = 'AUTO_POSTING_OF_INVOICES',
  Undefined = 'UNDEFINED'
}

export type SettlementResponse = {
  AccountId?: Maybe<Scalars['UUID']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNumber?: Maybe<Scalars['String']>;
  Id: Scalars['UUID'];
  IsRequired: Scalars['Boolean'];
  Name: Scalars['String'];
  SettlementType: SettlementTypeEnum;
};

export enum SettlementTypeEnum {
  DomesticRecipient = 'DOMESTIC_RECIPIENT',
  DomesticRecipientIssuingAnInvoiceInACurrencyOtherThanPln = 'DOMESTIC_RECIPIENT_ISSUING_AN_INVOICE_IN_A_CURRENCY_OTHER_THAN_PLN',
  DomesticSupplier = 'DOMESTIC_SUPPLIER',
  DomesticSupplierIssuingAnInvoiceInACurrencyOtherThanPln = 'DOMESTIC_SUPPLIER_ISSUING_AN_INVOICE_IN_A_CURRENCY_OTHER_THAN_PLN',
  ForeignRecipient = 'FOREIGN_RECIPIENT',
  ForeignSupplier = 'FOREIGN_SUPPLIER',
  RelatedForeignRecipient = 'RELATED_FOREIGN_RECIPIENT',
  RelatedForeignSupplier = 'RELATED_FOREIGN_SUPPLIER',
  RelatedRecipient = 'RELATED_RECIPIENT',
  RelatedSupplier = 'RELATED_SUPPLIER',
  Undefined = 'UNDEFINED',
  Vat = 'VAT'
}

export type SettlementTypeEnumOperationFilterInput = {
  eq?: InputMaybe<SettlementTypeEnum>;
  in?: InputMaybe<Array<SettlementTypeEnum>>;
  neq?: InputMaybe<SettlementTypeEnum>;
  nin?: InputMaybe<Array<SettlementTypeEnum>>;
};

export type SettlementsViewModel = {
  Accounts: Array<AccountViewModel>;
  Id: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  IsRequired: Scalars['Boolean'];
  Name: Scalars['String'];
  SettlementType: SettlementTypeEnum;
};

export type SettlementsViewModelFilterInput = {
  Accounts?: InputMaybe<ListFilterInputTypeOfAccountViewModelFilterInput>;
  Id?: InputMaybe<UuidOperationFilterInput>;
  InvoiceTypeGroup?: InputMaybe<InvoiceTypeGroupEnumOperationFilterInput>;
  IsRequired?: InputMaybe<BooleanOperationFilterInput>;
  Name?: InputMaybe<StringOperationFilterInput>;
  SettlementType?: InputMaybe<SettlementTypeEnumOperationFilterInput>;
  and?: InputMaybe<Array<SettlementsViewModelFilterInput>>;
  or?: InputMaybe<Array<SettlementsViewModelFilterInput>>;
};

export type SharedAccess = {
  /** Adres email użytkownika który ostatni modyfikował działanie usługi */
  LastModifiedBy?: Maybe<Scalars['String']>;
  /** Data ostatniej modyfikacji */
  LastModifiedDate?: Maybe<Scalars['DateTime']>;
  /** Stan usługi współdzieleinia dostępu do pobierania faktur */
  Status: Scalars['Boolean'];
  /** Id użytkownika który udostępnia dostęp do pobierania faktur */
  UserId?: Maybe<Scalars['UUID']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Serwis tworzący organizację */
export enum SourceServiceEnum {
  /** Symfonia KSeF */
  Admin = 'ADMIN',
  /** eBiuro */
  Ebiuro = 'EBIURO'
}

export type SourceServiceEnumOperationFilterInput = {
  eq?: InputMaybe<SourceServiceEnum>;
  in?: InputMaybe<Array<SourceServiceEnum>>;
  neq?: InputMaybe<SourceServiceEnum>;
  nin?: InputMaybe<Array<SourceServiceEnum>>;
};

export type StandardWebsocketNotification = {
  errorContent?: Maybe<Array<KeyValuePairOfStringAndString>>;
  errorType?: Maybe<WebsocketErrorType>;
  message?: Maybe<Scalars['String']>;
  notificationId: Scalars['UUID'];
  state: SubscriptionStateEnum;
  timestamp: Scalars['DateTime'];
  type: WebsocketNotificationType;
  visited: Scalars['Boolean'];
};

/** Odpowiedź informująca, czy zasubskrybowana operacja została rozpoczęta */
export type StandardWebsocketResponse = {
  /** Obiekt notyfikacji websocket */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Status zasubskrybowanej operacji */
  Result: SubscriptionStateEnum;
};

/** Stawki podatku wg. ustawy o VAT */
export enum StawkaPodatku {
  /** Stawka VAT 0% */
  Vat_0 = 'VAT_0',
  /** Stawka VAT 3% */
  Vat_3 = 'VAT_3',
  /** Stawka VAT 4% */
  Vat_4 = 'VAT_4',
  /** Stawka VAT 5% */
  Vat_5 = 'VAT_5',
  /** Stawka VAT 7% */
  Vat_7 = 'VAT_7',
  /** Stawka VAT 8% */
  Vat_8 = 'VAT_8',
  /** Stawka VAT 22% */
  Vat_22 = 'VAT_22',
  /** Stawka VAT 23% */
  Vat_23 = 'VAT_23',
  /** Niepodlegające opodatkowaniu-transakcje dostawy towarów oraz świadczenia usług poza terytorium kraju */
  VatNp = 'VAT_NP',
  /** Odwrotne obciążenie */
  VatOo = 'VAT_OO',
  /** Stawka podatku od wartości dodanej w przypadku, o którym mowa w dziale XII w rozdziale 6a ustawy */
  VatOss = 'VAT_OSS',
  /** Zwolnione z opodatkowania */
  VatZw = 'VAT_ZW'
}

/** Numery podmiotu lub grupy podmiotów w innych rejestrach i bazach danych */
export type Stopka = {
  /** Numer w Bazie Danych o Odpadach */
  BDO?: Maybe<Scalars['String']>;
  /** Informacje */
  Informacje?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Numer Krajowego Rejestru Sądowego */
  KRS?: Maybe<Scalars['String']>;
  /** Pełna nazwa */
  PelnaNazwa?: Maybe<Scalars['String']>;
  /** REGON */
  REGON?: Maybe<Scalars['String']>;
};

/** Numery podmiotu lub grupy podmiotów w innych rejestrach i bazach danych */
export type StopkaInput = {
  /** Numer w Bazie Danych o Odpadach */
  BDO?: InputMaybe<Scalars['String']>;
  /** Informacje */
  Informacje?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Numer Krajowego Rejestru Sądowego */
  KRS?: InputMaybe<Scalars['String']>;
  /** Pełna nazwa */
  PelnaNazwa?: InputMaybe<Scalars['String']>;
  /** REGON */
  REGON?: InputMaybe<Scalars['String']>;
};

/** Element Rejestr */
export type StopkaRejestrV2 = {
  /** Numer w Bazie Danych o Odpadach */
  BDO?: Maybe<Scalars['String']>;
  /** Numer Krajowego Rejestru Sądowego */
  KRS?: Maybe<Scalars['String']>;
  /** Pełna nazwa */
  PelnaNazwa?: Maybe<Scalars['String']>;
  /** REGON */
  REGON?: Maybe<Scalars['String']>;
};

/** Element Rejestr */
export type StopkaRejestrV2Input = {
  /** Numer w Bazie Danych o Odpadach */
  BDO?: InputMaybe<Scalars['String']>;
  /** Numer Krajowego Rejestru Sądowego */
  KRS?: InputMaybe<Scalars['String']>;
  /** Pełna nazwa */
  PelnaNazwa?: InputMaybe<Scalars['String']>;
  /** REGON */
  REGON?: InputMaybe<Scalars['String']>;
};

/** Element Stopka */
export type StopkaV2 = {
  /** Informacje */
  Informacje?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Lista rejestrów */
  Rejestry?: Maybe<Array<Maybe<StopkaRejestrV2>>>;
};

/** Element Stopka */
export type StopkaV2Input = {
  /** Informacje */
  Informacje?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Lista rejestrów */
  Rejestry?: InputMaybe<Array<InputMaybe<StopkaRejestrV2Input>>>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SubscribeDownloadInvoicesFromKSeFResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

export type SubscribeGetUpoResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

export type SubscribeGrantPermissionsInKSeFResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

export type SubscribeWhiteListCheckResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

/** Wykupiona subskrypcja przez organizację */
export type Subscription = {
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Numer klienta */
  CustomerNumber?: Maybe<Scalars['String']>;
  /** Data rozpoczęcia subskrypcji */
  DateFrom: Scalars['Date'];
  /** Data ważności subskrypcji */
  DateTo: Scalars['Date'];
  /** Adres e-mail administratora organizacji */
  Email?: Maybe<Scalars['String']>;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Lista pakietów zawartych w subskrypcji */
  Packages?: Maybe<Array<Maybe<Package>>>;
  /** Numer seryjny */
  SerialNumber?: Maybe<Scalars['String']>;
  /** Status subskrypcji */
  Status: SubscriptionStatus;
  /** Numer subskrypcji */
  SubscriptionNumber?: Maybe<Scalars['String']>;
  /** Rodzaj subskrypcji */
  SubscriptionType: SubscriptionType;
  /** Id Organizacji */
  TenantId?: Maybe<Scalars['UUID']>;
  /** Unikalny identyfikator pakietu */
  UniqueSubscriptionId: Scalars['UUID'];
};

/** Stan uruchomienia zasubskrybowanej operacji */
export enum SubscriptionStateEnum {
  /** Zablokowana do czasu ukończenia innych, zależnych operacji */
  Blocked = 'BLOCKED',
  /** W trakcie anulowania operacji zleconej przez użytkownika */
  CancellationInProgress = 'CANCELLATION_IN_PROGRESS',
  /** Operacja anulowana przez użytkownika */
  Cancelled = 'CANCELLED',
  /** Oczekująca */
  Enqueued = 'ENQUEUED',
  /** Ukończona */
  Finished = 'FINISHED',
  /** Domyślny */
  None = 'NONE',
  /** W toku */
  Processing = 'PROCESSING'
}

/** Status subskrypcji */
export enum SubscriptionStatus {
  /** Aktywny */
  Active = 'ACTIVE',
  /** Nieaktywny */
  Inactive = 'INACTIVE',
  /** Zawieszony */
  Suspended = 'SUSPENDED'
}

/** Typ subskrypcji */
export enum SubscriptionType {
  /** Dedykowany pakiet biznesowy */
  Enterprise = 'ENTERPRISE',
  /** Pakiet darmowy */
  Freemium = 'FREEMIUM',
  /** Pakiet płatny */
  Paid = 'PAID',
  /** Pakiet z przedpłatą */
  Prepaid = 'PREPAID'
}

/** Typ kontrahenta jako dostawcy towaru/usługi */
export enum SupplierType {
  /** Kosztowy */
  Cost = 'COST',
  /** Handlowy */
  Trading = 'TRADING',
  /** Nie zdefiniowany */
  Undefined = 'UNDEFINED'
}

/** Ustawienia kontrahenta jako dostawcy */
export type SupplierTypeItemInput = {
  /** Numer identyfikacji ewidencyjnej/podatkowej kontrahenta */
  Identifier?: InputMaybe<Scalars['String']>;
  /** Typ dostawcy */
  SupplierType: Scalars['Int'];
};

/** Systemy Symfonii, które mają dostęp do faktur w Symfonia KSeF */
export enum SymfoniaSystem {
  /** Księgowość */
  Fk = 'FK',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  Fksod = 'FKSOD',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  FksoDeBiuro = 'FKSODeBiuro',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  FKeBiuro = 'FKeBiuro',
  /** Handel */
  Han = 'HAN',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  Hanfk = 'HANFK',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  Hanfksod = 'HANFKSOD',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  HanfksoDeBiuro = 'HANFKSODeBiuro',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  HanfKeBiuro = 'HANFKeBiuro',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  Hansod = 'HANSOD',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  HansoDeBiuro = 'HANSODeBiuro',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  HaNeBiuro = 'HANeBiuro',
  /** SOD */
  Sod = 'SOD',
  /** Wartość serwisowa, nieprzeznaczona do użytku bezpośrednio w kodzie */
  SoDeBiuro = 'SODeBiuro',
  /** Wartość domyślna */
  Undefined = 'UNDEFINED',
  /** eBiuro */
  EBiuro = 'eBiuro'
}

export type SyncPermissionKSeFResponseType = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

/** Dane identyfikacyjne podatnika */
export type TDaneIdentyfikacyjne = {
  /** Pierwsze imię */
  ImiePierwsze?: Maybe<Scalars['String']>;
  /** Numer NIP */
  NIP?: Maybe<Scalars['String']>;
  /** Nazwisko */
  Nazwisko?: Maybe<Scalars['String']>;
  /** Pełna nazwa */
  PelnaNazwa?: Maybe<Scalars['String']>;
  /** Numer PESEL */
  Pesel?: Maybe<Scalars['String']>;
};

/** Dane identyfikacyjne podatnika */
export type TDaneIdentyfikacyjneInput = {
  /** Pierwsze imię */
  ImiePierwsze?: InputMaybe<Scalars['String']>;
  /** Numer NIP */
  NIP?: InputMaybe<Scalars['String']>;
  /** Nazwisko */
  Nazwisko?: InputMaybe<Scalars['String']>;
  /** Pełna nazwa */
  PelnaNazwa?: InputMaybe<Scalars['String']>;
  /** Numer PESEL */
  Pesel?: InputMaybe<Scalars['String']>;
};

/** Element Dane identyfikacyjne podmiotu trzeciego */
export type TDaneIdentyfikacyjneTrzeciPodmiotV2 = {
  /** Podmiot nie posiada identyfikatora podatkowego lub identyfikator nie występuje na fakturze: 1- tak */
  BrakID?: Maybe<Scalars['Boolean']>;
  /** Identyfikator wewnętrzny z NIP */
  IDWew?: Maybe<Scalars['String']>;
  /** Kod kraju nadania identyfikatora podatkowego */
  KodKraju?: Maybe<KodKraju>;
  /** Kod (prefiks) nabywcy VAT UE, o którym mowa w art. 106e ust. 1 pkt 24 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 4 ustawy */
  KodUE?: Maybe<PrefixPodatnika>;
  /** Numer NIP */
  NIP?: Maybe<Scalars['String']>;
  /**
   * Identyfikator podatkowy inny
   * Pattern: [a-zA-Z0-9]{1,50}.
   */
  NrID?: Maybe<Scalars['String']>;
  /**
   * Numer Identyfikacyjny VAT kontrahenta UE
   * Pattern: (\d|[A-Z]|\+|\*){1,12}.
   */
  NrVatUE?: Maybe<Scalars['String']>;
  /**
   * Imię i nazwisko lub nazwa
   * Typ znakowy ograniczony do 512 znaków
   * Minimum length: 1.
   * Maximum length: 512.
   */
  PelnaNazwa?: Maybe<Scalars['String']>;
};

/** Element Dane identyfikacyjne podmiotu trzeciego */
export type TDaneIdentyfikacyjneTrzeciPodmiotV2Input = {
  /** Podmiot nie posiada identyfikatora podatkowego lub identyfikator nie występuje na fakturze: 1- tak */
  BrakID?: InputMaybe<Scalars['Boolean']>;
  /** Identyfikator wewnętrzny z NIP */
  IDWew?: InputMaybe<Scalars['String']>;
  /** Kod kraju nadania identyfikatora podatkowego */
  KodKraju?: InputMaybe<KodKraju>;
  /** Kod (prefiks) nabywcy VAT UE, o którym mowa w art. 106e ust. 1 pkt 24 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 4 ustawy */
  KodUE?: InputMaybe<PrefixPodatnika>;
  /** Numer NIP */
  NIP?: InputMaybe<Scalars['String']>;
  /**
   * Identyfikator podatkowy inny
   * Pattern: [a-zA-Z0-9]{1,50}.
   */
  NrID?: InputMaybe<Scalars['String']>;
  /**
   * Numer Identyfikacyjny VAT kontrahenta UE
   * Pattern: (\d|[A-Z]|\+|\*){1,12}.
   */
  NrVatUE?: InputMaybe<Scalars['String']>;
  /**
   * Imię i nazwisko lub nazwa
   * Typ znakowy ograniczony do 512 znaków
   * Minimum length: 1.
   * Maximum length: 512.
   */
  PelnaNazwa?: InputMaybe<Scalars['String']>;
};

/** Dane identyfikacyjne podatnika */
export type TDaneIdentyfikacyjneV2 = {
  /** Podmiot nie posiada identyfikatora podatkowego lub identyfikator nie występuje na fakturze: 1- tak */
  BrakID?: Maybe<Scalars['Boolean']>;
  /** Kod kraju nadania identyfikatora podatkowego */
  KodKraju?: Maybe<KodKraju>;
  /** Kod (prefiks) nabywcy VAT UE, o którym mowa w art. 106e ust. 1 pkt 24 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 4 ustawy */
  KodUE?: Maybe<PrefixPodatnika>;
  /** Numer NIP */
  NIP?: Maybe<Scalars['String']>;
  /**
   * Identyfikator podatkowy inny
   * Pattern: [a-zA-Z0-9]{1,50}.
   */
  NrID?: Maybe<Scalars['String']>;
  /**
   * Numer Identyfikacyjny VAT kontrahenta UE
   * Pattern: (\d|[A-Z]|\+|\*){1,12}.
   */
  NrVatUE?: Maybe<Scalars['String']>;
  /**
   * Imię i nazwisko lub nazwa
   * Typ znakowy ograniczony do 512 znaków
   * Minimum length: 1.
   * Maximum length: 512.
   */
  PelnaNazwa?: Maybe<Scalars['String']>;
};

/** Dane identyfikacyjne podatnika */
export type TDaneIdentyfikacyjneV2Input = {
  /** Podmiot nie posiada identyfikatora podatkowego lub identyfikator nie występuje na fakturze: 1- tak */
  BrakID?: InputMaybe<Scalars['Boolean']>;
  /** Kod kraju nadania identyfikatora podatkowego */
  KodKraju?: InputMaybe<KodKraju>;
  /** Kod (prefiks) nabywcy VAT UE, o którym mowa w art. 106e ust. 1 pkt 24 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 4 ustawy */
  KodUE?: InputMaybe<PrefixPodatnika>;
  /** Numer NIP */
  NIP?: InputMaybe<Scalars['String']>;
  /**
   * Identyfikator podatkowy inny
   * Pattern: [a-zA-Z0-9]{1,50}.
   */
  NrID?: InputMaybe<Scalars['String']>;
  /**
   * Numer Identyfikacyjny VAT kontrahenta UE
   * Pattern: (\d|[A-Z]|\+|\*){1,12}.
   */
  NrVatUE?: InputMaybe<Scalars['String']>;
  /**
   * Imię i nazwisko lub nazwa
   * Typ znakowy ograniczony do 512 znaków
   * Minimum length: 1.
   * Maximum length: 512.
   */
  PelnaNazwa?: InputMaybe<Scalars['String']>;
};

/** Element Dane Kontaktowe */
export type TDaneKontaktoweV2 = {
  /**
   * Adres e-mail
   * Minimum length: 3.
   * Maximum length: 255.
   * Pattern: (.)+@(.)+.
   */
  Email?: Maybe<Scalars['String']>;
  /**
   * Numer telefonu
   * Maximum length: 16.
   */
  Telefon?: Maybe<Scalars['String']>;
};

/** Element Dane Kontaktowe */
export type TDaneKontaktoweV2Input = {
  /**
   * Adres e-mail
   * Minimum length: 3.
   * Maximum length: 255.
   * Pattern: (.)+@(.)+.
   */
  Email?: InputMaybe<Scalars['String']>;
  /**
   * Numer telefonu
   * Maximum length: 16.
   */
  Telefon?: InputMaybe<Scalars['String']>;
};

/** Procedura marży */
export type TMarza = {
  /**
   * W przypadku dostawy przedmiotów kolekcjonerskich i antyków, dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - przedmioty kolekcjonerskie i antyki
   */
  Antyki: Scalars['Boolean'];
  /**
   * W przypadku świadczenia usług turystyki, dla których podstawę opodatkowania stanowi marża, zgodnie z art. 119 ust. 1 ustawy,
   * a faktura dokumentująca świadczenie zawiera wyrazy procedura marży dla biur podróży
   */
  BiuroPodrozy: Scalars['Boolean'];
  /**
   * W przypadku dostawy dzieł sztuki dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - dzieła sztuki
   */
  DzieloSztuki: Scalars['Boolean'];
  /**
   * W przypadku dostawy towarów używanych dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - towary używane
   */
  TowarUzywany: Scalars['Boolean'];
};

/** Procedura marży */
export type TMarzaInput = {
  /**
   * W przypadku dostawy przedmiotów kolekcjonerskich i antyków, dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - przedmioty kolekcjonerskie i antyki
   */
  Antyki: Scalars['Boolean'];
  /**
   * W przypadku świadczenia usług turystyki, dla których podstawę opodatkowania stanowi marża, zgodnie z art. 119 ust. 1 ustawy,
   * a faktura dokumentująca świadczenie zawiera wyrazy procedura marży dla biur podróży
   */
  BiuroPodrozy: Scalars['Boolean'];
  /**
   * W przypadku dostawy dzieł sztuki dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - dzieła sztuki
   */
  DzieloSztuki: Scalars['Boolean'];
  /**
   * W przypadku dostawy towarów używanych dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - towary używane
   */
  TowarUzywany: Scalars['Boolean'];
};

/** Procedura marży */
export type TMarzaV2 = {
  /**
   * W przypadku dostawy przedmiotów kolekcjonerskich i antyków, dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - przedmioty kolekcjonerskie i antyki
   */
  Antyki: Scalars['Boolean'];
  /**
   * W przypadku świadczenia usług turystyki, dla których podstawę opodatkowania stanowi marża, zgodnie z art. 119 ust. 1 ustawy,
   * a faktura dokumentująca świadczenie zawiera wyrazy procedura marży dla biur podróży
   */
  BiuroPodrozy: Scalars['Boolean'];
  /**
   * W przypadku dostawy dzieł sztuki dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - dzieła sztuki
   */
  DzieloSztuki: Scalars['Boolean'];
  /**
   * W przypadku dostawy towarów używanych dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - towary używane
   */
  TowarUzywany: Scalars['Boolean'];
};

/** Procedura marży */
export type TMarzaV2Input = {
  /**
   * W przypadku dostawy przedmiotów kolekcjonerskich i antyków, dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - przedmioty kolekcjonerskie i antyki
   */
  Antyki: Scalars['Boolean'];
  /**
   * W przypadku świadczenia usług turystyki, dla których podstawę opodatkowania stanowi marża, zgodnie z art. 119 ust. 1 ustawy,
   * a faktura dokumentująca świadczenie zawiera wyrazy procedura marży dla biur podróży
   */
  BiuroPodrozy: Scalars['Boolean'];
  /**
   * W przypadku dostawy dzieł sztuki dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - dzieła sztuki
   */
  DzieloSztuki: Scalars['Boolean'];
  /**
   * W przypadku dostawy towarów używanych dla których podstawę opodatkowania stanowi marża, zgodnie z art. 120 ustawy,
   * a faktura dokumentująca dostawę zawiera wyrazy procedura marży - towary używane
   */
  TowarUzywany: Scalars['Boolean'];
};

/** Środek trwały */
export type TNowySrodekTransportuV2 = {
  /** Należy podać datę dopuszczenia nowego środka do użytku */
  DataDopuszczenia?: Maybe<Scalars['DateTime']>;
  /**
   * Jeśli dostawa dotyczy jednostek pływających, o których mowa w art. 2 pkt 10 lit. b ustawy, należy podać liczbę godzin roboczych używania nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  JednostkaPlywajacaLiczbaUzytychGodzinRoboczych?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy jednostek pływających, o których mowa w art. 2 pkt 10 lit. b ustawy, można podać numer kadłuba nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  JednostkaPlywajacaNumerKadluba?: Maybe<Scalars['String']>;
  /**
   * Kolor nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuKolor?: Maybe<Scalars['String']>;
  /**
   * Marka nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuMarka?: Maybe<Scalars['String']>;
  /**
   * Model nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuModel?: Maybe<Scalars['String']>;
  /**
   * Numer rejestracyjny nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuNumerRejestracyjny?: Maybe<Scalars['String']>;
  /**
   * Rok produkcji nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuRokProdukcji?: Maybe<Scalars['String']>;
  /**
   * Numer wiersza faktury, w którym wykazano dostawę nowego środka transportu
   * Liczby naturalne większe od zera
   * Minimum exclusive value: 0.
   */
  NumerWierszaFaktury?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać numer nadwozia
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyNumerNadwozia?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać numer podwozia
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyNumerPodwozia?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać numer ramy
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyNumerRamy?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać numer VIN
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyNumerVIN?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - należy podać przebieg pojazdu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyPrzebieg?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać typ nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyTyp?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy statków powietrznych, o których mowa w art. 2 pkt 10 lit. c ustawy, należy podać liczbę godzin roboczych używania nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  StatekPowietrznyLiczbaUzytychGodzinRoboczych?: Maybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy statków powietrznych, o których mowa w art. 2 pkt 10 lit. c ustawy, można podać numer fabryczny nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  StatekPowietrznyNumerFabryczny?: Maybe<Scalars['String']>;
};

/** Środek trwały */
export type TNowySrodekTransportuV2Input = {
  /** Należy podać datę dopuszczenia nowego środka do użytku */
  DataDopuszczenia?: InputMaybe<Scalars['DateTime']>;
  /**
   * Jeśli dostawa dotyczy jednostek pływających, o których mowa w art. 2 pkt 10 lit. b ustawy, należy podać liczbę godzin roboczych używania nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  JednostkaPlywajacaLiczbaUzytychGodzinRoboczych?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy jednostek pływających, o których mowa w art. 2 pkt 10 lit. b ustawy, można podać numer kadłuba nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  JednostkaPlywajacaNumerKadluba?: InputMaybe<Scalars['String']>;
  /**
   * Kolor nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuKolor?: InputMaybe<Scalars['String']>;
  /**
   * Marka nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuMarka?: InputMaybe<Scalars['String']>;
  /**
   * Model nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuModel?: InputMaybe<Scalars['String']>;
  /**
   * Numer rejestracyjny nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuNumerRejestracyjny?: InputMaybe<Scalars['String']>;
  /**
   * Rok produkcji nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  NowySrodekTransportuRokProdukcji?: InputMaybe<Scalars['String']>;
  /**
   * Numer wiersza faktury, w którym wykazano dostawę nowego środka transportu
   * Liczby naturalne większe od zera
   * Minimum exclusive value: 0.
   */
  NumerWierszaFaktury?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać numer nadwozia
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyNumerNadwozia?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać numer podwozia
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyNumerPodwozia?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać numer ramy
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyNumerRamy?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać numer VIN
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyNumerVIN?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - należy podać przebieg pojazdu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyPrzebieg?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - można podać typ nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  PojazdLadowyTyp?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy statków powietrznych, o których mowa w art. 2 pkt 10 lit. c ustawy, należy podać liczbę godzin roboczych używania nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  StatekPowietrznyLiczbaUzytychGodzinRoboczych?: InputMaybe<Scalars['String']>;
  /**
   * Jeśli dostawa dotyczy statków powietrznych, o których mowa w art. 2 pkt 10 lit. c ustawy, można podać numer fabryczny nowego środka transportu
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  StatekPowietrznyNumerFabryczny?: InputMaybe<Scalars['String']>;
};

/** Zwolnienie z podatku */
export type TPowodZwolnienia = {
  /** Należy wskazać przepis dyrektywy 2006/112/WE, który zwalnia od podatku taką dostawę towarów lub takie świadczenie usług */
  Dyrektywa?: Maybe<Scalars['String']>;
  /** Należy wskazać inną podstawę prawną wskazującą na to, że dostawa towarów lub świadczenie usług korzysta ze zwolnienia */
  PodstawaPrawna?: Maybe<Scalars['String']>;
  /** Należy wskazać przepis ustawy albo aktu wydanego na podstawie ustawy, na podstawie którego podatnik stosuje zwolnienie od podatku */
  PodstawaZwolnienia?: Maybe<Scalars['String']>;
};

/** Zwolnienie z podatku */
export type TPowodZwolnieniaInput = {
  /** Należy wskazać przepis dyrektywy 2006/112/WE, który zwalnia od podatku taką dostawę towarów lub takie świadczenie usług */
  Dyrektywa?: InputMaybe<Scalars['String']>;
  /** Należy wskazać inną podstawę prawną wskazującą na to, że dostawa towarów lub świadczenie usług korzysta ze zwolnienia */
  PodstawaPrawna?: InputMaybe<Scalars['String']>;
  /** Należy wskazać przepis ustawy albo aktu wydanego na podstawie ustawy, na podstawie którego podatnik stosuje zwolnienie od podatku */
  PodstawaZwolnienia?: InputMaybe<Scalars['String']>;
};

/** Zwolnienie z podatku */
export type TPowodZwolnieniaV2 = {
  /** Należy wskazać przepis dyrektywy 2006/112/WE, który zwalnia od podatku taką dostawę towarów lub takie świadczenie usług */
  Dyrektywa?: Maybe<Scalars['String']>;
  /** Należy wskazać inną podstawę prawną wskazującą na to, że dostawa towarów lub świadczenie usług korzysta ze zwolnienia */
  PodstawaPrawna?: Maybe<Scalars['String']>;
  /** Należy wskazać przepis ustawy albo aktu wydanego na podstawie ustawy, na podstawie którego podatnik stosuje zwolnienie od podatku */
  PodstawaZwolnienia?: Maybe<Scalars['String']>;
};

/** Zwolnienie z podatku */
export type TPowodZwolnieniaV2Input = {
  /** Należy wskazać przepis dyrektywy 2006/112/WE, który zwalnia od podatku taką dostawę towarów lub takie świadczenie usług */
  Dyrektywa?: InputMaybe<Scalars['String']>;
  /** Należy wskazać inną podstawę prawną wskazującą na to, że dostawa towarów lub świadczenie usług korzysta ze zwolnienia */
  PodstawaPrawna?: InputMaybe<Scalars['String']>;
  /** Należy wskazać przepis ustawy albo aktu wydanego na podstawie ustawy, na podstawie którego podatnik stosuje zwolnienie od podatku */
  PodstawaZwolnienia?: InputMaybe<Scalars['String']>;
};

/** Dane dotyczące rachunku bankowego */
export type TRachunekBankowy = {
  /** Nazwa banku */
  NazwaBanku?: Maybe<Scalars['String']>;
  /** Pełny numer rachunku w standardzie NRB */
  NrRBPL?: Maybe<Scalars['String']>;
  /** Pełny numer rachunku zagranicznego */
  NrRBZagr?: Maybe<Scalars['String']>;
  /** Kod SWIFT banku */
  SWIFT?: Maybe<Scalars['String']>;
};

/** Dane dotyczące rachunku bankowego */
export type TRachunekBankowyInput = {
  /** Nazwa banku */
  NazwaBanku?: InputMaybe<Scalars['String']>;
  /** Pełny numer rachunku w standardzie NRB */
  NrRBPL?: InputMaybe<Scalars['String']>;
  /** Pełny numer rachunku zagranicznego */
  NrRBZagr?: InputMaybe<Scalars['String']>;
  /** Kod SWIFT banku */
  SWIFT?: InputMaybe<Scalars['String']>;
};

/** Element Rachunek bankowy */
export type TRachunekBankowyV2 = {
  /** Nazwa banku */
  NazwaBanku?: Maybe<Scalars['String']>;
  /** Pełny numer rachunku w standardzie NRB */
  NrRBPL?: Maybe<Scalars['String']>;
  /** Pełny numer rachunku zagranicznego */
  NrRBZagr?: Maybe<Scalars['String']>;
  /** Opis rachunku */
  OpisRachunku?: Maybe<Scalars['String']>;
  /**
   * Typy rachunków własnych
   * 0 - Rachunek banku lub rachunek spółdzielczej kasy oszczędnościowo-kredytowej służący do dokonywania rozliczeń z tytułu nabywanych przez ten bank lub tę kasę wierzytelności pieniężnych
   * 1 - Rachunek banku lub rachunek spółdzielczej kasy oszczędnościowo-kredytowej wykorzystywany przez ten bank lub tę kasę do pobrania należności od nabywcy towarów lub usługobiorcy za dostawę towarów lub świadczenie usług, potwierdzone fakturą, i przekazania jej w całości albo części dostawcy towarów lub usługodawcy
   * 2 - Rachunek banku lub rachunek spółdzielczej kasy oszczędnościowo-kredytowej prowadzony przez ten bank lub tę kasę w ramach gospodarki własnej, niebędący rachunkiem rozliczeniowym
   */
  RachunekWlasnyBanku?: Maybe<Scalars['Int']>;
  /** Kod SWIFT banku */
  SWIFT?: Maybe<Scalars['String']>;
};

/** Element Rachunek bankowy */
export type TRachunekBankowyV2Input = {
  /** Nazwa banku */
  NazwaBanku?: InputMaybe<Scalars['String']>;
  /** Pełny numer rachunku w standardzie NRB */
  NrRBPL?: InputMaybe<Scalars['String']>;
  /** Pełny numer rachunku zagranicznego */
  NrRBZagr?: InputMaybe<Scalars['String']>;
  /** Opis rachunku */
  OpisRachunku?: InputMaybe<Scalars['String']>;
  /**
   * Typy rachunków własnych
   * 0 - Rachunek banku lub rachunek spółdzielczej kasy oszczędnościowo-kredytowej służący do dokonywania rozliczeń z tytułu nabywanych przez ten bank lub tę kasę wierzytelności pieniężnych
   * 1 - Rachunek banku lub rachunek spółdzielczej kasy oszczędnościowo-kredytowej wykorzystywany przez ten bank lub tę kasę do pobrania należności od nabywcy towarów lub usługobiorcy za dostawę towarów lub świadczenie usług, potwierdzone fakturą, i przekazania jej w całości albo części dostawcy towarów lub usługodawcy
   * 2 - Rachunek banku lub rachunek spółdzielczej kasy oszczędnościowo-kredytowej prowadzony przez ten bank lub tę kasę w ramach gospodarki własnej, niebędący rachunkiem rozliczeniowym
   */
  RachunekWlasnyBanku?: InputMaybe<Scalars['Int']>;
  /** Kod SWIFT banku */
  SWIFT?: InputMaybe<Scalars['String']>;
};

/** Element Skonto */
export type TSkontoV2 = {
  /**
   * Warunki, które nabywca powinien spełnić, aby skorzystać ze skonta
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  WarunkiSkonta?: Maybe<Scalars['String']>;
  /**
   * Wysokość skonta
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  WysokoscSkonta?: Maybe<Scalars['String']>;
};

/** Element Skonto */
export type TSkontoV2Input = {
  /**
   * Warunki, które nabywca powinien spełnić, aby skorzystać ze skonta
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  WarunkiSkonta?: InputMaybe<Scalars['String']>;
  /**
   * Wysokość skonta
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  WysokoscSkonta?: InputMaybe<Scalars['String']>;
};

/** Środek trwały */
export type TSrodekTrwaly = {
  /** Należy podać datę dopuszczenia nowego środka do użytku */
  DataDopuszczenia?: Maybe<Scalars['DateTime']>;
};

/** Środek trwały */
export type TSrodekTrwalyInput = {
  /** Należy podać datę dopuszczenia nowego środka do użytku */
  DataDopuszczenia?: InputMaybe<Scalars['DateTime']>;
};

/** Status prawny podatnika */
export type TStatusPodatnika = {
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika znajdującego się w stanie likwidacji */
  WLikwidcji: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika, który jest w trakcie postępowania restrukturyzacyjnego */
  WPostepowaniuRestr: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez przedsiębiorstwo w spadku */
  WSpadku: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika znajdującego się w stanie upadłości */
  WUpdalosci: Scalars['Boolean'];
};

/** Status prawny podatnika */
export type TStatusPodatnikaInput = {
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika znajdującego się w stanie likwidacji */
  WLikwidcji: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika, który jest w trakcie postępowania restrukturyzacyjnego */
  WPostepowaniuRestr: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez przedsiębiorstwo w spadku */
  WSpadku: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika znajdującego się w stanie upadłości */
  WUpdalosci: Scalars['Boolean'];
};

/** Status prawny podatnika */
export type TStatusPodatnikaV2 = {
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika znajdującego się w stanie likwidacji */
  WLikwidcji: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika, który jest w trakcie postępowania restrukturyzacyjnego */
  WPostepowaniuRestr: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez przedsiębiorstwo w spadku */
  WSpadku: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika znajdującego się w stanie upadłości */
  WUpdalosci: Scalars['Boolean'];
};

/** Status prawny podatnika */
export type TStatusPodatnikaV2Input = {
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika znajdującego się w stanie likwidacji */
  WLikwidcji: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika, który jest w trakcie postępowania restrukturyzacyjnego */
  WPostepowaniuRestr: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez przedsiębiorstwo w spadku */
  WSpadku: Scalars['Boolean'];
  /** Faktura dokumentująca sprzedaż dokonywaną przez podatnika znajdującego się w stanie upadłości */
  WUpdalosci: Scalars['Boolean'];
};

/** Termin płatności */
export type TTerminPlatnosci = {
  /** Termin płatności */
  TerminPlatnosci: Scalars['DateTime'];
  /** Opis terminu płatności */
  TerminPlatnosciOpis?: Maybe<Scalars['String']>;
};

/** Termin płatności */
export type TTerminPlatnosciInput = {
  /** Termin płatności */
  TerminPlatnosci: Scalars['DateTime'];
  /** Opis terminu płatności */
  TerminPlatnosciOpis?: InputMaybe<Scalars['String']>;
};

/** Zdarzenie transportowe do faktury */
export type TTransport = {
  /** Data i godzina rozpoczęcia transportu */
  DataGodzRozpTransportu?: Maybe<Scalars['DateTime']>;
  /** Data i godzina zakończenia transportu */
  DataGodzZakTransportu?: Maybe<Scalars['DateTime']>;
  /** Jednostka opakowania */
  JednostkaOpakowania?: Maybe<Scalars['String']>;
  /** Inny rodzaj ładunku */
  LadunekInny: Scalars['Boolean'];
  /** Numer zlecenia transportu */
  NrZleceniaTransportu?: Maybe<Scalars['String']>;
  /** Opis innego rodzaju ładunku */
  OpisInnegoLadunku?: Maybe<Scalars['String']>;
  /** Opis innego rodzaju transportu */
  OpisInnegoTransportu?: Maybe<Scalars['String']>;
  /** Opis ładunku */
  OpisLadunku?: Maybe<Ladunek>;
  /** Podmiot realizujący zdarzenie transportowe */
  Przewoznik?: Maybe<Podmiot>;
  /** Rodzaj zastosowanego transportu w przypadku dokonanej dostawy towarów */
  RodzajTransportu?: Maybe<RodzajTransportu>;
  /** Inny rodzaj transportu */
  TransportInny: Scalars['Boolean'];
  /** Wysyłka towaru do lokalizacji */
  WysylkaDo?: Maybe<Adres>;
  /** Wysyłka towaru przez lokalizację */
  WysylkaPrzez?: Maybe<Adres>;
  /** Wysyłka towaru z lokalizacji */
  WysylkaZ?: Maybe<Adres>;
};

/** Zdarzenie transportowe do faktury */
export type TTransportInput = {
  /** Data i godzina rozpoczęcia transportu */
  DataGodzRozpTransportu?: InputMaybe<Scalars['DateTime']>;
  /** Data i godzina zakończenia transportu */
  DataGodzZakTransportu?: InputMaybe<Scalars['DateTime']>;
  /** Jednostka opakowania */
  JednostkaOpakowania?: InputMaybe<Scalars['String']>;
  /** Inny rodzaj ładunku */
  LadunekInny: Scalars['Boolean'];
  /** Numer zlecenia transportu */
  NrZleceniaTransportu?: InputMaybe<Scalars['String']>;
  /** Opis innego rodzaju ładunku */
  OpisInnegoLadunku?: InputMaybe<Scalars['String']>;
  /** Opis innego rodzaju transportu */
  OpisInnegoTransportu?: InputMaybe<Scalars['String']>;
  /** Opis ładunku */
  OpisLadunku?: InputMaybe<Ladunek>;
  /** Podmiot realizujący zdarzenie transportowe */
  Przewoznik?: InputMaybe<PodmiotInput>;
  /** Rodzaj zastosowanego transportu w przypadku dokonanej dostawy towarów */
  RodzajTransportu?: InputMaybe<RodzajTransportu>;
  /** Inny rodzaj transportu */
  TransportInny: Scalars['Boolean'];
  /** Wysyłka towaru do lokalizacji */
  WysylkaDo?: InputMaybe<AdresInput>;
  /** Wysyłka towaru przez lokalizację */
  WysylkaPrzez?: InputMaybe<AdresInput>;
  /** Wysyłka towaru z lokalizacji */
  WysylkaZ?: InputMaybe<AdresInput>;
};

/** Element transport */
export type TTransportV2 = {
  /** Data i godzina rozpoczęcia transportu */
  DataGodzRozpTransportu?: Maybe<Scalars['DateTime']>;
  /** Data i godzina zakończenia transportu */
  DataGodzZakTransportu?: Maybe<Scalars['DateTime']>;
  /** Jednostka opakowania */
  JednostkaOpakowania?: Maybe<Scalars['String']>;
  /** Inny rodzaj ładunku */
  LadunekInny: Scalars['Boolean'];
  /** Numer zlecenia transportu */
  NrZleceniaTransportu?: Maybe<Scalars['String']>;
  /** Opis innego rodzaju ładunku */
  OpisInnegoLadunku?: Maybe<Scalars['String']>;
  /** Opis innego rodzaju transportu */
  OpisInnegoTransportu?: Maybe<Scalars['String']>;
  /** Opis ładunku */
  OpisLadunku?: Maybe<Ladunek>;
  /** Podmiot realizujący zdarzenie transportowe */
  Przewoznik?: Maybe<PodmiotV2>;
  /** Rodzaj zastosowanego transportu w przypadku dokonanej dostawy towarów */
  RodzajTransportu?: Maybe<RodzajTransportu>;
  /** Inny rodzaj transportu */
  TransportInny: Scalars['Boolean'];
  /** Wysyłka towaru do lokalizacji */
  WysylkaDo?: Maybe<AdresV2>;
  /** Wysyłka towaru przez lokalizację */
  WysylkaPrzez?: Maybe<Array<AdresV2>>;
  /** Wysyłka towaru z loklalizacji */
  WysylkaZ?: Maybe<AdresV2>;
};

/** Element transport */
export type TTransportV2Input = {
  /** Data i godzina rozpoczęcia transportu */
  DataGodzRozpTransportu?: InputMaybe<Scalars['DateTime']>;
  /** Data i godzina zakończenia transportu */
  DataGodzZakTransportu?: InputMaybe<Scalars['DateTime']>;
  /** Jednostka opakowania */
  JednostkaOpakowania?: InputMaybe<Scalars['String']>;
  /** Inny rodzaj ładunku */
  LadunekInny: Scalars['Boolean'];
  /** Numer zlecenia transportu */
  NrZleceniaTransportu?: InputMaybe<Scalars['String']>;
  /** Opis innego rodzaju ładunku */
  OpisInnegoLadunku?: InputMaybe<Scalars['String']>;
  /** Opis innego rodzaju transportu */
  OpisInnegoTransportu?: InputMaybe<Scalars['String']>;
  /** Opis ładunku */
  OpisLadunku?: InputMaybe<Ladunek>;
  /** Podmiot realizujący zdarzenie transportowe */
  Przewoznik?: InputMaybe<PodmiotV2Input>;
  /** Rodzaj zastosowanego transportu w przypadku dokonanej dostawy towarów */
  RodzajTransportu?: InputMaybe<RodzajTransportu>;
  /** Inny rodzaj transportu */
  TransportInny: Scalars['Boolean'];
  /** Wysyłka towaru do lokalizacji */
  WysylkaDo?: InputMaybe<AdresV2Input>;
  /** Wysyłka towaru przez lokalizację */
  WysylkaPrzez?: InputMaybe<Array<AdresV2Input>>;
  /** Wysyłka towaru z loklalizacji */
  WysylkaZ?: InputMaybe<AdresV2Input>;
};

/** Umowa transakcji do faktury */
export type TUmowa = {
  /** Data umowy */
  DataUmowy?: Maybe<Scalars['DateTime']>;
  /** Numer umowy */
  NrUmowy?: Maybe<Scalars['String']>;
};

/** Umowa transakcji do faktury */
export type TUmowaInput = {
  /** Data umowy */
  DataUmowy?: InputMaybe<Scalars['DateTime']>;
  /** Numer umowy */
  NrUmowy?: InputMaybe<Scalars['String']>;
};

/** Zamówienie dotyczące warunków transakcji */
export type TZamowienie = {
  /** Data zamówienia */
  DataZamowienia?: Maybe<Scalars['DateTime']>;
  /** Numer zamówienia */
  NrZamowienia?: Maybe<Scalars['String']>;
};

/** Zamówienie dotyczące warunków transakcji */
export type TZamowienieInput = {
  /** Data zamówienia */
  DataZamowienia?: InputMaybe<Scalars['DateTime']>;
  /** Numer zamówienia */
  NrZamowienia?: InputMaybe<Scalars['String']>;
};

/**
 * Możliwość wystąpienia więcej niż jednej zapłaty częściowej w fakturze może wystąpić wyłącznie w przypadku płatności dokonywanych po dostawie towarów lub wykonaniu usług,
 * a przed wystawieniem faktury, z wyjątkiem przypadków, gdy otrzymanie płatności nie skutkuje obowiązkiem wystawienia faktury
 */
export type TZaplataCzesciowa = {
  /** Data zapłaty częściowej, jeśli do wystawienia faktury płatność częściowa została dokonana */
  Data: Scalars['DateTime'];
  /** Kwota zapłaty częściowej */
  Kwota: Scalars['Decimal'];
};

/**
 * Możliwość wystąpienia więcej niż jednej zapłaty częściowej w fakturze może wystąpić wyłącznie w przypadku płatności dokonywanych po dostawie towarów lub wykonaniu usług,
 * a przed wystawieniem faktury, z wyjątkiem przypadków, gdy otrzymanie płatności nie skutkuje obowiązkiem wystawienia faktury
 */
export type TZaplataCzesciowaInput = {
  /** Data zapłaty częściowej, jeśli do wystawienia faktury płatność częściowa została dokonana */
  Data: Scalars['DateTime'];
  /** Kwota zapłaty częściowej */
  Kwota: Scalars['Decimal'];
};

export type TenantAssignmentQueryModel = {
  CompanyAssignments?: Maybe<Array<Maybe<CompanyAssignmentQueryModel>>>;
  RoleName?: Maybe<Scalars['String']>;
  Status: TenantStatus;
  TenantId: Scalars['UUID'];
  TenantName?: Maybe<Scalars['String']>;
};

/** Statystyka dla organizacji */
export type TenantAssignmentViewModel = {
  /** Lista firm organizacji */
  CompanyAssignments?: Maybe<Array<Maybe<CompanyAssignmentViewModel>>>;
  /** Nazwa roli */
  RoleName?: Maybe<Scalars['String']>;
  /** Status organizacji */
  Status: TenantStatus;
  /** Id organizacji */
  TenantId: Scalars['UUID'];
  /** Nazwa organizacji */
  TenantName?: Maybe<Scalars['String']>;
};

/** tenanci dla organizacji */
export type TenantInfoViewModel = {
  /** Lista firm organizacji */
  CompanyAssignments?: Maybe<Array<Maybe<CompanyInfoViewModel>>>;
  /** Nazwa roli */
  RoleName?: Maybe<Scalars['String']>;
  /** Status organizacji */
  Status: TenantStatus;
  /** Id organizacji */
  TenantId: Scalars['UUID'];
  /** Nazwa organizacji */
  TenantName?: Maybe<Scalars['String']>;
};

/** Organizacja */
export type TenantFilterInput = {
  /** Organizacja jest biurem rachunkowym */
  AccountOffice?: InputMaybe<BooleanOperationFilterInput>;
  /** Dane adresowe organizacji */
  Address?: InputMaybe<AddressFilterInput>;
  /** Lista firm organizacji */
  Companys?: InputMaybe<ListFilterInputTypeOfCompanyFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Data odrzucenia akceptacji organizacji */
  DecisionDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Powód odrzucenia akceptacji organizacji */
  DecisionReason?: InputMaybe<StringOperationFilterInput>;
  /** Status połączenia między aplikacjami */
  DesktopStatus?: InputMaybe<DesktopStatusOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<StringOperationFilterInput>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType?: InputMaybe<IdentifierTypeOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  /** Nazwa organizacji */
  Name?: InputMaybe<StringOperationFilterInput>;
  /** Serwis tworzący organizację */
  SourceService?: InputMaybe<SourceServiceEnumOperationFilterInput>;
  /** Status organizacji */
  Status?: InputMaybe<TenantStatusOperationFilterInput>;
  /** Numer subskrypcji */
  SubscriptionNumber?: InputMaybe<StringOperationFilterInput>;
  /** Lista użytkowników organizacji */
  Users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  /** Numer VAT UE */
  VatNumberUE?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<TenantFilterInput>>;
  or?: InputMaybe<Array<TenantFilterInput>>;
};

/** Organizacja */
export type TenantInput = {
  /** Organizacja jest biurem rachunkowym */
  AccountOffice: Scalars['Boolean'];
  /** Dane adresowe organizacji */
  Address?: InputMaybe<AddressInput>;
  /** Lista firm organizacji */
  Companys?: InputMaybe<Array<InputMaybe<CreateCompanyRequest>>>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<Scalars['String']>;
  /** Data odrzucenia akceptacji organizacji */
  DecisionDate?: InputMaybe<Scalars['DateTime']>;
  /** Powód odrzucenia akceptacji organizacji */
  DecisionReason?: InputMaybe<Scalars['String']>;
  /** Status połączenia między aplikacjami */
  DesktopStatus: DesktopStatus;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<Scalars['String']>;
  /** Nazwa organizacji */
  Name?: InputMaybe<Scalars['String']>;
  /** Serwis tworzący organizację */
  SourceService: SourceServiceEnum;
  /** Status organizacji */
  Status: TenantStatus;
  /** Numer subskrypcji */
  SubscriptionNumber?: InputMaybe<Scalars['String']>;
  /** Lista użytkowników organizacji */
  Users?: InputMaybe<Array<InputMaybe<UserInput>>>;
  /** Numer VAT UE */
  VatNumberUE?: InputMaybe<Scalars['String']>;
};

/** Odpowiedź */
export type TenantListQueryResponse = {
  /** Lista organizacji dla określonego Nip i Email, z określeniem czy Email z zapytania jest administratorem Tenant'a */
  Tenants?: Maybe<Array<Maybe<TenantListResponse>>>;
};

/** Klasa pomocnicza dla query TenantList */
export type TenantListResponse = {
  Tenant?: Maybe<TenantResponse>;
  UserExist: Scalars['Boolean'];
};

/** Odpowiedź */
export type TenantNipExistsQueryResponse = {
  /** Jeśli Exists = true email administaratora zamaskowany */
  Email?: Maybe<Scalars['String']>;
  /** True jeżeli istnieje */
  Exists: Scalars['Boolean'];
};

/** Organizacja */
export type TenantResponse = {
  /** Organizacja jest biurem rachunkowym */
  AccountOffice: Scalars['Boolean'];
  /** Dane adresowe organizacji */
  Address?: Maybe<Address>;
  /** Lista firm organizacji */
  Companys?: Maybe<Array<Maybe<Company>>>;
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Data odrzucenia akceptacji organizacji */
  DecisionDate?: Maybe<Scalars['DateTime']>;
  /** Powód odrzucenia akceptacji organizacji */
  DecisionReason?: Maybe<Scalars['String']>;
  /** Status połączenia między aplikacjami */
  DesktopStatus: DesktopStatus;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Nazwa organizacji */
  Name?: Maybe<Scalars['String']>;
  /** Serwis tworzący organizację */
  SourceService: SourceServiceEnum;
  /** Status organizacji */
  Status: TenantStatus;
  /** Numer subskrypcji */
  SubscriptionNumber?: Maybe<Scalars['String']>;
  /** Lista użytkowników organizacji */
  Users?: Maybe<Array<Maybe<User>>>;
  /** Numer VAT UE */
  VatNumberUE?: Maybe<Scalars['String']>;
};

/** Organizacja */
export type TenantSortInput = {
  /** Organizacja jest biurem rachunkowym */
  AccountOffice?: InputMaybe<SortEnumType>;
  /** Dane adresowe organizacji */
  Address?: InputMaybe<AddressSortInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<SortEnumType>;
  /** Data odrzucenia akceptacji organizacji */
  DecisionDate?: InputMaybe<SortEnumType>;
  /** Powód odrzucenia akceptacji organizacji */
  DecisionReason?: InputMaybe<SortEnumType>;
  /** Status połączenia między aplikacjami */
  DesktopStatus?: InputMaybe<SortEnumType>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<SortEnumType>;
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<SortEnumType>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType?: InputMaybe<SortEnumType>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<SortEnumType>;
  /** Nazwa organizacji */
  Name?: InputMaybe<SortEnumType>;
  /** Serwis tworzący organizację */
  SourceService?: InputMaybe<SortEnumType>;
  /** Status organizacji */
  Status?: InputMaybe<SortEnumType>;
  /** Numer subskrypcji */
  SubscriptionNumber?: InputMaybe<SortEnumType>;
  /** Numer VAT UE */
  VatNumberUE?: InputMaybe<SortEnumType>;
};

/** Status organizacji */
export enum TenantStatus {
  /** Aktywna */
  Active = 'ACTIVE',
  /** Nieaktywna */
  Deactivated = 'DEACTIVATED',
  /** Oczekuje na akceptację */
  PendingApproval = 'PENDING_APPROVAL',
  /** Odrzucona */
  Rejected = 'REJECTED',
  /** Do usunięcia */
  ToDelete = 'TO_DELETE'
}

export type TenantStatusOperationFilterInput = {
  eq?: InputMaybe<TenantStatus>;
  in?: InputMaybe<Array<TenantStatus>>;
  neq?: InputMaybe<TenantStatus>;
  nin?: InputMaybe<Array<TenantStatus>>;
};

/** A segment of a collection. */
export type TenantsCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<TenantResponse>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type TerminateKSeFSessionResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

export type TestCustomWebsocketNotificationResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

export type TrailRequestViewModel = {
  CloudModule: CloudModuleType;
  CompanyId: Scalars['UUID'];
  TenantId: Scalars['UUID'];
};

/** Odpowiedź */
export type TrialRequestsResponse = {
  /** Lista okresów próbnych */
  Items?: Maybe<Array<Maybe<TrailRequestViewModel>>>;
};

export type TriggerAppStreamTrialUsedResponse = {
  Success: Scalars['Boolean'];
};

export type TriggerBackupResponse = {
  Result?: Maybe<AppStreamBackupResponse>;
};

/** Podmiot trzeci widniejący na fakturze */
export type TrzeciPodmiot = {
  /** Adres */
  Adres?: Maybe<Adres>;
  /** Adres korespondencyjny */
  AdresKoresp?: Maybe<Adres>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjne;
  /** Adres email */
  Email?: Maybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: Maybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: Maybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: Maybe<PrefixPodatnika>;
  /**
   * Procentowy udział dodatkowego nabywcy. Udziałem nabywcy wymienionego w sekcji Podmiot2 jest wówczas dopełnienie do 100% sumy udziałów dodatkowych nabywców.
   * W przypadku niewypełnienia pola przyjmuje się, że udziały występujących w fakturze nabywców są równe
   */
  ProcentowyUdzial?: Maybe<Scalars['Decimal']>;
  /** Rola podmiotu trzeciego */
  Rola: RolaPodmiotu3;
  /** Status podatnika */
  StatusPodatnika?: Maybe<TStatusPodatnika>;
  /** Numer telefonu */
  Telefon?: Maybe<Scalars['String']>;
};

/** Podmiot trzeci widniejący na fakturze */
export type TrzeciPodmiotInput = {
  /** Adres */
  Adres?: InputMaybe<AdresInput>;
  /** Adres korespondencyjny */
  AdresKoresp?: InputMaybe<AdresInput>;
  /** Dane identyfikacyjne */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneInput;
  /** Adres email */
  Email?: InputMaybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: InputMaybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: InputMaybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: InputMaybe<PrefixPodatnika>;
  /**
   * Procentowy udział dodatkowego nabywcy. Udziałem nabywcy wymienionego w sekcji Podmiot2 jest wówczas dopełnienie do 100% sumy udziałów dodatkowych nabywców.
   * W przypadku niewypełnienia pola przyjmuje się, że udziały występujących w fakturze nabywców są równe
   */
  ProcentowyUdzial?: InputMaybe<Scalars['Decimal']>;
  /** Rola podmiotu trzeciego */
  Rola: RolaPodmiotu3;
  /** Status podatnika */
  StatusPodatnika?: InputMaybe<TStatusPodatnikaInput>;
  /** Numer telefonu */
  Telefon?: InputMaybe<Scalars['String']>;
};

/** Element Podmiot trzeci */
export type TrzeciPodmiotV2 = {
  /** Adres */
  Adres?: Maybe<AdresV2>;
  /** Adres korespondencyjny */
  AdresKoresp?: Maybe<AdresV2>;
  /** Dane identyfikacyjne podmiotu trzeciego */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneTrzeciPodmiotV2;
  /** Dane kontaktowe */
  DaneKontaktowe?: Maybe<Array<TDaneKontaktoweV2>>;
  /** Unikalny klucz powiązania danych nabywcy na fakturach korygujących, w przypadku gdy dane nabywcy na fakturze korygującej zmieniły się w stosunku do danych na fakturze korygowanej */
  IDNabywcy?: Maybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: Maybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: Maybe<Scalars['String']>;
  /**
   * Opis roli podmiotu - w przypadku wyboru roli jako Inny podmiot
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  OpisRoliInnej?: Maybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: Maybe<PrefixPodatnika>;
  /**
   * Procentowy udział dodatkowego nabywcy. Udziałem nabywcy wymienionego w sekcji Podmiot2 jest wówczas dopełnienie do 100% sumy udziałów dodatkowych nabywców.
   * W przypadku niewypełnienia pola przyjmuje się, że udziały występujących w fakturze nabywców są równe
   */
  ProcentowyUdzial?: Maybe<Scalars['Decimal']>;
  /** Rola podmiotu trzeciego */
  Rola?: Maybe<RolaPodmiotu3>;
  /** Status podatnika */
  StatusPodatnika?: Maybe<TStatusPodatnikaV2>;
};

/** Element Podmiot trzeci */
export type TrzeciPodmiotV2Input = {
  /** Adres */
  Adres?: InputMaybe<AdresV2Input>;
  /** Adres korespondencyjny */
  AdresKoresp?: InputMaybe<AdresV2Input>;
  /** Dane identyfikacyjne podmiotu trzeciego */
  DaneIdentyfikacyjne: TDaneIdentyfikacyjneTrzeciPodmiotV2Input;
  /** Dane kontaktowe */
  DaneKontaktowe?: InputMaybe<Array<TDaneKontaktoweV2Input>>;
  /** Unikalny klucz powiązania danych nabywcy na fakturach korygujących, w przypadku gdy dane nabywcy na fakturze korygującej zmieniły się w stosunku do danych na fakturze korygowanej */
  IDNabywcy?: InputMaybe<Scalars['String']>;
  /** Numer EORI podatnika towarów */
  NrEORI?: InputMaybe<Scalars['String']>;
  /** Numer klienta dla przypadków, w których podmiot wymieniony jako podmiot trzeci posługuje się nim w umowie lub zamówieniu */
  NrKlienta?: InputMaybe<Scalars['String']>;
  /**
   * Opis roli podmiotu - w przypadku wyboru roli jako Inny podmiot
   * Typ znakowy ograniczony do 256 znaków
   * Minimum length: 1.
   * Maximum length: 256.
   */
  OpisRoliInnej?: InputMaybe<Scalars['String']>;
  /** Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy */
  PrefixPodatnika?: InputMaybe<PrefixPodatnika>;
  /**
   * Procentowy udział dodatkowego nabywcy. Udziałem nabywcy wymienionego w sekcji Podmiot2 jest wówczas dopełnienie do 100% sumy udziałów dodatkowych nabywców.
   * W przypadku niewypełnienia pola przyjmuje się, że udziały występujących w fakturze nabywców są równe
   */
  ProcentowyUdzial?: InputMaybe<Scalars['Decimal']>;
  /** Rola podmiotu trzeciego */
  Rola?: InputMaybe<RolaPodmiotu3>;
  /** Status podatnika */
  StatusPodatnika?: InputMaybe<TStatusPodatnikaV2Input>;
};

/** Typ skutku korekty w ewidencji */
export enum TypKorekty {
  /** Korekta skutkująca w dacie ujęcia faktury pierwotnej */
  Typ1 = 'TYP1',
  /** Korekta skutkująca w dacie wystawienia faktury korygującej */
  Typ2 = 'TYP2',
  /** Korekta skutkująca w dacie innej, w tym gdy dla różnych pozycji faktury korygującej daty te są różne */
  Typ3 = 'TYP3'
}

/** Wynik zlecenia UPO */
export type UpoType = {
  /** Dane zakodowane w Base64 */
  FileContent?: Maybe<Scalars['String']>;
  /** Nazwa pliku */
  FileName?: Maybe<Scalars['String']>;
  /** Typ Mime pliku */
  MimeType?: Maybe<Scalars['String']>;
  /** Liczba brakujących faktur */
  MissingInvoices?: Maybe<Array<Maybe<MissedInvoice>>>;
  /** Liczba oczekiwanych faktur */
  RequestedInvoices?: Maybe<Array<Scalars['UUID']>>;
  /** Liczba uwzględnionych faktur */
  ReturnedInvoices?: Maybe<Array<Scalars['UUID']>>;
};

/** Nowe dane klucza */
export type UpdateApiKeyInput = {
  /** Unikalny ID rekordu */
  Id: Scalars['UUID'];
  /** Status klucza */
  IsActive?: InputMaybe<Scalars['Boolean']>;
  /** Nazwa klucza */
  Name?: InputMaybe<Scalars['String']>;
};

/** Wynik operacji aktualizacji dostępności załącznika */
export type UpdateAttachmentAccessibilityResponse = {
  /** Wynik operacji */
  IsDone: Scalars['Boolean'];
};

/** Wynik operacji aktualizacji dostępności załączników */
export type UpdateAttachmentsAccessibilityResponse = {
  /** Wynik operacji */
  IsDone: Scalars['Boolean'];
};

/** Nowe dane firmy */
export type UpdateCompanyInput = {
  /** Dane adresowe */
  Address?: InputMaybe<AddressInput>;
  /** Unikalny ID rekordu */
  Id: Scalars['UUID'];
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: InputMaybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Nazwa firmy */
  Name?: InputMaybe<Scalars['String']>;
  /** Id organizacji */
  TenantId: Scalars['UUID'];
};

export type UpdateCompanyVehicleMutationResponse = {
  IsDone: Scalars['Boolean'];
};

/** Wynik operacji aktualizacji kontrahentów */
export type UpdateContractorResponse = {
  Success: Scalars['Boolean'];
};

/** Nowe dane uzytkownika */
export type UpdateMyUserInput = {
  /** Imię */
  FirstName?: InputMaybe<Scalars['String']>;
  /** Nazwisko */
  LastName?: InputMaybe<Scalars['String']>;
  /** Numer telefonu */
  PhoneNumber?: InputMaybe<Scalars['String']>;
};

/** Zdjęcie uzytkownika */
export type UpdateProfilePhotoInput = {
  /** Zdjecie uzytkownika */
  PhotoFile?: InputMaybe<Scalars['Upload']>;
};

export type UpdateRoleRequest = {
  BusinessName?: InputMaybe<Scalars['String']>;
  Id: Scalars['UUID'];
  Name?: InputMaybe<Scalars['String']>;
  SystemType?: InputMaybe<Scalars['String']>;
};

/** Dane uzytkownika w AAD B2C */
export type UpdateSymfoniaIdInput = {
  /** Imię */
  FirstName?: InputMaybe<Scalars['String']>;
  /** Nazwisko */
  LastName?: InputMaybe<Scalars['String']>;
  /** Numer telefonu */
  PhoneNumber?: InputMaybe<Scalars['String']>;
};

/** Organizacja */
export type UpdateTenantResponse = {
  /** Organizacja jest biurem rachunkowym */
  AccountOffice: Scalars['Boolean'];
  /** Dane adresowe organizacji */
  Address?: Maybe<Address>;
  /** Lista firm organizacji */
  Companys?: Maybe<Array<Maybe<Company>>>;
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Data odrzucenia akceptacji organizacji */
  DecisionDate?: Maybe<Scalars['DateTime']>;
  /** Powód odrzucenia akceptacji organizacji */
  DecisionReason?: Maybe<Scalars['String']>;
  /** Status połączenia między aplikacjami */
  DesktopStatus: DesktopStatus;
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Numer identyfikacji ewidencyjnej/podatkowej */
  Identifier?: Maybe<Scalars['String']>;
  /** Typ numeru identyfikacyjnego */
  IdentifierType: IdentifierType;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Nazwa organizacji */
  Name?: Maybe<Scalars['String']>;
  /** Serwis tworzący organizację */
  SourceService: SourceServiceEnum;
  /** Status organizacji */
  Status: TenantStatus;
  /** Numer subskrypcji */
  SubscriptionNumber?: Maybe<Scalars['String']>;
  /** Lista użytkowników organizacji */
  Users?: Maybe<Array<Maybe<User>>>;
  /** Numer VAT UE */
  VatNumberUE?: Maybe<Scalars['String']>;
};

/** Nowe dane uzytkownika */
export type UpdateUserInput = {
  /** Email */
  Email: Scalars['String'];
  /** Imię */
  FirstName?: InputMaybe<Scalars['String']>;
  /** Id usera */
  Id: Scalars['UUID'];
  /** Nazwisko */
  LastName?: InputMaybe<Scalars['String']>;
  /** Numer telefonu */
  PhoneNumber?: InputMaybe<Scalars['String']>;
  /** Id organizacji */
  TenantId: Scalars['UUID'];
};

export type UploadInvoicesResponse = {
  /** Treść notyfikacji */
  NotificationModel?: Maybe<WebsocketNotificationModel>;
  /** Stan operacji */
  Result: SubscriptionStateEnum;
};

/** Obiekt opisujący status importu XML */
export type UploadInvoicesResultItem = {
  /** Nazwa importowanego pliku */
  FileName?: Maybe<Scalars['String']>;
  /** Treść komunikatu błędu */
  errorMessage?: Maybe<Scalars['String']>;
};

/** Rekord wgrywanej faktury */
export type UploadItemInput = {
  /** Faktura xml zakodowana base64 */
  FileContent?: InputMaybe<Scalars['String']>;
  /** Nazwa pliku */
  FileName?: InputMaybe<Scalars['String']>;
};

/** Format pliku UPO */
export enum UpoFileType {
  /** PDF generowany przez KSeF */
  Pdf = 'PDF',
  /** XML generowany przez KSeF */
  Xml = 'XML'
}

/** Powód, dla którego faktura została uznana za brakującą */
export enum UpoMissingInvoiceReason {
  /** Inny błąd */
  Error = 'ERROR',
  /** Posiada status przerwanej weryfikacji */
  InterruptedPending = 'INTERRUPTED_PENDING',
  /** Posiada status przerwanej wysyłki */
  InterruptedSending = 'INTERRUPTED_SENDING',
  /** Nie istnieje */
  NotExists = 'NOT_EXISTS',
  /** Nie została wysłana */
  NotSent = 'NOT_SENT',
  /** Brak numeru referencyjnego sesji */
  NoSessionReferenceNumber = 'NO_SESSION_REFERENCE_NUMBER',
  /** Faktura OCR */
  Ocr = 'OCR',
  /** W trakcie procesowania */
  Processing = 'PROCESSING',
  /** UPO nie zostało wygenerowane, błąd schemy */
  SchemaError = 'SCHEMA_ERROR',
  /** UPO nie zostało wygenerowane */
  UpoNotGenerated = 'UPO_NOT_GENERATED',
  /** Błąd walidacji */
  ValidationError = 'VALIDATION_ERROR'
}

/** Użytkownik organizacji */
export type User = {
  /** Warunki użytkowania Programu w chmurze https://materialy.symfonia.pl/publiczne/a8ca55 (obowiązkowe) */
  CloudConsent?: Maybe<Scalars['Boolean']>;
  /** Oświadczenie o upoważnienie do działania w imieniu reprezentowanego podmiotu: "Oświadczam, że jestem uprawniony/-a do działania w imieniu i ze skutkiem dla podmiotu, który reprezentuję." (obowiązkowe) */
  CompanyRepresentationConsent?: Maybe<Scalars['Boolean']>;
  /** Data utworzenia rekordu */
  CreatedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: Maybe<Scalars['String']>;
  /** Adres email użytkownika */
  Email: Scalars['String'];
  /** Imię użytkownika */
  FirstName: Scalars['String'];
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Nazwisko użytkownika */
  LastName: Scalars['String'];
  /** Zgoda na wykorzystanie e-mail do celów marketingowych */
  MarketingEmailConsent?: Maybe<Scalars['Boolean']>;
  /** Zgoda na używanie telekomunikacyjnych urządzeń końcowych do kontaktów marketingowych */
  MarketingEndDeviceConsent?: Maybe<Scalars['Boolean']>;
  /** Zgoda na używanie telefonu do kontaktów marketingowych */
  MarketingPhoneConsent?: Maybe<Scalars['Boolean']>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: Maybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: Maybe<Scalars['String']>;
  /** Zgoda na otrzymywanie newslettera */
  NewsletterConsent?: Maybe<Scalars['Boolean']>;
  /** Zgoda na przetwarzanie danych osobowych w celach marketingowych Symfonii. (obowiązkowe) */
  PersonalDataConsent?: Maybe<Scalars['Boolean']>;
  /** Zgoda na przetwarzanie danych osobowych w celach marketingowych przez Partnerów Symfonii. */
  PersonalDataForPartnersConsent?: Maybe<Scalars['Boolean']>;
  /** Numer telefonu użytkownika */
  PhoneNumber?: Maybe<Scalars['String']>;
  /** Akceptacja polityki prywatności (obowiązkowe) */
  PrivacyPolicyConsent?: Maybe<Scalars['Boolean']>;
  /** Przypisane role */
  RoleAssignments?: Maybe<Array<Maybe<RoleAssignment>>>;
  Tenants?: Maybe<Array<Maybe<TenantResponse>>>;
};

/** Użytkownik organizacji */
export type UserFilterInput = {
  /** Warunki użytkowania Programu w chmurze https://materialy.symfonia.pl/publiczne/a8ca55 (obowiązkowe) */
  CloudConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Oświadczenie o upoważnienie do działania w imieniu reprezentowanego podmiotu: "Oświadczam, że jestem uprawniony/-a do działania w imieniu i ze skutkiem dla podmiotu, który reprezentuję." (obowiązkowe) */
  CompanyRepresentationConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<StringOperationFilterInput>;
  /** Adres email użytkownika */
  Email?: InputMaybe<StringOperationFilterInput>;
  /** Imię użytkownika */
  FirstName?: InputMaybe<StringOperationFilterInput>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<UuidOperationFilterInput>;
  /** Nazwisko użytkownika */
  LastName?: InputMaybe<StringOperationFilterInput>;
  /** Zgoda na wykorzystanie e-mail do celów marketingowych */
  MarketingEmailConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Zgoda na używanie telekomunikacyjnych urządzeń końcowych do kontaktów marketingowych */
  MarketingEndDeviceConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Zgoda na używanie telefonu do kontaktów marketingowych */
  MarketingPhoneConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<DateTimeOperationFilterInput>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<StringOperationFilterInput>;
  /** Zgoda na otrzymywanie newslettera */
  NewsletterConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Zgoda na przetwarzanie danych osobowych w celach marketingowych Symfonii. (obowiązkowe) */
  PersonalDataConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Zgoda na przetwarzanie danych osobowych w celach marketingowych przez Partnerów Symfonii. */
  PersonalDataForPartnersConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Numer telefonu użytkownika */
  PhoneNumber?: InputMaybe<StringOperationFilterInput>;
  /** Akceptacja polityki prywatności (obowiązkowe) */
  PrivacyPolicyConsent?: InputMaybe<BooleanOperationFilterInput>;
  /** Przypisane role */
  RoleAssignments?: InputMaybe<ListFilterInputTypeOfRoleAssignmentFilterInput>;
  Tenants?: InputMaybe<ListFilterInputTypeOfTenantFilterInput>;
  and?: InputMaybe<Array<UserFilterInput>>;
  or?: InputMaybe<Array<UserFilterInput>>;
};

/** Użytkownik organizacji */
export type UserInput = {
  /** Warunki użytkowania Programu w chmurze https://materialy.symfonia.pl/publiczne/a8ca55 (obowiązkowe) */
  CloudConsent?: InputMaybe<Scalars['Boolean']>;
  /** Oświadczenie o upoważnienie do działania w imieniu reprezentowanego podmiotu: "Oświadczam, że jestem uprawniony/-a do działania w imieniu i ze skutkiem dla podmiotu, który reprezentuję." (obowiązkowe) */
  CompanyRepresentationConsent?: InputMaybe<Scalars['Boolean']>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<Scalars['String']>;
  /** Adres email użytkownika */
  Email: Scalars['String'];
  /** Imię użytkownika */
  FirstName: Scalars['String'];
  /** Unikalny Id rekordu */
  Id: Scalars['UUID'];
  /** Nazwisko użytkownika */
  LastName: Scalars['String'];
  /** Zgoda na wykorzystanie e-mail do celów marketingowych */
  MarketingEmailConsent?: InputMaybe<Scalars['Boolean']>;
  /** Zgoda na używanie telekomunikacyjnych urządzeń końcowych do kontaktów marketingowych */
  MarketingEndDeviceConsent?: InputMaybe<Scalars['Boolean']>;
  /** Zgoda na używanie telefonu do kontaktów marketingowych */
  MarketingPhoneConsent?: InputMaybe<Scalars['Boolean']>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<Scalars['DateTime']>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<Scalars['String']>;
  /** Zgoda na otrzymywanie newslettera */
  NewsletterConsent?: InputMaybe<Scalars['Boolean']>;
  /** Zgoda na przetwarzanie danych osobowych w celach marketingowych Symfonii. (obowiązkowe) */
  PersonalDataConsent?: InputMaybe<Scalars['Boolean']>;
  /** Zgoda na przetwarzanie danych osobowych w celach marketingowych przez Partnerów Symfonii. */
  PersonalDataForPartnersConsent?: InputMaybe<Scalars['Boolean']>;
  /** Numer telefonu użytkownika */
  PhoneNumber?: InputMaybe<Scalars['String']>;
  /** Akceptacja polityki prywatności (obowiązkowe) */
  PrivacyPolicyConsent?: InputMaybe<Scalars['Boolean']>;
  /** Przypisane role */
  RoleAssignments?: InputMaybe<Array<InputMaybe<RoleAssignmentInput>>>;
  Tenants?: InputMaybe<Array<InputMaybe<TenantInput>>>;
};

/** Ustawienia użytkownika w aplikacji Symfonia */
export type UserProperties = {
  /**
   * Ustawienia modułu AutoPosting w Symfonia KSeF.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}}) / [`TENANT`]({{Types.EnvironmentType.TENANT}}) / [`ADMIN`]({{Types.EnvironmentType.ADMIN}})
   */
  AutoPosting?: Maybe<AutoPostingUserPropertiesType>;
  /**
   * Ustawienia użytkownika w Symfonia KSeF.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}}) / [`TENANT`]({{Types.EnvironmentType.TENANT}}) / [`ADMIN`]({{Types.EnvironmentType.ADMIN}})
   */
  EArchive: EArchiveUserPropertiesType;
  /**
   * Status autoryzacji oraz uprawnienia użytkownika w KSeF Ministerstwa Finansów.
   *
   * EnvironmentId: [`COMPANY`]({{Types.EnvironmentType.COMPANY}}) / [`TENANT`]({{Types.EnvironmentType.TENANT}}) / [`ADMIN`]({{Types.EnvironmentType.ADMIN}})
   */
  KSeF: KSeFUserPropertiesType;
};

/** Użytkownik organizacji */
export type UserSortInput = {
  /** Warunki użytkowania Programu w chmurze https://materialy.symfonia.pl/publiczne/a8ca55 (obowiązkowe) */
  CloudConsent?: InputMaybe<SortEnumType>;
  /** Oświadczenie o upoważnienie do działania w imieniu reprezentowanego podmiotu: "Oświadczam, że jestem uprawniony/-a do działania w imieniu i ze skutkiem dla podmiotu, który reprezentuję." (obowiązkowe) */
  CompanyRepresentationConsent?: InputMaybe<SortEnumType>;
  /** Data utworzenia rekordu */
  CreatedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który utworzył rekord */
  CreatedBy?: InputMaybe<SortEnumType>;
  /** Adres email użytkownika */
  Email?: InputMaybe<SortEnumType>;
  /** Imię użytkownika */
  FirstName?: InputMaybe<SortEnumType>;
  /** Unikalny Id rekordu */
  Id?: InputMaybe<SortEnumType>;
  /** Nazwisko użytkownika */
  LastName?: InputMaybe<SortEnumType>;
  /** Zgoda na wykorzystanie e-mail do celów marketingowych */
  MarketingEmailConsent?: InputMaybe<SortEnumType>;
  /** Zgoda na używanie telekomunikacyjnych urządzeń końcowych do kontaktów marketingowych */
  MarketingEndDeviceConsent?: InputMaybe<SortEnumType>;
  /** Zgoda na używanie telefonu do kontaktów marketingowych */
  MarketingPhoneConsent?: InputMaybe<SortEnumType>;
  /** Data modyfikacji rekordu */
  ModifiedAt?: InputMaybe<SortEnumType>;
  /** E-mail użytkownika, który zmodyfikował rekord */
  ModifiedBy?: InputMaybe<SortEnumType>;
  /** Zgoda na otrzymywanie newslettera */
  NewsletterConsent?: InputMaybe<SortEnumType>;
  /** Zgoda na przetwarzanie danych osobowych w celach marketingowych Symfonii. (obowiązkowe) */
  PersonalDataConsent?: InputMaybe<SortEnumType>;
  /** Zgoda na przetwarzanie danych osobowych w celach marketingowych przez Partnerów Symfonii. */
  PersonalDataForPartnersConsent?: InputMaybe<SortEnumType>;
  /** Numer telefonu użytkownika */
  PhoneNumber?: InputMaybe<SortEnumType>;
  /** Akceptacja polityki prywatności (obowiązkowe) */
  PrivacyPolicyConsent?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type UsersCollectionSegment = {
  /** A flattened list of the items. */
  items?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type VatRegisterSortInput = {
  CompanyId?: InputMaybe<SortEnumType>;
  Correction?: InputMaybe<SortEnumType>;
  Id?: InputMaybe<SortEnumType>;
  IsActive?: InputMaybe<SortEnumType>;
  KindId?: InputMaybe<SortEnumType>;
  KindName?: InputMaybe<SortEnumType>;
  Margin?: InputMaybe<SortEnumType>;
  Name?: InputMaybe<SortEnumType>;
  SourceId?: InputMaybe<SortEnumType>;
  Type?: InputMaybe<SortEnumType>;
};

export type VatRegistriesResponse = {
  Id: Scalars['UUID'];
  Name: Scalars['String'];
};

export type VatSummaryChangeRequestInput = {
  VatRegisterId: Scalars['UUID'];
  VatSummaryLineId: Scalars['UUID'];
};

export type VatSummaryResponse = {
  GTUItems?: Maybe<Array<GtuCodeEnum>>;
  Gross: Scalars['Decimal'];
  Id: Scalars['UUID'];
  Net: Scalars['Decimal'];
  TaxRate: StawkaPodatku;
  Vat: Scalars['Decimal'];
  VatRegisterId?: Maybe<Scalars['UUID']>;
  VatRegisterName?: Maybe<Scalars['String']>;
};

export enum VehicleUsageTypeEnum {
  Company = 'COMPANY',
  Mixed = 'MIXED',
  Private = 'PRIVATE',
  Undefined = 'UNDEFINED'
}

export type VehicleUsageTypeEnumOperationFilterInput = {
  eq?: InputMaybe<VehicleUsageTypeEnum>;
  in?: InputMaybe<Array<VehicleUsageTypeEnum>>;
  neq?: InputMaybe<VehicleUsageTypeEnum>;
  nin?: InputMaybe<Array<VehicleUsageTypeEnum>>;
};

export type WsSubscription = {
  onAutoPostingInitializationCompleted: OnAutoPostingInitializationCompletedSubscriptionResponse;
  onAutoPostingInitializationStatusChanged: OnAutoPostingInitializationStatusChangedResponse;
  onAutoPostingResetInitializationCompleted: OnAutoPostingResetInitializationCompletedResponse;
  /** Subskrypcja na EventMessage przy wykorzystaniu onKSeFNotificationUserSubscriber do zbudowania odpowiedniego topiku */
  onKSeFNotificationUser: StandardWebsocketNotification;
  /** Subskrypcja websocket dotycząca stanu połączenia z KSeF */
  onKSeFOperatingStatusUpdateGeneral: KSeFOperatingStatusMessage;
};


export type WsSubscriptionOnAutoPostingInitializationCompletedArgs = {
  companyId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


export type WsSubscriptionOnAutoPostingInitializationStatusChangedArgs = {
  companyId: Scalars['UUID'];
};


export type WsSubscriptionOnAutoPostingResetInitializationCompletedArgs = {
  companyId: Scalars['UUID'];
};


export type WsSubscriptionOnKSeFNotificationUserArgs = {
  companyId: Scalars['String'];
  userId: Scalars['String'];
};

/** Parametry warunki transakcji */
export type WarunkiTransakcji = {
  /** Numery partii towaru */
  NrPartiiTowaru?: Maybe<Array<Scalars['String']>>;
  /** Lista zdarzeń transportowych */
  Transport?: Maybe<Array<TTransport>>;
  /** Lista umów */
  Umowy?: Maybe<Array<TUmowa>>;
  /** Warunki dostawy towaru lub usługi */
  WarunkiDostawy?: Maybe<Scalars['String']>;
  /** Lista zamówień */
  Zamowienia?: Maybe<Array<TZamowienie>>;
};

/** Parametry warunki transakcji */
export type WarunkiTransakcjiInput = {
  /** Numery partii towaru */
  NrPartiiTowaru?: InputMaybe<Array<Scalars['String']>>;
  /** Lista zdarzeń transportowych */
  Transport?: InputMaybe<Array<TTransportInput>>;
  /** Lista umów */
  Umowy?: InputMaybe<Array<TUmowaInput>>;
  /** Warunki dostawy towaru lub usługi */
  WarunkiDostawy?: InputMaybe<Scalars['String']>;
  /** Lista zamówień */
  Zamowienia?: InputMaybe<Array<TZamowienieInput>>;
};

/** Warunki transakcji */
export type WarunkiTransakcjiV2 = {
  /**
   * Kurs umowny - w przypadkach, gdy na fakturze znajduje się informacja o kursie, po którym zostały przeliczone kwoty wykazane na fakturze w PLN. Nie dotyczy przypadków, o których mowa w Dziale VI ustawy.
   * Typ wykorzystywany do określenia ilości. Wartość numeryczna 22 znaki max, w tym 6 po przecinku.
   * Total number of digits: 22.
   * Total number of digits in fraction: 6.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,6})?.
   */
  KursUmowny?: Maybe<Scalars['Decimal']>;
  /** Numery partii towaru */
  NrPartiiTowaru?: Maybe<Array<Scalars['String']>>;
  /** Wartość "1" oznacza dostawę dokonaną przez podmiot, o którym mowa w art. 22 ust. 2d ustawy. Pole dotyczy przypadku, w którym podmiot uczestniczy w transakcji łańcuchowej innej niż procedura trójstronna uproszczona, o której mowa w art. 135 ust. 1 pkt 4 ustawy */
  PodmiotPosredniczacy?: Maybe<Scalars['Boolean']>;
  /** Lista zdarzeń transportowych */
  Transport?: Maybe<Array<TTransportV2>>;
  /** Lista umów */
  Umowy?: Maybe<Array<TUmowa>>;
  /** Waluta umowna - trzyliterowy kod waluty (ISO-4217) w przypadkach, gdy na fakturze znajduje się informacja o kursie, po którym zostały przeliczone kwoty wykazane na fakturze w złotych. Nie dotyczy przypadków, o których mowa w Dziale VI ustawy */
  WalutaUmowna?: Maybe<KodWaluty>;
  /** Warunki dostawy towaru lub usługi */
  WarunkiDostawy?: Maybe<Scalars['String']>;
  /** Lista zamówień */
  Zamowienia?: Maybe<Array<TZamowienie>>;
};

/** Warunki transakcji */
export type WarunkiTransakcjiV2Input = {
  /**
   * Kurs umowny - w przypadkach, gdy na fakturze znajduje się informacja o kursie, po którym zostały przeliczone kwoty wykazane na fakturze w PLN. Nie dotyczy przypadków, o których mowa w Dziale VI ustawy.
   * Typ wykorzystywany do określenia ilości. Wartość numeryczna 22 znaki max, w tym 6 po przecinku.
   * Total number of digits: 22.
   * Total number of digits in fraction: 6.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,6})?.
   */
  KursUmowny?: InputMaybe<Scalars['Decimal']>;
  /** Numery partii towaru */
  NrPartiiTowaru?: InputMaybe<Array<Scalars['String']>>;
  /** Wartość "1" oznacza dostawę dokonaną przez podmiot, o którym mowa w art. 22 ust. 2d ustawy. Pole dotyczy przypadku, w którym podmiot uczestniczy w transakcji łańcuchowej innej niż procedura trójstronna uproszczona, o której mowa w art. 135 ust. 1 pkt 4 ustawy */
  PodmiotPosredniczacy?: InputMaybe<Scalars['Boolean']>;
  /** Lista zdarzeń transportowych */
  Transport?: InputMaybe<Array<TTransportV2Input>>;
  /** Lista umów */
  Umowy?: InputMaybe<Array<TUmowaInput>>;
  /** Waluta umowna - trzyliterowy kod waluty (ISO-4217) w przypadkach, gdy na fakturze znajduje się informacja o kursie, po którym zostały przeliczone kwoty wykazane na fakturze w złotych. Nie dotyczy przypadków, o których mowa w Dziale VI ustawy */
  WalutaUmowna?: InputMaybe<KodWaluty>;
  /** Warunki dostawy towaru lub usługi */
  WarunkiDostawy?: InputMaybe<Scalars['String']>;
  /** Lista zamówień */
  Zamowienia?: InputMaybe<Array<TZamowienieInput>>;
};

/** Typy błędów subskrypcji websocket */
export enum WebsocketErrorType {
  /** Błąd autoryzacji w KSeF */
  KsefAuthorization = 'KSEF_AUTHORIZATION',
  /** Błąd połączenia z serwerem KSeF */
  KsefConnection = 'KSEF_CONNECTION',
  /** Błąd wygenerowany przez KSeF */
  KsefError = 'KSEF_ERROR',
  /** Błąd limitu zapytań do KSeF */
  KsefLimit = 'KSEF_LIMIT',
  /** Błąd systemowy */
  System = 'SYSTEM',
  /** Błąd walidacji */
  Validation = 'VALIDATION'
}

/** Obiekt notyfikacji websocket */
export type WebsocketNotificationModel = {
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Dane szczegółowe błędu */
  ErrorContent?: Maybe<Array<KeyValuePairOfStringAndString>>;
  /** Typ błędu */
  ErrorType?: Maybe<WebsocketErrorType>;
  /** Flaga informująca, czy wynik operacji stanowi zawartość do pobrania */
  HasDownloadableContent: Scalars['Boolean'];
  /** Id joba w systemie kolejkowym */
  JobId?: Maybe<Scalars['String']>;
  /** Informacja o operacji */
  Message?: Maybe<Scalars['String']>;
  /** Id notyfikacji */
  NotificationId: Scalars['UUID'];
  /** Dane wyniku operacji w formacie JSON */
  ResponseJson?: Maybe<Scalars['String']>;
  /** Typ uruchomienia notyfikacji */
  SimultaneousRunType: WebsocketSimultaneousRunType;
  /** Status notyfikacji */
  State: SubscriptionStateEnum;
  /** Data utworzenia */
  Timestamp: Scalars['DateTime'];
  /** Typ notyfikacji */
  Type: WebsocketNotificationType;
  /** Id użytkownika */
  UserId: Scalars['UUID'];
  /** Flaga informująca, czy użytkownik zapoznał się w notyfikacją */
  Visited: Scalars['Boolean'];
};

/** Obiekt notyfikacji websocket */
export type WebsocketNotificationModelInput = {
  /** Id firmy */
  CompanyId: Scalars['UUID'];
  /** Dane szczegółowe błędu */
  ErrorContent?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  /** Typ błędu */
  ErrorType?: InputMaybe<WebsocketErrorType>;
  /** Flaga informująca, czy wynik operacji stanowi zawartość do pobrania */
  HasDownloadableContent: Scalars['Boolean'];
  /** Id joba w systemie kolejkowym */
  JobId?: InputMaybe<Scalars['String']>;
  /** Informacja o operacji */
  Message?: InputMaybe<Scalars['String']>;
  /** Id notyfikacji */
  NotificationId: Scalars['UUID'];
  /** Dane wyniku operacji w formacie JSON */
  ResponseJson?: InputMaybe<Scalars['String']>;
  /** Typ uruchomienia notyfikacji */
  SimultaneousRunType: WebsocketSimultaneousRunType;
  /** Status notyfikacji */
  State: SubscriptionStateEnum;
  /** Data utworzenia */
  Timestamp: Scalars['DateTime'];
  /** Typ notyfikacji */
  Type: WebsocketNotificationType;
  /** Id użytkownika */
  UserId: Scalars['UUID'];
  /** Flaga informująca, czy użytkownik zapoznał się w notyfikacją */
  Visited: Scalars['Boolean'];
};

/** Grupy notyfikacji WebSocket-owych dla statusów [`SubscriptionStateEnum`]({{Types.SubscriptionStateEnum}}) */
export enum WebsocketNotificationStateType {
  /** Tylko zakolejkowane i trwające */
  Active = 'ACTIVE',
  /** Wszystkie statusy */
  All = 'ALL',
  /** Tylko archiwalne */
  Archived = 'ARCHIVED'
}

/** Typy subskrypcji websocket */
export enum WebsocketNotificationType {
  /** Autoryzacja podpisem */
  AuthorizeInKsef = 'AUTHORIZE_IN_KSEF',
  /** Autoryzacja zewnętrznym tokenem */
  AuthorizeInKsefExternalToken = 'AUTHORIZE_IN_KSEF_EXTERNAL_TOKEN',
  /** Automatyczne pobieranie faktur */
  AutoFetchingInvoices = 'AUTO_FETCHING_INVOICES',
  /** Automatyczne wysyłanie faktur */
  AutoSendingInvoices = 'AUTO_SENDING_INVOICES',
  /** Automatyczne usunięcie autoryzacji zewnętrznym tokenem po otrzymaniu z KSeF błędu autoryzacji */
  AutoUnauthorizedInKseFExternalToken = 'AUTO_UNAUTHORIZED_IN_KSE_F_EXTERNAL_TOKEN',
  /** Usuwanie faktur wybranych przez użytkownika. Usuwa dokument z bazy danych aplikacji. */
  DeleteInvoices = 'DELETE_INVOICES',
  /** Pobieranie faktur */
  DownloadInvoices = 'DOWNLOAD_INVOICES',
  /** Pobieranie UPO */
  GetUpo = 'GET_UPO',
  /** Nadawanie uprawnień */
  GrantPermission = 'GRANT_PERMISSION',
  /** Zmiana statusu dekretacji faktury */
  InvoicePostingStatusChanged = 'INVOICE_POSTING_STATUS_CHANGED',
  /** Informacja, że faktura z OCR została zaakceptowana i zaimportowana do Symfonia KSeF */
  OcrInvoiceImported = 'OCR_INVOICE_IMPORTED',
  /** Błąd dekretacji faktury */
  PostInvoicesFailed = 'POST_INVOICES_FAILED',
  /** Przypisanie numeru ewidencyjnego do faktury po zaksięgowaniu dekretu */
  RegistrationNumberCreated = 'REGISTRATION_NUMBER_CREATED',
  /** Wysyłanie faktur */
  SendingInvoices = 'SENDING_INVOICES',
  /** Synchronizacja uprawnień użytkownika w KSeF MF */
  SyncPermission = 'SYNC_PERMISSION',
  /** Kasowanie sesji */
  TerminateSession = 'TERMINATE_SESSION',
  /** Usunięcie autoryzacji podpisem */
  UnauthorizedInKsef = 'UNAUTHORIZED_IN_KSEF',
  /** Usunięcie autoryzacji zewnętrznym tokenem */
  UnauthorizedInKsefExternalToken = 'UNAUTHORIZED_IN_KSEF_EXTERNAL_TOKEN',
  /** Wgrywanie faktur z dysku */
  UploadInvoices = 'UPLOAD_INVOICES',
  /** Walidacja numeru rachunku bankowego kontrahenta na białej liście */
  WhiteListValidation = 'WHITE_LIST_VALIDATION'
}

export enum WebsocketSimultaneousRunType {
  /** Operacje, które mogą się wykonać równolegle i nie muszą czekać na zakończenie poprzedniej operacji */
  Parallel = 'PARALLEL',
  /** Operacje, które mogą się wykonać równolegle ale posiadające inne parametry wejściowe */
  ParallelWithDifferentInputs = 'PARALLEL_WITH_DIFFERENT_INPUTS',
  /** Operacje, które muszą czekać na zakończenie poprzedniej operacji */
  Sequential = 'SEQUENTIAL'
}

/** Wynik sprawdzania danych faktury względem białej listy kontrahentów */
export type WhiteListItem = {
  /** Numer NIP kontrahenta */
  identifier?: Maybe<Scalars['String']>;
  /** Id faktury w Symfonia KSeF */
  invoiceId: Scalars['UUID'];
  /** Nazwa wystawcy faktury */
  issuerName?: Maybe<Scalars['String']>;
  /** Lista sprawdzeń */
  whiteListResult?: Maybe<Array<Maybe<WhiteListServiceResult>>>;
};

/** Model walidacji konta na białej liście */
export type WhiteListServiceResult = {
  /** Numer konta */
  Account?: Maybe<Scalars['String']>;
  /** Data sprawdzenia */
  Date: Scalars['DateTime'];
  /** Opis błędu */
  ErrorMessage?: Maybe<Scalars['String']>;
  /** Czy numer konta jest na białej liście */
  IsWhiteListed: Scalars['Boolean'];
};

/** Model sprawdzenia kont bankowych kontrahenta na białej liście */
export type WhiteListStatusDtoModel = {
  /** Lista kont bankowych kontrahenta zawarte na fakturze */
  Accounts?: Maybe<Array<Maybe<WhiteListServiceResult>>>;
  /** Sprawdzone na białej liście */
  IsValidated: Scalars['Boolean'];
};

export type WhiteListValidation = {
  /** Adres email użytkownika który ostatni modyfikował działanie usługi */
  LastModifiedBy?: Maybe<Scalars['String']>;
  /** Data ostatniej modyfikacji */
  LastModifiedDate?: Maybe<Scalars['DateTime']>;
  /** Stan usługi automatycznego sprawdzania rachunków bankowych kontrahentów na białej liście */
  Status: Scalars['Boolean'];
  /** Id użytkownika który udostępnia dostęp do pobierania faktur */
  UserId?: Maybe<Scalars['UUID']>;
};

/** Wiersz pozycji na fakturze */
export type Wiersz = {
  /** Symbol Nomenklatury Scalonej */
  CN?: Maybe<Scalars['String']>;
  /** Cena wraz z kwotą podatku (cena jednostkowa brutto), w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  CenaBrutto?: Maybe<Scalars['Decimal']>;
  /**
   * Cena jednostkowa towaru lub usługi bez kwoty podatku (cena jednostkowa netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  CenaNetto?: Maybe<Scalars['Decimal']>;
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się dla przypadku, gdy dla poszczególnych pozycji faktury występują różne daty
   */
  DataDostawy?: Maybe<Scalars['DateTime']>;
  /** Pole przeznaczone do wpisania przez sprzedawcę dodatkowych danych, które nie zostały uwzględnione w elementach struktury i nie są wymagane przepisami, a które mogą znaleźć się na fakturze */
  DodatkoweInfo?: Maybe<Scalars['String']>;
  /** Globalny numer jednostki handlowej */
  GTIN?: Maybe<Scalars['String']>;
  /** Oznaczenie dotyczące dostawy towarów i świadczenia usług */
  GTU?: Maybe<Gtu>;
  /** Ilość (liczba) dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  Ilosc?: Maybe<Scalars['Decimal']>;
  /** Miara dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  JM?: Maybe<Scalars['String']>;
  /** Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w art. 31a ustawy */
  KursWaluty?: Maybe<Scalars['Decimal']>;
  /** Kwota podatku akcyzowego zawarta w cenie towaru */
  KwotaAkcyzy?: Maybe<Scalars['Decimal']>;
  /**
   * Kwoty wszelkich opustów lub obniżek cen, w tym w formie rabatu z tytułu wcześniejszej zapłaty, o ile nie zostały one uwzględnione w cenie jednostkowej netto.
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 1 ustawy
   */
  KwotaOpustu?: Maybe<Scalars['Decimal']>;
  /** Kolejny numer wiersza faktury */
  LP?: Maybe<Scalars['Long']>;
  /** Nazwa (rodzaj) towaru lub usługi. Pole opcjonalne wyłącznie dla przypadku określonego w art 106j ust. 3 pkt 2 ustawy (faktura korekta) */
  Nazwa?: Maybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Obiektów Budowlanych */
  PKOB?: Maybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Wyrobów i Usług */
  PKWIU?: Maybe<Scalars['String']>;
  /** Oznaczenia dotyczące procedur dla faktur */
  Procedura?: Maybe<OznaczenieProcedury>;
  /** Wskazanie procedury dla wiersza faktury */
  ProceduraVat?: Maybe<ProceduraVat>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaOSS?: Maybe<Scalars['Decimal']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaPodatku?: Maybe<StawkaPodatku>;
  /** Uniwersalny unikalny numer wiersza faktury */
  UnikalnyNumer?: Maybe<Scalars['String']>;
  /** Wartość sprzedaży brutto, w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  WartoscBrutto?: Maybe<Scalars['Decimal']>;
  /**
   * Wartość dostarczonych towarów lub wykonanych usług, objętych transakcją, bez podatku (wartość sprzedaży netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  WartoscNetto?: Maybe<Scalars['Decimal']>;
};

/** Wiersz pozycji na fakturze */
export type WierszInput = {
  /** Symbol Nomenklatury Scalonej */
  CN?: InputMaybe<Scalars['String']>;
  /** Cena wraz z kwotą podatku (cena jednostkowa brutto), w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  CenaBrutto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Cena jednostkowa towaru lub usługi bez kwoty podatku (cena jednostkowa netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  CenaNetto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się dla przypadku, gdy dla poszczególnych pozycji faktury występują różne daty
   */
  DataDostawy?: InputMaybe<Scalars['DateTime']>;
  /** Pole przeznaczone do wpisania przez sprzedawcę dodatkowych danych, które nie zostały uwzględnione w elementach struktury i nie są wymagane przepisami, a które mogą znaleźć się na fakturze */
  DodatkoweInfo?: InputMaybe<Scalars['String']>;
  /** Globalny numer jednostki handlowej */
  GTIN?: InputMaybe<Scalars['String']>;
  /** Oznaczenie dotyczące dostawy towarów i świadczenia usług */
  GTU?: InputMaybe<Gtu>;
  /** Ilość (liczba) dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  Ilosc?: InputMaybe<Scalars['Decimal']>;
  /** Miara dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  JM?: InputMaybe<Scalars['String']>;
  /** Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w art. 31a ustawy */
  KursWaluty?: InputMaybe<Scalars['Decimal']>;
  /** Kwota podatku akcyzowego zawarta w cenie towaru */
  KwotaAkcyzy?: InputMaybe<Scalars['Decimal']>;
  /**
   * Kwoty wszelkich opustów lub obniżek cen, w tym w formie rabatu z tytułu wcześniejszej zapłaty, o ile nie zostały one uwzględnione w cenie jednostkowej netto.
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 1 ustawy
   */
  KwotaOpustu?: InputMaybe<Scalars['Decimal']>;
  /** Kolejny numer wiersza faktury */
  LP?: InputMaybe<Scalars['Long']>;
  /** Nazwa (rodzaj) towaru lub usługi. Pole opcjonalne wyłącznie dla przypadku określonego w art 106j ust. 3 pkt 2 ustawy (faktura korekta) */
  Nazwa?: InputMaybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Obiektów Budowlanych */
  PKOB?: InputMaybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Wyrobów i Usług */
  PKWIU?: InputMaybe<Scalars['String']>;
  /** Oznaczenia dotyczące procedur dla faktur */
  Procedura?: InputMaybe<OznaczenieProcedury>;
  /** Wskazanie procedury dla wiersza faktury */
  ProceduraVat?: InputMaybe<ProceduraVat>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaOSS?: InputMaybe<Scalars['Decimal']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaPodatku?: InputMaybe<StawkaPodatku>;
  /** Uniwersalny unikalny numer wiersza faktury */
  UnikalnyNumer?: InputMaybe<Scalars['String']>;
  /** Wartość sprzedaży brutto, w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  WartoscBrutto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Wartość dostarczonych towarów lub wykonanych usług, objętych transakcją, bez podatku (wartość sprzedaży netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  WartoscNetto?: InputMaybe<Scalars['Decimal']>;
};

/** Wiersz pozycji na fakturze */
export type WierszV2 = {
  /** Symbol Nomenklatury Scalonej */
  CN?: Maybe<Scalars['String']>;
  /** Cena wraz z kwotą podatku (cena jednostkowa brutto), w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  CenaBrutto?: Maybe<Scalars['Decimal']>;
  /**
   * Cena jednostkowa towaru lub usługi bez kwoty podatku (cena jednostkowa netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  CenaNetto?: Maybe<Scalars['Decimal']>;
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się dla przypadku, gdy dla poszczególnych pozycji faktury występują różne daty
   */
  DataDostawy?: Maybe<Scalars['DateTime']>;
  /** Pole przeznaczone do wpisania przez sprzedawcę dodatkowych danych, które nie zostały uwzględnione w elementach struktury i nie są wymagane przepisami, a które mogą znaleźć się na fakturze */
  DodatkoweInfo?: Maybe<Scalars['String']>;
  /** Globalny numer jednostki handlowej */
  GTIN?: Maybe<Scalars['String']>;
  /** Oznaczenie dotyczące dostawy towarów i świadczenia usług */
  GTU?: Maybe<Gtu>;
  /** Ilość (liczba) dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  Ilosc?: Maybe<Scalars['Decimal']>;
  /** Miara dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  JM?: Maybe<Scalars['String']>;
  /** Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w art. 31a ustawy */
  KursWaluty?: Maybe<Scalars['Decimal']>;
  /** Kwota podatku akcyzowego zawarta w cenie towaru */
  KwotaAkcyzy?: Maybe<Scalars['Decimal']>;
  /**
   * Kwoty wszelkich opustów lub obniżek cen, w tym w formie rabatu z tytułu wcześniejszej zapłaty, o ile nie zostały one uwzględnione w cenie jednostkowej netto.
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 1 ustawy
   */
  KwotaOpustu?: Maybe<Scalars['Decimal']>;
  /** Kolejny numer wiersza faktury */
  LP?: Maybe<Scalars['Long']>;
  /** Nazwa (rodzaj) towaru lub usługi. Pole opcjonalne wyłącznie dla przypadku określonego w art 106j ust. 3 pkt 2 ustawy (faktura korekta) */
  Nazwa?: Maybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Obiektów Budowlanych */
  PKOB?: Maybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Wyrobów i Usług */
  PKWIU?: Maybe<Scalars['String']>;
  /** Oznaczenia dotyczące procedur dla faktur */
  Procedura?: Maybe<OznaczenieProcedury>;
  /** Wskazanie procedury dla wiersza faktury */
  ProceduraVat?: Maybe<ProceduraVat>;
  /** Znacznik stanu przed korektą w przypadku faktury korygującej lub faktury korygującej fakturę wystawioną w związku z art. 106f ust. 3 ustawy, w przypadku gdy korekta dotyczy danych wykazanych w pozycjach faktury i jest dokonywana w sposób polegający na wykazaniu danych przed korektą i po korekcie jako osobnych wierszy z odrębną numeracją oraz w przypadku potwierdzania braku zmiany wartości danej pozycji */
  StanPrzed?: Maybe<Scalars['Boolean']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaOSS?: Maybe<Scalars['Decimal']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaPodatku?: Maybe<StawkaPodatku>;
  /** Uniwersalny unikalny numer wiersza faktury */
  UnikalnyNumer?: Maybe<Scalars['String']>;
  /** Wartość sprzedaży brutto, w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  WartoscBrutto?: Maybe<Scalars['Decimal']>;
  /**
   * Wartość dostarczonych towarów lub wykonanych usług, objętych transakcją, bez podatku (wartość sprzedaży netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  WartoscNetto?: Maybe<Scalars['Decimal']>;
  /** Kwota podatku w przypadku, o którym mowa w art. 106e ust. 10 ustawy */
  WartoscVat?: Maybe<Scalars['Decimal']>;
  /** Znacznik dla towaru lub usługi wymienionych w załączniku nr 15 do ustawy - wartość "1" */
  ZnacznikTowaruLubUslugi?: Maybe<Scalars['Boolean']>;
};

/** Wiersz pozycji na fakturze */
export type WierszV2Input = {
  /** Symbol Nomenklatury Scalonej */
  CN?: InputMaybe<Scalars['String']>;
  /** Cena wraz z kwotą podatku (cena jednostkowa brutto), w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  CenaBrutto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Cena jednostkowa towaru lub usługi bez kwoty podatku (cena jednostkowa netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  CenaNetto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się dla przypadku, gdy dla poszczególnych pozycji faktury występują różne daty
   */
  DataDostawy?: InputMaybe<Scalars['DateTime']>;
  /** Pole przeznaczone do wpisania przez sprzedawcę dodatkowych danych, które nie zostały uwzględnione w elementach struktury i nie są wymagane przepisami, a które mogą znaleźć się na fakturze */
  DodatkoweInfo?: InputMaybe<Scalars['String']>;
  /** Globalny numer jednostki handlowej */
  GTIN?: InputMaybe<Scalars['String']>;
  /** Oznaczenie dotyczące dostawy towarów i świadczenia usług */
  GTU?: InputMaybe<Gtu>;
  /** Ilość (liczba) dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  Ilosc?: InputMaybe<Scalars['Decimal']>;
  /** Miara dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  JM?: InputMaybe<Scalars['String']>;
  /** Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w art. 31a ustawy */
  KursWaluty?: InputMaybe<Scalars['Decimal']>;
  /** Kwota podatku akcyzowego zawarta w cenie towaru */
  KwotaAkcyzy?: InputMaybe<Scalars['Decimal']>;
  /**
   * Kwoty wszelkich opustów lub obniżek cen, w tym w formie rabatu z tytułu wcześniejszej zapłaty, o ile nie zostały one uwzględnione w cenie jednostkowej netto.
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 1 ustawy
   */
  KwotaOpustu?: InputMaybe<Scalars['Decimal']>;
  /** Kolejny numer wiersza faktury */
  LP?: InputMaybe<Scalars['Long']>;
  /** Nazwa (rodzaj) towaru lub usługi. Pole opcjonalne wyłącznie dla przypadku określonego w art 106j ust. 3 pkt 2 ustawy (faktura korekta) */
  Nazwa?: InputMaybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Obiektów Budowlanych */
  PKOB?: InputMaybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Wyrobów i Usług */
  PKWIU?: InputMaybe<Scalars['String']>;
  /** Oznaczenia dotyczące procedur dla faktur */
  Procedura?: InputMaybe<OznaczenieProcedury>;
  /** Wskazanie procedury dla wiersza faktury */
  ProceduraVat?: InputMaybe<ProceduraVat>;
  /** Znacznik stanu przed korektą w przypadku faktury korygującej lub faktury korygującej fakturę wystawioną w związku z art. 106f ust. 3 ustawy, w przypadku gdy korekta dotyczy danych wykazanych w pozycjach faktury i jest dokonywana w sposób polegający na wykazaniu danych przed korektą i po korekcie jako osobnych wierszy z odrębną numeracją oraz w przypadku potwierdzania braku zmiany wartości danej pozycji */
  StanPrzed?: InputMaybe<Scalars['Boolean']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaOSS?: InputMaybe<Scalars['Decimal']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaPodatku?: InputMaybe<StawkaPodatku>;
  /** Uniwersalny unikalny numer wiersza faktury */
  UnikalnyNumer?: InputMaybe<Scalars['String']>;
  /** Wartość sprzedaży brutto, w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  WartoscBrutto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Wartość dostarczonych towarów lub wykonanych usług, objętych transakcją, bez podatku (wartość sprzedaży netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  WartoscNetto?: InputMaybe<Scalars['Decimal']>;
  /** Kwota podatku w przypadku, o którym mowa w art. 106e ust. 10 ustawy */
  WartoscVat?: InputMaybe<Scalars['Decimal']>;
  /** Znacznik dla towaru lub usługi wymienionych w załączniku nr 15 do ustawy - wartość "1" */
  ZnacznikTowaruLubUslugi?: InputMaybe<Scalars['Boolean']>;
};

/** Uniwersalny element służący do przechowywania zakresu dat */
export type ZakresDatV2 = {
  /** Data do */
  DataDo: Scalars['DateTime'];
  /** Data od */
  DataOd: Scalars['DateTime'];
};

/** Uniwersalny element służący do przechowywania zakresu dat */
export type ZakresDatV2Input = {
  /** Data do */
  DataDo: Scalars['DateTime'];
  /** Data od */
  DataOd: Scalars['DateTime'];
};

/** Element zaliczka częściowa */
export type ZaliczkaCzesciowaV2 = {
  /**
   * Data otrzymania płatności, o której mowa w art. 106b ust. 1 pkt 4 ustawy
   * Data zdarzenia w okresie od 2006-01-01 do 2050-01-01
   * Minimum inclusive value: 2006-01-01.
   * Maximum inclusive value: 2050-01-01.
   */
  DataPlatnosci: Scalars['DateTime'];
  /**
   * Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w Dziale VI ustawy
   * Typ wykorzystywany do określenia ilości. Wartość numeryczna 22 znaki max, w tym 6 po przecinku
   * Total number of digits: 22.
   * Total number of digits in fraction: 6.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,6})?.
   */
  KursWaluty?: Maybe<Scalars['Decimal']>;
  /**
   * Kwota płatności, o której mowa w art. 106b ust. 1 pkt 4 ustawy, składająca się na kwotę w polu P_15. W przypadku faktur korygujących korekta kwoty wynikającej z faktury korygowanej
   * Wartość numeryczna 18 znaków max, w tym 2 znaki po przecinku
   * Total number of digits: 18.
   * Total number of digits in fraction: 2.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,2})?.
   */
  KwotaPlatnosci: Scalars['Decimal'];
};

/** Element zaliczka częściowa */
export type ZaliczkaCzesciowaV2Input = {
  /**
   * Data otrzymania płatności, o której mowa w art. 106b ust. 1 pkt 4 ustawy
   * Data zdarzenia w okresie od 2006-01-01 do 2050-01-01
   * Minimum inclusive value: 2006-01-01.
   * Maximum inclusive value: 2050-01-01.
   */
  DataPlatnosci: Scalars['DateTime'];
  /**
   * Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w Dziale VI ustawy
   * Typ wykorzystywany do określenia ilości. Wartość numeryczna 22 znaki max, w tym 6 po przecinku
   * Total number of digits: 22.
   * Total number of digits in fraction: 6.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,6})?.
   */
  KursWaluty?: InputMaybe<Scalars['Decimal']>;
  /**
   * Kwota płatności, o której mowa w art. 106b ust. 1 pkt 4 ustawy, składająca się na kwotę w polu P_15. W przypadku faktur korygujących korekta kwoty wynikającej z faktury korygowanej
   * Wartość numeryczna 18 znaków max, w tym 2 znaki po przecinku
   * Total number of digits: 18.
   * Total number of digits in fraction: 2.
   * Pattern: -?([1-9]\d{0,15}|0)(\.\d{1,2})?.
   */
  KwotaPlatnosci: Scalars['Decimal'];
};

/** Zamówienie do faktury */
export type Zamowienie = {
  /** Wartość zamówienia */
  Wartosc: Scalars['Decimal'];
  /** Lista wierszy zamówienia */
  Wiersze?: Maybe<Array<Maybe<Wiersz>>>;
};

/** Zamówienie do faktury */
export type ZamowienieInput = {
  /** Wartość zamówienia */
  Wartosc: Scalars['Decimal'];
  /** Lista wierszy zamówienia */
  Wiersze?: InputMaybe<Array<InputMaybe<WierszInput>>>;
};

/** Element Zamówienie */
export type ZamowienieV2 = {
  /** Wartość zamówienia */
  Wartosc: Scalars['Decimal'];
  /** Lista wierszy zamówienia */
  Wiersze?: Maybe<Array<Maybe<ZamowienieWierszV2>>>;
};

/** Element Zamówienie */
export type ZamowienieV2Input = {
  /** Wartość zamówienia */
  Wartosc: Scalars['Decimal'];
  /** Lista wierszy zamówienia */
  Wiersze?: InputMaybe<Array<InputMaybe<ZamowienieWierszV2Input>>>;
};

/** Element Wiersz zamówienia */
export type ZamowienieWierszV2 = {
  /** Symbol Nomenklatury Scalonej */
  CN?: Maybe<Scalars['String']>;
  /** Cena wraz z kwotą podatku (cena jednostkowa brutto), w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  CenaBrutto?: Maybe<Scalars['Decimal']>;
  /**
   * Cena jednostkowa towaru lub usługi bez kwoty podatku (cena jednostkowa netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  CenaNetto?: Maybe<Scalars['Decimal']>;
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się dla przypadku, gdy dla poszczególnych pozycji faktury występują różne daty
   */
  DataDostawy?: Maybe<Scalars['DateTime']>;
  /** Pole przeznaczone do wpisania przez sprzedawcę dodatkowych danych, które nie zostały uwzględnione w elementach struktury i nie są wymagane przepisami, a które mogą znaleźć się na fakturze */
  DodatkoweInfo?: Maybe<Scalars['String']>;
  /** Globalny numer jednostki handlowej */
  GTIN?: Maybe<Scalars['String']>;
  /** Oznaczenie dotyczące dostawy towarów i świadczenia usług */
  GTU?: Maybe<Gtu>;
  /** Ilość (liczba) dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  Ilosc?: Maybe<Scalars['Decimal']>;
  /** Miara dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  JM?: Maybe<Scalars['String']>;
  /** Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w art. 31a ustawy */
  KursWaluty?: Maybe<Scalars['Decimal']>;
  /** Kwota podatku akcyzowego zawarta w cenie towaru */
  KwotaAkcyzy?: Maybe<Scalars['Decimal']>;
  /**
   * Kwoty wszelkich opustów lub obniżek cen, w tym w formie rabatu z tytułu wcześniejszej zapłaty, o ile nie zostały one uwzględnione w cenie jednostkowej netto.
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 1 ustawy
   */
  KwotaOpustu?: Maybe<Scalars['Decimal']>;
  /** Kolejny numer wiersza faktury */
  LP?: Maybe<Scalars['Long']>;
  /** Nazwa (rodzaj) towaru lub usługi. Pole opcjonalne wyłącznie dla przypadku określonego w art 106j ust. 3 pkt 2 ustawy (faktura korekta) */
  Nazwa?: Maybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Obiektów Budowlanych */
  PKOB?: Maybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Wyrobów i Usług */
  PKWIU?: Maybe<Scalars['String']>;
  /**
   * Stawka podatku od wartości dodanej w przypadku, o którym mowa w dziale XII w rozdziale 6a ustawy
   * Wartość procentowa z dokładnością do 6 miejsc po przecinku
   * Total number of digits: 9.
   * Total number of digits in fraction: 6.
   * Minimum inclusive value: 0.
   * Maximum inclusive value: 100.
   */
  PodatekVatTaxFree?: Maybe<Scalars['Decimal']>;
  /** Oznaczenia dotyczące procedur dla faktur */
  Procedura?: Maybe<OznaczenieProcedury>;
  /** Wskazanie procedury dla wiersza faktury */
  ProceduraVat?: Maybe<ProceduraVat>;
  /** Znacznik stanu przed korektą w przypadku faktury korygującej lub faktury korygującej fakturę wystawioną w związku z art. 106f ust. 3 ustawy, w przypadku gdy korekta dotyczy danych wykazanych w pozycjach faktury i jest dokonywana w sposób polegający na wykazaniu danych przed korektą i po korekcie jako osobnych wierszy z odrębną numeracją oraz w przypadku potwierdzania braku zmiany wartości danej pozycji */
  StanPrzed?: Maybe<Scalars['Boolean']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaOSS?: Maybe<Scalars['Decimal']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaPodatku?: Maybe<StawkaPodatku>;
  /** Uniwersalny unikalny numer wiersza faktury */
  UnikalnyNumer?: Maybe<Scalars['String']>;
  /** Wartość sprzedaży brutto, w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  WartoscBrutto?: Maybe<Scalars['Decimal']>;
  /**
   * Wartość dostarczonych towarów lub wykonanych usług, objętych transakcją, bez podatku (wartość sprzedaży netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  WartoscNetto?: Maybe<Scalars['Decimal']>;
  /** Kwota podatku w przypadku, o którym mowa w art. 106e ust. 10 ustawy */
  WartoscVat?: Maybe<Scalars['Decimal']>;
  /** Znacznik dla towaru lub usługi wymienionych w załączniku nr 15 do ustawy - wartość "1" */
  ZnacznikTowaruLubUslugi?: Maybe<Scalars['Boolean']>;
  /** Znacznik dla towaru lub usługi wymienionych w załączniku nr 15 do ustawy - wartość "1" */
  ZnacznikZamowieniaTowaruLubUslugi?: Maybe<Scalars['Boolean']>;
};

/** Element Wiersz zamówienia */
export type ZamowienieWierszV2Input = {
  /** Symbol Nomenklatury Scalonej */
  CN?: InputMaybe<Scalars['String']>;
  /** Cena wraz z kwotą podatku (cena jednostkowa brutto), w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  CenaBrutto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Cena jednostkowa towaru lub usługi bez kwoty podatku (cena jednostkowa netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  CenaNetto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4 ustawy, o ile taka data jest określona i różni się od daty wystawienia faktury.
   * Pole wypełnia się dla przypadku, gdy dla poszczególnych pozycji faktury występują różne daty
   */
  DataDostawy?: InputMaybe<Scalars['DateTime']>;
  /** Pole przeznaczone do wpisania przez sprzedawcę dodatkowych danych, które nie zostały uwzględnione w elementach struktury i nie są wymagane przepisami, a które mogą znaleźć się na fakturze */
  DodatkoweInfo?: InputMaybe<Scalars['String']>;
  /** Globalny numer jednostki handlowej */
  GTIN?: InputMaybe<Scalars['String']>;
  /** Oznaczenie dotyczące dostawy towarów i świadczenia usług */
  GTU?: InputMaybe<Gtu>;
  /** Ilość (liczba) dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  Ilosc?: InputMaybe<Scalars['Decimal']>;
  /** Miara dostarczonych towarów lub zakres wykonanych usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy */
  JM?: InputMaybe<Scalars['String']>;
  /** Kurs waluty stosowany do wyliczenia kwoty podatku w przypadkach, o których mowa w art. 31a ustawy */
  KursWaluty?: InputMaybe<Scalars['Decimal']>;
  /** Kwota podatku akcyzowego zawarta w cenie towaru */
  KwotaAkcyzy?: InputMaybe<Scalars['Decimal']>;
  /**
   * Kwoty wszelkich opustów lub obniżek cen, w tym w formie rabatu z tytułu wcześniejszej zapłaty, o ile nie zostały one uwzględnione w cenie jednostkowej netto.
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 1 ustawy
   */
  KwotaOpustu?: InputMaybe<Scalars['Decimal']>;
  /** Kolejny numer wiersza faktury */
  LP?: InputMaybe<Scalars['Long']>;
  /** Nazwa (rodzaj) towaru lub usługi. Pole opcjonalne wyłącznie dla przypadku określonego w art 106j ust. 3 pkt 2 ustawy (faktura korekta) */
  Nazwa?: InputMaybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Obiektów Budowlanych */
  PKOB?: InputMaybe<Scalars['String']>;
  /** Symbol Polskiej Klasyfikacji Wyrobów i Usług */
  PKWIU?: InputMaybe<Scalars['String']>;
  /**
   * Stawka podatku od wartości dodanej w przypadku, o którym mowa w dziale XII w rozdziale 6a ustawy
   * Wartość procentowa z dokładnością do 6 miejsc po przecinku
   * Total number of digits: 9.
   * Total number of digits in fraction: 6.
   * Minimum inclusive value: 0.
   * Maximum inclusive value: 100.
   */
  PodatekVatTaxFree?: InputMaybe<Scalars['Decimal']>;
  /** Oznaczenia dotyczące procedur dla faktur */
  Procedura?: InputMaybe<OznaczenieProcedury>;
  /** Wskazanie procedury dla wiersza faktury */
  ProceduraVat?: InputMaybe<ProceduraVat>;
  /** Znacznik stanu przed korektą w przypadku faktury korygującej lub faktury korygującej fakturę wystawioną w związku z art. 106f ust. 3 ustawy, w przypadku gdy korekta dotyczy danych wykazanych w pozycjach faktury i jest dokonywana w sposób polegający na wykazaniu danych przed korektą i po korekcie jako osobnych wierszy z odrębną numeracją oraz w przypadku potwierdzania braku zmiany wartości danej pozycji */
  StanPrzed?: InputMaybe<Scalars['Boolean']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaOSS?: InputMaybe<Scalars['Decimal']>;
  /** Stawka podatku. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2, 3, 4 pkt 3 i ust. 5 pkt 3 ustawy */
  StawkaPodatku?: InputMaybe<StawkaPodatku>;
  /** Uniwersalny unikalny numer wiersza faktury */
  UnikalnyNumer?: InputMaybe<Scalars['String']>;
  /** Wartość sprzedaży brutto, w przypadku zastosowania art. 106e ust. 7 i 8 ustawy */
  WartoscBrutto?: InputMaybe<Scalars['Decimal']>;
  /**
   * Wartość dostarczonych towarów lub wykonanych usług, objętych transakcją, bez podatku (wartość sprzedaży netto).
   * Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3 oraz ust. 5 pkt 3 ustawy
   */
  WartoscNetto?: InputMaybe<Scalars['Decimal']>;
  /** Kwota podatku w przypadku, o którym mowa w art. 106e ust. 10 ustawy */
  WartoscVat?: InputMaybe<Scalars['Decimal']>;
  /** Znacznik dla towaru lub usługi wymienionych w załączniku nr 15 do ustawy - wartość "1" */
  ZnacznikTowaruLubUslugi?: InputMaybe<Scalars['Boolean']>;
  /** Znacznik dla towaru lub usługi wymienionych w załączniku nr 15 do ustawy - wartość "1" */
  ZnacznikZamowieniaTowaruLubUslugi?: InputMaybe<Scalars['Boolean']>;
};

export type OnAutoPostingResetInitializationCompletedResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  hasFailed: Scalars['Boolean'];
};

export type AcceptTenantMutationVariables = Exact<{
  TenantId: Scalars['UUID'];
}>;


export type AcceptTenantMutation = { AcceptTenant: { Accepted: boolean } };

export type ActivateTenantMutationVariables = Exact<{
  TenantId: Scalars['UUID'];
}>;


export type ActivateTenantMutation = { ActivateTenant: { Activated: boolean } };

export type AuthorizeInKSeFMutationVariables = Exact<{
  InitSessionRequestSigned: Scalars['String'];
}>;


export type AuthorizeInKSeFMutation = { AuthorizeInKSeF: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type AuthorizeInKSeFWithTokenMutationVariables = Exact<{
  Token: Scalars['String'];
}>;


export type AuthorizeInKSeFWithTokenMutation = { AuthorizeInKSeFWithToken: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type CancelWebsocketNotificationMutationVariables = Exact<{
  NotificationId: Scalars['UUID'];
}>;


export type CancelWebsocketNotificationMutation = { CancelWebsocketNotification: { Success: boolean } };

export type CreateOrganizationMutationVariables = Exact<{
  Identifier: Scalars['String'];
  IdentifierType: IdentifierType;
  Name: Scalars['String'];
  SubscriptionNumber: Scalars['String'];
  UserFirstName: Scalars['String'];
  UserLastName: Scalars['String'];
  UserPhoneNumber: Scalars['String'];
  CloudConsent: Scalars['Boolean'];
  PersonalDataConsent: Scalars['Boolean'];
  PrivacyPolicyConsent: Scalars['Boolean'];
  CompanyRepresentationConsent: Scalars['Boolean'];
  MarketingEndDeviceConsent: Scalars['Boolean'];
  MarketingPhoneConsent: Scalars['Boolean'];
  NewsletterConsent: Scalars['Boolean'];
  MarketingEmailConsent: Scalars['Boolean'];
  PersonalDataForPartnersConsent: Scalars['Boolean'];
}>;


export type CreateOrganizationMutation = { CreateOrganization: { TenantId: any } };

export type CreateTenantMutationVariables = Exact<{
  TenantName?: InputMaybe<Scalars['String']>;
  IdentifierType: IdentifierType;
  Identifier?: InputMaybe<Scalars['String']>;
}>;


export type CreateTenantMutation = { CreateTenant: { Name?: string | undefined, Id: any } };

export type CreateUserMutationVariables = Exact<{
  Email: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CloudConsent?: InputMaybe<Scalars['Boolean']>;
  PersonalDataConsent?: InputMaybe<Scalars['Boolean']>;
  PrivacyPolicyConsent?: InputMaybe<Scalars['Boolean']>;
  CompanyRepresentationConsent?: InputMaybe<Scalars['Boolean']>;
  MarketingEndDeviceConsent?: InputMaybe<Scalars['Boolean']>;
  MarketingEmailConsent?: InputMaybe<Scalars['Boolean']>;
  PersonalDataForPartnersConsent?: InputMaybe<Scalars['Boolean']>;
  TenantId: Scalars['UUID'];
}>;


export type CreateUserMutation = { CreateUser: { Id: any, Email: string, FirstName: string, LastName: string, CloudConsent?: boolean | undefined, PersonalDataConsent?: boolean | undefined, PrivacyPolicyConsent?: boolean | undefined, CompanyRepresentationConsent?: boolean | undefined, MarketingEndDeviceConsent?: boolean | undefined, PersonalDataForPartnersConsent?: boolean | undefined, Tenants?: Array<{ Id: any, Name?: string | undefined, Identifier?: string | undefined, IdentifierType: IdentifierType, Status: TenantStatus } | undefined> | undefined, RoleAssignments?: Array<{ Id: any, CompanyId?: any | undefined, Company?: { Id: any, Name: string } | undefined } | undefined> | undefined } };

export type DeactivateTenantMutationVariables = Exact<{
  TenantId: Scalars['UUID'];
}>;


export type DeactivateTenantMutation = { DeactivateTenant: { Deactivated: boolean } };

export type DeleteContributorAssignmentMutationVariables = Exact<{
  Id: Scalars['UUID'];
}>;


export type DeleteContributorAssignmentMutation = { DeleteRoleAssignment: boolean };

export type DeleteInvoicesMutationVariables = Exact<{
  InvoiceIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type DeleteInvoicesMutation = { DeleteInvoices: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type DeleteTenantMutationVariables = Exact<{
  Id: Scalars['UUID'];
}>;


export type DeleteTenantMutation = { DeleteTenant: { Deleted: boolean } };

export type DeleteUserMutationVariables = Exact<{
  Id: Scalars['UUID'];
}>;


export type DeleteUserMutation = { DeleteUser: boolean };

export type DownloadInvoicesFromKSeFMutationVariables = Exact<{
  externalEnabled: Scalars['Boolean'];
  internalEnabled: Scalars['Boolean'];
}>;


export type DownloadInvoicesFromKSeFMutation = { DownloadInvoicesFromKSeF: { Imported: number, ErrorItems?: Array<{ KSeFNumber?: string | undefined, ErrorMessage?: string | undefined } | undefined> | undefined } };

export type GenerateInitRequestToLoginMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateInitRequestToLoginMutation = { GenerateInitRequestToLogin: string };

export type GrantPermissionsInKsefMutationVariables = Exact<{
  IsNew: Scalars['Boolean'];
  IdentifierType: IdentifierType;
  Identifier: Scalars['String'];
  Name?: InputMaybe<Scalars['String']>;
  CredentialsInvoiceRead: Scalars['Boolean'];
  CredentialsInvoiceWrite: Scalars['Boolean'];
  CredentialsRead: Scalars['Boolean'];
  CredentialsManage: Scalars['Boolean'];
  CredentialsSelfInvoicing: Scalars['Boolean'];
  NipType: KSeFIdentifierType;
}>;


export type GrantPermissionsInKsefMutation = { SubscribeGrantPermissionsInKSeF: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type KSeFAutoFetchingInvoicesMutationVariables = Exact<{
  Status: CompanyKsefServicesStatusEnum;
  ExternalEnabled: Scalars['Boolean'];
  InternalEnabled: Scalars['Boolean'];
  Type: KSeFAutomaticOperationType;
  Minutes: Scalars['Int'];
  DownloadLimitDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type KSeFAutoFetchingInvoicesMutation = { KSeFAutoFetchingInvoices: { Success: boolean } };

export type KSeFAutoSendingInvoicesMutationVariables = Exact<{
  Status: CompanyKsefServicesStatusEnum;
  type: KSeFAutomaticOperationType;
  minutes: Scalars['Int'];
}>;


export type KSeFAutoSendingInvoicesMutation = { KSeFAutoSendingInvoices: { Success: boolean } };

export type KSeFInvoiceWhiteListValidationMutationVariables = Exact<{
  Status: CompanyKsefServicesStatusEnum;
}>;


export type KSeFInvoiceWhiteListValidationMutation = { KSeFInvoiceWhiteListValidation: { Success: boolean } };

export type KSeFInvoicesDownloadSharedAccessMutationVariables = Exact<{
  Status: CompanyKsefServicesStatusEnum;
}>;


export type KSeFInvoicesDownloadSharedAccessMutation = { KSeFInvoicesDownloadSharedAccess: { Success: boolean } };

export type MultipleSendToKSeFMutationVariables = Exact<{
  InvoiceIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type MultipleSendToKSeFMutation = { MultipleSendToKSeF: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type PublishInvoicesMutationVariables = Exact<{
  InvoiceIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type PublishInvoicesMutation = { PublishInvoices: { Sent: number, Failed: number } };

export type RejectTenantMutationVariables = Exact<{
  TenantId: Scalars['UUID'];
  Remarks: Scalars['String'];
}>;


export type RejectTenantMutation = { RejectTenant: { Rejected: boolean } };

export type RemoveAuthorizationInKSeFMutationVariables = Exact<{
  InitSessionRequestSigned: Scalars['String'];
}>;


export type RemoveAuthorizationInKSeFMutation = { RemoveAuthorizationInKSeF: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type RemoveAuthorizationWithExternalTokenInKSeFMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveAuthorizationWithExternalTokenInKSeFMutation = { RemoveAuthorizationWithExternalTokenInKSeF: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type RemoveEArchiveNotificationsMutationVariables = Exact<{
  EArchiveNotificationType: EArchiveNotificationType;
}>;


export type RemoveEArchiveNotificationsMutation = { RemoveEArchiveNotifications: { Success: boolean } };

export type RemovePlatformNotificationsMutationVariables = Exact<{
  PlatformNotificationType: PlatformNotificationType;
}>;


export type RemovePlatformNotificationsMutation = { RemovePlatformNotifications: { Success: boolean } };

export type SendInvoicesForPostingMutationVariables = Exact<{
  InvoiceIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type SendInvoicesForPostingMutation = { SendInvoicesForPosting: { IsDone: boolean } };

export type SendToKSeFMutationVariables = Exact<{
  InvoiceId: Scalars['UUID'];
}>;


export type SendToKSeFMutation = { SendToKSeF: { InvoiceId: any, KSeFStatus: KSeFStatus } };

export type SetEArchiveNotificationsMutationVariables = Exact<{
  EArchiveNotificationType: EArchiveNotificationType;
}>;


export type SetEArchiveNotificationsMutation = { SetEArchiveNotifications: { Success: boolean } };

export type SetPlatformNotificationsMutationVariables = Exact<{
  PlatformNotificationType: PlatformNotificationType;
}>;


export type SetPlatformNotificationsMutation = { SetPlatformNotifications: { Success: boolean } };

export type SetRoleAssignmentMutationVariables = Exact<{
  RoleId: Scalars['UUID'];
  CompanyIds: Array<InputMaybe<Scalars['UUID']>> | InputMaybe<Scalars['UUID']>;
  TenantId: Scalars['UUID'];
  UserId: Scalars['UUID'];
}>;


export type SetRoleAssignmentMutation = { SetRoleAssignment: { Id: any } };

export type SetSubscriptionTenantMutationVariables = Exact<{
  TenantId: Scalars['UUID'];
  SerialNumber: Scalars['String'];
}>;


export type SetSubscriptionTenantMutation = { SetSubscriptionTenant: { Success: boolean } };

export type SetSupplierTypeMutationVariables = Exact<{
  SupplierTypeItems: Array<SupplierTypeItemInput> | SupplierTypeItemInput;
}>;


export type SetSupplierTypeMutation = { SetSupplierType: { Result: boolean } };

export type SetWebsocketNotificationAcknowledgeMutationVariables = Exact<{
  NotificationIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type SetWebsocketNotificationAcknowledgeMutation = { SetWebsocketNotificationAcknowledge: { Success: boolean } };

export type SyncPermissionKSeFMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncPermissionKSeFMutation = { SyncPermissionKSeF: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type TerminateKSeFSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type TerminateKSeFSessionMutation = { TerminateKSeFSession: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type UpdateTenantMutationVariables = Exact<{
  TenantId: Scalars['UUID'];
  Name?: InputMaybe<Scalars['String']>;
  Identifier?: InputMaybe<Scalars['String']>;
  IdentifierType: IdentifierType;
}>;


export type UpdateTenantMutation = { UpdateTenant: { Id: any, Name?: string | undefined, Identifier?: string | undefined, IdentifierType: IdentifierType, SubscriptionNumber?: string | undefined, Status: TenantStatus, DecisionReason?: string | undefined, DecisionDate?: any | undefined, CreatedAt?: any | undefined } };

export type UpdateUserMutationVariables = Exact<{
  Id: Scalars['UUID'];
  Email: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  PhoneNumber?: InputMaybe<Scalars['String']>;
  TenantId: Scalars['UUID'];
}>;


export type UpdateUserMutation = { UpdateUser: UserWithDetailsItemFragment };

export type UploadInvoicesMutationVariables = Exact<{
  invoices: Array<UploadItemInput> | UploadItemInput;
}>;


export type UploadInvoicesMutation = { UploadInvoices: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type WhiteListCheckMutationVariables = Exact<{
  InvoiceIds: Array<Scalars['UUID']> | Scalars['UUID'];
  Date: Scalars['DateTime'];
}>;


export type WhiteListCheckMutation = { SubscribeWhiteListCheck: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type AcceptDecreeMutationVariables = Exact<{
  DecreeIds: Array<Scalars['UUID']> | Scalars['UUID'];
  UserId: Scalars['UUID'];
}>;


export type AcceptDecreeMutation = { AcceptDecree: { IsDone: boolean } };

export type ActivateAutoPostingModuleMutationVariables = Exact<{ [key: string]: never; }>;


export type ActivateAutoPostingModuleMutation = { ActivateAutoPostingModule: { IsDone: boolean } };

export type AddAttachmentMutationVariables = Exact<{
  input: AddAttachmentInput;
}>;


export type AddAttachmentMutation = { AddAttachment: { Id: any, FileName?: string | undefined } };

export type ArchiveCategoryMutationVariables = Exact<{
  CategoryId: Scalars['UUID'];
  NextCategoryId?: InputMaybe<Scalars['UUID']>;
  ShouldMoveCategoryRules: Scalars['Boolean'];
}>;


export type ArchiveCategoryMutation = { ArchiveCategory: { IsDone: boolean } };

export type ChangeCategoryNameMutationVariables = Exact<{
  Name: Scalars['String'];
  CategoryId: Scalars['UUID'];
  CompanyId: Scalars['UUID'];
}>;


export type ChangeCategoryNameMutation = { ChangeCategoryName: { IsDone: boolean } };

export type CreateCategoryMutationVariables = Exact<{
  Name: Scalars['String'];
  CompanyId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
}>;


export type CreateCategoryMutation = { CreateCategory: { IsDone: boolean, NewCategoryId?: any | undefined } };

export type CreateCompanyVehicleMutationVariables = Exact<{
  RegistrationNumber: Scalars['String'];
  VehicleUsageType: VehicleUsageTypeEnum;
  CompanyId: Scalars['UUID'];
  Description?: InputMaybe<Scalars['String']>;
}>;


export type CreateCompanyVehicleMutation = { CreateCompanyVehicle: { CompanyVehicle: { Id: any, Description?: string | undefined, RegistrationNumber: string, VehicleUsageType: VehicleUsageTypeEnum } } };

export type DeleteAttachmentsMutationVariables = Exact<{
  Id: Array<Scalars['UUID']> | Scalars['UUID'];
  InvoiceId: Scalars['UUID'];
}>;


export type DeleteAttachmentsMutation = { DeleteAttachment: { IsDone: boolean } };

export type GenerateZippedInvoiceAttachmentsMutationVariables = Exact<{
  Ids: Array<Scalars['UUID']> | Scalars['UUID'];
  InvoiceId: Scalars['UUID'];
  IncludeXML: Scalars['Boolean'];
  IncludePDF: Scalars['Boolean'];
}>;


export type GenerateZippedInvoiceAttachmentsMutation = { GenerateZippedInvoiceAttachments: { Link?: string | undefined, FileName?: string | undefined } };

export type RemoveCompanyVehicleMutationVariables = Exact<{
  Id: Scalars['UUID'];
}>;


export type RemoveCompanyVehicleMutation = { RemoveCompanyVehicle: { IsDone: boolean } };

export type ResetModuleInitializationMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetModuleInitializationMutation = { ResetModuleInitialization: { IsDone: boolean } };

export type SaveAutoPostingSettingsMutationVariables = Exact<{
  CompanyId: Scalars['UUID'];
  UserId: Scalars['UUID'];
  AutoPostingSettingChanges: Array<AutoPostingSettingChangeInput> | AutoPostingSettingChangeInput;
}>;


export type SaveAutoPostingSettingsMutation = { SaveAutoPostingSettings: { IsDone: boolean } };

export type SaveCategoriesAccountsMutationVariables = Exact<{
  UpdatedCategoriesAccounts: Array<CategoryAccountRequestInput> | CategoryAccountRequestInput;
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
}>;


export type SaveCategoriesAccountsMutation = { SaveCategoriesAccounts: { IsDone: boolean } };

export type SaveChangesOnDecreeMutationVariables = Exact<{
  DecreeId: Scalars['UUID'];
  CompanyId: Scalars['UUID'];
  UserId: Scalars['UUID'];
  ShouldRememberChanges: Scalars['Boolean'];
  DetailsChanges?: InputMaybe<DecreeDetailsChangeRequestInput>;
  CategoryAccountChanges?: InputMaybe<Array<CategoryAccountChangeRequestInput> | CategoryAccountChangeRequestInput>;
  PositionCategoryChanges?: InputMaybe<Array<PositionCategoryChangeRequestInput> | PositionCategoryChangeRequestInput>;
  PositionAccountChanges?: InputMaybe<Array<PositionAccountChangeRequestInput> | PositionAccountChangeRequestInput>;
  VatSummaryChanges?: InputMaybe<Array<VatSummaryChangeRequestInput> | VatSummaryChangeRequestInput>;
  PositionValueChanges?: InputMaybe<Array<PositionValueChangeRequestInput> | PositionValueChangeRequestInput>;
  PositionVehicleUsageTypeChanges?: InputMaybe<Array<PositionVehicleUsageTypeChangeRequestInput> | PositionVehicleUsageTypeChangeRequestInput>;
  DecreeLineValueChanges?: InputMaybe<Array<DecreeLineValueChangeRequestInput> | DecreeLineValueChangeRequestInput>;
}>;


export type SaveChangesOnDecreeMutation = { SaveChangesOnDecree: { IsChanged: boolean } };

export type SaveDocumentTypesSettingsMutationVariables = Exact<{
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  FinancialYearId: Scalars['UUID'];
  DocumentTypesSettings: Array<DocumentTypesSettingsRequestInput> | DocumentTypesSettingsRequestInput;
}>;


export type SaveDocumentTypesSettingsMutation = { SaveDocumentTypesSettings: { IsDone: boolean } };

export type SaveSettlementsMutationVariables = Exact<{
  AccountSettlementsChanges: Array<AccountSettlementsChangesInput> | AccountSettlementsChangesInput;
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
}>;


export type SaveSettlementsMutation = { SaveSettlements: { IsDone: boolean } };

export type SendInvoiceAttachmentsEmailMutationVariables = Exact<{
  InvoiceId: Scalars['UUID'];
  EmailAddresses: Array<Scalars['String']> | Scalars['String'];
  Message: Scalars['String'];
  ShareName: Scalars['String'];
  IncludeXML: Scalars['Boolean'];
  IncludePDF: Scalars['Boolean'];
}>;


export type SendInvoiceAttachmentsEmailMutation = { SendInvoiceAttachmentsEmail: { IsDone: boolean } };

export type UpdateAttachmentAccessibilityMutationVariables = Exact<{
  Id: Scalars['UUID'];
  IsPublic: Scalars['Boolean'];
  InvoiceId: Scalars['UUID'];
}>;


export type UpdateAttachmentAccessibilityMutation = { UpdateAttachmentAccessibility: { IsDone: boolean } };

export type UpdateAttachmentsAccessibilityMutationVariables = Exact<{
  Ids: Array<Scalars['UUID']> | Scalars['UUID'];
  IsPublic: Scalars['Boolean'];
  InvoiceId: Scalars['UUID'];
}>;


export type UpdateAttachmentsAccessibilityMutation = { UpdateAttachmentsAccessibility: { IsDone: boolean } };

export type UpdateCompanyVehicleMutationVariables = Exact<{
  Id: Scalars['UUID'];
  CompanyId: Scalars['UUID'];
  RegistrationNumber: Scalars['String'];
  VehicleUsageType: VehicleUsageTypeEnum;
  Description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCompanyVehicleMutation = { UpdateCompanyVehicle: { IsDone: boolean } };

export type CreateCompanyMutationVariables = Exact<{
  Name: Scalars['String'];
  Identifier?: InputMaybe<Scalars['String']>;
  IdentifierType: IdentifierType;
  TenantId: Scalars['UUID'];
  IsDemo: Scalars['Boolean'];
}>;


export type CreateCompanyMutation = { CreateCompany?: { Id: any, Name: string, Identifier?: string | undefined, IdentifierType: IdentifierType, IsDemo: boolean } | undefined };

export type DeleteCompanyMutationVariables = Exact<{
  Id: Scalars['UUID'];
}>;


export type DeleteCompanyMutation = { DeleteCompany: boolean };

export type UpdateCompanyMutationVariables = Exact<{
  Id: Scalars['UUID'];
  Name: Scalars['String'];
  Identifier?: InputMaybe<Scalars['String']>;
  IdentifierType: IdentifierType;
  TenantId: Scalars['UUID'];
}>;


export type UpdateCompanyMutation = { UpdateCompany: { Id: any, Name: string, Identifier?: string | undefined, IdentifierType: IdentifierType } };

export type CurrentEnvironmentForAdminFragment = { EnvironmentId: any, Type: EnvironmentType, UserId: any, FirstName?: string | undefined, LastName?: string | undefined, Email?: string | undefined, UserRoles?: Array<{ Id: any, Name?: string | undefined } | undefined> | undefined, Company?: CurrentEnvironmentCompanyItemFragment | undefined, Tenant?: CurrentEnvironmentTenantItemFragment | undefined };

export type CurrentEnvironmentForAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentEnvironmentForAdminQuery = { CurrentEnvironment: CurrentEnvironmentForAdminFragment, Tenants?: { items?: Array<TenantListItemFragment> | undefined } | undefined };

export type GetAttachmentLinkQueryVariables = Exact<{
  Id: Scalars['UUID'];
}>;


export type GetAttachmentLinkQuery = { GetAttachmentLink: { Link?: string | undefined } };

export type GetAttachmentSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAttachmentSettingsQuery = { GetAttachmentSettings: { AllowedAttachmentMimeTypes?: Array<string | undefined> | undefined } };

export type GetCompaniesQueryVariables = Exact<{
  FilterFields?: InputMaybe<CompanyFilterInput>;
  Take?: InputMaybe<Scalars['Int']>;
  Skip?: InputMaybe<Scalars['Int']>;
  Order?: InputMaybe<Array<CompanySortInput> | CompanySortInput>;
}>;


export type GetCompaniesQuery = { Companies?: { totalCount: number, items?: Array<CompanyListItemFragment> | undefined } | undefined };

export type GetCompaniesAndRolesQueryVariables = Exact<{
  FilterFields?: InputMaybe<CompanyFilterInput>;
  Take?: InputMaybe<Scalars['Int']>;
  Skip?: InputMaybe<Scalars['Int']>;
  Order?: InputMaybe<Array<CompanySortInput> | CompanySortInput>;
}>;


export type GetCompaniesAndRolesQuery = { Companies?: { totalCount: number, items?: Array<CompanyListItemFragment> | undefined } | undefined, Roles?: { totalCount: number, items?: Array<RoleShortListItemFragment> | undefined } | undefined };

export type GetCompanyExistsAdminQueryVariables = Exact<{
  TenantId: Scalars['UUID'];
  Identifier: Scalars['String'];
  IdentifierType: IdentifierType;
}>;


export type GetCompanyExistsAdminQuery = { CompanyExists: { Exists: boolean } };

export type CompanyAssigmentListItemFragment = { CompanyId: any, CompanyName?: string | undefined, PurchaseInvoicesCount: any, SalesInvoicesCount: any, Identifier?: string | undefined, IsDemo: boolean, HasKSeFAuthorization: boolean };

export type GetCompanyStatisticsQueryVariables = Exact<{
  TenantIdFilter?: InputMaybe<Scalars['UUID']>;
  Take: Scalars['Long'];
  Skip: Scalars['Long'];
  Order?: InputMaybe<ItemsOrderInput>;
  SearchText: Scalars['String'];
}>;


export type GetCompanyStatisticsQuery = { CompanyStatistics: { TotalTenantsCount: any, TotalCompanysCount: any, TenantAssignments?: Array<{ TenantId: any, TenantName?: string | undefined, CompanyAssignments?: Array<CompanyAssigmentListItemFragment | undefined> | undefined } | undefined> | undefined } };

export type CompanyInfoListItemFragment = { CompanyId: any, CompanyName?: string | undefined, Identifier?: string | undefined, IsDemo: boolean };

export type GetCompaniesForOrganisationQueryVariables = Exact<{
  TenantIdFilter?: InputMaybe<Scalars['UUID']>;
  Take: Scalars['Long'];
  Skip: Scalars['Long'];
  Order?: InputMaybe<ItemsOrderInput>;
  SearchText: Scalars['String'];
}>;


export type GetCompaniesForOrganisationQuery = { GetCompaniesForOrganisation: { TotalTenantsCount: any, TotalCompanysCount: any, TenantAssignments?: Array<{ TenantId: any, TenantName?: string | undefined, CompanyAssignments?: Array<CompanyInfoListItemFragment | undefined> | undefined } | undefined> | undefined } };

export type GetStatisticsForCompanyQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
}>;

export type GetStatisticsForCompanyQuery = { GetStatisticsForCompany: { PurchaseInvoicesCount: any, SalesInvoicesCount: any, HasKSeFAuthorization: boolean } };


export type GetContractorsQueryVariables = Exact<{
  ContractorFiltersFields: ContractorFiltersFieldsInput;
  SearchText?: InputMaybe<Scalars['String']>;
  Take: Scalars['Long'];
  Skip: Scalars['Long'];
  Order?: InputMaybe<ItemsOrderInput>;
}>;


export type GetContractorsQuery = { GetContractors: { TotalCount?: any | undefined, FilteredCount?: any | undefined, Items?: Array<ContractorsItemFragment> | undefined } };

export type ContractorsItemFragment = { CompanyId: any, CreatedAt?: any | undefined, CreatedBy?: string | undefined, Id: any, Identifier?: string | undefined, ModifiedAt?: any | undefined, ModifiedBy?: string | undefined, Name?: string | undefined, SupplierType: number, WhiteListStatus?: WhiteListModelFragment | undefined };

export type WhiteListModelFragment = { IsValidated: boolean, Accounts?: Array<{ Account?: string | undefined, Date: any, ErrorMessage?: string | undefined, IsWhiteListed: boolean } | undefined> | undefined };

export type GetCurrentSchemaVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentSchemaVersionQuery = { GetCurrentSchemaVersion: { SchemaVersion?: Array<KsefXsdVersion> | undefined } };

export type GetEArchiveNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEArchiveNotificationsQuery = { GetEArchiveNotifications: { Notifications?: Array<EArchiveNotificationType> | undefined } };

export type GetInvoiceFileContentActionGroupQueryVariables = Exact<{
  InvoiceIds: Array<Scalars['UUID']> | Scalars['UUID'];
  Token?: InputMaybe<Scalars['String']>;
}>;


export type GetInvoiceFileContentActionGroupQuery = { GetInvoiceFileContentActionGroup: { ZipName?: string | undefined, ZipContent?: string | undefined, MimeType?: string | undefined } };

export type GetInvoiceHtmlContentSingleQueryVariables = Exact<{
  InvoiceId: Scalars['UUID'];
  Token?: InputMaybe<Scalars['String']>;
}>;


export type GetInvoiceHtmlContentSingleQuery = { GetInvoiceFileContentSingle: { HtmlContent: string, PdfContent: string } };

export type GetInvoiceFileContentSingleQueryVariables = Exact<{
  InvoiceId: Scalars['UUID'];
  Token?: InputMaybe<Scalars['String']>;
}>;


export type GetInvoiceFileContentSingleQuery = { GetInvoiceFileContentSingle: { PdfContent: string, PdfName: string, MimeType: string } };

export type GetInvoicesQueryVariables = Exact<{
  FiltersFields: FiltersFieldsInput;
  SearchText?: InputMaybe<Scalars['String']>;
  Take: Scalars['Long'];
  Skip: Scalars['Long'];
  Order?: InputMaybe<ItemsOrderInput>;
}>;


export type GetInvoicesQuery = { GetInvoices: { TotalCount?: any | undefined, FilteredCount?: any | undefined, Items?: Array<InvoiceItemFragment> | undefined } };

export type InvoiceItemFragment = { AttachmentsCount: number, Number?: string | undefined, DateOfIssue: any, DestinationSystem?: string | undefined, DocumentType: RodzajFaktury, FileName?: string | undefined, InvoiceBound: InvoiceBound, IssuerName?: string | undefined, IssuerNip?: string | undefined, Currency?: string | undefined, RecipientName?: string | undefined, RecipientNip?: string | undefined, GrossValue: any, NetValue: any, VatValue: any, KsefDate?: any | undefined, KsefNumber?: string | undefined, Downloaded?: SymfoniaSystem | undefined, KSeFStatus: KSeFStatus, CreatedAt?: any | undefined, CreatedBy?: string | undefined, ModifiedAt?: any | undefined, ModifiedBy?: string | undefined, Id: any, InvoicePostingStatus: InvoicePostingStatus, RegistrationNumber?: string | undefined, CanDownloadUpo: boolean, SchemaVersion: KsefXsdVersion, IsDecreeAccepted: boolean, WhiteListStatus?: WhiteListModelFragment | undefined };

export type GetInvoicesTotalCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoicesTotalCountQuery = { PurchaseCount: { TotalCount?: any | undefined }, SellCount: { TotalCount?: any | undefined } };

export type GetInvoicesReportQueryVariables = Exact<{
  ReportFileType: ReportFileTypeEnum;
  FiltersFields: FiltersFieldsInput;
  SearchText?: InputMaybe<Scalars['String']>;
  take: Scalars['Long'];
  skip: Scalars['Long'];
  order?: InputMaybe<ItemsOrderInput>;
}>;


export type GetInvoicesReportQuery = { GetInvoicesReport: { FileName: string, FileContent: string, MimeType: string } };

export type GetIssuerInvoicesQueryVariables = Exact<{
  FiltersFields: FiltersFieldsIssuerInput;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
}>;


export type GetIssuerInvoicesQuery = { GetIssuerInvoices: { TotalCount?: any | undefined, Items?: Array<IssuerInvoiceFragment> | undefined } };

export type IssuerInvoiceFragment = { InvoiceBound: InvoiceBound, IssuerName: string, IssuertNip: string };

export type GetKSeFInvoiceXmlQueryVariables = Exact<{
  InvoiceId: Scalars['UUID'];
}>;


export type GetKSeFInvoiceXmlQuery = { GetKSeFInvoiceXML: { Content?: string | undefined, FileName?: string | undefined } };

export type GetMissedWebsocketSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMissedWebsocketSubscriptionsQuery = { GetMissedWebsocketSubscriptions: { Success: boolean } };

export type EnvironmentListItemFragment = { Name?: string | undefined, Status: EnvironmentStatus, EnvironmentId: any, Type: EnvironmentType, IsDemo: boolean, Company?: { Id: any, Name: string, Identifier?: string | undefined, IdentifierType: IdentifierType } | undefined, Tenant?: { Id: any, Name?: string | undefined, Identifier?: string | undefined, IdentifierType: IdentifierType, Status: TenantStatus } | undefined };

export type MyEnviromentsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyEnviromentsQuery = { MyEnvironments: { Environments?: Array<EnvironmentListItemFragment | undefined> | undefined } };

export type GetPackageStatisticQueryVariables = Exact<{
  TenantId: Scalars['UUID'];
}>;


export type GetPackageStatisticQuery = { GetPackageStatistic: { Status?: SubscriptionStatus | undefined, CurrentInvoicesCount?: number | undefined, CurrentStorageCount?: any | undefined, StorageUsedPercentage?: any | undefined, InvoicesUsedPercentage?: any | undefined, SerialNumber?: string | undefined, SubscriptionNumber?: string | undefined, CustomerNumber?: string | undefined, Email?: string | undefined, DateTo: any, Items: Array<PackageStatisticsItemFragment> } };

export type PackageStatisticsItemFragment = { CreatedAt?: any | undefined, CreatedBy?: string | undefined, Id: any, ModifiedAt?: any | undefined, ModifiedBy?: string | undefined, PackageDescription?: string | undefined, PackageTypeEnum: PackageType, PackageValue: string, SubscriptionId: any, Subscription: { SubscriptionType: SubscriptionType, TenantId?: any | undefined } };

export type GetPermissionForCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPermissionForCompanyQuery = { CurrentEnvironment: { UserProperties?: { KSeF: { Authorized: KSeFAuthResult, Credentials?: Array<KSeFTokenCredential> | undefined, AuthorizedBy?: string | undefined, AuthorizedAt?: any | undefined }, EArchive: { SharedAccess: { Status: boolean, LastModifiedBy?: string | undefined, LastModifiedDate?: any | undefined, UserId?: any | undefined } } } | undefined } };

export type GetPermissionsKSeFQueryVariables = Exact<{
  CredentialFiltersFields: CredentialFiltersFieldsInput;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  take?: InputMaybe<Scalars['Long']>;
  skip?: InputMaybe<Scalars['Long']>;
  fromKsef?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetPermissionsKSeFQuery = { GetPermissionsKSeF: { FromKsef: boolean, LastUpdated?: any | undefined, TotalCount: any, FilteredCount: any, Credentials: Array<CredentialsFragment> } };

export type CredentialsFragment = { Identifier?: string | undefined, IdentifierType: IdentifierType, CredentialsInvoiceRead: boolean, CredentialsInvoiceWrite: boolean, CredentialsRead: boolean, CredentialsManage: boolean, CredentialsSelfInvoicing: boolean };

export type GetPermissionsKSeFReportQueryQueryVariables = Exact<{
  ReportFileType: ReportFileTypeEnum;
  CredentialFiltersFields?: InputMaybe<CredentialFiltersFieldsInput>;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  take?: InputMaybe<Scalars['Long']>;
  skip?: InputMaybe<Scalars['Long']>;
}>;


export type GetPermissionsKSeFReportQueryQuery = { GetPermissionsKSeFReport: { FileName?: string | undefined, FileContent?: string | undefined, MimeType?: string | undefined } };

export type GetPlatformNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlatformNotificationsQuery = { GetPlatformNotifications: { Notifications?: Array<PlatformNotificationType> | undefined } };

export type GetRecipientInvoicesQueryVariables = Exact<{
  FiltersFields: FiltersFieldsRecipientsInput;
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  skip: Scalars['Long'];
  take: Scalars['Long'];
}>;


export type GetRecipientInvoicesQuery = { GetRecipientInvoices: { TotalCount?: any | undefined, Items?: Array<RecipientInvoiceFragment> | undefined } };

export type RecipientInvoiceFragment = { RecipientNip: string, RecipientName: string, InvoiceBound: InvoiceBound };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { Roles?: { totalCount: number, items?: Array<RoleShortListItemFragment> | undefined } | undefined };

export type TenantListItemFragment = { Id: any, Name?: string | undefined, Identifier?: string | undefined, IdentifierType: IdentifierType, SubscriptionNumber?: string | undefined, Status: TenantStatus, DecisionReason?: string | undefined, DecisionDate?: any | undefined, CreatedAt?: any | undefined, Companys?: Array<{ Name: string, Identifier?: string | undefined, IdentifierType: IdentifierType } | undefined> | undefined, Users?: Array<{ Email: string, FirstName: string, LastName: string, PhoneNumber?: string | undefined, RoleAssignments?: Array<{ Role?: { Name?: string | undefined } | undefined } | undefined> | undefined } | undefined> | undefined };

export type GetTenantsQueryVariables = Exact<{
  FilterFields?: InputMaybe<TenantFilterInput>;
  Take?: InputMaybe<Scalars['Int']>;
  Skip?: InputMaybe<Scalars['Int']>;
  Order?: InputMaybe<Array<TenantSortInput> | TenantSortInput>;
}>;


export type GetTenantsQuery = { Tenants?: { totalCount: number, items?: Array<TenantListItemFragment> | undefined } | undefined };

export type GetUpoQueryVariables = Exact<{
  InvoiceIds: Array<Scalars['UUID']> | Scalars['UUID'];
  UpoFileType: UpoFileType;
}>;


export type GetUpoQuery = { GetUPO: { FileName?: string | undefined, FileContent?: string | undefined, MimeType?: string | undefined, ReturnedInvoices?: Array<any> | undefined, RequestedInvoices?: Array<any> | undefined, MissingInvoices?: Array<{ InvoiceNumber?: string | undefined, Reason: UpoMissingInvoiceReason } | undefined> | undefined } };

export type GetUsersQueryVariables = Exact<{
  FilterFields?: InputMaybe<UserFilterInput>;
  Take?: InputMaybe<Scalars['Int']>;
  Skip?: InputMaybe<Scalars['Int']>;
  Order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
}>;


export type GetUsersQuery = { Users?: { totalCount: number, items?: Array<UserWithDetailsItemFragment> | undefined } | undefined };

export type GetUsersWithDetailsQueryVariables = Exact<{
  FilterFields?: InputMaybe<UserFilterInput>;
  CompaniesFilterFields?: InputMaybe<CompanyFilterInput>;
  Take?: InputMaybe<Scalars['Int']>;
  Skip?: InputMaybe<Scalars['Int']>;
  Order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
  CompaniesOrder?: InputMaybe<Array<CompanySortInput> | CompanySortInput>;
}>;


export type GetUsersWithDetailsQuery = { Users?: { totalCount: number, items?: Array<UserWithDetailsItemFragment> | undefined } | undefined, Companies?: { totalCount: number, items?: Array<CompanyListItemFragment> | undefined } | undefined, Roles?: { totalCount: number, items?: Array<RoleShortListItemFragment> | undefined } | undefined };

export type GetWebsocketNotificationQueryVariables = Exact<{
  notificationStateType: WebsocketNotificationStateType;
}>;


export type GetWebsocketNotificationQuery = { GetWebsocketNotificationsList: { WebsocketNotification?: Array<WebSocketNotificationFragment | undefined> | undefined } };

export type WebSocketNotificationFragment = { ErrorType?: WebsocketErrorType | undefined, Message?: string | undefined, Type: WebsocketNotificationType, NotificationId: any, Timestamp: any, Visited: boolean, State: SubscriptionStateEnum, HasDownloadableContent: boolean, CompanyId: any, UserId: any, ResponseJson?: string | undefined, ErrorContent?: Array<{ Key: string, Value: string }> | undefined };

export type GetWebsocketNotificationModelQueryVariables = Exact<{
  NotificationId: Scalars['UUID'];
}>;


export type GetWebsocketNotificationModelQuery = { GetWebsocketNotificationModel: WebSocketNotificationResponseFragment };

export type WebSocketNotificationResponseFragment = { ErrorType?: WebsocketErrorType | undefined, Message?: string | undefined, Type: WebsocketNotificationType, NotificationId: any, Timestamp: any, Visited: boolean, State: SubscriptionStateEnum, HasDownloadableContent: boolean, CompanyId: any, UserId: any, ResponseJson?: string | undefined, ErrorContent?: Array<{ Key: string, Value: string }> | undefined };

export type CheckIsDownloadInvoicesRunningQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckIsDownloadInvoicesRunningQuery = { IsDownloadInvoicesRunning: { IsRunning: boolean } };

export type KSeFSessionInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type KSeFSessionInfoQuery = { KSeFSessionInfo: { Active: boolean, ExpirationTime?: any | undefined } };

export type RequiresSessionTerminationForUpoQueryVariables = Exact<{
  InvoicesIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type RequiresSessionTerminationForUpoQuery = { RequiresSessionTerminationForUPO: { Result: boolean } };

export type SubscribeDownloadInvoicesFromKSeFQueryVariables = Exact<{
  InternalEnabled: Scalars['Boolean'];
  Internal?: InputMaybe<DateTimeRangeInput>;
  ExternalEnabled: Scalars['Boolean'];
  External?: InputMaybe<DateTimeRangeInput>;
}>;


export type SubscribeDownloadInvoicesFromKSeFQuery = { SubscribeDownloadInvoicesFromKSeF: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type SubscribeGetUpoQueryVariables = Exact<{
  InvoiceIds: Array<Scalars['UUID']> | Scalars['UUID'];
  UpoFileType: UpoFileType;
}>;


export type SubscribeGetUpoQuery = { SubscribeGetUpo: { Result: SubscriptionStateEnum, NotificationModel?: WebSocketNotificationFragment | undefined } };

export type GetUserKSeFRequestForCredentialsQueryVariables = Exact<{
  SearchText?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<ItemsOrderInput>;
  take?: InputMaybe<Scalars['Long']>;
  skip?: InputMaybe<Scalars['Long']>;
  RequestedCredentialFiltersFields?: InputMaybe<RequestedCredentialFiltersFieldsInput>;
}>;


export type GetUserKSeFRequestForCredentialsQuery = { GetUserKSeFRequestForCredentials: { TotalCount: any, FilteredCount: any, RequestedCredentials: Array<RequestedCredentialsFragment> } };

export type RequestedCredentialsFragment = { Identifier: string, CredentialsInvoiceRead: boolean, CredentialsInvoiceWrite: boolean, CredentialsRead: boolean, CredentialsManage: boolean, CredentialsSelfInvoicing: boolean, ProcessingDescription?: string | undefined, CreatedAt?: any | undefined, RequestType: KSeFRequestForCredentialType, ProcessingStatus: KSeFRequestForCredentialsProcessingStatus };

export type GetAccountsQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  AccountType?: InputMaybe<AccountTypeEnum>;
}>;


export type GetAccountsQuery = { GetAccounts: { AccountsPlan: Array<{ Id: any, Number: string, Name: string, IsFinal: boolean, IsDisabled: boolean, ParentId?: any | undefined, ChildrenIds?: Array<any> | undefined, AccountType: AccountTypeEnum, FinancialYearId: any }> } };

export type GetAccountsForPostingQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  DecreeId: Scalars['UUID'];
  AccountType?: InputMaybe<AccountTypeEnum>;
}>;


export type GetAccountsForPostingQuery = { GetAccountsForPosting: { AccountsPlan: Array<{ Id: any, Number: string, Name: string, IsFinal: boolean, IsDisabled: boolean, ParentId?: any | undefined, ChildrenIds?: Array<any> | undefined, AccountType: AccountTypeEnum, FinancialYearId: any }> } };

export type GetAttachmentsQueryVariables = Exact<{
  InvoiceId: Scalars['UUID'];
}>;


export type GetAttachmentsQuery = { GetAttachments: { Attachments?: Array<{ Id: any, CompanyId: any, CreatedByEmail?: string | undefined, FileName?: string | undefined, CreatedAt: any, IsPublic: boolean, Size: any, InvoiceId: any } | undefined> | undefined } };

export type GetAutoPostingModuleStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAutoPostingModuleStatusQuery = { GetAutoPostingModuleStatus: { ModuleSettings: { ModuleSubscriptionStatusValue: ModuleSubscriptionStatusEnum, InitializationStatusValue: InitializationStatusEnum, InitializationTaskTypeValue: InitializationTaskTypeEnum, ErrorDetails?: string | undefined }, ConfigurationStatus: { IsRequiredConfiguration: boolean, RequiredModulesToFill?: Array<RequiredModulesToFillEnum> | undefined } } };

export type GetAutoPostingSettingsQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
}>;


export type GetAutoPostingSettingsQuery = { GetAutoPostingSettings: { AutoPostingSettings: { AutoPostingOfInvoices?: { SettingId: any, SettingType: SettingTypeEnum, IsActive: boolean, ModifiedAt?: any | undefined, ModifiedBy?: string | undefined } | undefined } } };

export type GetCategoriesQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  Order?: InputMaybe<Array<CategoryViewModelSortInput> | CategoryViewModelSortInput>;
}>;


export type GetCategoriesQuery = { GetCategoriesViewModel?: { items?: Array<{ Id: any, MlKey?: string | undefined, Name: string, InvoiceTypeGroup: InvoiceTypeGroupEnum, Type: CategoryTypeEnum, IsArchived: boolean }> | undefined } | undefined };

export type GetCategoriesAccountsQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  SearchText?: InputMaybe<Scalars['String']>;
  FilterFields?: InputMaybe<CategoryViewModelFilterInput>;
  Take?: InputMaybe<Scalars['Int']>;
  Skip?: InputMaybe<Scalars['Int']>;
  Order?: InputMaybe<Array<CategoryViewModelSortInput> | CategoryViewModelSortInput>;
}>;


export type GetCategoriesAccountsQuery = { GetCategoriesViewModel?: { totalCount: number, items?: Array<{ Id: any, Name: string, Type: CategoryTypeEnum, Accounts: Array<{ Id: any, Number: string, Name: string, IsFinal: boolean, IsDisabled: boolean, ParentId?: any | undefined, AccountType: AccountTypeEnum, FinancialYearId: any }> }> | undefined } | undefined };

export type GetCategoryRulesQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  Filters: CategoryRulesFiltersInput;
  SearchText?: InputMaybe<Scalars['String']>;
  Take: Scalars['Long'];
  Skip: Scalars['Long'];
  Order?: InputMaybe<ItemsOrderInput>;
}>;


export type GetCategoryRulesQuery = { GetCategoryRules: { TotalCount?: any | undefined, Items: Array<{ Id: any, PositionName: string, PreviousCategoryName: string, CurrentCategoryName: string, IsActive: boolean, UserId?: any | undefined, UserFirstName: string, UserLastName: string, ModifiedAt: any, InvoiceTypeGroup: InvoiceTypeGroupEnum }> } };

export type GetCategoryRulesUsersAndPositionsQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  IsActive: Scalars['Boolean'];
}>;


export type GetCategoryRulesUsersAndPositionsQuery = { GetCategoryRulesUsersAndPositions: { UsersList: Array<{ UserId?: any | undefined, UserFirstName: string, UserLastName: string }>, Positions: Array<{ Id: any, PositionName: string }> } };

export type GetCompanyVehiclesQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  SearchText?: InputMaybe<Scalars['String']>;
  Take?: InputMaybe<Scalars['Int']>;
  Skip?: InputMaybe<Scalars['Int']>;
  Order?: InputMaybe<Array<CompanyVehicleViewModelSortInput> | CompanyVehicleViewModelSortInput>;
  FilterFields?: InputMaybe<CompanyVehicleViewModelFilterInput>;
}>;


export type GetCompanyVehiclesQuery = { CompanyVehicles?: { totalCount: number, items?: Array<{ Id: any, RegistrationNumber: string, VehicleUsageType: VehicleUsageTypeEnum, Description?: string | undefined }> | undefined } | undefined };

export type GetCompanyVehicleByIdQueryVariables = Exact<{
  VehicleId: Scalars['UUID'];
}>;


export type GetCompanyVehicleByIdQuery = { GetCompanyVehicleById: { Vehicle: { Id: any, RegistrationNumber: string, Description?: string | undefined, VehicleUsageType: VehicleUsageTypeEnum } } };

export type GetDecreeDetailsQueryVariables = Exact<{
  DecreeId: Scalars['UUID'];
}>;


export type GetDecreeDetailsQuery = { GetDecreeDetails: { Decree: (
      { FinancialYearId: any, DocumentType?: (
        { Symbol?: string | undefined }
        & InvoiceDocumentTypeFragment
      ) | undefined, VatSummaries: Array<VatSummariesFragment> }
      & DecreeDetailsItemFragment
    ) } };

export type GetDocumentTypesQueryVariables = Exact<{
  FinancialYearId: Scalars['UUID'];
}>;


export type GetDocumentTypesQuery = { GetInvoiceDocumentTypes: { DocumentTypeResponse: Array<{ Id: any, Symbol?: string | undefined, Name: string }> } };

export type GetDocumentTypesSettingsQueryVariables = Exact<{
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  FinancialYearId: Scalars['UUID'];
}>;


export type GetDocumentTypesSettingsQuery = { GetDocumentTypesSettings: { DocumentTypesVatRegistries: Array<{ SettingType: DocumentTypeSettingTypeEnum, InvoiceDocumentTypeId: any }> } };

export type GetFinancialYearsQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
}>;


export type GetFinancialYearsQuery = { GetFinancialYears: { FinancialYears: Array<{ Id: any, Name: string, LastSyncDate: any, BeginDate: any, EndDate: any, IsConfigured: boolean, Status: FinancialYearStatusEnum, IsActive: boolean }> } };

export type GetInvoiceDocumentTypesQueryVariables = Exact<{
  FinancialYearId: Scalars['UUID'];
}>;


export type GetInvoiceDocumentTypesQuery = { GetInvoiceDocumentTypes: { DocumentTypeResponse: Array<{ Id: any, Name: string, VatRegistryId?: any | undefined, VatRegistryName?: string | undefined, Symbol?: string | undefined }> } };

export type GetPostingInvoicesReportQueryVariables = Exact<{
  reportFileType: ReportFileTypeEnum;
  where?: InputMaybe<GetPostingInvoicesReportFilterInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<GetPostingInvoicesReportSortInput> | GetPostingInvoicesReportSortInput>;
}>;


export type GetPostingInvoicesReportQuery = { GetPostingInvoicesReport: { FileName: string, FileContent: string, MimeType: string } };

export type GetPostingInvoicesQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  SearchText?: InputMaybe<Scalars['String']>;
  Take?: InputMaybe<Scalars['Int']>;
  Skip?: InputMaybe<Scalars['Int']>;
  Order?: InputMaybe<Array<DecreeViewModelSortInput> | DecreeViewModelSortInput>;
  FilterFields?: InputMaybe<DecreeViewModelFilterInput>;
}>;


export type GetPostingInvoicesQuery = { GetPostingInvoicesViewModel?: { totalCount: number, items?: Array<{ Id: any, GrossValue: any, CreatedAt: any, CreatedBy?: any | undefined, PostingStatus: PostingStatusEnum, InvoiceTypeGroup: InvoiceTypeGroupEnum, InvoiceNumber: string, InvoiceDocumentId: any, ContractorId?: any | undefined, IsAccepted: boolean, Contractor?: { Identifier: string, Name: string } | undefined }> | undefined } | undefined };

export type GetRecalculatedDecreeQueryVariables = Exact<{
  DecreeId: Scalars['UUID'];
  CompanyId: Scalars['UUID'];
  CategoryAccountChanges?: InputMaybe<Array<CategoryAccountChangeRequestInput> | CategoryAccountChangeRequestInput>;
  PositionCategoryChanges?: InputMaybe<Array<PositionCategoryChangeRequestInput> | PositionCategoryChangeRequestInput>;
  PositionAccountChanges?: InputMaybe<Array<PositionAccountChangeRequestInput> | PositionAccountChangeRequestInput>;
  PositionVehicleUsageTypeChanges?: InputMaybe<Array<PositionVehicleUsageTypeChangeRequestInput> | PositionVehicleUsageTypeChangeRequestInput>;
}>;


export type GetRecalculatedDecreeQuery = { GetRecalculatedDecree: { RecalculatedDecree: (
      { FinancialYearId: any, DocumentType?: (
        { Symbol?: string | undefined }
        & InvoiceDocumentTypeFragment
      ) | undefined, VatSummaries: Array<VatSummariesFragment> }
      & DecreeDetailsItemFragment
    ) } };

export type GetRequiredConfigurationStatusQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
}>;


export type GetRequiredConfigurationStatusQuery = { GetRequiredConfigurationStatus: { IsRequiredConfiguration: boolean } };

export type GetSettlementsQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
}>;


export type GetSettlementsQuery = { GetSettlements: { Settlements: Array<{ Id: any, Name: string, IsRequired: boolean, SettlementType: SettlementTypeEnum, AccountId?: any | undefined, AccountName?: string | undefined, AccountNumber?: string | undefined }> } };

export type GetVatRegistriesQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
}>;


export type GetVatRegistriesQuery = { GetVatRegistries: { VatRegistries: Array<{ Id: any, Name: string }> } };

export type LicensePlateExistsQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  RegistrationNumber: Scalars['String'];
}>;


export type LicensePlateExistsQuery = { LicensePlateExists: { LicensePlateExists: boolean } };

export type GetCategoryAccountsFilterDataQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  InvoiceTypeGroup: InvoiceTypeGroupEnum;
  Type?: InputMaybe<Array<CategoryTypeEnum> | CategoryTypeEnum>;
}>;


export type GetCategoryAccountsFilterDataQuery = { Categories?: { items?: Array<{ Id: any, Name: string, Type: CategoryTypeEnum }> | undefined } | undefined, Accounts?: { items?: Array<{ Accounts: Array<{ Id: any, Name: string, Number: string, AccountType: AccountTypeEnum }> }> | undefined } | undefined };

export type CategoryRulesFiltersDataQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
  FinancialYearId: Scalars['UUID'];
  IsActive: Scalars['Boolean'];
}>;


export type CategoryRulesFiltersDataQuery = { Categories?: { items?: Array<{ Id: any, Name: string }> | undefined } | undefined, UsersAndPositions: { UsersList: Array<{ UserId?: any | undefined, UserFirstName: string, UserLastName: string }>, Positions: Array<{ Id: any, PositionName: string }> } };

export type GetPostedInvoicesFiltersDataQueryVariables = Exact<{
  CompanyId: Scalars['UUID'];
}>;


export type GetPostedInvoicesFiltersDataQuery = { Contractors?: { items?: Array<{ Id: any, Identifier: string, Name: string }> | undefined } | undefined };

export type CurrentEnvironmentCompanyItemFragment = { IdentifierType: IdentifierType, Identifier?: string | undefined, Id: any, Name?: string | undefined, IsDemo: boolean };

export type CurrentEnvironmentTenantItemFragment = { Id: any, Name?: string | undefined, Identifier?: string | undefined, IdentifierType: IdentifierType, SubscriptionNumber?: string | undefined };

export type CompanyListItemFragment = { Id: any, Name: string, Identifier?: string | undefined, IdentifierType: IdentifierType, IsDemo: boolean, Tenant?: { Id: any } | undefined };

export type RoleShortListItemFragment = { Id: any, Name?: string | undefined };

export type CompanyShortListItemFragment = { Id: any, Name: string };

export type UserWithDetailsItemFragment = { Email: string, FirstName: string, LastName: string, PhoneNumber?: string | undefined, Id: any, RoleAssignments?: Array<{ Id: any, TenantId: any, CompanyId?: any | undefined, Role?: RoleShortListItemFragment | undefined, Company?: CompanyShortListItemFragment | undefined } | undefined> | undefined };

export type DecreeDetailsItemFragment = { Id: any, InvoiceDocumentId: any, InvoiceNumber: string, PostingStatus: PostingStatusEnum, Gross: any, Net: any, Vat: any, KSeFNumber?: string | undefined, KSeFDate?: any | undefined, DateOfIssue: any, OperationDate: any, PaymentType?: PaymentTypeEnum | undefined, PaymentDate?: any | undefined, CurrencyCode: CurrencyCodeEnum, InvoiceType: InvoiceTypeEnum, InvoiceTypeGroup: InvoiceTypeGroupEnum, IsAccepted: boolean, DocumentType?: InvoiceDocumentTypeFragment | undefined, Contractor?: ContractorFragment | undefined, DecreeLines: Array<DecreeLineFragment> };

export type AccountsPlanFragment = { Id: any, Number: string, Name: string, IsFinal: boolean, ParentId?: any | undefined, ChildrenIds?: Array<any> | undefined, AccountType: AccountTypeEnum, FinancialYearId: any };

export type DecreeLineFragment = { Id: any, AccountNameAndNumber: string, CategoryId?: any | undefined, CategoryMlKey: string, CategoryName: string, CanAccountBeChanged: boolean, LineType: DecreeLineTypeEnum, AccountType: AccountTypeEnum, Value: any, Account?: { Id: any, Name: string, Number: string, AccountType: AccountTypeEnum } | undefined, Positions?: Array<PositionFragment> | undefined };

export type PositionFragment = { Id: any, InvoicePositionId: string, Name: string, Value: any, IsRuleCategory: boolean, VehicleId?: any | undefined, CanEditVehicles: boolean, IsRound: boolean };

export type ContractorFragment = { Id: any, IdentifierType: PostingIdentifierTypeEnum, Identifier: string, Name: string, ContractorOriginType: ContractorOriginTypeEnum, ContractorRelatedType: ContractorRelatedTypeEnum, Street?: string | undefined, Place?: string | undefined, PostCode?: string | undefined };

export type InvoiceDocumentTypeFragment = { Id: any, Name: string, VatRegistryId?: any | undefined, VatRegistryName?: string | undefined };

export type VatSummariesFragment = { Id: any, Vat: any, Gross: any, Net: any, GTUItems?: Array<GtuCodeEnum> | undefined, TaxRate: StawkaPodatku, VatRegisterId?: any | undefined, VatRegisterName?: string | undefined };

export type GetCompanyExistsQueryVariables = Exact<{
  TenantId: Scalars['UUID'];
  Identifier: Scalars['String'];
  IdentifierType: IdentifierType;
}>;


export type GetCompanyExistsQuery = { CompanyExists: { Exists: boolean } };

export type CurrentEnvironmentItemFragment = { EnvironmentId: any, Type: EnvironmentType, UserId: any, FirstName?: string | undefined, LastName?: string | undefined, Email?: string | undefined, UserRoles?: Array<{ Id: any, Name?: string | undefined } | undefined> | undefined, Company?: CurrentEnvironmentCompanyItemFragment | undefined, Tenant?: CurrentEnvironmentTenantItemFragment | undefined, UserProperties?: { AutoPosting?: { Status: boolean } | undefined, EArchive: { AutoFetchingInvoices: { Status: boolean, ExternalEnabled: boolean, InternalEnabled: boolean, LastModifiedBy?: string | undefined, LastModifiedDate?: any | undefined, Minutes?: number | undefined, Type?: KSeFAutomaticOperationType | undefined, DownloadLimitDate?: any | undefined }, AutoSendingInvoices: { Status: boolean, LastModifiedBy?: string | undefined, LastModifiedDate?: any | undefined, Minutes?: number | undefined, Type?: KSeFAutomaticOperationType | undefined }, SharedAccess: { Status: boolean, LastModifiedBy?: string | undefined, LastModifiedDate?: any | undefined, UserId?: any | undefined }, WhiteListValidation: { Status: boolean, LastModifiedBy?: string | undefined, LastModifiedDate?: any | undefined, UserId?: any | undefined } }, KSeF: { Authorized: KSeFAuthResult, Credentials?: Array<KSeFTokenCredential> | undefined, AuthorizedBy?: string | undefined, AuthorizedAt?: any | undefined } } | undefined };

export type CurrentEnvironmentQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentEnvironmentQuery = { CurrentEnvironment: CurrentEnvironmentItemFragment };

export type KSeFCheckStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type KSeFCheckStatusSubscription = { onKSeFOperatingStatusUpdateGeneral: { status: KSeFOperatingStatus, statusDate: any } };

export type KSeFNotificationUserSubscriptionVariables = Exact<{
  userId: Scalars['String'];
  companyId: Scalars['String'];
}>;


export type KSeFNotificationUserSubscription = { onKSeFNotificationUser: NotificationFragment };

export type NotificationFragment = { errorType?: WebsocketErrorType | undefined, message?: string | undefined, notificationId: any, timestamp: any, type: WebsocketNotificationType, visited: boolean, state: SubscriptionStateEnum, errorContent?: Array<{ Key: string, Value: string }> | undefined };

export type OnAutoPostingInitializationCompletedSubscriptionVariables = Exact<{
  CompanyId: Scalars['UUID'];
  UserId: Scalars['UUID'];
}>;


export type OnAutoPostingInitializationCompletedSubscription = { onAutoPostingInitializationCompleted: { isCompleted: boolean } };

export type OnAutoPostingResetInitializationCompletedSubscriptionVariables = Exact<{
  CompanyId: Scalars['UUID'];
}>;


export type OnAutoPostingResetInitializationCompletedSubscription = { onAutoPostingResetInitializationCompleted: { hasFailed: boolean, errorMessage?: string | undefined } };

export type OnAutoPostingInitializationStatusChangedSubscriptionVariables = Exact<{
  CompanyId: Scalars['UUID'];
}>;


export type OnAutoPostingInitializationStatusChangedSubscription = { onAutoPostingInitializationStatusChanged: { status: ProcessTaskStatusEnum, taskType: InitializationTaskTypeEnum, errorDetails?: string | undefined, errorType?: string | undefined } };

export const CurrentEnvironmentCompanyItemFragmentDoc = gql`
    fragment CurrentEnvironmentCompanyItem on CurrentCompanyViewModel {
  IdentifierType
  Identifier
  Id
  Name
  IsDemo
}
    `;
export const CurrentEnvironmentTenantItemFragmentDoc = gql`
    fragment CurrentEnvironmentTenantItem on CurrentTenantViewModel {
  Id
  Name
  Identifier
  IdentifierType
  SubscriptionNumber
}
    `;
export const CurrentEnvironmentForAdminFragmentDoc = gql`
    fragment CurrentEnvironmentForAdmin on CurrentEnvironmentViewModel {
  EnvironmentId
  Type
  UserId
  FirstName
  LastName
  Email
  UserRoles {
    Id
    Name
  }
  Company {
    ...CurrentEnvironmentCompanyItem
  }
  Tenant {
    ...CurrentEnvironmentTenantItem
  }
}
    ${CurrentEnvironmentCompanyItemFragmentDoc}
${CurrentEnvironmentTenantItemFragmentDoc}`;
export const CompanyAssigmentListItemFragmentDoc = gql`
    fragment CompanyAssigmentListItem on CompanyAssignmentViewModel {
  CompanyId
  CompanyName
  HasKSeFAuthorization
  Identifier
  IsDemo
  PurchaseInvoicesCount
  SalesInvoicesCount
}
    `;
    export const CompanyInfoListItemFragmentDoc = gql`
    fragment CompanyInfoListItem on CompanyInfoViewModel {
  CompanyId
  CompanyName
  Identifier
  IsDemo
}
    `;
export const WhiteListModelFragmentDoc = gql`
    fragment WhiteListModel on WhiteListStatusDtoModel {
  IsValidated
  Accounts {
    Account
    Date
    ErrorMessage
    IsWhiteListed
  }
}
    `;
export const ContractorsItemFragmentDoc = gql`
    fragment ContractorsItem on ContractorsDto {
  CompanyId
  CreatedAt
  CreatedBy
  Id
  Identifier
  ModifiedAt
  ModifiedBy
  Name
  SupplierType
  WhiteListStatus {
    ...WhiteListModel
  }
}
    ${WhiteListModelFragmentDoc}`;
export const InvoiceItemFragmentDoc = gql`
    fragment InvoiceItem on InvoiceDto {
  AttachmentsCount
  Number
  DateOfIssue
  DestinationSystem
  DocumentType
  FileName
  InvoiceBound
  IssuerName
  IssuerNip
  Currency
  RecipientName
  RecipientNip
  GrossValue
  NetValue
  VatValue
  KsefDate
  KsefNumber
  Downloaded
  KSeFStatus
  CreatedAt
  CreatedBy
  ModifiedAt
  ModifiedBy
  Id
  InvoicePostingStatus
  RegistrationNumber
  CanDownloadUpo
  SchemaVersion
  IsDecreeAccepted
  WhiteListStatus {
    ...WhiteListModel
  }
}
    ${WhiteListModelFragmentDoc}`;
export const IssuerInvoiceFragmentDoc = gql`
    fragment IssuerInvoice on Issuer {
  InvoiceBound
  IssuerName
  IssuertNip
}
    `;
export const EnvironmentListItemFragmentDoc = gql`
    fragment EnvironmentListItem on EnvironmentViewModel {
  Name
  Status
  EnvironmentId
  Type
  IsDemo
  Company {
    Id
    Name
    Identifier
    IdentifierType
  }
  Tenant {
    Id
    Name
    Identifier
    IdentifierType
    Status
  }
}
    `;
export const PackageStatisticsItemFragmentDoc = gql`
    fragment PackageStatisticsItem on Package {
  CreatedAt
  CreatedBy
  Id
  ModifiedAt
  ModifiedBy
  PackageDescription
  PackageTypeEnum
  PackageValue
  Subscription {
    SubscriptionType
    TenantId
  }
  SubscriptionId
}
    `;
export const CredentialsFragmentDoc = gql`
    fragment Credentials on Credential {
  Identifier
  IdentifierType
  CredentialsInvoiceRead
  CredentialsInvoiceWrite
  CredentialsRead
  CredentialsManage
  CredentialsSelfInvoicing
}
    `;
export const RecipientInvoiceFragmentDoc = gql`
    fragment RecipientInvoice on Recipient {
  RecipientNip
  RecipientName
  InvoiceBound
}
    `;
export const TenantListItemFragmentDoc = gql`
    fragment TenantListItem on TenantResponse {
  Id
  Name
  Identifier
  IdentifierType
  SubscriptionNumber
  Status
  DecisionReason
  DecisionDate
  CreatedAt
  Companys {
    Name
    Identifier
    IdentifierType
  }
  Users {
    Email
    FirstName
    LastName
    PhoneNumber
    RoleAssignments {
      Role {
        Name
      }
    }
  }
}
    `;
export const WebSocketNotificationFragmentDoc = gql`
    fragment WebSocketNotification on WebsocketNotificationModel {
  ErrorType
  ErrorContent {
    Key
    Value
  }
  Message
  Type
  NotificationId
  Timestamp
  Visited
  State
  HasDownloadableContent
  CompanyId
  UserId
  ResponseJson
}
    `;
export const WebSocketNotificationResponseFragmentDoc = gql`
    fragment WebSocketNotificationResponse on GetWebsocketNotificationModelResponse {
  ErrorType
  ErrorContent {
    Key
    Value
  }
  Message
  Type
  NotificationId
  Timestamp
  Visited
  State
  HasDownloadableContent
  CompanyId
  UserId
  ResponseJson
}
    `;
export const RequestedCredentialsFragmentDoc = gql`
    fragment RequestedCredentials on RequestedCredential {
  Identifier
  CredentialsInvoiceRead
  CredentialsInvoiceWrite
  CredentialsRead
  CredentialsManage
  CredentialsSelfInvoicing
  ProcessingDescription
  CreatedAt
  RequestType
  ProcessingStatus
}
    `;
export const CompanyListItemFragmentDoc = gql`
    fragment CompanyListItem on Company {
  Id
  Name
  Identifier
  IdentifierType
  IsDemo
  Tenant {
    Id
  }
}
    `;
export const RoleShortListItemFragmentDoc = gql`
    fragment RoleShortListItem on Role {
  Id
  Name
}
    `;
export const CompanyShortListItemFragmentDoc = gql`
    fragment CompanyShortListItem on Company {
  Id
  Name
}
    `;
export const UserWithDetailsItemFragmentDoc = gql`
    fragment UserWithDetailsItem on User {
  Email
  FirstName
  LastName
  PhoneNumber
  Id
  RoleAssignments: RoleAssignments {
    Role {
      ...RoleShortListItem
    }
    Id
    TenantId
    CompanyId
    Company {
      ...CompanyShortListItem
    }
  }
}
    ${RoleShortListItemFragmentDoc}
${CompanyShortListItemFragmentDoc}`;
export const InvoiceDocumentTypeFragmentDoc = gql`
    fragment InvoiceDocumentType on InvoiceDocumentTypeResponse {
  Id
  Name
  VatRegistryId
  VatRegistryName
}
    `;
export const ContractorFragmentDoc = gql`
    fragment Contractor on ContractorResponse {
  Id
  IdentifierType
  Identifier
  Name
  ContractorOriginType
  ContractorRelatedType
  Street
  Place
  PostCode
}
    `;
export const PositionFragmentDoc = gql`
    fragment Position on PositionDetailsResponse {
  Id
  InvoicePositionId
  Name
  Value
  IsRuleCategory
  VehicleId
  CanEditVehicles
  IsRound
}
    `;
export const DecreeLineFragmentDoc = gql`
    fragment DecreeLine on DecreeLineResponse {
  Id
  AccountNameAndNumber
  CategoryId
  CategoryMlKey
  CategoryName
  Account {
    Id
    Name
    Number
    AccountType
  }
  CanAccountBeChanged
  LineType
  AccountType
  Value
  Positions {
    ...Position
  }
}
    ${PositionFragmentDoc}`;
export const DecreeDetailsItemFragmentDoc = gql`
    fragment DecreeDetailsItem on DecreeResponse {
  Id
  InvoiceDocumentId
  InvoiceNumber
  PostingStatus
  Gross
  Net
  Vat
  KSeFNumber
  KSeFDate
  DateOfIssue
  OperationDate
  PaymentType
  PaymentDate
  CurrencyCode
  InvoiceType
  InvoiceTypeGroup
  IsAccepted
  DocumentType {
    ...InvoiceDocumentType
  }
  Contractor {
    ...Contractor
  }
  DecreeLines {
    ...DecreeLine
  }
}
    ${InvoiceDocumentTypeFragmentDoc}
${ContractorFragmentDoc}
${DecreeLineFragmentDoc}`;
export const AccountsPlanFragmentDoc = gql`
    fragment AccountsPlan on AccountResponse {
  Id
  Number
  Name
  IsFinal
  ParentId
  ChildrenIds
  AccountType
  FinancialYearId
}
    `;
export const VatSummariesFragmentDoc = gql`
    fragment VatSummaries on VatSummaryResponse {
  Id
  Vat
  Gross
  Net
  GTUItems
  TaxRate
  VatRegisterId
  VatRegisterName
}
    `;
export const CurrentEnvironmentItemFragmentDoc = gql`
    fragment CurrentEnvironmentItem on CurrentEnvironmentViewModel {
  EnvironmentId
  Type
  UserId
  FirstName
  LastName
  Email
  UserRoles {
    Id
    Name
  }
  Company {
    ...CurrentEnvironmentCompanyItem
  }
  Tenant {
    ...CurrentEnvironmentTenantItem
  }
  UserProperties {
    AutoPosting {
      Status
    }
    EArchive {
      AutoFetchingInvoices {
        Status
        ExternalEnabled
        InternalEnabled
        LastModifiedBy
        LastModifiedDate
        Minutes
        Type
        DownloadLimitDate
      }
      AutoSendingInvoices {
        Status
        LastModifiedBy
        LastModifiedDate
        Minutes
        Type
      }
      SharedAccess {
        Status
        LastModifiedBy
        LastModifiedDate
        UserId
      }
      WhiteListValidation {
        Status
        LastModifiedBy
        LastModifiedDate
        UserId
      }
    }
    KSeF {
      Authorized
      Credentials
      AuthorizedBy
      AuthorizedAt
    }
  }
}
    ${CurrentEnvironmentCompanyItemFragmentDoc}
${CurrentEnvironmentTenantItemFragmentDoc}`;
export const NotificationFragmentDoc = gql`
    fragment Notification on StandardWebsocketNotification {
  errorContent {
    Key
    Value
  }
  errorType
  message
  notificationId
  timestamp
  type
  visited
  state
}
    `;
export const AcceptTenantDocument = gql`
    mutation AcceptTenant($TenantId: UUID!) {
  AcceptTenant(TenantId: $TenantId) {
    Accepted
  }
}
    `;
export type AcceptTenantMutationFn = Apollo.MutationFunction<AcceptTenantMutation, AcceptTenantMutationVariables>;

/**
 * __useAcceptTenantMutation__
 *
 * To run a mutation, you first call `useAcceptTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTenantMutation, { data, loading, error }] = useAcceptTenantMutation({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *   },
 * });
 */
export function useAcceptTenantMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTenantMutation, AcceptTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTenantMutation, AcceptTenantMutationVariables>(AcceptTenantDocument, options);
      }
export type AcceptTenantMutationHookResult = ReturnType<typeof useAcceptTenantMutation>;
export type AcceptTenantMutationResult = Apollo.MutationResult<AcceptTenantMutation>;
export type AcceptTenantMutationOptions = Apollo.BaseMutationOptions<AcceptTenantMutation, AcceptTenantMutationVariables>;
export const ActivateTenantDocument = gql`
    mutation ActivateTenant($TenantId: UUID!) {
  ActivateTenant(TenantId: $TenantId) {
    Activated
  }
}
    `;
export type ActivateTenantMutationFn = Apollo.MutationFunction<ActivateTenantMutation, ActivateTenantMutationVariables>;

/**
 * __useActivateTenantMutation__
 *
 * To run a mutation, you first call `useActivateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTenantMutation, { data, loading, error }] = useActivateTenantMutation({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *   },
 * });
 */
export function useActivateTenantMutation(baseOptions?: Apollo.MutationHookOptions<ActivateTenantMutation, ActivateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateTenantMutation, ActivateTenantMutationVariables>(ActivateTenantDocument, options);
      }
export type ActivateTenantMutationHookResult = ReturnType<typeof useActivateTenantMutation>;
export type ActivateTenantMutationResult = Apollo.MutationResult<ActivateTenantMutation>;
export type ActivateTenantMutationOptions = Apollo.BaseMutationOptions<ActivateTenantMutation, ActivateTenantMutationVariables>;
export const AuthorizeInKSeFDocument = gql`
    mutation AuthorizeInKSeF($InitSessionRequestSigned: String!) {
  AuthorizeInKSeF(InitSessionRequestSigned: $InitSessionRequestSigned) {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type AuthorizeInKSeFMutationFn = Apollo.MutationFunction<AuthorizeInKSeFMutation, AuthorizeInKSeFMutationVariables>;

/**
 * __useAuthorizeInKSeFMutation__
 *
 * To run a mutation, you first call `useAuthorizeInKSeFMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeInKSeFMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeInKSeFMutation, { data, loading, error }] = useAuthorizeInKSeFMutation({
 *   variables: {
 *      InitSessionRequestSigned: // value for 'InitSessionRequestSigned'
 *   },
 * });
 */
export function useAuthorizeInKSeFMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizeInKSeFMutation, AuthorizeInKSeFMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorizeInKSeFMutation, AuthorizeInKSeFMutationVariables>(AuthorizeInKSeFDocument, options);
      }
export type AuthorizeInKSeFMutationHookResult = ReturnType<typeof useAuthorizeInKSeFMutation>;
export type AuthorizeInKSeFMutationResult = Apollo.MutationResult<AuthorizeInKSeFMutation>;
export type AuthorizeInKSeFMutationOptions = Apollo.BaseMutationOptions<AuthorizeInKSeFMutation, AuthorizeInKSeFMutationVariables>;
export const AuthorizeInKSeFWithTokenDocument = gql`
    mutation AuthorizeInKSeFWithToken($Token: String!) {
  AuthorizeInKSeFWithToken(Token: $Token) {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type AuthorizeInKSeFWithTokenMutationFn = Apollo.MutationFunction<AuthorizeInKSeFWithTokenMutation, AuthorizeInKSeFWithTokenMutationVariables>;

/**
 * __useAuthorizeInKSeFWithTokenMutation__
 *
 * To run a mutation, you first call `useAuthorizeInKSeFWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeInKSeFWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeInKSeFWithTokenMutation, { data, loading, error }] = useAuthorizeInKSeFWithTokenMutation({
 *   variables: {
 *      Token: // value for 'Token'
 *   },
 * });
 */
export function useAuthorizeInKSeFWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizeInKSeFWithTokenMutation, AuthorizeInKSeFWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorizeInKSeFWithTokenMutation, AuthorizeInKSeFWithTokenMutationVariables>(AuthorizeInKSeFWithTokenDocument, options);
      }
export type AuthorizeInKSeFWithTokenMutationHookResult = ReturnType<typeof useAuthorizeInKSeFWithTokenMutation>;
export type AuthorizeInKSeFWithTokenMutationResult = Apollo.MutationResult<AuthorizeInKSeFWithTokenMutation>;
export type AuthorizeInKSeFWithTokenMutationOptions = Apollo.BaseMutationOptions<AuthorizeInKSeFWithTokenMutation, AuthorizeInKSeFWithTokenMutationVariables>;
export const CancelWebsocketNotificationDocument = gql`
    mutation CancelWebsocketNotification($NotificationId: UUID!) {
  CancelWebsocketNotification(notificationId: $NotificationId) {
    Success
  }
}
    `;
export type CancelWebsocketNotificationMutationFn = Apollo.MutationFunction<CancelWebsocketNotificationMutation, CancelWebsocketNotificationMutationVariables>;

/**
 * __useCancelWebsocketNotificationMutation__
 *
 * To run a mutation, you first call `useCancelWebsocketNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWebsocketNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWebsocketNotificationMutation, { data, loading, error }] = useCancelWebsocketNotificationMutation({
 *   variables: {
 *      NotificationId: // value for 'NotificationId'
 *   },
 * });
 */
export function useCancelWebsocketNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CancelWebsocketNotificationMutation, CancelWebsocketNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelWebsocketNotificationMutation, CancelWebsocketNotificationMutationVariables>(CancelWebsocketNotificationDocument, options);
      }
export type CancelWebsocketNotificationMutationHookResult = ReturnType<typeof useCancelWebsocketNotificationMutation>;
export type CancelWebsocketNotificationMutationResult = Apollo.MutationResult<CancelWebsocketNotificationMutation>;
export type CancelWebsocketNotificationMutationOptions = Apollo.BaseMutationOptions<CancelWebsocketNotificationMutation, CancelWebsocketNotificationMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($Identifier: String!, $IdentifierType: IdentifierType!, $Name: String!, $SubscriptionNumber: String!, $UserFirstName: String!, $UserLastName: String!, $UserPhoneNumber: String!, $CloudConsent: Boolean!, $PersonalDataConsent: Boolean!, $PrivacyPolicyConsent: Boolean!, $CompanyRepresentationConsent: Boolean!, $MarketingEndDeviceConsent: Boolean!, $MarketingPhoneConsent: Boolean!, $NewsletterConsent: Boolean!, $MarketingEmailConsent: Boolean!, $PersonalDataForPartnersConsent: Boolean!) {
  CreateOrganization(
    Identifier: $Identifier
    IdentifierType: $IdentifierType
    Name: $Name
    SubscriptionNumber: $SubscriptionNumber
    UserFirstName: $UserFirstName
    UserLastName: $UserLastName
    UserPhoneNumber: $UserPhoneNumber
    CloudConsent: $CloudConsent
    PersonalDataConsent: $PersonalDataConsent
    PrivacyPolicyConsent: $PrivacyPolicyConsent
    CompanyRepresentationConsent: $CompanyRepresentationConsent
    MarketingEndDeviceConsent: $MarketingEndDeviceConsent
    MarketingPhoneConsent: $MarketingPhoneConsent
    NewsletterConsent: $NewsletterConsent
    MarketingEmailConsent: $MarketingEmailConsent
    PersonalDataForPartnersConsent: $PersonalDataForPartnersConsent
  ) {
    TenantId
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      Identifier: // value for 'Identifier'
 *      IdentifierType: // value for 'IdentifierType'
 *      Name: // value for 'Name'
 *      SubscriptionNumber: // value for 'SubscriptionNumber'
 *      UserFirstName: // value for 'UserFirstName'
 *      UserLastName: // value for 'UserLastName'
 *      UserPhoneNumber: // value for 'UserPhoneNumber'
 *      CloudConsent: // value for 'CloudConsent'
 *      PersonalDataConsent: // value for 'PersonalDataConsent'
 *      PrivacyPolicyConsent: // value for 'PrivacyPolicyConsent'
 *      CompanyRepresentationConsent: // value for 'CompanyRepresentationConsent'
 *      MarketingEndDeviceConsent: // value for 'MarketingEndDeviceConsent'
 *      MarketingPhoneConsent: // value for 'MarketingPhoneConsent'
 *      NewsletterConsent: // value for 'NewsletterConsent'
 *      MarketingEmailConsent: // value for 'MarketingEmailConsent'
 *      PersonalDataForPartnersConsent: // value for 'PersonalDataForPartnersConsent'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const CreateTenantDocument = gql`
    mutation CreateTenant($TenantName: String, $IdentifierType: IdentifierType!, $Identifier: String) {
  CreateTenant(
    Input: {TenantName: $TenantName, IdentifierType: $IdentifierType, Identifier: $Identifier}
  ) {
    Name
    Id
  }
}
    `;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      TenantName: // value for 'TenantName'
 *      IdentifierType: // value for 'IdentifierType'
 *      Identifier: // value for 'Identifier'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($Email: String!, $FirstName: String!, $LastName: String!, $CloudConsent: Boolean, $PersonalDataConsent: Boolean, $PrivacyPolicyConsent: Boolean, $CompanyRepresentationConsent: Boolean, $MarketingEndDeviceConsent: Boolean, $MarketingEmailConsent: Boolean, $PersonalDataForPartnersConsent: Boolean, $TenantId: UUID!) {
  CreateUser(
    Input: {Email: $Email, FirstName: $FirstName, LastName: $LastName, CloudConsent: $CloudConsent, PersonalDataConsent: $PersonalDataConsent, PrivacyPolicyConsent: $PrivacyPolicyConsent, CompanyRepresentationConsent: $CompanyRepresentationConsent, MarketingEndDeviceConsent: $MarketingEndDeviceConsent, MarketingEmailConsent: $MarketingEmailConsent, PersonalDataForPartnersConsent: $PersonalDataForPartnersConsent, TenantId: $TenantId}
  ) {
    Id
    Email
    FirstName
    LastName
    CloudConsent
    PersonalDataConsent
    PrivacyPolicyConsent
    CompanyRepresentationConsent
    MarketingEndDeviceConsent
    PersonalDataForPartnersConsent
    Tenants {
      Id
      Name
      Identifier
      IdentifierType
      Status
    }
    RoleAssignments {
      Id
      CompanyId
      Company {
        Id
        Name
      }
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      Email: // value for 'Email'
 *      FirstName: // value for 'FirstName'
 *      LastName: // value for 'LastName'
 *      CloudConsent: // value for 'CloudConsent'
 *      PersonalDataConsent: // value for 'PersonalDataConsent'
 *      PrivacyPolicyConsent: // value for 'PrivacyPolicyConsent'
 *      CompanyRepresentationConsent: // value for 'CompanyRepresentationConsent'
 *      MarketingEndDeviceConsent: // value for 'MarketingEndDeviceConsent'
 *      MarketingEmailConsent: // value for 'MarketingEmailConsent'
 *      PersonalDataForPartnersConsent: // value for 'PersonalDataForPartnersConsent'
 *      TenantId: // value for 'TenantId'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeactivateTenantDocument = gql`
    mutation DeactivateTenant($TenantId: UUID!) {
  DeactivateTenant(TenantId: $TenantId) {
    Deactivated
  }
}
    `;
export type DeactivateTenantMutationFn = Apollo.MutationFunction<DeactivateTenantMutation, DeactivateTenantMutationVariables>;

/**
 * __useDeactivateTenantMutation__
 *
 * To run a mutation, you first call `useDeactivateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTenantMutation, { data, loading, error }] = useDeactivateTenantMutation({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *   },
 * });
 */
export function useDeactivateTenantMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateTenantMutation, DeactivateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateTenantMutation, DeactivateTenantMutationVariables>(DeactivateTenantDocument, options);
      }
export type DeactivateTenantMutationHookResult = ReturnType<typeof useDeactivateTenantMutation>;
export type DeactivateTenantMutationResult = Apollo.MutationResult<DeactivateTenantMutation>;
export type DeactivateTenantMutationOptions = Apollo.BaseMutationOptions<DeactivateTenantMutation, DeactivateTenantMutationVariables>;
export const DeleteContributorAssignmentDocument = gql`
    mutation DeleteContributorAssignment($Id: UUID!) {
  DeleteRoleAssignment(Input: {Id: $Id})
}
    `;
export type DeleteContributorAssignmentMutationFn = Apollo.MutationFunction<DeleteContributorAssignmentMutation, DeleteContributorAssignmentMutationVariables>;

/**
 * __useDeleteContributorAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteContributorAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContributorAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContributorAssignmentMutation, { data, loading, error }] = useDeleteContributorAssignmentMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeleteContributorAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContributorAssignmentMutation, DeleteContributorAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContributorAssignmentMutation, DeleteContributorAssignmentMutationVariables>(DeleteContributorAssignmentDocument, options);
      }
export type DeleteContributorAssignmentMutationHookResult = ReturnType<typeof useDeleteContributorAssignmentMutation>;
export type DeleteContributorAssignmentMutationResult = Apollo.MutationResult<DeleteContributorAssignmentMutation>;
export type DeleteContributorAssignmentMutationOptions = Apollo.BaseMutationOptions<DeleteContributorAssignmentMutation, DeleteContributorAssignmentMutationVariables>;
export const DeleteInvoicesDocument = gql`
    mutation DeleteInvoices($InvoiceIds: [UUID!]!) {
  DeleteInvoices(invoiceIds: $InvoiceIds) {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type DeleteInvoicesMutationFn = Apollo.MutationFunction<DeleteInvoicesMutation, DeleteInvoicesMutationVariables>;

/**
 * __useDeleteInvoicesMutation__
 *
 * To run a mutation, you first call `useDeleteInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoicesMutation, { data, loading, error }] = useDeleteInvoicesMutation({
 *   variables: {
 *      InvoiceIds: // value for 'InvoiceIds'
 *   },
 * });
 */
export function useDeleteInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoicesMutation, DeleteInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoicesMutation, DeleteInvoicesMutationVariables>(DeleteInvoicesDocument, options);
      }
export type DeleteInvoicesMutationHookResult = ReturnType<typeof useDeleteInvoicesMutation>;
export type DeleteInvoicesMutationResult = Apollo.MutationResult<DeleteInvoicesMutation>;
export type DeleteInvoicesMutationOptions = Apollo.BaseMutationOptions<DeleteInvoicesMutation, DeleteInvoicesMutationVariables>;
export const DeleteTenantDocument = gql`
    mutation DeleteTenant($Id: UUID!) {
  DeleteTenant(TenantId: $Id) {
    Deleted
  }
}
    `;
export type DeleteTenantMutationFn = Apollo.MutationFunction<DeleteTenantMutation, DeleteTenantMutationVariables>;

/**
 * __useDeleteTenantMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMutation, { data, loading, error }] = useDeleteTenantMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeleteTenantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTenantMutation, DeleteTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTenantMutation, DeleteTenantMutationVariables>(DeleteTenantDocument, options);
      }
export type DeleteTenantMutationHookResult = ReturnType<typeof useDeleteTenantMutation>;
export type DeleteTenantMutationResult = Apollo.MutationResult<DeleteTenantMutation>;
export type DeleteTenantMutationOptions = Apollo.BaseMutationOptions<DeleteTenantMutation, DeleteTenantMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($Id: UUID!) {
  DeleteUser(Input: {Id: $Id})
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DownloadInvoicesFromKSeFDocument = gql`
    mutation DownloadInvoicesFromKSeF($externalEnabled: Boolean!, $internalEnabled: Boolean!) {
  DownloadInvoicesFromKSeF(
    externalEnabled: $externalEnabled
    internalEnabled: $internalEnabled
  ) {
    Imported
    ErrorItems {
      KSeFNumber
      ErrorMessage
    }
  }
}
    `;
export type DownloadInvoicesFromKSeFMutationFn = Apollo.MutationFunction<DownloadInvoicesFromKSeFMutation, DownloadInvoicesFromKSeFMutationVariables>;

/**
 * __useDownloadInvoicesFromKSeFMutation__
 *
 * To run a mutation, you first call `useDownloadInvoicesFromKSeFMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvoicesFromKSeFMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadInvoicesFromKSeFMutation, { data, loading, error }] = useDownloadInvoicesFromKSeFMutation({
 *   variables: {
 *      externalEnabled: // value for 'externalEnabled'
 *      internalEnabled: // value for 'internalEnabled'
 *   },
 * });
 */
export function useDownloadInvoicesFromKSeFMutation(baseOptions?: Apollo.MutationHookOptions<DownloadInvoicesFromKSeFMutation, DownloadInvoicesFromKSeFMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadInvoicesFromKSeFMutation, DownloadInvoicesFromKSeFMutationVariables>(DownloadInvoicesFromKSeFDocument, options);
      }
export type DownloadInvoicesFromKSeFMutationHookResult = ReturnType<typeof useDownloadInvoicesFromKSeFMutation>;
export type DownloadInvoicesFromKSeFMutationResult = Apollo.MutationResult<DownloadInvoicesFromKSeFMutation>;
export type DownloadInvoicesFromKSeFMutationOptions = Apollo.BaseMutationOptions<DownloadInvoicesFromKSeFMutation, DownloadInvoicesFromKSeFMutationVariables>;
export const GenerateInitRequestToLoginDocument = gql`
    mutation GenerateInitRequestToLogin {
  GenerateInitRequestToLogin
}
    `;
export type GenerateInitRequestToLoginMutationFn = Apollo.MutationFunction<GenerateInitRequestToLoginMutation, GenerateInitRequestToLoginMutationVariables>;

/**
 * __useGenerateInitRequestToLoginMutation__
 *
 * To run a mutation, you first call `useGenerateInitRequestToLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateInitRequestToLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateInitRequestToLoginMutation, { data, loading, error }] = useGenerateInitRequestToLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateInitRequestToLoginMutation(baseOptions?: Apollo.MutationHookOptions<GenerateInitRequestToLoginMutation, GenerateInitRequestToLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateInitRequestToLoginMutation, GenerateInitRequestToLoginMutationVariables>(GenerateInitRequestToLoginDocument, options);
      }
export type GenerateInitRequestToLoginMutationHookResult = ReturnType<typeof useGenerateInitRequestToLoginMutation>;
export type GenerateInitRequestToLoginMutationResult = Apollo.MutationResult<GenerateInitRequestToLoginMutation>;
export type GenerateInitRequestToLoginMutationOptions = Apollo.BaseMutationOptions<GenerateInitRequestToLoginMutation, GenerateInitRequestToLoginMutationVariables>;
export const GrantPermissionsInKsefDocument = gql`
    mutation GrantPermissionsInKsef($IsNew: Boolean!, $IdentifierType: IdentifierType!, $Identifier: String!, $Name: String, $CredentialsInvoiceRead: Boolean!, $CredentialsInvoiceWrite: Boolean!, $CredentialsRead: Boolean!, $CredentialsManage: Boolean!, $CredentialsSelfInvoicing: Boolean!, $NipType: KSeFIdentifierType!) {
  SubscribeGrantPermissionsInKSeF(
    IsNew: $IsNew
    IdentifierType: $IdentifierType
    Identifier: $Identifier
    Name: $Name
    CredentialsInvoiceRead: $CredentialsInvoiceRead
    CredentialsInvoiceWrite: $CredentialsInvoiceWrite
    CredentialsRead: $CredentialsRead
    CredentialsManage: $CredentialsManage
    CredentialsSelfInvoicing: $CredentialsSelfInvoicing
    NipType: $NipType
  ) {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type GrantPermissionsInKsefMutationFn = Apollo.MutationFunction<GrantPermissionsInKsefMutation, GrantPermissionsInKsefMutationVariables>;

/**
 * __useGrantPermissionsInKsefMutation__
 *
 * To run a mutation, you first call `useGrantPermissionsInKsefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantPermissionsInKsefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantPermissionsInKsefMutation, { data, loading, error }] = useGrantPermissionsInKsefMutation({
 *   variables: {
 *      IsNew: // value for 'IsNew'
 *      IdentifierType: // value for 'IdentifierType'
 *      Identifier: // value for 'Identifier'
 *      Name: // value for 'Name'
 *      CredentialsInvoiceRead: // value for 'CredentialsInvoiceRead'
 *      CredentialsInvoiceWrite: // value for 'CredentialsInvoiceWrite'
 *      CredentialsRead: // value for 'CredentialsRead'
 *      CredentialsManage: // value for 'CredentialsManage'
 *      CredentialsSelfInvoicing: // value for 'CredentialsSelfInvoicing'
 *      NipType: // value for 'NipType'
 *   },
 * });
 */
export function useGrantPermissionsInKsefMutation(baseOptions?: Apollo.MutationHookOptions<GrantPermissionsInKsefMutation, GrantPermissionsInKsefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GrantPermissionsInKsefMutation, GrantPermissionsInKsefMutationVariables>(GrantPermissionsInKsefDocument, options);
      }
export type GrantPermissionsInKsefMutationHookResult = ReturnType<typeof useGrantPermissionsInKsefMutation>;
export type GrantPermissionsInKsefMutationResult = Apollo.MutationResult<GrantPermissionsInKsefMutation>;
export type GrantPermissionsInKsefMutationOptions = Apollo.BaseMutationOptions<GrantPermissionsInKsefMutation, GrantPermissionsInKsefMutationVariables>;
export const KSeFAutoFetchingInvoicesDocument = gql`
    mutation KSeFAutoFetchingInvoices($Status: CompanyKsefServicesStatusEnum!, $ExternalEnabled: Boolean!, $InternalEnabled: Boolean!, $Type: KSeFAutomaticOperationType!, $Minutes: Int!, $DownloadLimitDate: DateTime) {
  KSeFAutoFetchingInvoices(
    Status: $Status
    ExternalEnabled: $ExternalEnabled
    InternalEnabled: $InternalEnabled
    Type: $Type
    Minutes: $Minutes
    DownloadLimitDate: $DownloadLimitDate
  ) {
    Success
  }
}
    `;
export type KSeFAutoFetchingInvoicesMutationFn = Apollo.MutationFunction<KSeFAutoFetchingInvoicesMutation, KSeFAutoFetchingInvoicesMutationVariables>;

/**
 * __useKSeFAutoFetchingInvoicesMutation__
 *
 * To run a mutation, you first call `useKSeFAutoFetchingInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKSeFAutoFetchingInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kSeFAutoFetchingInvoicesMutation, { data, loading, error }] = useKSeFAutoFetchingInvoicesMutation({
 *   variables: {
 *      Status: // value for 'Status'
 *      ExternalEnabled: // value for 'ExternalEnabled'
 *      InternalEnabled: // value for 'InternalEnabled'
 *      Type: // value for 'Type'
 *      Minutes: // value for 'Minutes'
 *      DownloadLimitDate: // value for 'DownloadLimitDate'
 *   },
 * });
 */
export function useKSeFAutoFetchingInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<KSeFAutoFetchingInvoicesMutation, KSeFAutoFetchingInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KSeFAutoFetchingInvoicesMutation, KSeFAutoFetchingInvoicesMutationVariables>(KSeFAutoFetchingInvoicesDocument, options);
      }
export type KSeFAutoFetchingInvoicesMutationHookResult = ReturnType<typeof useKSeFAutoFetchingInvoicesMutation>;
export type KSeFAutoFetchingInvoicesMutationResult = Apollo.MutationResult<KSeFAutoFetchingInvoicesMutation>;
export type KSeFAutoFetchingInvoicesMutationOptions = Apollo.BaseMutationOptions<KSeFAutoFetchingInvoicesMutation, KSeFAutoFetchingInvoicesMutationVariables>;
export const KSeFAutoSendingInvoicesDocument = gql`
    mutation KSeFAutoSendingInvoices($Status: CompanyKsefServicesStatusEnum!, $type: KSeFAutomaticOperationType!, $minutes: Int!) {
  KSeFAutoSendingInvoices(Status: $Status, type: $type, minutes: $minutes) {
    Success
  }
}
    `;
export type KSeFAutoSendingInvoicesMutationFn = Apollo.MutationFunction<KSeFAutoSendingInvoicesMutation, KSeFAutoSendingInvoicesMutationVariables>;

/**
 * __useKSeFAutoSendingInvoicesMutation__
 *
 * To run a mutation, you first call `useKSeFAutoSendingInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKSeFAutoSendingInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kSeFAutoSendingInvoicesMutation, { data, loading, error }] = useKSeFAutoSendingInvoicesMutation({
 *   variables: {
 *      Status: // value for 'Status'
 *      type: // value for 'type'
 *      minutes: // value for 'minutes'
 *   },
 * });
 */
export function useKSeFAutoSendingInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<KSeFAutoSendingInvoicesMutation, KSeFAutoSendingInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KSeFAutoSendingInvoicesMutation, KSeFAutoSendingInvoicesMutationVariables>(KSeFAutoSendingInvoicesDocument, options);
      }
export type KSeFAutoSendingInvoicesMutationHookResult = ReturnType<typeof useKSeFAutoSendingInvoicesMutation>;
export type KSeFAutoSendingInvoicesMutationResult = Apollo.MutationResult<KSeFAutoSendingInvoicesMutation>;
export type KSeFAutoSendingInvoicesMutationOptions = Apollo.BaseMutationOptions<KSeFAutoSendingInvoicesMutation, KSeFAutoSendingInvoicesMutationVariables>;
export const KSeFInvoiceWhiteListValidationDocument = gql`
    mutation KSeFInvoiceWhiteListValidation($Status: CompanyKsefServicesStatusEnum!) {
  KSeFInvoiceWhiteListValidation(Status: $Status) {
    Success
  }
}
    `;
export type KSeFInvoiceWhiteListValidationMutationFn = Apollo.MutationFunction<KSeFInvoiceWhiteListValidationMutation, KSeFInvoiceWhiteListValidationMutationVariables>;

/**
 * __useKSeFInvoiceWhiteListValidationMutation__
 *
 * To run a mutation, you first call `useKSeFInvoiceWhiteListValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKSeFInvoiceWhiteListValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kSeFInvoiceWhiteListValidationMutation, { data, loading, error }] = useKSeFInvoiceWhiteListValidationMutation({
 *   variables: {
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useKSeFInvoiceWhiteListValidationMutation(baseOptions?: Apollo.MutationHookOptions<KSeFInvoiceWhiteListValidationMutation, KSeFInvoiceWhiteListValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KSeFInvoiceWhiteListValidationMutation, KSeFInvoiceWhiteListValidationMutationVariables>(KSeFInvoiceWhiteListValidationDocument, options);
      }
export type KSeFInvoiceWhiteListValidationMutationHookResult = ReturnType<typeof useKSeFInvoiceWhiteListValidationMutation>;
export type KSeFInvoiceWhiteListValidationMutationResult = Apollo.MutationResult<KSeFInvoiceWhiteListValidationMutation>;
export type KSeFInvoiceWhiteListValidationMutationOptions = Apollo.BaseMutationOptions<KSeFInvoiceWhiteListValidationMutation, KSeFInvoiceWhiteListValidationMutationVariables>;
export const KSeFInvoicesDownloadSharedAccessDocument = gql`
    mutation KSeFInvoicesDownloadSharedAccess($Status: CompanyKsefServicesStatusEnum!) {
  KSeFInvoicesDownloadSharedAccess(Status: $Status) {
    Success
  }
}
    `;
export type KSeFInvoicesDownloadSharedAccessMutationFn = Apollo.MutationFunction<KSeFInvoicesDownloadSharedAccessMutation, KSeFInvoicesDownloadSharedAccessMutationVariables>;

/**
 * __useKSeFInvoicesDownloadSharedAccessMutation__
 *
 * To run a mutation, you first call `useKSeFInvoicesDownloadSharedAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKSeFInvoicesDownloadSharedAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kSeFInvoicesDownloadSharedAccessMutation, { data, loading, error }] = useKSeFInvoicesDownloadSharedAccessMutation({
 *   variables: {
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useKSeFInvoicesDownloadSharedAccessMutation(baseOptions?: Apollo.MutationHookOptions<KSeFInvoicesDownloadSharedAccessMutation, KSeFInvoicesDownloadSharedAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KSeFInvoicesDownloadSharedAccessMutation, KSeFInvoicesDownloadSharedAccessMutationVariables>(KSeFInvoicesDownloadSharedAccessDocument, options);
      }
export type KSeFInvoicesDownloadSharedAccessMutationHookResult = ReturnType<typeof useKSeFInvoicesDownloadSharedAccessMutation>;
export type KSeFInvoicesDownloadSharedAccessMutationResult = Apollo.MutationResult<KSeFInvoicesDownloadSharedAccessMutation>;
export type KSeFInvoicesDownloadSharedAccessMutationOptions = Apollo.BaseMutationOptions<KSeFInvoicesDownloadSharedAccessMutation, KSeFInvoicesDownloadSharedAccessMutationVariables>;
export const MultipleSendToKSeFDocument = gql`
    mutation MultipleSendToKSeF($InvoiceIds: [UUID!]!) {
  MultipleSendToKSeF(InvoiceIds: $InvoiceIds) {
    NotificationModel {
      ...WebSocketNotification
    }
    Result
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type MultipleSendToKSeFMutationFn = Apollo.MutationFunction<MultipleSendToKSeFMutation, MultipleSendToKSeFMutationVariables>;

/**
 * __useMultipleSendToKSeFMutation__
 *
 * To run a mutation, you first call `useMultipleSendToKSeFMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultipleSendToKSeFMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multipleSendToKSeFMutation, { data, loading, error }] = useMultipleSendToKSeFMutation({
 *   variables: {
 *      InvoiceIds: // value for 'InvoiceIds'
 *   },
 * });
 */
export function useMultipleSendToKSeFMutation(baseOptions?: Apollo.MutationHookOptions<MultipleSendToKSeFMutation, MultipleSendToKSeFMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultipleSendToKSeFMutation, MultipleSendToKSeFMutationVariables>(MultipleSendToKSeFDocument, options);
      }
export type MultipleSendToKSeFMutationHookResult = ReturnType<typeof useMultipleSendToKSeFMutation>;
export type MultipleSendToKSeFMutationResult = Apollo.MutationResult<MultipleSendToKSeFMutation>;
export type MultipleSendToKSeFMutationOptions = Apollo.BaseMutationOptions<MultipleSendToKSeFMutation, MultipleSendToKSeFMutationVariables>;
export const PublishInvoicesDocument = gql`
    mutation PublishInvoices($InvoiceIds: [UUID!]!) {
  PublishInvoices(InvoiceIds: $InvoiceIds) {
    Sent
    Failed
  }
}
    `;
export type PublishInvoicesMutationFn = Apollo.MutationFunction<PublishInvoicesMutation, PublishInvoicesMutationVariables>;

/**
 * __usePublishInvoicesMutation__
 *
 * To run a mutation, you first call `usePublishInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishInvoicesMutation, { data, loading, error }] = usePublishInvoicesMutation({
 *   variables: {
 *      InvoiceIds: // value for 'InvoiceIds'
 *   },
 * });
 */
export function usePublishInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<PublishInvoicesMutation, PublishInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishInvoicesMutation, PublishInvoicesMutationVariables>(PublishInvoicesDocument, options);
      }
export type PublishInvoicesMutationHookResult = ReturnType<typeof usePublishInvoicesMutation>;
export type PublishInvoicesMutationResult = Apollo.MutationResult<PublishInvoicesMutation>;
export type PublishInvoicesMutationOptions = Apollo.BaseMutationOptions<PublishInvoicesMutation, PublishInvoicesMutationVariables>;
export const RejectTenantDocument = gql`
    mutation RejectTenant($TenantId: UUID!, $Remarks: String!) {
  RejectTenant(TenantId: $TenantId, Remarks: $Remarks) {
    Rejected
  }
}
    `;
export type RejectTenantMutationFn = Apollo.MutationFunction<RejectTenantMutation, RejectTenantMutationVariables>;

/**
 * __useRejectTenantMutation__
 *
 * To run a mutation, you first call `useRejectTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTenantMutation, { data, loading, error }] = useRejectTenantMutation({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *      Remarks: // value for 'Remarks'
 *   },
 * });
 */
export function useRejectTenantMutation(baseOptions?: Apollo.MutationHookOptions<RejectTenantMutation, RejectTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectTenantMutation, RejectTenantMutationVariables>(RejectTenantDocument, options);
      }
export type RejectTenantMutationHookResult = ReturnType<typeof useRejectTenantMutation>;
export type RejectTenantMutationResult = Apollo.MutationResult<RejectTenantMutation>;
export type RejectTenantMutationOptions = Apollo.BaseMutationOptions<RejectTenantMutation, RejectTenantMutationVariables>;
export const RemoveAuthorizationInKSeFDocument = gql`
    mutation RemoveAuthorizationInKSeF($InitSessionRequestSigned: String!) {
  RemoveAuthorizationInKSeF(InitSessionRequestSigned: $InitSessionRequestSigned) {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type RemoveAuthorizationInKSeFMutationFn = Apollo.MutationFunction<RemoveAuthorizationInKSeFMutation, RemoveAuthorizationInKSeFMutationVariables>;

/**
 * __useRemoveAuthorizationInKSeFMutation__
 *
 * To run a mutation, you first call `useRemoveAuthorizationInKSeFMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAuthorizationInKSeFMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAuthorizationInKSeFMutation, { data, loading, error }] = useRemoveAuthorizationInKSeFMutation({
 *   variables: {
 *      InitSessionRequestSigned: // value for 'InitSessionRequestSigned'
 *   },
 * });
 */
export function useRemoveAuthorizationInKSeFMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAuthorizationInKSeFMutation, RemoveAuthorizationInKSeFMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAuthorizationInKSeFMutation, RemoveAuthorizationInKSeFMutationVariables>(RemoveAuthorizationInKSeFDocument, options);
      }
export type RemoveAuthorizationInKSeFMutationHookResult = ReturnType<typeof useRemoveAuthorizationInKSeFMutation>;
export type RemoveAuthorizationInKSeFMutationResult = Apollo.MutationResult<RemoveAuthorizationInKSeFMutation>;
export type RemoveAuthorizationInKSeFMutationOptions = Apollo.BaseMutationOptions<RemoveAuthorizationInKSeFMutation, RemoveAuthorizationInKSeFMutationVariables>;
export const RemoveAuthorizationWithExternalTokenInKSeFDocument = gql`
    mutation RemoveAuthorizationWithExternalTokenInKSeF {
  RemoveAuthorizationWithExternalTokenInKSeF {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type RemoveAuthorizationWithExternalTokenInKSeFMutationFn = Apollo.MutationFunction<RemoveAuthorizationWithExternalTokenInKSeFMutation, RemoveAuthorizationWithExternalTokenInKSeFMutationVariables>;

/**
 * __useRemoveAuthorizationWithExternalTokenInKSeFMutation__
 *
 * To run a mutation, you first call `useRemoveAuthorizationWithExternalTokenInKSeFMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAuthorizationWithExternalTokenInKSeFMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAuthorizationWithExternalTokenInKSeFMutation, { data, loading, error }] = useRemoveAuthorizationWithExternalTokenInKSeFMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveAuthorizationWithExternalTokenInKSeFMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAuthorizationWithExternalTokenInKSeFMutation, RemoveAuthorizationWithExternalTokenInKSeFMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAuthorizationWithExternalTokenInKSeFMutation, RemoveAuthorizationWithExternalTokenInKSeFMutationVariables>(RemoveAuthorizationWithExternalTokenInKSeFDocument, options);
      }
export type RemoveAuthorizationWithExternalTokenInKSeFMutationHookResult = ReturnType<typeof useRemoveAuthorizationWithExternalTokenInKSeFMutation>;
export type RemoveAuthorizationWithExternalTokenInKSeFMutationResult = Apollo.MutationResult<RemoveAuthorizationWithExternalTokenInKSeFMutation>;
export type RemoveAuthorizationWithExternalTokenInKSeFMutationOptions = Apollo.BaseMutationOptions<RemoveAuthorizationWithExternalTokenInKSeFMutation, RemoveAuthorizationWithExternalTokenInKSeFMutationVariables>;
export const RemoveEArchiveNotificationsDocument = gql`
    mutation RemoveEArchiveNotifications($EArchiveNotificationType: EArchiveNotificationType!) {
  RemoveEArchiveNotifications(EArchiveNotificationType: $EArchiveNotificationType) {
    Success
  }
}
    `;
export type RemoveEArchiveNotificationsMutationFn = Apollo.MutationFunction<RemoveEArchiveNotificationsMutation, RemoveEArchiveNotificationsMutationVariables>;

/**
 * __useRemoveEArchiveNotificationsMutation__
 *
 * To run a mutation, you first call `useRemoveEArchiveNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEArchiveNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEArchiveNotificationsMutation, { data, loading, error }] = useRemoveEArchiveNotificationsMutation({
 *   variables: {
 *      EArchiveNotificationType: // value for 'EArchiveNotificationType'
 *   },
 * });
 */
export function useRemoveEArchiveNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEArchiveNotificationsMutation, RemoveEArchiveNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEArchiveNotificationsMutation, RemoveEArchiveNotificationsMutationVariables>(RemoveEArchiveNotificationsDocument, options);
      }
export type RemoveEArchiveNotificationsMutationHookResult = ReturnType<typeof useRemoveEArchiveNotificationsMutation>;
export type RemoveEArchiveNotificationsMutationResult = Apollo.MutationResult<RemoveEArchiveNotificationsMutation>;
export type RemoveEArchiveNotificationsMutationOptions = Apollo.BaseMutationOptions<RemoveEArchiveNotificationsMutation, RemoveEArchiveNotificationsMutationVariables>;
export const RemovePlatformNotificationsDocument = gql`
    mutation RemovePlatformNotifications($PlatformNotificationType: PlatformNotificationType!) {
  RemovePlatformNotifications(PlatformNotificationType: $PlatformNotificationType) {
    Success
  }
}
    `;
export type RemovePlatformNotificationsMutationFn = Apollo.MutationFunction<RemovePlatformNotificationsMutation, RemovePlatformNotificationsMutationVariables>;

/**
 * __useRemovePlatformNotificationsMutation__
 *
 * To run a mutation, you first call `useRemovePlatformNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlatformNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlatformNotificationsMutation, { data, loading, error }] = useRemovePlatformNotificationsMutation({
 *   variables: {
 *      PlatformNotificationType: // value for 'PlatformNotificationType'
 *   },
 * });
 */
export function useRemovePlatformNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlatformNotificationsMutation, RemovePlatformNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlatformNotificationsMutation, RemovePlatformNotificationsMutationVariables>(RemovePlatformNotificationsDocument, options);
      }
export type RemovePlatformNotificationsMutationHookResult = ReturnType<typeof useRemovePlatformNotificationsMutation>;
export type RemovePlatformNotificationsMutationResult = Apollo.MutationResult<RemovePlatformNotificationsMutation>;
export type RemovePlatformNotificationsMutationOptions = Apollo.BaseMutationOptions<RemovePlatformNotificationsMutation, RemovePlatformNotificationsMutationVariables>;
export const SendInvoicesForPostingDocument = gql`
    mutation SendInvoicesForPosting($InvoiceIds: [UUID!]!) {
  SendInvoicesForPosting(InvoiceIds: $InvoiceIds) {
    IsDone
  }
}
    `;
export type SendInvoicesForPostingMutationFn = Apollo.MutationFunction<SendInvoicesForPostingMutation, SendInvoicesForPostingMutationVariables>;

/**
 * __useSendInvoicesForPostingMutation__
 *
 * To run a mutation, you first call `useSendInvoicesForPostingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoicesForPostingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoicesForPostingMutation, { data, loading, error }] = useSendInvoicesForPostingMutation({
 *   variables: {
 *      InvoiceIds: // value for 'InvoiceIds'
 *   },
 * });
 */
export function useSendInvoicesForPostingMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoicesForPostingMutation, SendInvoicesForPostingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoicesForPostingMutation, SendInvoicesForPostingMutationVariables>(SendInvoicesForPostingDocument, options);
      }
export type SendInvoicesForPostingMutationHookResult = ReturnType<typeof useSendInvoicesForPostingMutation>;
export type SendInvoicesForPostingMutationResult = Apollo.MutationResult<SendInvoicesForPostingMutation>;
export type SendInvoicesForPostingMutationOptions = Apollo.BaseMutationOptions<SendInvoicesForPostingMutation, SendInvoicesForPostingMutationVariables>;
export const SendToKSeFDocument = gql`
    mutation SendToKSeF($InvoiceId: UUID!) {
  SendToKSeF(InvoiceId: $InvoiceId) {
    InvoiceId
    KSeFStatus
  }
}
    `;
export type SendToKSeFMutationFn = Apollo.MutationFunction<SendToKSeFMutation, SendToKSeFMutationVariables>;

/**
 * __useSendToKSeFMutation__
 *
 * To run a mutation, you first call `useSendToKSeFMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToKSeFMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToKSeFMutation, { data, loading, error }] = useSendToKSeFMutation({
 *   variables: {
 *      InvoiceId: // value for 'InvoiceId'
 *   },
 * });
 */
export function useSendToKSeFMutation(baseOptions?: Apollo.MutationHookOptions<SendToKSeFMutation, SendToKSeFMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendToKSeFMutation, SendToKSeFMutationVariables>(SendToKSeFDocument, options);
      }
export type SendToKSeFMutationHookResult = ReturnType<typeof useSendToKSeFMutation>;
export type SendToKSeFMutationResult = Apollo.MutationResult<SendToKSeFMutation>;
export type SendToKSeFMutationOptions = Apollo.BaseMutationOptions<SendToKSeFMutation, SendToKSeFMutationVariables>;
export const SetEArchiveNotificationsDocument = gql`
    mutation SetEArchiveNotifications($EArchiveNotificationType: EArchiveNotificationType!) {
  SetEArchiveNotifications(EArchiveNotificationType: $EArchiveNotificationType) {
    Success
  }
}
    `;
export type SetEArchiveNotificationsMutationFn = Apollo.MutationFunction<SetEArchiveNotificationsMutation, SetEArchiveNotificationsMutationVariables>;

/**
 * __useSetEArchiveNotificationsMutation__
 *
 * To run a mutation, you first call `useSetEArchiveNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEArchiveNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEArchiveNotificationsMutation, { data, loading, error }] = useSetEArchiveNotificationsMutation({
 *   variables: {
 *      EArchiveNotificationType: // value for 'EArchiveNotificationType'
 *   },
 * });
 */
export function useSetEArchiveNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SetEArchiveNotificationsMutation, SetEArchiveNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEArchiveNotificationsMutation, SetEArchiveNotificationsMutationVariables>(SetEArchiveNotificationsDocument, options);
      }
export type SetEArchiveNotificationsMutationHookResult = ReturnType<typeof useSetEArchiveNotificationsMutation>;
export type SetEArchiveNotificationsMutationResult = Apollo.MutationResult<SetEArchiveNotificationsMutation>;
export type SetEArchiveNotificationsMutationOptions = Apollo.BaseMutationOptions<SetEArchiveNotificationsMutation, SetEArchiveNotificationsMutationVariables>;
export const SetPlatformNotificationsDocument = gql`
    mutation SetPlatformNotifications($PlatformNotificationType: PlatformNotificationType!) {
  SetPlatformNotifications(PlatformNotificationType: $PlatformNotificationType) {
    Success
  }
}
    `;
export type SetPlatformNotificationsMutationFn = Apollo.MutationFunction<SetPlatformNotificationsMutation, SetPlatformNotificationsMutationVariables>;

/**
 * __useSetPlatformNotificationsMutation__
 *
 * To run a mutation, you first call `useSetPlatformNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlatformNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlatformNotificationsMutation, { data, loading, error }] = useSetPlatformNotificationsMutation({
 *   variables: {
 *      PlatformNotificationType: // value for 'PlatformNotificationType'
 *   },
 * });
 */
export function useSetPlatformNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SetPlatformNotificationsMutation, SetPlatformNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPlatformNotificationsMutation, SetPlatformNotificationsMutationVariables>(SetPlatformNotificationsDocument, options);
      }
export type SetPlatformNotificationsMutationHookResult = ReturnType<typeof useSetPlatformNotificationsMutation>;
export type SetPlatformNotificationsMutationResult = Apollo.MutationResult<SetPlatformNotificationsMutation>;
export type SetPlatformNotificationsMutationOptions = Apollo.BaseMutationOptions<SetPlatformNotificationsMutation, SetPlatformNotificationsMutationVariables>;
export const SetRoleAssignmentDocument = gql`
    mutation SetRoleAssignment($RoleId: UUID!, $CompanyIds: [UUID]!, $TenantId: UUID!, $UserId: UUID!) {
  SetRoleAssignment(
    RoleId: $RoleId
    CompanyIds: $CompanyIds
    TenantId: $TenantId
    UserId: $UserId
  ) {
    Id
  }
}
    `;
export type SetRoleAssignmentMutationFn = Apollo.MutationFunction<SetRoleAssignmentMutation, SetRoleAssignmentMutationVariables>;

/**
 * __useSetRoleAssignmentMutation__
 *
 * To run a mutation, you first call `useSetRoleAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRoleAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRoleAssignmentMutation, { data, loading, error }] = useSetRoleAssignmentMutation({
 *   variables: {
 *      RoleId: // value for 'RoleId'
 *      CompanyIds: // value for 'CompanyIds'
 *      TenantId: // value for 'TenantId'
 *      UserId: // value for 'UserId'
 *   },
 * });
 */
export function useSetRoleAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<SetRoleAssignmentMutation, SetRoleAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRoleAssignmentMutation, SetRoleAssignmentMutationVariables>(SetRoleAssignmentDocument, options);
      }
export type SetRoleAssignmentMutationHookResult = ReturnType<typeof useSetRoleAssignmentMutation>;
export type SetRoleAssignmentMutationResult = Apollo.MutationResult<SetRoleAssignmentMutation>;
export type SetRoleAssignmentMutationOptions = Apollo.BaseMutationOptions<SetRoleAssignmentMutation, SetRoleAssignmentMutationVariables>;
export const SetSubscriptionTenantDocument = gql`
    mutation SetSubscriptionTenant($TenantId: UUID!, $SerialNumber: String!) {
  SetSubscriptionTenant(TenantId: $TenantId, SerialNumber: $SerialNumber) {
    Success
  }
}
    `;
export type SetSubscriptionTenantMutationFn = Apollo.MutationFunction<SetSubscriptionTenantMutation, SetSubscriptionTenantMutationVariables>;

/**
 * __useSetSubscriptionTenantMutation__
 *
 * To run a mutation, you first call `useSetSubscriptionTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubscriptionTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubscriptionTenantMutation, { data, loading, error }] = useSetSubscriptionTenantMutation({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *      SerialNumber: // value for 'SerialNumber'
 *   },
 * });
 */
export function useSetSubscriptionTenantMutation(baseOptions?: Apollo.MutationHookOptions<SetSubscriptionTenantMutation, SetSubscriptionTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSubscriptionTenantMutation, SetSubscriptionTenantMutationVariables>(SetSubscriptionTenantDocument, options);
      }
export type SetSubscriptionTenantMutationHookResult = ReturnType<typeof useSetSubscriptionTenantMutation>;
export type SetSubscriptionTenantMutationResult = Apollo.MutationResult<SetSubscriptionTenantMutation>;
export type SetSubscriptionTenantMutationOptions = Apollo.BaseMutationOptions<SetSubscriptionTenantMutation, SetSubscriptionTenantMutationVariables>;
export const SetSupplierTypeDocument = gql`
    mutation SetSupplierType($SupplierTypeItems: [SupplierTypeItemInput!]!) {
  SetSupplierType(SupplierTypeItems: $SupplierTypeItems) {
    Result
  }
}
    `;
export type SetSupplierTypeMutationFn = Apollo.MutationFunction<SetSupplierTypeMutation, SetSupplierTypeMutationVariables>;

/**
 * __useSetSupplierTypeMutation__
 *
 * To run a mutation, you first call `useSetSupplierTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSupplierTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSupplierTypeMutation, { data, loading, error }] = useSetSupplierTypeMutation({
 *   variables: {
 *      SupplierTypeItems: // value for 'SupplierTypeItems'
 *   },
 * });
 */
export function useSetSupplierTypeMutation(baseOptions?: Apollo.MutationHookOptions<SetSupplierTypeMutation, SetSupplierTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSupplierTypeMutation, SetSupplierTypeMutationVariables>(SetSupplierTypeDocument, options);
      }
export type SetSupplierTypeMutationHookResult = ReturnType<typeof useSetSupplierTypeMutation>;
export type SetSupplierTypeMutationResult = Apollo.MutationResult<SetSupplierTypeMutation>;
export type SetSupplierTypeMutationOptions = Apollo.BaseMutationOptions<SetSupplierTypeMutation, SetSupplierTypeMutationVariables>;
export const SetWebsocketNotificationAcknowledgeDocument = gql`
    mutation SetWebsocketNotificationAcknowledge($NotificationIds: [UUID!]!) {
  SetWebsocketNotificationAcknowledge(NotificationIds: $NotificationIds) {
    Success
  }
}
    `;
export type SetWebsocketNotificationAcknowledgeMutationFn = Apollo.MutationFunction<SetWebsocketNotificationAcknowledgeMutation, SetWebsocketNotificationAcknowledgeMutationVariables>;

/**
 * __useSetWebsocketNotificationAcknowledgeMutation__
 *
 * To run a mutation, you first call `useSetWebsocketNotificationAcknowledgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWebsocketNotificationAcknowledgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWebsocketNotificationAcknowledgeMutation, { data, loading, error }] = useSetWebsocketNotificationAcknowledgeMutation({
 *   variables: {
 *      NotificationIds: // value for 'NotificationIds'
 *   },
 * });
 */
export function useSetWebsocketNotificationAcknowledgeMutation(baseOptions?: Apollo.MutationHookOptions<SetWebsocketNotificationAcknowledgeMutation, SetWebsocketNotificationAcknowledgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWebsocketNotificationAcknowledgeMutation, SetWebsocketNotificationAcknowledgeMutationVariables>(SetWebsocketNotificationAcknowledgeDocument, options);
      }
export type SetWebsocketNotificationAcknowledgeMutationHookResult = ReturnType<typeof useSetWebsocketNotificationAcknowledgeMutation>;
export type SetWebsocketNotificationAcknowledgeMutationResult = Apollo.MutationResult<SetWebsocketNotificationAcknowledgeMutation>;
export type SetWebsocketNotificationAcknowledgeMutationOptions = Apollo.BaseMutationOptions<SetWebsocketNotificationAcknowledgeMutation, SetWebsocketNotificationAcknowledgeMutationVariables>;
export const SyncPermissionKSeFDocument = gql`
    mutation SyncPermissionKSeF {
  SyncPermissionKSeF {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type SyncPermissionKSeFMutationFn = Apollo.MutationFunction<SyncPermissionKSeFMutation, SyncPermissionKSeFMutationVariables>;

/**
 * __useSyncPermissionKSeFMutation__
 *
 * To run a mutation, you first call `useSyncPermissionKSeFMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPermissionKSeFMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPermissionKSeFMutation, { data, loading, error }] = useSyncPermissionKSeFMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncPermissionKSeFMutation(baseOptions?: Apollo.MutationHookOptions<SyncPermissionKSeFMutation, SyncPermissionKSeFMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncPermissionKSeFMutation, SyncPermissionKSeFMutationVariables>(SyncPermissionKSeFDocument, options);
      }
export type SyncPermissionKSeFMutationHookResult = ReturnType<typeof useSyncPermissionKSeFMutation>;
export type SyncPermissionKSeFMutationResult = Apollo.MutationResult<SyncPermissionKSeFMutation>;
export type SyncPermissionKSeFMutationOptions = Apollo.BaseMutationOptions<SyncPermissionKSeFMutation, SyncPermissionKSeFMutationVariables>;
export const TerminateKSeFSessionDocument = gql`
    mutation TerminateKSeFSession {
  TerminateKSeFSession {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type TerminateKSeFSessionMutationFn = Apollo.MutationFunction<TerminateKSeFSessionMutation, TerminateKSeFSessionMutationVariables>;

/**
 * __useTerminateKSeFSessionMutation__
 *
 * To run a mutation, you first call `useTerminateKSeFSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateKSeFSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateKSeFSessionMutation, { data, loading, error }] = useTerminateKSeFSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useTerminateKSeFSessionMutation(baseOptions?: Apollo.MutationHookOptions<TerminateKSeFSessionMutation, TerminateKSeFSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateKSeFSessionMutation, TerminateKSeFSessionMutationVariables>(TerminateKSeFSessionDocument, options);
      }
export type TerminateKSeFSessionMutationHookResult = ReturnType<typeof useTerminateKSeFSessionMutation>;
export type TerminateKSeFSessionMutationResult = Apollo.MutationResult<TerminateKSeFSessionMutation>;
export type TerminateKSeFSessionMutationOptions = Apollo.BaseMutationOptions<TerminateKSeFSessionMutation, TerminateKSeFSessionMutationVariables>;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($TenantId: UUID!, $Name: String, $Identifier: String, $IdentifierType: IdentifierType!) {
  UpdateTenant(
    Input: {Id: $TenantId, Name: $Name, Identifier: $Identifier, IdentifierType: $IdentifierType}
  ) {
    Id
    Name
    Identifier
    IdentifierType
    SubscriptionNumber
    Status
    DecisionReason
    DecisionDate
    CreatedAt
  }
}
    `;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *      Name: // value for 'Name'
 *      Identifier: // value for 'Identifier'
 *      IdentifierType: // value for 'IdentifierType'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($Id: UUID!, $Email: String!, $FirstName: String!, $LastName: String!, $PhoneNumber: String, $TenantId: UUID!) {
  UpdateUser(
    Input: {Id: $Id, Email: $Email, FirstName: $FirstName, LastName: $LastName, PhoneNumber: $PhoneNumber, TenantId: $TenantId}
  ) {
    ...UserWithDetailsItem
  }
}
    ${UserWithDetailsItemFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *      Email: // value for 'Email'
 *      FirstName: // value for 'FirstName'
 *      LastName: // value for 'LastName'
 *      PhoneNumber: // value for 'PhoneNumber'
 *      TenantId: // value for 'TenantId'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadInvoicesDocument = gql`
    mutation UploadInvoices($invoices: [UploadItemInput!]!) {
  UploadInvoices(invoices: $invoices) {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type UploadInvoicesMutationFn = Apollo.MutationFunction<UploadInvoicesMutation, UploadInvoicesMutationVariables>;

/**
 * __useUploadInvoicesMutation__
 *
 * To run a mutation, you first call `useUploadInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInvoicesMutation, { data, loading, error }] = useUploadInvoicesMutation({
 *   variables: {
 *      invoices: // value for 'invoices'
 *   },
 * });
 */
export function useUploadInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<UploadInvoicesMutation, UploadInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadInvoicesMutation, UploadInvoicesMutationVariables>(UploadInvoicesDocument, options);
      }
export type UploadInvoicesMutationHookResult = ReturnType<typeof useUploadInvoicesMutation>;
export type UploadInvoicesMutationResult = Apollo.MutationResult<UploadInvoicesMutation>;
export type UploadInvoicesMutationOptions = Apollo.BaseMutationOptions<UploadInvoicesMutation, UploadInvoicesMutationVariables>;
export const WhiteListCheckDocument = gql`
    mutation WhiteListCheck($InvoiceIds: [UUID!]!, $Date: DateTime!) {
  SubscribeWhiteListCheck(InvoiceIds: $InvoiceIds, Date: $Date) {
    NotificationModel {
      ...WebSocketNotification
    }
    Result
  }
}
    ${WebSocketNotificationFragmentDoc}`;
export type WhiteListCheckMutationFn = Apollo.MutationFunction<WhiteListCheckMutation, WhiteListCheckMutationVariables>;

/**
 * __useWhiteListCheckMutation__
 *
 * To run a mutation, you first call `useWhiteListCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWhiteListCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [whiteListCheckMutation, { data, loading, error }] = useWhiteListCheckMutation({
 *   variables: {
 *      InvoiceIds: // value for 'InvoiceIds'
 *      Date: // value for 'Date'
 *   },
 * });
 */
export function useWhiteListCheckMutation(baseOptions?: Apollo.MutationHookOptions<WhiteListCheckMutation, WhiteListCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WhiteListCheckMutation, WhiteListCheckMutationVariables>(WhiteListCheckDocument, options);
      }
export type WhiteListCheckMutationHookResult = ReturnType<typeof useWhiteListCheckMutation>;
export type WhiteListCheckMutationResult = Apollo.MutationResult<WhiteListCheckMutation>;
export type WhiteListCheckMutationOptions = Apollo.BaseMutationOptions<WhiteListCheckMutation, WhiteListCheckMutationVariables>;
export const AcceptDecreeDocument = gql`
    mutation AcceptDecree($DecreeIds: [UUID!]!, $UserId: UUID!) {
  AcceptDecree(DecreeIds: $DecreeIds, UserId: $UserId) {
    IsDone
  }
}
    `;
export type AcceptDecreeMutationFn = Apollo.MutationFunction<AcceptDecreeMutation, AcceptDecreeMutationVariables>;

/**
 * __useAcceptDecreeMutation__
 *
 * To run a mutation, you first call `useAcceptDecreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDecreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDecreeMutation, { data, loading, error }] = useAcceptDecreeMutation({
 *   variables: {
 *      DecreeIds: // value for 'DecreeIds'
 *      UserId: // value for 'UserId'
 *   },
 * });
 */
export function useAcceptDecreeMutation(baseOptions?: Apollo.MutationHookOptions<AcceptDecreeMutation, AcceptDecreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptDecreeMutation, AcceptDecreeMutationVariables>(AcceptDecreeDocument, options);
      }
export type AcceptDecreeMutationHookResult = ReturnType<typeof useAcceptDecreeMutation>;
export type AcceptDecreeMutationResult = Apollo.MutationResult<AcceptDecreeMutation>;
export type AcceptDecreeMutationOptions = Apollo.BaseMutationOptions<AcceptDecreeMutation, AcceptDecreeMutationVariables>;
export const ActivateAutoPostingModuleDocument = gql`
    mutation ActivateAutoPostingModule {
  ActivateAutoPostingModule {
    IsDone
  }
}
    `;
export type ActivateAutoPostingModuleMutationFn = Apollo.MutationFunction<ActivateAutoPostingModuleMutation, ActivateAutoPostingModuleMutationVariables>;

/**
 * __useActivateAutoPostingModuleMutation__
 *
 * To run a mutation, you first call `useActivateAutoPostingModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAutoPostingModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAutoPostingModuleMutation, { data, loading, error }] = useActivateAutoPostingModuleMutation({
 *   variables: {
 *   },
 * });
 */
export function useActivateAutoPostingModuleMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAutoPostingModuleMutation, ActivateAutoPostingModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAutoPostingModuleMutation, ActivateAutoPostingModuleMutationVariables>(ActivateAutoPostingModuleDocument, options);
      }
export type ActivateAutoPostingModuleMutationHookResult = ReturnType<typeof useActivateAutoPostingModuleMutation>;
export type ActivateAutoPostingModuleMutationResult = Apollo.MutationResult<ActivateAutoPostingModuleMutation>;
export type ActivateAutoPostingModuleMutationOptions = Apollo.BaseMutationOptions<ActivateAutoPostingModuleMutation, ActivateAutoPostingModuleMutationVariables>;
export const AddAttachmentDocument = gql`
    mutation AddAttachment($input: AddAttachmentInput!) {
  AddAttachment(Input: $input) {
    Id
    FileName
  }
}
    `;
export type AddAttachmentMutationFn = Apollo.MutationFunction<AddAttachmentMutation, AddAttachmentMutationVariables>;

/**
 * __useAddAttachmentMutation__
 *
 * To run a mutation, you first call `useAddAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttachmentMutation, { data, loading, error }] = useAddAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<AddAttachmentMutation, AddAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAttachmentMutation, AddAttachmentMutationVariables>(AddAttachmentDocument, options);
      }
export type AddAttachmentMutationHookResult = ReturnType<typeof useAddAttachmentMutation>;
export type AddAttachmentMutationResult = Apollo.MutationResult<AddAttachmentMutation>;
export type AddAttachmentMutationOptions = Apollo.BaseMutationOptions<AddAttachmentMutation, AddAttachmentMutationVariables>;
export const ArchiveCategoryDocument = gql`
    mutation ArchiveCategory($CategoryId: UUID!, $NextCategoryId: UUID, $ShouldMoveCategoryRules: Boolean!) {
  ArchiveCategory(
    CategoryId: $CategoryId
    NextCategoryId: $NextCategoryId
    ShouldMoveCategoryRules: $ShouldMoveCategoryRules
  ) {
    IsDone
  }
}
    `;
export type ArchiveCategoryMutationFn = Apollo.MutationFunction<ArchiveCategoryMutation, ArchiveCategoryMutationVariables>;

/**
 * __useArchiveCategoryMutation__
 *
 * To run a mutation, you first call `useArchiveCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCategoryMutation, { data, loading, error }] = useArchiveCategoryMutation({
 *   variables: {
 *      CategoryId: // value for 'CategoryId'
 *      NextCategoryId: // value for 'NextCategoryId'
 *      ShouldMoveCategoryRules: // value for 'ShouldMoveCategoryRules'
 *   },
 * });
 */
export function useArchiveCategoryMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveCategoryMutation, ArchiveCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveCategoryMutation, ArchiveCategoryMutationVariables>(ArchiveCategoryDocument, options);
      }
export type ArchiveCategoryMutationHookResult = ReturnType<typeof useArchiveCategoryMutation>;
export type ArchiveCategoryMutationResult = Apollo.MutationResult<ArchiveCategoryMutation>;
export type ArchiveCategoryMutationOptions = Apollo.BaseMutationOptions<ArchiveCategoryMutation, ArchiveCategoryMutationVariables>;
export const ChangeCategoryNameDocument = gql`
    mutation ChangeCategoryName($Name: String!, $CategoryId: UUID!, $CompanyId: UUID!) {
  ChangeCategoryName(Name: $Name, CategoryId: $CategoryId, CompanyId: $CompanyId) {
    IsDone
  }
}
    `;
export type ChangeCategoryNameMutationFn = Apollo.MutationFunction<ChangeCategoryNameMutation, ChangeCategoryNameMutationVariables>;

/**
 * __useChangeCategoryNameMutation__
 *
 * To run a mutation, you first call `useChangeCategoryNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCategoryNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCategoryNameMutation, { data, loading, error }] = useChangeCategoryNameMutation({
 *   variables: {
 *      Name: // value for 'Name'
 *      CategoryId: // value for 'CategoryId'
 *      CompanyId: // value for 'CompanyId'
 *   },
 * });
 */
export function useChangeCategoryNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCategoryNameMutation, ChangeCategoryNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCategoryNameMutation, ChangeCategoryNameMutationVariables>(ChangeCategoryNameDocument, options);
      }
export type ChangeCategoryNameMutationHookResult = ReturnType<typeof useChangeCategoryNameMutation>;
export type ChangeCategoryNameMutationResult = Apollo.MutationResult<ChangeCategoryNameMutation>;
export type ChangeCategoryNameMutationOptions = Apollo.BaseMutationOptions<ChangeCategoryNameMutation, ChangeCategoryNameMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($Name: String!, $CompanyId: UUID!, $InvoiceTypeGroup: InvoiceTypeGroupEnum!) {
  CreateCategory(
    Name: $Name
    CompanyId: $CompanyId
    InvoiceTypeGroup: $InvoiceTypeGroup
  ) {
    IsDone
    NewCategoryId
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      Name: // value for 'Name'
 *      CompanyId: // value for 'CompanyId'
 *      InvoiceTypeGroup: // value for 'InvoiceTypeGroup'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreateCompanyVehicleDocument = gql`
    mutation CreateCompanyVehicle($RegistrationNumber: String!, $VehicleUsageType: VehicleUsageTypeEnum!, $CompanyId: UUID!, $Description: String) {
  CreateCompanyVehicle(
    RegistrationNumber: $RegistrationNumber
    VehicleUsageType: $VehicleUsageType
    CompanyId: $CompanyId
    Description: $Description
  ) {
    CompanyVehicle {
      Id
      Description
      RegistrationNumber
      VehicleUsageType
    }
  }
}
    `;
export type CreateCompanyVehicleMutationFn = Apollo.MutationFunction<CreateCompanyVehicleMutation, CreateCompanyVehicleMutationVariables>;

/**
 * __useCreateCompanyVehicleMutation__
 *
 * To run a mutation, you first call `useCreateCompanyVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyVehicleMutation, { data, loading, error }] = useCreateCompanyVehicleMutation({
 *   variables: {
 *      RegistrationNumber: // value for 'RegistrationNumber'
 *      VehicleUsageType: // value for 'VehicleUsageType'
 *      CompanyId: // value for 'CompanyId'
 *      Description: // value for 'Description'
 *   },
 * });
 */
export function useCreateCompanyVehicleMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyVehicleMutation, CreateCompanyVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyVehicleMutation, CreateCompanyVehicleMutationVariables>(CreateCompanyVehicleDocument, options);
      }
export type CreateCompanyVehicleMutationHookResult = ReturnType<typeof useCreateCompanyVehicleMutation>;
export type CreateCompanyVehicleMutationResult = Apollo.MutationResult<CreateCompanyVehicleMutation>;
export type CreateCompanyVehicleMutationOptions = Apollo.BaseMutationOptions<CreateCompanyVehicleMutation, CreateCompanyVehicleMutationVariables>;
export const DeleteAttachmentsDocument = gql`
    mutation DeleteAttachments($Id: [UUID!]!, $InvoiceId: UUID!) {
  DeleteAttachment(Ids: $Id, InvoiceId: $InvoiceId) {
    IsDone
  }
}
    `;
export type DeleteAttachmentsMutationFn = Apollo.MutationFunction<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>;

/**
 * __useDeleteAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentsMutation, { data, loading, error }] = useDeleteAttachmentsMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *      InvoiceId: // value for 'InvoiceId'
 *   },
 * });
 */
export function useDeleteAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>(DeleteAttachmentsDocument, options);
      }
export type DeleteAttachmentsMutationHookResult = ReturnType<typeof useDeleteAttachmentsMutation>;
export type DeleteAttachmentsMutationResult = Apollo.MutationResult<DeleteAttachmentsMutation>;
export type DeleteAttachmentsMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentsMutation, DeleteAttachmentsMutationVariables>;
export const GenerateZippedInvoiceAttachmentsDocument = gql`
    mutation GenerateZippedInvoiceAttachments($Ids: [UUID!]!, $InvoiceId: UUID!, $IncludeXML: Boolean!, $IncludePDF: Boolean!) {
  GenerateZippedInvoiceAttachments(
    Ids: $Ids
    InvoiceId: $InvoiceId
    IncludeXML: $IncludeXML
    IncludePDF: $IncludePDF
  ) {
    Link
    FileName
  }
}
    `;
export type GenerateZippedInvoiceAttachmentsMutationFn = Apollo.MutationFunction<GenerateZippedInvoiceAttachmentsMutation, GenerateZippedInvoiceAttachmentsMutationVariables>;

/**
 * __useGenerateZippedInvoiceAttachmentsMutation__
 *
 * To run a mutation, you first call `useGenerateZippedInvoiceAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateZippedInvoiceAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateZippedInvoiceAttachmentsMutation, { data, loading, error }] = useGenerateZippedInvoiceAttachmentsMutation({
 *   variables: {
 *      Ids: // value for 'Ids'
 *      InvoiceId: // value for 'InvoiceId'
 *      IncludeXML: // value for 'IncludeXML'
 *      IncludePDF: // value for 'IncludePDF'
 *   },
 * });
 */
export function useGenerateZippedInvoiceAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateZippedInvoiceAttachmentsMutation, GenerateZippedInvoiceAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateZippedInvoiceAttachmentsMutation, GenerateZippedInvoiceAttachmentsMutationVariables>(GenerateZippedInvoiceAttachmentsDocument, options);
      }
export type GenerateZippedInvoiceAttachmentsMutationHookResult = ReturnType<typeof useGenerateZippedInvoiceAttachmentsMutation>;
export type GenerateZippedInvoiceAttachmentsMutationResult = Apollo.MutationResult<GenerateZippedInvoiceAttachmentsMutation>;
export type GenerateZippedInvoiceAttachmentsMutationOptions = Apollo.BaseMutationOptions<GenerateZippedInvoiceAttachmentsMutation, GenerateZippedInvoiceAttachmentsMutationVariables>;
export const RemoveCompanyVehicleDocument = gql`
    mutation RemoveCompanyVehicle($Id: UUID!) {
  RemoveCompanyVehicle(Id: $Id) {
    IsDone
  }
}
    `;
export type RemoveCompanyVehicleMutationFn = Apollo.MutationFunction<RemoveCompanyVehicleMutation, RemoveCompanyVehicleMutationVariables>;

/**
 * __useRemoveCompanyVehicleMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyVehicleMutation, { data, loading, error }] = useRemoveCompanyVehicleMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useRemoveCompanyVehicleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyVehicleMutation, RemoveCompanyVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyVehicleMutation, RemoveCompanyVehicleMutationVariables>(RemoveCompanyVehicleDocument, options);
      }
export type RemoveCompanyVehicleMutationHookResult = ReturnType<typeof useRemoveCompanyVehicleMutation>;
export type RemoveCompanyVehicleMutationResult = Apollo.MutationResult<RemoveCompanyVehicleMutation>;
export type RemoveCompanyVehicleMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyVehicleMutation, RemoveCompanyVehicleMutationVariables>;
export const ResetModuleInitializationDocument = gql`
    mutation ResetModuleInitialization {
  ResetModuleInitialization {
    IsDone
  }
}
    `;
export type ResetModuleInitializationMutationFn = Apollo.MutationFunction<ResetModuleInitializationMutation, ResetModuleInitializationMutationVariables>;

/**
 * __useResetModuleInitializationMutation__
 *
 * To run a mutation, you first call `useResetModuleInitializationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetModuleInitializationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetModuleInitializationMutation, { data, loading, error }] = useResetModuleInitializationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetModuleInitializationMutation(baseOptions?: Apollo.MutationHookOptions<ResetModuleInitializationMutation, ResetModuleInitializationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetModuleInitializationMutation, ResetModuleInitializationMutationVariables>(ResetModuleInitializationDocument, options);
      }
export type ResetModuleInitializationMutationHookResult = ReturnType<typeof useResetModuleInitializationMutation>;
export type ResetModuleInitializationMutationResult = Apollo.MutationResult<ResetModuleInitializationMutation>;
export type ResetModuleInitializationMutationOptions = Apollo.BaseMutationOptions<ResetModuleInitializationMutation, ResetModuleInitializationMutationVariables>;
export const SaveAutoPostingSettingsDocument = gql`
    mutation SaveAutoPostingSettings($CompanyId: UUID!, $UserId: UUID!, $AutoPostingSettingChanges: [AutoPostingSettingChangeInput!]!) {
  SaveAutoPostingSettings(
    CompanyId: $CompanyId
    UserId: $UserId
    AutoPostingSettingChanges: $AutoPostingSettingChanges
  ) {
    IsDone
  }
}
    `;
export type SaveAutoPostingSettingsMutationFn = Apollo.MutationFunction<SaveAutoPostingSettingsMutation, SaveAutoPostingSettingsMutationVariables>;

/**
 * __useSaveAutoPostingSettingsMutation__
 *
 * To run a mutation, you first call `useSaveAutoPostingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAutoPostingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAutoPostingSettingsMutation, { data, loading, error }] = useSaveAutoPostingSettingsMutation({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      UserId: // value for 'UserId'
 *      AutoPostingSettingChanges: // value for 'AutoPostingSettingChanges'
 *   },
 * });
 */
export function useSaveAutoPostingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveAutoPostingSettingsMutation, SaveAutoPostingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAutoPostingSettingsMutation, SaveAutoPostingSettingsMutationVariables>(SaveAutoPostingSettingsDocument, options);
      }
export type SaveAutoPostingSettingsMutationHookResult = ReturnType<typeof useSaveAutoPostingSettingsMutation>;
export type SaveAutoPostingSettingsMutationResult = Apollo.MutationResult<SaveAutoPostingSettingsMutation>;
export type SaveAutoPostingSettingsMutationOptions = Apollo.BaseMutationOptions<SaveAutoPostingSettingsMutation, SaveAutoPostingSettingsMutationVariables>;
export const SaveCategoriesAccountsDocument = gql`
    mutation SaveCategoriesAccounts($UpdatedCategoriesAccounts: [CategoryAccountRequestInput!]!, $CompanyId: UUID!, $FinancialYearId: UUID!) {
  SaveCategoriesAccounts(
    UpdatedCategoriesAccounts: $UpdatedCategoriesAccounts
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
  ) {
    IsDone
  }
}
    `;
export type SaveCategoriesAccountsMutationFn = Apollo.MutationFunction<SaveCategoriesAccountsMutation, SaveCategoriesAccountsMutationVariables>;

/**
 * __useSaveCategoriesAccountsMutation__
 *
 * To run a mutation, you first call `useSaveCategoriesAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCategoriesAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCategoriesAccountsMutation, { data, loading, error }] = useSaveCategoriesAccountsMutation({
 *   variables: {
 *      UpdatedCategoriesAccounts: // value for 'UpdatedCategoriesAccounts'
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *   },
 * });
 */
export function useSaveCategoriesAccountsMutation(baseOptions?: Apollo.MutationHookOptions<SaveCategoriesAccountsMutation, SaveCategoriesAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCategoriesAccountsMutation, SaveCategoriesAccountsMutationVariables>(SaveCategoriesAccountsDocument, options);
      }
export type SaveCategoriesAccountsMutationHookResult = ReturnType<typeof useSaveCategoriesAccountsMutation>;
export type SaveCategoriesAccountsMutationResult = Apollo.MutationResult<SaveCategoriesAccountsMutation>;
export type SaveCategoriesAccountsMutationOptions = Apollo.BaseMutationOptions<SaveCategoriesAccountsMutation, SaveCategoriesAccountsMutationVariables>;
export const SaveChangesOnDecreeDocument = gql`
    mutation SaveChangesOnDecree($DecreeId: UUID!, $CompanyId: UUID!, $UserId: UUID!, $ShouldRememberChanges: Boolean!, $DetailsChanges: DecreeDetailsChangeRequestInput, $CategoryAccountChanges: [CategoryAccountChangeRequestInput!], $PositionCategoryChanges: [PositionCategoryChangeRequestInput!], $PositionAccountChanges: [PositionAccountChangeRequestInput!], $VatSummaryChanges: [VatSummaryChangeRequestInput!], $PositionValueChanges: [PositionValueChangeRequestInput!], $PositionVehicleUsageTypeChanges: [PositionVehicleUsageTypeChangeRequestInput!], $DecreeLineValueChanges: [DecreeLineValueChangeRequestInput!]) {
  SaveChangesOnDecree(
    DecreeId: $DecreeId
    CompanyId: $CompanyId
    UserId: $UserId
    ShouldRememberChanges: $ShouldRememberChanges
    DetailsChanges: $DetailsChanges
    CategoryAccountChanges: $CategoryAccountChanges
    PositionCategoryChanges: $PositionCategoryChanges
    PositionAccountChanges: $PositionAccountChanges
    VatSummaryChanges: $VatSummaryChanges
    PositionValueChanges: $PositionValueChanges
    PositionVehicleUsageTypeChanges: $PositionVehicleUsageTypeChanges
    DecreeLineValueChanges: $DecreeLineValueChanges
  ) {
    IsChanged
  }
}
    `;
export type SaveChangesOnDecreeMutationFn = Apollo.MutationFunction<SaveChangesOnDecreeMutation, SaveChangesOnDecreeMutationVariables>;

/**
 * __useSaveChangesOnDecreeMutation__
 *
 * To run a mutation, you first call `useSaveChangesOnDecreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveChangesOnDecreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveChangesOnDecreeMutation, { data, loading, error }] = useSaveChangesOnDecreeMutation({
 *   variables: {
 *      DecreeId: // value for 'DecreeId'
 *      CompanyId: // value for 'CompanyId'
 *      UserId: // value for 'UserId'
 *      ShouldRememberChanges: // value for 'ShouldRememberChanges'
 *      DetailsChanges: // value for 'DetailsChanges'
 *      CategoryAccountChanges: // value for 'CategoryAccountChanges'
 *      PositionCategoryChanges: // value for 'PositionCategoryChanges'
 *      PositionAccountChanges: // value for 'PositionAccountChanges'
 *      VatSummaryChanges: // value for 'VatSummaryChanges'
 *      PositionValueChanges: // value for 'PositionValueChanges'
 *      PositionVehicleUsageTypeChanges: // value for 'PositionVehicleUsageTypeChanges'
 *      DecreeLineValueChanges: // value for 'DecreeLineValueChanges'
 *   },
 * });
 */
export function useSaveChangesOnDecreeMutation(baseOptions?: Apollo.MutationHookOptions<SaveChangesOnDecreeMutation, SaveChangesOnDecreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveChangesOnDecreeMutation, SaveChangesOnDecreeMutationVariables>(SaveChangesOnDecreeDocument, options);
      }
export type SaveChangesOnDecreeMutationHookResult = ReturnType<typeof useSaveChangesOnDecreeMutation>;
export type SaveChangesOnDecreeMutationResult = Apollo.MutationResult<SaveChangesOnDecreeMutation>;
export type SaveChangesOnDecreeMutationOptions = Apollo.BaseMutationOptions<SaveChangesOnDecreeMutation, SaveChangesOnDecreeMutationVariables>;
export const SaveDocumentTypesSettingsDocument = gql`
    mutation SaveDocumentTypesSettings($InvoiceTypeGroup: InvoiceTypeGroupEnum!, $FinancialYearId: UUID!, $DocumentTypesSettings: [DocumentTypesSettingsRequestInput!]!) {
  SaveDocumentTypesSettings(
    InvoiceTypeGroup: $InvoiceTypeGroup
    FinancialYearId: $FinancialYearId
    DocumentTypesSettings: $DocumentTypesSettings
  ) {
    IsDone
  }
}
    `;
export type SaveDocumentTypesSettingsMutationFn = Apollo.MutationFunction<SaveDocumentTypesSettingsMutation, SaveDocumentTypesSettingsMutationVariables>;

/**
 * __useSaveDocumentTypesSettingsMutation__
 *
 * To run a mutation, you first call `useSaveDocumentTypesSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDocumentTypesSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDocumentTypesSettingsMutation, { data, loading, error }] = useSaveDocumentTypesSettingsMutation({
 *   variables: {
 *      InvoiceTypeGroup: // value for 'InvoiceTypeGroup'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      DocumentTypesSettings: // value for 'DocumentTypesSettings'
 *   },
 * });
 */
export function useSaveDocumentTypesSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveDocumentTypesSettingsMutation, SaveDocumentTypesSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDocumentTypesSettingsMutation, SaveDocumentTypesSettingsMutationVariables>(SaveDocumentTypesSettingsDocument, options);
      }
export type SaveDocumentTypesSettingsMutationHookResult = ReturnType<typeof useSaveDocumentTypesSettingsMutation>;
export type SaveDocumentTypesSettingsMutationResult = Apollo.MutationResult<SaveDocumentTypesSettingsMutation>;
export type SaveDocumentTypesSettingsMutationOptions = Apollo.BaseMutationOptions<SaveDocumentTypesSettingsMutation, SaveDocumentTypesSettingsMutationVariables>;
export const SaveSettlementsDocument = gql`
    mutation SaveSettlements($AccountSettlementsChanges: [AccountSettlementsChangesInput!]!, $CompanyId: UUID!, $FinancialYearId: UUID!, $InvoiceTypeGroup: InvoiceTypeGroupEnum!) {
  SaveSettlements(
    AccountSettlementsChanges: $AccountSettlementsChanges
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    InvoiceTypeGroup: $InvoiceTypeGroup
  ) {
    IsDone
  }
}
    `;
export type SaveSettlementsMutationFn = Apollo.MutationFunction<SaveSettlementsMutation, SaveSettlementsMutationVariables>;

/**
 * __useSaveSettlementsMutation__
 *
 * To run a mutation, you first call `useSaveSettlementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSettlementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSettlementsMutation, { data, loading, error }] = useSaveSettlementsMutation({
 *   variables: {
 *      AccountSettlementsChanges: // value for 'AccountSettlementsChanges'
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      InvoiceTypeGroup: // value for 'InvoiceTypeGroup'
 *   },
 * });
 */
export function useSaveSettlementsMutation(baseOptions?: Apollo.MutationHookOptions<SaveSettlementsMutation, SaveSettlementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSettlementsMutation, SaveSettlementsMutationVariables>(SaveSettlementsDocument, options);
      }
export type SaveSettlementsMutationHookResult = ReturnType<typeof useSaveSettlementsMutation>;
export type SaveSettlementsMutationResult = Apollo.MutationResult<SaveSettlementsMutation>;
export type SaveSettlementsMutationOptions = Apollo.BaseMutationOptions<SaveSettlementsMutation, SaveSettlementsMutationVariables>;
export const SendInvoiceAttachmentsEmailDocument = gql`
    mutation SendInvoiceAttachmentsEmail($InvoiceId: UUID!, $EmailAddresses: [String!]!, $Message: String!, $ShareName: String!, $IncludeXML: Boolean!, $IncludePDF: Boolean!) {
  SendInvoiceAttachmentsEmail(
    InvoiceId: $InvoiceId
    EmailAddresses: $EmailAddresses
    Message: $Message
    ShareName: $ShareName
    IncludeXML: $IncludeXML
    IncludePDF: $IncludePDF
  ) {
    IsDone
  }
}
    `;
export type SendInvoiceAttachmentsEmailMutationFn = Apollo.MutationFunction<SendInvoiceAttachmentsEmailMutation, SendInvoiceAttachmentsEmailMutationVariables>;

/**
 * __useSendInvoiceAttachmentsEmailMutation__
 *
 * To run a mutation, you first call `useSendInvoiceAttachmentsEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvoiceAttachmentsEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvoiceAttachmentsEmailMutation, { data, loading, error }] = useSendInvoiceAttachmentsEmailMutation({
 *   variables: {
 *      InvoiceId: // value for 'InvoiceId'
 *      EmailAddresses: // value for 'EmailAddresses'
 *      Message: // value for 'Message'
 *      ShareName: // value for 'ShareName'
 *      IncludeXML: // value for 'IncludeXML'
 *      IncludePDF: // value for 'IncludePDF'
 *   },
 * });
 */
export function useSendInvoiceAttachmentsEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceAttachmentsEmailMutation, SendInvoiceAttachmentsEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceAttachmentsEmailMutation, SendInvoiceAttachmentsEmailMutationVariables>(SendInvoiceAttachmentsEmailDocument, options);
      }
export type SendInvoiceAttachmentsEmailMutationHookResult = ReturnType<typeof useSendInvoiceAttachmentsEmailMutation>;
export type SendInvoiceAttachmentsEmailMutationResult = Apollo.MutationResult<SendInvoiceAttachmentsEmailMutation>;
export type SendInvoiceAttachmentsEmailMutationOptions = Apollo.BaseMutationOptions<SendInvoiceAttachmentsEmailMutation, SendInvoiceAttachmentsEmailMutationVariables>;
export const UpdateAttachmentAccessibilityDocument = gql`
    mutation UpdateAttachmentAccessibility($Id: UUID!, $IsPublic: Boolean!, $InvoiceId: UUID!) {
  UpdateAttachmentAccessibility(
    Id: $Id
    IsPublic: $IsPublic
    InvoiceId: $InvoiceId
  ) {
    IsDone
  }
}
    `;
export type UpdateAttachmentAccessibilityMutationFn = Apollo.MutationFunction<UpdateAttachmentAccessibilityMutation, UpdateAttachmentAccessibilityMutationVariables>;

/**
 * __useUpdateAttachmentAccessibilityMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentAccessibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentAccessibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentAccessibilityMutation, { data, loading, error }] = useUpdateAttachmentAccessibilityMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *      IsPublic: // value for 'IsPublic'
 *      InvoiceId: // value for 'InvoiceId'
 *   },
 * });
 */
export function useUpdateAttachmentAccessibilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttachmentAccessibilityMutation, UpdateAttachmentAccessibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttachmentAccessibilityMutation, UpdateAttachmentAccessibilityMutationVariables>(UpdateAttachmentAccessibilityDocument, options);
      }
export type UpdateAttachmentAccessibilityMutationHookResult = ReturnType<typeof useUpdateAttachmentAccessibilityMutation>;
export type UpdateAttachmentAccessibilityMutationResult = Apollo.MutationResult<UpdateAttachmentAccessibilityMutation>;
export type UpdateAttachmentAccessibilityMutationOptions = Apollo.BaseMutationOptions<UpdateAttachmentAccessibilityMutation, UpdateAttachmentAccessibilityMutationVariables>;
export const UpdateAttachmentsAccessibilityDocument = gql`
    mutation UpdateAttachmentsAccessibility($Ids: [UUID!]!, $IsPublic: Boolean!, $InvoiceId: UUID!) {
  UpdateAttachmentsAccessibility(
    Ids: $Ids
    IsPublic: $IsPublic
    InvoiceId: $InvoiceId
  ) {
    IsDone
  }
}
    `;
export type UpdateAttachmentsAccessibilityMutationFn = Apollo.MutationFunction<UpdateAttachmentsAccessibilityMutation, UpdateAttachmentsAccessibilityMutationVariables>;

/**
 * __useUpdateAttachmentsAccessibilityMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentsAccessibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentsAccessibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentsAccessibilityMutation, { data, loading, error }] = useUpdateAttachmentsAccessibilityMutation({
 *   variables: {
 *      Ids: // value for 'Ids'
 *      IsPublic: // value for 'IsPublic'
 *      InvoiceId: // value for 'InvoiceId'
 *   },
 * });
 */
export function useUpdateAttachmentsAccessibilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttachmentsAccessibilityMutation, UpdateAttachmentsAccessibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttachmentsAccessibilityMutation, UpdateAttachmentsAccessibilityMutationVariables>(UpdateAttachmentsAccessibilityDocument, options);
      }
export type UpdateAttachmentsAccessibilityMutationHookResult = ReturnType<typeof useUpdateAttachmentsAccessibilityMutation>;
export type UpdateAttachmentsAccessibilityMutationResult = Apollo.MutationResult<UpdateAttachmentsAccessibilityMutation>;
export type UpdateAttachmentsAccessibilityMutationOptions = Apollo.BaseMutationOptions<UpdateAttachmentsAccessibilityMutation, UpdateAttachmentsAccessibilityMutationVariables>;
export const UpdateCompanyVehicleDocument = gql`
    mutation UpdateCompanyVehicle($Id: UUID!, $CompanyId: UUID!, $RegistrationNumber: String!, $VehicleUsageType: VehicleUsageTypeEnum!, $Description: String) {
  UpdateCompanyVehicle(
    Id: $Id
    CompanyId: $CompanyId
    RegistrationNumber: $RegistrationNumber
    VehicleUsageType: $VehicleUsageType
    Description: $Description
  ) {
    IsDone
  }
}
    `;
export type UpdateCompanyVehicleMutationFn = Apollo.MutationFunction<UpdateCompanyVehicleMutation, UpdateCompanyVehicleMutationVariables>;

/**
 * __useUpdateCompanyVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyVehicleMutation, { data, loading, error }] = useUpdateCompanyVehicleMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *      CompanyId: // value for 'CompanyId'
 *      RegistrationNumber: // value for 'RegistrationNumber'
 *      VehicleUsageType: // value for 'VehicleUsageType'
 *      Description: // value for 'Description'
 *   },
 * });
 */
export function useUpdateCompanyVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyVehicleMutation, UpdateCompanyVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyVehicleMutation, UpdateCompanyVehicleMutationVariables>(UpdateCompanyVehicleDocument, options);
      }
export type UpdateCompanyVehicleMutationHookResult = ReturnType<typeof useUpdateCompanyVehicleMutation>;
export type UpdateCompanyVehicleMutationResult = Apollo.MutationResult<UpdateCompanyVehicleMutation>;
export type UpdateCompanyVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyVehicleMutation, UpdateCompanyVehicleMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($Name: String!, $Identifier: String, $IdentifierType: IdentifierType!, $TenantId: UUID!, $IsDemo: Boolean!) {
  CreateCompany(
    Input: {Name: $Name, Identifier: $Identifier, IdentifierType: $IdentifierType, TenantId: $TenantId, IsDemo: $IsDemo}
  ) {
    Id
    Name
    Identifier
    IdentifierType
    IsDemo
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      Name: // value for 'Name'
 *      Identifier: // value for 'Identifier'
 *      IdentifierType: // value for 'IdentifierType'
 *      TenantId: // value for 'TenantId'
 *      IsDemo: // value for 'IsDemo'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($Id: UUID!) {
  DeleteCompany(Input: {Id: $Id})
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($Id: UUID!, $Name: String!, $Identifier: String, $IdentifierType: IdentifierType!, $TenantId: UUID!) {
  UpdateCompany(
    Input: {Id: $Id, Name: $Name, Identifier: $Identifier, IdentifierType: $IdentifierType, TenantId: $TenantId}
  ) {
    Id
    Name
    Identifier
    IdentifierType
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      Id: // value for 'Id'
 *      Name: // value for 'Name'
 *      Identifier: // value for 'Identifier'
 *      IdentifierType: // value for 'IdentifierType'
 *      TenantId: // value for 'TenantId'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CurrentEnvironmentForAdminDocument = gql`
    query CurrentEnvironmentForAdmin {
  CurrentEnvironment {
    ...CurrentEnvironmentForAdmin
  }
  Tenants {
    items {
      ...TenantListItem
    }
  }
}
    ${CurrentEnvironmentForAdminFragmentDoc}
${TenantListItemFragmentDoc}`;

/**
 * __useCurrentEnvironmentForAdminQuery__
 *
 * To run a query within a React component, call `useCurrentEnvironmentForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentEnvironmentForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentEnvironmentForAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentEnvironmentForAdminQuery(baseOptions?: Apollo.QueryHookOptions<CurrentEnvironmentForAdminQuery, CurrentEnvironmentForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentEnvironmentForAdminQuery, CurrentEnvironmentForAdminQueryVariables>(CurrentEnvironmentForAdminDocument, options);
      }
export function useCurrentEnvironmentForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentEnvironmentForAdminQuery, CurrentEnvironmentForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentEnvironmentForAdminQuery, CurrentEnvironmentForAdminQueryVariables>(CurrentEnvironmentForAdminDocument, options);
        }
export type CurrentEnvironmentForAdminQueryHookResult = ReturnType<typeof useCurrentEnvironmentForAdminQuery>;
export type CurrentEnvironmentForAdminLazyQueryHookResult = ReturnType<typeof useCurrentEnvironmentForAdminLazyQuery>;
export type CurrentEnvironmentForAdminQueryResult = Apollo.QueryResult<CurrentEnvironmentForAdminQuery, CurrentEnvironmentForAdminQueryVariables>;
export const GetAttachmentLinkDocument = gql`
    query GetAttachmentLink($Id: UUID!) {
  GetAttachmentLink(Id: $Id) {
    Link
  }
}
    `;

/**
 * __useGetAttachmentLinkQuery__
 *
 * To run a query within a React component, call `useGetAttachmentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentLinkQuery({
 *   variables: {
 *      Id: // value for 'Id'
 *   },
 * });
 */
export function useGetAttachmentLinkQuery(baseOptions: Apollo.QueryHookOptions<GetAttachmentLinkQuery, GetAttachmentLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentLinkQuery, GetAttachmentLinkQueryVariables>(GetAttachmentLinkDocument, options);
      }
export function useGetAttachmentLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentLinkQuery, GetAttachmentLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentLinkQuery, GetAttachmentLinkQueryVariables>(GetAttachmentLinkDocument, options);
        }
export type GetAttachmentLinkQueryHookResult = ReturnType<typeof useGetAttachmentLinkQuery>;
export type GetAttachmentLinkLazyQueryHookResult = ReturnType<typeof useGetAttachmentLinkLazyQuery>;
export type GetAttachmentLinkQueryResult = Apollo.QueryResult<GetAttachmentLinkQuery, GetAttachmentLinkQueryVariables>;
export const GetAttachmentSettingsDocument = gql`
    query GetAttachmentSettings {
  GetAttachmentSettings {
    AllowedAttachmentMimeTypes
  }
}
    `;

/**
 * __useGetAttachmentSettingsQuery__
 *
 * To run a query within a React component, call `useGetAttachmentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAttachmentSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAttachmentSettingsQuery, GetAttachmentSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentSettingsQuery, GetAttachmentSettingsQueryVariables>(GetAttachmentSettingsDocument, options);
      }
export function useGetAttachmentSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentSettingsQuery, GetAttachmentSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentSettingsQuery, GetAttachmentSettingsQueryVariables>(GetAttachmentSettingsDocument, options);
        }
export type GetAttachmentSettingsQueryHookResult = ReturnType<typeof useGetAttachmentSettingsQuery>;
export type GetAttachmentSettingsLazyQueryHookResult = ReturnType<typeof useGetAttachmentSettingsLazyQuery>;
export type GetAttachmentSettingsQueryResult = Apollo.QueryResult<GetAttachmentSettingsQuery, GetAttachmentSettingsQueryVariables>;
export const GetCompaniesDocument = gql`
    query GetCompanies($FilterFields: CompanyFilterInput, $Take: Int, $Skip: Int, $Order: [CompanySortInput!]) {
  Companies(where: $FilterFields, take: $Take, skip: $Skip, order: $Order) {
    items {
      ...CompanyListItem
    }
    totalCount
  }
}
    ${CompanyListItemFragmentDoc}`;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      FilterFields: // value for 'FilterFields'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetCompaniesAndRolesDocument = gql`
    query GetCompaniesAndRoles($FilterFields: CompanyFilterInput, $Take: Int, $Skip: Int, $Order: [CompanySortInput!]) {
  Companies(where: $FilterFields, take: $Take, skip: $Skip, order: $Order) {
    items {
      ...CompanyListItem
    }
    totalCount
  }
  Roles {
    items {
      ...RoleShortListItem
    }
    totalCount
  }
}
    ${CompanyListItemFragmentDoc}
${RoleShortListItemFragmentDoc}`;

/**
 * __useGetCompaniesAndRolesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesAndRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesAndRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesAndRolesQuery({
 *   variables: {
 *      FilterFields: // value for 'FilterFields'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetCompaniesAndRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesAndRolesQuery, GetCompaniesAndRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesAndRolesQuery, GetCompaniesAndRolesQueryVariables>(GetCompaniesAndRolesDocument, options);
      }
export function useGetCompaniesAndRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesAndRolesQuery, GetCompaniesAndRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesAndRolesQuery, GetCompaniesAndRolesQueryVariables>(GetCompaniesAndRolesDocument, options);
        }
export type GetCompaniesAndRolesQueryHookResult = ReturnType<typeof useGetCompaniesAndRolesQuery>;
export type GetCompaniesAndRolesLazyQueryHookResult = ReturnType<typeof useGetCompaniesAndRolesLazyQuery>;
export type GetCompaniesAndRolesQueryResult = Apollo.QueryResult<GetCompaniesAndRolesQuery, GetCompaniesAndRolesQueryVariables>;
export const GetCompanyExistsAdminDocument = gql`
    query getCompanyExistsAdmin($TenantId: UUID!, $Identifier: String!, $IdentifierType: IdentifierType!) {
  CompanyExists(
    TenantId: $TenantId
    Identifier: $Identifier
    IdentifierType: $IdentifierType
  ) {
    Exists
  }
}
    `;

/**
 * __useGetCompanyExistsAdminQuery__
 *
 * To run a query within a React component, call `useGetCompanyExistsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyExistsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyExistsAdminQuery({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *      Identifier: // value for 'Identifier'
 *      IdentifierType: // value for 'IdentifierType'
 *   },
 * });
 */
export function useGetCompanyExistsAdminQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyExistsAdminQuery, GetCompanyExistsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyExistsAdminQuery, GetCompanyExistsAdminQueryVariables>(GetCompanyExistsAdminDocument, options);
      }
export function useGetCompanyExistsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyExistsAdminQuery, GetCompanyExistsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyExistsAdminQuery, GetCompanyExistsAdminQueryVariables>(GetCompanyExistsAdminDocument, options);
        }
export type GetCompanyExistsAdminQueryHookResult = ReturnType<typeof useGetCompanyExistsAdminQuery>;
export type GetCompanyExistsAdminLazyQueryHookResult = ReturnType<typeof useGetCompanyExistsAdminLazyQuery>;
export type GetCompanyExistsAdminQueryResult = Apollo.QueryResult<GetCompanyExistsAdminQuery, GetCompanyExistsAdminQueryVariables>;
export const GetCompanyStatisticsDocument = gql`
    query GetCompanyStatistics($TenantIdFilter: UUID, $Take: Long!, $Skip: Long!, $Order: ItemsOrderInput, $SearchText: String!) {
  CompanyStatistics(
    TenantIdFilter: $TenantIdFilter
    take: $Take
    skip: $Skip
    order: $Order
    SearchText: $SearchText
  ) {
    TenantAssignments {
      TenantId
      TenantName
      CompanyAssignments {
        ...CompanyAssigmentListItem
      }
    }
    TotalTenantsCount
    TotalCompanysCount
  }
}
    ${CompanyAssigmentListItemFragmentDoc}`;

/**
 * __useGetCompanyStatisticsQuery__
 *
 * To run a query within a React component, call `useGetCompanyStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyStatisticsQuery({
 *   variables: {
 *      TenantIdFilter: // value for 'TenantIdFilter'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *      SearchText: // value for 'SearchText'
 *   },
 * });
 */
export function useGetCompanyStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables>(GetCompanyStatisticsDocument, options);
      }
export function useGetCompanyStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables>(GetCompanyStatisticsDocument, options);
        }
export type GetCompanyStatisticsQueryHookResult = ReturnType<typeof useGetCompanyStatisticsQuery>;
export type GetCompanyStatisticsLazyQueryHookResult = ReturnType<typeof useGetCompanyStatisticsLazyQuery>;
export type GetCompanyStatisticsQueryResult = Apollo.QueryResult<GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables>;
export const GetCompaniesForOrganisationDocument = gql`
    query GetCompaniesForOrganisation($TenantIdFilter: UUID, $Take: Long!, $Skip: Long!, $Order: ItemsOrderInput, $SearchText: String!) {
  GetCompaniesForOrganisation(
    TenantIdFilter: $TenantIdFilter
    take: $Take
    skip: $Skip
    order: $Order
    SearchText: $SearchText
  ) {
    TenantAssignments {
      TenantId
      TenantName
      CompanyAssignments {
        ...CompanyInfoListItem
      }
    }
    TotalTenantsCount
    TotalCompanysCount
  }
}
    ${CompanyInfoListItemFragmentDoc}`;

/**
 * __useGetCompaniesForOrganisationQuery__
 *
 * To run a query within a React component, call `useGetCompaniesForOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesForOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesForOrganisationQuery({
 *   variables: {
 *      TenantIdFilter: // value for 'TenantIdFilter'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *      SearchText: // value for 'SearchText'
 *   },
 * });
 */
export function useGetCompaniesForOrganisationQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesForOrganisationQuery, GetCompaniesForOrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesForOrganisationQuery, GetCompaniesForOrganisationQueryVariables>(GetCompaniesForOrganisationDocument, options);
      }
export function useGetCompaniesForOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesForOrganisationQuery, GetCompaniesForOrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesForOrganisationQuery, GetCompaniesForOrganisationQueryVariables>(GetCompaniesForOrganisationDocument, options);
        }
export type GetCompaniesForOrganisationQueryHookResult = ReturnType<typeof useGetCompaniesForOrganisationQuery>;
export type GetCompaniesForOrganisationLazyQueryHookResult = ReturnType<typeof useGetCompaniesForOrganisationLazyQuery>;
export type GetCompaniesForOrganisationQueryResult = Apollo.QueryResult<GetCompaniesForOrganisationQuery, GetCompaniesForOrganisationQueryVariables>;
export const GetStatisticsForCompanyDocument = gql`
    query GetStatisticsForCompany($CompanyId: UUID!) {
  GetStatisticsForCompany(
    CompanyId: $CompanyId
  ) {
    PurchaseInvoicesCount
    SalesInvoicesCount
    HasKSeFAuthorization
  }
}
    `;

/**
 * __useGetStatisticsForCompanyQuery__
 *
 * To run a query within a React component, call `useGetStatisticsForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsForCompanyQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
  *   },
 * });
 */
export function useGetStatisticsForCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetStatisticsForCompanyQuery, GetStatisticsForCompanyQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetStatisticsForCompanyQuery, GetStatisticsForCompanyQueryVariables>(GetStatisticsForCompanyDocument, options);
}
export function useGetStatisticsForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticsForCompanyQuery, GetStatisticsForCompanyQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetStatisticsForCompanyQuery, GetStatisticsForCompanyQueryVariables>(GetStatisticsForCompanyDocument, options);
  }
export type GetStatisticsForCompanyQueryHookResult = ReturnType<typeof useGetStatisticsForCompanyQuery>;
export type GetStatisticsForCompanyLazyQueryHookResult = ReturnType<typeof useGetStatisticsForCompanyLazyQuery>;
export type GetStatisticsForCompanyQueryResult = Apollo.QueryResult<GetStatisticsForCompanyQuery, GetStatisticsForCompanyQueryVariables>;
export const GetContractorsDocument = gql`
    query GetContractors($ContractorFiltersFields: ContractorFiltersFieldsInput!, $SearchText: String, $Take: Long!, $Skip: Long!, $Order: ItemsOrderInput) {
  GetContractors(
    ContractorFiltersFields: $ContractorFiltersFields
    SearchText: $SearchText
    take: $Take
    skip: $Skip
    order: $Order
  ) {
    Items {
      ...ContractorsItem
    }
    TotalCount
    FilteredCount
  }
}
    ${ContractorsItemFragmentDoc}`;

/**
 * __useGetContractorsQuery__
 *
 * To run a query within a React component, call `useGetContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractorsQuery({
 *   variables: {
 *      ContractorFiltersFields: // value for 'ContractorFiltersFields'
 *      SearchText: // value for 'SearchText'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetContractorsQuery(baseOptions: Apollo.QueryHookOptions<GetContractorsQuery, GetContractorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractorsQuery, GetContractorsQueryVariables>(GetContractorsDocument, options);
      }
export function useGetContractorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractorsQuery, GetContractorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractorsQuery, GetContractorsQueryVariables>(GetContractorsDocument, options);
        }
export type GetContractorsQueryHookResult = ReturnType<typeof useGetContractorsQuery>;
export type GetContractorsLazyQueryHookResult = ReturnType<typeof useGetContractorsLazyQuery>;
export type GetContractorsQueryResult = Apollo.QueryResult<GetContractorsQuery, GetContractorsQueryVariables>;
export const GetCurrentSchemaVersionDocument = gql`
    query GetCurrentSchemaVersion {
  GetCurrentSchemaVersion {
    SchemaVersion
  }
}
    `;

/**
 * __useGetCurrentSchemaVersionQuery__
 *
 * To run a query within a React component, call `useGetCurrentSchemaVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSchemaVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSchemaVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSchemaVersionQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentSchemaVersionQuery, GetCurrentSchemaVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentSchemaVersionQuery, GetCurrentSchemaVersionQueryVariables>(GetCurrentSchemaVersionDocument, options);
      }
export function useGetCurrentSchemaVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSchemaVersionQuery, GetCurrentSchemaVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentSchemaVersionQuery, GetCurrentSchemaVersionQueryVariables>(GetCurrentSchemaVersionDocument, options);
        }
export type GetCurrentSchemaVersionQueryHookResult = ReturnType<typeof useGetCurrentSchemaVersionQuery>;
export type GetCurrentSchemaVersionLazyQueryHookResult = ReturnType<typeof useGetCurrentSchemaVersionLazyQuery>;
export type GetCurrentSchemaVersionQueryResult = Apollo.QueryResult<GetCurrentSchemaVersionQuery, GetCurrentSchemaVersionQueryVariables>;
export const GetEArchiveNotificationsDocument = gql`
    query GetEArchiveNotifications {
  GetEArchiveNotifications {
    Notifications
  }
}
    `;

/**
 * __useGetEArchiveNotificationsQuery__
 *
 * To run a query within a React component, call `useGetEArchiveNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEArchiveNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEArchiveNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEArchiveNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetEArchiveNotificationsQuery, GetEArchiveNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEArchiveNotificationsQuery, GetEArchiveNotificationsQueryVariables>(GetEArchiveNotificationsDocument, options);
      }
export function useGetEArchiveNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEArchiveNotificationsQuery, GetEArchiveNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEArchiveNotificationsQuery, GetEArchiveNotificationsQueryVariables>(GetEArchiveNotificationsDocument, options);
        }
export type GetEArchiveNotificationsQueryHookResult = ReturnType<typeof useGetEArchiveNotificationsQuery>;
export type GetEArchiveNotificationsLazyQueryHookResult = ReturnType<typeof useGetEArchiveNotificationsLazyQuery>;
export type GetEArchiveNotificationsQueryResult = Apollo.QueryResult<GetEArchiveNotificationsQuery, GetEArchiveNotificationsQueryVariables>;
export const GetInvoiceFileContentActionGroupDocument = gql`
    query GetInvoiceFileContentActionGroup($InvoiceIds: [UUID!]!, $Token: String) {
  GetInvoiceFileContentActionGroup(InvoiceIds: $InvoiceIds, Token: $Token) {
    ZipName
    ZipContent
    MimeType
  }
}
    `;

/**
 * __useGetInvoiceFileContentActionGroupQuery__
 *
 * To run a query within a React component, call `useGetInvoiceFileContentActionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceFileContentActionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceFileContentActionGroupQuery({
 *   variables: {
 *      InvoiceIds: // value for 'InvoiceIds'
 *      Token: // value for 'Token'
 *   },
 * });
 */
export function useGetInvoiceFileContentActionGroupQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceFileContentActionGroupQuery, GetInvoiceFileContentActionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceFileContentActionGroupQuery, GetInvoiceFileContentActionGroupQueryVariables>(GetInvoiceFileContentActionGroupDocument, options);
      }
export function useGetInvoiceFileContentActionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceFileContentActionGroupQuery, GetInvoiceFileContentActionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceFileContentActionGroupQuery, GetInvoiceFileContentActionGroupQueryVariables>(GetInvoiceFileContentActionGroupDocument, options);
        }
export type GetInvoiceFileContentActionGroupQueryHookResult = ReturnType<typeof useGetInvoiceFileContentActionGroupQuery>;
export type GetInvoiceFileContentActionGroupLazyQueryHookResult = ReturnType<typeof useGetInvoiceFileContentActionGroupLazyQuery>;
export type GetInvoiceFileContentActionGroupQueryResult = Apollo.QueryResult<GetInvoiceFileContentActionGroupQuery, GetInvoiceFileContentActionGroupQueryVariables>;
export const GetInvoiceHtmlContentSingleDocument = gql`
    query GetInvoiceHTMLContentSingle($InvoiceId: UUID!, $Token: String) {
  GetInvoiceFileContentSingle(InvoiceId: $InvoiceId, Token: $Token) {
    HtmlContent
    PdfContent
  }
}
    `;

/**
 * __useGetInvoiceHtmlContentSingleQuery__
 *
 * To run a query within a React component, call `useGetInvoiceHtmlContentSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceHtmlContentSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceHtmlContentSingleQuery({
 *   variables: {
 *      InvoiceId: // value for 'InvoiceId'
 *      Token: // value for 'Token'
 *   },
 * });
 */
export function useGetInvoiceHtmlContentSingleQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceHtmlContentSingleQuery, GetInvoiceHtmlContentSingleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceHtmlContentSingleQuery, GetInvoiceHtmlContentSingleQueryVariables>(GetInvoiceHtmlContentSingleDocument, options);
      }
export function useGetInvoiceHtmlContentSingleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceHtmlContentSingleQuery, GetInvoiceHtmlContentSingleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceHtmlContentSingleQuery, GetInvoiceHtmlContentSingleQueryVariables>(GetInvoiceHtmlContentSingleDocument, options);
        }
export type GetInvoiceHtmlContentSingleQueryHookResult = ReturnType<typeof useGetInvoiceHtmlContentSingleQuery>;
export type GetInvoiceHtmlContentSingleLazyQueryHookResult = ReturnType<typeof useGetInvoiceHtmlContentSingleLazyQuery>;
export type GetInvoiceHtmlContentSingleQueryResult = Apollo.QueryResult<GetInvoiceHtmlContentSingleQuery, GetInvoiceHtmlContentSingleQueryVariables>;
export const GetInvoiceFileContentSingleDocument = gql`
    query GetInvoiceFileContentSingle($InvoiceId: UUID!, $Token: String) {
  GetInvoiceFileContentSingle(InvoiceId: $InvoiceId, Token: $Token) {
    PdfContent
    PdfName
    MimeType
  }
}
    `;

/**
 * __useGetInvoiceFileContentSingleQuery__
 *
 * To run a query within a React component, call `useGetInvoiceFileContentSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceFileContentSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceFileContentSingleQuery({
 *   variables: {
 *      InvoiceId: // value for 'InvoiceId'
 *      Token: // value for 'Token'
 *   },
 * });
 */
export function useGetInvoiceFileContentSingleQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceFileContentSingleQuery, GetInvoiceFileContentSingleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceFileContentSingleQuery, GetInvoiceFileContentSingleQueryVariables>(GetInvoiceFileContentSingleDocument, options);
      }
export function useGetInvoiceFileContentSingleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceFileContentSingleQuery, GetInvoiceFileContentSingleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceFileContentSingleQuery, GetInvoiceFileContentSingleQueryVariables>(GetInvoiceFileContentSingleDocument, options);
        }
export type GetInvoiceFileContentSingleQueryHookResult = ReturnType<typeof useGetInvoiceFileContentSingleQuery>;
export type GetInvoiceFileContentSingleLazyQueryHookResult = ReturnType<typeof useGetInvoiceFileContentSingleLazyQuery>;
export type GetInvoiceFileContentSingleQueryResult = Apollo.QueryResult<GetInvoiceFileContentSingleQuery, GetInvoiceFileContentSingleQueryVariables>;
export const GetInvoicesDocument = gql`
    query GetInvoices($FiltersFields: FiltersFieldsInput!, $SearchText: String, $Take: Long!, $Skip: Long!, $Order: ItemsOrderInput) {
  GetInvoices(
    FiltersFields: $FiltersFields
    SearchText: $SearchText
    take: $Take
    skip: $Skip
    order: $Order
  ) {
    Items {
      ...InvoiceItem
    }
    TotalCount
    FilteredCount
  }
}
    ${InvoiceItemFragmentDoc}`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      FiltersFields: // value for 'FiltersFields'
 *      SearchText: // value for 'SearchText'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetInvoicesTotalCountDocument = gql`
    query GetInvoicesTotalCount {
  PurchaseCount: GetInvoices(
    FiltersFields: {Foreign: true, Own: false, ToSend: false, Sent: false, ToDownload: true, Downloaded: true, ReadyForPosting: false, ToAccept: false}
    SearchText: ""
    take: 1
    skip: 0
  ) {
    TotalCount
  }
  SellCount: GetInvoices(
    FiltersFields: {Foreign: false, Own: true, ToSend: true, Sent: true, ToDownload: false, Downloaded: false, ReadyForPosting: false, ToAccept: false}
    SearchText: ""
    take: 1
    skip: 0
  ) {
    TotalCount
  }
}
    `;

/**
 * __useGetInvoicesTotalCountQuery__
 *
 * To run a query within a React component, call `useGetInvoicesTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesTotalCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoicesTotalCountQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicesTotalCountQuery, GetInvoicesTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesTotalCountQuery, GetInvoicesTotalCountQueryVariables>(GetInvoicesTotalCountDocument, options);
      }
export function useGetInvoicesTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesTotalCountQuery, GetInvoicesTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesTotalCountQuery, GetInvoicesTotalCountQueryVariables>(GetInvoicesTotalCountDocument, options);
        }
export type GetInvoicesTotalCountQueryHookResult = ReturnType<typeof useGetInvoicesTotalCountQuery>;
export type GetInvoicesTotalCountLazyQueryHookResult = ReturnType<typeof useGetInvoicesTotalCountLazyQuery>;
export type GetInvoicesTotalCountQueryResult = Apollo.QueryResult<GetInvoicesTotalCountQuery, GetInvoicesTotalCountQueryVariables>;
export const GetInvoicesReportDocument = gql`
    query GetInvoicesReport($ReportFileType: ReportFileTypeEnum!, $FiltersFields: FiltersFieldsInput!, $SearchText: String, $take: Long!, $skip: Long!, $order: ItemsOrderInput) {
  GetInvoicesReport(
    ReportFileType: $ReportFileType
    FiltersFields: $FiltersFields
    SearchText: $SearchText
    take: $take
    skip: $skip
    order: $order
  ) {
    FileName
    FileContent
    MimeType
  }
}
    `;

/**
 * __useGetInvoicesReportQuery__
 *
 * To run a query within a React component, call `useGetInvoicesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesReportQuery({
 *   variables: {
 *      ReportFileType: // value for 'ReportFileType'
 *      FiltersFields: // value for 'FiltersFields'
 *      SearchText: // value for 'SearchText'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetInvoicesReportQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesReportQuery, GetInvoicesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesReportQuery, GetInvoicesReportQueryVariables>(GetInvoicesReportDocument, options);
      }
export function useGetInvoicesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesReportQuery, GetInvoicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesReportQuery, GetInvoicesReportQueryVariables>(GetInvoicesReportDocument, options);
        }
export type GetInvoicesReportQueryHookResult = ReturnType<typeof useGetInvoicesReportQuery>;
export type GetInvoicesReportLazyQueryHookResult = ReturnType<typeof useGetInvoicesReportLazyQuery>;
export type GetInvoicesReportQueryResult = Apollo.QueryResult<GetInvoicesReportQuery, GetInvoicesReportQueryVariables>;
export const GetIssuerInvoicesDocument = gql`
    query GetIssuerInvoices($FiltersFields: FiltersFieldsIssuerInput!, $SearchText: String, $order: ItemsOrderInput, $skip: Long!, $take: Long!) {
  GetIssuerInvoices(
    FiltersFields: $FiltersFields
    SearchText: $SearchText
    order: $order
    skip: $skip
    take: $take
  ) {
    Items {
      ...IssuerInvoice
    }
    TotalCount
  }
}
    ${IssuerInvoiceFragmentDoc}`;

/**
 * __useGetIssuerInvoicesQuery__
 *
 * To run a query within a React component, call `useGetIssuerInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuerInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuerInvoicesQuery({
 *   variables: {
 *      FiltersFields: // value for 'FiltersFields'
 *      SearchText: // value for 'SearchText'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetIssuerInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetIssuerInvoicesQuery, GetIssuerInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssuerInvoicesQuery, GetIssuerInvoicesQueryVariables>(GetIssuerInvoicesDocument, options);
      }
export function useGetIssuerInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuerInvoicesQuery, GetIssuerInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssuerInvoicesQuery, GetIssuerInvoicesQueryVariables>(GetIssuerInvoicesDocument, options);
        }
export type GetIssuerInvoicesQueryHookResult = ReturnType<typeof useGetIssuerInvoicesQuery>;
export type GetIssuerInvoicesLazyQueryHookResult = ReturnType<typeof useGetIssuerInvoicesLazyQuery>;
export type GetIssuerInvoicesQueryResult = Apollo.QueryResult<GetIssuerInvoicesQuery, GetIssuerInvoicesQueryVariables>;
export const GetKSeFInvoiceXmlDocument = gql`
    query GetKSeFInvoiceXML($InvoiceId: UUID!) {
  GetKSeFInvoiceXML(InvoiceId: $InvoiceId) {
    Content
    FileName
  }
}
    `;

/**
 * __useGetKSeFInvoiceXmlQuery__
 *
 * To run a query within a React component, call `useGetKSeFInvoiceXmlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKSeFInvoiceXmlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKSeFInvoiceXmlQuery({
 *   variables: {
 *      InvoiceId: // value for 'InvoiceId'
 *   },
 * });
 */
export function useGetKSeFInvoiceXmlQuery(baseOptions: Apollo.QueryHookOptions<GetKSeFInvoiceXmlQuery, GetKSeFInvoiceXmlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKSeFInvoiceXmlQuery, GetKSeFInvoiceXmlQueryVariables>(GetKSeFInvoiceXmlDocument, options);
      }
export function useGetKSeFInvoiceXmlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKSeFInvoiceXmlQuery, GetKSeFInvoiceXmlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKSeFInvoiceXmlQuery, GetKSeFInvoiceXmlQueryVariables>(GetKSeFInvoiceXmlDocument, options);
        }
export type GetKSeFInvoiceXmlQueryHookResult = ReturnType<typeof useGetKSeFInvoiceXmlQuery>;
export type GetKSeFInvoiceXmlLazyQueryHookResult = ReturnType<typeof useGetKSeFInvoiceXmlLazyQuery>;
export type GetKSeFInvoiceXmlQueryResult = Apollo.QueryResult<GetKSeFInvoiceXmlQuery, GetKSeFInvoiceXmlQueryVariables>;
export const GetMissedWebsocketSubscriptionsDocument = gql`
    query GetMissedWebsocketSubscriptions {
  GetMissedWebsocketSubscriptions {
    Success
  }
}
    `;

/**
 * __useGetMissedWebsocketSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetMissedWebsocketSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissedWebsocketSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissedWebsocketSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMissedWebsocketSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetMissedWebsocketSubscriptionsQuery, GetMissedWebsocketSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissedWebsocketSubscriptionsQuery, GetMissedWebsocketSubscriptionsQueryVariables>(GetMissedWebsocketSubscriptionsDocument, options);
      }
export function useGetMissedWebsocketSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissedWebsocketSubscriptionsQuery, GetMissedWebsocketSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissedWebsocketSubscriptionsQuery, GetMissedWebsocketSubscriptionsQueryVariables>(GetMissedWebsocketSubscriptionsDocument, options);
        }
export type GetMissedWebsocketSubscriptionsQueryHookResult = ReturnType<typeof useGetMissedWebsocketSubscriptionsQuery>;
export type GetMissedWebsocketSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetMissedWebsocketSubscriptionsLazyQuery>;
export type GetMissedWebsocketSubscriptionsQueryResult = Apollo.QueryResult<GetMissedWebsocketSubscriptionsQuery, GetMissedWebsocketSubscriptionsQueryVariables>;
export const MyEnviromentsDocument = gql`
    query MyEnviroments {
  MyEnvironments {
    Environments {
      ...EnvironmentListItem
    }
  }
}
    ${EnvironmentListItemFragmentDoc}`;

/**
 * __useMyEnviromentsQuery__
 *
 * To run a query within a React component, call `useMyEnviromentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEnviromentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEnviromentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyEnviromentsQuery(baseOptions?: Apollo.QueryHookOptions<MyEnviromentsQuery, MyEnviromentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyEnviromentsQuery, MyEnviromentsQueryVariables>(MyEnviromentsDocument, options);
      }
export function useMyEnviromentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyEnviromentsQuery, MyEnviromentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyEnviromentsQuery, MyEnviromentsQueryVariables>(MyEnviromentsDocument, options);
        }
export type MyEnviromentsQueryHookResult = ReturnType<typeof useMyEnviromentsQuery>;
export type MyEnviromentsLazyQueryHookResult = ReturnType<typeof useMyEnviromentsLazyQuery>;
export type MyEnviromentsQueryResult = Apollo.QueryResult<MyEnviromentsQuery, MyEnviromentsQueryVariables>;
export const GetPackageStatisticDocument = gql`
    query GetPackageStatistic($TenantId: UUID!) {
  GetPackageStatistic(TenantId: $TenantId) {
    Status
    CurrentInvoicesCount
    CurrentStorageCount
    StorageUsedPercentage
    InvoicesUsedPercentage
    SerialNumber
    SubscriptionNumber
    CustomerNumber
    Email
    DateTo
    Items {
      ...PackageStatisticsItem
    }
  }
}
    ${PackageStatisticsItemFragmentDoc}`;

/**
 * __useGetPackageStatisticQuery__
 *
 * To run a query within a React component, call `useGetPackageStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageStatisticQuery({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *   },
 * });
 */
export function useGetPackageStatisticQuery(baseOptions: Apollo.QueryHookOptions<GetPackageStatisticQuery, GetPackageStatisticQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackageStatisticQuery, GetPackageStatisticQueryVariables>(GetPackageStatisticDocument, options);
      }
export function useGetPackageStatisticLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackageStatisticQuery, GetPackageStatisticQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackageStatisticQuery, GetPackageStatisticQueryVariables>(GetPackageStatisticDocument, options);
        }
export type GetPackageStatisticQueryHookResult = ReturnType<typeof useGetPackageStatisticQuery>;
export type GetPackageStatisticLazyQueryHookResult = ReturnType<typeof useGetPackageStatisticLazyQuery>;
export type GetPackageStatisticQueryResult = Apollo.QueryResult<GetPackageStatisticQuery, GetPackageStatisticQueryVariables>;
export const GetPermissionForCompanyDocument = gql`
    query GetPermissionForCompany {
  CurrentEnvironment {
    UserProperties {
      KSeF {
        Authorized
        Credentials
        AuthorizedBy
        AuthorizedAt
      }
      EArchive {
        SharedAccess {
          Status
          LastModifiedBy
          LastModifiedDate
          UserId
        }
      }
    }
  }
}
    `;

/**
 * __useGetPermissionForCompanyQuery__
 *
 * To run a query within a React component, call `useGetPermissionForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionForCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionForCompanyQuery, GetPermissionForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionForCompanyQuery, GetPermissionForCompanyQueryVariables>(GetPermissionForCompanyDocument, options);
      }
export function useGetPermissionForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionForCompanyQuery, GetPermissionForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionForCompanyQuery, GetPermissionForCompanyQueryVariables>(GetPermissionForCompanyDocument, options);
        }
export type GetPermissionForCompanyQueryHookResult = ReturnType<typeof useGetPermissionForCompanyQuery>;
export type GetPermissionForCompanyLazyQueryHookResult = ReturnType<typeof useGetPermissionForCompanyLazyQuery>;
export type GetPermissionForCompanyQueryResult = Apollo.QueryResult<GetPermissionForCompanyQuery, GetPermissionForCompanyQueryVariables>;
export const GetPermissionsKSeFDocument = gql`
    query GetPermissionsKSeF($CredentialFiltersFields: CredentialFiltersFieldsInput!, $SearchText: String, $order: ItemsOrderInput, $take: Long, $skip: Long, $fromKsef: Boolean) {
  GetPermissionsKSeF(
    CredentialFiltersFields: $CredentialFiltersFields
    SearchText: $SearchText
    take: $take
    skip: $skip
    order: $order
    fromKSeF: $fromKsef
  ) {
    FromKsef
    LastUpdated
    Credentials {
      ...Credentials
    }
    TotalCount
    FilteredCount
  }
}
    ${CredentialsFragmentDoc}`;

/**
 * __useGetPermissionsKSeFQuery__
 *
 * To run a query within a React component, call `useGetPermissionsKSeFQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsKSeFQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsKSeFQuery({
 *   variables: {
 *      CredentialFiltersFields: // value for 'CredentialFiltersFields'
 *      SearchText: // value for 'SearchText'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      fromKsef: // value for 'fromKsef'
 *   },
 * });
 */
export function useGetPermissionsKSeFQuery(baseOptions: Apollo.QueryHookOptions<GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables>(GetPermissionsKSeFDocument, options);
      }
export function useGetPermissionsKSeFLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables>(GetPermissionsKSeFDocument, options);
        }
export type GetPermissionsKSeFQueryHookResult = ReturnType<typeof useGetPermissionsKSeFQuery>;
export type GetPermissionsKSeFLazyQueryHookResult = ReturnType<typeof useGetPermissionsKSeFLazyQuery>;
export type GetPermissionsKSeFQueryResult = Apollo.QueryResult<GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables>;
export const GetPermissionsKSeFReportQueryDocument = gql`
    query GetPermissionsKSeFReportQuery($ReportFileType: ReportFileTypeEnum!, $CredentialFiltersFields: CredentialFiltersFieldsInput, $SearchText: String, $order: ItemsOrderInput, $take: Long, $skip: Long) {
  GetPermissionsKSeFReport(
    ReportFileType: $ReportFileType
    CredentialFiltersFields: $CredentialFiltersFields
    SearchText: $SearchText
    order: $order
    take: $take
    skip: $skip
  ) {
    FileName
    FileContent
    MimeType
  }
}
    `;

/**
 * __useGetPermissionsKSeFReportQueryQuery__
 *
 * To run a query within a React component, call `useGetPermissionsKSeFReportQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsKSeFReportQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsKSeFReportQueryQuery({
 *   variables: {
 *      ReportFileType: // value for 'ReportFileType'
 *      CredentialFiltersFields: // value for 'CredentialFiltersFields'
 *      SearchText: // value for 'SearchText'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPermissionsKSeFReportQueryQuery(baseOptions: Apollo.QueryHookOptions<GetPermissionsKSeFReportQueryQuery, GetPermissionsKSeFReportQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsKSeFReportQueryQuery, GetPermissionsKSeFReportQueryQueryVariables>(GetPermissionsKSeFReportQueryDocument, options);
      }
export function useGetPermissionsKSeFReportQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsKSeFReportQueryQuery, GetPermissionsKSeFReportQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsKSeFReportQueryQuery, GetPermissionsKSeFReportQueryQueryVariables>(GetPermissionsKSeFReportQueryDocument, options);
        }
export type GetPermissionsKSeFReportQueryQueryHookResult = ReturnType<typeof useGetPermissionsKSeFReportQueryQuery>;
export type GetPermissionsKSeFReportQueryLazyQueryHookResult = ReturnType<typeof useGetPermissionsKSeFReportQueryLazyQuery>;
export type GetPermissionsKSeFReportQueryQueryResult = Apollo.QueryResult<GetPermissionsKSeFReportQueryQuery, GetPermissionsKSeFReportQueryQueryVariables>;
export const GetPlatformNotificationsDocument = gql`
    query GetPlatformNotifications {
  GetPlatformNotifications {
    Notifications
  }
}
    `;

/**
 * __useGetPlatformNotificationsQuery__
 *
 * To run a query within a React component, call `useGetPlatformNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatformNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetPlatformNotificationsQuery, GetPlatformNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlatformNotificationsQuery, GetPlatformNotificationsQueryVariables>(GetPlatformNotificationsDocument, options);
      }
export function useGetPlatformNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlatformNotificationsQuery, GetPlatformNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlatformNotificationsQuery, GetPlatformNotificationsQueryVariables>(GetPlatformNotificationsDocument, options);
        }
export type GetPlatformNotificationsQueryHookResult = ReturnType<typeof useGetPlatformNotificationsQuery>;
export type GetPlatformNotificationsLazyQueryHookResult = ReturnType<typeof useGetPlatformNotificationsLazyQuery>;
export type GetPlatformNotificationsQueryResult = Apollo.QueryResult<GetPlatformNotificationsQuery, GetPlatformNotificationsQueryVariables>;
export const GetRecipientInvoicesDocument = gql`
    query GetRecipientInvoices($FiltersFields: FiltersFieldsRecipientsInput!, $SearchText: String, $order: ItemsOrderInput, $skip: Long!, $take: Long!) {
  GetRecipientInvoices(
    FiltersFields: $FiltersFields
    SearchText: $SearchText
    order: $order
    skip: $skip
    take: $take
  ) {
    Items {
      ...RecipientInvoice
    }
    TotalCount
  }
}
    ${RecipientInvoiceFragmentDoc}`;

/**
 * __useGetRecipientInvoicesQuery__
 *
 * To run a query within a React component, call `useGetRecipientInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipientInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipientInvoicesQuery({
 *   variables: {
 *      FiltersFields: // value for 'FiltersFields'
 *      SearchText: // value for 'SearchText'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetRecipientInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetRecipientInvoicesQuery, GetRecipientInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecipientInvoicesQuery, GetRecipientInvoicesQueryVariables>(GetRecipientInvoicesDocument, options);
      }
export function useGetRecipientInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecipientInvoicesQuery, GetRecipientInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecipientInvoicesQuery, GetRecipientInvoicesQueryVariables>(GetRecipientInvoicesDocument, options);
        }
export type GetRecipientInvoicesQueryHookResult = ReturnType<typeof useGetRecipientInvoicesQuery>;
export type GetRecipientInvoicesLazyQueryHookResult = ReturnType<typeof useGetRecipientInvoicesLazyQuery>;
export type GetRecipientInvoicesQueryResult = Apollo.QueryResult<GetRecipientInvoicesQuery, GetRecipientInvoicesQueryVariables>;
export const GetRolesDocument = gql`
    query GetRoles {
  Roles {
    items {
      ...RoleShortListItem
    }
    totalCount
  }
}
    ${RoleShortListItemFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetTenantsDocument = gql`
    query GetTenants($FilterFields: TenantFilterInput, $Take: Int, $Skip: Int, $Order: [TenantSortInput!]) {
  Tenants(where: $FilterFields, take: $Take, skip: $Skip, order: $Order) {
    items {
      ...TenantListItem
    }
    totalCount
  }
}
    ${TenantListItemFragmentDoc}`;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *      FilterFields: // value for 'FilterFields'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
      }
export function useGetTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;
export const GetUpoDocument = gql`
    query GetUpo($InvoiceIds: [UUID!]!, $UpoFileType: UpoFileType!) {
  GetUPO(InvoiceIds: $InvoiceIds, UpoFileType: $UpoFileType) {
    FileName
    FileContent
    MimeType
    MissingInvoices {
      InvoiceNumber
      Reason
    }
    ReturnedInvoices
    RequestedInvoices
  }
}
    `;

/**
 * __useGetUpoQuery__
 *
 * To run a query within a React component, call `useGetUpoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpoQuery({
 *   variables: {
 *      InvoiceIds: // value for 'InvoiceIds'
 *      UpoFileType: // value for 'UpoFileType'
 *   },
 * });
 */
export function useGetUpoQuery(baseOptions: Apollo.QueryHookOptions<GetUpoQuery, GetUpoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpoQuery, GetUpoQueryVariables>(GetUpoDocument, options);
      }
export function useGetUpoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpoQuery, GetUpoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpoQuery, GetUpoQueryVariables>(GetUpoDocument, options);
        }
export type GetUpoQueryHookResult = ReturnType<typeof useGetUpoQuery>;
export type GetUpoLazyQueryHookResult = ReturnType<typeof useGetUpoLazyQuery>;
export type GetUpoQueryResult = Apollo.QueryResult<GetUpoQuery, GetUpoQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($FilterFields: UserFilterInput, $Take: Int, $Skip: Int, $Order: [UserSortInput!]) {
  Users(where: $FilterFields, take: $Take, skip: $Skip, order: $Order) {
    items {
      ...UserWithDetailsItem
    }
    totalCount
  }
}
    ${UserWithDetailsItemFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      FilterFields: // value for 'FilterFields'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUsersWithDetailsDocument = gql`
    query GetUsersWithDetails($FilterFields: UserFilterInput, $CompaniesFilterFields: CompanyFilterInput, $Take: Int, $Skip: Int, $Order: [UserSortInput!], $CompaniesOrder: [CompanySortInput!]) {
  Users(where: $FilterFields, take: $Take, skip: $Skip, order: $Order) {
    items {
      ...UserWithDetailsItem
    }
    totalCount
  }
  Companies(
    where: $CompaniesFilterFields
    take: 99
    skip: 0
    order: $CompaniesOrder
  ) {
    items {
      ...CompanyListItem
    }
    totalCount
  }
  Roles {
    items {
      ...RoleShortListItem
    }
    totalCount
  }
}
    ${UserWithDetailsItemFragmentDoc}
${CompanyListItemFragmentDoc}
${RoleShortListItemFragmentDoc}`;

/**
 * __useGetUsersWithDetailsQuery__
 *
 * To run a query within a React component, call `useGetUsersWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWithDetailsQuery({
 *   variables: {
 *      FilterFields: // value for 'FilterFields'
 *      CompaniesFilterFields: // value for 'CompaniesFilterFields'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *      CompaniesOrder: // value for 'CompaniesOrder'
 *   },
 * });
 */
export function useGetUsersWithDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersWithDetailsQuery, GetUsersWithDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersWithDetailsQuery, GetUsersWithDetailsQueryVariables>(GetUsersWithDetailsDocument, options);
      }
export function useGetUsersWithDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersWithDetailsQuery, GetUsersWithDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersWithDetailsQuery, GetUsersWithDetailsQueryVariables>(GetUsersWithDetailsDocument, options);
        }
export type GetUsersWithDetailsQueryHookResult = ReturnType<typeof useGetUsersWithDetailsQuery>;
export type GetUsersWithDetailsLazyQueryHookResult = ReturnType<typeof useGetUsersWithDetailsLazyQuery>;
export type GetUsersWithDetailsQueryResult = Apollo.QueryResult<GetUsersWithDetailsQuery, GetUsersWithDetailsQueryVariables>;
export const GetWebsocketNotificationDocument = gql`
    query GetWebsocketNotification($notificationStateType: WebsocketNotificationStateType!) {
  GetWebsocketNotificationsList(notificationStateType: $notificationStateType) {
    WebsocketNotification {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;

/**
 * __useGetWebsocketNotificationQuery__
 *
 * To run a query within a React component, call `useGetWebsocketNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebsocketNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebsocketNotificationQuery({
 *   variables: {
 *      notificationStateType: // value for 'notificationStateType'
 *   },
 * });
 */
export function useGetWebsocketNotificationQuery(baseOptions: Apollo.QueryHookOptions<GetWebsocketNotificationQuery, GetWebsocketNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebsocketNotificationQuery, GetWebsocketNotificationQueryVariables>(GetWebsocketNotificationDocument, options);
      }
export function useGetWebsocketNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebsocketNotificationQuery, GetWebsocketNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebsocketNotificationQuery, GetWebsocketNotificationQueryVariables>(GetWebsocketNotificationDocument, options);
        }
export type GetWebsocketNotificationQueryHookResult = ReturnType<typeof useGetWebsocketNotificationQuery>;
export type GetWebsocketNotificationLazyQueryHookResult = ReturnType<typeof useGetWebsocketNotificationLazyQuery>;
export type GetWebsocketNotificationQueryResult = Apollo.QueryResult<GetWebsocketNotificationQuery, GetWebsocketNotificationQueryVariables>;
export const GetWebsocketNotificationModelDocument = gql`
    query GetWebsocketNotificationModel($NotificationId: UUID!) {
  GetWebsocketNotificationModel(notificationId: $NotificationId) {
    ...WebSocketNotificationResponse
  }
}
    ${WebSocketNotificationResponseFragmentDoc}`;

/**
 * __useGetWebsocketNotificationModelQuery__
 *
 * To run a query within a React component, call `useGetWebsocketNotificationModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebsocketNotificationModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebsocketNotificationModelQuery({
 *   variables: {
 *      NotificationId: // value for 'NotificationId'
 *   },
 * });
 */
export function useGetWebsocketNotificationModelQuery(baseOptions: Apollo.QueryHookOptions<GetWebsocketNotificationModelQuery, GetWebsocketNotificationModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebsocketNotificationModelQuery, GetWebsocketNotificationModelQueryVariables>(GetWebsocketNotificationModelDocument, options);
      }
export function useGetWebsocketNotificationModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebsocketNotificationModelQuery, GetWebsocketNotificationModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebsocketNotificationModelQuery, GetWebsocketNotificationModelQueryVariables>(GetWebsocketNotificationModelDocument, options);
        }
export type GetWebsocketNotificationModelQueryHookResult = ReturnType<typeof useGetWebsocketNotificationModelQuery>;
export type GetWebsocketNotificationModelLazyQueryHookResult = ReturnType<typeof useGetWebsocketNotificationModelLazyQuery>;
export type GetWebsocketNotificationModelQueryResult = Apollo.QueryResult<GetWebsocketNotificationModelQuery, GetWebsocketNotificationModelQueryVariables>;
export const CheckIsDownloadInvoicesRunningDocument = gql`
    query CheckIsDownloadInvoicesRunning {
  IsDownloadInvoicesRunning {
    IsRunning
  }
}
    `;

/**
 * __useCheckIsDownloadInvoicesRunningQuery__
 *
 * To run a query within a React component, call `useCheckIsDownloadInvoicesRunningQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsDownloadInvoicesRunningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsDownloadInvoicesRunningQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckIsDownloadInvoicesRunningQuery(baseOptions?: Apollo.QueryHookOptions<CheckIsDownloadInvoicesRunningQuery, CheckIsDownloadInvoicesRunningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckIsDownloadInvoicesRunningQuery, CheckIsDownloadInvoicesRunningQueryVariables>(CheckIsDownloadInvoicesRunningDocument, options);
      }
export function useCheckIsDownloadInvoicesRunningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIsDownloadInvoicesRunningQuery, CheckIsDownloadInvoicesRunningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckIsDownloadInvoicesRunningQuery, CheckIsDownloadInvoicesRunningQueryVariables>(CheckIsDownloadInvoicesRunningDocument, options);
        }
export type CheckIsDownloadInvoicesRunningQueryHookResult = ReturnType<typeof useCheckIsDownloadInvoicesRunningQuery>;
export type CheckIsDownloadInvoicesRunningLazyQueryHookResult = ReturnType<typeof useCheckIsDownloadInvoicesRunningLazyQuery>;
export type CheckIsDownloadInvoicesRunningQueryResult = Apollo.QueryResult<CheckIsDownloadInvoicesRunningQuery, CheckIsDownloadInvoicesRunningQueryVariables>;
export const KSeFSessionInfoDocument = gql`
    query KSeFSessionInfo {
  KSeFSessionInfo {
    Active
    ExpirationTime
  }
}
    `;

/**
 * __useKSeFSessionInfoQuery__
 *
 * To run a query within a React component, call `useKSeFSessionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useKSeFSessionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKSeFSessionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useKSeFSessionInfoQuery(baseOptions?: Apollo.QueryHookOptions<KSeFSessionInfoQuery, KSeFSessionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KSeFSessionInfoQuery, KSeFSessionInfoQueryVariables>(KSeFSessionInfoDocument, options);
      }
export function useKSeFSessionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KSeFSessionInfoQuery, KSeFSessionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KSeFSessionInfoQuery, KSeFSessionInfoQueryVariables>(KSeFSessionInfoDocument, options);
        }
export type KSeFSessionInfoQueryHookResult = ReturnType<typeof useKSeFSessionInfoQuery>;
export type KSeFSessionInfoLazyQueryHookResult = ReturnType<typeof useKSeFSessionInfoLazyQuery>;
export type KSeFSessionInfoQueryResult = Apollo.QueryResult<KSeFSessionInfoQuery, KSeFSessionInfoQueryVariables>;
export const RequiresSessionTerminationForUpoDocument = gql`
    query RequiresSessionTerminationForUPO($InvoicesIds: [UUID!]!) {
  RequiresSessionTerminationForUPO(invoiceIds: $InvoicesIds) {
    Result
  }
}
    `;

/**
 * __useRequiresSessionTerminationForUpoQuery__
 *
 * To run a query within a React component, call `useRequiresSessionTerminationForUpoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequiresSessionTerminationForUpoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequiresSessionTerminationForUpoQuery({
 *   variables: {
 *      InvoicesIds: // value for 'InvoicesIds'
 *   },
 * });
 */
export function useRequiresSessionTerminationForUpoQuery(baseOptions: Apollo.QueryHookOptions<RequiresSessionTerminationForUpoQuery, RequiresSessionTerminationForUpoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequiresSessionTerminationForUpoQuery, RequiresSessionTerminationForUpoQueryVariables>(RequiresSessionTerminationForUpoDocument, options);
      }
export function useRequiresSessionTerminationForUpoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequiresSessionTerminationForUpoQuery, RequiresSessionTerminationForUpoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequiresSessionTerminationForUpoQuery, RequiresSessionTerminationForUpoQueryVariables>(RequiresSessionTerminationForUpoDocument, options);
        }
export type RequiresSessionTerminationForUpoQueryHookResult = ReturnType<typeof useRequiresSessionTerminationForUpoQuery>;
export type RequiresSessionTerminationForUpoLazyQueryHookResult = ReturnType<typeof useRequiresSessionTerminationForUpoLazyQuery>;
export type RequiresSessionTerminationForUpoQueryResult = Apollo.QueryResult<RequiresSessionTerminationForUpoQuery, RequiresSessionTerminationForUpoQueryVariables>;
export const SubscribeDownloadInvoicesFromKSeFDocument = gql`
    query SubscribeDownloadInvoicesFromKSeF($InternalEnabled: Boolean!, $Internal: DateTimeRangeInput, $ExternalEnabled: Boolean!, $External: DateTimeRangeInput) {
  SubscribeDownloadInvoicesFromKSeF(
    internalEnabled: $InternalEnabled
    internal: $Internal
    externalEnabled: $ExternalEnabled
    external: $External
  ) {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;

/**
 * __useSubscribeDownloadInvoicesFromKSeFQuery__
 *
 * To run a query within a React component, call `useSubscribeDownloadInvoicesFromKSeFQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeDownloadInvoicesFromKSeFQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeDownloadInvoicesFromKSeFQuery({
 *   variables: {
 *      InternalEnabled: // value for 'InternalEnabled'
 *      Internal: // value for 'Internal'
 *      ExternalEnabled: // value for 'ExternalEnabled'
 *      External: // value for 'External'
 *   },
 * });
 */
export function useSubscribeDownloadInvoicesFromKSeFQuery(baseOptions: Apollo.QueryHookOptions<SubscribeDownloadInvoicesFromKSeFQuery, SubscribeDownloadInvoicesFromKSeFQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscribeDownloadInvoicesFromKSeFQuery, SubscribeDownloadInvoicesFromKSeFQueryVariables>(SubscribeDownloadInvoicesFromKSeFDocument, options);
      }
export function useSubscribeDownloadInvoicesFromKSeFLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscribeDownloadInvoicesFromKSeFQuery, SubscribeDownloadInvoicesFromKSeFQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscribeDownloadInvoicesFromKSeFQuery, SubscribeDownloadInvoicesFromKSeFQueryVariables>(SubscribeDownloadInvoicesFromKSeFDocument, options);
        }
export type SubscribeDownloadInvoicesFromKSeFQueryHookResult = ReturnType<typeof useSubscribeDownloadInvoicesFromKSeFQuery>;
export type SubscribeDownloadInvoicesFromKSeFLazyQueryHookResult = ReturnType<typeof useSubscribeDownloadInvoicesFromKSeFLazyQuery>;
export type SubscribeDownloadInvoicesFromKSeFQueryResult = Apollo.QueryResult<SubscribeDownloadInvoicesFromKSeFQuery, SubscribeDownloadInvoicesFromKSeFQueryVariables>;
export const SubscribeGetUpoDocument = gql`
    query SubscribeGetUpo($InvoiceIds: [UUID!]!, $UpoFileType: UpoFileType!) {
  SubscribeGetUpo(InvoiceIds: $InvoiceIds, UpoFileType: $UpoFileType) {
    Result
    NotificationModel {
      ...WebSocketNotification
    }
  }
}
    ${WebSocketNotificationFragmentDoc}`;

/**
 * __useSubscribeGetUpoQuery__
 *
 * To run a query within a React component, call `useSubscribeGetUpoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeGetUpoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeGetUpoQuery({
 *   variables: {
 *      InvoiceIds: // value for 'InvoiceIds'
 *      UpoFileType: // value for 'UpoFileType'
 *   },
 * });
 */
export function useSubscribeGetUpoQuery(baseOptions: Apollo.QueryHookOptions<SubscribeGetUpoQuery, SubscribeGetUpoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscribeGetUpoQuery, SubscribeGetUpoQueryVariables>(SubscribeGetUpoDocument, options);
      }
export function useSubscribeGetUpoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscribeGetUpoQuery, SubscribeGetUpoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscribeGetUpoQuery, SubscribeGetUpoQueryVariables>(SubscribeGetUpoDocument, options);
        }
export type SubscribeGetUpoQueryHookResult = ReturnType<typeof useSubscribeGetUpoQuery>;
export type SubscribeGetUpoLazyQueryHookResult = ReturnType<typeof useSubscribeGetUpoLazyQuery>;
export type SubscribeGetUpoQueryResult = Apollo.QueryResult<SubscribeGetUpoQuery, SubscribeGetUpoQueryVariables>;
export const GetUserKSeFRequestForCredentialsDocument = gql`
    query GetUserKSeFRequestForCredentials($SearchText: String, $order: ItemsOrderInput, $take: Long, $skip: Long, $RequestedCredentialFiltersFields: RequestedCredentialFiltersFieldsInput) {
  GetUserKSeFRequestForCredentials(
    CredentialFiltersFields: $RequestedCredentialFiltersFields
    SearchText: $SearchText
    take: $take
    skip: $skip
    order: $order
  ) {
    RequestedCredentials {
      ...RequestedCredentials
    }
    TotalCount
    FilteredCount
  }
}
    ${RequestedCredentialsFragmentDoc}`;

/**
 * __useGetUserKSeFRequestForCredentialsQuery__
 *
 * To run a query within a React component, call `useGetUserKSeFRequestForCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserKSeFRequestForCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserKSeFRequestForCredentialsQuery({
 *   variables: {
 *      SearchText: // value for 'SearchText'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      RequestedCredentialFiltersFields: // value for 'RequestedCredentialFiltersFields'
 *   },
 * });
 */
export function useGetUserKSeFRequestForCredentialsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables>(GetUserKSeFRequestForCredentialsDocument, options);
      }
export function useGetUserKSeFRequestForCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables>(GetUserKSeFRequestForCredentialsDocument, options);
        }
export type GetUserKSeFRequestForCredentialsQueryHookResult = ReturnType<typeof useGetUserKSeFRequestForCredentialsQuery>;
export type GetUserKSeFRequestForCredentialsLazyQueryHookResult = ReturnType<typeof useGetUserKSeFRequestForCredentialsLazyQuery>;
export type GetUserKSeFRequestForCredentialsQueryResult = Apollo.QueryResult<GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables>;
export const GetAccountsDocument = gql`
    query GetAccounts($CompanyId: UUID!, $FinancialYearId: UUID!, $InvoiceTypeGroup: InvoiceTypeGroupEnum!, $AccountType: AccountTypeEnum) {
  GetAccounts(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    InvoiceTypeGroup: $InvoiceTypeGroup
    AccountType: $AccountType
  ) {
    AccountsPlan {
      Id
      Number
      Name
      IsFinal
      IsDisabled
      ParentId
      ChildrenIds
      AccountType
      FinancialYearId
    }
  }
}
    `;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      InvoiceTypeGroup: // value for 'InvoiceTypeGroup'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const GetAccountsForPostingDocument = gql`
    query GetAccountsForPosting($CompanyId: UUID!, $DecreeId: UUID!, $AccountType: AccountTypeEnum) {
  GetAccountsForPosting(
    CompanyId: $CompanyId
    DecreeId: $DecreeId
    AccountType: $AccountType
  ) {
    AccountsPlan {
      Id
      Number
      Name
      IsFinal
      IsDisabled
      ParentId
      ChildrenIds
      AccountType
      FinancialYearId
    }
  }
}
    `;

/**
 * __useGetAccountsForPostingQuery__
 *
 * To run a query within a React component, call `useGetAccountsForPostingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsForPostingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsForPostingQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      DecreeId: // value for 'DecreeId'
 *      AccountType: // value for 'AccountType'
 *   },
 * });
 */
export function useGetAccountsForPostingQuery(baseOptions: Apollo.QueryHookOptions<GetAccountsForPostingQuery, GetAccountsForPostingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsForPostingQuery, GetAccountsForPostingQueryVariables>(GetAccountsForPostingDocument, options);
      }
export function useGetAccountsForPostingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsForPostingQuery, GetAccountsForPostingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsForPostingQuery, GetAccountsForPostingQueryVariables>(GetAccountsForPostingDocument, options);
        }
export type GetAccountsForPostingQueryHookResult = ReturnType<typeof useGetAccountsForPostingQuery>;
export type GetAccountsForPostingLazyQueryHookResult = ReturnType<typeof useGetAccountsForPostingLazyQuery>;
export type GetAccountsForPostingQueryResult = Apollo.QueryResult<GetAccountsForPostingQuery, GetAccountsForPostingQueryVariables>;
export const GetAttachmentsDocument = gql`
    query GetAttachments($InvoiceId: UUID!) {
  GetAttachments(InvoiceId: $InvoiceId) {
    Attachments {
      Id
      CompanyId
      CreatedByEmail
      FileName
      CreatedAt
      IsPublic
      Size
      InvoiceId
    }
  }
}
    `;

/**
 * __useGetAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentsQuery({
 *   variables: {
 *      InvoiceId: // value for 'InvoiceId'
 *   },
 * });
 */
export function useGetAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetAttachmentsQuery, GetAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(GetAttachmentsDocument, options);
      }
export function useGetAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentsQuery, GetAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(GetAttachmentsDocument, options);
        }
export type GetAttachmentsQueryHookResult = ReturnType<typeof useGetAttachmentsQuery>;
export type GetAttachmentsLazyQueryHookResult = ReturnType<typeof useGetAttachmentsLazyQuery>;
export type GetAttachmentsQueryResult = Apollo.QueryResult<GetAttachmentsQuery, GetAttachmentsQueryVariables>;
export const GetAutoPostingModuleStatusDocument = gql`
    query GetAutoPostingModuleStatus {
  GetAutoPostingModuleStatus {
    ModuleSettings {
      ModuleSubscriptionStatusValue
      InitializationStatusValue
      InitializationTaskTypeValue
      ErrorDetails
    }
    ConfigurationStatus {
      IsRequiredConfiguration
      RequiredModulesToFill
    }
  }
}
    `;

/**
 * __useGetAutoPostingModuleStatusQuery__
 *
 * To run a query within a React component, call `useGetAutoPostingModuleStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoPostingModuleStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoPostingModuleStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAutoPostingModuleStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetAutoPostingModuleStatusQuery, GetAutoPostingModuleStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoPostingModuleStatusQuery, GetAutoPostingModuleStatusQueryVariables>(GetAutoPostingModuleStatusDocument, options);
      }
export function useGetAutoPostingModuleStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoPostingModuleStatusQuery, GetAutoPostingModuleStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoPostingModuleStatusQuery, GetAutoPostingModuleStatusQueryVariables>(GetAutoPostingModuleStatusDocument, options);
        }
export type GetAutoPostingModuleStatusQueryHookResult = ReturnType<typeof useGetAutoPostingModuleStatusQuery>;
export type GetAutoPostingModuleStatusLazyQueryHookResult = ReturnType<typeof useGetAutoPostingModuleStatusLazyQuery>;
export type GetAutoPostingModuleStatusQueryResult = Apollo.QueryResult<GetAutoPostingModuleStatusQuery, GetAutoPostingModuleStatusQueryVariables>;
export const GetAutoPostingSettingsDocument = gql`
    query GetAutoPostingSettings($CompanyId: UUID!) {
  GetAutoPostingSettings(CompanyId: $CompanyId) {
    AutoPostingSettings {
      AutoPostingOfInvoices {
        SettingId
        SettingType
        IsActive
        ModifiedAt
        ModifiedBy
      }
    }
  }
}
    `;

/**
 * __useGetAutoPostingSettingsQuery__
 *
 * To run a query within a React component, call `useGetAutoPostingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoPostingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoPostingSettingsQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *   },
 * });
 */
export function useGetAutoPostingSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetAutoPostingSettingsQuery, GetAutoPostingSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoPostingSettingsQuery, GetAutoPostingSettingsQueryVariables>(GetAutoPostingSettingsDocument, options);
      }
export function useGetAutoPostingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoPostingSettingsQuery, GetAutoPostingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoPostingSettingsQuery, GetAutoPostingSettingsQueryVariables>(GetAutoPostingSettingsDocument, options);
        }
export type GetAutoPostingSettingsQueryHookResult = ReturnType<typeof useGetAutoPostingSettingsQuery>;
export type GetAutoPostingSettingsLazyQueryHookResult = ReturnType<typeof useGetAutoPostingSettingsLazyQuery>;
export type GetAutoPostingSettingsQueryResult = Apollo.QueryResult<GetAutoPostingSettingsQuery, GetAutoPostingSettingsQueryVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories($CompanyId: UUID!, $FinancialYearId: UUID!, $Order: [CategoryViewModelSortInput!]) {
  GetCategoriesViewModel(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    order: $Order
  ) {
    items {
      Id
      MlKey
      Name
      InvoiceTypeGroup
      Type
      IsArchived
    }
  }
}
    `;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetCategoriesAccountsDocument = gql`
    query GetCategoriesAccounts($CompanyId: UUID!, $FinancialYearId: UUID!, $SearchText: String, $FilterFields: CategoryViewModelFilterInput, $Take: Int, $Skip: Int, $Order: [CategoryViewModelSortInput!]) {
  GetCategoriesViewModel(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    SearchText: $SearchText
    where: $FilterFields
    take: $Take
    skip: $Skip
    order: $Order
  ) {
    items {
      Id
      Name
      Type
      Accounts {
        Id
        Number
        Name
        IsFinal
        IsDisabled
        ParentId
        AccountType
        FinancialYearId
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetCategoriesAccountsQuery__
 *
 * To run a query within a React component, call `useGetCategoriesAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesAccountsQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      SearchText: // value for 'SearchText'
 *      FilterFields: // value for 'FilterFields'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetCategoriesAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesAccountsQuery, GetCategoriesAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesAccountsQuery, GetCategoriesAccountsQueryVariables>(GetCategoriesAccountsDocument, options);
      }
export function useGetCategoriesAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesAccountsQuery, GetCategoriesAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesAccountsQuery, GetCategoriesAccountsQueryVariables>(GetCategoriesAccountsDocument, options);
        }
export type GetCategoriesAccountsQueryHookResult = ReturnType<typeof useGetCategoriesAccountsQuery>;
export type GetCategoriesAccountsLazyQueryHookResult = ReturnType<typeof useGetCategoriesAccountsLazyQuery>;
export type GetCategoriesAccountsQueryResult = Apollo.QueryResult<GetCategoriesAccountsQuery, GetCategoriesAccountsQueryVariables>;
export const GetCategoryRulesDocument = gql`
    query GetCategoryRules($CompanyId: UUID!, $FinancialYearId: UUID!, $Filters: CategoryRulesFiltersInput!, $SearchText: String, $Take: Long!, $Skip: Long!, $Order: ItemsOrderInput) {
  GetCategoryRules(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    Filters: $Filters
    SearchText: $SearchText
    take: $Take
    skip: $Skip
    order: $Order
  ) {
    Items {
      Id
      PositionName
      PreviousCategoryName
      CurrentCategoryName
      IsActive
      UserId
      UserFirstName
      UserLastName
      ModifiedAt
      InvoiceTypeGroup
    }
    TotalCount
  }
}
    `;

/**
 * __useGetCategoryRulesQuery__
 *
 * To run a query within a React component, call `useGetCategoryRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryRulesQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      Filters: // value for 'Filters'
 *      SearchText: // value for 'SearchText'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *   },
 * });
 */
export function useGetCategoryRulesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryRulesQuery, GetCategoryRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryRulesQuery, GetCategoryRulesQueryVariables>(GetCategoryRulesDocument, options);
      }
export function useGetCategoryRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryRulesQuery, GetCategoryRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryRulesQuery, GetCategoryRulesQueryVariables>(GetCategoryRulesDocument, options);
        }
export type GetCategoryRulesQueryHookResult = ReturnType<typeof useGetCategoryRulesQuery>;
export type GetCategoryRulesLazyQueryHookResult = ReturnType<typeof useGetCategoryRulesLazyQuery>;
export type GetCategoryRulesQueryResult = Apollo.QueryResult<GetCategoryRulesQuery, GetCategoryRulesQueryVariables>;
export const GetCategoryRulesUsersAndPositionsDocument = gql`
    query GetCategoryRulesUsersAndPositions($CompanyId: UUID!, $FinancialYearId: UUID!, $IsActive: Boolean!) {
  GetCategoryRulesUsersAndPositions(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    IsActive: $IsActive
  ) {
    UsersList {
      UserId
      UserFirstName
      UserLastName
    }
    Positions {
      Id
      PositionName
    }
  }
}
    `;

/**
 * __useGetCategoryRulesUsersAndPositionsQuery__
 *
 * To run a query within a React component, call `useGetCategoryRulesUsersAndPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryRulesUsersAndPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryRulesUsersAndPositionsQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useGetCategoryRulesUsersAndPositionsQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryRulesUsersAndPositionsQuery, GetCategoryRulesUsersAndPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryRulesUsersAndPositionsQuery, GetCategoryRulesUsersAndPositionsQueryVariables>(GetCategoryRulesUsersAndPositionsDocument, options);
      }
export function useGetCategoryRulesUsersAndPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryRulesUsersAndPositionsQuery, GetCategoryRulesUsersAndPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryRulesUsersAndPositionsQuery, GetCategoryRulesUsersAndPositionsQueryVariables>(GetCategoryRulesUsersAndPositionsDocument, options);
        }
export type GetCategoryRulesUsersAndPositionsQueryHookResult = ReturnType<typeof useGetCategoryRulesUsersAndPositionsQuery>;
export type GetCategoryRulesUsersAndPositionsLazyQueryHookResult = ReturnType<typeof useGetCategoryRulesUsersAndPositionsLazyQuery>;
export type GetCategoryRulesUsersAndPositionsQueryResult = Apollo.QueryResult<GetCategoryRulesUsersAndPositionsQuery, GetCategoryRulesUsersAndPositionsQueryVariables>;
export const GetCompanyVehiclesDocument = gql`
    query GetCompanyVehicles($CompanyId: UUID!, $SearchText: String, $Take: Int, $Skip: Int, $Order: [CompanyVehicleViewModelSortInput!], $FilterFields: CompanyVehicleViewModelFilterInput) {
  CompanyVehicles(
    CompanyId: $CompanyId
    SearchText: $SearchText
    where: $FilterFields
    take: $Take
    skip: $Skip
    order: $Order
  ) {
    items {
      Id
      RegistrationNumber
      VehicleUsageType
      Description
    }
    totalCount
  }
}
    `;

/**
 * __useGetCompanyVehiclesQuery__
 *
 * To run a query within a React component, call `useGetCompanyVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyVehiclesQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      SearchText: // value for 'SearchText'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *      FilterFields: // value for 'FilterFields'
 *   },
 * });
 */
export function useGetCompanyVehiclesQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables>(GetCompanyVehiclesDocument, options);
      }
export function useGetCompanyVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables>(GetCompanyVehiclesDocument, options);
        }
export type GetCompanyVehiclesQueryHookResult = ReturnType<typeof useGetCompanyVehiclesQuery>;
export type GetCompanyVehiclesLazyQueryHookResult = ReturnType<typeof useGetCompanyVehiclesLazyQuery>;
export type GetCompanyVehiclesQueryResult = Apollo.QueryResult<GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables>;
export const GetCompanyVehicleByIdDocument = gql`
    query GetCompanyVehicleById($VehicleId: UUID!) {
  GetCompanyVehicleById(VehicleId: $VehicleId) {
    Vehicle {
      Id
      RegistrationNumber
      Description
      VehicleUsageType
    }
  }
}
    `;

/**
 * __useGetCompanyVehicleByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyVehicleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyVehicleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyVehicleByIdQuery({
 *   variables: {
 *      VehicleId: // value for 'VehicleId'
 *   },
 * });
 */
export function useGetCompanyVehicleByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyVehicleByIdQuery, GetCompanyVehicleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyVehicleByIdQuery, GetCompanyVehicleByIdQueryVariables>(GetCompanyVehicleByIdDocument, options);
      }
export function useGetCompanyVehicleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyVehicleByIdQuery, GetCompanyVehicleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyVehicleByIdQuery, GetCompanyVehicleByIdQueryVariables>(GetCompanyVehicleByIdDocument, options);
        }
export type GetCompanyVehicleByIdQueryHookResult = ReturnType<typeof useGetCompanyVehicleByIdQuery>;
export type GetCompanyVehicleByIdLazyQueryHookResult = ReturnType<typeof useGetCompanyVehicleByIdLazyQuery>;
export type GetCompanyVehicleByIdQueryResult = Apollo.QueryResult<GetCompanyVehicleByIdQuery, GetCompanyVehicleByIdQueryVariables>;
export const GetDecreeDetailsDocument = gql`
    query GetDecreeDetails($DecreeId: UUID!) {
  GetDecreeDetails(DecreeId: $DecreeId) {
    Decree {
      ...DecreeDetailsItem
      FinancialYearId
      DocumentType {
        ...InvoiceDocumentType
        Symbol
      }
      VatSummaries {
        ...VatSummaries
      }
    }
  }
}
    ${DecreeDetailsItemFragmentDoc}
${InvoiceDocumentTypeFragmentDoc}
${VatSummariesFragmentDoc}`;

/**
 * __useGetDecreeDetailsQuery__
 *
 * To run a query within a React component, call `useGetDecreeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDecreeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDecreeDetailsQuery({
 *   variables: {
 *      DecreeId: // value for 'DecreeId'
 *   },
 * });
 */
export function useGetDecreeDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDecreeDetailsQuery, GetDecreeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDecreeDetailsQuery, GetDecreeDetailsQueryVariables>(GetDecreeDetailsDocument, options);
      }
export function useGetDecreeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDecreeDetailsQuery, GetDecreeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDecreeDetailsQuery, GetDecreeDetailsQueryVariables>(GetDecreeDetailsDocument, options);
        }
export type GetDecreeDetailsQueryHookResult = ReturnType<typeof useGetDecreeDetailsQuery>;
export type GetDecreeDetailsLazyQueryHookResult = ReturnType<typeof useGetDecreeDetailsLazyQuery>;
export type GetDecreeDetailsQueryResult = Apollo.QueryResult<GetDecreeDetailsQuery, GetDecreeDetailsQueryVariables>;
export const GetDocumentTypesDocument = gql`
    query GetDocumentTypes($FinancialYearId: UUID!) {
  GetInvoiceDocumentTypes(FinancialYearId: $FinancialYearId) {
    DocumentTypeResponse {
      Id
      Symbol
      Name
    }
  }
}
    `;

/**
 * __useGetDocumentTypesQuery__
 *
 * To run a query within a React component, call `useGetDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTypesQuery({
 *   variables: {
 *      FinancialYearId: // value for 'FinancialYearId'
 *   },
 * });
 */
export function useGetDocumentTypesQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
      }
export function useGetDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>(GetDocumentTypesDocument, options);
        }
export type GetDocumentTypesQueryHookResult = ReturnType<typeof useGetDocumentTypesQuery>;
export type GetDocumentTypesLazyQueryHookResult = ReturnType<typeof useGetDocumentTypesLazyQuery>;
export type GetDocumentTypesQueryResult = Apollo.QueryResult<GetDocumentTypesQuery, GetDocumentTypesQueryVariables>;
export const GetDocumentTypesSettingsDocument = gql`
    query GetDocumentTypesSettings($InvoiceTypeGroup: InvoiceTypeGroupEnum!, $FinancialYearId: UUID!) {
  GetDocumentTypesSettings(
    InvoiceTypeGroup: $InvoiceTypeGroup
    FinancialYearId: $FinancialYearId
  ) {
    DocumentTypesVatRegistries {
      SettingType
      InvoiceDocumentTypeId
    }
  }
}
    `;

/**
 * __useGetDocumentTypesSettingsQuery__
 *
 * To run a query within a React component, call `useGetDocumentTypesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTypesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTypesSettingsQuery({
 *   variables: {
 *      InvoiceTypeGroup: // value for 'InvoiceTypeGroup'
 *      FinancialYearId: // value for 'FinancialYearId'
 *   },
 * });
 */
export function useGetDocumentTypesSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentTypesSettingsQuery, GetDocumentTypesSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentTypesSettingsQuery, GetDocumentTypesSettingsQueryVariables>(GetDocumentTypesSettingsDocument, options);
      }
export function useGetDocumentTypesSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTypesSettingsQuery, GetDocumentTypesSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentTypesSettingsQuery, GetDocumentTypesSettingsQueryVariables>(GetDocumentTypesSettingsDocument, options);
        }
export type GetDocumentTypesSettingsQueryHookResult = ReturnType<typeof useGetDocumentTypesSettingsQuery>;
export type GetDocumentTypesSettingsLazyQueryHookResult = ReturnType<typeof useGetDocumentTypesSettingsLazyQuery>;
export type GetDocumentTypesSettingsQueryResult = Apollo.QueryResult<GetDocumentTypesSettingsQuery, GetDocumentTypesSettingsQueryVariables>;
export const GetFinancialYearsDocument = gql`
    query GetFinancialYears($CompanyId: UUID!) {
  GetFinancialYears(CompanyId: $CompanyId) {
    FinancialYears {
      Id
      Name
      LastSyncDate
      BeginDate
      EndDate
      IsConfigured
      Status
      IsActive
    }
  }
}
    `;

/**
 * __useGetFinancialYearsQuery__
 *
 * To run a query within a React component, call `useGetFinancialYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialYearsQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *   },
 * });
 */
export function useGetFinancialYearsQuery(baseOptions: Apollo.QueryHookOptions<GetFinancialYearsQuery, GetFinancialYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancialYearsQuery, GetFinancialYearsQueryVariables>(GetFinancialYearsDocument, options);
      }
export function useGetFinancialYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialYearsQuery, GetFinancialYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancialYearsQuery, GetFinancialYearsQueryVariables>(GetFinancialYearsDocument, options);
        }
export type GetFinancialYearsQueryHookResult = ReturnType<typeof useGetFinancialYearsQuery>;
export type GetFinancialYearsLazyQueryHookResult = ReturnType<typeof useGetFinancialYearsLazyQuery>;
export type GetFinancialYearsQueryResult = Apollo.QueryResult<GetFinancialYearsQuery, GetFinancialYearsQueryVariables>;
export const GetInvoiceDocumentTypesDocument = gql`
    query GetInvoiceDocumentTypes($FinancialYearId: UUID!) {
  GetInvoiceDocumentTypes(FinancialYearId: $FinancialYearId) {
    DocumentTypeResponse {
      Id
      Name
      VatRegistryId
      VatRegistryName
      Symbol
    }
  }
}
    `;

/**
 * __useGetInvoiceDocumentTypesQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDocumentTypesQuery({
 *   variables: {
 *      FinancialYearId: // value for 'FinancialYearId'
 *   },
 * });
 */
export function useGetInvoiceDocumentTypesQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceDocumentTypesQuery, GetInvoiceDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceDocumentTypesQuery, GetInvoiceDocumentTypesQueryVariables>(GetInvoiceDocumentTypesDocument, options);
      }
export function useGetInvoiceDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceDocumentTypesQuery, GetInvoiceDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceDocumentTypesQuery, GetInvoiceDocumentTypesQueryVariables>(GetInvoiceDocumentTypesDocument, options);
        }
export type GetInvoiceDocumentTypesQueryHookResult = ReturnType<typeof useGetInvoiceDocumentTypesQuery>;
export type GetInvoiceDocumentTypesLazyQueryHookResult = ReturnType<typeof useGetInvoiceDocumentTypesLazyQuery>;
export type GetInvoiceDocumentTypesQueryResult = Apollo.QueryResult<GetInvoiceDocumentTypesQuery, GetInvoiceDocumentTypesQueryVariables>;
export const GetPostingInvoicesReportDocument = gql`
    query GetPostingInvoicesReport($reportFileType: ReportFileTypeEnum!, $where: GetPostingInvoicesReportFilterInput, $take: Int, $skip: Int, $order: [GetPostingInvoicesReportSortInput!]) {
  GetPostingInvoicesReport(
    reportFileType: $reportFileType
    where: $where
    take: $take
    skip: $skip
    order: $order
  ) {
    FileName
    FileContent
    MimeType
  }
}
    `;

/**
 * __useGetPostingInvoicesReportQuery__
 *
 * To run a query within a React component, call `useGetPostingInvoicesReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostingInvoicesReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostingInvoicesReportQuery({
 *   variables: {
 *      reportFileType: // value for 'reportFileType'
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPostingInvoicesReportQuery(baseOptions: Apollo.QueryHookOptions<GetPostingInvoicesReportQuery, GetPostingInvoicesReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostingInvoicesReportQuery, GetPostingInvoicesReportQueryVariables>(GetPostingInvoicesReportDocument, options);
      }
export function useGetPostingInvoicesReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostingInvoicesReportQuery, GetPostingInvoicesReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostingInvoicesReportQuery, GetPostingInvoicesReportQueryVariables>(GetPostingInvoicesReportDocument, options);
        }
export type GetPostingInvoicesReportQueryHookResult = ReturnType<typeof useGetPostingInvoicesReportQuery>;
export type GetPostingInvoicesReportLazyQueryHookResult = ReturnType<typeof useGetPostingInvoicesReportLazyQuery>;
export type GetPostingInvoicesReportQueryResult = Apollo.QueryResult<GetPostingInvoicesReportQuery, GetPostingInvoicesReportQueryVariables>;
export const GetPostingInvoicesDocument = gql`
    query GetPostingInvoices($CompanyId: UUID!, $SearchText: String, $Take: Int, $Skip: Int, $Order: [DecreeViewModelSortInput!], $FilterFields: DecreeViewModelFilterInput) {
  GetPostingInvoicesViewModel(
    CompanyId: $CompanyId
    SearchText: $SearchText
    where: $FilterFields
    take: $Take
    skip: $Skip
    order: $Order
  ) {
    items {
      Id
      GrossValue
      CreatedAt
      CreatedBy
      PostingStatus
      InvoiceTypeGroup
      InvoiceNumber
      InvoiceDocumentId
      ContractorId
      Contractor {
        Identifier
        Name
      }
      IsAccepted
    }
    totalCount
  }
}
    `;

/**
 * __useGetPostingInvoicesQuery__
 *
 * To run a query within a React component, call `useGetPostingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostingInvoicesQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      SearchText: // value for 'SearchText'
 *      Take: // value for 'Take'
 *      Skip: // value for 'Skip'
 *      Order: // value for 'Order'
 *      FilterFields: // value for 'FilterFields'
 *   },
 * });
 */
export function useGetPostingInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetPostingInvoicesQuery, GetPostingInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostingInvoicesQuery, GetPostingInvoicesQueryVariables>(GetPostingInvoicesDocument, options);
      }
export function useGetPostingInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostingInvoicesQuery, GetPostingInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostingInvoicesQuery, GetPostingInvoicesQueryVariables>(GetPostingInvoicesDocument, options);
        }
export type GetPostingInvoicesQueryHookResult = ReturnType<typeof useGetPostingInvoicesQuery>;
export type GetPostingInvoicesLazyQueryHookResult = ReturnType<typeof useGetPostingInvoicesLazyQuery>;
export type GetPostingInvoicesQueryResult = Apollo.QueryResult<GetPostingInvoicesQuery, GetPostingInvoicesQueryVariables>;
export const GetRecalculatedDecreeDocument = gql`
    query GetRecalculatedDecree($DecreeId: UUID!, $CompanyId: UUID!, $CategoryAccountChanges: [CategoryAccountChangeRequestInput!], $PositionCategoryChanges: [PositionCategoryChangeRequestInput!], $PositionAccountChanges: [PositionAccountChangeRequestInput!], $PositionVehicleUsageTypeChanges: [PositionVehicleUsageTypeChangeRequestInput!]) {
  GetRecalculatedDecree(
    DecreeId: $DecreeId
    CompanyId: $CompanyId
    CategoryAccountChanges: $CategoryAccountChanges
    PositionCategoryChanges: $PositionCategoryChanges
    PositionAccountChanges: $PositionAccountChanges
    PositionVehicleUsageTypeChanges: $PositionVehicleUsageTypeChanges
  ) {
    RecalculatedDecree {
      ...DecreeDetailsItem
      FinancialYearId
      DocumentType {
        ...InvoiceDocumentType
        Symbol
      }
      VatSummaries {
        ...VatSummaries
      }
    }
  }
}
    ${DecreeDetailsItemFragmentDoc}
${InvoiceDocumentTypeFragmentDoc}
${VatSummariesFragmentDoc}`;

/**
 * __useGetRecalculatedDecreeQuery__
 *
 * To run a query within a React component, call `useGetRecalculatedDecreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecalculatedDecreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecalculatedDecreeQuery({
 *   variables: {
 *      DecreeId: // value for 'DecreeId'
 *      CompanyId: // value for 'CompanyId'
 *      CategoryAccountChanges: // value for 'CategoryAccountChanges'
 *      PositionCategoryChanges: // value for 'PositionCategoryChanges'
 *      PositionAccountChanges: // value for 'PositionAccountChanges'
 *      PositionVehicleUsageTypeChanges: // value for 'PositionVehicleUsageTypeChanges'
 *   },
 * });
 */
export function useGetRecalculatedDecreeQuery(baseOptions: Apollo.QueryHookOptions<GetRecalculatedDecreeQuery, GetRecalculatedDecreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecalculatedDecreeQuery, GetRecalculatedDecreeQueryVariables>(GetRecalculatedDecreeDocument, options);
      }
export function useGetRecalculatedDecreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecalculatedDecreeQuery, GetRecalculatedDecreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecalculatedDecreeQuery, GetRecalculatedDecreeQueryVariables>(GetRecalculatedDecreeDocument, options);
        }
export type GetRecalculatedDecreeQueryHookResult = ReturnType<typeof useGetRecalculatedDecreeQuery>;
export type GetRecalculatedDecreeLazyQueryHookResult = ReturnType<typeof useGetRecalculatedDecreeLazyQuery>;
export type GetRecalculatedDecreeQueryResult = Apollo.QueryResult<GetRecalculatedDecreeQuery, GetRecalculatedDecreeQueryVariables>;
export const GetRequiredConfigurationStatusDocument = gql`
    query GetRequiredConfigurationStatus($CompanyId: UUID!) {
  GetRequiredConfigurationStatus(CompanyId: $CompanyId) {
    IsRequiredConfiguration
  }
}
    `;

/**
 * __useGetRequiredConfigurationStatusQuery__
 *
 * To run a query within a React component, call `useGetRequiredConfigurationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequiredConfigurationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequiredConfigurationStatusQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *   },
 * });
 */
export function useGetRequiredConfigurationStatusQuery(baseOptions: Apollo.QueryHookOptions<GetRequiredConfigurationStatusQuery, GetRequiredConfigurationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequiredConfigurationStatusQuery, GetRequiredConfigurationStatusQueryVariables>(GetRequiredConfigurationStatusDocument, options);
      }
export function useGetRequiredConfigurationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequiredConfigurationStatusQuery, GetRequiredConfigurationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequiredConfigurationStatusQuery, GetRequiredConfigurationStatusQueryVariables>(GetRequiredConfigurationStatusDocument, options);
        }
export type GetRequiredConfigurationStatusQueryHookResult = ReturnType<typeof useGetRequiredConfigurationStatusQuery>;
export type GetRequiredConfigurationStatusLazyQueryHookResult = ReturnType<typeof useGetRequiredConfigurationStatusLazyQuery>;
export type GetRequiredConfigurationStatusQueryResult = Apollo.QueryResult<GetRequiredConfigurationStatusQuery, GetRequiredConfigurationStatusQueryVariables>;
export const GetSettlementsDocument = gql`
    query GetSettlements($CompanyId: UUID!, $FinancialYearId: UUID!, $InvoiceTypeGroup: InvoiceTypeGroupEnum!) {
  GetSettlements(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    InvoiceTypeGroup: $InvoiceTypeGroup
  ) {
    Settlements {
      Id
      Name
      IsRequired
      SettlementType
      AccountId
      AccountName
      AccountNumber
    }
  }
}
    `;

/**
 * __useGetSettlementsQuery__
 *
 * To run a query within a React component, call `useGetSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettlementsQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      InvoiceTypeGroup: // value for 'InvoiceTypeGroup'
 *   },
 * });
 */
export function useGetSettlementsQuery(baseOptions: Apollo.QueryHookOptions<GetSettlementsQuery, GetSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettlementsQuery, GetSettlementsQueryVariables>(GetSettlementsDocument, options);
      }
export function useGetSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettlementsQuery, GetSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettlementsQuery, GetSettlementsQueryVariables>(GetSettlementsDocument, options);
        }
export type GetSettlementsQueryHookResult = ReturnType<typeof useGetSettlementsQuery>;
export type GetSettlementsLazyQueryHookResult = ReturnType<typeof useGetSettlementsLazyQuery>;
export type GetSettlementsQueryResult = Apollo.QueryResult<GetSettlementsQuery, GetSettlementsQueryVariables>;
export const GetVatRegistriesDocument = gql`
    query GetVatRegistries($CompanyId: UUID!) {
  GetVatRegistries(CompanyId: $CompanyId) {
    VatRegistries {
      Id
      Name
    }
  }
}
    `;

/**
 * __useGetVatRegistriesQuery__
 *
 * To run a query within a React component, call `useGetVatRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVatRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVatRegistriesQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *   },
 * });
 */
export function useGetVatRegistriesQuery(baseOptions: Apollo.QueryHookOptions<GetVatRegistriesQuery, GetVatRegistriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVatRegistriesQuery, GetVatRegistriesQueryVariables>(GetVatRegistriesDocument, options);
      }
export function useGetVatRegistriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVatRegistriesQuery, GetVatRegistriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVatRegistriesQuery, GetVatRegistriesQueryVariables>(GetVatRegistriesDocument, options);
        }
export type GetVatRegistriesQueryHookResult = ReturnType<typeof useGetVatRegistriesQuery>;
export type GetVatRegistriesLazyQueryHookResult = ReturnType<typeof useGetVatRegistriesLazyQuery>;
export type GetVatRegistriesQueryResult = Apollo.QueryResult<GetVatRegistriesQuery, GetVatRegistriesQueryVariables>;
export const LicensePlateExistsDocument = gql`
    query LicensePlateExists($CompanyId: UUID!, $RegistrationNumber: String!) {
  LicensePlateExists(
    CompanyId: $CompanyId
    RegistrationNumber: $RegistrationNumber
  ) {
    LicensePlateExists
  }
}
    `;

/**
 * __useLicensePlateExistsQuery__
 *
 * To run a query within a React component, call `useLicensePlateExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensePlateExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensePlateExistsQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      RegistrationNumber: // value for 'RegistrationNumber'
 *   },
 * });
 */
export function useLicensePlateExistsQuery(baseOptions: Apollo.QueryHookOptions<LicensePlateExistsQuery, LicensePlateExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicensePlateExistsQuery, LicensePlateExistsQueryVariables>(LicensePlateExistsDocument, options);
      }
export function useLicensePlateExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicensePlateExistsQuery, LicensePlateExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicensePlateExistsQuery, LicensePlateExistsQueryVariables>(LicensePlateExistsDocument, options);
        }
export type LicensePlateExistsQueryHookResult = ReturnType<typeof useLicensePlateExistsQuery>;
export type LicensePlateExistsLazyQueryHookResult = ReturnType<typeof useLicensePlateExistsLazyQuery>;
export type LicensePlateExistsQueryResult = Apollo.QueryResult<LicensePlateExistsQuery, LicensePlateExistsQueryVariables>;
export const GetCategoryAccountsFilterDataDocument = gql`
    query GetCategoryAccountsFilterData($CompanyId: UUID!, $FinancialYearId: UUID!, $InvoiceTypeGroup: InvoiceTypeGroupEnum!, $Type: [CategoryTypeEnum!]) {
  Categories: GetCategoriesViewModel(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    where: {InvoiceTypeGroup: {eq: $InvoiceTypeGroup}, Type: {in: $Type}}
    order: {Name: ASC}
  ) {
    items {
      Id
      Name
      Type
    }
  }
  Accounts: GetCategoriesViewModel(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    where: {InvoiceTypeGroup: {eq: $InvoiceTypeGroup}, Type: {in: $Type}, Accounts: {any: true}}
  ) {
    items {
      Accounts {
        Id
        Name
        Number
        AccountType
      }
    }
  }
}
    `;

/**
 * __useGetCategoryAccountsFilterDataQuery__
 *
 * To run a query within a React component, call `useGetCategoryAccountsFilterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryAccountsFilterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryAccountsFilterDataQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      InvoiceTypeGroup: // value for 'InvoiceTypeGroup'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useGetCategoryAccountsFilterDataQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryAccountsFilterDataQuery, GetCategoryAccountsFilterDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryAccountsFilterDataQuery, GetCategoryAccountsFilterDataQueryVariables>(GetCategoryAccountsFilterDataDocument, options);
      }
export function useGetCategoryAccountsFilterDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryAccountsFilterDataQuery, GetCategoryAccountsFilterDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryAccountsFilterDataQuery, GetCategoryAccountsFilterDataQueryVariables>(GetCategoryAccountsFilterDataDocument, options);
        }
export type GetCategoryAccountsFilterDataQueryHookResult = ReturnType<typeof useGetCategoryAccountsFilterDataQuery>;
export type GetCategoryAccountsFilterDataLazyQueryHookResult = ReturnType<typeof useGetCategoryAccountsFilterDataLazyQuery>;
export type GetCategoryAccountsFilterDataQueryResult = Apollo.QueryResult<GetCategoryAccountsFilterDataQuery, GetCategoryAccountsFilterDataQueryVariables>;
export const CategoryRulesFiltersDataDocument = gql`
    query CategoryRulesFiltersData($CompanyId: UUID!, $FinancialYearId: UUID!, $IsActive: Boolean!) {
  Categories: GetCategoriesViewModel(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    where: {CurrentCategoryRules: {any: true}}
  ) {
    items {
      Id
      Name
    }
  }
  UsersAndPositions: GetCategoryRulesUsersAndPositions(
    CompanyId: $CompanyId
    FinancialYearId: $FinancialYearId
    IsActive: $IsActive
  ) {
    UsersList {
      UserId
      UserFirstName
      UserLastName
    }
    Positions {
      Id
      PositionName
    }
  }
}
    `;

/**
 * __useCategoryRulesFiltersDataQuery__
 *
 * To run a query within a React component, call `useCategoryRulesFiltersDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryRulesFiltersDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryRulesFiltersDataQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      FinancialYearId: // value for 'FinancialYearId'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useCategoryRulesFiltersDataQuery(baseOptions: Apollo.QueryHookOptions<CategoryRulesFiltersDataQuery, CategoryRulesFiltersDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryRulesFiltersDataQuery, CategoryRulesFiltersDataQueryVariables>(CategoryRulesFiltersDataDocument, options);
      }
export function useCategoryRulesFiltersDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryRulesFiltersDataQuery, CategoryRulesFiltersDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryRulesFiltersDataQuery, CategoryRulesFiltersDataQueryVariables>(CategoryRulesFiltersDataDocument, options);
        }
export type CategoryRulesFiltersDataQueryHookResult = ReturnType<typeof useCategoryRulesFiltersDataQuery>;
export type CategoryRulesFiltersDataLazyQueryHookResult = ReturnType<typeof useCategoryRulesFiltersDataLazyQuery>;
export type CategoryRulesFiltersDataQueryResult = Apollo.QueryResult<CategoryRulesFiltersDataQuery, CategoryRulesFiltersDataQueryVariables>;
export const GetPostedInvoicesFiltersDataDocument = gql`
    query GetPostedInvoicesFiltersData($CompanyId: UUID!) {
  Contractors: GetContractorsViewModel(
    CompanyId: $CompanyId
    FromDecrees: true
    order: {Name: ASC}
  ) {
    items {
      Id
      Identifier
      Name
    }
  }
}
    `;

/**
 * __useGetPostedInvoicesFiltersDataQuery__
 *
 * To run a query within a React component, call `useGetPostedInvoicesFiltersDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostedInvoicesFiltersDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostedInvoicesFiltersDataQuery({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *   },
 * });
 */
export function useGetPostedInvoicesFiltersDataQuery(baseOptions: Apollo.QueryHookOptions<GetPostedInvoicesFiltersDataQuery, GetPostedInvoicesFiltersDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostedInvoicesFiltersDataQuery, GetPostedInvoicesFiltersDataQueryVariables>(GetPostedInvoicesFiltersDataDocument, options);
      }
export function useGetPostedInvoicesFiltersDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostedInvoicesFiltersDataQuery, GetPostedInvoicesFiltersDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostedInvoicesFiltersDataQuery, GetPostedInvoicesFiltersDataQueryVariables>(GetPostedInvoicesFiltersDataDocument, options);
        }
export type GetPostedInvoicesFiltersDataQueryHookResult = ReturnType<typeof useGetPostedInvoicesFiltersDataQuery>;
export type GetPostedInvoicesFiltersDataLazyQueryHookResult = ReturnType<typeof useGetPostedInvoicesFiltersDataLazyQuery>;
export type GetPostedInvoicesFiltersDataQueryResult = Apollo.QueryResult<GetPostedInvoicesFiltersDataQuery, GetPostedInvoicesFiltersDataQueryVariables>;
export const GetCompanyExistsDocument = gql`
    query getCompanyExists($TenantId: UUID!, $Identifier: String!, $IdentifierType: IdentifierType!) {
  CompanyExists(
    TenantId: $TenantId
    Identifier: $Identifier
    IdentifierType: $IdentifierType
  ) {
    Exists
  }
}
    `;

/**
 * __useGetCompanyExistsQuery__
 *
 * To run a query within a React component, call `useGetCompanyExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyExistsQuery({
 *   variables: {
 *      TenantId: // value for 'TenantId'
 *      Identifier: // value for 'Identifier'
 *      IdentifierType: // value for 'IdentifierType'
 *   },
 * });
 */
export function useGetCompanyExistsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyExistsQuery, GetCompanyExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyExistsQuery, GetCompanyExistsQueryVariables>(GetCompanyExistsDocument, options);
      }
export function useGetCompanyExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyExistsQuery, GetCompanyExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyExistsQuery, GetCompanyExistsQueryVariables>(GetCompanyExistsDocument, options);
        }
export type GetCompanyExistsQueryHookResult = ReturnType<typeof useGetCompanyExistsQuery>;
export type GetCompanyExistsLazyQueryHookResult = ReturnType<typeof useGetCompanyExistsLazyQuery>;
export type GetCompanyExistsQueryResult = Apollo.QueryResult<GetCompanyExistsQuery, GetCompanyExistsQueryVariables>;
export const CurrentEnvironmentDocument = gql`
    query CurrentEnvironment {
  CurrentEnvironment {
    ...CurrentEnvironmentItem
  }
}
    ${CurrentEnvironmentItemFragmentDoc}`;

/**
 * __useCurrentEnvironmentQuery__
 *
 * To run a query within a React component, call `useCurrentEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentEnvironmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentEnvironmentQuery(baseOptions?: Apollo.QueryHookOptions<CurrentEnvironmentQuery, CurrentEnvironmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentEnvironmentQuery, CurrentEnvironmentQueryVariables>(CurrentEnvironmentDocument, options);
      }
export function useCurrentEnvironmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentEnvironmentQuery, CurrentEnvironmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentEnvironmentQuery, CurrentEnvironmentQueryVariables>(CurrentEnvironmentDocument, options);
        }
export type CurrentEnvironmentQueryHookResult = ReturnType<typeof useCurrentEnvironmentQuery>;
export type CurrentEnvironmentLazyQueryHookResult = ReturnType<typeof useCurrentEnvironmentLazyQuery>;
export type CurrentEnvironmentQueryResult = Apollo.QueryResult<CurrentEnvironmentQuery, CurrentEnvironmentQueryVariables>;
export const KSeFCheckStatusDocument = gql`
    subscription KSeFCheckStatus {
  onKSeFOperatingStatusUpdateGeneral {
    status
    statusDate
  }
}
    `;

/**
 * __useKSeFCheckStatusSubscription__
 *
 * To run a query within a React component, call `useKSeFCheckStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKSeFCheckStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKSeFCheckStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useKSeFCheckStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<KSeFCheckStatusSubscription, KSeFCheckStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<KSeFCheckStatusSubscription, KSeFCheckStatusSubscriptionVariables>(KSeFCheckStatusDocument, options);
      }
export type KSeFCheckStatusSubscriptionHookResult = ReturnType<typeof useKSeFCheckStatusSubscription>;
export type KSeFCheckStatusSubscriptionResult = Apollo.SubscriptionResult<KSeFCheckStatusSubscription>;
export const KSeFNotificationUserDocument = gql`
    subscription KSeFNotificationUser($userId: String!, $companyId: String!) {
  onKSeFNotificationUser(userId: $userId, companyId: $companyId) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useKSeFNotificationUserSubscription__
 *
 * To run a query within a React component, call `useKSeFNotificationUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useKSeFNotificationUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKSeFNotificationUserSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useKSeFNotificationUserSubscription(baseOptions: Apollo.SubscriptionHookOptions<KSeFNotificationUserSubscription, KSeFNotificationUserSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<KSeFNotificationUserSubscription, KSeFNotificationUserSubscriptionVariables>(KSeFNotificationUserDocument, options);
      }
export type KSeFNotificationUserSubscriptionHookResult = ReturnType<typeof useKSeFNotificationUserSubscription>;
export type KSeFNotificationUserSubscriptionResult = Apollo.SubscriptionResult<KSeFNotificationUserSubscription>;
export const OnAutoPostingInitializationCompletedDocument = gql`
    subscription OnAutoPostingInitializationCompleted($CompanyId: UUID!, $UserId: UUID!) {
  onAutoPostingInitializationCompleted(companyId: $CompanyId, userId: $UserId) {
    isCompleted
  }
}
    `;

/**
 * __useOnAutoPostingInitializationCompletedSubscription__
 *
 * To run a query within a React component, call `useOnAutoPostingInitializationCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAutoPostingInitializationCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAutoPostingInitializationCompletedSubscription({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *      UserId: // value for 'UserId'
 *   },
 * });
 */
export function useOnAutoPostingInitializationCompletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnAutoPostingInitializationCompletedSubscription, OnAutoPostingInitializationCompletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAutoPostingInitializationCompletedSubscription, OnAutoPostingInitializationCompletedSubscriptionVariables>(OnAutoPostingInitializationCompletedDocument, options);
      }
export type OnAutoPostingInitializationCompletedSubscriptionHookResult = ReturnType<typeof useOnAutoPostingInitializationCompletedSubscription>;
export type OnAutoPostingInitializationCompletedSubscriptionResult = Apollo.SubscriptionResult<OnAutoPostingInitializationCompletedSubscription>;
export const OnAutoPostingResetInitializationCompletedDocument = gql`
    subscription OnAutoPostingResetInitializationCompleted($CompanyId: UUID!) {
  onAutoPostingResetInitializationCompleted(companyId: $CompanyId) {
    hasFailed
    errorMessage
  }
}
    `;

/**
 * __useOnAutoPostingResetInitializationCompletedSubscription__
 *
 * To run a query within a React component, call `useOnAutoPostingResetInitializationCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAutoPostingResetInitializationCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAutoPostingResetInitializationCompletedSubscription({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *   },
 * });
 */
export function useOnAutoPostingResetInitializationCompletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnAutoPostingResetInitializationCompletedSubscription, OnAutoPostingResetInitializationCompletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAutoPostingResetInitializationCompletedSubscription, OnAutoPostingResetInitializationCompletedSubscriptionVariables>(OnAutoPostingResetInitializationCompletedDocument, options);
      }
export type OnAutoPostingResetInitializationCompletedSubscriptionHookResult = ReturnType<typeof useOnAutoPostingResetInitializationCompletedSubscription>;
export type OnAutoPostingResetInitializationCompletedSubscriptionResult = Apollo.SubscriptionResult<OnAutoPostingResetInitializationCompletedSubscription>;
export const OnAutoPostingInitializationStatusChangedDocument = gql`
    subscription OnAutoPostingInitializationStatusChanged($CompanyId: UUID!) {
  onAutoPostingInitializationStatusChanged(companyId: $CompanyId) {
    status
    taskType
    errorDetails
    errorType
  }
}
    `;

/**
 * __useOnAutoPostingInitializationStatusChangedSubscription__
 *
 * To run a query within a React component, call `useOnAutoPostingInitializationStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAutoPostingInitializationStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAutoPostingInitializationStatusChangedSubscription({
 *   variables: {
 *      CompanyId: // value for 'CompanyId'
 *   },
 * });
 */
export function useOnAutoPostingInitializationStatusChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnAutoPostingInitializationStatusChangedSubscription, OnAutoPostingInitializationStatusChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAutoPostingInitializationStatusChangedSubscription, OnAutoPostingInitializationStatusChangedSubscriptionVariables>(OnAutoPostingInitializationStatusChangedDocument, options);
      }
export type OnAutoPostingInitializationStatusChangedSubscriptionHookResult = ReturnType<typeof useOnAutoPostingInitializationStatusChangedSubscription>;
export type OnAutoPostingInitializationStatusChangedSubscriptionResult = Apollo.SubscriptionResult<OnAutoPostingInitializationStatusChangedSubscription>;
