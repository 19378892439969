import {InvoicesSumInterface} from './InvoicesSumState';
import {sessionStorageService} from '../../modules/common/helpers/storage';

type ItemsKeys = Partial<Record<keyof Pick<InvoicesSumInterface, 'isOn'>, boolean>>;
export interface InvoicesSumPersistServiceInterface {
  save(items?: ItemsKeys): void;
  load(): void;
  remove(items?: ItemsKeys): void;
}

export class InvoicesSumPersistService implements InvoicesSumPersistServiceInterface {
  private readonly isEnabledKey: string;

  constructor(private key: string, private invoicesSum: InvoicesSumInterface) {
    this.isEnabledKey = this.key + '.isEnabled';
  }

  public save(items?: ItemsKeys): void {
    sessionStorageService.setItem(this.createKeyWithSufix(this.isEnabledKey), items?.isOn);
  }

  public load(): void {
    try {
      const sumEnabledState = sessionStorageService.getItem<boolean>(this.createKeyWithSufix(this.isEnabledKey));
      this.invoicesSum.setSumEnabled(sumEnabledState ?? false);
    } catch (err) {
      console.error(err);
    }
  }

  public remove(): void {
    sessionStorageService.removeItem(this.createKeyWithSufix(this.isEnabledKey));
  }

  private createKeyWithSufix(key: string): string {
    return `${key}.${this.invoicesSum.envId}.${this.invoicesSum.companyId}`;
  }
}
