import {Event, EventServiceI, EventsService} from '@symfonia/utils';
import {apolloClient} from '../modules/root/providers/GraphQLProvider';
import {TypedDocumentNode} from '@apollo/client';
import {Subscription} from 'zen-observable-ts';
import {action, computed, makeObservable, observable, reaction} from 'mobx';

import {
  KSeFNotificationUserSubscription,
  KSeFNotificationUserSubscriptionVariables,
  NotificationFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {ConsumeMissedNotifications} from './ConsumeMissedNotifications';
import {AnyObject} from 'yup/es/types';
import {Tr} from '../locales/translationKeys';
import {intl} from '../modules/root/IntlProvider';
import {NotificationEvent} from '../state/KSeFSubscriptionServices/WsEventsRepository';
import {NotificationDataType} from './helpers/NotificationDataParsers';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export type Variables = KSeFNotificationUserSubscriptionVariables

export type ErrorContent = Array<{ key: string, value: string }>

export type EventParams = Omit<NotificationFragment, 'timestamp' | 'notificationId' | 'errorContent'> & {
  timestamp: number,
  notificationId: string,
  errorContent?: Array<{ Key: string, Value: string }>
}

export type TransformedEventParams = Omit<EventParams, 'errorContent'> & { errorContent: ErrorContent }

export interface BaseEventConsumerI<T extends string | number, P extends AnyObject> {
  //typ eventu
  type: T;

  //listener któy wykona się w momencie pojawienia się eventu
  //zawiera dane z eventu ws
  onUpdate(event: P, data?: ((event: EventParams) => NotificationDataType | Promise<NotificationDataType | null> | null) | NotificationDataType | null): void;
}

export interface SubscriptionEventsServiceI<P extends AnyObject = EventParams, T extends string | number = WebsocketNotificationType> {
  subscribed: boolean;

  //Rejestruje konsumer
  //Zwraca metodę do odsubskrybowania tego eventu oraz listener
  subscribe(consumer: BaseEventConsumerI<T, P>): { unsubscribe: () => void, event: Event<P> };

  //umożliwia odsubskrybowanie eventu podając jego typ oraz referencję do listenera jako event
  //Zwraca true jeśli się uda usunąć i false jeśli nie
  unsubscribe(eventType: T, event: Event<P>): boolean;
}

export interface SubscriptionInitializerI<P extends AnyObject = Variables> {
  isReady: boolean;
  subscribed: boolean;

  //rozłącza połączenie ws, anuluje i czyści wszystkie consumery, czyści alerty
  cancel(): this;

  //Resetuje połączenie i obecne subskrypcje, Uruchamia subskrypcję websocketową z listą consumerów
  reconnect(consumeMissed: boolean): Promise<void>;
}

type SubscribeReturnType<P extends AnyObject> = ReturnType<SubscriptionEventsServiceI<P>['subscribe']>

export class SubscriptionEventsService implements SubscriptionEventsServiceI, SubscriptionInitializerI {

  @observable
  public subscribed: boolean = false;

  private eventsService: EventServiceI<WebsocketNotificationType, EventParams>;

  private subscription: Subscription | null = null;

  private consumeMissedNotifications = new ConsumeMissedNotifications(this.envObserver, this.earchiveState);

  private readonly onInitialize?: (eventService: SubscriptionEventsServiceI) => void;

  constructor(private graphqlDocument: TypedDocumentNode, private envObserver: EnvObserverI, private earchiveState: EArchiveState, onInitialize?: (eventService: SubscriptionEventsServiceI) => void) {
    this.onInitialize = onInitialize?.bind?.(this);
    this.eventsService = new EventsService<WebsocketNotificationType, EventParams>();
    makeObservable(this);

    //automatyczne zresetowanie połączenia przy zmianie kontekstu
    reaction(() => this.contextIds, async () => await this.reconnect(), {fireImmediately: true});
  }

  @computed
  public get isReady(): boolean {
    return this.envObserver.currentEnv.userId !== null && this.envObserver.currentEnv.companyId !== null;
  }

  @computed
  private get contextIds(): { companyId: string | null, userId: string | null } {
    const {companyId, userId} = this.envObserver.currentEnv;
    return {companyId, userId};
  }

  public async reconnect(consumeMissed: boolean = true): Promise<void> {
    this.cancel();
    const {companyId, userId} = this.contextIds;
    if (companyId && userId) {
      this.initialize(userId, companyId, this.onInitialize);
      consumeMissed && await this.consumeMissedNotifications.fetch();
    }
  }

  public subscribe(consumer: BaseEventConsumerI<WebsocketNotificationType, EventParams>): SubscribeReturnType<EventParams> {
    const event = consumer.onUpdate.bind(consumer);
    return {unsubscribe: this.eventsService.subscribe(consumer.type, event), event};
  }

  public unsubscribe(eventType: WebsocketNotificationType, event: Event<EventParams>) {
    return this.eventsService.unsubscribe(eventType, event);
  }


  @action.bound
  public cancel(): this {
    this.subscription?.unsubscribe();
    this.subscribed = false;
    this.subscription = null;
    this.eventsService.clear();
    this.earchiveState.alertsState.clearAlerts();
    return this;
  }

  @action.bound
  private initialize(userId: string, companyId: string, onInitialize?: (self: this) => void): void {
    if (this.subscription || !this.isReady) {
      return;
    }
    this.subscription = apolloClient.subscribe<KSeFNotificationUserSubscription, KSeFNotificationUserSubscriptionVariables>({
      query: this.graphqlDocument,
      context: {envId: companyId},
      variables: {userId, companyId},
    }).subscribe({
      next: ({data, errors}) => {
        data?.onKSeFNotificationUser && this.eventsService.notify(data.onKSeFNotificationUser.type, data.onKSeFNotificationUser as EventParams);
        errors && console.log({errors});
      },
      error: (errorValue) => {
        console.error(errorValue);
      },
    });
    this.subscribed = true;
    onInitialize?.(this);
  }
}

const EVENT_TYPES_TRANSLATIONS: Partial<Record<WebsocketNotificationType, Tr>> = Object.freeze({
  [WebsocketNotificationType.GetUpo]: Tr.GetUPO,
  [WebsocketNotificationType.UnauthorizedInKsef]: Tr.UnauthorizedInKSeF,
  [WebsocketNotificationType.AuthorizeInKsef]: Tr.AuthorizeInKSeF,
  [WebsocketNotificationType.AuthorizeInKsefExternalToken]: Tr.AuthorizeInKSeFExternalToken,
  [WebsocketNotificationType.UnauthorizedInKsefExternalToken]: Tr.UnauthorizedInKSeFExternalToken,
  [WebsocketNotificationType.AutoUnauthorizedInKseFExternalToken]: Tr.AutoUnauthorizedInKSeFExternalToken,
  [WebsocketNotificationType.TerminateSession]: Tr.TerminateSession,
  [WebsocketNotificationType.DownloadInvoices]: Tr.DownloadInvoices,
  [WebsocketNotificationType.GrantPermission]: Tr.PermissionOperation,
  [WebsocketNotificationType.AutoFetchingInvoices]: Tr.AutoFetchingInvoices,
  [WebsocketNotificationType.AutoSendingInvoices]: Tr.AutoSendingInvoices,
  [WebsocketNotificationType.SendingInvoices]: Tr.SendingInvoices,
  [WebsocketNotificationType.RegistrationNumberCreated]: Tr.RegistrationNumberCreated,
  [WebsocketNotificationType.InvoicePostingStatusChanged]: Tr.InvoicePostingStatusChanged,
  [WebsocketNotificationType.UploadInvoices]: Tr.UploadInvoices,
  [WebsocketNotificationType.OcrInvoiceImported]: Tr.OcrInvoiceImported,
  [WebsocketNotificationType.PostInvoicesFailed]: Tr.PostInvoicesFailed,
  [WebsocketNotificationType.DeleteInvoices]: Tr.deleteInvoiceFinished,
  [WebsocketNotificationType.WhiteListValidation]: Tr.whiteListValidation,
  [WebsocketNotificationType.SyncPermission]: Tr.SyncPermissionKSeF,
});

export function getNotificationTypeName(notification: Pick<NotificationEvent, 'Type'>): string {
  return intl.formatMessage({id: EVENT_TYPES_TRANSLATIONS[notification.Type] ?? Tr.unknownNotificationType});
}



