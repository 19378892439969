import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {Modal} from '@symfonia/brandbook';
import {intl} from '../../root/IntlProvider';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import FilehubFooterButtons from '../components/FilehubFooterButtons';
import {FilehubFileUploader} from '../components/FilehubFileUploader';
import {useState} from 'react';
import {addAttachments} from '../table/useFilehubMethods';
import {InvoicesService} from '../../earchive/modules/Invoices/Invoices.service';

export type FilehubFile = {
  file: File;
  fileType: {
    isFolder: boolean;
    isValid: boolean;
    mimeType?: string;
    extension?: string;
  };
  id: string;
  containsInvalidCharacters: boolean;
  containsExceededFileSize: boolean;
};

type Props = {
  invoicesService: InvoicesService;
  onClose: () => void;
  state: IFilehubState;
};

const FilehubAddAttachmentModal = observer(({invoicesService, onClose, state}: Props) => {
  const [files, setFiles] = useState<FilehubFile[]>([]);
  const [inValidFiles, setInvalidFiles] = useState<FilehubFile[]>([]);
  const [attachmentsContainsFolder, setAttachmentsContainsFolder] = useState<FilehubFile[]>([]);
  const [filesWithInvalidCharacters, setFilesWithInvalidCharacters] = useState<FilehubFile[]>([]);
  const [filesWithExceededFileSize, setFilesWithExceededFileSize] = useState<FilehubFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isModalOpened = state.isAddAttachmentModalOpen;
  const hasInvalidFiles = inValidFiles.length !== 0;
  const hasFiles = files.length > 0;
  const hasAttachmentsContainsFolder = attachmentsContainsFolder.length !== 0;
  const hasFilesWithInvalidCharacters = filesWithInvalidCharacters.length !== 0;
  const hasFilesWithExceededFileSize = filesWithExceededFileSize.length !== 0;
  const isSaveEnabled =
    !hasInvalidFiles &&
    hasFiles &&
    !hasAttachmentsContainsFolder &&
    !hasFilesWithInvalidCharacters &&
    !hasFilesWithExceededFileSize;

  const sendAttachment = async () => {
    setIsLoading(true);
    await addAttachments(files, state, setFiles, setIsLoading);
    await invoicesService.repository.fetch();
    setIsLoading(false);
  };

  const handleClose = () => {
    if (!isLoading) {
      state.setIsFileHubOpen(true);
      state.setAreFooterButtonsShown(false);
      state.setErrorSizeFileList([]);
      onClose();
      setFiles([]);
    }
  };

  const hideSuccessInfo = () => {
    state.setSuccessfulAddRequest(null);
  };

  return (
    <div onClick={hideSuccessInfo}>
      <Modal
        className="p-[16px]"
        onClose={handleClose}
        show={isModalOpened}
        title={intl.formatMessage({id: Tr.addingAttachment})}
      >
        {intl.formatMessage({id: Tr.invoicePURCHASE})}, <strong>{state.invoiceNumber}</strong>
        <FilehubFileUploader
          files={files}
          invalidFiles={inValidFiles}
          isLoading={isLoading}
          attachmentsContainsFolder={attachmentsContainsFolder}
          setAttachmentsContainsFolder={setAttachmentsContainsFolder}
          filesWithInvalidCharacters={filesWithInvalidCharacters}
          setFilesWithInvalidCharacters={setFilesWithInvalidCharacters}
          filesWithExceededFileSize={filesWithExceededFileSize}
          setFilesWithExceededFileSize={setFilesWithExceededFileSize}
          setFiles={setFiles}
          setInvalidFiles={setInvalidFiles}
          state={state}
        />
        <div className="mt-[16px]">
          <FilehubFooterButtons
            loading={isLoading}
            onClose={handleClose}
            onSave={sendAttachment}
            isSaveEnabled={isSaveEnabled}
            secondaryButtonText={Tr.cancelButton}
          />
        </div>
      </Modal>
    </div>
  );
});

export default FilehubAddAttachmentModal;
