import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '../../../../locales/translationKeys';
import {IconWithInfo} from '@symfonia/symfonia-ksef-components';
import {KSeFOperatingStatus} from '@symfonia-ksef/graphql';
import {Typography} from '@mui/material';
import {dateParser} from '../../../common';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const KSeFConnectionError = () => {
  const intl = useIntl();
  const addAlert = earchiveState.alertsState.addAlert;

  addAlert((intl.formatMessage({id: Tr.ksefStatusError})), ToastVariant.ERROR, {
    displayDuration: 10000,
    omitIfHasTheSameAlert: true
  });

  return <IconWithInfo
    tooltipWidth="auto"
    status={KSeFOperatingStatus.Down}
    tooltipContent={<Typography className="pre-line-typography">
      <FormattedMessage
        id={Tr[`ksefHealth_${KSeFOperatingStatus.Down}_tooltip_Content`]}
        values={{date: dateParser(new Date(), true), br: <br/>}}/>
    </Typography>
    }/>;
};
