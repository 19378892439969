import {observer} from 'mobx-react-lite';
import {FC, useEffect, useState} from 'react';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import FilehubTableMenu from '../components/FilehubTableMenu';
import {Checkbox, CheckboxSize, IconSvg, Table, TableRowHeight, Tag, TagColor, TagSize} from '@symfonia/brandbook';
import {filehubTableConfig} from './FilehubTableConfig';
import {TableRow} from '@symfonia/brandbook';
import {Button} from '@mui/material';
import {MoreVert} from '@mui/icons-material';
import {getSize, intlFunction} from '../utils/utils';
import {Tr} from '@symfonia-ksef/locales/keys';
import {DateRenderer} from '../../common';
import {Attachment} from '@symfonia-ksef/graphql';
import FilehubMultipleActionsBar from '../components/FilehubMultipleActionsBar';
import FilehubFooterButtons from '../components/FilehubFooterButtons';
import {useFilehubMethods} from './useFilehubMethods';
import _ from 'lodash';
import {MultipleActionsRequestTypeEnum} from '../../earchive/pages/Documents/state/FilehubState';
import {FilehubNoAttachments} from '../components/FilehubNoAttachments';
import FilehubDeleteAttachmentModal from '../modals/FilehubDeleteAttachmentModal';
import FilehubDownloadAttachmentModal from '../modals/FilehubDownloadAttachmentModal';
import FilehubModalHeader from '../components/FilehubModalHeader';
import {LinearProgress} from '@mui/material';
import {InvoicesService} from '../../earchive/modules/Invoices/Invoices.service';
import {FilehubAlert} from '../components/FilehubAlert';
import {intl} from '../../root/IntlProvider';

interface FilehubTableProps {
  invoicesService: InvoicesService;
  state: IFilehubState;
}

export const FilehubTable: FC<FilehubTableProps> = observer(({invoicesService, state}) => {
  const [allSelectedAttachments, setAllSelectedAttachments] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState<Attachment[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingAttachmentsList, setLoadingAttachmentsList] = useState(true);

  const attachments = state.attachments;
  const attachmentsWithoutFilters = state.attachmentsWithoutFilters;
  const selectedAttachmentsLength = selectedAttachments.length;
  const showFooterButtons = state.areFooterButtonsShown;
  const attachmentsLength = attachments.length;
  const isMultipleActionsBarVisible = selectedAttachmentsLength > 0;
  const hasAttachments = state.attachments.length > 0;
  const attachmentIds = selectedAttachments.map(attachment => attachment.Id);

  const clearSelection = () => {
    setAllSelectedAttachments(false);
    setSelectedAttachments([]);
  };

  const {changeAttachmentStatusAction, getAttachmentsAction} = useFilehubMethods(state, clearSelection);

  useEffect(() => {
    async function fetchData() {
      setLoadingAttachmentsList(true);
      await getAttachmentsAction(state.invoiceId);
      setLoadingAttachmentsList(false);
    }

    fetchData();

    return () => {
      state.setAttachments([]);
      state.setAttachmentsWithoutFilters([]);
    };
  }, []);

  const handleHeaderCheckboxSelect = (checked: boolean) => {
    if (checked) setSelectedAttachments(attachments);
    else setSelectedAttachments([]);
    setAllSelectedAttachments(checked);
  };

  const filehubTableHeaders = filehubTableConfig(handleHeaderCheckboxSelect, allSelectedAttachments);

  const undoFooterButton = () => {
    const deepAttachmentsWithoutFilters = _.cloneDeep(attachmentsWithoutFilters);
    state.setAttachments(deepAttachmentsWithoutFilters);
    state.setAreFooterButtonsShown(false);
    clearSelection();
  };

  const hideRequestAlert = () => {
    state.setSuccessfulDeleteRequest(null);
    state.setSuccessfulMultipleActionsRequest(null);
  };

  const toggleAddAttachmentModal = () => {
    state.setIsAddAttachmentModalOpen(!state.isAddAttachmentModalOpen);
    state.setIsFileHubOpen(false);
    hideRequestAlert();
  };

  const saveFooterButton = async () => {
    setLoading(true);

    if (
      state.multipleActionsRequestType === MultipleActionsRequestTypeEnum.Private ||
      state.multipleActionsRequestType === MultipleActionsRequestTypeEnum.Public
    ) {
      await changeAttachmentStatusAction();
    }

    setLoadingAttachmentsList(true);
    await getAttachmentsAction(state.invoiceId);
    setLoadingAttachmentsList(false);

    setSelectedAttachments(attachments.filter(attachment => attachmentIds.includes(attachment.Id)));

    setLoading(false);
  };

  const handleCheckboxSelect = (selected: boolean, selectedAttachment: Attachment) => {
    if (selected) setSelectedAttachments(prev => [...prev, selectedAttachment]);
    else setSelectedAttachments(prev => prev.filter(attachment => attachment.Id !== selectedAttachment.Id));
    setAllSelectedAttachments(false);
  };

  useEffect(() => {
    if (selectedAttachmentsLength !== 0 && attachmentsLength === selectedAttachmentsLength) {
      setAllSelectedAttachments(true);
    } else {
      setAllSelectedAttachments(false);
    }
  }, [selectedAttachments, attachments]);

  return (
    <>
      <FilehubDeleteAttachmentModal invoicesService={invoicesService} clearSelection={clearSelection} state={state}/>
      <FilehubDownloadAttachmentModal selectedAttachments={selectedAttachments} state={state}/>
      <FilehubModalHeader
        clearSelection={clearSelection}
        openAddAttachmentModal={toggleAddAttachmentModal}
        state={state}
      />
      {isMultipleActionsBarVisible && (
        <FilehubMultipleActionsBar clearSelection={clearSelection} selectedAttachments={selectedAttachments} selectedAttachmentsIds={attachmentIds} state={state}/>
      )}

      <FilehubTableMenu state={state}/>
      <div className="h-[4px] my-[-12px]">{loadingAttachmentsList && <LinearProgress/>}</div>
      <Table
        className="mt-[-4px] overflow-y-auto max-h-[40vh]"
        header={filehubTableHeaders}
        tableRowHeight={TableRowHeight.MD}
      >
        {attachments.map((row: Attachment) => {
          const {IsPublic: isPublic, FileName: fileName, Id: id, CreatedAt: createdAt, CreatedByEmail: createdByEmail, Size: size,} = row;

          return (
            <TableRow>
              <span>
                <Checkbox
                  checked={selectedAttachments.some(attachment => attachment.Id === id)}
                  size={CheckboxSize.SM}
                  onChange={selected => handleCheckboxSelect(selected, row)}
                  value={`filehubTableCheckbox-${id}`}
                />
              </span>
              <span>{fileName}</span>
              <span>{DateRenderer({value: createdAt})}</span>
              <span>{createdByEmail}</span>
              <span>
                <Tag
                  className="px-[12px]"
                  color={isPublic ? TagColor.GREEN_LIGHT : TagColor.ORANGE_LIGHT}
                  lIcon={isPublic ? IconSvg.PUBLIC : IconSvg.VPN_LOCK}
                  size={TagSize.MD}
                  text={intlFunction(isPublic ? Tr.publicStatus : Tr.privateStatus)}
                />
              </span>
              <span>{getSize(size)}</span>
              <span>
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    state.setSelectedInvoiceMenuAnchorEl(e.currentTarget);
                    state.setSelectedInvoiceMenuOpened(true);
                    state.setSelectedRow(row);
                  }}
                  size="small"
                >
                  <MoreVert className="IconWithTransform"/>
                </Button>
              </span>
            </TableRow>
          );
        })}
      </Table>
      {!hasAttachments && <FilehubNoAttachments/>}
      {state.showGetAttachmentsError && (
        <FilehubAlert title={intl.formatMessage({id: Tr.filehubGetAttachmentsErrorTitle})}/>
      )}
      {showFooterButtons && (
        <FilehubFooterButtons loading={loading} onClose={undoFooterButton} onSave={saveFooterButton}/>
      )}
    </>
  );
});
