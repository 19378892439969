import React, {FC, useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useModule} from '../../../root/services/MobXServices/BaseModule';
import {ExtendedTable} from '../../../root/components/ExtendedTable';
import {useIntl} from 'react-intl';
import {PermissionsRegisterService} from './PermissionsRegister.service';

type PermissionsRegisterViewProps = {
    subscribeToRefresh?: (download: () => void) => (() => void) | undefined;
}

export const PermissionsRegisterView: FC<PermissionsRegisterViewProps> = observer(({
    subscribeToRefresh
}) => {
  const module = useModule(() => new PermissionsRegisterService());
  useEffect(() => {
    return subscribeToRefresh?.(() => module.repository.fetch());
  }, [subscribeToRefresh, module.repository]);
  
  return <ExtendedTable
    service={module.tableService}
    loaderEnabled
  />;
});
