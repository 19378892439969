import './InvoicePreview.scss';
import {ButtonMenu, ButtonWithIcon} from '@symfonia/symfonia-ksef-components';
import {Buttons, getButtons} from './IPConfig';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useRef} from 'react';
import {InvoiceHTMLPreview} from './InvoiceHTMLPreview';
import {DefaultItem, InvoicePreviewInterface} from '@symfonia-ksef/state/InvoicePreviewState/InvoicePreviewRepository';
import {useIntl} from 'react-intl';
import {InvoiceDto} from '@symfonia-ksef/graphql';
import {Modal} from '@symfonia/brandbook';
import {useDeviceViewport, usePrevious} from '@symfonia/symfonia-ksef-components';
import {Text} from '../../../root/components/Text';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

type InvoicePreviewComponentProps<T extends DefaultItem = DefaultItem> = {
  isInternalInvoiceBound?: boolean,
  invoices: InvoiceDto[],
  invoicePreviewRepository: InvoicePreviewInterface<T>,
  className?: string
  withButtons: boolean,
  hidden?: boolean,
  onChange?: (item?: T) => Promise<void> | void
};


export const InvoicePreviewComponent = observer(<T extends DefaultItem = DefaultItem>({
                                                                                        invoices,
                                                                                        invoicePreviewRepository,
                                                                                        withButtons,
                                                                                        hidden,
                                                                                        onChange,
                                                                                      }: InvoicePreviewComponentProps<T>) => {

  const {
    initialized,
    currentInvoiceId,
    canZoomIn,
    canZoomOut,
  } = invoicePreviewRepository;

  const previousInvoiceId = usePrevious(currentInvoiceId);

  const intl = useIntl();

  const zoomTooltipTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const {left, right, zoomIn, zoomOut, download} = useMemo<Buttons>(() => getButtons({
    previewRepository: invoicePreviewRepository,
    invoices,
    zoomTooltipTimeout: zoomTooltipTimeout.current,
    intl,
    onChange,
    currentInvoiceIndex: invoicePreviewRepository.getCurrentInvoiceIndex(invoices),
  }), [invoices, invoicePreviewRepository, invoicePreviewRepository.currentInvoiceId, canZoomIn, canZoomOut, onChange]);

  useEffect(() => {
    if (!currentInvoiceId) {
      invoicePreviewRepository.reset({state: true});
    }
    ((currentInvoiceId && currentInvoiceId !== previousInvoiceId) || !initialized) && invoicePreviewRepository.load();
  }, [currentInvoiceId, initialized]);


  const {isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);

  if (hidden) {
    return null;
  }

  const preview = <>
    <div className="grow flex justify-between flex-col">
      <InvoiceHTMLPreview invoicePreviewRepository={invoicePreviewRepository}/>
      {withButtons && !isPhone && <ButtonMenu
        className={`flex items-center content-center justify-center gap-x-4`}
        buttons={[left, right]}/>}
    </div>

    {!isPhone && <div>
      <ButtonMenu
        className="flex flex-col items-center content-center justify-start mx-2 gap-y-4"
        buttons={[download, zoomOut, zoomIn]}/>
    </div>}
  </>;

  if (isPhone) {
    return <Modal
      title={
        <div className="pl-[32px] flex items-center content-center justify-between">
          <ButtonWithIcon className="mr-[8px]" {...left}/>
          <div>
            <ButtonMenu
              className={`flex items-center content-center justify-center gap-x-4`}
              buttons={[zoomOut, download, zoomIn]}
            />
            <Text
              className="w-full text-center block">{invoicePreviewRepository.currentItem?.Description}</Text>
          </div>
          <ButtonWithIcon className="ml-[8px]" {...right}/>
        </div>
      }
      stickyHeader
      className="w-[calc(100vw-96px)] !min-w-0 h-screen !p-0"
      show={invoicePreviewRepository.enableInvoicePreview}
      onClose={() => invoicePreviewRepository.setEnableInvoicePreview(false)}
    >
      <div className="flex nowrap w-full h-[calc(100vh-176px)]">{preview}</div>
    </Modal>;
  }


  return <div
    className="flex nowrap w-full h-[calc(100vh-64px)] sticky top-[64px] shadow-xl rounded-xl border border-slate-200 py-[12px] px-[6px]">
    {preview}
  </div>;
});
