import {BaseModule} from '../../../root/services/MobXServices/BaseModule';
import {computed, makeObservable} from 'mobx';
import {TableBuilder} from '../../../root/services/TableServices/TableBuilder';
import {PermissionsRepository, PermissionsRepositoryI} from './Permissions.repository';
import {
  PermissionsDataSourceHandler,
  permissionsInitialColumnsFactory,
  PermissionsRow,
  PermissionsTableI,
  permissionsTableKeysFactory,
} from './PermissionsTable/PermissionsTableConfig';
import {CredentialsFragment, GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables} from '@symfonia-ksef/graphql';
import {PaginationMode} from '../../../root/services/TableServices/PaginationTableService';
import {PermissionsFiltersState} from './PermissionsFIlters/PermissionsFiltersState';
import {SearchService, SearchServiceI} from '../../../root/services/SearchService/SearchService';
import dayjs from 'dayjs';

export class PermissionsService extends BaseModule {
  public readonly repository!: PermissionsRepositoryI;
  public readonly areActionsDisabled: boolean | undefined;
  public readonly filters!: PermissionsFiltersState;
  public readonly searchService!: SearchServiceI;
  public readonly onEditClicked: (userPermissions: CredentialsFragment, element: HTMLElement) => void;

  constructor(onEditClicked: (userPermissions: CredentialsFragment, element: HTMLElement) => void, areActionsDisabled: boolean | undefined) {
    super();
    this.filters = new PermissionsFiltersState(this);
    this.repository = new PermissionsRepository(this.filters);
    this.searchService = new SearchService(this.repository, () => this.tableService.setPagination({page: 1}), true);
    this.onEditClicked = onEditClicked;
    this.areActionsDisabled = areActionsDisabled;
    this.createTableService();

    makeObservable(this);
  }

  private _tableService!: PermissionsTableI;

  public get tableService(): PermissionsTableI {
    return this._tableService;
  }

  @computed
  public get permissions(): PermissionsRow[] {
    return this.tableService.rows;
  }

  @computed
  public get syncPermissionDate(): Date {
    return this.repository.data?.LastUpdated;
  }

  @computed
  public get isAnySyncDateAvailable(): boolean {
    return this.repository.data?.LastUpdated;
  }

  @computed
  public get syncDate() {
    const date = dayjs(this.syncPermissionDate);
    return date.format('DD.MM.YYYY');
  }

  public get syncHour() {
    const date = dayjs(this.syncPermissionDate);
    return date.format('HH:mm');
  }

  protected _onMount(): void {
    this.tableService.onMount();
    this.searchService.onMount();
    this.repository.fetch();
  }

  protected _onUnmount(): void {
    this.tableService.onUnmount();
    this.searchService.onUnmount();
  }

  private getColumns() {
    return permissionsInitialColumnsFactory(this.onEditClicked, this.areActionsDisabled);
  }

  private createTableService(): void {
    this._tableService = TableBuilder.create<PermissionsRow, 'GetPermissionsKSeF', GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables>()
      .connectKeyFactory(permissionsTableKeysFactory)
      .connectColumns(this.getColumns())
      .connectSortBy({persistKey: 'permissionsSortBy'})
      .connectDataSource(new PermissionsDataSourceHandler(this.repository))
      .connectPagination({
        lifeCycle: {mode: PaginationMode.serverSide},
        persistKey: 'permissionsPagination',
      })
      .buildExtended();
  }
}
