import {Route, Routes} from 'react-router-dom';
import SharesPage from './pages/SharesPage';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {observer} from 'mobx-react-lite';

interface SharesProps{
  state:IFilehubState;
}

const Shares = observer(({state} : SharesProps) => {
  return (
    <Routes>
      <Route path="/" element={<SharesPage state={state}/>}/>
    </Routes>
  );
});

export default Shares;
