import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {dateParser} from '../../helpers';
import {PackageType} from '@symfonia-ksef/graphql';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';
import {Text} from '../../../root/components/Text';
import {BulletList, IconColor, IconSize, IconSvg, TooltipPosition} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {ChartElement, CircularPackageLimit, IconWithTooltip} from '@symfonia/symfonia-ksef-components';
import {InsideText} from './InsideText';
import {getDecimal, getPackageEndingTooltip} from '../../helpers/packageStatisticsHelper';
import PackageWarning from './PackageWarning';

export type ChartData = {type: ChartElement, name: string; numberValue: number; stringValue?: string};

export const PackageInfo = observer(({isAdmin}: {isAdmin: boolean}) => {
  const intl = useIntl();
  const usedIntl = Tr.used;
  const availableIntl = Tr.available;
  const packageIntl = Tr.package;

  const {
    state: {DateTo, Items: Packages},
    subscriptionIsInactive,
    subscriptionIsActive,
    loadingError,
    subscriptionNotFound,
    maxInvoicesSize,
    maxStorageSize,
    currentStorageUsage,
    currentInvoicesUsage,
    availableStorageSize,
    currentInvoicesPercentageUsage,
    currentStoragePercentageUsage,
    invoicesPercentageLeft,
    storagePercentageLeft,
    packageThresholds,
    packageDateIsEnding,
    invoicePackageIsEnding,
    storagePackageIsEnding,
    deactivationDate,
    expiringThreshold,
    invoicesLeft,
    isPackageUsed,
    invoicesPackageUsed,
    storagePackageUsed,
    enabled,
  } = earchiveState.packageStatistics;

  const hasActivePackages = !loadingError || (!subscriptionNotFound && subscriptionIsInactive === false) || subscriptionIsInactive === false;
  const isWarning = packageDateIsEnding || !subscriptionIsActive || invoicePackageIsEnding || storagePackageIsEnding;
  const textContent = getPackageEndingTooltip(isPackageUsed, subscriptionIsActive, invoicePackageIsEnding, storagePackageIsEnding, packageDateIsEnding, isAdmin);
  const isPackageExpiring = invoicePackageIsEnding || storagePackageIsEnding || packageDateIsEnding;
  const invoicesNotActive = invoicesPackageUsed || !subscriptionIsActive;
  const storageNotActive = storagePackageUsed || !subscriptionIsActive;

  const packageListBulletpoints = useMemo(
    () =>
      Packages &&
      Packages.map(item => {
        const label =
          item.PackageTypeEnum === PackageType.Service
            ? item.PackageDescription
            : intl.formatMessage(
              {id: `description_${item.PackageTypeEnum}`},
              {value: item.PackageTypeEnum === PackageType.Storage ? maxStorageSize.valueWithSpace : item.PackageValue},
            );

        return label;
      }),
    [Packages, intl, maxStorageSize],
  );

  const invoicesInsideText = (
    <InsideText
      id="availableInvoices"
      isAdmin={isAdmin}
      available={invoicesNotActive ? 0 : maxInvoicesSize - currentInvoicesUsage}
      max={maxInvoicesSize}
      percent={invoicesNotActive ? getDecimal(0) : getDecimal(invoicesPercentageLeft)}
    />
  );

  const memoryInsideText = (
    <InsideText
      id="availableSpace"
      isAdmin={isAdmin}
      available={storageNotActive ? getDecimal(0) : availableStorageSize.valueWithoutUnit}
      max={maxStorageSize.valueWithSpace}
      percent={storageNotActive ? getDecimal(0) : getDecimal(storagePercentageLeft)}
    />
  );

  const chartDataAmountOfInvoices: ChartData[] = [
    {
      type: ChartElement.AVAILABLE,
      name: availableIntl,
      numberValue: isAdmin ? maxInvoicesSize - currentInvoicesUsage : invoicesPercentageLeft,
      stringValue: invoicesNotActive
      ? (isAdmin ? '0' : '0,00%')
      : (isAdmin ? `${maxInvoicesSize - currentInvoicesUsage}` : `${getDecimal(invoicesPercentageLeft)}%`),
    },
    {
      type: ChartElement.USED,
      name: usedIntl,
      numberValue: isAdmin ? currentInvoicesUsage : currentInvoicesPercentageUsage,
      stringValue: isAdmin ? `${currentInvoicesUsage}` : `${getDecimal(currentInvoicesPercentageUsage)}%`,
    },
    {
      type: ChartElement.CAPACITY,
      name: packageIntl,
      numberValue: isAdmin ? maxInvoicesSize : 100,
      stringValue: isAdmin ? `${maxInvoicesSize}` : '100,00%',
    },
  ];

  const chartDataMemoryUsage: ChartData[] = [
    {
      type: ChartElement.AVAILABLE,
      name: availableIntl,
      numberValue: isAdmin ? Math.max(0, maxStorageSize.bytes - currentStorageUsage.bytes) : storagePercentageLeft,
      stringValue: storageNotActive
      ? (isAdmin ? '0,00 GB' : '0,00%')
      : (isAdmin ? availableStorageSize.valueWithSpace : `${getDecimal(storagePercentageLeft)}%`),
    },
    {
      type: ChartElement.USED,
      name: usedIntl,
      numberValue: isAdmin ? currentStorageUsage.bytes : currentStoragePercentageUsage,
      stringValue: isAdmin ? currentStorageUsage.valueWithSpace : `${getDecimal(currentStoragePercentageUsage)}%`,
    },
    {
      type: ChartElement.CAPACITY,
      name: packageIntl,
      numberValue: isAdmin ? maxStorageSize.bytes : 100,
      stringValue: isAdmin ? maxStorageSize.valueWithSpace : `100,00%`,
    },
  ];

  return (
    <div className="grid">
      <div className="grid">
        <Text className="font-bold my-[20px]">
          <FormattedMessage id={Tr.yourPackage}/>
        </Text>

        <div className="mb-[16px]">
          <Text testId="packageInfoActiveUntil">
            <FormattedMessage id={Tr.activeUntil}/>:{' '}
          </Text>
          {DateTo ? <Text className="font-bold">{dateParser(DateTo)}</Text> : <FormattedMessage id="noData"/>}
        </div>

        <div>
          {isWarning && (
            <div className="mb-[16px]">
              <PackageWarning
                enabled={enabled}
                textId={textContent?.content}
                isPackageExpiring={isPackageExpiring}
                subscriptionIsInactive={subscriptionIsInactive}
                packageDateIsEnding={packageDateIsEnding}
                DateTo={DateTo}
                invoicePackageIsEnding={invoicePackageIsEnding}
                invoicesLeft={invoicesLeft}
                storagePackageIsEnding={storagePackageIsEnding}
                availableStorageSize={availableStorageSize?.value}
                deactivationDate={deactivationDate}
                expiringThreshold={expiringThreshold}
              />
            </div>
          )}
          {isAdmin && !subscriptionIsInactive && (
            <div className="mb-[16px]">
              <Text testId="packageInfopackageIncludes">
                <FormattedMessage id={Tr.packageIncludes}/>{' '}
              </Text>
              {!Packages || (!Packages.length && <FormattedMessage id={Tr.noData}/>)}
              {Packages && Packages.length > 0 && <BulletList className={'whitespace-nowrap'} elements={packageListBulletpoints as string[]}/>}
            </div>
          )}
        </div>
      </div>
      {hasActivePackages && (
        <div className="grid gap-[16px]">
          <div>
            <CircularPackageLimit
              chartData={chartDataAmountOfInvoices}
              insideText={invoicesInsideText}
              testId="packageInfoInvoicesAmount"
              thresholds={packageThresholds}
              isNotActive={!!invoicesNotActive}
              titleElement={
                <div className="flex justify-start gap-[8px]">
                  <Text className="font-bold">
                    <FormattedMessage id={Tr.invoicesCount}/>
                  </Text>
                  <IconWithTooltip
                    tooltipWidth="auto"
                    tooltipClass={'w-[800px]'}
                    icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
                    size={IconSize.MD}
                    tooltipPosition={TooltipPosition.RIGHT}
                    tooltipContent={<p>{intl.formatMessage({id: Tr.packageUsageTooltipContent}, {br: <br/>})}</p>}
                  />
                </div>
              }
            />
          </div>
          <div>
            <CircularPackageLimit
              chartData={chartDataMemoryUsage}
              insideText={memoryInsideText}
              testId="packageInfoDateMemoryUsage"
              thresholds={packageThresholds}
              isNotActive={!!storageNotActive}
              titleElement={
                  <Text className="font-bold">
                    <FormattedMessage id={Tr.memory}/>
                  </Text>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
});
