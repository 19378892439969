import {Dropdown, DropdownWidth, Pagination} from '@symfonia/brandbook';
import {useState} from 'react';

const paginationSelectOptions = [
  {
    label: '5',
    value: '5',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '50',
    value: '50',
  },
];

const FilehubSharePagination = () => {
  const [selectedPaginationOption, setSelectedPaginationOption] = useState(paginationSelectOptions[0].value);
  const [paginationPage, setPaginationPage] = useState(1);

  const handlePaginationPageChange = (page: number) => {
    setPaginationPage(page);
  };

  const handlePaginationSelectChange = (value: string[]) => {
    setSelectedPaginationOption(value[0]);
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div>
        <Dropdown
          onChange={handlePaginationSelectChange}
          options={paginationSelectOptions}
          value={[selectedPaginationOption]}
          width={DropdownWidth.FULL}
        />
      </div>
      <div>
        <Pagination currentPage={paginationPage} onChange={handlePaginationPageChange} pageCount={5}/>
      </div>
    </div>
  );
};

export default FilehubSharePagination;
