import {MultiSelectModel} from '@symfonia/symfonia-ksef-components';
import {InvoiceBound, InvoicePostingStatus, KSeFStatus} from '@symfonia-ksef/graphql';
import {InvoiceKsefStatusToText} from './InvoiceKsefStatusToText';
import {FiltersForQuery} from '../../layout/components/Filters/FiltersState';
import {InvoiceFilterType, InvoicesFiltersModel} from '../../earchive/models';
import {InvoicePostingStatusToText} from './InvoicePostingStatusToText';

export function getDefaultKsefStatusesList(): MultiSelectModel[] {
  return Object.values(KSeFStatus)
    .map(el => ({key: el, value: InvoiceKsefStatusToText(el).text}))
    .sort((a, b) => a.value.localeCompare(b.value));
}

export function getDefaultPostingStatusesList(): MultiSelectModel[] {
  return Object.values(InvoicePostingStatus)
    .map(el => ({key: el, value: InvoicePostingStatusToText(el).text}))
    .sort((a, b) => a.value.localeCompare(b.value));
}

export type FiltersType = Partial<Pick<FiltersForQuery, 'Downloaded' | 'ToSend' | 'ToDownload' | 'Sent' | 'ReadyForPosting'>>;

export const FILTERS_TYPES = Object.freeze({
  sent: 'Sent',
  notSent: 'ToSend',
  downloaded: 'Downloaded',
  notDownloaded: 'ToDownload',
  readyForPosting: 'ReadyForPosting',
  toAccept: 'ToAccept',
});

export const FILTERS_LOCAL_TYPES = Object.freeze({
  Sent: 'sent',
  ToSend: 'notSent',
  Downloaded: 'downloaded',
  ToDownload: 'notDownloaded',
  ReadyForPosting: 'readyForPosting',
  ToAccept: 'toAccept',
});

export const mapToLocalFiltersValue = (activeFiltersValue?: Partial<Pick<FiltersForQuery, 'Downloaded' | 'ToDownload' | 'Sent' | 'ToSend' | 'ReadyForPosting' | 'ToAccept'>>): InvoicesFiltersModel => {
  if (!activeFiltersValue) {
    return {type: InvoiceFilterType.All};
  }
  const keys = Object.values(FILTERS_TYPES);
  const type = Object.entries(activeFiltersValue)
    .filter(([key, value]) => keys.includes(key as keyof typeof FILTERS_LOCAL_TYPES) && value === true)
    .reduce<InvoiceFilterType>((foundType, [key, value]) => {
      return foundType === InvoiceFilterType.All
        ? (FILTERS_LOCAL_TYPES[key as keyof typeof FILTERS_LOCAL_TYPES] as InvoiceFilterType)
        : InvoiceFilterType.All;
    }, InvoiceFilterType.All);
  return {type};
};

export const mapToActiveFilters = (type: InvoiceFilterType, invoiceBound: InvoiceBound): FiltersType => {
  const {sent, notSent, downloaded, notDownloaded} = FILTERS_TYPES;
  const invoiceParamsType: string[] = (invoiceBound === InvoiceBound.External ? [downloaded, notDownloaded] : [sent, notSent]);
  return Object.entries(FILTERS_TYPES).reduce<FiltersType>((filters, [typeKey, filterKey]) => {
    filters[filterKey as keyof FiltersType] =
      (type === InvoiceFilterType.All
        ? invoiceParamsType.includes(filterKey)
        : type === typeKey);
    return filters;
  }, {});
};
