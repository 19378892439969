import {intl} from "../../root/IntlProvider";
import {Tr} from "@symfonia-ksef/locales/keys";
import {ButtonSecondary, IconSvg, Input} from "@symfonia/brandbook";
import debounce from "lodash.debounce";

interface SharesPageSearchBarProps {
	disabled?: boolean;
	setSearchQuery: (q: string) => void;
  }

  const handleSearch = (phrase: string) => {
	console.log(`handleSearch: ${phrase}`);    
  };

  const debouncedSearchHandler = debounce((e: string) => handleSearch(e), 500);

export const SharesPageSearchBar = ({setSearchQuery, disabled}: SharesPageSearchBarProps) => (
	<div className="mb-[30px] inline-flex items-center gap-x-[10px]">
		<Input
	      placeholder={intl.formatMessage({id: Tr.searchInTheList})}
          rightIcon={IconSvg.SEARCH}
          defaultValue={''}
          onInput={debouncedSearchHandler}
          disabled={disabled}
        />
		<ButtonSecondary rIcon={IconSvg.FILTER_LIST} onClick={() => alert("o => !o")}/>
	</div>
  );